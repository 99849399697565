import { FC } from 'react'
import Folder from './Folder'
import DocumentById from './DocumentById'

interface DocumentIdsFolderProps {
  fileIds: number[]
  canDelete?: boolean
  loading?: boolean
}

const DocumentIdsFolder: FC<DocumentIdsFolderProps> = ({
  fileIds,
  canDelete = true,
  loading
}) => {
  return (
    <Folder
      canDelete={canDelete}
      canUpload={false}
      loading={loading}
    >
      {fileIds.map((id) => (
        <DocumentById key={id} id={id} canDelete={canDelete} />
      ))}
    </Folder>
  )

}

export default DocumentIdsFolder
