import { FC, Fragment } from 'react'
import { useOutletContext } from 'react-router-dom'
import { ApplicationByIdQuery, DocumentTypeEntity } from '../../graphql/schema'
import DocumentManager from '../../components/DocumentManager'

const Documents: FC = () => {
  const [data] = useOutletContext<[ApplicationByIdQuery]>()
  const app = data?.application

  if (!app) return null

  return (
    <Fragment>
      <DocumentManager entityId={app._id} entityType={DocumentTypeEntity.Application} />
      <h3 className='mb-8 mt-10 text-h200'>Документы компании-клиента</h3>
      {app.customerCompany?._id && (
        <DocumentManager entityId={app.customerCompany._id} entityType={DocumentTypeEntity.CustomerCompany} />
      )}
    </Fragment>
  )
}

export default Documents
