import { forwardRef, ComponentPropsWithoutRef } from 'react'
import { FieldError } from 'react-hook-form'
import { errorToString } from '../../../utils/errorToString'
import c from 'clsx'
import { IMaskInput, ReactMaskOpts } from 'react-imask'

interface MaskedNumberInputProps extends Omit<ComponentPropsWithoutRef<'input'>, 'onChange'> {
  label?: string
  error?: string | FieldError
  value?: string
  onChange: (e: { target: { value: string } }) => void
}

const numberMaskOptions: ReactMaskOpts = {
  mask: Number,
  thousandsSeparator: ' ',
  scale: 2
}

/**
 * Компонент для ввода чисел с разделителем разрядов пробелами и разделителем дробной части запятой
 */
const MaskedNumberInput = forwardRef<HTMLInputElement, MaskedNumberInputProps>(function Input(props, ref) {
  const { label, children, error, onChange, value, placeholder, inputMode } = props
  return (
    <div>
      {!!label && <div className='inp-label text-p350 mb-5'>{label}</div>}

      <label
        className={c(
          'group block relative bg-white-0 ring-grayscale-400 hover:focus-within:ring-red-100 focus-within:ring-red-100 hover:ring-grayscale-250 rounded-xl ring-1',
          !!error && 'ring-red-100 ring-1'
        )}
      >
        <IMaskInput
          value={value}
          {...numberMaskOptions}
          inputRef={ref}
          placeholder={placeholder}
          inputMode={inputMode}
          className='py-7 px-10 transition-opacity border-none outline-none bg-transparent focus:ring-0 w-full placeholder-grayscale-250 tabular-nums'
          onAccept={(v) => {
            onChange && onChange({ target: { value: v } })
          }}
        />
        {children}
      </label>
      {error && <div className='text-red-150 text-p450 pl-4 pt-2'>{errorToString(error)}</div>}
    </div>
  )
})

export default MaskedNumberInput
