import { FC, useEffect, useState } from 'react'
import { ReactComponent as PlusIcon } from '../../svg/icons/plus.svg'
import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'
import { ReactComponent as TickIcon } from '../../svg/icons/tick-xs.svg'
import {
  TaskKind,
  TaskTargetType,
  UserRoles,
  useCreateTaskMutation,
  useMoveTaskMutation,
  useUpdateTaskMutation,
  useUsersQuery
} from '../../graphql/schema'
import { SubmitHandler, useForm } from 'react-hook-form'
import Input from '../../components/Input'
import { handleBackendErrorsToForm } from '../../utils/backendErrorUtils'
import { ApolloError } from '@apollo/client'
import RadioButtonsGroup from '../../components/RadioButtonsGroup'
import SubmitButton from '../../components/SubmitButton'
import Textarea from '../Textarea'
import MaskedInput from '../MaskedInput'
import { getCalendarFormatDate, timeFormatter } from '../../utils/dateFormatter'
import getFullName from '../../utils/getFullName'
import Highlighted from '../Highlighted'
import Select from '../Select'
import useNodes from '../../hooks/useNodes'
import useFilteredUsers from '../../hooks/useFilteredUsers'
import { Switch } from '@headlessui/react'
import c from 'clsx'
import { Task } from './index'
import useCurrentUser from '../../hooks/useCurrentUser'
import sortUsersByAcivation from '../../utils/sortUsersByActivation'

interface TaskFormProps {
  targetId?: number
  targetType?: TaskTargetType
  childTargetId?: number
  childTargetType?: TaskTargetType
  onCreateTask?: (task: Task) => void
  onMoveTask?: (task: Task) => void
  onUpdateTask?: () => void
  entity?: Task
  managerId?: number
  comment?: string
  role?: UserRoles
  automaticDate?: Date
  automaticTime?: string
  automaticNotifications?: boolean
}

interface Inputs {
  kind: TaskKind
  user: number
  body: string
  date: string
  time: string
}

const TaskForm: FC<TaskFormProps> = ({
  targetId,
  targetType,
  childTargetId,
  childTargetType,
  onCreateTask,
  onUpdateTask,
  onMoveTask,
  entity,
  managerId,
  comment,
  role,
  automaticDate,
  automaticTime,
  automaticNotifications
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
    setValue
  } = useForm<Inputs>({
    defaultValues: {
      body: entity?.body || comment || '',
      date: entity?.dueDate
        ? getCalendarFormatDate(new Date(entity.dueDate))
        : getCalendarFormatDate(automaticDate) || '',
      time:
        entity?.dueDate && entity?.isDueDateTimeSpecified
          ? timeFormatter.format(new Date(entity.dueDate))
          : automaticTime || ''
    }
  })

  const [currentUserSelected, setCurrentUserSelected] = useState(false)
  const currentUser = useCurrentUser()

  const [kind, setKind] = useState<TaskKind>(entity?.kind || TaskKind.Task)
  const [query, setQuery] = useState('')
  // при наличии userId (то есть редактирование формы) подставляется исполнитель задачи, затем опционально переданный managerId,
  // и при создании новой задачи исполнителем по умолчанию подставляется текущий пользователь
  const [userId, setUserId] = useState(entity?.userId || managerId || currentUser?._id)
  const [userError, setUserError] = useState('')
  const [dueDateTimeSpecified, setDueDateTimeSpecified] = useState<boolean>(
    typeof entity?.isDueDateTimeSpecified === 'boolean' ? entity.isDueDateTimeSpecified : true
  )
  const [notificationActivated, setNotificationActivated] = useState<boolean>(
    typeof entity?.isNotificationActivated === 'boolean'
      ? entity.isNotificationActivated
      : automaticNotifications || true
  )

  const { data: usersData } = useUsersQuery({
    variables: { order: [{ name: 'asc' }], roles: role }
  })
  const users = useNodes(usersData?.users?.edges)
  const list = useFilteredUsers(users, query)

  list.sort(sortUsersByAcivation)

  const [createTask, { loading: loadingCreate }] = useCreateTaskMutation()
  const [updateTask, { loading: loadingUpdate }] = useUpdateTaskMutation()
  const [moveTask, { loading: loadingMove }] = useMoveTaskMutation()

  const time = watch('time')

  useEffect(() => {
    const regExpTime = /^(?:0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
    if (regExpTime.test(time)) {
      setDueDateTimeSpecified(true)
    } else {
      setDueDateTimeSpecified(false)
      setNotificationActivated(false)
    }
  }, [time])

  useEffect(() => {
    setCurrentUserSelected(currentUser?._id === userId)
  }, [currentUser, userId])

  const loading = loadingCreate || loadingUpdate || loadingMove

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (loading || !data) return
    if (!userId) {
      setUserError('Обязательное поле')
      return
    }

    if (entity) {
      const dueDate = data.date && data.time ? new Date(`${data.date}T${data.time}`) : `${data.date}T00:00:00.000Z`
      const updateInput = {
        id: entity?.id,
        kind,
        userId,
        body: data.body,
        isDueDateTimeSpecified: dueDateTimeSpecified,
        isNotificationActivated: dueDateTimeSpecified ? notificationActivated : false,
        dueDate
      }

      if (entity?.dueDate && new Date(entity.dueDate).setSeconds(0, 0) === new Date(dueDate).setSeconds(0, 0)) {
        await updateTask({
          variables: {
            input: updateInput
          }
        })
          .then(() => {
            if (onUpdateTask) onUpdateTask()
          })
          .catch((err: ApolloError) => {
            handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
              setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
            })
          })
      } else {
        await moveTask({
          variables: {
            input: updateInput
          }
        })
          .then((res) => {
            if (onMoveTask)
              onMoveTask({ ...updateInput, id: res.data?.moveTask.id, originalTaskId: updateInput.id } as Task)
          })
          .catch((err: ApolloError) => {
            handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
              setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
            })
          })
      }
    } else {
      const createInput = {
        targetId,
        targetType,
        childTargetId,
        childTargetType,
        kind,
        userId,
        body: data.body,
        isDueDateTimeSpecified: dueDateTimeSpecified,
        isNotificationActivated: dueDateTimeSpecified ? notificationActivated : false,
        dueDate: dueDateTimeSpecified ? new Date(`${data.date}T${data.time}`) : `${data.date}T00:00:00.000Z`
      }

      await createTask({
        variables: {
          input: createInput
        }
      })
        .then((res) => {
          if (onCreateTask) onCreateTask(res?.data?.createTask as Task)
        })
        .catch((err: ApolloError) => {
          handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
            setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
          })
        })
    }
  }

  return (
    <section className='md: w-[448px] p-12'>
      <h1 className='mb-12 font-display text-h200'>{entity ? 'Редактирование задачи' : 'Новая задача'}</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='relative mb-12 flex flex-col gap-8'>
          <RadioButtonsGroup
            onChange={(value) => setKind(value as TaskKind)}
            options={[
              ['Задача', TaskKind.Task],
              ['Связаться', TaskKind.Contact],
              ['Встреча', TaskKind.Meeting]
            ]}
            checkedValue={kind}
          />
          <div className='relative'>
            <Select
              editable
              type='text'
              label='Исполнитель'
              autoComplete='off'
              onQuery={(query) => {
                setQuery(query)
              }}
              value={getFullName(users.find((user) => user._id === userId))}
              error={userError || errors.user?.message}
            >
              {list.map((user) => (
                <li
                  key={user._id}
                  onClick={() => {
                    if (!user.isActive) return
                    setUserId(user._id)
                    setUserError('')
                  }}
                  className={c(
                    'px-12 py-5 hover:bg-grayscale-450',
                    user.isActive ? 'cursor-pointer' : 'cursor-not-allowed'
                  )}
                >
                  <div className='flex items-center justify-between'>
                    <div className={c('mb-1', user.isActive ? 'text-grayscale-0' : 'text-grayscale-150')}>
                      <Highlighted
                        classMarkName='text-red-100 bg-transparent'
                        text={`${user?.name} ${user?.surname}${!user.isActive ? ' (Деактивирован)' : ''}`}
                        highlight={query}
                      />
                    </div>
                    {userId === user._id && (
                      <div className='ml-5 flex items-center rounded-full bg-red-100/5 px-5 py-2 text-red-100'>
                        <TickIcon />
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </Select>
            {!currentUserSelected && (
              <button
                type='button'
                className='absolute right-10 top-24 text-sm text-grayscale-150 hover:text-red-100'
                onClick={() => {
                  setUserId(currentUser?._id)
                }}
              >
                Назначить себе
              </button>
            )}
          </div>
          <div className='grid grid-cols-2 gap-10'>
            <Input
              label='Дата'
              type='date'
              {...register('date', {
                required: true,
                validate: (value) => {
                  if (new Date(value).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
                    return 'Не может быть ранее текущей даты'
                  }
                  if (new Date(value).getFullYear() - new Date().getFullYear() > 10) {
                    return 'Не более 10 лет от текущей даты'
                  }
                  return true
                }
              })}
              error={errors.date}
            />
            <MaskedInput
              label='Время'
              mask='time'
              autoComplete='off'
              placeholder='Необязательно'
              onAccept={(value) => setValue('time', value)}
              {...register('time', {
                minLength: {
                  value: 5,
                  message: 'Введите время в формате 00:00'
                },
                maxLength: {
                  value: 5,
                  message: 'Введите время в формате 00:00'
                }
              })}
              error={errors.time}
            />
          </div>
          <Switch.Group>
            <div className='flex items-center'>
              <Switch
                disabled={!dueDateTimeSpecified}
                checked={notificationActivated}
                onChange={(checked: boolean) => setNotificationActivated(checked)}
                className={c(
                  'relative mr-5 inline-flex h-12 w-12 items-center justify-center overflow-hidden rounded-lg border-grayscale-300 transition-colors focus:outline-none focus:ring-2 focus:ring-grayscale-400 focus:ring-offset-2',
                  notificationActivated ? 'bg-red-100' : 'border-1 bg-white-0'
                )}
              >
                <TickIcon
                  className={c(
                    'text-white-0 transition-transform',
                    notificationActivated ? 'translate-y-0' : 'translate-y-20'
                  )}
                />
              </Switch>
              <Switch.Label className={c('mr-5', !dueDateTimeSpecified && 'text-grayscale-200')}>
                Отправлять уведомления
              </Switch.Label>
            </div>
          </Switch.Group>
          <Textarea label='Комментарий' {...register('body', { required: true })} error={errors.body} />
        </div>

        <SubmitButton loading={loading}>
          {entity ? (
            <>
              <EditIcon className='mr-5' />
              Изменить
            </>
          ) : (
            <>
              <PlusIcon className='mr-5' />
              Создать
            </>
          )}
        </SubmitButton>
      </form>
    </section>
  )
}

export default TaskForm
