import { FC } from 'react'
import { BaseHistoryEventProps } from '../..'
import { EventActedAt, EventInitiatedBy } from '../EventComponents'
import UserName from '../../../UserName'

export interface SourceChangedEventProps extends BaseHistoryEventProps {
  data?: {
    user?: {
      before?: number
      after: number
    }
    bdmUser?: {
      before?: number
      after: number
    }
    commissionRewardRate?: {
      before?: number
      after: number
    }
  }
}

const SourceChangedEvent: FC<SourceChangedEventProps> = ({ data, actedAt, initiatedByUserId, withLine }) => {
  return (
    <>
      {(data?.user || data?.bdmUser) && (
        <div className='flex gap-6'>
          <EventActedAt date={actedAt} withLine={data.commissionRewardRate ? true : withLine} />
          <div>
            <div className='flex items-start gap-2'>
              <EventInitiatedBy userId={initiatedByUserId} />
              <span className='text-labels-tertiary'>изменил(а) ответственных менеджеров</span>
            </div>
            <div>
              {data?.user && (
                <>
                  Менеджер по лизингу:
                  <div className='flex gap-2'>
                    {data.user.before ? <UserName userId={data.user.before} /> : 'Не указан'}
                    →
                    <UserName userId={data?.user?.after} />
                  </div>
                </>
              )}
              {data?.bdmUser && (
                <>
                  Менеджер по развитию:
                  <div className='flex gap-2'>
                    {data.bdmUser.before ? <UserName userId={data.bdmUser.before} /> : 'Не указан'}
                    →
                    <UserName userId={data?.bdmUser?.after} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {data?.commissionRewardRate && (
        <div className='flex gap-6'>
          <EventActedAt date={actedAt} withLine={withLine} />
          <div>
            <div className='flex items-start gap-2'>
              <EventInitiatedBy userId={initiatedByUserId} />
              <span className='text-labels-tertiary'> заполнил(а) информацию о бонусах</span>
            </div>
            <div>
              Агентское вознаграждение:{' '}
              {data.commissionRewardRate.before ? `${data.commissionRewardRate.before}%` : 'Не указано'} →{' '}
              {data.commissionRewardRate.after}%
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SourceChangedEvent
