import { FC, useMemo } from 'react'
import { PaymentKind, PaymentState } from '../../../graphql/schema'
import Tooltip from '../../../components/Tooltip'
import { dateFormatter } from '../../../utils/dateFormatter'
import { formatMoney } from '../../../utils/formatNumber'
import { ReactComponent as CheckIcon } from '../../../svg/ui/check.svg'
import { overdueDays } from '../../../utils/payment'

interface PaymentCalendarProps {
  payments: CalendarPayment[]
}

export interface CalendarPayment {
  id: string
  date: string
  amount: number
  amountPaid: number
  kind: PaymentKind
  createdAt: string
  state: PaymentState
  isOverdued: boolean
  paidAt?: string
}

const baseDate = new Date(0)

const monthNames: string[] = []
for (let i = 0; i < 12; i++) {
  baseDate.setMonth(i)
  monthNames.push(baseDate.toLocaleString('ru', { month: 'short' }).replace('.', ''))
}

interface PaymentBadgeProps {
  paid: boolean
  daysOverdue: number
}

const PaymentBadge: FC<PaymentBadgeProps> = ({ paid, daysOverdue }) => {
  if (paid) {
    if (daysOverdue > 0) {
      // оплачено, но просрочено
      return <div className='rounded-md bg-tr-red p-2 text-body-m text-dark-red'>{daysOverdue}</div>
    } else {
      // оплачено вовремя
      return (
        <div className='flex h-full items-center justify-center rounded-md bg-tr-green p-2 text-body-m text-dark-green'>
          <CheckIcon className='m-1 text-labels-tertiary' />
        </div>
      )
    }
  }
  if (daysOverdue > 0) {
    // не оплачено и просрочено
    return <div className='rounded-md bg-base-red p-2 text-body-m text-base-white'>{daysOverdue}</div>
  }

  // ожидает оплаты
  return (
    <div className='text-labelster flex h-14 items-center justify-center rounded-md p-2 text-center text-body-m ring-1 ring-inset ring-separators-secondary' />
  )
}

const PaymentCalendar: FC<PaymentCalendarProps> = ({ payments }) => {
  const years = useMemo(() => {
    const years = new Set<number>()
    payments?.forEach((payment) => {
      years.add(new Date(payment.date).getFullYear())
    })
    return Array.from(years).sort()
  }, [payments])

  const PaymentBlocks = useMemo(() => {
    const today = new Date()

    const blocks = payments?.map((payment) => {
      const date = new Date(payment.date)

      const daysOverdue = overdueDays(payment, today)

      return (
        <div
          key={payment.id}
          className='cursor-pointer p-2 text-center font-mono tabular-nums text-labels-secondary'
          style={{ gridColumnStart: date.getMonth() + 1 }}
        >
          <Tooltip target={<PaymentBadge paid={payment.state === PaymentState.Paid} daysOverdue={daysOverdue} />}>
            <div>
              <div className='text-title-m font-medium'>
                {payment.state === PaymentState.Paid && daysOverdue === 0 && 'Оплачен вовремя'}
                {payment.state === PaymentState.Paid && daysOverdue > 0 && 'Оплачен с просрочкой'}
                {payment.state === PaymentState.Pending && daysOverdue === 0 && 'Ожидает оплаты'}
                {payment.state === PaymentState.Pending && daysOverdue > 0 && 'Просрочен и не оплачен'}
              </div>
              <div className='mb-5'>
                {payment.paidAt ? dateFormatter.format(new Date(payment.paidAt)) : dateFormatter.format(date)}
              </div>
              <div>{formatMoney(payment.amount)}</div>
            </div>
          </Tooltip>
        </div>
      )
    }, [])

    return blocks
  }, [payments])

  return (
    <div className=''>
      <div className='rounded-md bg-surface-primary shadow-card'>
        <div
          className='grid grid-cols-[auto_1fr]'
          style={{ gridTemplateRows: `auto 14px repeat(${years.length}, 1fr) 14px` }}
        >
          <div className='col-start-1 row-span-full row-start-3 grid grid-rows-subgrid text-labels-secondary'>
            {years.map((year) => (
              <div key={year} className='px-7 py-4 text-center'>
                {year}
              </div>
            ))}
          </div>
          <div className='col-start-2 row-start-1 grid grid-cols-12 grid-rows-subgrid px-7 text-labels-secondary'>
            {monthNames.map((month) => (
              <div key={month} className='py-4 text-center capitalize'>
                {month}
              </div>
            ))}
          </div>
          <div
            className='col-start-2 row-start-3 grid grid-cols-12 grid-rows-subgrid px-7'
            style={{
              gridRow: `3 / span ${years.length}`
            }}
          >
            {PaymentBlocks}
          </div>
          <div className='col-start-1 row-span-full border-r-1 border-separators-primary' />
          <div className='col-span-full row-start-1 border-b-1 border-separators-primary' />
        </div>
      </div>
    </div>
  )
}

export default PaymentCalendar
