import * as React from 'react'
import { ReactComponent as YesIcon } from '../svg/icons/circleCheck.svg'
import { ReactComponent as NoIcon } from '../svg/icons/circleCross.svg'

interface ConfirmationProps {
  children: React.ReactNode
  onDone: () => void
  onDismiss: () => void
  title?: string
  yesText?: string
  noText?: string
}

const ConfirmationForm: React.FC<ConfirmationProps> = ({
  children,
  onDone = () => {},
  onDismiss = () => {},
  title,
  yesText,
  noText
}) => {
  return (
    <section className='md: w-[448px] p-10'>
      {title && <h1 className='mb-10 text-h100 font-semibold'>{title}</h1>}
      <h1 className='mb-10 text-p100'>{children}</h1>
      <div className='flex gap-6'>
        <button
          onClick={() => onDone()}
          className='relative h-21 w-full overflow-hidden rounded-xl bg-grayscale-400 text-p200 font-semibold leading-full hover:bg-surface-tertiary active:bg-grayscale-300'
        >
          <div className='flex items-center justify-center font-medium'>
            <YesIcon className='mr-5 inline' height='15px' width='15px' title='Да' />
            {yesText || 'Да'}
          </div>
        </button>
        <button
          onClick={() => onDismiss()}
          className='relative h-21 w-full overflow-hidden rounded-xl bg-red-100 text-p200 font-semibold leading-full text-white-0 hover:bg-red-50 active:bg-red-150'
        >
          <div className='flex items-center justify-center font-medium'>
            <NoIcon className='mr-5 inline' height='15px' width='15px' title='Нет' />
            {noText || 'Нет'}
          </div>
        </button>
      </div>
    </section>
  )
}

export default ConfirmationForm
