import { FC, useState } from 'react'
import NestedSelectInput, { NestedSelectOption } from '../../components/NestedSelectInput'
import c from 'clsx'
import { TERMINATION_REASON_GROUP } from '../../utils/constants'
import { ReactComponent as WarningIcon } from '../../svg/icons/warning.svg'
import { ReactComponent as EditIcon } from '../../svg/ui/edit.svg'
import { DealStatusCircle, statusColorDict, statusTextColorDict } from '../../components/DealStatus'
import {
  DealStatus as Status,
  useDealStatusSelectQuery,
  useUpdateDealStatusMutation,
  useUpdateDealTerminationReasonMutation,
  DealInsuranceKind
} from '../../graphql/schema'
import { useDealSwitcherState } from './useDealSwitcherState'
import { dealStatusDict } from '../../utils/dictionaries'
import Tooltip from '../../components/Tooltip'
import Modal from '../../components/Modal'
import ConfirmationForm from '../../components/ConfirmationForm'
import { showPopup } from '../../components/Toaster/showPopup'
import { dateFormatter } from '../../utils/dateFormatter.ts'
import SuspenseModal from '../../components/SuspenseModal.tsx'
import TerminationDateForm from './TerminationDateForm.tsx'

const TERMINATION_REASON = TERMINATION_REASON_GROUP.flatMap((g) => g.name)

const TerminationReasion: FC<{ reason: string; active: boolean }> = ({ active, reason }) => (
  <div className='flex cursor-pointer items-center gap-5 px-9 py-5'>
    <div>
      <div
        className={c('h-10 w-10 rounded-full border-1 border-grayscale-300', active && 'border-[7px] border-red-100')}
      />
    </div>
    <div className='text-left font-medium'>{reason}</div>
  </div>
)

const DealStatusSwitcher: FC<{ id: number }> = ({ id }) => {
  const { data: dealData, loading: dealLoading } = useDealStatusSelectQuery({ variables: { id: `${id}` }, skip: !id })
  const deal = dealData?.deal
  const status = deal?.status

  const { switcherState, loading } = useDealSwitcherState(`${id}`, deal?.status)

  const [addDealTerminationReason, { loading: updateDealTerminationReasonLoading }] =
    useUpdateDealTerminationReasonMutation()
  const [updateDealStatus, { loading: updateDealStatusLoading }] = useUpdateDealStatusMutation()
  const [cancelFormOpen, setCancelFormOpen] = useState(false)
  const [terminationDateFormOpen, setTerminationDateFormOpen] = useState(false)

  const addTerminationReason = async (terminationReason: string) => {
    await addDealTerminationReason({
      variables: {
        input: {
          id: `${id}`,
          status: Status.Terminated,
          terminationReason
        }
      }
    })
  }

  const changeStatus = async (status: Status) => {
    if (!deal) return
    await updateDealStatus({
      variables: {
        id: `${id}`,
        status: status
      }
    }),
      () => {
        showPopup({
          title: 'Ошибка',
          subtitle: 'Не удалось изменить статус сделки'
        })
      }
  }

  const globalLoading = dealLoading || loading || updateDealStatusLoading || updateDealTerminationReasonLoading

  const terminationReasons = TERMINATION_REASON_GROUP.map((terminationReason) => ({
    name: terminationReason.name,
    node: (
      <TerminationReasion
        reason={terminationReason.name}
        active={deal?.status === Status.Terminated && deal?.terminationReason === terminationReason.name}
      />
    )
  }))

  const options: NestedSelectOption[] = switcherState
    .map((option) => {
      const current = option.state === status

      return {
        name: option.state,
        node: (
          <div
            onClick={() => {
              if (
                option.state === Status.Cancelled &&
                deal?.insuranceKind === DealInsuranceKind.WithInsurance &&
                !!deal?.insuranceContract
              ) {
                setCancelFormOpen(true)
              }
            }}
            className={c(
              'flex h-16 cursor-pointer items-center gap-5 px-5',
              current && 'pointer-events-none text-base-red',
              !current && !option.allowed && 'pointer-events-none hover:bg-surface-primary'
            )}
          >
            <DealStatusCircle
              status={option.state}
              bg={!current && !option.allowed}
              className={c(!current && !option.allowed && 'opacity-50')}
            />
            <div className={c('whitespace-nowrap', !current && !option.allowed && 'opacity-50')}>
              {dealStatusDict[option.state]}
            </div>

            {!!option?.messages?.length && (
              <Tooltip className='pointer-events-auto ml-auto' target={<WarningIcon className='text-base-red' />}>
                <div className='mb-2 font-display font-medium'>Чтобы продолжить, нужно:</div>
                <ul className='list-inside list-disc'>
                  {option.messages.map((message) => (
                    <li key={message}>{message}</li>
                  ))}
                </ul>
              </Tooltip>
            )}
          </div>
        ),
        disabled: !option.allowed,
        children: option.state === Status.Terminated ? terminationReasons : undefined
      }
    })
    .filter(Boolean) as NestedSelectOption[]

  return (
    <div className='flex gap-x-4'>
      <NestedSelectInput
        loading={globalLoading}
        className={c(
          'flex min-h-16 items-center gap-x-3 rounded-md px-5 py-3 text-body-m font-medium',
          status ? statusColorDict[status] : 'bg-surface-secondary',
          status ? statusTextColorDict[status] : 'text-labels-secondary'
        )}
        options={options}
        onSelect={async (status: string) => {
          if (TERMINATION_REASON.includes(status)) {
            await addTerminationReason(status)
          } else {
            await changeStatus(status as Status)
          }
        }}
      >
        {status && <DealStatusCircle status={status as Status} bg={true} />}
        {status && <div className='whitespace-nowrap'>{dealStatusDict[status]}</div>}
      </NestedSelectInput>

      {deal?.status === Status.Terminated && (
        <div className='flex items-center gap-3 rounded-md bg-tr-red px-5 py-3 font-medium text-dark-red'>
          {deal?.terminationDate ? dateFormatter.format(new Date(deal.terminationDate)) : ''}
          <button className='hover:text-red-100' onClick={() => setTerminationDateFormOpen(true)}>
            <EditIcon title='Редактировать' />
          </button>
        </div>
      )}
      {deal?.status === Status.Terminated && (
        <div className='rounded-md bg-tr-red px-5 py-3 font-medium text-dark-red'>{deal.terminationReason}</div>
      )}
      <Modal open={cancelFormOpen} setOpen={setCancelFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ConfirmationForm
            yesText='Точно аннулирован'
            noText='Ещё не аннулирован'
            onDone={async () => {
              setCancelFormOpen(false)
              await changeStatus(Status.Cancelled).catch((e) => {
                showPopup({
                  title: 'Ошибка',
                  subtitle: 'Не удалось аннулировать сделку'
                })
                console.error(e)
              })
            }}
            onDismiss={() => setCancelFormOpen(false)}
          >
            По данному договору был выпущен страховой полис. Для отмены сделки необходимо аннулировать полис
            страхования.
          </ConfirmationForm>
        </div>
      </Modal>
      <SuspenseModal open={terminationDateFormOpen} setOpen={setTerminationDateFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <TerminationDateForm dealId={id} onDone={() => setTerminationDateFormOpen(false)} />
        </div>
      </SuspenseModal>
    </div>
  )
}

export default DealStatusSwitcher
