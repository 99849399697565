import { FC, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { useBoVerificationsQuery, useContactByIdQuery } from '../../graphql/schema'
import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'
import EditContactForm from '../../components/Forms/EditContactForm'
import SuspenseModal from '../../components/SuspenseModal.tsx'
import useAccessCheck from '../../hooks/useAccessCheck.ts'
import PhoneNumber from '../../components/PhoneNumber.tsx'
import {
  EntityBar,
  EntityBarItem,
  EntityHeader,
  EntityInfo,
  EntityName,
  EntityNav,
  EntityNavItem
} from '../../components/EntityPage/EntityHeader.tsx'
import PrimaryButton from '../../components/UI/PrimaryButton.tsx'
import { EntityPage } from '../../components/EntityPage/EntityPage.tsx'

const Contact: FC = () => {
  const { id } = useParams<'id'>()

  const [editContactId, setEditContactId] = useState<number>()

  const { data, refetch, loading } = useContactByIdQuery({ variables: { id: id?.toString() as string }, skip: !id })
  const contact = data?.contact

  const hasAccessBkiView = useAccessCheck('contact.bki.view')
  const hasAccessEditContactAfterAccepted = useAccessCheck('contact.edit_after_accepted')

  const { data: boVerificationsData } = useBoVerificationsQuery({
    variables: { contactId: parseInt(id!), states: ['completed', 'rejected', 'accepted'] },
    skip: !id
  })
  const boVerification = boVerificationsData?.boVerifications[0]
  const canEdit = boVerification?.state === 'ACCEPTED' ? hasAccessEditContactAfterAccepted : true

  const acceptExpiresAt =
    contact?.verificationAcceptExpiresAt && new Date() > new Date(contact.verificationAcceptExpiresAt)

  if (!contact) return null

  return (
    <div className='flex-grow'>
      <EntityHeader>
        <div className='mb-6 flex items-start justify-between'>
          <div>
            <EntityInfo>Контакт</EntityInfo>
            <EntityName value={contact.fio} loading={loading} />
          </div>
          <div className='flex flex-none gap-x-4'>
            {!loading && canEdit && (
              <PrimaryButton onClick={() => setEditContactId(contact._id)}>
                <EditIcon title='Редактировать' />
                Редактировать
              </PrimaryButton>
            )}
          </div>
        </div>
        <div className='mb-12 flex gap-x-4'>
          {(() => {
            switch (contact?.verificationStatus) {
              case 'accepted':
                if (acceptExpiresAt)
                  return <div className='rounded-md bg-tr-red px-5 py-3 text-dark-red'>Верификация просрочена</div>
                return <div className='rounded-md bg-tr-green px-5 py-3 text-dark-green'>Проверен</div>
              case 'rejected':
                return <div className='rounded-md bg-tr-red px-5 py-3 text-dark-red'>Отказано</div>
              case 'completed':
                return <div className='rounded-md bg-tr-yellow px-5 py-3 text-dark-yellow'>Ожидает проверки</div>
            }
          })()}
        </div>
        <EntityBar>
          <EntityBarItem title='Телефон' loading={loading}>
            <PhoneNumber number={contact.phone} />
          </EntityBarItem>
          <EntityBarItem title='E-mail' loading={loading}>
            <a href={`mailto:${contact.email}`} target='_blank' rel='noopener noreferrer'>
              {contact.email}
            </a>
          </EntityBarItem>
        </EntityBar>
        <EntityNav>
          <EntityNavItem title='KYC' to='' />
          <EntityNavItem title='Документы' to='docs' />
          <EntityNavItem title='Заявки' to='applications' />
          {hasAccessBkiView && <EntityNavItem title='Кредитная информация' to='bki' />}
        </EntityNav>
      </EntityHeader>
      <SuspenseModal open={!!editContactId} setOpen={() => setEditContactId(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <EditContactForm
            onDone={() => {
              refetch()
              setEditContactId(undefined)
            }}
            id={editContactId as number}
          />
        </div>
      </SuspenseModal>
      <EntityPage>
        <Outlet context={[refetch]} />
      </EntityPage>
    </div>
  )
}

export default Contact
