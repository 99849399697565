type Company = {
  name: {
    short_with_opf?: string
    full_with_opf?: string
  }
}

export default function getCompanyName(company: Company | undefined): string {
  if (company) {
    if (company.name?.short_with_opf) {
      return company.name.short_with_opf
    }
    if (company.name?.full_with_opf) {
      return company.name.full_with_opf
    }
  }
  return ''
}
