export default function downloadAndSaveFile(url: string, fileName: string): Promise<void> {
  return fetch(url)
    .then(async (response) => {
      if (response.ok) {
        return response.blob()
      }
      const errors = (await response.json()).errors
      if (typeof errors === 'string') {
        throw new Error('Network response was not ok.')
      }
      throw new Error(JSON.stringify(errors))
    })
    .then((blob: Blob) => {
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    })
}
