import { FC, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useFundingSourceQuery, useFundingTranchesQuery } from '../../graphql/schema'
import { formatMoneyDecimal } from '../../utils/formatNumber'
import GridView, { GridViewConfig } from '../../components/GridView/index'
import useNodes from '../../hooks/useNodes'
import { FundingTranche } from '../Deal/Finances/index'
import useScrollPagination from '../../hooks/useScrollPagination'
import { Card, CardHeader, CardTitle } from '../../components/Card'

const Info: FC = () => {
  const { id } = useParams<'id'>()
  const { data } = useFundingSourceQuery({ variables: { id: `${id}` }, skip: !id })
  const fundingSource = data?.fundingSource

  const {
    data: fundingTranchesData,
    loading,
    error,
    fetchMore
  } = useFundingTranchesQuery({
    variables: { fundingSourceId: `${id}` },
    skip: !id
  })
  const fundingTranches = useNodes(fundingTranchesData?.fundingTranches?.edges)
  const { wrapperRef, isFetching } = useScrollPagination(fetchMore, fundingTranchesData?.fundingTranches?.pageInfo)

  const fundingTranchesTableConfig = useMemo<GridViewConfig<FundingTranche>>(
    () => ({
      grid: 'grid-cols-auto-3',
      columns: [
        { title: '№ транша', value: 'number', numeric: true },
        { title: 'Сделка', value: 'dealId', numeric: true },
        {
          title: 'Сумма',
          value: (t) => <div className='text-right'>{formatMoneyDecimal(t.amount)}</div>,
          numeric: true
        }
      ],
      rows: {
        link: (t) => `/deals/${t?.dealId}/finances`
      }
    }),
    []
  )

  if (!fundingSource) return null

  return (
    <div className='grid grid-cols-3 gap-6'>
      <div className='col-span-1 flex flex-col gap-6'>
        <Card>
          <CardHeader>
            <CardTitle>Сумма траншей</CardTitle>
          </CardHeader>
          <div className='px-5 pb-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              <div className='ml-5 py-5 font-mono'>{formatMoneyDecimal(fundingSource.fundingTranchesAmountSum)}</div>
            </div>
          </div>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Транши</CardTitle>
          </CardHeader>
          <div className='px-5 pb-5' ref={wrapperRef}>
            <GridView
              config={fundingTranchesTableConfig}
              data={fundingTranches}
              loading={loading || isFetching}
              error={error?.message}
            />
          </div>
        </Card>
      </div>
    </div>
  )
}

export default Info
