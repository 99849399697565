import { FC } from 'react'
import usePersonAddress from '../../../../src/hooks/usePersonAddress'

interface RegAddressFromVerificationsProps {
  inn: string
  contactsList: {
    __typename: string
    _id: number
    verificationStatus: string
  }[]
  onSetAddress: (value: string) => void
}

const RegAddressFromVerifications: FC<RegAddressFromVerificationsProps> = ({ inn, contactsList, onSetAddress }) => {
  const [getContactRegAddress, addressLoading, addressError] = usePersonAddress()

  return (
    <>
      {addressLoading ? (
        <span className='text-xs text-grayscale-250'>Загрузка...</span>
      ) : addressError ? (
        <span className='text-xs text-red-150'>Данные не найдены</span>
      ) : (
        <button
          className='text-xs flex-none text-red-50 underline decoration-dashed w-fit'
          type='button'
          onClick={async () => {
            const address = await getContactRegAddress(contactsList, inn)
            onSetAddress(address || '')
          }}
        >
          Заполнить из верификации
        </button>
      )}
    </>
  )
}

export default RegAddressFromVerifications
