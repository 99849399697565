import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { DocumentTypeEntity } from '../../graphql/schema'
import DocumentManager from '../../components/DocumentManager'

const Documents: FC = () => {
  const { id } = useParams<'id'>()

  if (!id) return null

  return <DocumentManager entityId={parseInt(id)} entityType={DocumentTypeEntity.GuarantorCompany} />
}

export default Documents
