import { FieldError } from 'react-hook-form'

const defaultErrorTypeMessages: Record<string, string> = {
  required: 'Обязательное поле'
}

export function errorToString(error?: string | FieldError): string | undefined {
  if (typeof error === 'string') {
    return error
  }
  if (error?.message) {
    return error.message
  }
  if (error?.type) {
    if (error.type in defaultErrorTypeMessages) return defaultErrorTypeMessages[error.type]
    return error.type
  }
}
