import * as React from 'react'
import { Guarantor, GuarantorStatus, useUpdateGuarantorMutation } from '../../../graphql/schema'
import { SubmitHandler, useForm } from 'react-hook-form'
import SubmitButton from '../../../components/SubmitButton'
import Input from '../../../components/Input'
import RadioButtonsGroup from '../../../components/RadioButtonsGroup'

interface GuarantorFormProps {
  onDone: () => void
  guarantor: Guarantor
}

type Inputs = {
  status: string
  signed: string
}

const EditGuarantorForm: React.FC<GuarantorFormProps> = ({ guarantor, onDone }) => {
  const [updateGuarantor, { loading }] = useUpdateGuarantorMutation()

  const { register, handleSubmit, watch, setValue } = useForm<Inputs>({
    defaultValues: {
      status: guarantor?.status,
      signed: (guarantor?.status === GuarantorStatus.Signed ? new Date(guarantor.signed!) : new Date())
        .toISOString()
        .split('T')
        .shift()
    }
  })

  const status = watch('status') as GuarantorStatus

  const onSubmit: SubmitHandler<Inputs> = async () => {
    if (!guarantor) return

    await updateGuarantor({
      variables: {
        input: {
          status: status,
          dealId: guarantor.deal._id.toString(),
          contactId: guarantor.contact?._id.toString()!,
          signMethod: guarantor.signMethod!,
          id: guarantor._id.toString()
        }
      }
    }).then(() => onDone())
  }

  if (!guarantor) return null

  return (
    <section className='md: w-[448px] p-12'>
      <h1 className='font-display text-h200 mb-12'>Редактирование поручителя</h1>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 flex flex-col gap-12 relative'>
          <RadioButtonsGroup
            onChange={(value) => setValue('status', value)}
            options={[
              ['Подписан', GuarantorStatus.Signed],
              ['Направлен', GuarantorStatus.NotSigned]
            ]}
            checkedValue={status}
            label='Статус договора'
          />
          {status === GuarantorStatus.Signed && (
            <Input readOnly label='Дата подписания' type='date' {...register('signed')} />
          )}
        </div>
        <SubmitButton loading={loading}>Сохранить</SubmitButton>
      </form>
    </section>
  )
}

export default EditGuarantorForm
