import { FC, useEffect, useMemo, useState } from 'react'
import { NestedValue, NonUndefined, SubmitHandler, useForm } from 'react-hook-form'
import { SourceByIdQuery, useCategoriesQuery, useUpdateSourceMutation } from '../../graphql/schema'
import { ApolloError } from '@apollo/client'
import useNodes from '../../hooks/useNodes'
import { handleBackendErrorsToForm } from '../../utils/backendErrorUtils'
import SubmitButton from '../../components/SubmitButton'
import GroupSelectInput, { Option } from '../../components/GroupSelectInput'
import { NodeType } from '../../types'

import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'

type Source = NonUndefined<SourceByIdQuery['source']>
type LeasingSubjectCategory = NodeType<Source['leasingSubjectCategories']>
type Inputs = {
  id: string
  leasingSubjectCategories: NestedValue<LeasingSubjectCategory[]>
}

interface CategoriesFormProps {
  onDone?: () => void
  source: Source
}

const CategoriesForm: FC<CategoriesFormProps> = ({ onDone, source }) => {
  const [updateSource, { loading }] = useUpdateSourceMutation()

  const { handleSubmit, formState, setError } = useForm<Inputs>()

  const [selectedCategories, setSelectedCategories] = useState<Option[]>([])

  const { data: categoriesData } = useCategoriesQuery()
  const categories = useNodes(categoriesData?.leasingSubjectCategories?.edges)
  const options = useMemo(
    () =>
      categories?.map((cat) => ({
        name: cat.name,
        value: cat._id,
        group: cat.categoryGroup?.name
      })) || [],
    [categories]
  )

  const defaultLeasingSubjectCategories = useMemo(
    () => source?.leasingSubjectCategories?.edges?.map((l) => l?.node) as LeasingSubjectCategory[],
    [source?.leasingSubjectCategories]
  )
  useEffect(() => {
    setSelectedCategories(
      options?.filter((option) => defaultLeasingSubjectCategories?.map((cat) => cat._id).includes(option.value)) || []
    )
  }, [options, defaultLeasingSubjectCategories])

  const onSubmit: SubmitHandler<Inputs> = async () => {
    await updateSource({
      variables: {
        input: {
          id: source._id.toString(),
          leasingSubjectCategories: selectedCategories.map((lc) => lc.value.toString())
        }
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  return (
    <section className='md: w-[448px] p-12'>
      <h1 className='font-display text-h200 mb-12'>Изменение категорий</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 flex flex-col gap-8 relative'>
          <div>
            <GroupSelectInput
              label='Категории источника'
              placeholder='Выбрать категории'
              options={options}
              selectedOptions={selectedCategories}
              onChange={setSelectedCategories}
              error={formState.errors.leasingSubjectCategories?.message}
            />
          </div>
        </div>
        <SubmitButton loading={loading}>
          <EditIcon className='mr-5' />
          Изменить
        </SubmitButton>
      </form>
    </section>
  )
}

export default CategoriesForm
