import { FC } from 'react'
import RadioButtonsGroup from '../../components/RadioButtonsGroup'
import {
  DocumentTypeEntity,
  useDocumentsQuery,
  useSupplierRetrobonusesQuery,
  useUpdateSupplierRetrobonusesMutation
} from '../../graphql/schema'
import { useParams } from 'react-router-dom'
import EntityFolder from '../../components/DocumentManager/EntityFolder'
import useNodes from '../../hooks/useNodes'
import { showPopup } from '../../components/Toaster/showPopup'
import { Card, CardHeader, CardTitle } from '../../components/Card'

const RETROBONUS_FOLDER_NAME = 'Договор ретробонуса'

const Retrobonuses: FC = () => {
  const { id } = useParams<'id'>()
  const { data } = useSupplierRetrobonusesQuery({
    variables: { id: id as string },
    skip: !id
  })
  const [updateSupplierRetrobonuses] = useUpdateSupplierRetrobonusesMutation()

  const supplier = data?.supplierCompany

  const { data: documentsData } = useDocumentsQuery({
    variables: {
      filter: {
        entityId: id as string,
        entityType: DocumentTypeEntity.SupplierCompany,
        type: RETROBONUS_FOLDER_NAME
      }
    },
    skip: !id
  })

  const documents = useNodes(documentsData?.documents?.edges)

  const handleHasRetrosChange = (value: string) => {
    if (!id) return
    const hasRetrobonus = value === 'yes'
    // В случае, если документов нет и параметр ретробонуса выключен, его нельзя включить без загрузки документов
    if (documents.length == 0 && hasRetrobonus) {
      showPopup({ title: 'Ошибка', subtitle: 'Невозможно включить, так как не загружены документы' })
      return
    }
    updateSupplierRetrobonuses({
      variables: {
        input: {
          id,
          hasRetrobonus
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateSupplierCompany: {
          __typename: 'updateSupplierCompanyPayload',
          supplierCompany: {
            __typename: 'SupplierCompany',
            _id: Number(id),
            hasRetrobonus
          }
        }
      }
    })
  }

  return (
    <div className='grid grid-cols-2 gap-6'>
      <Card>
        <CardHeader>
          <CardTitle>Ретробонусы</CardTitle>
        </CardHeader>
        <div className='px-5 pb-5'>
          <div className='rounded-md bg-surface-primary p-6 shadow-card'>
            <RadioButtonsGroup
              onChange={handleHasRetrosChange}
              options={[
                ['С ретробонусом', 'yes'],
                ['Без ретробонуса', 'no']
              ]}
              checkedValue={supplier?.hasRetrobonus ? 'yes' : 'no'}
            />
            <div className='mt-10'>
              <EntityFolder
                entityId={parseInt(id || '')}
                entityType={'supplier_company' as DocumentTypeEntity}
                folderName={RETROBONUS_FOLDER_NAME}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default Retrobonuses
