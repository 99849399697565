import { FC } from 'react'
import * as React from 'react'
import c from 'clsx'
import { ReactComponent as PlusIcon } from '../../svg/icons/plus.svg'

interface SearchProps {
  onClick: () => void
  children: React.ReactNode
  className?: string
  disable?: boolean
}

const AddButton: FC<SearchProps> = ({ onClick, children, className, disable = false }) => {
  return (
    <button
      onClick={onClick}
      className={c(
        'h-25 border-1 border-grayscale-300 border-dashed rounded-md flex items-center justify-center w-full text-grayscale-250 hover:text-grayscale-200 disabled:text-grayscale-250',
        className
      )}
      disabled={disable}
    >
      <PlusIcon className='mr-5' />
      <span>{children}</span>
    </button>
  )
}

export default AddButton
