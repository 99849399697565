import { FC } from 'react'
import { useContactFilter } from './FilterForm'
import SelectedFilter from '../../components/Filter/SelectedFilter'
import { verificationStateDict } from '../../utils/dictionaries'
import { VerificationState } from '../../graphql/schema'

const SelectedFilters: FC = () => {
  const { filterState, setFilterState, filterActive } = useContactFilter()

  if (!filterActive || filterState?.query?.length) return null

  return (
    <div className='flex flex-wrap gap-2 mb-8'>
      {Object.keys(filterState).map((key) => {
        //@ts-ignore
        const objValue = filterState[key]
        switch (key) {
          case 'verificationStatus':
            return (
              objValue && (
                <SelectedFilter
                  key={key}
                  onRemove={() =>
                    setFilterState({
                      ...filterState,
                      [key]: undefined
                    })
                  }
                >
                  {verificationStateDict[objValue as VerificationState]}
                </SelectedFilter>
              )
            )
        }
      })}
    </div>
  )
}

export default SelectedFilters
