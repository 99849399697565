import { FC, useState } from 'react'
import {
  AttachmentCategory,
  PersonInfo,
  PersonInfoGender,
  useUpdateVerificationMutation
} from '../../../graphql/schema'
import { SubmitHandler, useForm } from 'react-hook-form'
import Input from '../../../components/Input'
import { ReactComponent as EditIcon } from '../../../svg/icons/edit.svg'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils'
import { ApolloError } from '@apollo/client'
import SubmitButton from '../../../components/SubmitButton'
import RadioButtonsGroup from '../../../components/RadioButtonsGroup'
import MaskedInput from '../../../components/MaskedInput'
import Checkbox from '../../../components/Forms/Checkbox.tsx'
import { IDocument } from '../../../components/DocumentManager/Document.tsx'
import VerificationDocumentPreview from './VerificationDocumentPreview.tsx'
import Textarea from '../../../components/Textarea.tsx'

interface PersonInfoUpdateInputs {
  firstName: string
  lastName: string
  middleName: string
  gender: PersonInfoGender
  birthDate: string
  birthPlace: string
  passportSeriesAndNumber: string
  passportIssueId: string
  passportIssueDate: string
  passportIssuedBy: string
  regAddress: string
  regAddressSameAsFact: boolean
  factAdress: string
}

const EditPersonInfoForm: FC<{
  uuid: string
  personInfo: PersonInfo
  onDone: () => void
  attachments: (IDocument & { category?: AttachmentCategory })[]
}> = ({ uuid, personInfo, onDone, attachments }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm<PersonInfoUpdateInputs>({
    defaultValues: {
      firstName: personInfo?.firstName,
      lastName: personInfo?.lastName,
      middleName: personInfo?.middleName,
      birthDate: personInfo?.birthDate && new Date(personInfo?.birthDate).toISOString().split('T').shift(),
      birthPlace: personInfo?.birthPlace,
      passportSeriesAndNumber: personInfo?.passportSeriesAndNumber,
      passportIssueId: personInfo?.passportIssueId,
      passportIssueDate:
        personInfo?.passportIssueDate && new Date(personInfo?.passportIssueDate).toISOString().split('T').shift(),
      passportIssuedBy: personInfo?.passportIssuedBy,
      regAddress: personInfo?.regAddress,
      factAdress: personInfo?.regAddressSameAsFact ? personInfo?.regAddress : personInfo?.factAdress
    }
  })
  const [updateVerification, { loading }] = useUpdateVerificationMutation()

  const [gender, setGender] = useState(personInfo.gender as string)
  const [regAddressSameAsFact, setRegAddressSameAsFact] = useState(personInfo?.regAddressSameAsFact)

  const onSubmit: SubmitHandler<PersonInfoUpdateInputs> = async (data) => {
    if (!uuid) return

    await updateVerification({
      variables: {
        uuid,
        input: {
          personInfo: {
            ...data,
            gender: gender as PersonInfoGender,
            fullName: `${data?.lastName} ${data?.firstName} ${data?.middleName}`.trim(),
            regAddressSameAsFact,
            factAdress: regAddressSameAsFact ? data.regAddress : data.factAdress
          }
        }
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<PersonInfoUpdateInputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  const passportMain = attachments.find((a) => a.category === AttachmentCategory.PassportMain)
  const passportRegistration = attachments.find((a) => a.category === AttachmentCategory.PassportRegistration)

  return (
    <section className='w-[958px] p-12 lg:w-[1200px]'>
      <h1 className='mb-12 font-display text-h200'>Редактирование паспортных данных</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 grid grid-cols-2 gap-20'>
          <div>{passportMain && <VerificationDocumentPreview docs={[passportMain]} className='max-h-[765px]' />}</div>

          <div className='flex flex-col gap-12'>
            <div className='rounded-xl bg-grayscale-450 px-8 py-6'>
              <div className='mb-10 font-semibold leading-5'>Паспорт выдан</div>

              <div className='relative flex flex-col gap-8'>
                <Textarea
                  labelClassName='text-p200 leading-5'
                  {...register('passportIssuedBy')}
                  error={errors.passportIssuedBy}
                />
                <div className='flex items-center justify-between gap-20'>
                  <div className='flex-1'>
                    <Input
                      label='Дата выдачи'
                      labelClassName='text-p200 leading-5'
                      type='date'
                      {...register('passportIssueDate')}
                      error={errors.passportIssueDate}
                    />
                  </div>
                  <div className='flex-1'>
                    <Input
                      label='Код подразделения'
                      labelClassName='text-p200 leading-5'
                      type='text'
                      placeholder='780-040'
                      {...register('passportIssueId')}
                      error={errors.passportIssueId}
                    />
                  </div>
                </div>
                <MaskedInput
                  label='Серия/Номер'
                  labelClassName='text-p200 leading-5'
                  type='text'
                  placeholder='0000 000000'
                  mask='0000 000000'
                  {...register('passportSeriesAndNumber')}
                  error={errors.passportSeriesAndNumber}
                />
              </div>
            </div>

            <div className='rounded-xl bg-grayscale-450 px-8 py-6'>
              <div className='mb-10 font-semibold leading-5'>Личность владельца</div>

              <div className='relative flex flex-col gap-8'>
                <Input
                  labelClassName='text-p200 leading-5'
                  type='text'
                  placeholder='Иванов'
                  {...register('lastName')}
                  error={errors.lastName}
                />
                <div className='flex items-center justify-between gap-20'>
                  <div className='flex-1'>
                    <Input
                      labelClassName='text-p200 leading-5'
                      type='text'
                      placeholder='Иван'
                      {...register('firstName')}
                      error={errors.firstName}
                    />
                  </div>
                  <div className='flex-1'>
                    <Input
                      labelClassName='text-p200 leading-5'
                      type='text'
                      placeholder='Иванович'
                      {...register('middleName')}
                      error={errors.middleName}
                    />
                  </div>
                </div>
                <div className='flex items-center justify-between gap-20'>
                  <div className='flex-1'>
                    <RadioButtonsGroup
                      onChange={(value) => setGender(value)}
                      options={[
                        ['Мужской', PersonInfoGender.Male],
                        ['Женский', PersonInfoGender.Female]
                      ]}
                      checkedValue={gender}
                      label='Пол'
                      labelClassName='text-p200 leading-5'
                    />
                  </div>
                  <div className='flex-1'>
                    <Input
                      label='Дата рождения'
                      labelClassName='text-p200 leading-5'
                      type='date'
                      {...register('birthDate')}
                      error={errors.birthDate}
                    />
                  </div>
                </div>
                <Textarea
                  label='Место рождения'
                  labelClassName='text-p200 leading-5'
                  {...register('birthPlace')}
                  error={errors.birthPlace}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='mb-12 grid grid-cols-2 gap-20'>
          <div>
            {passportRegistration && (
              <VerificationDocumentPreview docs={[passportRegistration]} className='max-h-[765px]' />
            )}
          </div>

          <div>
            <div className='rounded-xl bg-grayscale-450 px-8 py-6'>
              <div className='mb-10 font-semibold leading-5'>Место жительства</div>

              <div className='relative flex flex-col gap-8'>
                <div className='flex flex-col gap-4'>
                  <Textarea
                    label='Адрес регистрации'
                    labelClassName='text-p200 leading-5'
                    {...register('regAddress')}
                    error={errors.regAddress}
                  />
                  <Checkbox
                    label='Место регистрации совпадает с фактическим местом проживания'
                    labelClassName='text-p200 leading-5'
                    checked={regAddressSameAsFact}
                    onChange={(checked) => setRegAddressSameAsFact(checked)}
                  />
                </div>
                {!regAddressSameAsFact && (
                  <Textarea
                    label='Адрес проживания'
                    labelClassName='text-p200 leading-5'
                    {...register('factAdress')}
                    error={errors.factAdress}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <SubmitButton loading={loading}>
          <EditIcon className='mr-5' />
          Изменить
        </SubmitButton>
      </form>
    </section>
  )
}

export default EditPersonInfoForm
