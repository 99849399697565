import { useMemo } from 'react'
import { DocumentTypeEntity, useDocumentTypesQuery } from '../../graphql/schema'

const useDocumentTypes = (type?: DocumentTypeEntity) => {
  const { data: docTypesData } = useDocumentTypesQuery()

  const docTypes = useMemo(() => {
    const types = docTypesData?.documentTypes?.edges?.map((edge) => {
      return edge?.node
    })
    if (type) {
      return types?.filter((t) => t?.entity === type).reverse()
    }
    return types
  }, [docTypesData, type])

  return docTypes
}

export default useDocumentTypes
