import { Menu, MenuButton, MenuItems, Transition } from '@headlessui/react'
import c from 'clsx'
import { FC, useRef, useCallback, Children, ReactElement, cloneElement } from 'react'
import { ReactComponent as EllipsisIcon } from '../../svg/icons/ellipsis.svg'

interface DropdownProps {
  children: JSX.Element | JSX.Element[]
  className?: string
}

const Dropdown: FC<DropdownProps> = ({ children, className }) => {
  // это воркераунд чтобы закрыть меню, если пункт меню вызывает асинхронную задачу
  // временное решение, пока Headless UI не позволят програмно открывать/закрывать меню

  const closeRef = useRef<HTMLButtonElement>(null)
  const detectorRef = useRef<HTMLDivElement>(null)
  const closeMenu = useCallback(() => {
    // если detectorRef отрендерен, значит меню открыто
    if (detectorRef.current && closeRef.current) {
      closeRef.current.click()
    }
  }, [])

  return (
    <Menu as='div' className='relative'>
      <MenuButton
        className={c('flex h-10 items-center justify-center hover:text-red-100', className)}
        as='button'
        ref={closeRef}
      >
        {({ open }) => (
          <div className='flex h-full items-center text-grayscale-200 hover:text-red-100'>
            <EllipsisIcon className={c(open && 'text-red-100')} />
          </div>
        )}
      </MenuButton>
      <Transition
        as='div'
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <MenuItems
          as='div'
          className='bg-white absolute right-0 z-50 mt-2 origin-top-right rounded-lg bg-white-0 p-2 shadow-lg focus:outline-none'
        >
          <div ref={detectorRef}></div>

          {Children.map(children, (child: ReactElement) => {
            const childClone = cloneElement(child, {
              onClick: async (e: React.PointerEvent) => {
                e.preventDefault()
                await child.props?.onClick()
                closeMenu()
              }
            })
            return <Menu.Item as='div'>{childClone}</Menu.Item>
          })}
        </MenuItems>
      </Transition>
    </Menu>
  )
}

export default Dropdown
