import { FC } from 'react'
import { dateFormatter } from '../utils/dateFormatter'
import GridView, { GridViewConfig } from './GridView'
import AvatarTooltip from './Avatar/AvatarTooltip'
import { AppStatus, ApplicationStatusBadge } from './ApplicationStatus'
import Tooltip from './Tooltip'

// минимальный набор полей для отображения в таблице
export interface ApplicationsListApplication {
  _id?: number
  created?: string
  status?: string
  customerCompany?: {
    fullWithOpf?: string
    shortWithOpf?: string
    name?: string
  }
  source?: {
    name?: string
  }
  user?: {
    _id: number
  }
  lossReason?: string
}

const appTableConfig: GridViewConfig<ApplicationsListApplication> = {
  grid: 'grid-cols-application',
  columns: [
    { title: 'ID', value: '_id', numeric: true },
    {
      title: 'Компания',
      value: (a) => <span className='line-clamp-1'>{a?.customerCompany?.shortWithOpf}</span>
    },
    { title: 'Источник', value: (a) => <span className='line-clamp-1'>{a?.source?.name}</span> },
    { title: 'Cоздано', value: (a) => dateFormatter.format(new Date(a?.created || '')), numeric: true },
    {
      title: 'МЛ',
      titleClassName: 'text-center',
      padding: false,
      value: (a) => <AvatarTooltip className='mx-auto' userId={a?.user?._id} />
    },
    {
      title: 'Статус',
      padding: false,
      value: (a) => (
        <div className='flex px-[5px]'>
          <Tooltip target={<ApplicationStatusBadge status={a.status} />}>
            {a.status === AppStatus.Closed && a.lossReason}
          </Tooltip>
        </div>
      )
    }
  ],
  rows: {
    link: (a) => `/applications/${a?._id}`
  }
}

interface ApplicationsListProps {
  loading: boolean
  applicationsList: ApplicationsListApplication[]
  error?: string
}

const ApplicationsList: FC<ApplicationsListProps> = ({ loading, applicationsList, error }) => {
  return <GridView data={applicationsList} config={appTableConfig} loading={loading} error={error} />
}

export default ApplicationsList
