import { suspend } from 'suspend-react'
import { HTTP_METHODS, downloadFile } from '../utils/downloadFile'

const useSuspenseDocument = (url?: string, type: HTTP_METHODS = 'POST', mimetype?: string) => {
  const file = suspend(async () => {
    if (!url) return
    const blob = await downloadFile(url, type)
    return new File([blob], url, { type: mimetype || blob.type || 'application/octet-stream' })
  }, [url])

  return file
}

export default useSuspenseDocument
