import { FC, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Tooltip from '../../../components/Tooltip'
import ConfirmationForm from '../../../components/ConfirmationForm'
import SupplyPaymentForm from './SupplyPaymentForm'
import SupplyForm from './SupplyForm'
import { NodeType } from '../../../types'
import c from 'clsx'
import EntityFolder from '../../../components/DocumentManager/EntityFolder'
import Document from '../../../components/DocumentManager/Document'
import Checkbox from '../../../components/Forms/Checkbox'
import { ReactComponent as PayIcon } from '../../../svg/icons/payout.svg'
import { ReactComponent as WarningIcon } from '../../../svg/icons/warning.svg'
import { ReactComponent as TrashIcon } from '../../../svg/ui/delete.svg'
import { ReactComponent as TickIcon } from '../../../svg/icons/tick-md.svg'
import { ReactComponent as EditIcon } from '../../../svg/ui/edit.svg'
import { ReactComponent as NewDocIcon } from '../../../svg/ui/new-doc.svg'
import Modal from '../../../components/Modal'
import {
  BankOrderState,
  BankOrderTargetType,
  DealSuppliesQuery,
  DealSupplyMode,
  DocumentTypeEntity,
  PaymentKind,
  PaymentState,
  PaymentTargetType,
  useBankOrdersQuery,
  useDealRatesForSupplyQuery,
  usePaymentsQuery,
  useRemoveDealSupplyMutation,
  useUpdateDealSupplyFlagsMutation
} from '../../../graphql/schema'
import useEntityDocuments from '../../../components/DocumentManager/useEntityDocuments'
import useNodes from '../../../hooks/useNodes'
import { formatMoneyDecimal } from '../../../utils/formatNumber'
import Hint from '../../../components/Hint'
import {
  SHIPPING_FOLDER_NAME,
  SPECIFICATIONS_FOLDER_NAME,
  SUPPLY_FOLDER_NAME,
  TRANSFER_FOLDER_NAME
} from '../../../config'
import { dateFormatter } from '../../../utils/dateFormatter'
import Spec from './Spec'
import SuspenseModal from '../../../components/SuspenseModal'
import useAccessCheck from '../../../hooks/useAccessCheck.ts'
import { getSpecTotals } from './parseSpec.ts'
import BankOrders from '../../../components/BankOrders/BankOrders.tsx'
import TransferActForm from './TransferActForm.tsx'
import SupplyOverdueTooltip from '../../../components/SupplyOverdueTooltip.tsx'

interface SupplyProps {
  dealId: number
  supply: NodeType<NonNullable<DealSuppliesQuery['deal']>['dealSupplies']>
}

const Supply: FC<SupplyProps> = ({ supply, dealId }) => {
  const [supplyPaymentFormOpen, setSupplyPaymentFormOpen] = useState<boolean>(false)
  const [transferActFormOpen, setTransferActFormOpen] = useState<boolean>(false)
  const [step, setStep] = useState<number | undefined>()

  const frameMode = supply.mode === DealSupplyMode.Frame

  const { data } = useBankOrdersQuery({
    variables: {
      targetId: supply._id.toString(),
      targetType: BankOrderTargetType.DealSupply
    }
  })

  const { data: basePaymentsData } = usePaymentsQuery({
    variables: {
      kinds: [PaymentKind.Advance, PaymentKind.Commission],
      targetType: PaymentTargetType.Deal,
      targetId: dealId.toString()
    }
  })
  const advancePaymentDone =
    useNodes(basePaymentsData?.payments.edges).find((p) => p.kind === PaymentKind.Advance)?.state === PaymentState.Paid
  const comissionPaymentDone =
    useNodes(basePaymentsData?.payments.edges).find((p) => p.kind === PaymentKind.Commission)?.state ===
    PaymentState.Paid

  const payments = useNodes(data?.bankOrders?.edges)
  const hasPayments = !!payments.length
  const allPaymentsDone = payments.every((p) => p?.state === BankOrderState.Paid)
  const hasAccessSupplyFullyPaid = useAccessCheck('deal.supplies.supply_fully_paid')
  const hasAccessBankOrdersManage = useAccessCheck('bank_orders.manage')

  const company = supply?.supplierCompany

  const [removeDealSupply] = useRemoveDealSupplyMutation({
    update: (cache) => {
      const normalizedId = cache.identify({ id: supply._id, __typename: 'DealSupply' })
      cache.evict({ id: normalizedId })
      cache.gc()
    }
  })

  const supplyDocs = useEntityDocuments({
    entityId: supply._id,
    entityType: DocumentTypeEntity.DealSupply,
    folderName: SUPPLY_FOLDER_NAME
  })
  const supplyDocsSettled = supplyDocs.every((doc) => !doc.loading)
  const specificationDocs = useEntityDocuments({
    entityId: supply._id,
    entityType: DocumentTypeEntity.DealSupply,
    folderName: SPECIFICATIONS_FOLDER_NAME
  })
  const specificationDocsSettled = specificationDocs.every((doc) => !doc.loading)
  const shippingDocs = useEntityDocuments({
    entityId: supply._id,
    entityType: DocumentTypeEntity.DealSupply,
    folderName: SHIPPING_FOLDER_NAME
  })
  const shippingDocsSettled = shippingDocs.every((doc) => !doc.loading)
  const transferDocs = useEntityDocuments({
    entityId: supply._id,
    entityType: DocumentTypeEntity.DealSupply,
    folderName: TRANSFER_FOLDER_NAME
  })
  const transferDocsSettled = transferDocs.every((doc) => !doc.loading)

  const hasContractDocs = frameMode ? !!specificationDocs.length : !!supplyDocs.length
  const contractDocsSettled = frameMode ? specificationDocsSettled : supplyDocsSettled
  const hasShippingDocs = !!shippingDocs.length && !!transferDocs.length
  const hasPlannedShippingDate = Boolean(supply.shippingDate)
  const hasAmount = supply.amount && supply.amount > 0

  const { data: dealAdvanceRateData } = useDealRatesForSupplyQuery({
    variables: { id: dealId.toString() },
    skip: !dealId
  })
  const dealAdvanceRate = dealAdvanceRateData?.deal?.advanceRate
  const dealComissionRate = dealAdvanceRateData?.deal?.comissionRate

  const [updateDealSupply] = useUpdateDealSupplyFlagsMutation()

  const onFlagChange = useCallback(
    (flags: { contractDone?: boolean; paymentDone?: boolean; shippingDone?: boolean }) => {
      updateDealSupply({
        variables: {
          input: {
            id: supply._id.toString(),
            contractDone: supply.contractDone,
            paymentDone: supply.paymentDone,
            shippingDone: supply.shippingDone,
            ...flags
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateDealSupply: {
            __typename: 'updateDealSupplyPayload',
            dealSupply: {
              __typename: 'DealSupply',
              _id: supply._id,
              contractDone: supply.contractDone,
              paymentDone: supply.paymentDone,
              shippingDone: supply.shippingDone,
              ...flags
            }
          }
        }
      })
    },
    [supply.paymentDone, supply.contractDone, supply.shippingDone, supply._id, updateDealSupply]
  )

  const [deletionFormOpen, setDeletionFormOpen] = useState(false)
  const [editFormOpen, setEditFormOpen] = useState(false)

  const hasRequisites = Boolean(
    company &&
      company.bankDetails?.edges?.length &&
      company.bankDetails?.edges[0]?.node?.bic &&
      company.bankDetails?.edges[0]?.node?.account &&
      company.inn
  )

  useEffect(() => {
    if (step !== undefined) return

    if (supply?.paymentDone) {
      setStep(2)
    } else if (supply?.contractDone) {
      setStep(1)
    } else {
      setStep(0)
    }
  }, [supply?.paymentDone, supply?.contractDone, step])

  const canPay =
    hasRequisites &&
    hasContractDocs &&
    hasAmount &&
    hasPlannedShippingDate &&
    (!!dealAdvanceRate ? advancePaymentDone : true) &&
    (!!dealComissionRate ? comissionPaymentDone : true)

  const { totalAmount } = getSpecTotals(supply?.nomenclature || [])
  const amountDeviates = supply?.nomenclature?.length && supply.amount && totalAmount && supply.amount !== totalAmount

  return (
    <>
      <div className='rounded-md bg-surface-primary pb-4 shadow-card'>
        <div className='px-10 pb-4 pt-10'>
          <div className='flex'>
            <Link className='mr-4 hover:text-red-100' to={`/suppliers/${company._id}`}>
              <h1 className='font-display text-h200'>{company?.shortWithOpf}</h1>
            </Link>
            <SupplyOverdueTooltip supplyId={company._id} />
            <button className='ml-auto' onClick={() => setEditFormOpen(true)}>
              <EditIcon className='flex-none text-grayscale-200' height='20px' width='20px' title='Редактировать' />
            </button>
          </div>
          <div className='text-grayscale-150'>{supply?.companyBranch?.name || `ИНН: ${company.inn}`}</div>
        </div>
        <div className='flex w-full items-stretch gap-4 px-10 pb-6 pt-4'>
          <button
            data-headlessui-state={step === 0 ? 'active' : supply.contractDone && 'checked'}
            onClick={() => setStep(0)}
            className='flex-grow whitespace-nowrap rounded-xl py-6 text-grayscale-150 ring-1 ring-grayscale-400 ui-checked:bg-grayscale-450 ui-checked:text-grayscale-200 ui-checked:ring-grayscale-450 ui-active:bg-red-200 ui-active:font-medium ui-active:text-red-100 ui-active:ring-red-200'
          >
            {supply.contractDone && <TickIcon className='mr-3 inline-flex' />}
            Договор
          </button>
          <button
            data-headlessui-state={step === 1 ? 'active' : supply.paymentDone && 'checked'}
            onClick={() => setStep(1)}
            className='flex-grow whitespace-nowrap rounded-xl py-6 text-grayscale-150 ring-1 ring-grayscale-400 ui-checked:bg-grayscale-450 ui-checked:text-grayscale-200 ui-checked:ring-grayscale-450 ui-active:bg-red-200 ui-active:font-medium ui-active:text-red-100 ui-active:ring-red-200'
          >
            {supply.paymentDone && <TickIcon className='mr-3 inline-flex' />}
            Оплата
          </button>
          <button
            data-headlessui-state={step === 2 ? 'active' : supply.shippingDone && 'checked'}
            onClick={() => setStep(2)}
            className='flex-grow whitespace-nowrap rounded-xl py-6 text-grayscale-150 ring-1 ring-grayscale-400 ui-checked:bg-grayscale-450 ui-checked:text-grayscale-200 ui-checked:ring-grayscale-450 ui-active:bg-red-200 ui-active:font-medium ui-active:text-red-100 ui-active:ring-red-200'
          >
            {supply.shippingDone && <TickIcon className='mr-3 inline-flex' />}
            Отгрузка
          </button>
        </div>
        <div className='mb-4 px-4'>
          <table className='w-full'>
            <tbody>
              {frameMode && company.frameContractDocumentNumber && company.frameContractDocumentDate && (
                <tr className='group'>
                  <td className='px-6 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    Рамочный договор поставки
                  </td>
                  <td className='flex items-center justify-end gap-x-5 px-6 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    {company.frameContractDocumentNumber} от{' '}
                    {dateFormatter.format(new Date(company.frameContractDocumentDate))}
                  </td>
                </tr>
              )}
              <tr className='group'>
                <td className='px-6 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                  Сумма поставки
                </td>
                <td className='flex items-center justify-end gap-x-5 px-6 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                  {amountDeviates && (
                    <div className='relative'>
                      <Tooltip target={<WarningIcon className='text-red-100' height='20px' width='20px' />}>
                        Сумма в номенклатуре: {formatMoneyDecimal(totalAmount / 100)}
                      </Tooltip>
                    </div>
                  )}
                  {supply?.amount ? formatMoneyDecimal(supply?.amount / 100) : '—'}
                </td>
              </tr>
              <tr className='group'>
                <td className='px-6 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                  Плановая дата отгрузки
                </td>
                <td className='px-6 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                  {supply?.shippingDate ? dateFormatter.format(new Date(supply.shippingDate)) : '—'}
                </td>
              </tr>
              <tr className='group'>
                <td className='px-6 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                  Фактическая дата отгрузки
                </td>
                <td className='px-6 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                  {supply?.factShippingDate ? dateFormatter.format(new Date(supply.factShippingDate)) : '—'}
                </td>
              </tr>
              <tr className='group'>
                <td className='px-6 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                  Валюта поставщика
                </td>
                <td className='px-6 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                  {supply?.currency || '—'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {step === 0 && (
          <>
            <div className='border-t-1 border-grayscale-400 px-10 py-6'>
              {frameMode ? (
                <>
                  <div className='mb-6 font-display font-medium'>Рамочный договор</div>
                  {!!supply?.frameContractDocument && <Document canDelete={false} doc={supply.frameContractDocument} />}
                </>
              ) : (
                <>
                  <div className='mb-6 font-display font-medium'>Договор поставки</div>
                  <EntityFolder
                    entityId={supply._id}
                    entityType={DocumentTypeEntity.DealSupply}
                    folderName={SUPPLY_FOLDER_NAME}
                    canDelete={!supply.contractDone}
                    canUpload={!supply.contractDone}
                  />
                </>
              )}
            </div>
            {frameMode && (
              <div className='border-t-1 border-grayscale-400 px-10 py-6'>
                <div className='mb-6 font-display font-medium'>Спецификации</div>
                <EntityFolder
                  entityId={supply._id}
                  entityType={DocumentTypeEntity.DealSupply}
                  folderName={SPECIFICATIONS_FOLDER_NAME}
                  canDelete={!supply.contractDone}
                  canUpload={!supply.contractDone}
                />
              </div>
            )}
            {/* Фикс для старых поставок, где из-за отсутствия рамочного договора не отображались доки IT-2072 */}
            {!frameMode && !!specificationDocs?.length && (
              <div className='border-t-1 border-grayscale-400 px-10 py-6'>
                <div className='mb-6 font-display font-medium'>Спецификации (архив)</div>
                <EntityFolder
                  entityId={supply._id}
                  entityType={DocumentTypeEntity.DealSupply}
                  folderName={SPECIFICATIONS_FOLDER_NAME}
                  canDelete={!supply.contractDone}
                  canUpload={!supply.contractDone}
                />
              </div>
            )}
            <div className='border-t-1 border-grayscale-400 px-10 py-6'>
              <div className='mb-6 font-display font-medium'>Номенклатура</div>
              <Spec supplyId={supply._id} currency={supply?.currency || ''} spec={supply?.nomenclature} />
            </div>
            <div className='border-t-1 border-grayscale-400 px-10 py-6 empty:hidden'>
              {hasContractDocs && contractDocsSettled && supply.amount && (
                <Checkbox
                  label={'Все документы загружены'}
                  checked={supply.contractDone}
                  onChange={(checked) => onFlagChange({ contractDone: checked })}
                />
              )}
            </div>
          </>
        )}
        {step === 1 && (
          <>
            <div className='border-t-1 border-grayscale-400 px-4 py-6 empty:hidden'>
              <BankOrders
                companyName={company?.shortWithOpf || ''}
                payments={payments}
                canDelete={hasAccessBankOrdersManage}
              />
            </div>
            {hasAccessSupplyFullyPaid && hasPayments && allPaymentsDone && (
              <div className='border-t-1 border-grayscale-400 px-10 py-6'>
                <Checkbox
                  label={'Полностью оплачено'}
                  checked={supply.paymentDone}
                  onChange={(checked) => onFlagChange({ paymentDone: checked })}
                />
              </div>
            )}
          </>
        )}
        {step === 2 && (
          <>
            <div className='border-t-1 border-grayscale-400 px-10 py-6'>
              <div className='mb-6 font-display font-medium'>{SHIPPING_FOLDER_NAME}</div>
              <EntityFolder
                entityId={supply._id}
                entityType={DocumentTypeEntity.DealSupply}
                folderName={SHIPPING_FOLDER_NAME}
                canDelete={!supply.shippingDone}
                canUpload={!supply.shippingDone}
              />
            </div>
            <div className='border-t-1 border-grayscale-400 px-10 py-6'>
              <div className='mb-6 flex justify-between'>
                <div className='font-display font-medium'>{TRANSFER_FOLDER_NAME}</div>
                {!!supply?.nomenclature && (
                  <button
                    className='flex items-center gap-4 text-p200 text-labels-secondary'
                    onClick={() => setTransferActFormOpen(true)}
                  >
                    <NewDocIcon title={TRANSFER_FOLDER_NAME} />
                    {TRANSFER_FOLDER_NAME}
                  </button>
                )}
              </div>
              <EntityFolder
                entityId={supply._id}
                entityType={DocumentTypeEntity.DealSupply}
                folderName={TRANSFER_FOLDER_NAME}
                canDelete={!supply.shippingDone}
                canUpload={!supply.shippingDone}
              />
            </div>
            {hasShippingDocs && shippingDocsSettled && transferDocsSettled && !!supply.factShippingDate && (
              <div className='border-t-1 border-grayscale-400 px-10 py-6'>
                <Checkbox
                  label={'Полностью отгружено'}
                  checked={supply.shippingDone}
                  onChange={(checked) => onFlagChange({ shippingDone: checked })}
                />
              </div>
            )}
          </>
        )}
        <div className='grid gap-y-2 border-t-1 border-grayscale-400 px-10 py-6 empty:hidden'>
          {!supply.amount && (
            <Hint
              text='Добавить стоимость поставки'
              onClick={() => {
                setEditFormOpen(true)
              }}
            />
          )}
          {!supply.shippingDate && (
            <Hint
              text='Добавить плановую дату отгрузки'
              onClick={() => {
                setEditFormOpen(true)
              }}
            />
          )}
          {!supply.factShippingDate && step === 2 && (
            <Hint
              text='Добавить фактическую дату отгрузки'
              onClick={() => {
                setEditFormOpen(true)
              }}
            />
          )}
        </div>
        <div className='border-t-1 border-grayscale-400 pb-2 pt-6 empty:hidden'>
          {step === 1 && (
            <button
              className='flex w-full cursor-pointer items-center px-10 py-5 disabled:text-grayscale-250'
              disabled={!hasAccessBankOrdersManage || !canPay}
              onClick={() => setSupplyPaymentFormOpen(true)}
            >
              <PayIcon width='20px' className='mr-8 flex-none' title='Удалить' />
              Оплатить поставку
              {!canPay && (
                <Tooltip className='ml-auto' target={<WarningIcon className='text-red-100' />}>
                  <div className='mb-2 font-display font-medium'>Чтобы продолжить, нужно:</div>
                  <ul className='list-inside list-disc'>
                    {!hasRequisites && (
                      <li>
                        <Link className='underline' to={`/suppliers/${company._id}`}>
                          Добавить реквизиты поставщика
                        </Link>
                      </li>
                    )}
                    {!hasContractDocs && (
                      <li>
                        <button className='underline' onClick={() => setStep(0)}>
                          Загрузить {frameMode ? 'спецификации' : 'договор поставки'}
                        </button>
                      </li>
                    )}
                    {!hasAmount && (
                      <li>
                        <button className='underline' onClick={() => setEditFormOpen(true)}>
                          Указать стоимость поставки
                        </button>
                      </li>
                    )}
                    {!hasPlannedShippingDate && (
                      <li>
                        <button className='underline' onClick={() => setEditFormOpen(true)}>
                          Указать плановую дату отгрузки
                        </button>
                      </li>
                    )}
                    {!!dealAdvanceRate && !advancePaymentDone && <li>Получить авансовый платёж от клиента</li>}
                    {!!dealComissionRate && !comissionPaymentDone && <li>Получить комиссионный платёж от клиента</li>}
                  </ul>
                </Tooltip>
              )}
            </button>
          )}
          {!supply.paymentDone &&
            !supply.contractDone &&
            !supply.shippingDone &&
            !hasContractDocs &&
            !payments.length &&
            !transferDocs.length &&
            !shippingDocs.length && (
              <button
                className='flex w-full cursor-pointer items-center px-10 py-5 text-red-100 disabled:text-grayscale-250'
                disabled={!!payments.length}
                onClick={() => setDeletionFormOpen(true)}
              >
                <TrashIcon width='20px' className='mr-8 flex-none' title='Удалить' />
                <span className={c(payments.length ? 'text-grayscale-250' : 'text-red-100')}>Удалить поставку</span>
              </button>
            )}
        </div>
      </div>
      <SuspenseModal open={editFormOpen} setOpen={setEditFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <SupplyForm dealId={dealId} supplyId={supply._id} onDone={() => setEditFormOpen(false)} />
        </div>
      </SuspenseModal>
      <Modal open={deletionFormOpen} setOpen={setDeletionFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ConfirmationForm
            title='Удаление поставки'
            onDone={() => {
              setDeletionFormOpen(false)
              removeDealSupply({ variables: { input: { id: supply._id.toString() } } })
            }}
            onDismiss={() => setDeletionFormOpen(false)}
          >
            Вы действительно хотите удалить поставку?
          </ConfirmationForm>
        </div>
      </Modal>
      <SuspenseModal open={supplyPaymentFormOpen} setOpen={setSupplyPaymentFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <SupplyPaymentForm
            dealId={dealId}
            supplyId={supply._id}
            onDone={() => {
              setSupplyPaymentFormOpen(false)
            }}
          />
        </div>
      </SuspenseModal>
      <SuspenseModal open={transferActFormOpen} setOpen={setTransferActFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <TransferActForm dealId={dealId} supplyId={supply._id} />
        </div>
      </SuspenseModal>
    </>
  )
}

export default Supply
