import { FC } from 'react'
import { useSourceFilter } from './FilterForm'
import SelectedFilter from '../../components/Filter/SelectedFilter'
import UserName from '../../components/UserName'
import { SourceCategory } from '../../graphql/schema'
import { Tag } from '../../components/Tags/Tag.tsx'

const SelectedFilters: FC = () => {
  const { filterState, setFilterState, filterActive } = useSourceFilter()

  if (!filterActive || filterState?.query?.length) return null

  return (
    <div className='flex flex-wrap gap-2 mb-8'>
      {Object.keys(filterState).map((key) => {
        //@ts-ignore
        const objValue = filterState[key]
        switch (key) {
          case 'users':
          case 'bdmUsers':
            return objValue?.map((filter: number) => (
              <SelectedFilter
                key={key + filter}
                onRemove={() =>
                  setFilterState({
                    ...filterState,
                    [key]: (objValue as number[]).filter((id) => id !== filter)
                  })
                }
              >
                <UserName userId={filter} />
              </SelectedFilter>
            ))
          case 'categories':
            return objValue?.map((filter: { _id: SourceCategory; name: string }) => (
              <SelectedFilter
                key={filter.name + filter._id}
                onRemove={() =>
                  setFilterState({
                    ...filterState,
                    [key]: (objValue as { _id: SourceCategory; name: string }[]).filter((i) => i._id !== filter._id)
                  })
                }
              >
                {filter.name}
              </SelectedFilter>
            ))
          case 'tagsList':
            return objValue?.map((filter: Tag) => (
              <SelectedFilter
                key={filter._id}
                onRemove={() =>
                  setFilterState({
                    ...filterState,
                    [key]: (objValue as Tag[]).filter((i) => i._id !== filter._id)
                  })
                }
              >
                {filter.name}
              </SelectedFilter>
            ))
        }
      })}
    </div>
  )
}

export default SelectedFilters
