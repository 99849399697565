import { FC, ReactNode } from 'react'
import {
  Kind,
  AgeType,
  LiquidityType,
  ReconciliationFeraStatus,
  ReconciliationCustomerStatus,
  ReconciliationInitiatedBy
} from '../../../graphql/schema'
import { ageTypeDict, liquidityTypeDict } from '../../../utils/dictionaries'
import { formatDecimal, formatMoneyDecimal, formatPercent } from '../../../utils/formatNumber'
import { ReactComponent as CircleCheckIcon } from '../../../svg/icons/circleCheck.svg'
import { ReactComponent as CircleCrossIcon } from '../../../svg/icons/circleCross.svg'
import { ReactComponent as CircleEmptyIcon } from '../../../svg/icons/circleEmpty.svg'
import { ReactComponent as CircleSlotIcon } from '../../../svg/icons/circleSlot.svg'
import { ReactComponent as LoadingIcon } from '../../../svg/icons/loading.svg'
import { ReactComponent as FileIcon } from '../../../svg/icons/fileDownload.svg'
import { ReactComponent as WarningIcon } from '../../../svg/icons/warning.svg'
import getReconciliationFileName from '../../../utils/getReconciliationFileName'
import { useDownloadCommercial } from '../../../hooks/useDownloadCommercial'
import AvatarTooltip from '../../../components/Avatar/AvatarTooltip.tsx'
import useNodes from '../../../hooks/useNodes.ts'
import getReconciliationStatus from '../../../utils/reconciliationStatus.ts'

export interface CardReconciliation {
  _id: number
  amount: number
  advanceRate: number
  comissionRate: number
  durationMonths: number
  insuranceRate: number
  interestRate: number
  recommendedInterestRate?: number
  recommendedInsuranceRate?: number
  recommendedCommissionRate?: number
  kind: Kind
  ageType?: AgeType
  liquidityType?: LiquidityType
  monthPayment?: number
  vatRate: number
  created: string
  feraStatus: ReconciliationFeraStatus
  feraStatusChangedBy?: {
    _id: number
    name: string
    surname: string
  }
  feraStatusChangedAt?: string
  feraStatusAutoAccepted?: boolean
  feraAcceptExpiresAt?: string
  customerStatus: ReconciliationCustomerStatus
  customerStatusChangedBy?: {
    _id: number
    name: string
    surname: string
  }
  customerStatusChangedAt?: string
  initiatedBy: ReconciliationInitiatedBy
  createdBy: {
    name: string
    surname: string
  }
  requiredGuarantorsCount?: number
  reconciliationGuarantors?: {
    edges?: Array<
      | {
          node?: {
            _id: number
            contact?: {
              _id: number
              fio: string
              verificationStatus: string
              verificationAcceptExpiresAt?: string
            }
            company?: {
              _id: number
              inn: string
              shortWithOpf?: string
            }
          }
        }
      | undefined
    >
  }
  reconciliationNumber: number
}
interface ReconciliationCardProps {
  ourSide: boolean
  reconciliation?: CardReconciliation
  diffRec?: CardReconciliation
  canDecide: boolean
  onAccept: () => void
  onOffer: () => void
  onReject: () => void
}

const Diff: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <span>
      <span className='mr-4 line-through'>{children}</span>
      <span className='mr-4'>&#x2192;</span>
    </span>
  )
}

const ReconciliationCard: FC<ReconciliationCardProps> = ({
  ourSide,
  canDecide,
  reconciliation,
  diffRec,
  onAccept,
  onOffer,
  onReject
}) => {
  const {
    amount,
    advanceRate,
    durationMonths,
    interestRate,
    comissionRate,
    insuranceRate,
    recommendedInterestRate,
    recommendedInsuranceRate,
    recommendedCommissionRate,
    requiredGuarantorsCount = 0,
    reconciliationGuarantors,
    monthPayment,
    kind,
    ageType,
    liquidityType
  } = reconciliation || {}

  const status = ourSide ? reconciliation?.feraStatus : reconciliation?.customerStatus
  const statusChangedBy = ourSide ? reconciliation?.feraStatusChangedBy : reconciliation?.customerStatusChangedBy
  const statusChangedAt = ourSide ? reconciliation?.feraStatusChangedAt : reconciliation?.customerStatusChangedAt
  const feraAcceptExpiresAt = reconciliation?.feraAcceptExpiresAt
  const accepted = status === 'accepted' ? true : status === 'rejected' ? false : undefined
  const initiatedByUs = reconciliation?.initiatedBy === 'fera'
  const autoAccepted = reconciliation?.feraStatusAutoAccepted
  const feraAcceptExpired = Boolean(
    reconciliation?.feraAcceptExpiresAt && new Date() >= new Date(reconciliation?.feraAcceptExpiresAt)
  )

  const [downloadCommercial, loading] = useDownloadCommercial(
    reconciliation?._id,
    reconciliation && getReconciliationFileName(reconciliation.reconciliationNumber, reconciliation._id)
  )

  const guarantors = useNodes(reconciliationGuarantors?.edges)

  // показываем данные только при автоаппруве с нашей стороны или у инициатора
  const showData = autoAccepted ? ourSide : ourSide === initiatedByUs

  const cardData = {
    customerStatus: reconciliation?.customerStatus,
    feraStatus: reconciliation?.feraStatus,
    ourSide: ourSide,
    feraAcceptExpired: feraAcceptExpired,
    feraAcceptExpiresAt: feraAcceptExpiresAt,
    statusChangedAt: statusChangedAt,
    autoAccepted: autoAccepted
  }

  const reconciliationInfo = () => {
    const [reconciliationStatus, reconciliationText] = getReconciliationStatus(cardData)
    if (reconciliationStatus === 'accepted') {
      return (
        <>
          <CircleCheckIcon className='text-green-600' />
          <div className='ml-auto flex items-center gap-8'>
            {reconciliationText}
            {!autoAccepted && statusChangedBy && <AvatarTooltip userId={statusChangedBy._id} />}
          </div>
        </>
      )
    } else if (reconciliationStatus === 'rejected') {
      return (
        <>
          <CircleCrossIcon className='text-red-50' />
          <div className='ml-auto flex items-center gap-8'>
            {reconciliationText}
            {!feraAcceptExpired && statusChangedBy && <AvatarTooltip userId={statusChangedBy._id} />}
          </div>
        </>
      )
    } else if (reconciliationStatus === 'otherRejected') {
      return <CircleSlotIcon className='text-grayscale-300' />
    } else return <CircleEmptyIcon />
  }

  const extraGuarantors = Math.max(0, requiredGuarantorsCount - (guarantors?.length || 0))

  const enoughGuarantors = extraGuarantors === 0

  const getVatKind = (kind?: Kind) => {
    switch (kind) {
      case Kind.Medicine:
        return 'Мед. (0%)'
      case Kind.Regular:
        return 'Обычн. (20%)'
      case Kind.Usn:
        return 'УСН (0%)'
    }
  }

  return (
    <div>
      <div className='p-10'>
        <div className='flex items-center'>
          <h2 className='mr-8 font-display text-lg font-medium'>{ourSide ? 'Fera' : 'Клиент'}</h2>
          {reconciliationInfo()}
        </div>
      </div>
      {!ourSide && !showData && accepted === true && (
        <div className='border-t-1 border-grayscale-400 p-10'>
          <div className='flex items-stretch gap-x-4'>
            {autoAccepted ? (
              <>
                <button
                  onClick={onOffer}
                  className='flex-grow cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 bg-grayscale-450 px-5 py-5 text-center text-grayscale-150 hover:bg-grayscale-400'
                >
                  Встречное предложение
                </button>
                <button
                  onClick={onReject}
                  className='flex-grow cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 bg-grayscale-450 px-5 py-5 text-center text-red-50 hover:bg-grayscale-400'
                >
                  Отказать
                </button>
              </>
            ) : (
              <button
                onClick={onOffer}
                className='flex-grow cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 bg-grayscale-450 px-5 py-5 text-center text-grayscale-150 hover:bg-grayscale-400'
              >
                Что-то изменилось...
              </button>
            )}
          </div>
        </div>
      )}

      {showData && (
        <div className='grid grid-cols-2 gap-x-4 px-4'>
          <div className='col-span-2 mb-4 flex rounded-lg px-6 py-4 odd:bg-grayscale-450'>
            <div className='text-grayscale-150'>Сумма</div>
            <div className='ml-auto'>
              {!!diffRec && diffRec.amount !== amount && <Diff>{formatMoneyDecimal(diffRec.amount / 100)}</Diff>}
              <span>{formatMoneyDecimal((amount || 0) / 100)}</span>
            </div>
          </div>
          <div>
            <div className='mb-4 flex rounded-lg px-6 py-4 even:bg-grayscale-450'>
              <div className='text-grayscale-150'>Ставка</div>
              <div className='ml-auto'>
                {!!diffRec && diffRec.interestRate !== interestRate && (
                  <Diff>{formatPercent(diffRec.interestRate)}</Diff>
                )}
                {formatPercent(interestRate)}
              </div>
            </div>
            <div className='mb-4 flex rounded-lg px-6 py-4 even:bg-grayscale-450'>
              <div className='text-grayscale-150'>Срок</div>
              <div className='ml-auto'>
                {!!diffRec && diffRec.durationMonths !== durationMonths && <Diff>{diffRec.durationMonths}</Diff>}
                {durationMonths} мес.
              </div>
            </div>
            <div className='mb-4 flex rounded-lg px-6 py-4 even:bg-grayscale-450'>
              <div className='text-grayscale-150'>Аванс</div>
              <div className='ml-auto'>
                {!!diffRec && diffRec.advanceRate !== advanceRate && <Diff>{formatPercent(diffRec.advanceRate)}</Diff>}
                {formatPercent(advanceRate)}
              </div>
            </div>
          </div>
          <div>
            <div className='mb-4 flex rounded-lg px-6 py-4 even:bg-grayscale-450'>
              <div className='text-grayscale-150'>Комиссия</div>
              <div className='ml-auto'>
                {!!diffRec && diffRec.comissionRate !== comissionRate && (
                  <Diff>{formatPercent(diffRec.comissionRate)}</Diff>
                )}
                {formatPercent(comissionRate)}
              </div>
            </div>
            <div className='mb-4 flex rounded-lg px-6 py-4 even:bg-grayscale-450'>
              <div className='text-grayscale-150'>Страховка</div>
              <div className='ml-auto'>
                {!!diffRec && diffRec.insuranceRate !== insuranceRate && (
                  <Diff>{formatPercent(diffRec.insuranceRate)}</Diff>
                )}
                {formatPercent(insuranceRate)}
              </div>
            </div>
            <div className='mb-4 flex rounded-lg px-6 py-4 even:bg-grayscale-450'>
              <div className='text-grayscale-150'>НДС</div>
              <div className='ml-auto'>
                {!!diffRec && diffRec.kind !== kind && <Diff>{getVatKind(diffRec.kind)}</Diff>}
                {getVatKind(kind)}
              </div>
            </div>
          </div>
        </div>
      )}

      {showData && (
        <div className='px-4'>
          <div className='mb-4 flex rounded-lg px-6 py-4 odd:bg-grayscale-450'>
            <div className='text-grayscale-150'>Ликвидность</div>
            <div className='ml-auto'>{!!liquidityType && liquidityTypeDict[liquidityType]}</div>
          </div>
          <div className='mb-4 flex rounded-lg px-6 py-4 odd:bg-grayscale-450'>
            <div className='text-grayscale-150'>Срок бизнеса</div>
            <div className='ml-auto'>{!!ageType && ageTypeDict[ageType]}</div>
          </div>
          <div className='mb-4 flex rounded-lg px-6 py-4 odd:bg-grayscale-450'>
            <div className='text-grayscale-150'>Условия из калькулятора</div>
            {recommendedCommissionRate || recommendedInsuranceRate || recommendedInterestRate ? (
              <div className='ml-auto'>
                {recommendedInterestRate && `Ста — ${recommendedInterestRate}%`}
                {recommendedInsuranceRate && ', '}
                {recommendedInsuranceRate && `Стр — ${recommendedInsuranceRate}%`}
                {recommendedCommissionRate && ', '}
                {recommendedCommissionRate && `K — ${recommendedCommissionRate}%`}
              </div>
            ) : (
              <div className='ml-auto'>—</div>
            )}
          </div>
        </div>
      )}

      {showData && (
        <div className='border-t-1 border-grayscale-400 px-4 py-4'>
          <h3 className='mb-6 mt-4 px-6 font-medium'>Поручители</h3>
          {guarantors.map((g) => (
            <div key={g._id} className='flex rounded-lg px-6 py-4 even:bg-grayscale-450'>
              <div className='text-grayscale-150'>{g?.company?.shortWithOpf || g?.contact?.fio}</div>
            </div>
          ))}
          {Array.from({ length: extraGuarantors }).map((_, i) => (
            <div key={i} className='flex items-center rounded-lg px-6 py-4 even:bg-grayscale-450'>
              <WarningIcon className='mr-4 h-10 w-10 flex-none text-red-50' />
              <div className='text-grayscale-150'>Требуется дополнительный поручитель {i > 0 ? i + 1 : ''}</div>
            </div>
          ))}
        </div>
      )}

      {showData && (
        <div className='border-t-1 border-grayscale-400 p-10'>
          <h3 className='mb-4 font-display text-p200'>Лизинговый платёж</h3>
          {monthPayment && <div className='text-h100 font-medium'>{formatDecimal(monthPayment)} ₽ в месяц</div>}
        </div>
      )}

      {accepted === undefined && canDecide && (
        <div className='border-t-1 border-grayscale-400 p-10'>
          <div className='flex items-stretch gap-x-4'>
            {/* hide accept button form customer when not enough guarantors */}
            {(ourSide || (!ourSide && enoughGuarantors)) && (
              <button
                onClick={onAccept}
                className='flex-grow cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 bg-grayscale-450 px-5 py-5 text-center text-green-600 hover:bg-grayscale-400'
              >
                Принять
              </button>
            )}
            <button
              onClick={onOffer}
              className='flex-grow cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 bg-grayscale-450 px-5 py-5 text-center text-grayscale-150 hover:bg-grayscale-400'
            >
              Встречное предложение
            </button>
            <button
              onClick={onReject}
              className='flex-grow cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 bg-grayscale-450 px-5 py-5 text-center text-red-50 hover:bg-grayscale-400'
            >
              Отказать
            </button>
          </div>
        </div>
      )}

      {showData && (
        <div className='border-t-1 border-grayscale-400 p-10'>
          <div onClick={downloadCommercial} className='flex cursor-pointer items-center text-grayscale-150'>
            {loading ? <LoadingIcon className='animate-spin' /> : <FileIcon onClick={() => downloadCommercial()} />}
            <span className='ml-4'>Коммерческое предложение</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default ReconciliationCard
