import { FC, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ReactComponent as PlusIcon } from '../../../svg/icons/plus.svg'
import { ReactComponent as EditIcon } from '../../../svg/icons/edit.svg'
import SubmitButton from '../../../components/SubmitButton'
import Suggestions from '../../../components/Suggestions'
import {
  DealSupply,
  useAddDealRetrobonusMutation,
  useDealRetrobonusesQuery,
  useDealSuppliesQuery,
  useUpdateDealRetrobonusMutation
} from '../../../graphql/schema'
import useNodes from '../../../hooks/useNodes'
import { ApolloError } from '@apollo/client'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils'
import parseDecimal from '../../../utils/parseDecimal'
import { formatMoneyDecimal } from '../../../utils/formatNumber'
import PercentInput from '../../../components/Forms/Inputs/PercentInput.tsx'

export type CommissionRetrobonusEntity = {
  retrobonusId: number
  retrobonusNumber: number
  dealSupplyId: number
  rate?: number
  amount?: number
}

type CommissionRetrobonusFormProps = {
  onDone: () => void
  dealId?: string
  entity?: CommissionRetrobonusEntity
}

type Inputs = {
  dealSupply: string
  rate: string
  amount: string
}

const CommissionRetrobonusForm: FC<CommissionRetrobonusFormProps> = ({ onDone, dealId, entity }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    resetField,
    watch
  } = useForm<Inputs>({
    defaultValues: {
      rate: entity?.rate?.toString() || '0',
      amount: entity?.amount ? (entity.amount / 100).toString() : '0'
    }
  })

  const [supplyTarget, setSupplyTarget] = useState<DealSupply | null>()
  const [query, setQuery] = useState('')

  const { data: dealRetrobonusesData } = useDealRetrobonusesQuery({
    variables: {
      deal: `${dealId}`
    },
    skip: !dealId
  })
  const selectedDealSupplies = useNodes(dealRetrobonusesData?.dealRetrobonuses?.edges)

  const { data } = useDealSuppliesQuery({ variables: { id: dealId! }, skip: !dealId })
  const dealSupplies = useNodes(data?.deal?.dealSupplies?.edges) as DealSupply[]

  const filteredDealSupplies = useMemo(() => {
    const retroDealSupplies = dealSupplies?.filter(
      (dealSupply) =>
        dealSupply?.supplierCompany?.hasRetrobonus &&
        !selectedDealSupplies?.map((s) => s.dealSupply._id)?.includes(dealSupply._id)
    )

    return query
      ? retroDealSupplies.filter(
          (dealSupply) =>
            dealSupply?.supplierCompany?.shortWithOpf?.toLowerCase().includes(query.toLowerCase()) ||
            dealSupply?.supplierCompany?.inn?.toLowerCase().includes(query.toLowerCase())
        )
      : retroDealSupplies
  }, [dealSupplies, query])

  useEffect(() => {
    if (entity) {
      const foundDealSupply = dealSupplies?.find((supply) => supply?._id === entity?.dealSupplyId)
      if (foundDealSupply) {
        setSupplyTarget(foundDealSupply)
      }
    }
  }, [dealSupplies, entity])

  const [addDealRetrobonus, { loading }] = useAddDealRetrobonusMutation()
  const [updateDealRetrobonus, { loading: loadingUpdate }] = useUpdateDealRetrobonusMutation()

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (!data?.rate || !data?.amount) return

    if (entity) {
      await updateDealRetrobonus({
        variables: {
          input: {
            id: entity.retrobonusId.toString(),
            rate: parseDecimal(data.rate)
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateDealRetrobonus: {
            __typename: 'updateDealRetrobonusPayload',
            dealRetrobonus: {
              __typename: 'DealRetrobonus',
              _id: entity.retrobonusId,
              rate: parseDecimal(data.rate),
              amount: parseDecimal(data.amount) * 100
            }
          }
        }
      })
        .then(() => {
          if (onDone) onDone()
        })
        .catch((err: ApolloError) => {
          handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
            setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
          })
        })
    } else {
      await addDealRetrobonus({
        variables: {
          input: {
            dealSupply: supplyTarget?._id.toString() as string,
            rate: parseDecimal(data.rate)
          }
        }
      })
        .then(() => {
          if (onDone) onDone()
        })
        .catch((err: ApolloError) => {
          handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
            setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
          })
        })
    }
  }

  return (
    <section className='w-[448px] p-12'>
      <h1 className='font-display text-h200 mb-12'>
        {entity ? 'Редактировать ретробонус №' + entity.retrobonusNumber : 'Добавить ретробонус'}
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid grid-cols-1 gap-12 mb-12 relative'>
          {!entity && (
            <div>
              <div className='inp-label text-p350 mb-5'>Выберите поставку</div>
              <Suggestions<DealSupply>
                initialState={true}
                highlight={query || supplyTarget?.supplierCompany?.shortWithOpf}
                suggestions={filteredDealSupplies?.map((dealSupply) => ({
                  key: dealSupply._id,
                  title: dealSupply.supplierCompany?.shortWithOpf || '',
                  subtitle: `ИНН: ${dealSupply.supplierCompany.inn}, Сумма: ${formatMoneyDecimal(
                    dealSupply.amount / 100
                  )}`,
                  payload: dealSupply,
                  disabled: !dealSupply?.amount
                }))}
                select={(suggestion) => {
                  setValue('dealSupply', suggestion?.payload?.supplierCompany?.shortWithOpf || '')
                  resetField('rate')
                  resetField('amount')
                  setQuery('')
                  setSupplyTarget(suggestion?.payload)
                }}
              >
                <label className='group relative bg-white-0 ring-grayscale-400 hover:focus-within:ring-red-100 focus-within:ring-red-100 hover:ring-grayscale-250 rounded-xl ring-1 flex items-center'>
                  <input
                    type='text'
                    placeholder='Выбрать'
                    className='py-7 px-10 transition-opacity border-none outline-none bg-transparent focus:ring-0 w-full placeholder-grayscale-250 truncate'
                    autoComplete='off'
                    onInput={(e) => {
                      setSupplyTarget(null)
                      setQuery(e.currentTarget.value)
                    }}
                    {...register('dealSupply', { required: true })}
                  />
                  {typeof supplyTarget?.amount === 'number' && (
                    <p className='text-grayscale-200 whitespace-nowrap pr-10'>
                      {formatMoneyDecimal(supplyTarget.amount / 100)}
                    </p>
                  )}
                </label>
              </Suggestions>
            </div>
          )}

          <PercentInput
            label='Ретробонус'
            percentValue={watch('rate')}
            value={watch('amount')}
            onPercentChange={(percent) => {
              setValue('rate', percent)
              setValue('amount', ((supplyTarget?.amount / 100) * (parseDecimal(percent) / 100)).toString())
            }}
            error={errors?.rate}
          />
        </div>

        <SubmitButton loading={loading || loadingUpdate}>
          {!entity ? (
            <>
              <PlusIcon className='mr-5' />
              Добавить ретробонус
            </>
          ) : (
            <>
              <EditIcon className='mr-5' />
              Изменить
            </>
          )}
        </SubmitButton>
      </form>
    </section>
  )
}

export default CommissionRetrobonusForm
