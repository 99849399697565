import { FC, useState } from 'react'
import Dropdown from '../Dropdown/index'
import DropdownButton from '../Dropdown/Button'
import { useRemoveContactInfoMutation } from '../../graphql/schema'
import ContactInfoForm from './ContactInfoForm'
import Modal from '../Modal'
import useAccessCheck from '../../hooks/useAccessCheck'
import { ReactComponent as AddIcon } from '../../svg/ui/plus.svg'
import PhoneNumber from '../PhoneNumber'
import { Card, CardHeader, CardIconButton, CardMenu, CardTitle } from '../Card.tsx'

export enum ContactInfoKind {
  Source = 'source',
  SupplierCompany = 'supplierCompany',
  CustomerCompany = 'customerCompany'
}

type ContactInfo = {
  _id: number
  fio: string
  phone?: string
  email?: string
  comment?: string
  verificationStatus?: string
  contactSource?: string
}

interface ContactInfoProps {
  onDone: () => void
  contactInfos: ContactInfo[]
  targetId: number
  targetType: ContactInfoKind
  abilityCreate: string
  abilityEdit: string
  title?: string
}

const ContactInfo: FC<ContactInfoProps> = ({
  contactInfos,
  onDone,
  targetId,
  targetType,
  abilityCreate,
  abilityEdit,
  title
}) => {
  const [contactInfoFormOpen, setContactInfoFormOpen] = useState(false)
  const [editContactInfoId, setEditContactInfoId] = useState<number | undefined>()

  const [removeContactInfo] = useRemoveContactInfoMutation()

  const hasAccessCreate = useAccessCheck(abilityCreate)
  const hasAccessEdit = useAccessCheck(abilityEdit)

  return (
    <Card>
      <CardHeader>
        <CardTitle>{title || 'Контакты'}</CardTitle>
        {hasAccessCreate && (
          <CardMenu>
            <CardIconButton
              onClick={() => {
                setEditContactInfoId(undefined)
                setContactInfoFormOpen(true)
              }}
            >
              <AddIcon />
            </CardIconButton>
          </CardMenu>
        )}
      </CardHeader>

      {!!contactInfos.length && (
        <div className='flex flex-col gap-5 px-5 pb-5'>
          {contactInfos?.map((c) => (
            <div key={c._id} className='relative rounded-md bg-surface-primary p-10 shadow-card'>
              <div className='mb-10'>
                <div className='flex justify-between'>
                  <div className='flex items-center font-medium text-grayscale-0'>
                    {c.fio}
                    {c.verificationStatus === 'accepted' && <div className='ml-5 h-6 w-6 rounded-full bg-green-400' />}
                    <div className='ml-5 font-normal text-grayscale-200'>{c.contactSource}</div>
                  </div>
                  {hasAccessEdit && !c.contactSource && (
                    <Dropdown className='ml-auto pl-4 pr-4'>
                      <DropdownButton
                        action='edit'
                        onClick={() => {
                          setEditContactInfoId(c._id)
                          setContactInfoFormOpen(true)
                        }}
                      />
                      <DropdownButton
                        action='delete'
                        onClick={async () => {
                          await removeContactInfo({ variables: { input: { contactInfo: c._id.toString() } } })
                          onDone()
                        }}
                      />
                    </Dropdown>
                  )}
                </div>
                <div className='text-grayscale-150'>{c.comment}</div>
              </div>
              <div className='flex items-center text-grayscale-150'>
                <div className='flex items-center'>
                  <PhoneNumber number={c.phone} />
                </div>
                {!!c.email && !!c.phone && <div className='mx-5 h-2 w-2 rounded-full bg-grayscale-150' />}
                <div className='flex items-center'>{c.email}</div>
              </div>
            </div>
          ))}
        </div>
      )}

      <Modal open={contactInfoFormOpen} setOpen={setContactInfoFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ContactInfoForm
            onDone={() => {
              onDone()
              setContactInfoFormOpen(false)
              setEditContactInfoId(undefined)
            }}
            contactInfoId={editContactInfoId}
            targetId={targetId}
            targetType={targetType}
          />
        </div>
      </Modal>
    </Card>
  )
}

export default ContactInfo
