import { FC, useEffect, useState } from 'react'
import { ReactComponent as PlusIcon } from '../../../svg/icons/plus.svg'
import { ReactComponent as TickIcon } from '../../../svg/icons/tick-xs.svg'
import {
  TaskKind,
  UsersQuery,
  useCreateTaskMutation,
  TaskTargetType,
  useUsersSuspenseQuery,
  UserRoles
} from '../../../graphql/schema'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ApolloError } from '@apollo/client'
import { NodeType } from '../../../types'
import useFilteredUsers from '../../../hooks/useFilteredUsers.ts'
import useNodes from '../../../hooks/useNodes.ts'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils.ts'
import Select from '../../../components/Select.tsx'
import getFullName from '../../../utils/getFullName.ts'
import Highlighted from '../../../components/Highlighted.tsx'
import Textarea from '../../../components/Textarea.tsx'
import SubmitButton from '../../../components/SubmitButton'
import c from 'clsx'

export type User = NodeType<UsersQuery['users']>

function sortUsersByActivation(a: User, b: User) {
  if (a.isActive && !b.isActive) {
    return -1
  }
  if (!a.isActive && b.isActive) {
    return 1
  } else {
    return a.name.localeCompare(b.name)
  }
}

interface Inputs {
  userId: number
  userName: string
  body: string
}

interface WaitingForAdditionalInfoFormProps {
  applicationId: number
  scoringProcessId: number
  managerId?: number
  onDone?: () => void
}

const WaitingForAdditionalInfoForm: FC<WaitingForAdditionalInfoFormProps> = ({
  applicationId,
  scoringProcessId,
  managerId,
  onDone
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    watch
  } = useForm<Inputs>({
    defaultValues: {
      userId: managerId,
      body: '[ДОПЗАПРОС] '
    }
  })
  const userId = watch('userId')

  const [query, setQuery] = useState('')

  const { data: usersData } = useUsersSuspenseQuery({
    variables: { roles: UserRoles.RoleManager, order: [{ name: 'asc' }] }
  })
  const users = useNodes(usersData?.users?.edges)
  const list = useFilteredUsers(users, query)
  list.sort(sortUsersByActivation)

  const [createTask, { loading }] = useCreateTaskMutation()

  useEffect(() => {
    if (managerId) {
      setValue('userName', getFullName(users.find((user) => user._id === managerId)))
    }
  }, [managerId])

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (loading || !data) return

    const createInput = {
      targetId: applicationId,
      targetType: TaskTargetType.Application,
      kind: TaskKind.Task,
      userId: data.userId,
      body: data.body,
      isDueDateTimeSpecified: true,
      isNotificationActivated: true,
      dueDate: new Date(),
      childTargetId: scoringProcessId,
      childTargetType: TaskTargetType.ScoringProcess
    }

    await createTask({
      variables: {
        input: createInput
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  return (
    <section className='md: w-[448px] p-12'>
      <h1 className='font-display text-h200 mb-12'>Доп. запрос</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 flex flex-col gap-8 relative'>
          <div className='relative'>
            <Select
              editable
              type='text'
              label='Менеджер'
              autoComplete='off'
              onQuery={(query) => setQuery(query)}
              error={errors.userName?.message}
              {...register('userName', { required: true })}
            >
              {list.map((user) => (
                <li
                  key={user._id}
                  onClick={() => {
                    if (!user.isActive) return
                    setValue('userId', user._id)
                    setValue('userName', getFullName(user))
                  }}
                  className={c(
                    'hover:bg-grayscale-450 px-12 py-5',
                    user.isActive ? 'cursor-pointer' : 'cursor-not-allowed'
                  )}
                >
                  <div className='flex justify-between items-center'>
                    <div className={c('mb-1', user.isActive ? 'text-grayscale-0' : 'text-grayscale-150')}>
                      <Highlighted
                        classMarkName='text-red-100 bg-transparent'
                        text={`${user?.name} ${user?.surname}${!user.isActive ? ' (Деактивирован)' : ''}`}
                        highlight={query}
                      />
                    </div>
                    {userId === user._id && (
                      <div className='rounded-full bg-red-100/5 text-red-100 px-5 py-2 flex items-center ml-5'>
                        <TickIcon />
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </Select>
          </div>
          <Textarea label='Комментарий' {...register('body', { required: true })} error={errors.body} />
        </div>

        <SubmitButton loading={loading}>
          <PlusIcon className='mr-5' />
          Создать
        </SubmitButton>
      </form>
    </section>
  )
}

export default WaitingForAdditionalInfoForm
