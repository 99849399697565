import { FC } from 'react'
import Input from '../components/Input'
import { useForm, SubmitHandler, FieldError } from 'react-hook-form'
import { ReactComponent as BackofficeLogo } from '../svg/backoffice.svg'
import { ApolloError } from '@apollo/client'
import { useLoginMutation } from '../graphql/schema'
import { jwtVar, refreshTokenVar } from '../graphql/cache'
import { EMAIL_PATTERN } from '../utils/constants'
import SubmitButton from '../components/SubmitButton'

type Inputs = {
  login: string,
  password: string,
}

const getErrorMessage = (err?: FieldError) => {
  if (!err) return

  if (err.message) return err.message
  switch (err.type) {
    case 'required':
      return 'Обязательное поле'
    default:
      return err.type
  }
}

const Auth: FC = () => {
  const { register, handleSubmit, formState: { errors }, setError } = useForm<Inputs>()

  const [login, { loading }] = useLoginMutation()

  const onSubmit: SubmitHandler<Inputs> = async data => {
    const result = await login({ variables: data }).catch((err: ApolloError) => {
      if (err.graphQLErrors) {
        setError('password', {
          message: err.graphQLErrors[0].message
        })
      }
    })

    if (!result?.data?.loginUser?.user) return

    const { jwt, refreshToken } = result.data?.loginUser?.user

    if (jwt) {
      jwtVar(jwt)
    }

    if (refreshToken) {
      refreshTokenVar(refreshToken)
    }

    return
  }

  return (
    <div className='h-screen bg-white-0 flex items-center justify-center relative overflow-hidden'>
      <div className='absolute w-[1341px] h-[1341px] rounded-full border-2 border-red-100 -left-[765px] -top-[582px] -translate-x-25 animate-wiggle-slow-l' />
      <div className='absolute w-[1372px] h-[1372px] rounded-full border-1 border-red-100 border-opacity-50 -left-[607px] -top-[736px] translate-y-25 animate-wiggle-slow' />
      <div className='absolute w-[1341px] h-[1341px] rounded-full border-2 border-red-100 -right-[428px] -bottom-[1123px] translate-x-25 animate-wiggle-slow' />
      <div className='absolute w-[1372px] h-[1372px] rounded-full border-1 border-red-100 border-opacity-50 -right-[754px] -bottom-[959px] -translate-y-25 animate-wiggle-slow-l' />
      <div className='md:w-175'>
        <div className='flex items-center justify-center mb-18'>
          <BackofficeLogo />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='grid grid-cols-1 gap-y-2 mb-15'>
            <div className='mb-8'>
              <Input
                label='Ваш E-mail'
                type='text'
                autoComplete='email'
                inputMode='email'
                placeholder='user@fera.ru'
                error={getErrorMessage(errors.login)}
                {...register('login', {
                  required: true,
                  pattern: {
                    value: EMAIL_PATTERN,
                    message: 'Некорректный E-mail'
                  }
                })}
              />
            </div>
            <Input
              label='Пароль'
              type='password'
              autoComplete='current-password'
              placeholder=''
              error={getErrorMessage(errors.password)}
              {...register('password', {
                required: true,
              })}
            />
          </div>

          <SubmitButton loading={loading}>Войти</SubmitButton>
        </form>
      </div>
    </div >
  )
}

export default Auth
