import { useCallback, useState } from 'react'
import copyToClipboard from 'copy-to-clipboard'

const useCopyWidgetToken = (widgetPublicId?: string) => {
  const [copyLinkDone, setCopyLinkDone] = useState(false)

  const copyLink = useCallback(async () => {
    if (widgetPublicId) {
      copyToClipboard(widgetPublicId)
      setCopyLinkDone(true)
      await new Promise((res) => setTimeout(res, 1000))
      setTimeout(() => setCopyLinkDone(false), 100)
    }
  }, [widgetPublicId])

  return [copyLink, copyLinkDone] as const
}

export default useCopyWidgetToken
