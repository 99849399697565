import { FC, useState } from 'react'
import { useContactsQuery } from '../../graphql/schema'
import useScrollPagination from '../../hooks/useScrollPagination'
import EditContactForm from '../../components/Forms/EditContactForm'
import useNodes from '../../hooks/useNodes'
import ContactsList from '../../components/ContactsList'
import SearchBar, { FilterButton } from '../../components/Search'
import FilterForm, { useContactFilter } from './FilterForm'
import useUpdateEffect from '../../hooks/useUpdateEffect'
import SelectedFilters from './SelectedFilters'
import SuspenseModal from '../../components/SuspenseModal.tsx'

const Contacts: FC = () => {
  const [editContactId, setEditContactId] = useState<number>()

  const { filterState, setFilterState, filterActive, query, setQuery, variables } = useContactFilter()

  const {
    data: contactsData,
    refetch,
    loading,
    error,
    fetchMore
  } = useContactsQuery({
    nextFetchPolicy: 'cache-and-network',
    variables
  })

  useUpdateEffect(() => {
    refetch(variables)
  }, [variables, refetch])

  const contactsList = useNodes(contactsData?.contacts?.edges)

  const { wrapperRef: triggerRef, isFetching } = useScrollPagination(fetchMore, contactsData?.contacts?.pageInfo)

  return (
    <div className='py-15 px-6 flex-grow container'>
      <div className='bg-surface-secondary rounded-xl p-5 mb-8'>
        <div className='bg-white-0 shadow-xs rounded-md'>
          <div className='flex justify-between items-start p-10 border-grayscale-400'>
            <div>
              <h1 className='font-display text-h100'>Контакты</h1>
            </div>
          </div>
        </div>
      </div>

      <SearchBar onChange={setQuery} query={query}>
        <FilterButton active={filterActive}>
          <FilterForm state={filterState} onChange={setFilterState} />
        </FilterButton>
      </SearchBar>

      <SelectedFilters />

      <div className='bg-surface-secondary rounded-xl p-5' ref={triggerRef}>
        <ContactsList
          contactsList={contactsList}
          onEdit={setEditContactId}
          loading={loading || isFetching}
          error={error?.message}
        />
      </div>
      <SuspenseModal open={!!editContactId} setOpen={() => setEditContactId(undefined)}>
        <div className='bg-white-0 rounded-xl z-10'>
          <EditContactForm
            onDone={() => {
              refetch()
              setEditContactId(undefined)
            }}
            id={editContactId as number}
          />
        </div>
      </SuspenseModal>
    </div>
  )
}

export default Contacts
