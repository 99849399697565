import { FC, useMemo } from 'react'
import { Card, CardHeader, CardTitle } from '../../../components/Card'
import { PaymentDisciplineDealsQuery } from '../../../graphql/schema'
import { formatMoney } from '../../../utils/formatNumber'
import { NodeType } from '../../../types'
import DisciplineDeal from './DisciplineDeal'

type DisciplineDeal = NodeType<NonNullable<PaymentDisciplineDealsQuery['customerCompany']>['deals']>

interface DisciplineDealGroupProps {
  title: string
  deals: DisciplineDeal[]
}

const DisciplineDealGroup: FC<DisciplineDealGroupProps> = ({ title, deals }) => {
  const totalDealAmount = useMemo(() => {
    return deals.reduce((acc, deal) => acc + deal.amount, 0)
  }, [deals])

  return (
    <div className='grid gap-y-5'>
      <div className='grid grid-cols-3'>
        <Card className='flex flex-col'>
          <CardHeader>
            <CardTitle>{title} сделки</CardTitle>
          </CardHeader>
          <div className='col-span-2 flex flex-1 flex-col px-5 pb-5'>
            <div className='grid grid-cols-[1fr_auto_1fr] divide-y-1 divide-separators-primary rounded-md bg-surface-primary shadow-card'>
              <div className='col-span-3 grid grid-cols-subgrid divide-x-1 divide-separators-primary'>
                <div className='px-7 py-4 text-labels-secondary'>Сделки</div>
                <div className='px-7 py-4 text-labels-secondary'>На сумму</div>
              </div>

              <div className='col-span-3 grid grid-cols-subgrid divide-x-1 divide-separators-primary'>
                <div className='px-7 py-4 font-mono tabular-nums'>{deals.length}</div>
                <div className='px-7 py-4 font-mono tabular-nums'>{formatMoney(totalDealAmount / 100)}</div>
              </div>
            </div>
          </div>
        </Card>
      </div>

      {deals.map((deal) => (
        <Card key={deal._id} className='p-5'>
          <DisciplineDeal id={deal._id} amount={deal.amount} status={deal.status} />
        </Card>
      ))}
    </div>
  )
}

export default DisciplineDealGroup
