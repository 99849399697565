import { forwardRef, ComponentPropsWithoutRef } from 'react'
import { FieldError } from 'react-hook-form'
import { errorToString } from '../../../utils/errorToString'
import c from 'clsx'
import { IMaskInput, ReactMaskOpts } from 'react-imask'

interface MaskedPercentInputProps extends Omit<ComponentPropsWithoutRef<'input'>, 'onChange'> {
  label?: string
  error?: string | FieldError
  value?: string
  max?: number
  onChange: (e: { target: { value: string } }) => void
}

/**
 * Компонент для ввода чисел с разделителем разрядов пробелами и разделителем дробной части запятой
 */
const MaskedPercentInput = forwardRef<HTMLInputElement, MaskedPercentInputProps>(function Input(props, ref) {
  const { label, children, error, onChange, value, max, placeholder, inputMode, onBlur } = props

  const percentMaskOptions: ReactMaskOpts = {
    mask: 'num %',
    // adds percent to the end when not empty
    eager: true,
    blocks: {
      num: {
        mask: Number,
        scale: 2,
        max: max || 100
      }
    }
  }

  return (
    <div>
      {!!label && <div className='inp-label text-p350 mb-5'>{label}</div>}

      <label
        className={c(
          'group relative block rounded-xl bg-white-0 ring-1 ring-grayscale-400 focus-within:ring-red-100 hover:ring-grayscale-250 hover:focus-within:ring-red-100',
          !!error && 'ring-1 ring-red-100'
        )}
      >
        <IMaskInput
          value={value}
          {...percentMaskOptions}
          inputRef={ref}
          placeholder={placeholder}
          inputMode={inputMode}
          className='w-full border-none bg-transparent px-10 py-7 tabular-nums placeholder-grayscale-250 outline-none transition-opacity focus:ring-0'
          onAccept={(v) => {
            onChange && onChange({ target: { value: v } })
          }}
          onBlur={onBlur}
        />
        {children}
      </label>
      {error && <div className='text-p450 pl-4 pt-2 text-red-150'>{errorToString(error)}</div>}
    </div>
  )
})

export default MaskedPercentInput
