import { FC } from 'react'
import { FieldError } from 'react-hook-form'
import c from 'clsx'
import { errorToString } from '../../../utils/errorToString'
import { IMaskInput } from 'react-imask'
import { percentProps } from './PercentNumberInput.tsx'
import { formatMoneyDecimal } from '../../../utils/formatNumber.ts'
import parseDecimal from '../../../utils/parseDecimal.ts'

interface PercentNumberInputProps {
  percentValue: string
  value: string
  onPercentChange: (percent: string) => void
  label?: string
  error?: string | FieldError
}

const Input: FC<PercentNumberInputProps> = ({ label, error, onPercentChange, value, percentValue }) => {
  return (
    <div>
      {!!label && <div className='inp-label text-p350 mb-5'>{label}</div>}

      <div className='flex items-center gap-5'>
        <label
          className={c(
            'group flex relative bg-white-0 ring-grayscale-400 hover:focus-within:ring-red-100 focus-within:ring-red-100 hover:ring-grayscale-250 rounded-xl ring-1',
            !!error && 'ring-red-100 ring-1'
          )}
        >
          <IMaskInput
            value={percentValue?.replace('.', ',')}
            {...percentProps}
            // it accepts className prop
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            className='w-30 block tabular-nums box-content py-7 px-10 transition-opacity border-none outline-none bg-transparent focus:ring-0 placeholder-grayscale-250'
            onAccept={(_, { unmaskedValue }) => {
              if (unmaskedValue === percentValue) return
              onPercentChange(unmaskedValue.replace(',', '.'))
            }}
          />
        </label>
        <div>{`->`}</div>
        <div>{formatMoneyDecimal(parseDecimal(value) ? parseDecimal(value) : 0)}</div>
      </div>
      {error && <div className='text-red-150 text-p450 pl-4 pt-2'>{errorToString(error)}</div>}
    </div>
  )
}

export default Input
