import { toast } from 'sonner'
import { ReactComponent as CloseIcon } from '../../svg/icons/cross-xs.svg'

interface ToastParams {
  title: string
  subtitle: string
  autoCloseTimeout?: number
}

export const showPopup = ({ title, subtitle, autoCloseTimeout }: ToastParams) => {
  const toastId = toast(
    <div>
      <div className='mb-5 flex items-center justify-between'>
        <div className='font-semibold text-base-red'>{title}</div>
        <CloseIcon
          className='cursor-pointer text-labels-tertiary hover:text-base-red'
          onClick={() => toast.dismiss(toastId)}
        />
      </div>

      <div className='text-labels-primary'>{subtitle}</div>
    </div>,
    { duration: autoCloseTimeout }
  )
}
