import { FC, ReactElement, memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import c from 'clsx'
import useWebAnimations from '@wellyshen/use-web-animations'
import { throttle } from 'throttle-debounce'

interface CoutnerProps {
  value: number
}

export const getDigits = (value: number) => {
  const digits = [] // array of digits from least to most significant
  if (value === 0) return [0]
  let remainder = value
  while (remainder > 0) {
    digits.push(remainder % 10)
    remainder = Math.floor(remainder / 10)
  }
  return digits
}

/**
 * finds the number of digits between two digits on a drum in a given direction
 * @param direction drum rotation cw = 1, ccw = -1
 * @param from
 * @param to
 * @param n number of digits on drum
 * @returns number of digits between from and to in a given direction
 */
export const getDistance = (direction: number, from: number, to: number, n = 10) => {
  if (from === to) return 0
  const a = from % n
  const b = to % n
  const maxNum = n - 1
  const distance = Math.abs(a - b)

  if (direction === Math.sign(a - b)) {
    return maxNum - distance + 1
  } else {
    return distance
  }
}

// number of digits on drum
const N = 10

interface DigitProps {
  value?: number
  nextValue?: number
  revolutions: number
  direction: number
  fraction: number
  className?: string
}

const Digit: FC<DigitProps> = memo(({ value, nextValue, revolutions, direction, fraction, className }) => {
  const [mounted, setMounted] = useState(value !== undefined)
  const mounting = value === undefined
  const unmounting = nextValue === undefined

  const {
    ref: frameRef,
    animate: animateFrame,
    getAnimation: getFrameAnimation
  } = useWebAnimations<HTMLDivElement>({
    onFinish: () => {
      if (unmounting) {
        setMounted(false)
      }
    },
    onUpdate: () => {
      if (mounted) return
      if (!mounting) return
      const animation = getFrameAnimation()
      if (!animation) return
      const timing = animation?.effect?.getTiming()
      if (!animation.currentTime) return
      if (!timing?.delay) return
      if (Number(animation.currentTime) > timing.delay) {
        setMounted(true)
      }
    }
  })
  const { ref, animate, getAnimation } = useWebAnimations<HTMLDivElement>()

  const ribbon = []
  const distance = getDistance(direction, value || 0, nextValue || 0, N)

  for (let i = 0; i < distance + 1 + revolutions * N; i++) {
    // get the i'th number on a drum in a given direction
    // const number = (N + value + i * direction) % N
    let digit: number | string = (N + (value || 0) + ((N + i * direction) % N)) % N
    if (mounting && i === 0) digit = ' '
    if (unmounting && i === distance + 1 + revolutions * N - 1) digit = ' '
    ribbon.push(<div key={i}>{digit}</div>)
  }

  if (direction < 0) ribbon.reverse()
  //((7 + distance / N + revolutions * N) * 100) * 0.7
  // const duration = 1000 + (distance / 10 + revolutions * 10) * 100

  useLayoutEffect(() => {
    // stop previous animation
    getAnimation()?.cancel()
    getFrameAnimation()?.cancel()

    const duration = 2000 + (1 - fraction) * 2000
    animateFrame({
      keyframes: [
        { transform: direction > 0 ? 'translateY(0)' : 'translateY(100%)' },
        { transform: direction > 0 ? 'translateY(100%)' : 'translateY(0)' }
      ],
      animationOptions: {
        duration,
        delay: fraction * 1000,
        easing: 'ease-in-out',
        fill: 'both'
      }
    })
    animate({
      keyframes: [
        { transform: direction > 0 ? 'translateY(0)' : 'translateY(-100%)' },
        { transform: direction > 0 ? 'translateY(-100%)' : 'translateY(0)' }
      ],
      animationOptions: {
        duration,
        delay: fraction * 1000,
        easing: 'ease-in-out',
        fill: 'both'
      }
    })
  }, [value, nextValue, revolutions, direction, fraction, getAnimation, getFrameAnimation, animateFrame, animate])

  // const toStyle = direction > 0 ? '-translate-y-full' : 'translate-y-0'
  // const frameToStyle = direction > 0 ? 'translate-y-full' : 'translate-y-0'
  // const fromStyle = direction > 0 ? 'translate-y-0' : '-translate-y-full'
  // const frameFromStyle = direction > 0 ? 'translate-y-0' : 'translate-y-full'

  return (
    <span className={c(!mounted && 'hidden', 'relative inline-block overflow-hidden leading-full', className)}>
      <span className='invisible relative inline-block leading-full'>{value || 0}</span>
      <span className='pointer-events-none absolute inset-0 select-none leading-full'>
        <div ref={frameRef} className={'h-full leading-full'}>
          <div className='leading-full' ref={ref}>
            {ribbon}
          </div>
        </div>
      </span>
    </span>
  )
})

const Counter: FC<CoutnerProps> = memo(({ value: requestedNextValue }) => {
  const [nextValue, setNextValue] = useState(requestedNextValue)
  const valueRef = useRef(nextValue)
  const value = valueRef.current
  // const [targetValue, setTargetValue] = useState(nextValue)

  // split value into digits without using string
  const digits = getDigits(value) // array of digits from least to most significant
  const nextDigits = getDigits(nextValue) // array of digits from least to most significant

  // find index of first different digit
  let firstDiffIndex = Math.max(digits.length, nextDigits.length)
  while (digits[firstDiffIndex] === nextDigits[firstDiffIndex] && firstDiffIndex > 0) {
    firstDiffIndex--
  }

  const direction = nextValue >= value ? 1 : -1

  const result: ReactElement[] = []

  const renderedDigits = Math.max(nextDigits.length, digits.length)

  for (let i = 0; i < renderedDigits; i++) {
    const revolutions = Math.max(0, firstDiffIndex - i)

    result.push(
      <Digit
        key={i}
        className={i > 0 && i % 3 === 0 ? 'mr-8' : ''}
        fraction={(i + 1) / renderedDigits}
        value={digits[i]}
        nextValue={nextDigits[i]}
        revolutions={revolutions}
        direction={direction}
      />
    )
  }

  const throttledValueChange = useCallback(
    throttle(3000, (value: number) => {
      setNextValue((v) => {
        valueRef.current = v
        return value
      })
    }),
    [setNextValue]
  )

  useEffect(() => {
    if (requestedNextValue === valueRef.current) return
    throttledValueChange(requestedNextValue)
  }, [requestedNextValue, throttledValueChange, valueRef])

  return <div className='flex tabular-nums leading-full'>{result.reverse()}</div>
})

export default Counter
