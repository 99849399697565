import { FC } from 'react'
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'

interface ModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  children?: React.ReactNode
}

const Modal: FC<ModalProps> = ({ children, open, setOpen }) => {
  return (
    <Dialog className='relative z-50' open={open} onClose={() => setOpen(false)}>
      <DialogBackdrop
        transition
        className='bg-base-black/30 fixed inset-0 duration-500 ease-out data-[closed]:opacity-0'
      />
      <div className='fixed inset-0 overflow-y-auto py-10'>
        <div className='flex min-h-full w-screen items-center justify-center'>
          <DialogPanel transition className='duration-300 ease-out data-closed:scale-95 data-closed:opacity-0'>
            {open && children}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}

export default Modal
