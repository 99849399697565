import { FC, useState } from 'react'
import {
  DealRetrobonusStatus,
  DocumentTypeEntity,
  useRemoveDealRetrobonusMutation,
  useUpdateDealRetrobonusStatusMutation
} from '../../../graphql/schema'
import { formatDecimal } from '../../../utils/formatNumber'
import useAccessCheck from '../../../hooks/useAccessCheck'
import { ReactComponent as TrashIcon } from '../../../svg/ui/delete.svg'
import { ReactComponent as EditIcon } from '../../../svg/ui/edit.svg'
import Modal from '../../../components/Modal'
import ConfirmationForm from '../../../components/ConfirmationForm'
import EntityFolder from '../../../components/DocumentManager/EntityFolder'
import { Link } from 'react-router-dom'
import useEntityDocuments from '../../../components/DocumentManager/useEntityDocuments'
import Checkbox from '../../../components/Forms/Checkbox'
import CommissionRetrobonusForm, { CommissionRetrobonusEntity } from './CommissionRetrobonusForm'

const RETROBONUS_ACT_FOLDER_NAME = 'Акт ретробонуса'
const RETROBONUS_AGREEMENT_FOLDER_NAME = 'Договор ретробонуса'

interface CommissionRetrobonusProps {
  dealId: number
  retrobonusId: number
  retrobonusNumber: number
  dealSupplyId: number
  status: DealRetrobonusStatus
  supplierCompanyId?: number
  supplierCompanyName?: string
  rate?: number
  amount?: number

  onDelete?: () => void
}

const CommissionRetrobonus: FC<CommissionRetrobonusProps> = ({
  dealId,
  retrobonusId,
  retrobonusNumber,
  dealSupplyId,
  supplierCompanyId,
  supplierCompanyName,
  rate,
  amount,
  status,

  onDelete
}) => {
  const [updateDealRetrobonusStatus] = useUpdateDealRetrobonusStatusMutation()
  const [removeDealRetrobonus] = useRemoveDealRetrobonusMutation()
  const [deletionFormOpen, setDeletionFormOpen] = useState(false)

  const retrobonusDocs = useEntityDocuments({
    entityId: retrobonusId,
    entityType: DocumentTypeEntity.DealRetrobonus,
    folderName: RETROBONUS_ACT_FOLDER_NAME
  })
  const hasDocuments = !!retrobonusDocs.length

  const [editEntity, setEditEntity] = useState<CommissionRetrobonusEntity>()
  const [formOpen, setFormOpen] = useState(false)

  async function updateCommissionRetrobonusStatus(retrobonusId: number, status: DealRetrobonusStatus): Promise<void> {
    await updateDealRetrobonusStatus({
      variables: {
        input: {
          id: retrobonusId.toString(),
          status
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateDealRetrobonus: {
          __typename: 'updateDealRetrobonusPayload',
          dealRetrobonus: {
            __typename: 'DealRetrobonus',
            _id: retrobonusId,
            status
          }
        }
      }
    })
  }

  const hasAccessRetrobonusManage = useAccessCheck('deal.agent_commission.retrobonus.manage')
  const hasAccessRetrobonusEdit = useAccessCheck('deal.agent_commission.retrobonus.edit')

  return (
    <>
      <div className='col-span-1'>
        <div className='rounded-md bg-surface-primary pb-4 shadow-card'>
          <div className='p-10'>
            <h2 className='flex font-display text-h200'>
              Ретробонус №{retrobonusNumber}
              <button
                className='ml-auto flex cursor-pointer items-center leading-5 text-grayscale-200 hover:text-red-100'
                onClick={() => {
                  setEditEntity({
                    retrobonusId,
                    retrobonusNumber,
                    dealSupplyId,
                    rate,
                    amount
                  })
                  setFormOpen(true)
                }}
              >
                <EditIcon height='20px' width='20px' title='Редактировать' />
              </button>
            </h2>
            {supplierCompanyName && (
              <Link to={`/suppliers/${supplierCompanyId}`}>
                <p className='mt-3 text-grayscale-150 hover:text-red-100'>{supplierCompanyName}</p>
              </Link>
            )}
          </div>
          <div className='px-4 py-6'>
            <table className='conte w-full'>
              <tbody>
                <tr className='group'>
                  <td className='px-6 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    Ретробонус
                  </td>
                  <td className='px-6 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    {rate}%
                  </td>
                </tr>
                <tr className='group'>
                  <td className='px-6 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    Сумма ретробонуса
                  </td>
                  <td className='px-6 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    {formatDecimal(amount)} ₽
                  </td>
                </tr>
                <tr className='group'>
                  <td className='px-6 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    Статус
                  </td>
                  <td className='px-6 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    {status === DealRetrobonusStatus.Paid ? 'Получен' : 'Не получен'}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className='border-t-1 border-grayscale-400 px-10 py-6'>
            <div className='mb-6 font-display font-medium'>{RETROBONUS_ACT_FOLDER_NAME}</div>
            <EntityFolder
              entityId={retrobonusId}
              entityType={DocumentTypeEntity.DealRetrobonus}
              folderName={RETROBONUS_ACT_FOLDER_NAME}
              canDelete={status !== DealRetrobonusStatus.Paid}
            />
          </div>

          {supplierCompanyId && (
            <div className='border-t-1 border-grayscale-400 px-10 py-6'>
              <div className='mb-6 font-display font-medium'>{RETROBONUS_AGREEMENT_FOLDER_NAME}</div>
              <EntityFolder
                entityId={supplierCompanyId}
                entityType={DocumentTypeEntity.SupplierCompany}
                folderName={RETROBONUS_AGREEMENT_FOLDER_NAME}
                canDelete={false}
                canUpload={false}
              />
            </div>
          )}

          {hasAccessRetrobonusManage && hasDocuments && (
            <div className='border-t-1 border-grayscale-400 px-10 py-6'>
              <Checkbox
                label='Ретробонус получен'
                checked={status === DealRetrobonusStatus.Paid}
                onChange={(checked) =>
                  updateCommissionRetrobonusStatus(
                    retrobonusId,
                    checked ? DealRetrobonusStatus.Paid : DealRetrobonusStatus.Pending
                  )
                }
              />
            </div>
          )}
          {hasAccessRetrobonusEdit && status !== DealRetrobonusStatus.Paid && (
            <div
              className={
                'flex cursor-pointer items-center border-t-1 border-grayscale-400 px-12 py-6 pt-10 text-red-100'
              }
              onClick={() => setDeletionFormOpen(true)}
            >
              <TrashIcon height='16px' width='15px' className='mr-8' title='Удалить' />
              Удалить ретробонус
            </div>
          )}
        </div>
      </div>
      <Modal open={formOpen} setOpen={setFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <CommissionRetrobonusForm
            onDone={() => {
              setFormOpen(false)
            }}
            dealId={dealId.toString()}
            entity={editEntity}
          />
        </div>
      </Modal>
      <Modal open={deletionFormOpen} setOpen={setDeletionFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ConfirmationForm
            title='Удаление ретробонуса'
            onDone={async () => {
              setDeletionFormOpen(false)
              await removeDealRetrobonus({
                variables: { input: { id: retrobonusId.toString() } }
              })
              onDelete?.()
            }}
            onDismiss={() => setDeletionFormOpen(false)}
          >
            Вы действительно хотите удалить ретробонус?
          </ConfirmationForm>
        </div>
      </Modal>
    </>
  )
}

export default CommissionRetrobonus
