import { FC } from 'react'
import Badge from './UI/Badge'

type LeasingCategory = { _id: number; name: string } | undefined

interface LeasingSubjectCategoriesListProps {
  categories: LeasingCategory[]
}

const LeasingSubjectCategoriesList: FC<LeasingSubjectCategoriesListProps> = ({ categories }) => {
  return (
    <div className='flex gap-3 flex-wrap empty:hidden'>
      {categories.map((c) => (
        <Badge key={c?._id}>{c?.name}</Badge>
      ))}
    </div>
  )
}

export default LeasingSubjectCategoriesList
