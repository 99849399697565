import { FC, Fragment, useMemo, useState } from 'react'
import { ReactComponent as DownloadIcon } from '../../../svg/icons/fileDownload.svg'
import { ReactComponent as WarningIcon } from '../../../svg/ui/warning.svg'
import { useParams } from 'react-router-dom'
import useMultipleEntityDocuments from '../../../components/DocumentManager/useMultipleEntityDocuments'
import { DocumentTypeEntity, useDealForDealDocumentsQuery } from '../../../graphql/schema'
import DocumentManager from '../../../components/DocumentManager'
import LeasingContractForm from './LeasingContractForm'
import CommissionContractForm from './CommissionContractForm'
import InvoiceForm from './InvoiceForm'
import Hint from '../../../components/Hint'
import {
  COMMISSION_REWARD_FOLDER_ACT_NAME,
  COMMISSION_REWARD_FOLDER_INVOICE_NAME,
  GUARANTOR_CONTRACT_FOLDER_NAME,
  SHIPPING_FOLDER_NAME,
  SPECIFICATIONS_FOLDER_NAME,
  SUPPLY_FOLDER_NAME,
  TRANSFER_FOLDER_NAME
} from '../../../config'
import Folder from '../../../components/DocumentManager/Folder'
import DocumentIdsFolder from '../../../components/DocumentManager/DocumentIdsFolder'
import dealFormOpenState from '../state'
import CollectionForm from './Collection/CollectionForm.tsx'
import SuspenseModal from '../../../components/SuspenseModal.tsx'
import GroupZip from '../../../components/DocumentManager/GroupZip.tsx'
import { entityTypeDict } from '../../../utils/dictionaries.ts'
import Columns from '../../../components/Columns.tsx'
import useAccessCheck from '../../../hooks/useAccessCheck.ts'
import { Card } from '../../../components/Card.tsx'

const Documents: FC = () => {
  const { id } = useParams<'id'>()
  const dealId = parseInt(id || '')
  const [contractFormOpen, setContractFormOpen] = useState(false)
  const [commissionContractFormOpen, setCommissionContractFormOpen] = useState(false)
  const [invoiceFormOpen, setInvoiceFormOpen] = useState(false)
  const [collectionFormOpen, setCollectionFormOpen] = useState(false)

  const hasAccessCollectionManage = useAccessCheck('deal.documents.collection.manage')

  const { data, loading } = useDealForDealDocumentsQuery({
    variables: { id: id as string },
    skip: !id,
    fetchPolicy: 'cache-and-network'
  })
  const deal = data?.deal

  const commissionRewardIds = (deal?.agentCommissionRewards?.edges?.map((r) => r?.node?._id) as number[]) || []
  const supplyIds = (deal?.dealSupplies?.edges?.map((ds) => ds?.node?._id) as number[]) || []
  const guarantorIds = (deal?.guarantors?.edges?.map((g) => g?.node?._id) as number[]) || []
  const suppliesSpecs = deal?.dealSupplies?.edges?.map((ds) => ds?.node?.nomenclature).filter(Boolean)

  const hasSupplies = !!deal?.dealSupplies?.edges?.length
  // all suppliers have same vat kind
  const suppliersVatKindOk =
    hasSupplies &&
    deal?.dealSupplies?.edges?.every((ds, _, arr) => {
      const vc = ds?.node?.supplierCompany?.vatKind
      return !!vc && vc === arr[0]?.node?.supplierCompany?.vatKind
    })

  const specsAdded = !!suppliesSpecs?.length && suppliesSpecs.length === deal?.dealSupplies?.edges?.length
  const hasBankDetails = deal?.customerCompany?.bankDetails?.totalCount
  const hasContractNumber = deal?.contractNumber
  const hasContractDate = deal?.contractDate
  const hasAdvancePaymentDate = deal?.advancePaymentDate
  const hasInsuranceKind = deal?.insuranceKind

  const readyContract =
    hasSupplies &&
    hasBankDetails &&
    hasContractNumber &&
    hasContractDate &&
    hasAdvancePaymentDate &&
    hasInsuranceKind &&
    hasSupplies &&
    specsAdded &&
    suppliersVatKindOk
  const readyComissionContract = hasBankDetails
  const readyInvoice = hasBankDetails && hasContractNumber && hasContractDate && hasAdvancePaymentDate
  const readyCollection = hasContractNumber && hasContractDate

  const frameContractsIds = useMemo(() => {
    const result = new Set<number>(
      (deal?.dealSupplies?.edges?.map((ds) => ds?.node?.frameContractDocument?._id) as number[]) || []
    )
    return Array.from(result).filter(Boolean)
  }, [deal?.dealSupplies])

  const [commissionActDocs, commissionActDocsLoading] = useMultipleEntityDocuments({
    entityIds: commissionRewardIds,
    entityType: DocumentTypeEntity.AgentCommissionReward,
    folderName: COMMISSION_REWARD_FOLDER_ACT_NAME
  })

  const [commissionInvoiceDocs, commissionInvoiceDocsLoading] = useMultipleEntityDocuments({
    entityIds: commissionRewardIds,
    entityType: DocumentTypeEntity.AgentCommissionReward,
    folderName: COMMISSION_REWARD_FOLDER_INVOICE_NAME
  })

  const [supplyContractDocs, supplyDocsLoading] = useMultipleEntityDocuments({
    entityIds: supplyIds,
    entityType: DocumentTypeEntity.DealSupply,
    folderName: SUPPLY_FOLDER_NAME
  })

  const [supplySpecificationsDocs, supplySpecificationsDocsLoading] = useMultipleEntityDocuments({
    entityIds: supplyIds,
    entityType: DocumentTypeEntity.DealSupply,
    folderName: SPECIFICATIONS_FOLDER_NAME
  })

  const [guarantorDocs, guarantorDocsLoading] = useMultipleEntityDocuments({
    entityIds: guarantorIds,
    entityType: DocumentTypeEntity.Guarantor,
    folderName: GUARANTOR_CONTRACT_FOLDER_NAME
  })

  const [supplyShippingDocs, supplyShippingDocsLoading] = useMultipleEntityDocuments({
    entityIds: supplyIds,
    entityType: DocumentTypeEntity.DealSupply,
    folderName: SHIPPING_FOLDER_NAME
  })

  const [supplyTransferDocs, supplyTransferDocsLoading] = useMultipleEntityDocuments({
    entityIds: supplyIds,
    entityType: DocumentTypeEntity.DealSupply,
    folderName: TRANSFER_FOLDER_NAME
  })

  const shippingAndTransferDocs = [...supplyShippingDocs, ...supplyTransferDocs]
  const shippingAndTransferDocsLoading = supplyShippingDocsLoading || supplyTransferDocsLoading

  return (
    <Fragment>
      <h3 className='mb-8 text-h200'>Инструменты</h3>
      <Card>
        <div className='mt-8 grid grid-cols-3 gap-5 p-5'>
          <Columns n={3}>
            <div className='mb-5'>
              <div className='rounded-md bg-white-0 px-8 py-8 shadow-xs'>
                <button
                  onClick={() => setContractFormOpen(true)}
                  disabled={!readyContract}
                  className='flex h-25 w-full items-center justify-center gap-5 rounded-md border-1 border-dashed border-grayscale-300 px-4 text-grayscale-250 hover:text-grayscale-200 disabled:hover:text-grayscale-250'
                >
                  {readyContract ? <DownloadIcon /> : <WarningIcon />}
                  Договор лизинга
                </button>
                {!readyContract && !loading && (
                  <div className='grid gap-y-2'>
                    <div className='px-8 pb-4 pt-6 text-grayscale-150'>Необходимо заполнить:</div>
                    {!hasBankDetails && (
                      <Hint
                        text='Банковские реквизиты компании'
                        to={`/customers/${data?.deal?.customerCompany?._id}`}
                      />
                    )}
                    {!hasSupplies && <Hint text='Поставки' to={`/deals/${id}/supplies`} />}
                    {!hasContractNumber && <Hint text='Номер договора' onClick={() => dealFormOpenState(true)} />}
                    {!hasContractDate && <Hint text='Дата договора' onClick={() => dealFormOpenState(true)} />}
                    {!hasAdvancePaymentDate && (
                      <Hint text='Дата внесения аванса' onClick={() => dealFormOpenState(true)} />
                    )}
                    {!hasInsuranceKind && <Hint text='Страховка' onClick={() => dealFormOpenState(true)} />}
                    {!specsAdded && <Hint text='Номенклатуры всех поставок' to={`/deals/${id}/supplies`} />}
                    {!suppliersVatKindOk && (
                      <Hint text='Один тип НДС у всех поставщиков' to={`/deals/${id}/supplies`} />
                    )}
                  </div>
                )}
                <SuspenseModal open={contractFormOpen} setOpen={setContractFormOpen}>
                  <div className='z-10 rounded-xl bg-white-0'>
                    <LeasingContractForm
                      dealId={dealId}
                      onDone={() => {
                        setContractFormOpen(false)
                      }}
                    />
                  </div>
                </SuspenseModal>
              </div>
            </div>
            <div className='mb-5'>
              <div className='rounded-md bg-white-0 px-8 py-8 shadow-xs'>
                <button
                  onClick={() => setCommissionContractFormOpen(true)}
                  disabled={!readyComissionContract}
                  className='flex h-25 w-full items-center justify-center gap-5 rounded-md border-1 border-dashed border-grayscale-300 px-4 text-grayscale-250 hover:text-grayscale-200 disabled:hover:text-grayscale-250'
                >
                  {readyComissionContract ? <DownloadIcon /> : <WarningIcon />}
                  Счёт-договор на комиссию
                </button>
                {!readyComissionContract && !loading && (
                  <div className='grid gap-y-2'>
                    <div className='px-8 pb-4 pt-6 text-grayscale-150'>Необходимо заполнить:</div>
                    {!hasBankDetails && (
                      <Hint
                        text='Банковские реквизиты компании'
                        to={`/customers/${data?.deal?.customerCompany?._id}`}
                      />
                    )}
                  </div>
                )}
                <SuspenseModal open={commissionContractFormOpen} setOpen={setCommissionContractFormOpen}>
                  <div className='z-10 rounded-xl bg-white-0'>
                    <CommissionContractForm
                      dealId={dealId}
                      onDone={() => {
                        setCommissionContractFormOpen(false)
                      }}
                    />
                  </div>
                </SuspenseModal>
              </div>
            </div>

            <div className='mb-5'>
              <div className='rounded-md bg-white-0 px-8 py-8 shadow-xs'>
                <button
                  onClick={() => setInvoiceFormOpen(true)}
                  disabled={!readyInvoice}
                  className='flex h-25 w-full items-center justify-center gap-5 rounded-md border-1 border-dashed border-grayscale-300 px-4 text-grayscale-250 hover:text-grayscale-200 disabled:hover:text-grayscale-250'
                >
                  {readyInvoice ? <DownloadIcon /> : <WarningIcon />}
                  Счёт на оплату
                </button>
                {!readyInvoice && !loading && (
                  <div className='grid gap-y-2'>
                    <div className='px-8 pb-4 pt-6 text-grayscale-150'>Необходимо заполнить:</div>
                    {!hasBankDetails && (
                      <Hint
                        text='Банковские реквизиты компании'
                        to={`/customers/${data?.deal?.customerCompany?._id}`}
                      />
                    )}
                    {!hasContractNumber && <Hint text='Номер договора' onClick={() => dealFormOpenState(true)} />}
                    {!hasContractDate && <Hint text='Дата договора' onClick={() => dealFormOpenState(true)} />}
                    {!hasAdvancePaymentDate && (
                      <Hint text='Дата внесения аванса' onClick={() => dealFormOpenState(true)} />
                    )}
                  </div>
                )}
                <SuspenseModal open={invoiceFormOpen} setOpen={setInvoiceFormOpen}>
                  <div className='z-10 rounded-xl bg-white-0'>
                    <InvoiceForm
                      dealId={dealId}
                      onDone={() => {
                        setInvoiceFormOpen(false)
                      }}
                    />
                  </div>
                </SuspenseModal>
              </div>
            </div>
            {hasAccessCollectionManage && (
              <div>
                <div className='rounded-md bg-white-0 px-8 py-8 shadow-xs'>
                  <button
                    onClick={() => setCollectionFormOpen(true)}
                    disabled={!readyCollection}
                    className='flex h-25 w-full items-center justify-center gap-5 rounded-md border-1 border-dashed border-grayscale-300 px-4 text-grayscale-250 hover:text-grayscale-200 disabled:hover:text-grayscale-250'
                  >
                    {readyCollection ? <DownloadIcon /> : <WarningIcon />}
                    Коллекторские дела
                  </button>
                  {!readyCollection && !loading && (
                    <div className='grid gap-y-2'>
                      <div className='px-8 pb-4 pt-6 text-grayscale-150'>Необходимо заполнить:</div>
                      {!hasContractNumber && <Hint text='Номер договора' onClick={() => dealFormOpenState(true)} />}
                      {!hasContractDate && <Hint text='Дата договора' onClick={() => dealFormOpenState(true)} />}
                    </div>
                  )}
                  <SuspenseModal open={collectionFormOpen} setOpen={setCollectionFormOpen}>
                    <div className='z-10 rounded-xl bg-white-0'>
                      <CollectionForm dealId={dealId} />
                    </div>
                  </SuspenseModal>
                </div>
              </div>
            )}
          </Columns>
        </div>
      </Card>

      <h3 className='my-8 text-h200'>Документы сделки</h3>
      <DocumentManager entityId={dealId} entityType={DocumentTypeEntity.Deal}>
        <div className='relative mb-5 rounded-xl bg-white-0 p-10 shadow-xs'>
          <div className='flex items-center justify-between gap-x-5 pb-12'>
            <h3 className='font-display text-h200'>Акты источника</h3>
            <GroupZip
              name={`${entityTypeDict[DocumentTypeEntity.Deal]} ${dealId} Акты источника`}
              ids={commissionActDocs.map((doc) => doc._id)}
            />
          </div>
          <Folder files={commissionActDocs} loading={commissionActDocsLoading} canDelete={false} />
        </div>
        <div className='relative mb-5 rounded-xl bg-white-0 p-10 shadow-xs'>
          <div className='flex items-center justify-between gap-x-5 pb-12'>
            <h3 className='font-display text-h200'>Счета-фактуры источника</h3>
            <GroupZip
              name={`${entityTypeDict[DocumentTypeEntity.Deal]} ${dealId} Счета-фактуры источника`}
              ids={commissionInvoiceDocs.map((doc) => doc._id)}
            />
          </div>
          <Folder files={commissionInvoiceDocs} loading={commissionInvoiceDocsLoading} canDelete={false} />
        </div>
        <div className='relative mb-5 rounded-xl bg-white-0 p-10 shadow-xs'>
          <div className='flex items-center justify-between gap-x-5 pb-12'>
            <h3 className='font-display text-h200'>Договоры поставки</h3>
            <GroupZip
              name={`${entityTypeDict[DocumentTypeEntity.Deal]} ${dealId} Договоры поставки`}
              ids={frameContractsIds}
            />
          </div>
          <DocumentIdsFolder fileIds={frameContractsIds} loading={supplyDocsLoading} canDelete={false} />
        </div>
        <div className='relative mb-5 rounded-xl bg-white-0 p-10 shadow-xs'>
          <div className='flex items-center justify-between gap-x-5 pb-12'>
            <h3 className='font-display text-h200'>Нерамочные договоры поставки</h3>
            <GroupZip
              name={`${entityTypeDict[DocumentTypeEntity.Deal]} ${dealId} Нерамочные договоры поставки`}
              ids={supplyContractDocs.map((doc) => doc._id)}
            />
          </div>
          <Folder files={supplyContractDocs} loading={supplyDocsLoading} canDelete={false} />
        </div>
        <div className='relative mb-5 rounded-xl bg-white-0 p-10 shadow-xs'>
          <div className='flex items-center justify-between gap-x-5 pb-12'>
            <h3 className='font-display text-h200'>Спецификации</h3>
            <GroupZip
              name={`${entityTypeDict[DocumentTypeEntity.Deal]} ${dealId} Спецификации`}
              ids={supplySpecificationsDocs.map((doc) => doc._id)}
            />
          </div>
          <Folder files={supplySpecificationsDocs} loading={supplySpecificationsDocsLoading} canDelete={false} />
        </div>
        <div className='relative mb-5 rounded-xl bg-white-0 p-10 shadow-xs'>
          <div className='flex items-center justify-between gap-x-5 pb-12'>
            <h3 className='font-display text-h200'>Договоры поручительства</h3>
            <GroupZip
              name={`${entityTypeDict[DocumentTypeEntity.Deal]} ${dealId} Договоры поручительства`}
              ids={guarantorDocs.map((doc) => doc._id)}
            />
          </div>
          <Folder files={guarantorDocs} loading={guarantorDocsLoading} canDelete={false} />
        </div>
        <div className='relative mb-5 rounded-xl bg-white-0 p-10 shadow-xs'>
          <div className='flex items-center justify-between gap-x-5 pb-12'>
            <h3 className='font-display text-h200'>Отгрузочные документы</h3>
            <GroupZip
              name={`${entityTypeDict[DocumentTypeEntity.Deal]} ${dealId} Отгрузочные документы`}
              ids={shippingAndTransferDocs.map((doc) => doc._id)}
            />
          </div>
          <Folder files={shippingAndTransferDocs} loading={shippingAndTransferDocsLoading} canDelete={false} />
        </div>
      </DocumentManager>

      <h3 className='my-8 text-h200'>Документы компании-клиента</h3>
      {deal?.customerCompany && (
        <DocumentManager entityId={deal?.customerCompany?._id} entityType={DocumentTypeEntity.CustomerCompany} />
      )}
    </Fragment>
  )
}

export default Documents
