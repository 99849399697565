import { FC } from 'react'
import { BaseHistoryEventProps } from '../..'
import { EventActedAt, EventInitiatedBy } from '../EventComponents'

export interface ApplicationContactAddedEvent extends BaseHistoryEventProps {
  data?: {
    fio?: string
    phone?: string
  }
}

const ApplicationContactAddedEvent: FC<ApplicationContactAddedEvent> = ({
  actedAt,
  data,
  withLine,
  initiatedByUserId
}) => {
  return (
    <div className='flex gap-6'>
      <EventActedAt date={actedAt} withLine={withLine} />
      <div>
        <div className='flex items-start gap-2'>
          <EventInitiatedBy userId={initiatedByUserId} />
          <span className='text-grayscale-200'>создал(а) контакт</span>
        </div>
        <span>
          {data?.fio} {data?.phone}
        </span>
      </div>
    </div>
  )
}

export default ApplicationContactAddedEvent
