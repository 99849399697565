import { ReactComponent as ChevronIcon } from '../../svg/icons/chevron.svg'
import { ReactComponent as ArrowUpIcon } from '../../svg/icons/arrowUpSort.svg'
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { FC } from 'react'
import c from 'clsx'

interface SortMenuProps {
  title: string
  sort?: string
  order: { [key: string]: string }
  setOrder: (order: { [key: string]: string }) => void
  className?: string
}

const SortMenu: FC<SortMenuProps> = ({ title, sort, order, setOrder, className }) => {
  return (
    <Menu as='div' className={c(className, 'relative')}>
      <MenuButton className={'flex h-full w-full items-center gap-2 px-7 py-4'}>
        <span className='text-left text-grayscale-150'>{title}</span>
        {sort && (
          <span className='flex flex-col'>
            <ChevronIcon className={c('h-3 w-4 -rotate-180', order && order[`${sort}`] === 'asc' && 'text-red-100')} />
            <ChevronIcon className={c('h-3 w-4', order && order[`${sort}`] === 'desc' && 'text-red-100')} />
          </span>
        )}
      </MenuButton>
      <Transition
        as='div'
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <MenuItems className='absolute -left-1px top-full w-100 rounded-xl border-[1px] border-grayscale-400 bg-white-0 text-p200 font-medium'>
          <MenuItem>
            <div
              onClick={() => setOrder({ [`${sort}`]: 'asc' })}
              className='flex cursor-pointer items-center gap-8 px-7 py-4 first:rounded-t-xl last:rounded-b-xl hover:bg-grayscale-400'
            >
              <ArrowUpIcon />
              По возрастанию
            </div>
          </MenuItem>
          <MenuItem>
            <div
              onClick={() => setOrder({ [`${sort}`]: 'desc' })}
              className='flex cursor-pointer items-center gap-8 px-7 py-4 first:rounded-t-xl last:rounded-b-xl hover:bg-grayscale-400'
            >
              <ArrowUpIcon className='rotate-180' />
              По убыванию
            </div>
          </MenuItem>
        </MenuItems>
      </Transition>
    </Menu>
  )
}

export default SortMenu
