export interface UserLike {
  name: string
  surname: string
}

export default function getFullName(user: UserLike | undefined, shortSurname: boolean = false): string {
  if (!user) return ''

  const surname = shortSurname ? user.surname.charAt(0).toUpperCase() + '.' : user.surname

  return user.name + ' ' + surname
}
