import { useCallback } from 'react'
import { useFindOrCreateBoVerificationMutation } from '../graphql/schema'
import copyToClipboard from 'copy-to-clipboard'
import { env } from '../env'

const useCopyVerificationLink = () => {
  const [addBoVerification, { loading: linkLoading }] = useFindOrCreateBoVerificationMutation()

  const loadAndCopyLink = useCallback(async (contactId: number) => {
    const { data } = await addBoVerification({
      variables: {
        input: {
          contactId
        }
      },
    })
    const uuid = data?.findOrCreateBoVerification?.uuid
    if (!uuid) return
    const url = `${env.REACT_APP_PUBLIC_VERIFICATION_URL}?uuid=${uuid}`
    // copy to clipboard
    copyToClipboard(url)
  }, [addBoVerification])

  return [loadAndCopyLink, linkLoading] as const
}

export default useCopyVerificationLink
