import { FC, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { DocumentTypeEntity, useContactByIdQuery, useContactGuarantorDocumentsQuery } from '../../graphql/schema'
import DocumentManager from '../../components/DocumentManager'
import { GUARANTOR_CONTRACT_FOLDER_NAME } from '../../config'
import useNodes from '../../hooks/useNodes'
import Folder from '../../components/DocumentManager/Folder'
import GroupZip from '../../components/DocumentManager/GroupZip'
import { entityTypeDict } from '../../utils/dictionaries'

const Documents: FC = () => {
  const { id } = useParams<'id'>()
  const { data } = useContactByIdQuery({ variables: { id: id?.toString() as string }, skip: !id })

  const { data: guarantorContractsData, loading: guarantorContractsLoading } = useContactGuarantorDocumentsQuery({
    variables: {
      contact: id as string,
      type: GUARANTOR_CONTRACT_FOLDER_NAME
    },
    nextFetchPolicy: 'cache-and-network',
    skip: !id
  })
  const guarantorContracts = useNodes(guarantorContractsData?.contactGuarantorDocuments?.edges)

  const contact = data?.contact

  if (!contact) return null
  return (
    <Fragment>
      <DocumentManager entityId={contact._id} entityType={DocumentTypeEntity.Contact}>
        <div className='p-10 rounded-xl bg-white-0 mb-5 shadow-xs relative'>
          <div className='pb-12 gap-x-5 flex justify-between items-center'>
            <h3 className='text-h200 font-display'>Договоры поручительства</h3>
            <GroupZip
              name={`${entityTypeDict[DocumentTypeEntity.Contact]} ${id} Договоры поручительства`}
              ids={guarantorContracts.map((doc) => doc._id)}
            />
          </div>
          <Folder files={guarantorContracts} loading={guarantorContractsLoading} canDelete={false} />
        </div>
      </DocumentManager>
    </Fragment>
  )
}

export default Documents
