import { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { ReactComponent as EditIcon } from '../../../svg/icons/edit.svg'
import SubmitButton from '../../../components/SubmitButton'
import { UserRoles } from '../../../graphql/schema.tsx'
import ManagerInput from '../../../components/Forms/Inputs/ManagerInput.tsx'

type Inputs = {
  risksUserId: number
}

interface ScoringRisksUserFormProps {
  userId?: number
  loading?: boolean
  onDone: (risksUserId: number) => void
}

const ScoringRisksUserForm: FC<ScoringRisksUserFormProps> = ({ userId, loading, onDone }) => {
  const { handleSubmit, control, formState } = useForm<Inputs>({
    defaultValues: {
      risksUserId: userId
    }
  })

  const onSubmit: SubmitHandler<Inputs> = async (data) => onDone(data.risksUserId)

  return (
    <section className='md: w-[448px] p-12'>
      <h1 className='mb-12 font-display text-h200'>Менеджеры Fera</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='relative mb-12 flex flex-col gap-8'>
          <Controller
            name='risksUserId'
            control={control}
            render={({ field }) => (
              <ManagerInput
                selected={field.value ? [field.value] : []}
                onChange={(selected) =>
                  selected.length ? field.onChange(selected[selected.length - 1]) : field.onChange(null)
                }
                label='Менеджер отдела скоринга'
                placeholder='Выберите менеджера'
                error={formState.errors?.risksUserId}
                role={UserRoles.RoleRisks}
              />
            )}
          />
        </div>
        <SubmitButton loading={loading}>
          <EditIcon className='mr-5' />
          Изменить
        </SubmitButton>
      </form>
    </section>
  )
}

export default ScoringRisksUserForm
