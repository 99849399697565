import { FC } from 'react'
import { useSupplierFilter } from './FilterForm'
import SelectedFilter from '../../components/Filter/SelectedFilter'
import { EngagePartnerStatus } from '../../graphql/schema.tsx'
import UserName from '../../components/UserName.tsx'
import { Tag } from '../../components/Tags/Tag.tsx'

const SelectedFilters: FC = () => {
  const { filterState, setFilterState, filterActive } = useSupplierFilter()

  if (!filterActive || filterState?.query?.length) return null

  return (
    <div className='mb-8 flex flex-wrap gap-2'>
      {Object.keys(filterState).map((key) => {
        //@ts-ignore
        const objValue = filterState[key]
        switch (key) {
          case 'statuses':
            return objValue?.map((filter: { value: string; name: string }) => (
              <SelectedFilter
                key={filter.name + filter.value}
                onRemove={() =>
                  setFilterState({
                    ...filterState,
                    [key]: (objValue as { value: EngagePartnerStatus; name: string }[]).filter(
                      (i) => i.value !== filter.value
                    )
                  })
                }
              >
                {filter.name}
              </SelectedFilter>
            ))
          case 'bdmUsers':
            return objValue?.map((filter: number) => (
              <SelectedFilter
                key={key + filter}
                onRemove={() =>
                  setFilterState({
                    ...filterState,
                    [key]: (objValue as number[]).filter((id) => id !== filter)
                  })
                }
              >
                <UserName userId={filter} />
              </SelectedFilter>
            ))
          case 'categories':
            return objValue?.map((filter: { value: string; name: string }) => (
              <SelectedFilter
                key={filter.name + filter.value}
                onRemove={() =>
                  setFilterState({
                    ...filterState,
                    [key]: (objValue as { value: string; name: string }[]).filter((i) => i.value !== filter.value)
                  })
                }
              >
                {filter.name}
              </SelectedFilter>
            ))
          case 'tagsList':
            return objValue?.map((filter: Tag) => (
              <SelectedFilter
                key={filter._id}
                onRemove={() =>
                  setFilterState({
                    ...filterState,
                    [key]: (objValue as Tag[]).filter((i) => i._id !== filter._id)
                  })
                }
              >
                {filter.name}
              </SelectedFilter>
            ))
        }
      })}
    </div>
  )
}

export default SelectedFilters
