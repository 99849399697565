import * as Sentry from '@sentry/react'
import { jwtVar } from '../graphql/cache.ts'
import parseJWT from './parseJWT.ts'
import { env } from '../env.ts'

// listen to changes in the jwtVar and update the Sentry user accordingly
const updateSentryUser = () => {
  jwtVar.onNextChange(updateSentryUser)

  const jwt = jwtVar()

  if (!jwt) {
    Sentry.setUser(null)
    Sentry.setTags({ 'user.roles': null })
    return
  }

  const userData = parseJWT(jwt)
  Sentry.setUser({ id: userData?.id, name: userData?.username, roles: userData?.roles })
  Sentry.setTags({ 'user.roles': userData?.roles.join(',') })
}

const enableSentry = ['production', 'staging'].includes(env.REACT_APP_ENVIRONMENT as string)

const initSentry = () => {
  if (!enableSentry) return

  Sentry.init({
    dsn: 'https://47ed8a0c398f46d7b42b1911a19f430c@mysentry.io/37',
    environment: env.REACT_APP_ENVIRONMENT,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    // Session Replay
    replaysSessionSampleRate: 1.0, // how many sessions are recorded
    replaysOnErrorSampleRate: 1.0 // how many recorded sessions with error are saved
  })

  updateSentryUser()
}

export default initSentry
