import {
  forwardRef,
  ComponentPropsWithoutRef,
  useState,
  useCallback,
  FormEvent,
  useEffect,
  Children,
  useRef
} from 'react'
import { useWindowEvent } from '../hooks/useWindowEvent'
import { usePopper } from 'react-popper'
import c from 'clsx'
import useThrottledState from '../hooks/useThrottledState'
import clsx from 'clsx'
import { Placement } from '@popperjs/core'
import { FieldError } from 'react-hook-form'
import { errorToString } from '../utils/errorToString'

import { ReactComponent as SearchIcon } from '../svg/icons/search.svg'
import { ReactComponent as CrossIcon } from '../svg/icons/cross.svg'

interface SelectProps {
  label?: string
  editable?: boolean
  error?: string | FieldError
  throttleTime?: number
  onQuery?: (query: string) => void
  placement?: Placement
  clearSelected?: () => void
}

const Select = forwardRef<HTMLInputElement, ComponentPropsWithoutRef<'input'> & SelectProps>(function Input(
  {
    label,
    children,
    error,
    throttleTime = 500,
    editable = false,
    onQuery,
    placement = 'bottom',
    clearSelected,
    ...props
  },
  ref: any
) {
  const [open, setOpen] = useState(false)

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
    modifiers: [
      { name: 'preventOverflow', enabled: true },
      { name: 'flip', enabled: false },
      { name: 'offset', options: { offset: [0, 10] } }
    ]
  })

  // закрываем варианты, когда пользователь кликает вне их
  useWindowEvent('pointerdown', (event) => {
    if (!open) return
    if (popperElement?.contains(event.target as Node)) return
    if (referenceElement?.contains(event.target as Node)) return
    setOpen(false)
    event.preventDefault()
  })

  const handleClick = useCallback(() => {
    if (open) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }, [open])

  const [editMode, setEditMode] = useState<boolean>(false)
  const [inputText, setInputText] = useState<string>('')
  const inputTextRef = useRef<HTMLInputElement>(null)
  const inputSearchRef = useRef<HTMLInputElement>(null)
  const [queryText, setQueryText] = useState<string>('')
  const [query] = useThrottledState(queryText, throttleTime)

  useEffect(() => {
    if (onQuery && (editable || props.multiple)) {
      onQuery(query)
    }
  }, [onQuery, editable, query, props.multiple])

  useEffect(() => {
    if (editMode) {
      inputTextRef.current?.focus()
    }
  }, [editMode])

  useEffect(() => {
    if (open) {
      inputSearchRef.current?.focus()
    }
  }, [open])

  return (
    <div>
      {!!label && <div className='inp-label text-p350 mb-5'>{label}</div>}

      <div
        ref={setReferenceElement}
        onClick={handleClick}
        title={props?.value?.toString()}
        className={c(
          'group pointer-events-none relative block rounded-xl bg-white-0 ring-1 ring-grayscale-400 focus-within:ring-red-100 hover:ring-grayscale-250 hover:focus-within:ring-red-100',
          !!error && 'bg-red-100 bg-opacity-5 ring-1 ring-red-100',
          props.disabled && 'text-labels-tertiary'
        )}
      >
        <input
          className={clsx(
            'inp pointer-events-auto w-full cursor-pointer border-none bg-transparent px-10 py-7 placeholder-grayscale-250 outline-none transition-opacity focus:ring-0',
            !editMode && 'hidden'
          )}
          placeholder='Поиск по строке...'
          ref={inputTextRef}
          onBlur={() => {
            if (!editable) return
            setEditMode(false)
          }}
          onInput={(el: FormEvent<HTMLInputElement>) => {
            if (!editable) return
            const inputEl = el.target as HTMLInputElement
            setInputText(inputEl.value)
            setQueryText(inputEl.value)
            !open && setOpen(true)
          }}
          value={inputText}
        />
        <input
          className={clsx(
            'inp pointer-events-auto w-full cursor-pointer overflow-ellipsis border-none bg-transparent px-10 py-7 placeholder-grayscale-250 outline-none transition-opacity focus:ring-0',
            editMode && 'hidden'
          )}
          ref={ref}
          readOnly={true}
          onClick={(event) => {
            props.onClick?.(event)
            if (!editable) return
            const inputEl = event.target as HTMLInputElement
            setEditMode(true)
            setInputText(!props.multiple ? inputEl.value : '')
            !open && setOpen(true)
          }}
          onFocus={(event) => {
            props.onFocus?.(event)
            setQueryText('')
          }}
          {...props}
        />
        {clearSelected && props?.value && !props.disabled && (
          <CrossIcon
            className='pointer-events-auto absolute right-10 top-10 cursor-pointer text-grayscale-250 hover:text-red-100/70'
            height='12px'
            width='12px'
            onClick={(event) => {
              event.stopPropagation()
              if (clearSelected) {
                clearSelected()
              }
            }}
          />
        )}
      </div>
      {error && <div className='text-p450 pl-4 pt-2 text-red-150'>{errorToString(error)}</div>}
      {open && (Children.count(children) > 0 || props.multiple) && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className='absolute z-50 flex max-h-170 w-full'
        >
          <div className='text-p450 flex max-h-full w-full flex-col rounded-xl bg-white-0 text-grayscale-150 shadow-lg ring-1 ring-grayscale-400'>
            {!!onQuery && props.multiple && (
              <div className='flex h-29 flex-none items-center border-b-1 border-grayscale-350'>
                <SearchIcon className='mx-10 text-grayscale-200' />
                <input
                  className='flex-grow border-none placeholder-grayscale-250 outline-none ring-0 focus:ring-0'
                  placeholder='Поиск'
                  onChange={(e) => setQueryText(e.target.value)}
                  value={queryText}
                  ref={inputSearchRef}
                />
              </div>
            )}
            <ul
              onClick={() => {
                !props.multiple && setOpen(false)
                setEditMode(false)
                inputSearchRef.current?.focus()
                setQueryText('')
              }}
              className={c('overflow-y-auto scrollbar-hide')}
            >
              {children}
            </ul>
          </div>
        </div>
      )}
    </div>
  )
})

export default Select
