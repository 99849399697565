import { FC, ReactNode, Children } from 'react'
import c from 'clsx'
import { ReactComponent as FileUploadIcon } from '../../svg/icons/fileUpload.svg'
import { DocumentNodeFragment } from '../../graphql/schema'
import { useDropzone } from 'react-dropzone'
import { ReactComponent as LoadingIcon } from '../../svg/icons/loading.svg'
import Document from './Document'

interface FolderProps {
  onUpload?: (files: File[]) => Promise<void>
  files?: DocumentNodeFragment[]
  children?: ReactNode
  limit?: number
  canDelete?: boolean
  canUpload?: boolean
  loading?: boolean
}

const Folder: FC<FolderProps> = ({
  onUpload,
  files,
  limit = 0,
  canDelete = true,
  loading = false,
  children,
  ...props
}) => {
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: onUpload,
    multiple: limit !== 1,
    noClick: true,
    maxFiles: limit
  })

  const canUpload = props.canUpload && !!onUpload
  const filesCount = (files?.length || 0) + Children.count(children)

  return (
    <div {...getRootProps()} className='flex flex-col gap-12'>
      <div className='empty:hidden'>
        {files?.map((doc) => (
          <div key={doc._id} className='py-10 first:pt-0 last:pb-0 border-b-1 border-grayscale-450 last:border-b-0'>
            <Document doc={doc} canDelete={canDelete} />
          </div>
        ))}
        {Children.map(children, (child, i) => (
          <div key={`file-${i}`} className='py-10 first:pt-0 last:pb-0 border-b-1 border-grayscale-450 last:border-b-0'>
            {child}
          </div>
        ))}
      </div>
      {/* show button when there is no limit or file amount does not exceed it */}
      {(!limit || (files?.length || 0) < limit) && canUpload ? (
        <div
          onClick={open}
          className={c(
            isDragActive && 'ring-2 ring-red-50 border-opacity-0',
            'cursor-pointer h-25 border-1 border-grayscale-300 border-dashed rounded-md flex gap-5 items-center justify-center w-full text-grayscale-250 hover:text-grayscale-200'
          )}
        >
          <FileUploadIcon />
          Добавить файлы
          <label className='cursor-pointer'>
            <input
              {...getInputProps()}
              className='absolute cursor-pointer inset-0 w-full h-full opacity-0'
              type='file'
            />
          </label>
        </div>
      ) : (
        !filesCount && (
          <div className='h-25 border-1 border-grayscale-300 border-dashed rounded-xl flex gap-5 items-center justify-center w-full text-grayscale-250'>
            {loading ? (
              <LoadingIcon className='mr-7 flex-none animate-spin' width='20px' height='20px' />
            ) : (
              'Нет файлов'
            )}
          </div>
        )
      )}
    </div>
  )
}

export default Folder
