import { FC, ComponentProps } from 'react'
import c from 'clsx'

const PrimaryButton: FC<ComponentProps<'button'>> = ({ children, className, ...props }) => {
  return (
    <button
      className={c(
        'px-5 py-3 flex items-center gap-x-3 rounded-md bg-surface-primary shadow-card font-medium text-labels-secondary hover:text-labels-primary hover:shadow-card-hover disabled:cursor-not-allowed',
        className
      )}
      {...props}
    >
      {children}
    </button>
  )
}

export default PrimaryButton
