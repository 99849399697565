import { FC, Suspense, useEffect, useRef } from 'react'
import { ErrorBoundary } from '@sentry/react'
import { ReactComponent as LoadingIcon } from '../../svg/icons/loading.svg'
import { useYmaps } from '../../hooks/useYmaps'
import c from 'clsx'

interface YMapProps {
  latitude: number
  longitude: number
  zoom?: number
  className?: string
}

const Map: FC<YMapProps> = ({ latitude, longitude, zoom = 15, className }) => {
  const mapRef = useRef<HTMLDivElement>(null)

  const ymaps3 = useYmaps()

  useEffect(() => {
    if (!mapRef.current) return

    const markerElement = document.createElement('div')
    markerElement.className = 'w-5 h-5 bg-red-50 rounded-full ring-[3px] ring-red-50 ring-offset-4'
    markerElement.id = 'urmom'

    const map = new ymaps3.YMap(
      mapRef.current,
      {
        location: {
          center: [longitude, latitude],
          zoom: zoom
        }
      },
      [new ymaps3.YMapDefaultSchemeLayer({}), new ymaps3.YMapDefaultFeaturesLayer({})]
    )

    map.addChild(new ymaps3.YMapMarker({ coordinates: [longitude, latitude] }, markerElement))

    const copyrights = mapRef.current?.querySelector('.ymaps3x0--map-copyrights')
    if (copyrights) copyrights?.remove()
  }, [latitude, longitude, ymaps3, zoom])

  return <div ref={mapRef} id='YMapsID' className={className}></div>
}

const SuspenseMap: FC<YMapProps> = (props) => {
  return (
    <ErrorBoundary
      fallback={
        <div className={c('bg-grayscale-450 flex items-center justify-center', props.className)}>
          Не удалось загрузить карту
        </div>
      }
    >
      <Suspense
        fallback={
          <div className={c('bg-grayscale-450 flex items-center justify-center', props.className)}>
            <LoadingIcon className='text-grayscale-250 animate-spin flex-none' />
          </div>
        }
      >
        <Map {...props} />
      </Suspense>
    </ErrorBoundary>
  )
}

export default SuspenseMap
