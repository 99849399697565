import React, { FC, useState } from 'react'
import { ReactComponent as TrashIcon } from '../../svg/icons/trash.svg'
import { ReactComponent as LoadingIcon } from '../../svg/icons/loading.svg'
import FileIcon from '../FileIcon'
import Preview from '../../components/Preview'
import useRemoveDocument from './useRemoveDocument'
import FileDownload from '../FileDownload'
import Modal from '../Modal'
import useToggle from '../../hooks/useToggle'
import { APIRoot } from '../../utils/APIRoot'
import ConfirmationForm from '../ConfirmationForm'
import { shortDateTimeFormatter } from '../../utils/dateFormatter'
import { HTTP_METHODS } from '../../utils/downloadFile'
import SuspenseModal from '../SuspenseModal'

export interface IDocument {
  _id: number
  created: string
  filePath: string
  originalName: string
  loading: boolean
  user?: {
    name: string
    surname: string
  }
  mimetype?: string
}

interface DocumentProps {
  doc: IDocument
  apiRoot?: string
  downloadRequestType?: HTTP_METHODS
  previewRequestType?: HTTP_METHODS
  mimetype?: string
  canDelete?: boolean
  canDownload?: boolean
  canPreview?: boolean
  onPreviewClick?: () => void
}

const noop = () => {}

const Document: FC<DocumentProps> = ({
  doc,
  apiRoot = APIRoot,
  downloadRequestType = 'POST',
  previewRequestType,
  mimetype,
  canDelete = true,
  canDownload = true,
  canPreview = true,
  onPreviewClick
}) => {
  const [previewOpen, togglePreviewOpen] = useToggle(false)
  const [deletionFormOpen, setDeletionFormOpen] = useState(false)

  const remove = useRemoveDocument(doc._id)

  const handlePreviewClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    togglePreviewOpen()
  }

  return (
    <figure className='flex items-center'>
      <FileIcon className='cursor-pointer' name={doc.originalName} />
      <div className='mx-6 text-p100 min-w-0'>
        <div
          onClick={canPreview ? (!!onPreviewClick ? onPreviewClick : handlePreviewClick) : noop}
          className='font-medium whitespace-nowrap text-ellipsis overflow-hidden cursor-pointer hover:text-red-100'
          title={doc.originalName}
        >
          {doc.originalName}
        </div>
        <div className='flex text-grayscale-150 text-p200'>
          {doc.user && (doc.user?.surname || doc.user?.name) && (
            <div className='whitespace-nowrap text-ellipsis overflow-hidden'>
              {doc.user?.surname + ' ' + doc.user?.name},
            </div>
          )}
          <div className='flex-none'>{shortDateTimeFormatter.format(new Date(doc.created))}</div>
        </div>
      </div>
      {doc.loading ? (
        <LoadingIcon className='text-grayscale-250 animate-spin ml-auto flex-none' />
      ) : (
        // Prevent event bubbling for cases when file is used in radio
        <div className='contents' onClick={(e) => e.stopPropagation()}>
          {canDownload && (
            <FileDownload
              className='text-grayscale-250 flex-none cursor-pointer ml-auto hover:text-red-100'
              url={apiRoot + doc.filePath}
              fileName={doc.originalName}
              type={downloadRequestType}
            />
          )}
          {canDelete && (
            <TrashIcon
              onClick={() => setDeletionFormOpen(true)}
              className='text-grayscale-250 flex-none cursor-pointer ml-5 hover:text-red-100'
            />
          )}
        </div>
      )}

      <SuspenseModal open={previewOpen} setOpen={togglePreviewOpen}>
        <div className='bg-white-0 p-6 rounded-xl flex items-center justify-center'>
          <Preview
            url={apiRoot + doc.filePath}
            filename={doc.originalName}
            requestType={previewRequestType}
            mimetype={mimetype}
          />
        </div>
      </SuspenseModal>

      <Modal open={deletionFormOpen} setOpen={setDeletionFormOpen}>
        <div className='bg-white-0 rounded-xl z-10'>
          <ConfirmationForm
            title='Удаление документа'
            onDone={() => {
              setDeletionFormOpen(false)
              remove()
            }}
            onDismiss={() => setDeletionFormOpen(false)}
          >
            Вы действительно хотите удалить {doc.originalName || 'документ'}?
          </ConfirmationForm>
        </div>
      </Modal>
    </figure>
  )
}

export default Document
