import { FC, useRef } from 'react'
import * as React from 'react'
import c from 'clsx'
import { ReactComponent as SearchIcon } from '../../svg/icons/search.svg'
import { ReactComponent as FilterIcon } from '../../svg/icons/filter.svg'
import { ReactComponent as CrossIcon } from '../../svg/icons/cross-xs.svg'
import { Popover } from '@headlessui/react'

interface SearchProps {
  onChange: (query: string) => void
  query?: string
  children: React.ReactNode
}

const SearchBar: FC<SearchProps> = ({ onChange, children, query }) => {
  // internal state is needed because external is debounced
  const inputRef = useRef<HTMLInputElement>(null)

  const showClear = !!query?.length
  return (
    <div className='bg-surface-secondary p-5 rounded-xl mb-8'>
      <div className='bg-surface-primary rounded-md flex items-center h-21 shadow-card'>
        <SearchIcon className='mx-10 text-grayscale-200' />
        <input
          ref={inputRef}
          value={query}
          onChange={(e) => {
            onChange(e.target.value)
          }}
          className='border-none outline-none ring-0 focus:ring-0 placeholder-grayscale-250 flex-grow'
          placeholder='Поиск'
        />

        <button
          disabled={!showClear}
          data-headlessui-state={!showClear && 'disabled'}
          className='px-11 py-5 text-grayscale-200 opacity-100 hover:text-red-100 ui-disabled:opacity-0 transition-opacity'
          onClick={() => {
            onChange('')
            inputRef.current?.focus()
          }}
        >
          <CrossIcon />
        </button>

        <div className='flex gap-x-11'>{children}</div>
      </div>
    </div>
  )
}

interface FilterButtonProps {
  active: boolean
  query?: string
  children: React.ReactNode
}

const FilterButton: FC<FilterButtonProps> = ({ active, children }) => {
  return (
    <Popover className='relative z-50'>
      <Popover.Button className='px-11 py-5 border-l-1 border-grayscale-400 text-grayscale-200 hover:text-red-100'>
        <FilterIcon className={c(active && 'text-red-100')} />
      </Popover.Button>
      <Popover.Panel className='absolute rounded-xl bg-white-0 w-195 right-0 shadow-dropdown mt-10'>
        {children}
      </Popover.Panel>
    </Popover>
  )
}

export default SearchBar

export { FilterButton }
