import { holidays as rawHolidays } from '../data/holidays.json'

const holidays = rawHolidays.map(holiday => Date.parse(holiday.substring(6, 10) + '-' + holiday.substring(3, 5) + '-' + holiday.substring(0, 2)))

export const addUTCDays = (date: Date, days: number) => {
  const result = new Date(date)
  result.setUTCDate(result.getUTCDate() + days)
  return result
}

export const minCoveredDate = new Date(holidays[0])
export const maxCoveredDate = new Date(holidays[holidays.length - 1])

const millisecondsInDay = 1000 * 60 * 60 * 24
let lastWorkingDay = holidays[holidays.length - 1]

// iterate holidays backwards and calculate last working day
for (let i = holidays.length - 1; i >= 0; i--) {
  const holiday = holidays[i]
  if (lastWorkingDay === holiday) {
    lastWorkingDay -= millisecondsInDay
  } else {
    break
  }
}

export const maxWorkingDate = new Date(lastWorkingDay)


export const addWorkDays = (date: Date, days: number): Date | undefined => {
  let result = new Date(date)

  //reset time to 00:00:00
  result.setUTCHours(0, 0, 0, 0)

  let nextHolidayIndex = holidays.findIndex(holiday => holiday >= result.getTime())

  if (!holidays.length || result.getTime() < holidays[0]) {
    console.warn('Date outside known range, holiday file refresh required')
    return
  }

  while (days > 0) {
    const nextHoliday = holidays[nextHolidayIndex]
    if (!nextHoliday || result.getTime() >= maxCoveredDate.getTime()) {
      console.warn('Date outside known range, holiday file refresh required')
      return
    }
    result = addUTCDays(result, 1)

    if (result.getTime() === nextHoliday) {
      nextHolidayIndex += 1
    } else {
      days -= 1
    }
  }

  return result
}

// calculate number of workdays between two dates
export const workDaysBetween = (startDate: Date, endDate: Date): number | undefined => {
  let date = new Date(startDate)
  date.setUTCHours(0, 0, 0, 0)

  const end = new Date(endDate)
  end.setUTCHours(0, 0, 0, 0)
  if (date.getTime() >= end.getTime()) {
    return 0
  }

  let i = 0

  let nextHolidayIndex = holidays.findIndex(holiday => holiday >= date.getTime())
  if (nextHolidayIndex === -1) {
    console.warn('Date outside known range, holiday file refresh required')
    return
  }

  let result = 0
  while (date.getTime() <= endDate.getTime()) {
    i += 1
    if (i > 500) {
      return
    }
    if (!holidays[nextHolidayIndex] || date.getTime() > maxCoveredDate.getTime()) {
      console.warn('Date outside known range, holiday file refresh required')
      return
    }
    if (date.getTime() >= holidays[nextHolidayIndex]) {
      nextHolidayIndex += 1
    } else {
      result += 1
    }
    date = addUTCDays(date, 1)
  }

  return result
}

export const daysBetween = (startDate: Date, endDate: Date): number => {
  const utc1 = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
  const utc2 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
  return Math.floor((utc2 - utc1) / millisecondsInDay)
}
