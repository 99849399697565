import { FC, useEffect, useRef, useState } from 'react'
import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'
import { ReactComponent as CheckIcon } from '../../svg/icons/check.svg'
import { ReactComponent as AddIcon } from '../../svg/icons/add.svg'
import { Tag as ITag } from './Tag.tsx'
import Tag from './Tag.tsx'
import TagInput from './TagInput.tsx'
import c from 'clsx'
import { TagEntity } from '../../graphql/schema.tsx'
import SecondaryButton from '../UI/SecondaryButton.tsx'

interface TagsProps {
  entityType: TagEntity
  className?: string
  tags: ITag[]
  onAddTag: (id: number) => void
  onRemoveTag: (id: number) => void
}

const Tags: FC<TagsProps> = ({ entityType, className, tags, onAddTag, onRemoveTag }) => {
  const [edit, setEdit] = useState(false)
  const [showInput, setShowInput] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)

  // handle clicks outside of the tags wrapper
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (!wrapperRef.current) return
      if (wrapperRef.current.contains(e.target as Node)) {
        return
      }
      if (showInput) {
        console.log('input is shown, closing it first')
        setShowInput(false)
        // if there are no tags, close edit mode
        if (!tags.length) {
          setEdit(false)
        }
        return
      }
      if (edit) {
        console.log('closing tags edit mode')
        setEdit(false)
      }
    }

    // dont listen for clicks outside if not in edit mode or input is not shown
    if (!edit && !showInput) return

    document.addEventListener('pointerdown', handleClickOutside)

    return () => {
      document.removeEventListener('pointerdown', handleClickOutside)
    }
  }, [showInput, edit, tags.length])

  // focus input when it is shown
  useEffect(() => {
    if (!showInput) return
    if (!wrapperRef.current) return

    // find the input element and focus it
    // get underlying DOM element from react ref
    const element = wrapperRef.current
    const input = element.querySelector('input')
    console.log('input', input, wrapperRef.current)
    if (input) {
      console.log('focus input', input)
      input.focus()
    }
  }, [showInput])

  return (
    <div ref={wrapperRef} className={c('flex flex-wrap gap-4 text-labels-secondary', className)}>
      {tags.map((tag) => (
        <Tag key={tag._id} value={tag} wiggle={edit} onRemove={onRemoveTag} />
      ))}
      {showInput && (
        <TagInput
          entityType={entityType}
          selectedTags={tags.map((tag) => tag._id)}
          onAddTag={(id) => {
            setShowInput(false)
            onAddTag(id)
          }}
        />
      )}
      {(edit || !tags.length) && !showInput && (
        <SecondaryButton
          onClick={() => {
            setEdit(true)
            setShowInput(true)
          }}
        >
          <AddIcon />
        </SecondaryButton>
      )}
      {edit && (
        <SecondaryButton
          onClick={() => {
            setEdit(false)
            setShowInput(false)
          }}
        >
          <CheckIcon />
        </SecondaryButton>
      )}
      {!edit && !!tags.length && (
        <SecondaryButton
          onClick={() => {
            setEdit(true)
          }}
        >
          <EditIcon />
        </SecondaryButton>
      )}
    </div>
  )
}

export default Tags
