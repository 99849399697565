import { ComponentPropsWithoutRef, FC, useState } from 'react'
import Dropdown from '../../components/Dropdown'
import DropdownButton from '../../components/Dropdown/Button'
import Modal from '../../components/Modal'
import { useRemoveBankDetailMutation } from '../../graphql/schema'
import ConfirmationForm from '../../components/ConfirmationForm'
import RequisiteForm from './RequisiteForm'
import clsx from 'clsx'
import AddButton from '../AddButton'
import { Card, CardHeader, CardTitle } from '../Card.tsx'

export type Requisite = {
  _id: number
  bic: string
  name: string
  account: string
  correspondentAccount: string
}

type RequisitesProps = {
  targetId: number
  targetType: string
  requisites: Requisite[]
  onDone: () => void
} & ComponentPropsWithoutRef<'div'>

const Requisites: FC<RequisitesProps> = ({ targetId, targetType, requisites, onDone, ...props }) => {
  const [editEntity, setEditEntity] = useState<Requisite>()
  const [deletingEntity, setDeletingEntity] = useState<Requisite>()
  const [deletionFormOpen, setDeletionFormOpen] = useState(false)
  const [formOpen, setFormOpen] = useState(false)
  const [removeDetail, { loading: loadingDeletion }] = useRemoveBankDetailMutation()

  function openEditModal(entity?: Requisite): void {
    setEditEntity(entity)
    setFormOpen(true)
  }

  function openDeletingEntityModal(entity?: Requisite): void {
    setDeletingEntity(entity)
    setDeletionFormOpen(true)
  }

  function removeBankDetail() {
    if (!deletingEntity) {
      return
    }
    return removeDetail({
      variables: {
        input: {
          bankDetail: deletingEntity._id.toString()
        }
      }
    })
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Реквизиты</CardTitle>
      </CardHeader>

      <div className='px-5 pb-5'>
        <div {...props} className='flex flex-col gap-6'>
          {requisites.length > 0 && (
            <div
              className={clsx(
                'w-full rounded-md bg-surface-primary p-4 text-left shadow-card',
                loadingDeletion && 'pointer-events-none opacity-50'
              )}
            >
              {requisites?.map((req, i) => (
                <div
                  className='mb-10 mt-6 border-b-1 border-grayscale-400 pb-17 last-of-type:mb-0 last-of-type:border-b-0 last-of-type:pb-0'
                  key={i}
                >
                  <table className='w-full'>
                    <thead>
                      <tr className='group'>
                        <td className='w-1/2 rounded-l-xl px-6 pb-10 text-p200 font-medium'>{req?.name}</td>
                        <td className='w-1/2 rounded-r-xl p-6 text-right'>
                          <div className='inline-block'>
                            <Dropdown className='pl-4 pr-4'>
                              <DropdownButton action='edit' onClick={() => openEditModal(req)} />
                              <DropdownButton action='delete' onClick={() => openDeletingEntityModal(req)} />
                            </Dropdown>
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='group'>
                        <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>Расчётный счёт</td>
                        <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>{req?.account}</td>
                      </tr>
                      <tr className='group'>
                        <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>Корр. счёт</td>
                        <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>{req?.correspondentAccount}</td>
                      </tr>
                      <tr className='group'>
                        <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>БИК</td>
                        <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>{req?.bic}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
            </div>
          )}
          <AddButton onClick={() => openEditModal()}>Добавить банковские реквизиты</AddButton>
        </div>

        <Modal open={deletionFormOpen} setOpen={setDeletionFormOpen}>
          <div className='z-10 rounded-xl bg-white-0'>
            <ConfirmationForm
              title='Удаление реквизита'
              onDone={async () => {
                setDeletionFormOpen(false)
                await removeBankDetail()
                onDone()
              }}
              onDismiss={() => setDeletionFormOpen(false)}
            >
              Вы действительно хотите удалить реквизит?
            </ConfirmationForm>
          </div>
        </Modal>
        <Modal open={formOpen} setOpen={setFormOpen}>
          <div className='z-10 rounded-xl bg-white-0'>
            <RequisiteForm
              targetId={targetId}
              targetType={targetType}
              onDone={() => {
                onDone()
                setFormOpen(false)
              }}
              entity={editEntity}
            />
          </div>
        </Modal>
      </div>
    </Card>
  )
}

export default Requisites
