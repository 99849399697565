import { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { UserRoles, useUpdateSupplierCompanyMutation } from '../../graphql/schema'
import { handleBackendErrorsToForm } from '../../utils/backendErrorUtils'
import { ApolloError } from '@apollo/client'
import SubmitButton from '../../components/SubmitButton'
import ManagerInput from '../../components/Forms/Inputs/ManagerInput'
import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'

type Inputs = {
  id: string
  engagePartnerBdmUser: number
}

interface ManagersFormProps {
  supplierId: string
  managerId?: number
  onDone?: () => void
}

const ManagersForm: FC<ManagersFormProps> = ({ supplierId, managerId, onDone }) => {
  const [updateSupplier, { loading }] = useUpdateSupplierCompanyMutation()

  const { handleSubmit, formState, setError, control } = useForm<Inputs>({
    defaultValues: {
      engagePartnerBdmUser: managerId
    }
  })

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await updateSupplier({
      variables: {
        input: {
          id: supplierId,
          // @ts-ignore
          engagePartnerBdmUser: data?.engagePartnerBdmUser?.toString() || null
        }
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  return (
    <section className='md: w-[448px] p-12'>
      <h1 className='font-display text-h200 mb-12'>Менеджеры Fera</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 flex flex-col gap-8 relative'>
          <Controller
            name='engagePartnerBdmUser'
            control={control}
            render={({ field }) => (
              <ManagerInput
                label='Менеджер по привлечению'
                placeholder='Выберите менеджера'
                selected={field.value ? [field.value] : []}
                onChange={(selected) =>
                  selected.length ? field.onChange(selected[selected.length - 1]) : field.onChange(null)
                }
                error={formState.errors?.engagePartnerBdmUser}
                role={UserRoles.RoleBdm}
              />
            )}
          />
        </div>
        <SubmitButton loading={loading}>
          <EditIcon className='mr-5' />
          Изменить
        </SubmitButton>
      </form>
    </section>
  )
}

export default ManagersForm
