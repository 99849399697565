import { FC } from 'react'
import YMap from '../../../components/YMap'
import { Card, CardHeader, CardTitle } from '../../../components/Card.tsx'

interface GeolocationProps {
  coordinates?: { ip?: string; latitude?: number; longitude?: number }
}

const Geolocation: FC<GeolocationProps> = ({ coordinates }) => (
  <Card>
    <CardHeader>
      <CardTitle>Местоположение</CardTitle>
    </CardHeader>

    <div className='px-5 pb-5'>
      <div className='rounded-md bg-surface-primary shadow-card'>
        <table className='w-full'>
          <tbody>
            <tr className='group'>
              <td className='px-8 py-6 text-grayscale-150 first:rounded-l-md last:rounded-r-md'>IP адрес</td>
              <td className='px-8 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                {coordinates?.ip || 'Отказано'}
              </td>
            </tr>
          </tbody>
        </table>
        {coordinates?.latitude && coordinates?.longitude && (
          <YMap
            className='relative mt-7 h-[370px] w-full overflow-hidden rounded-md'
            latitude={coordinates.latitude}
            longitude={coordinates.longitude}
          />
        )}
      </div>
    </div>
  </Card>
)

export default Geolocation
