import { FC, useState } from 'react'
import { PersonInfo, useUpdateVerificationMutation } from '../../../graphql/schema'
import { SubmitHandler, useForm } from 'react-hook-form'
import Input from '../../../components/Input'
import { ReactComponent as EditIcon } from '../../../svg/icons/edit.svg'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils'
import { ApolloError } from '@apollo/client'
import SubmitButton from '../../../components/SubmitButton'
import RadioButtonsGroup from '../../../components/RadioButtonsGroup'

interface AddressUpdateInputs {
  regAddress: string
  regAddressSameAsFact: boolean
  factAdress: string
}

const EditAddressForm: FC<{ uuid: string; personInfo: PersonInfo; onDone: () => void }> = ({
  uuid,
  personInfo,
  onDone
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm<AddressUpdateInputs>({
    defaultValues: {
      regAddress: personInfo?.regAddress,
      factAdress: personInfo?.regAddressSameAsFact ? '' : personInfo?.factAdress
    }
  })
  const [updateVerification, { loading }] = useUpdateVerificationMutation()

  const [regAddressSameAsFact, setRegAddressSameAsFact] = useState(personInfo?.regAddressSameAsFact ? 'true' : 'false')

  const onSubmit: SubmitHandler<AddressUpdateInputs> = async (data) => {
    if (!uuid) return

    await updateVerification({
      variables: {
        uuid,
        input: {
          personInfo: {
            regAddress: data.regAddress,
            regAddressSameAsFact: regAddressSameAsFact === 'true',
            factAdress: regAddressSameAsFact === 'true' ? data.regAddress : data.factAdress,

            fullName: personInfo?.fullName,
            firstName: personInfo?.firstName,
            lastName: personInfo?.lastName,
            middleName: personInfo?.middleName,
            gender: personInfo?.gender,
            birthDate: personInfo?.birthDate,
            birthPlace: personInfo?.birthPlace,
            passportSeriesAndNumber: personInfo?.passportSeriesAndNumber,
            passportIssueId: personInfo?.passportIssueId,
            passportIssueDate: personInfo?.passportIssueDate,
            passportIssuedBy: personInfo?.passportIssuedBy
          }
        }
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<AddressUpdateInputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  return (
    <section className='w-[448px] p-12'>
      <h1 className='font-display text-h200 mb-12'>Редактирование адреса</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid grid-cols-1 gap-10 mb-12'>
          <Input label='Адрес регистрации' type='text' {...register('regAddress')} error={errors.regAddress} />
          <RadioButtonsGroup
            onChange={(value) => setRegAddressSameAsFact(value)}
            options={[
              ['Да', 'true'],
              ['Нет', 'false']
            ]}
            checkedValue={regAddressSameAsFact}
            label='Место регистрации совпадает с фактическим адресом проживания?'
          />
          {regAddressSameAsFact === 'false' && (
            <Input label='Адрес проживания' type='text' {...register('factAdress')} error={errors.factAdress} />
          )}
        </div>

        <SubmitButton loading={loading}>
          <EditIcon className='mr-5' />
          Изменить
        </SubmitButton>
      </form>
    </section>
  )
}

export default EditAddressForm
