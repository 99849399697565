import { FC } from 'react'
import { usePersonCheckFinmonQuery, CheckedContactResult, Verification } from '../../../graphql/schema'
import { ReactComponent as WarningIcon } from '../../../svg/icons/warning.svg'
import { dateFormatter } from '../../../utils/dateFormatter'
import Tooltip from '../../Tooltip'
import c from 'clsx'
import { Card, CardHeader, CardMenu, CardTitle } from '../../Card.tsx'

const PersonCheckFinmon: FC<{ boVerification: Verification }> = ({ boVerification }) => {
  const { data: personCheckFinmonData } = usePersonCheckFinmonQuery({
    variables: {
      fio: `${boVerification?.personInfo?.lastName} ${boVerification?.personInfo?.firstName} ${boVerification?.personInfo?.middleName}`,
      passport: boVerification?.personInfo?.passportSeriesAndNumber!
    },
    skip:
      !boVerification?.personInfo?.lastName ||
      !boVerification?.personInfo?.firstName ||
      !boVerification?.personInfo?.passportSeriesAndNumber
  })
  const checkContact = personCheckFinmonData?.checkContact

  return (
    <Card>
      <CardHeader>
        <CardTitle>Опросник для поручительства</CardTitle>
        {checkContact?.createdDate && (
          <CardMenu>Обновлено {dateFormatter.format(new Date(checkContact?.createdDate))}</CardMenu>
        )}
      </CardHeader>

      <div className='px-5 pb-5'>
        <div className='rounded-md bg-surface-primary shadow-card'>
          <table className='w-full'>
            <tbody>
              <tr className='group'>
                <td className='px-8 py-6 text-grayscale-150 first:rounded-l-md last:rounded-r-md'>Росфинмониторинг</td>
                <td className='px-8 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                  {!checkContact ? (
                    <div className='flex items-center justify-end'>
                      <p className='mr-5'>Не удалось проверить</p>
                      <Tooltip target={<WarningIcon className='text-red-100' height='20px' width='20px' />}>
                        <div className='mb-2 font-display font-medium'>Чтобы продолжить, нужно:</div>
                        <ul className='list-inside list-disc'>
                          {(!boVerification?.personInfo?.firstName || !boVerification?.personInfo?.lastName) && (
                            <li>Указать ФИО</li>
                          )}
                          {!boVerification?.personInfo?.passportSeriesAndNumber && (
                            <li>Указать серию/номер паспорта</li>
                          )}
                        </ul>
                      </Tooltip>
                    </div>
                  ) : (
                    <div className='flex items-center justify-end'>
                      {checkContact?.resultTerrorist === CheckedContactResult.NoMatches &&
                      checkContact?.resultTerroristOON === CheckedContactResult.NoMatches
                        ? 'Проверка пройдена'
                        : 'Совпадение по ФИО/Паспорту'}
                      <div
                        className={c(
                          'ml-6 h-7 w-7 rounded-full',
                          checkContact?.resultTerrorist === CheckedContactResult.NoMatches &&
                            checkContact?.resultTerroristOON === CheckedContactResult.NoMatches
                            ? 'bg-green-400'
                            : 'bg-red-100'
                        )}
                      />
                    </div>
                  )}
                </td>
              </tr>
              <tr className='group border-t-1 border-grayscale-400'>
                <td className='px-8 py-6 text-grayscale-150 first:rounded-l-md last:rounded-r-md'>Блокировка счетов</td>
                <td className='px-8 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                  {!checkContact ? (
                    <div className='flex items-center justify-end'>
                      <p className='mr-5'>Не удалось проверить</p>
                      <Tooltip target={<WarningIcon className='text-red-100' height='20px' width='20px' />}>
                        <div className='mb-2 font-display font-medium'>Чтобы продолжить, нужно:</div>
                        <ul className='list-inside list-disc'>
                          {(!boVerification?.personInfo?.firstName || !boVerification?.personInfo?.lastName) && (
                            <li>Указать ФИО</li>
                          )}
                          {!boVerification?.personInfo?.passportSeriesAndNumber && (
                            <li>Указать серию/номер паспорта</li>
                          )}
                        </ul>
                      </Tooltip>
                    </div>
                  ) : (
                    <div className='flex items-center justify-end'>
                      {checkContact?.resultBlockedAccounts === CheckedContactResult.NoMatches
                        ? 'Проверка пройдена'
                        : 'Совпадение по ФИО/Паспорту'}
                      <div
                        className={c(
                          'ml-6 h-7 w-7 rounded-full',
                          checkContact?.resultBlockedAccounts === CheckedContactResult.NoMatches
                            ? 'bg-green-400'
                            : 'bg-red-100'
                        )}
                      />
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  )
}

export default PersonCheckFinmon
