import { FC, useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { useBankOrderQuery } from '../../graphql/schema.tsx'
import TransactionAmount from '../TransactionAmount.tsx'
import c from 'clsx'

interface BankOrderInfoFormProps {
  onDone?: () => void
  paymentId?: string
  companyName: string
}

const dateFormatter = Intl.DateTimeFormat('ru-RU', {
  day: 'numeric',
  month: 'long',
  year: 'numeric'
})

const BankOrderInfoForm: FC<BankOrderInfoFormProps> = ({ onDone, companyName, paymentId }) => {
  const [tab, setTab] = useState<'from' | 'to'>('from')

  const { data } = useBankOrderQuery({
    variables: {
      id: `${paymentId}`
    },
    skip: !paymentId
  })

  const payment = data?.bankOrder

  if (!payment) return null

  return (
    <section className='p-12 md:w-[448px]'>
      <div className='mb-18 flex items-center justify-between'>
        <h1 className='font-display text-h200'>Исходящий платёж</h1>
        <p className='text-p350 text-grayscale-150'>{dateFormatter.format(new Date(payment?.createdAt))}</p>
      </div>
      <p className='mb-18 text-center text-p100 text-grayscale-150'>{companyName}</p>
      <TransactionAmount amount={payment.amount} className='mb-18 text-center text-[25px] text-red-150' currency='₽' />
      <RadioGroup
        className='mx-5 mb-12 grid auto-cols-fr grid-flow-col rounded-xl bg-grayscale-450 p-1'
        value={tab}
        onChange={(value: 'from' | 'to') => {
          setTab(value)
        }}
      >
        <RadioGroup.Option value='from'>
          <div
            className={c(
              'm-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center',
              tab === 'from' && 'border-grayscale-350 bg-white-0'
            )}
          >
            Откуда
          </div>
        </RadioGroup.Option>
        <RadioGroup.Option value='to'>
          <div
            className={c(
              'm-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center',
              tab === 'to' && 'border-grayscale-350 bg-white-0'
            )}
          >
            Куда
          </div>
        </RadioGroup.Option>
      </RadioGroup>
      {tab === 'from' ? (
        <table className='w-full'>
          <tbody>
            <tr className='group'>
              <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>Источник</td>
              <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                {payment?.fundingSource?.name}
              </td>
            </tr>
            <tr className='group'>
              <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                Название банка
              </td>
              <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                {payment?.feraBankAccount?.name}
              </td>
            </tr>
            <tr className='group'>
              <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>Счёт</td>
              <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                {payment?.feraBankAccount?.number}
              </td>
            </tr>
            <tr className='group'>
              <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>БИК</td>
              <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                {payment?.feraBankAccount?.bic}
              </td>
            </tr>
            <tr className='group'>
              <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>Корр.счёт</td>
              <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                {payment?.feraBankAccount?.corrNumber}
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <table className='w-full'>
          <tbody>
            <tr className='group'>
              <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>ИНН</td>
              <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                {payment?.recipientInn}
              </td>
            </tr>
            <tr className='group'>
              <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>КПП</td>
              <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                {payment?.recipientKpp}
              </td>
            </tr>
            <tr className='group'>
              <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                Название банка
              </td>
              <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                {payment?.recipientBankName}
              </td>
            </tr>
            <tr className='group'>
              <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>Счёт</td>
              <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                {payment?.recipientAccountNumber}
              </td>
            </tr>
            <tr className='group'>
              <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>БИК</td>
              <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                {payment?.recipientBankBik}
              </td>
            </tr>
            <tr className='group'>
              <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>Корр.счёт</td>
              <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                {payment?.recipientBankCorrNumber}
              </td>
            </tr>
          </tbody>
        </table>
      )}
      <button
        className='mt-18 flex h-25 w-full items-center justify-center rounded-xl bg-grayscale-200 font-semibold text-white-0'
        title='Закрыть'
        onClick={onDone}
      >
        Закрыть
      </button>
    </section>
  )
}

export default BankOrderInfoForm
