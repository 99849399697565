import { FC } from 'react'

const drawArc = (cx: number, cy: number, radius: number, startAngle: number, endAngle: number): string => {
  // Convert angles from degrees to radians
  const startRadians = (Math.PI / 180) * startAngle
  const endRadians = (Math.PI / 180) * endAngle

  // Calculate the start point of the arc
  const startX = cx + radius * Math.cos(startRadians)
  const startY = cy + radius * Math.sin(startRadians)

  // Calculate the end point of the arc
  const endX = cx + radius * Math.cos(endRadians)
  const endY = cy + radius * Math.sin(endRadians)

  // Determine if the arc should be drawn in a large arc (greater than 180 degrees)
  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'

  // Determine if the arc should be drawn in a positive angle direction
  const sweepFlag = '1'

  // Create the d attribute for the path
  return `M ${startX} ${startY} A ${radius} ${radius} 0 ${largeArcFlag} ${sweepFlag} ${endX} ${endY}`
}

interface StatusRingProps {
  maxSegments: number
  activeSegments: number
  className?: string
}

const D = 16 // Diameter of the circle
const R = D / 2 // Radius of the circle
const T = 1.2 // Thickness of the ring
const spacing = 20 // Spacing between segments in degrees

const StatusRing: FC<StatusRingProps> = ({ maxSegments, activeSegments, className }) => {
  let pathArray = []
  let currentAngle = -90 // Start at the top of the circle
  const oneSegmentAngle = 360 / maxSegments - spacing

  for (let i = 0; i < maxSegments; i++) {
    currentAngle += spacing / 2
    pathArray.push(
      <path
        key={i}
        d={drawArc(R, R, R - T / 2, currentAngle, currentAngle + oneSegmentAngle)}
        opacity={i < activeSegments ? '1' : '0.3'}
      />
    )
    currentAngle += oneSegmentAngle + spacing / 2
  }

  return (
    <svg
      width={D}
      height={D}
      className={className}
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeWidth={T}
    >
      {pathArray}
    </svg>
  )
}

export default StatusRing
