import { useEffect, useRef, useState } from 'react'
import { BehaviorSubject, combineLatest, Observable, of, switchMap, map } from 'rxjs'

export type StateEnum = string

export interface StateDefinition<TState extends StateEnum, TContext> {
  state: TState
  nextStates: TState[]
  checks?: StateChecker<TContext, TState>[]
}

export interface CheckResult {
  passed: boolean
  message?: string
  loading?: boolean
}

export type StateChecker<TContext, TState> = (
  context: TContext,
  currentState: TState,
  nextState: TState
) => Observable<CheckResult>

export type CheckFunction<TData> = (data: TData) => CheckResult
export interface SwitcherState<TState extends StateEnum> {
  state: TState
  allowed: boolean
  messages?: string[]
  loading: boolean // Add loading state for each state
}

export const useSwitcherState = <TState extends StateEnum, TContext extends object>(
  currentState: TState | undefined,
  stateDefinitions: StateDefinition<TState, TContext>[],
  context$: BehaviorSubject<TContext>
) => {
  const [switcherState, setSwitcherState] = useState<SwitcherState<TState>[]>(
    stateDefinitions.map((stateDefinition) => ({
      state: stateDefinition.state,
      allowed: false,
      loading: false
    }))
  )
  const [loading, setLoading] = useState(false)

  const currentState$ = useRef(new BehaviorSubject(currentState)).current

  useEffect(() => {
    currentState$.next(currentState)
  }, [currentState, currentState$])

  useEffect(() => {
    const subscription = combineLatest([currentState$, context$])
      .pipe(
        switchMap(([currentState, context]) => {
          const activeState = stateDefinitions.find((stateDefinition) => stateDefinition.state === currentState)

          const stateObservables = stateDefinitions.map((stateDefinition) => {
            // If this state is not allowef from the current state - return false
            if (!currentState || !activeState?.nextStates?.includes(stateDefinition.state)) {
              return of({
                state: stateDefinition.state,
                allowed: false,
                loading: false
              })
            }

            // If no checks - return true
            if (!stateDefinition.checks?.length) {
              return of({
                state: stateDefinition.state,
                allowed: true,
                loading: false
              })
            }

            // Run checks
            return combineLatest(
              stateDefinition.checks.map((check) => check(context, currentState, stateDefinition.state))
            ).pipe(
              map((results) => {
                const allowed = results.every((result) => result.passed)
                const messages = results.map((result) => result.message as string).filter(Boolean)
                const loading = results.some((result) => result.loading)
                return {
                  state: stateDefinition.state,
                  allowed,
                  messages: messages.length > 0 ? messages : undefined,
                  loading
                }
              })
            )
          })

          return combineLatest(stateObservables)
        })
      )
      .subscribe((newSwitcherState) => {
        setSwitcherState(newSwitcherState)
        setLoading(newSwitcherState.some((state) => state.loading))
      })

    return () => subscription.unsubscribe()
  }, [currentState$, context$, stateDefinitions])

  return { switcherState, loading }
}
