import { useMemo } from 'react'

const useNodes = <T>(edges?: Array<{ node?: T } | undefined>) => {
  return useMemo(() => (edges ? edges.map((edge) => edge?.node as T) : []), [edges])
}

export default useNodes

export const getNodes = <T>(edges?: Array<{ node?: T } | undefined>): T[] => {
  if (!edges) return []
  return edges.map((edge) => edge?.node as T)
}
