import { useEffect, useRef, useState } from 'react'
import { ObservableQueryFields } from '@apollo/client'

type PageInfo = {
  startCursor?: string | null
  endCursor?: string | null
  hasNextPage?: boolean
  hasPreviousPage?: boolean
}

type FetchMoreVariables = {
  offset: string
  limit?: number
}

/**
 * Hook to trigger a callback when the user scrolls to the bottom of the element.
 */
const useScrollPagination = (fetchMore: ObservableQueryFields<any, FetchMoreVariables>['fetchMore'], pageInfo?: PageInfo) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [isFetching, setIsFetching] = useState(false)
  useEffect(() => {
    // initially fetch data until scroll appears
    triggerFetchMore()

    function handleScroll() {
      // fetch more only if no active fetch in progress
      if (!isFetching) {
        triggerFetchMore()
      }
    }

    function triggerFetchMore() {
      if (
        pageInfo?.hasNextPage &&
        wrapperRef?.current &&
        wrapperRef?.current.clientHeight &&
        wrapperRef.current.getBoundingClientRect().bottom <= window.innerHeight
      ) {
        setIsFetching(true)
        fetchMore({
          variables: {
            after: pageInfo.endCursor || undefined
          }
        }).then(() => {
          setIsFetching(false)
        })
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [fetchMore, pageInfo, isFetching])

  return { wrapperRef, isFetching }
}

export default useScrollPagination
