import { FC } from 'react'
import { ReactComponent as ChevronIcon } from '../../../svg/icons/chevron-slim.svg'
import { Disclosure } from '@headlessui/react'
import { ClientData } from '../../../types/bki.ts'
import { capitalizeFirst } from '../../../utils/contractUtils.ts'

type Ogrnip = {
  date: string
  no: string
  sign: string
}

interface ClientProps {
  data?: ClientData
  contacts?: {
    email: { mail: string } | { mail: string }[]
    phone: { number: string } | { number: string }[]
  }
  ogrnip?: Ogrnip | Ogrnip[]
}

const Client: FC<ClientProps> = ({ data, contacts, ogrnip }) => {
  const names = (Array.isArray(data?.history?.name) ? data?.history?.name : [data?.history?.name])?.filter(
    (n) => !!n?.first || !!n?.last || !!n?.middle
  )
  const docs = (Array.isArray(data?.history?.doc) ? data?.history?.doc : [data?.history?.doc])?.filter(
    (d) => !!d?.number || !!d?.serial || !!d?.date
  )
  const phones = (Array.isArray(contacts?.phone) ? contacts?.phone : [contacts?.phone])?.filter((p) => !!p?.number)
  const emails = (Array.isArray(contacts?.email) ? contacts?.email : [contacts?.email])?.filter((e) => !!e?.mail)
  const ogrnips = (Array.isArray(ogrnip) ? ogrnip : [ogrnip])?.filter((o) => !!o?.no)

  return (
    <div className='flex flex-col gap-5'>
      <div className='cursor-pointer rounded-md bg-surface-primary py-6 shadow-card'>
        <Disclosure>
          <Disclosure.Button className='flex w-full items-center px-10'>
            <h2 className='flex items-center text-p100'>Список известных ФИО</h2>
            <ChevronIcon className='ml-auto transition-transform ui-open:-rotate-180' />
          </Disclosure.Button>
          <Disclosure.Panel className='cursor-auto'>
            <div className='mt-15 flex gap-6 px-6'>
              <table className='w-full'>
                <tbody>
                  <tr className='group'>
                    <td className='px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                      Текущее ФИО
                    </td>
                    <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                      {capitalizeFirst(data?.name?.last || '')} {capitalizeFirst(data?.name?.first || '')}{' '}
                      {capitalizeFirst(data?.name?.middle || '')}
                    </td>
                  </tr>
                  {names?.map((name, index) => (
                    <tr key={`${name?.last}` + index} className='group'>
                      <td className='px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                        Предыдущее ФИО
                      </td>
                      <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                        {capitalizeFirst(name?.last || '')} {capitalizeFirst(name?.first || '')}{' '}
                        {capitalizeFirst(name?.middle || '')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Disclosure.Panel>
        </Disclosure>
      </div>

      <div className='cursor-pointer rounded-md bg-surface-primary py-6 shadow-card'>
        <Disclosure>
          <Disclosure.Button className='flex w-full items-center px-10'>
            <h2 className='flex items-center text-p100'>Обнаруженные паспорта</h2>
            <ChevronIcon className='ml-auto transition-transform ui-open:-rotate-180' />
          </Disclosure.Button>
          <Disclosure.Panel className='cursor-auto'>
            <div className='mt-15 flex gap-6 px-6'>
              <table className='w-full'>
                <tbody>
                  <tr className='group'>
                    <td className='rounded-l-xl px-8 py-6 text-grayscale-150 group-odd:bg-grayscale-450'>Текущий</td>
                    <td className='px-8 py-6 text-center text-grayscale-150 group-odd:bg-grayscale-450'>Паспорт РФ</td>
                    <td className='px-8 py-6 text-center group-odd:bg-grayscale-450'>
                      {data?.doc?.serial} {data?.doc?.number}
                    </td>
                    <td className='rounded-r-xl px-8 py-6 text-right group-odd:bg-grayscale-450'>{data?.doc?.date}</td>
                  </tr>
                  {docs?.map((doc, index) => (
                    <tr key={`${doc?.number}` + index} className='group'>
                      <td className='rounded-l-xl px-8 py-6 text-grayscale-150 group-odd:bg-grayscale-450'>
                        Предыдущий
                      </td>
                      <td className='px-8 py-6 text-center text-grayscale-150 group-odd:bg-grayscale-450'>
                        Паспорт РФ
                      </td>
                      <td className='px-8 py-6 text-center group-odd:bg-grayscale-450'>
                        {doc?.serial} {doc?.number}
                      </td>
                      <td className='rounded-r-xl px-8 py-6 text-right group-odd:bg-grayscale-450'>{doc?.date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Disclosure.Panel>
        </Disclosure>
      </div>

      <div className='cursor-pointer rounded-md bg-surface-primary py-6 shadow-card'>
        <Disclosure>
          <Disclosure.Button className='flex w-full items-center px-10'>
            <h2 className='flex items-center text-p100'>Контактная информация</h2>
            <ChevronIcon className='ml-auto transition-transform ui-open:-rotate-180' />
          </Disclosure.Button>
          <Disclosure.Panel className='cursor-auto'>
            <div className='mt-15 flex gap-6 px-6'>
              <table className='w-full'>
                <tbody>
                  {phones?.map((phone, index) => (
                    <tr key={`${phone?.number}` + index} className='group'>
                      <td className='px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                        Номер телефона
                      </td>
                      <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                        {phone?.number}
                      </td>
                    </tr>
                  ))}
                  {emails?.map((email, index) => (
                    <tr key={`${email?.mail}` + index} className='group'>
                      <td className='px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                        Email
                      </td>
                      <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                        {email?.mail}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Disclosure.Panel>
        </Disclosure>
      </div>

      <div className='cursor-pointer rounded-md bg-surface-primary py-6 shadow-card'>
        <Disclosure>
          <Disclosure.Button className='flex w-full items-center px-10'>
            <h2 className='flex items-center text-p100'>Прочие документы</h2>
            <ChevronIcon className='ml-auto transition-transform ui-open:-rotate-180' />
          </Disclosure.Button>
          <Disclosure.Panel className='cursor-auto'>
            <div className='mt-15 flex gap-6 px-6'>
              <table className='w-full'>
                <tbody>
                  <tr className='group'>
                    <td className='px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                      ИНН
                    </td>
                    <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                      {data?.inn?.no}
                    </td>
                  </tr>
                  <tr className='group'>
                    <td className='px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                      СНИЛС
                    </td>
                    <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                      {data?.snils?.no}
                    </td>
                  </tr>
                  {ogrnips?.map((ogrnip, index) => (
                    <tr key={`${ogrnip?.no}` + index} className='group'>
                      <td className='px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                        ОРГНИП
                      </td>
                      <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                        {ogrnip?.no}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Disclosure.Panel>
        </Disclosure>
      </div>
    </div>
  )
}

export default Client
