import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ReactComponent as CheckmarkNotActualIcon } from '../../svg/icons/circleEmpty.svg'
import { ReactComponent as CheckmarkActualIcon } from '../../svg/icons/circleCheck.svg'
import {
  BkiPayments,
  DocumentTypeEntity,
  PaymentKind,
  PaymentsQuery,
  PaymentTargetType,
  ReportingBkiGuarantorContractStatus,
  ReportingBkiLeasingContractStatus,
  ReportingFedresStatus,
  ReportingRfmStatus,
  useDealByIdQuery,
  useDealGuarantorsQuery,
  usePaymentsQuery,
  useUpdateDealMutation
} from '../../graphql/schema'
import { RadioGroup } from '@headlessui/react'
import c from 'clsx'
import GridView, { GridViewConfig } from '../../components/GridView'
import useNodes from '../../hooks/useNodes'
import { dateFormatter, dateTimeFormatter } from '../../utils/dateFormatter'
import { formatDecimal } from '../../utils/formatNumber'
import Tooltip from '../../components/Tooltip'
import { Card, CardHeader, CardTitle } from '../../components/Card.tsx'
import EntityFolder from '../../components/DocumentManager/EntityFolder.tsx'
import { NodeType } from '../../types'

const ROS_FIN_MONITORING_OPTIONS = [
  ['Отчитано полностью', ReportingRfmStatus.Sent],
  ['Отчитано частично', ReportingRfmStatus.PartlySent],
  ['Не отчитано', ReportingRfmStatus.Pending],
  ['Не подлежит контролю', ReportingRfmStatus.NotRequired]
]

type Payment = NodeType<PaymentsQuery['payments']> & { penaltyPayments?: Payment[] }
type PaymentWithBki = Payment & { bkiPaymentSent: boolean; bkiPaymentSentAt?: string }

const Regulators: FC = () => {
  const { id } = useParams<'id'>()
  const { data } = useDealByIdQuery({ variables: { id: `${id}` }, skip: !id, fetchPolicy: 'cache-and-network' })
  const { data: dealGuarantorsData } = useDealGuarantorsQuery({
    variables: { id: id?.toString() as string },
    skip: !id
  })
  const deal = data?.deal
  const hasGuarantors = !!dealGuarantorsData?.deal?.guarantors?.edges?.length
  const reportingBkiPaymentsSent: BkiPayments[] = useMemo(
    () => deal?.reportingBkiPaymentsSent || [],
    [deal?.reportingBkiPaymentsSent]
  )

  const {
    data: leasingPaymentsData,
    loading: leasingPaymentsLoading,
    error: leasingPaymentsError
  } = usePaymentsQuery({
    variables: {
      kind: PaymentKind.Leasing,
      targetType: PaymentTargetType.Deal,
      targetId: id
    },
    skip: !id
  })
  const leasingPayments = useNodes(leasingPaymentsData?.payments.edges) as Payment[]
  const paymentsWithBki: PaymentWithBki[] = useMemo(
    () =>
      leasingPayments.map((payment) => ({
        ...payment,
        bkiPaymentSent: reportingBkiPaymentsSent.find((i) => parseInt(payment.id) === i.id)?.state === 'sent',
        bkiPaymentSentAt: reportingBkiPaymentsSent.find((i) => parseInt(payment.id) === i.id)?.sentAt
      })),
    [leasingPayments, reportingBkiPaymentsSent]
  )

  const [updateDeal] = useUpdateDealMutation()

  const markActualPayment = useCallback(
    async (payment: Payment) => {
      if (!deal?._id) return

      await updateDeal({
        variables: {
          input: {
            id: deal?._id.toString(),
            reportingBkiPaymentsSent: [
              ...reportingBkiPaymentsSent,
              {
                id: parseInt(payment.id),
                sentAt: new Date().toISOString(),
                state: 'sent'
              }
            ]
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateDeal: {
            __typename: 'updateDealPayload',
            deal: {
              ...deal,
              reportingBkiPaymentsSent: [
                ...reportingBkiPaymentsSent,
                {
                  id: parseInt(payment.id),
                  sentAt: new Date().toISOString(),
                  state: 'sent'
                }
              ]
            }
          }
        }
      }).then(
        () => {
          console.log('reportingBkiPaymentsSent updated')
        },
        (err: Error) => {
          console.log(err.message)
        }
      )
    },
    [deal, updateDeal, reportingBkiPaymentsSent]
  )

  const markNotActualPayment = useCallback(
    async (payment: Payment) => {
      if (!deal?._id) return

      await updateDeal({
        variables: {
          input: {
            id: deal?._id.toString(),
            reportingBkiPaymentsSent: reportingBkiPaymentsSent.filter((i) => i.id !== parseInt(payment.id))
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateDeal: {
            __typename: 'updateDealPayload',
            deal: {
              ...deal,
              reportingBkiPaymentsSent: reportingBkiPaymentsSent.filter((i) => i.id !== parseInt(payment.id))
            }
          }
        }
      }).then(
        () => {
          console.log('reportingBkiPaymentsSent updated')
        },
        (err: Error) => {
          console.log(err.message)
        }
      )
    },
    [deal, updateDeal, reportingBkiPaymentsSent]
  )

  const paymentsWithBkiConfig = useMemo<GridViewConfig<PaymentWithBki>>(
    () => ({
      grid: 'grid-cols-auto-4 gap-y-2',
      columns: [
        {
          title: '№',
          value: (_, i) => i + 1
        },
        { title: 'Дата', value: (p) => dateFormatter.format(new Date(p.date)) },
        { title: 'Сумма', value: (p) => `${formatDecimal(p.amount * 100)} ₽` },
        {
          value: (p) => (
            <div className='flex h-full items-center justify-end'>
              {p.bkiPaymentSent ? (
                <Tooltip
                  target={
                    <CheckmarkActualIcon
                      className='cursor-pointer text-red-100'
                      onClick={() => markNotActualPayment(p)}
                    />
                  }
                >
                  {p?.bkiPaymentSentAt && <p>Отправлено: {dateTimeFormatter.format(new Date(p.bkiPaymentSentAt))}</p>}
                </Tooltip>
              ) : (
                <CheckmarkNotActualIcon className='cursor-pointer' onClick={() => markActualPayment(p)} />
              )}
            </div>
          )
        }
      ]
    }),
    [markActualPayment, markNotActualPayment]
  )

  const [fedResource, setFedResource] = useState(deal?.reportingFedresStatus)
  const [leasingContract, setLeasingContract] = useState(deal?.reportingBkiLeasingContractStatus)
  const [guarantorContract, setGuarantorContract] = useState(deal?.reportingBkiGuarantorContractStatus)
  const [rosFinMonitoring, setRosFinMonitoring] = useState(deal?.reportingRfmStatus)

  useEffect(() => {
    if (deal?.reportingFedresStatus) {
      setFedResource(deal.reportingFedresStatus)
    }
  }, [deal?.reportingFedresStatus])

  useEffect(() => {
    if (deal?.reportingBkiLeasingContractStatus) {
      setLeasingContract(deal.reportingBkiLeasingContractStatus)
    }
  }, [deal?.reportingBkiLeasingContractStatus])

  useEffect(() => {
    if (deal?.reportingBkiGuarantorContractStatus) {
      setGuarantorContract(deal.reportingBkiGuarantorContractStatus)
    }
  }, [deal?.reportingBkiGuarantorContractStatus])

  useEffect(() => {
    if (deal?.reportingRfmStatus) {
      setRosFinMonitoring(deal.reportingRfmStatus)
    }
  }, [deal?.reportingRfmStatus])

  if (!deal) return null

  const changeReportingStatus = async (
    type: string,
    state:
      | ReportingFedresStatus
      | ReportingBkiLeasingContractStatus
      | ReportingBkiGuarantorContractStatus
      | ReportingRfmStatus
  ) => {
    await updateDeal({
      variables: {
        input: {
          id: deal._id.toString(),
          reportingFedresStatus: type === 'fedres' ? (state as ReportingFedresStatus) : fedResource || undefined,
          reportingBkiLeasingContractStatus:
            type === 'bkiLeasingContract' ? (state as ReportingBkiLeasingContractStatus) : leasingContract || undefined,
          reportingBkiGuarantorContractStatus:
            type === 'bkiGuarantorContract'
              ? (state as ReportingBkiGuarantorContractStatus)
              : guarantorContract || undefined,
          reportingRfmStatus: type === 'rfm' ? (state as ReportingRfmStatus) : rosFinMonitoring || undefined
        }
      }
    }).then(
      () => {
        console.log('reporting status updated')
      },
      (err: Error) => {
        console.log(err.message)
      }
    )
  }

  return (
    <div className='grid grid-cols-3 gap-6'>
      <div className='col-span-1 flex flex-col gap-6'>
        <Card>
          <CardHeader>
            <CardTitle>Бюро Кредитных Историй</CardTitle>
          </CardHeader>
          <div className='px-5 pb-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              <div className='p-6'>
                <div className='inp-label text-p350 mb-5'>Договор лизинга</div>
                <RadioGroup
                  className='grid auto-cols-fr grid-flow-col rounded-md bg-grayscale-450 p-1'
                  value={leasingContract}
                  onChange={(value) => {
                    if (value) {
                      changeReportingStatus('bkiLeasingContract', value)
                      setLeasingContract(value)
                    }
                  }}
                >
                  <RadioGroup.Option value={ReportingBkiLeasingContractStatus.Sent}>
                    {({ checked }) => (
                      <div
                        className={c(
                          'm-1 cursor-pointer rounded-md border-1 border-solid border-grayscale-450 px-5 py-5 text-center',
                          checked && 'border-grayscale-350 bg-white-0'
                        )}
                      >
                        Передан
                      </div>
                    )}
                  </RadioGroup.Option>
                  <RadioGroup.Option value={ReportingBkiLeasingContractStatus.Pending}>
                    {({ checked }) => (
                      <div
                        className={c(
                          'm-1 cursor-pointer rounded-md border-1 border-solid border-grayscale-450 px-5 py-5 text-center',
                          checked && 'border-grayscale-350 bg-white-0'
                        )}
                      >
                        Не Передан
                      </div>
                    )}
                  </RadioGroup.Option>
                </RadioGroup>
              </div>
              {hasGuarantors && (
                <div className='p-6'>
                  <div className='inp-label text-p350 mb-5'>Договор(-ы) поручительства</div>
                  <RadioGroup
                    className='grid auto-cols-fr grid-flow-col rounded-md bg-grayscale-450 p-1'
                    value={guarantorContract}
                    onChange={(value) => {
                      if (value) {
                        changeReportingStatus('bkiGuarantorContract', value)
                        setGuarantorContract(value)
                      }
                    }}
                  >
                    <RadioGroup.Option value={ReportingBkiGuarantorContractStatus.Sent}>
                      {({ checked }) => (
                        <div
                          className={c(
                            'm-1 cursor-pointer rounded-md border-1 border-solid border-grayscale-450 px-5 py-5 text-center',
                            checked && 'border-grayscale-350 bg-white-0'
                          )}
                        >
                          Передан
                        </div>
                      )}
                    </RadioGroup.Option>
                    <RadioGroup.Option value={ReportingBkiGuarantorContractStatus.Pending}>
                      {({ checked }) => (
                        <div
                          className={c(
                            'm-1 cursor-pointer rounded-md border-1 border-solid border-grayscale-450 px-5 py-5 text-center',
                            checked && 'border-grayscale-350 bg-white-0'
                          )}
                        >
                          Не Передан
                        </div>
                      )}
                    </RadioGroup.Option>
                  </RadioGroup>
                </div>
              )}
            </div>
          </div>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Отметки в БКИ</CardTitle>
          </CardHeader>
          <div className='px-5 pb-5'>
            <GridView
              data={paymentsWithBki}
              config={paymentsWithBkiConfig}
              loading={leasingPaymentsLoading}
              error={leasingPaymentsError?.message}
            />
          </div>
        </Card>
      </div>

      <div className='col-span-1 flex flex-col gap-6'>
        <Card>
          <CardHeader>
            <CardTitle>РосФинМониторинг</CardTitle>
          </CardHeader>
          <div className='px-5 pb-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              <RadioGroup
                className='text-p350 flex-wrap px-6'
                value={rosFinMonitoring}
                onChange={(value) => {
                  if (value) {
                    changeReportingStatus('rfm', value)
                    setRosFinMonitoring(value)
                  }
                }}
              >
                {ROS_FIN_MONITORING_OPTIONS.map((option, i) => (
                  <RadioGroup.Option className='cursor-pointer py-5' value={option[1]} key={i}>
                    {({ checked }) => (
                      <div className='flex grow flex-col'>
                        <div className='flex items-center'>
                          <div
                            className={c(
                              'h-10 w-10 rounded-full border-1 border-grayscale-300',
                              checked && 'border-[6px] !border-red-100'
                            )}
                          />
                          <div className='rounded-full px-6'>{option[0]}</div>
                        </div>

                        {(option[0] === 'Отчитано полностью' || option[0] === 'Отчитано частично') && checked && (
                          <div className='mt-5'>
                            <EntityFolder
                              entityId={deal._id}
                              entityType={DocumentTypeEntity.Deal}
                              folderName='Отчёты РФМ'
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
            </div>
          </div>
        </Card>
      </div>

      <div className='col-span-1 flex flex-col gap-6'>
        <Card>
          <CardHeader>
            <CardTitle>Федресурс</CardTitle>
          </CardHeader>
          <div className='px-5 pb-5'>
            <div className='rounded-md bg-surface-primary p-5 shadow-card'>
              <RadioGroup
                className='grid auto-cols-fr grid-flow-col rounded-md bg-grayscale-450 p-1'
                value={fedResource}
                onChange={(value) => {
                  if (value) {
                    changeReportingStatus('fedres', value)
                    setFedResource(value)
                  }
                }}
              >
                <RadioGroup.Option value={ReportingFedresStatus.Sent}>
                  {({ checked }) => (
                    <div
                      className={c(
                        'm-1 cursor-pointer rounded-md border-1 border-solid border-grayscale-450 px-5 py-5 text-center',
                        checked && 'border-grayscale-350 bg-white-0'
                      )}
                    >
                      Направлен
                    </div>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option value={ReportingFedresStatus.Pending}>
                  {({ checked }) => (
                    <div
                      className={c(
                        'm-1 cursor-pointer rounded-md border-1 border-solid border-grayscale-450 px-5 py-5 text-center',
                        checked && 'border-grayscale-350 bg-white-0'
                      )}
                    >
                      Не направлен
                    </div>
                  )}
                </RadioGroup.Option>
              </RadioGroup>
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default Regulators
