import { DocumentTypeEntity, useDocumentsQuery } from '../../graphql/schema'
import useNodes from '../../hooks/useNodes'

interface DocumentsQueryVars {
  entityId: number | string
  entityType: DocumentTypeEntity
  folderName: string
}

const useEntityDocuments = ({ entityId, entityType, folderName }: DocumentsQueryVars) => {
  const { data } = useDocumentsQuery({
    variables: {
      filter: {
        entityType,
        type: folderName,
        entityId: `${entityId}`
      }
    }
  })

  const files = useNodes(data?.documents?.edges)

  return files
}

export default useEntityDocuments
