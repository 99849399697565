import { showPopup } from '../components/Toaster/showPopup'
import { jwtVar } from '../graphql/cache'

export type HTTP_METHODS = 'GET' | 'POST'
/**
 * downloads the file by id
 * adding jwt token to the request header
 */
export default function downloadAndSaveFile(
  url: string,
  fileName: string,
  type: HTTP_METHODS = 'GET',
  body?: any
): Promise<void> {
  return downloadFile(url, type, body).then((blob) => saveFile(blob, fileName))
}

export const downloadFile = (url: string, type: HTTP_METHODS = 'GET', body?: any) => {
  return fetch(url, {
    method: type,
    headers: {
      Authorization: `Bearer ${jwtVar()}`
    },
    body: body ? JSON.stringify(body) : null
  }).then(async (response) => {
    if (response.ok) {
      return response.blob()
    }

    // Проверка на статус 401 - ошибка авторизации
    if (response.status === 401) {
      // Показываем попап с сообщением об ошибке авторизации
      showPopup({
        title: 'Ошибка авторизации',
        subtitle: 'Произошла ошибка авторизации. Попробуйте обновить страницу и попробовать снова',
        autoCloseTimeout: 5000
      })

      // Дополнительно выбрасываем ошибку, чтобы обработать ее выше по цепочке
      throw new Error('Unauthorized - 401')
    }

    // Обработка остальных ошибок
    const errors = (await response.json()).errors
    if (typeof errors === 'string') {
      throw new Error('Network response was not ok.')
    }
    throw new Error(JSON.stringify(errors))
  })
}

export const saveFile = (blob: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.target = '_blank'
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}
