import { FC, useMemo } from 'react'
import {
  DealStatus,
  usePaymentDisciplineDealsQuery,
  PaymentDisciplineDealsQuery,
  usePaymentDisciplineDealsPaymentsQuery,
  PaymentState
} from '../../../graphql/schema'
import { useParams } from 'react-router-dom'
import { overdueDays } from '../../../utils/payment'
import DisciplineDealGroup from './DisciplineDealGroup'
import { NodeType } from '../../../types'
import { Card, CardHeader, CardTitle } from '../../../components/Card'
import BarChart, { PaymentBar } from './BarChart'
import { formatMoney } from '../../../utils/formatNumber'

const completedDealStatuses = [DealStatus.Closed, DealStatus.Terminated, DealStatus.Cancelled]

type DisciplineDeal = NodeType<NonNullable<PaymentDisciplineDealsQuery['customerCompany']>['deals']>

interface PaymentTotals {
  pending: number
  pendingAmount: number
  overdue: number
  overdueAmount: number
  penalties: number
  penaltiesAmount: number
}

const PaymentDiscipline: FC = () => {
  const { id } = useParams<'id'>()
  const { data: dealsData } = usePaymentDisciplineDealsQuery({
    variables: { customerCompanyId: id as string },
    skip: !id
  })

  const dealIds = useMemo(() => {
    const result: string[] = []
    dealsData?.customerCompany?.deals?.edges?.forEach((edge) => {
      if (edge?.node) result.push(edge.node._id.toString())
    })
    return result
  }, [dealsData])

  const { data: dealsPaymentsData } = usePaymentDisciplineDealsPaymentsQuery({
    variables: { dealIds },
    skip: !dealIds.length
  })

  const [paymentStats, paymentTotals] = useMemo(() => {
    const stats = new Map<number, PaymentBar>()
    const totals: PaymentTotals = {
      pending: 0,
      pendingAmount: 0,
      overdue: 0,
      overdueAmount: 0,
      penalties: 0,
      penaltiesAmount: 0
    }
    let firstMonth: number = Infinity
    let lastMonth: number = 0

    dealsPaymentsData?.payments?.edges?.forEach((dealPayment) => {
      const payment = dealPayment?.node
      if (!payment) return

      if (payment.penaltyPayments.length) {
        payment.penaltyPayments.forEach((penalty) => {
          totals.penalties += 1
          totals.penaltiesAmount += penalty.amount
        })
      }

      const paymentDate = new Date(payment.date)
      paymentDate.setDate(1)
      const monthTimestamp = paymentDate.setHours(0, 0, 0, 0)

      if (monthTimestamp < firstMonth) firstMonth = monthTimestamp
      if (monthTimestamp > lastMonth) lastMonth = monthTimestamp

      if (!stats.has(monthTimestamp)) {
        stats.set(monthTimestamp, {
          month: new Date(monthTimestamp),
          pendingAmount: 0,
          paidOnTimeAmount: 0,
          paidOverdueAmount: 0,
          unpaidOverdueAmount: 0
        })
      }
      const bucket = stats.get(paymentDate.getTime())
      if (!bucket) return

      const today = new Date()
      const daysOverdue = overdueDays(payment, today)

      if (payment.state === PaymentState.Paid) {
        if (daysOverdue > 0) {
          bucket.paidOverdueAmount += payment.amount
        } else {
          bucket.paidOnTimeAmount += payment.amount
        }
      } else {
        if (daysOverdue > 0) {
          bucket.unpaidOverdueAmount += payment.amount
          totals.overdue += 1
          totals.overdueAmount += payment.amount
        } else {
          bucket.pendingAmount += payment.amount
          totals.pending += 1
          totals.pendingAmount += payment.amount
        }
      }
    })

    console.log('firstMonth', firstMonth, 'lastMonth', lastMonth)
    // fill in missing months
    if (firstMonth > 0 && lastMonth < Infinity && firstMonth < lastMonth) {
      console.log('filling months', firstMonth, lastMonth)
      const currentMonth = new Date(firstMonth)
      while (currentMonth.getTime() <= lastMonth) {
        if (!stats.has(currentMonth.getTime())) {
          console.log('filling', currentMonth)
          stats.set(currentMonth.getTime(), {
            month: new Date(currentMonth),
            pendingAmount: 0,
            paidOnTimeAmount: 0,
            paidOverdueAmount: 0,
            unpaidOverdueAmount: 0
          })
        }
        currentMonth.setMonth(currentMonth.getMonth() + 1)
      }
    }

    const sortedStats = Array.from(stats.values()).sort((a, b) => a.month.getTime() - b.month.getTime())
    return [sortedStats, totals] as const
  }, [dealsPaymentsData])

  const [activeDeals, completedDeals] = useMemo(() => {
    if (!dealsData) return [[], []]
    const deals = dealsData?.customerCompany?.deals?.edges
    if (!deals) return [[], []]

    const active: DisciplineDeal[] = []
    const completed: DisciplineDeal[] = []

    deals.forEach((dealNode) => {
      const deal = dealNode?.node
      if (!deal) return
      if (completedDealStatuses.includes(deal.status)) {
        completed.push(deal)
      } else {
        active.push(deal)
      }
    })

    return [active, completed]
  }, [dealsData])

  return (
    <div className='grid gap-y-15'>
      <div className='grid gap-5'>
        <div className='grid grid-cols-3 gap-5'>
          <Card className='flex flex-col'>
            <CardHeader>
              <CardTitle>Оставшиеся платежи</CardTitle>
            </CardHeader>
            <div className='col-span-2 flex flex-1 flex-col px-5 pb-5'>
              {paymentTotals.pending > 0 ? (
                <div className='grid grid-cols-[1fr_auto_1fr] divide-y-1 divide-separators-primary rounded-md bg-surface-primary shadow-card'>
                  <div className='col-span-3 grid grid-cols-subgrid divide-x-1 divide-separators-primary'>
                    <div className='px-7 py-4 text-labels-secondary'>Платежи</div>
                    <div className='px-7 py-4 text-labels-secondary'>На сумму</div>
                  </div>

                  <div className='col-span-3 grid grid-cols-subgrid divide-x-1 divide-separators-primary'>
                    <div className='px-7 py-4 font-mono tabular-nums'>{paymentTotals.pending}</div>
                    <div className='px-7 py-4 font-mono tabular-nums'>{formatMoney(paymentTotals.pendingAmount)}</div>
                  </div>
                </div>
              ) : (
                <div className='flex flex-1 items-center justify-center rounded-md bg-surface-primary px-7 py-4 text-center text-labels-tertiary shadow-card'>
                  Платежей нет
                </div>
              )}
            </div>
          </Card>
          <Card className='flex flex-col'>
            <CardHeader>
              <CardTitle>Общая просрочка</CardTitle>
            </CardHeader>
            <div className='col-span-2 flex flex-1 flex-col px-5 pb-5'>
              {paymentTotals.overdue > 0 ? (
                <div className='grid grid-cols-[1fr_auto_1fr] divide-y-1 divide-separators-primary rounded-md bg-surface-primary shadow-card'>
                  <div className='col-span-3 grid grid-cols-subgrid divide-x-1 divide-separators-primary'>
                    <div className='px-7 py-4 text-labels-secondary'>Платежи</div>
                    <div className='px-7 py-4 text-labels-secondary'>На сумму</div>
                  </div>

                  <div className='col-span-3 grid grid-cols-subgrid divide-x-1 divide-separators-primary'>
                    <div className='px-7 py-4 font-mono tabular-nums'>{paymentTotals.overdue}</div>
                    <div className='px-7 py-4 font-mono tabular-nums'>{formatMoney(paymentTotals.overdueAmount)}</div>
                  </div>
                </div>
              ) : (
                <div className='flex flex-1 items-center justify-center rounded-md bg-surface-primary px-7 py-4 text-center text-labels-tertiary shadow-card'>
                  Просрочек нет
                </div>
              )}
            </div>
          </Card>
          <Card className='flex flex-col'>
            <CardHeader>
              <CardTitle>Пени</CardTitle>
            </CardHeader>
            <div className='col-span-2 flex flex-1 flex-col px-5 pb-5'>
              {paymentTotals.penalties > 0 ? (
                <div className='grid grid-cols-[1fr_auto_1fr] divide-y-1 divide-separators-primary rounded-md bg-surface-primary shadow-card'>
                  <div className='col-span-3 grid grid-cols-subgrid divide-x-1 divide-separators-primary'>
                    <div className='px-7 py-4 text-labels-secondary'>Платежи</div>
                    <div className='px-7 py-4 text-labels-secondary'>На сумму</div>
                  </div>

                  <div className='col-span-3 grid grid-cols-subgrid divide-x-1 divide-separators-primary'>
                    <div className='px-7 py-4 font-mono tabular-nums'>{paymentTotals.penalties}</div>
                    <div className='px-7 py-4 font-mono tabular-nums'>{formatMoney(paymentTotals.penaltiesAmount)}</div>
                  </div>
                </div>
              ) : (
                <div className='flex flex-1 items-center justify-center rounded-md bg-surface-primary px-7 py-4 text-center text-labels-tertiary shadow-card'>
                  Пеней нет
                </div>
              )}
            </div>
          </Card>
        </div>

        {!!paymentStats.length && (
          <Card>
            <CardHeader>
              <CardTitle>Платёжная нагрузка</CardTitle>
            </CardHeader>
            <div className='px-5 pb-5'>
              <div className='rounded-md bg-surface-primary shadow-card'>
                <BarChart data={paymentStats} className='h-100' />
              </div>
            </div>
          </Card>
        )}
      </div>
      {!!activeDeals.length && <DisciplineDealGroup title='Активные' deals={activeDeals} />}
      {!!completedDeals?.length && <DisciplineDealGroup title='Завершенные' deals={completedDeals} />}
    </div>
  )
}

export default PaymentDiscipline
