import { FC, useState } from 'react'
import { ReactComponent as DownloadIcon } from '../../../svg/icons/fileDownload.svg'
import { ReactComponent as WarningIcon } from '../../../svg/ui/warning.svg'
import { ReactComponent as TrashIcon } from '../../../svg/ui/delete.svg'
import { ReactComponent as PlusIcon } from '../../../svg/icons/plus.svg'
import {
  AgentCommissionRewardStatus,
  BankOrderTargetType,
  DealStatus,
  DocumentTypeEntity,
  useAgentCommissionRewardQuery,
  useBankOrdersQuery,
  useDealForCommissionQuery,
  useDocumentsQuery,
  useRemoveAgentCommissionRewardMutation,
  useUpdateAgentCommissionRewardMutation,
  VatKind
} from '../../../graphql/schema'
import { Link } from 'react-router-dom'
import { formatMoneyDecimal } from '../../../utils/formatNumber'
import useAccessCheck from '../../../hooks/useAccessCheck'
import { RadioGroup } from '@headlessui/react'
import Modal from '../../../components/Modal'
import ConfirmationForm from '../../../components/ConfirmationForm'
import EntityFolder from '../../../components/DocumentManager/EntityFolder'
import { COMMISSION_REWARD_FOLDER_ACT_NAME, COMMISSION_REWARD_FOLDER_INVOICE_NAME } from '../../../config'
import Hint from '../../../components/Hint'
import ActReportForm from '../Documents/ActReportForm'
import SuspenseModal from '../../../components/SuspenseModal.tsx'
import Tooltip from '../../../components/Tooltip.tsx'
import RewardPaymentForm from './RewardPaymentForm.tsx'
import useNodes from '../../../hooks/useNodes.ts'
import BankOrders from '../../../components/BankOrders/BankOrders.tsx'

const calculateCommissionAmount = (amount: number, rate: number) =>
  Math.round(Number(amount * Number((rate / 100).toFixed(8))))

const COMMISSION_OPTIONS = [
  ['Не выплачено', AgentCommissionRewardStatus.Pending],
  ['Выплачено', AgentCommissionRewardStatus.Paid]
]

interface CommissionRewardProps {
  rewardId: number
  dealId?: number
  onDelete?: () => void
}

const CommissionReward: FC<CommissionRewardProps> = ({ rewardId, dealId, onDelete }) => {
  const hasAccessRewardManage = useAccessCheck('deal.agent_commission.reward.manage')
  const hasAccessRewardEdit = useAccessCheck('deal.agent_commission.reward.edit')
  const hasRewardPaymentsManage = useAccessCheck('deal.agent_commission.reward.payments_manage')

  const [updateAgentCommissionRewardMutation] = useUpdateAgentCommissionRewardMutation()
  const [removeAgentCommissionRewardMutation] = useRemoveAgentCommissionRewardMutation()

  const [deletionFormOpen, setDeletionFormOpen] = useState(false)
  const [actReportFormOpen, setActReportFormOpen] = useState(false)
  const [rewardPaymenFormOpen, setRewardPaymenFormOpen] = useState(false)

  const { data: dealData } = useDealForCommissionQuery({ variables: { id: `${dealId}` }, skip: !dealId })
  const deal = dealData?.deal

  const { data: rewardData, refetch } = useAgentCommissionRewardQuery({
    variables: { id: `${rewardId}` },
    skip: !rewardId
  })
  const reward = rewardData?.agentCommissionReward

  const { data: actDocumentsData } = useDocumentsQuery({
    variables: {
      filter: {
        entityId: `${rewardId}`,
        entityType: DocumentTypeEntity.AgentCommissionReward,
        type: COMMISSION_REWARD_FOLDER_ACT_NAME
      }
    },
    skip: !rewardId
  })

  const isOsno = reward?.source?.company?.vatKind === VatKind.Osno
  const hasDocuments = !!actDocumentsData?.documents?.edges?.length
  const hasAgentContractN = !!reward?.source?.agentContractNumber
  const hasAgentContractDate = !!reward?.source?.agentContractDate
  const hasVatKind = !!reward?.source?.company?.vatKind
  const hasEdoStatus = typeof reward?.source?.edoStatus === 'boolean'
  const hasInn = !!reward?.source?.company?.inn
  const hasRequisites = !!reward?.source?.company?.bankDetails?.totalCount
  const hasSupplies = !!deal?.dealSupplies?.totalCount
  const readyActReport =
    hasVatKind && hasEdoStatus && hasInn && hasRequisites && hasAgentContractN && hasAgentContractDate && hasSupplies

  const { data: bankOrdersQuery, refetch: refetchBankOrders } = useBankOrdersQuery({
    variables: {
      targetId: `${rewardId}`,
      targetType: BankOrderTargetType.AgentCommissionReward
    }
  })
  const payments = useNodes(bankOrdersQuery?.bankOrders.edges)

  const { data: DocumentsQuery } = useDocumentsQuery({
    variables: {
      filter: {
        entityType: DocumentTypeEntity.AgentCommissionReward,
        type: COMMISSION_REWARD_FOLDER_ACT_NAME,
        entityId: `${rewardId}`
      }
    },
    skip: !rewardId
  })
  const actReportDocs = useNodes(DocumentsQuery?.documents?.edges)
  const readyRewardPay = !!actReportDocs.length

  async function updateAgentCommissionRewardStatus(
    rewardId: number,
    status: AgentCommissionRewardStatus
  ): Promise<void> {
    await updateAgentCommissionRewardMutation({
      variables: {
        input: {
          id: rewardId.toString(),
          status
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateAgentCommissionReward: {
          __typename: 'updateAgentCommissionRewardPayload',
          agentCommissionReward: {
            __typename: 'AgentCommissionReward',
            _id: rewardId,
            status
          }
        }
      }
    })
  }

  const expectedAmount =
    deal?.status === DealStatus.PaymentReception
      ? calculateCommissionAmount(deal?.amount, reward?.commissionRate || 0)
      : undefined

  return (
    <>
      <div className='col-span-1'>
        <div className='rounded-md bg-white-0 pb-4 shadow-xs'>
          <div className='p-10'>
            {reward?.source?.company?.shortWithOpf ? (
              <>
                <Link className='hover:text-red-100' to={`/sources/${reward?.source?._id}`}>
                  <h2 className='font-display text-h200'>{reward?.source?.company?.shortWithOpf}</h2>
                </Link>
                <p className='mt-3 text-grayscale-150'>{reward?.source?.name}</p>
              </>
            ) : (
              <Link className='text-grayscale-150 hover:text-red-100' to={`/sources/${reward?.source?._id}`}>
                <p className='mt-3'>{reward?.source?.name}</p>
              </Link>
            )}
          </div>
          <div className='px-4 pb-6'>
            <table className='conte w-full'>
              <tbody>
                <tr className='group'>
                  <td className='px-6 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    Комиссия
                  </td>
                  <td className='px-6 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    {reward?.commissionRate}%
                  </td>
                </tr>
                <tr className='group'>
                  <td className='px-6 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    Сумма комиссии агенту
                  </td>
                  <td className='flex items-center justify-end gap-x-5 px-6 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    {!!expectedAmount && reward?.amount !== expectedAmount && (
                      <div className='relative'>
                        <Tooltip target={<WarningIcon className='text-red-100' height='20px' width='20px' />}>
                          Ожидаемая: {formatMoneyDecimal(expectedAmount / 100)}
                        </Tooltip>
                      </div>
                    )}
                    {reward?.amount ? formatMoneyDecimal(reward.amount / 100) : '—'}
                  </td>
                </tr>
                <tr className='group'>
                  <td className='px-6 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    Статус
                  </td>
                  <td className='px-6 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    {reward?.status === AgentCommissionRewardStatus.Paid ? 'Выплачено' : 'Не выплачено'}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='border-t-1 border-grayscale-400 px-10 py-6'>
            <div className='mb-6 font-display font-medium'>Оплата</div>
            <BankOrders
              companyName={reward?.source?.company?.shortWithOpf || ''}
              payments={payments}
              canDelete={hasRewardPaymentsManage}
            />
            {hasRewardPaymentsManage && (
              <Tooltip
                target={
                  <button
                    onClick={() => setRewardPaymenFormOpen(true)}
                    disabled={!readyRewardPay}
                    className='mt-6 flex h-25 w-full items-center justify-center gap-5 rounded-xl border-1 border-dashed border-grayscale-300 text-grayscale-250 hover:text-grayscale-200 disabled:hover:text-grayscale-250'
                  >
                    {readyRewardPay ? <PlusIcon /> : <WarningIcon width={20} height={20} />}
                    Создать платеж
                  </button>
                }
              >
                {!readyRewardPay ? 'Необходимо подгрузить Акт-отчет' : ''}
              </Tooltip>
            )}
          </div>
          <div className='border-t-1 border-grayscale-400 px-10 py-6'>
            <div className='mb-6 font-display font-medium'>Акт-отчет</div>
            <button
              onClick={() => setActReportFormOpen(true)}
              disabled={!readyActReport}
              className='flex h-25 w-full items-center justify-center gap-5 rounded-xl border-1 border-dashed border-grayscale-300 text-grayscale-250 hover:text-grayscale-200 disabled:hover:text-grayscale-250'
            >
              {readyActReport ? <DownloadIcon /> : <WarningIcon />}
              Сгенерировать
            </button>
          </div>
          <div className='border-t-1 border-grayscale-400 px-10 py-6'>
            <div className='mb-6 font-display font-medium'>Акт</div>
            <EntityFolder
              entityId={rewardId}
              entityType={DocumentTypeEntity.AgentCommissionReward}
              folderName={COMMISSION_REWARD_FOLDER_ACT_NAME}
              canDelete={reward?.status !== AgentCommissionRewardStatus.Paid}
            />
          </div>
          {isOsno && (
            <div className='border-t-1 border-grayscale-400 px-10 py-6'>
              <div className='mb-6 font-display font-medium'>Счёт-фактура</div>
              <EntityFolder
                entityId={rewardId}
                entityType={DocumentTypeEntity.AgentCommissionReward}
                folderName={COMMISSION_REWARD_FOLDER_INVOICE_NAME}
                canDelete={reward?.status !== AgentCommissionRewardStatus.Paid}
              />
            </div>
          )}
          {hasAccessRewardManage && hasDocuments && (
            <div className='border-t-1 border-grayscale-400 px-10 py-6'>
              <RadioGroup
                className='grid auto-cols-fr grid-flow-col rounded-xl bg-grayscale-450 p-1'
                value={reward?.status}
                onChange={(value) => updateAgentCommissionRewardStatus(rewardId, value)}
              >
                {COMMISSION_OPTIONS.map((option, i) => (
                  <RadioGroup.Option key={i} value={option[1]}>
                    <div className='m-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center ui-checked:border-grayscale-350 ui-checked:bg-white-0'>
                      {option[0]}
                    </div>
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
            </div>
          )}
          {hasAccessRewardEdit && reward?.status !== AgentCommissionRewardStatus.Paid && (
            <div
              className='flex cursor-pointer items-center border-t-1 border-grayscale-400 px-12 py-8 text-red-100'
              onClick={() => setDeletionFormOpen(true)}
            >
              <TrashIcon height='16px' width='15px' className='mr-8' title='Удалить' />
              Удалить комиссию
            </div>
          )}
          {!readyActReport && (
            <div className='grid gap-y-2 border-t-1 border-grayscale-400 px-4'>
              <div className='px-8 py-6 text-grayscale-150'>Необходимо заполнить:</div>
              {!hasAgentContractN && <Hint text='Агентский договор' to={`/sources/${reward?.source?._id}`} />}
              {!hasAgentContractDate && <Hint text='Дата агентского договора' to={`/sources/${reward?.source?._id}`} />}
              {!hasVatKind && <Hint text='Система налогообложения' to={`/sources/${reward?.source?._id}`} />}
              {!hasEdoStatus && <Hint text='ЭДО' to={`/sources/${reward?.source?._id}`} />}
              {!hasInn && <Hint text='ИНН источника' to={`/sources/${reward?.source?._id}`} />}
              {!hasRequisites && <Hint text='Банковские реквизиты' to={`/sources/${reward?.source?._id}`} />}
              {!hasSupplies && <Hint text='Поставки' to={`/deals/${dealId}/supplies`} />}
            </div>
          )}
        </div>
      </div>
      <SuspenseModal
        open={actReportFormOpen}
        setOpen={() => {
          setActReportFormOpen(false)
          refetch()
        }}
      >
        <div className='z-10 rounded-xl bg-white-0'>
          <ActReportForm dealId={dealId} sourceId={reward?.source?._id} />
        </div>
      </SuspenseModal>
      <SuspenseModal open={rewardPaymenFormOpen} setOpen={setRewardPaymenFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <RewardPaymentForm
            rewardId={rewardId}
            onDone={() => {
              setRewardPaymenFormOpen(false)
              refetchBankOrders()
            }}
          />
        </div>
      </SuspenseModal>
      <Modal open={deletionFormOpen} setOpen={setDeletionFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ConfirmationForm
            title='Удаление комиссии'
            onDone={async () => {
              setDeletionFormOpen(false)
              await removeAgentCommissionRewardMutation({
                variables: { input: { id: rewardId.toString() } }
              })
              onDelete?.()
            }}
            onDismiss={() => setDeletionFormOpen(false)}
          >
            Вы действительно хотите удалить комиссию?
          </ConfirmationForm>
        </div>
      </Modal>
    </>
  )
}

export default CommissionReward
