import { FC } from 'react'
import { useAddCollectionProcessMutation } from '../../../graphql/schema'
import { ReactComponent as LoadingIcon } from './../../../svg/icons/loading.svg'

interface AddCollectionProcessProps {
  dealId: string
  paymentId: number
  onAddCollectionProcess: () => void
}

const AddCollectionProcess: FC<AddCollectionProcessProps> = ({ dealId, paymentId, onAddCollectionProcess }) => {
  const [addCollectionProcess, { loading: addCollectionProcessLoading }] = useAddCollectionProcessMutation()

  return (
    <button
      onClick={async () => {
        await addCollectionProcess({
          variables: { input: { payment: paymentId, deal: `${dealId}` } }
        })
        onAddCollectionProcess()
      }}
      className='py-2 px-5 border-1 text-grayscale-150 rounded-xl border-grayscale-400 font-medium flex items-center'
    >
      Запустить коллекшн
      {addCollectionProcessLoading && <LoadingIcon className='animate-spin mx-auto ml-5' width='14px' height='14px' />}
    </button>
  )
}

export default AddCollectionProcess
