import { FC } from 'react'
import { CustomersFilterState, useCustomerFilter } from './FilterForm'
import SelectedFilter from '../../components/Filter/SelectedFilter'
import UserName from '../../components/UserName'

const SelectedFilters: FC = () => {
  const { filterState, setFilterState, filterActive } = useCustomerFilter()

  if (!filterActive || filterState?.query?.length) return null

  return (
    <div className='mb-8 flex flex-wrap gap-2'>
      {(Object.keys(filterState) as (keyof CustomersFilterState)[]).map((key) => {
        switch (key) {
          case 'managerUsers':
            return filterState[key]?.map((filter: number) => (
              <SelectedFilter
                key={key + filter}
                onRemove={() =>
                  setFilterState({
                    ...filterState,
                    [key]: filterState[key].filter((id) => id !== filter)
                  })
                }
              >
                <UserName userId={filter} />
              </SelectedFilter>
            ))
        }
      })}
    </div>
  )
}

export default SelectedFilters
