import { FC, useMemo } from 'react'
import { DealStatus, PaymentState, usePaymentDisciplineDealPaymentsQuery } from '../../../graphql/schema'
import { formatMoney } from '../../../utils/formatNumber'
import { DealStatusBadge } from '../../../components/DealStatus'
import useNodes from '../../../hooks/useNodes'
import PaymentCalendar from './PaymentCalendar'
import { Link } from 'react-router-dom'

interface DisciplineDealProps {
  id: number
  amount: number
  status: DealStatus
}

interface PaymentStats {
  pending: number
  pendingAmonut: number
  overdue: number
  overdueAmount: number
  penalties: number
  penaltiesAmount: number
}

const DisciplineDeal: FC<DisciplineDealProps> = ({ id, amount, status }) => {
  const { data: paymentsData } = usePaymentDisciplineDealPaymentsQuery({
    variables: { dealId: id.toString() },
    skip: !id
  })

  const payments = useNodes(paymentsData?.payments?.edges)

  const paymentStats = useMemo(() => {
    const stats: PaymentStats = {
      pending: 0,
      pendingAmonut: 0,
      overdue: 0,
      overdueAmount: 0,
      penalties: 0,
      penaltiesAmount: 0
    }

    payments.forEach((payment) => {
      if (payment.isOverdued) {
        stats.overdue += 1
        stats.overdueAmount += payment.amount
      }
      if (payment.state !== PaymentState.Paid) {
        stats.pending += 1
        stats.pendingAmonut += payment.amount
      }
      payment.penaltyPayments?.forEach((penalty) => {
        stats.penalties += 1
        stats.penaltiesAmount += penalty.amount
      })
    }, [])

    return stats
  }, [payments])

  return (
    <section className='grid grid-cols-3 gap-x-5'>
      <div>
        <div className='rounded-md bg-surface-primary shadow-card'>
          <div className='flex items-center justify-between border-b-1 border-separators-primary px-7 py-4 last:border-b-0'>
            <div>
              <Link to={`/deals/${id}`}>
                <h3 className='text-title-m font-medium'>Сделка № {id}</h3>
              </Link>
              <div className='text-labels-secondary'>{formatMoney(amount / 100)}</div>
            </div>
            <DealStatusBadge status={status} />
          </div>
          {!!payments.length && (
            <div className='grid grid-cols-[1fr_auto_1fr] divide-y-1 divide-separators-primary'>
              <div className='col-span-3 grid grid-cols-subgrid divide-x-1 divide-separators-primary'>
                <div className='' />
                <div className='px-7 py-4 text-labels-secondary'>Кол-во</div>
                <div className='px-7 py-4 text-labels-secondary'>На сумму</div>
              </div>

              <div className='col-span-3 grid grid-cols-subgrid divide-x-1 divide-separators-primary'>
                <div className='px-7 py-4 text-labels-secondary'>Осталось</div>
                <div className='px-7 py-4 font-mono tabular-nums'>{paymentStats.pending}</div>
                <div className='px-7 py-4 font-mono tabular-nums'>{formatMoney(paymentStats.pendingAmonut)}</div>
              </div>

              {!!paymentStats.overdue && (
                <div className='col-span-3 grid grid-cols-subgrid divide-x-1 divide-separators-primary'>
                  <div className='px-7 py-4 text-labels-secondary'>Просрочено</div>
                  <div className='px-7 py-4 font-mono'>{paymentStats.overdue}</div>
                  <div className='px-7 py-4 font-mono'>{formatMoney(paymentStats.overdueAmount)}</div>
                </div>
              )}

              {!!paymentStats.penalties && (
                <div className='col-span-3 grid grid-cols-subgrid divide-x-1 divide-separators-primary'>
                  <div className='col-span-2 px-7 py-4 text-labels-secondary'>Пени</div>
                  <div className='px-7 py-4 font-mono'>{formatMoney(paymentStats.penaltiesAmount)}</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {!payments.length ? (
        <div className='col-span-2 grid place-items-center rounded-md bg-surface-primary py-4 text-labels-tertiary shadow-card'>
          Платежей нет
        </div>
      ) : (
        <div className='col-span-2'>
          <PaymentCalendar payments={payments} />
        </div>
      )}
    </section>
  )
}

export default DisciplineDeal
