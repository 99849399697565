import { FC } from 'react'
import { BaseHistoryEventProps } from '../..'
import { formatMoneyDecimal } from '../../../../utils/formatNumber'
import { EventActedAt, EventInitiatedBy } from '../EventComponents'

export interface DealCreatedEventProps extends BaseHistoryEventProps {
  data?: {
    guarantors?: string
    sourceReconciliation: {
      amount: string
      advanceRate: string
      interestRate: string
      comissionRate: string
      insuranceRate: string
      reconciliationNumber: number
    }
  }
}

const DealCreatedEvent: FC<DealCreatedEventProps> = ({ data, actedAt, initiatedByUserId, withLine }) => {
  return (
    <div className='flex gap-6'>
      <EventActedAt date={actedAt} withLine={withLine} />
      <div>
        <div className='flex items-start gap-2'>
          <EventInitiatedBy userId={initiatedByUserId} />
          <span className='text-grayscale-200'>создал(а) сделку</span>
        </div>
        <div>{`Согласование №${data?.sourceReconciliation.reconciliationNumber},
              ${
                data?.sourceReconciliation.amount && formatMoneyDecimal(parseFloat(data?.sourceReconciliation.amount))
              }, A - ${parseFloat(data?.sourceReconciliation.advanceRate || '')}%, Ста -
              ${parseFloat(data?.sourceReconciliation.interestRate || '')}%, Стр - % ${parseFloat(
          data?.sourceReconciliation.insuranceRate || ''
        )}, К - ${parseFloat(data?.sourceReconciliation.comissionRate || '')} %, Поручители: ${
          data?.guarantors === null ? 'Нет поручителей' : data?.guarantors
        }`}</div>
      </div>
    </div>
  )
}

export default DealCreatedEvent
