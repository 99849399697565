import { FC } from 'react'
import { BaseHistoryEventProps } from '../..'
import { EventActedAt, EventInitiatedBy } from '../EventComponents'

export interface ContactChangedEventProps extends BaseHistoryEventProps {
  data?: {
    diff?: {
      fio?: {
        before?: string
        after?: string
      }
    }
  }
}
const ContactChangedEvent: FC<ContactChangedEventProps> = ({ actedAt, data, withLine, initiatedByUserId }) => {
  return (
    <div className='flex gap-6'>
      <EventActedAt date={actedAt} withLine={withLine} />
      <div>
        <div className='flex items-start gap-2'>
          <EventInitiatedBy userId={initiatedByUserId} />
          <span className='text-labels-secondary'>изменил(а) контакт</span>
        </div>
        <div>
          {data?.diff?.fio?.before} → {data?.diff?.fio?.after}
        </div>
      </div>
    </div>
  )
}

export default ContactChangedEvent
