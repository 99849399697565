import { useEffect, useRef, useState } from 'react'

interface InViewportConfig {
  threshold?: number
  once?: boolean
}

const useInViewport = <T extends HTMLElement>({ threshold = 0.5, once = false }: InViewportConfig) => {
  const elementRef = useRef<T | null>(null)
  const [inView, setInView] = useState(false)

  useEffect(() => {
    const target = elementRef.current as HTMLElement
    if (!target) return
    if (once && inView) return

    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting)
      },
      {
        threshold
      }
    )

    observer.observe(target)

    return () => {
      observer.unobserve(target)
    }
  }, [elementRef, threshold, once, inView])

  return [inView, elementRef] as const
}

export default useInViewport
