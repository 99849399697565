import { ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

interface AuthLinkOptions {
  getToken: () => string
  refreshToken: () => Promise<string>
  isTokenExpired: () => boolean
}

export function createValidTokenGetter({ getToken, refreshToken, isTokenExpired }: AuthLinkOptions, pendingAccessTokenPromise: Promise<string> | null) {
  return function getAccessTokenPromise(): Promise<string | undefined> {
    // if token refresh is in progress, return the promise
    if (pendingAccessTokenPromise) {
      return pendingAccessTokenPromise
    }

    // if token is missing return nothing
    if (!getToken()) return new Promise(resolve => resolve(undefined))

    // if token expried, refresh it
    if (isTokenExpired()) {
      pendingAccessTokenPromise = refreshToken().finally(() => pendingAccessTokenPromise = null)
      return pendingAccessTokenPromise
    }

    return new Promise(resolve => resolve(getToken()))
  }
}


export default function createAuthRefreshLink(options: AuthLinkOptions): ApolloLink {
  const pendingAccessTokenPromise: Promise<any> | null = null

  const getValidToken = createValidTokenGetter(options, pendingAccessTokenPromise)

  return setContext(async (_, { headers }) => {
    const accessToken = await getValidToken()

    // token can be missing for auth request
    if (!accessToken) {
      return { headers }
    }

    return {
      headers: {
        ...headers,
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
      }
    }
  })
}