import { FC } from 'react'
import { paymentOverdueStateDict } from '../../../types/bki.ts'

export const paymentOverdueStates = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'A']

interface PaymentOverdueCounterProps {
  paymentOverdue: { [key: string]: number }
}

const PaymentOverdueCounter: FC<PaymentOverdueCounterProps> = ({ paymentOverdue }) => {
  if (Object.keys(paymentOverdue).length === 0) return <div className='mt-6 text-grayscale-150'>Все платежи в срок</div>

  return (
    <div className='mt-6 text-grayscale-150 grid grid-cols-3 grid-flow-rows auto-cols-fr'>
      {paymentOverdueStates.map(
        (state) =>
          paymentOverdue[state] && (
            <div key={state}>
              Просрочка {paymentOverdueStateDict[state]} дней —{' '}
              <span className='font-medium'>{paymentOverdue[state]}</span>
            </div>
          )
      )}
    </div>
  )
}

export default PaymentOverdueCounter
