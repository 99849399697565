import { FC, useState } from 'react'
import c from 'clsx'
import { dateTimeFormatter } from '../../../utils/dateFormatter'
import { useCompanyCheckFinmonQuery, useRefreshCompanyCheckFinmonMutation } from '../../../graphql/schema'
import { ReactComponent as WarningIcon } from '../../../svg/ui/warning.svg'
import { ReactComponent as RefreshIcon } from '../../../svg/ui/refresh.svg'
import { Card, CardHeader, CardIconButton, CardMenu, CardSeparator, CardTitle } from '../../Card.tsx'

type CompanyCheckFinmonResultProps = { loading: boolean; failed: boolean; found: boolean }

const CompanyCheckFinmonResult: FC<CompanyCheckFinmonResultProps> = ({ loading, failed, found }) => {
  if (loading) {
    return (
      <div className='flex items-center justify-end'>
        Идет проверка
        <div className='ml-6 h-7 w-7 rounded-full bg-yellow-400' />
      </div>
    )
  }

  if (failed) {
    return (
      <div className='flex items-center justify-end'>
        <p className='mr-5'>Не удалось проверить</p>
        <WarningIcon className='text-red-100' height='20px' width='20px' />
      </div>
    )
  } else {
    return (
      <div className='flex items-center justify-end'>
        {found ? 'Найден в списках' : 'Проверка пройдена'}
        <div className={c('ml-6 h-7 w-7 rounded-full', found ? 'bg-red-100' : 'bg-green-400')} />
      </div>
    )
  }
}

const CompanyCheckFinmon: FC<{ inn: string }> = ({ inn }) => {
  const [isFetching, setIsFetching] = useState(false)

  const { data, loading, refetch } = useCompanyCheckFinmonQuery({
    variables: {
      inn
    },
    skip: !inn
  })
  const checkCustomer = data?.checkCompany

  const [refreshCompanyCheckCompany] = useRefreshCompanyCheckFinmonMutation()

  async function refreshCheck() {
    if (isFetching) return

    setIsFetching(true)

    await refreshCompanyCheckCompany({
      variables: {
        inn
      }
    })
    await refetch()

    setTimeout(() => {
      setIsFetching(false)
    }, 1000)
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Проверка по ИНН</CardTitle>
        {checkCustomer?.createdDate && (
          <>
            <div className='ml-auto text-labels-tertiary'>
              Обновлено {dateTimeFormatter.format(new Date(checkCustomer?.createdDate))}
            </div>
            <CardSeparator />
          </>
        )}
        <CardMenu>
          <CardIconButton onClick={refreshCheck}>
            <RefreshIcon className={c(loading && 'animate-spin-reverse')} />
          </CardIconButton>
        </CardMenu>
      </CardHeader>

      <div className='px-5 pb-5'>
        <div className='rounded-md bg-surface-primary shadow-card'>
          <table className='w-full'>
            <tbody>
              <tr className='group'>
                <td className='p-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl'>Росфинмониторинг</td>
                <td className='p-6 text-right first:rounded-l-xl last:rounded-r-xl'>
                  <CompanyCheckFinmonResult
                    loading={loading}
                    failed={!checkCustomer}
                    found={!!checkCustomer?.terrorist}
                  />
                </td>
              </tr>
              <tr className='group border-t-1 border-grayscale-400'>
                <td className='p-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl'>Блокировка счетов</td>
                <td className='p-6 text-right first:rounded-l-xl last:rounded-r-xl'>
                  <CompanyCheckFinmonResult
                    loading={loading}
                    failed={!checkCustomer}
                    found={!!checkCustomer?.blockedAccounts}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  )
}

export default CompanyCheckFinmon
