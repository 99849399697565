import { shortDateTimeFormatter } from './dateFormatter'

type ReconciliationStatus = 'accepted' | 'rejected' | 'pending' | undefined

export interface ReconciliationProps {
  ourSide: boolean
  feraStatus: ReconciliationStatus
  customerStatus: ReconciliationStatus
  feraAcceptExpired?: boolean
  feraAcceptExpiresAt?: string
  statusChangedAt?: string
  autoAccepted?: boolean | undefined
}

function getReconciliationStatus(data: ReconciliationProps) {
  if (data.ourSide) {
    if (data.customerStatus === 'rejected') {
      return ['otherRejected']
    }
    switch (data.feraStatus) {
      case undefined:
        return ['pending']
      case 'pending':
        return ['pending']
      case 'rejected':
        if (data.statusChangedAt) {
          return ['rejected', `отказано: ${shortDateTimeFormatter.format(new Date(data.statusChangedAt))}`]
        } else return ['rejected']
      case 'accepted':
        if (data.feraAcceptExpired) {
          if (data.feraAcceptExpiresAt) {
            return ['rejected', `просрочено: ${shortDateTimeFormatter.format(new Date(data.feraAcceptExpiresAt))} авто`]
          } else return ['rejected']
        } else {
          if (data.statusChangedAt) {
            return data.autoAccepted
              ? ['accepted', `автоодобрено: ${shortDateTimeFormatter.format(new Date(data.statusChangedAt))}`]
              : ['accepted', `одобрено: ${shortDateTimeFormatter.format(new Date(data.statusChangedAt))}`]
          } else return ['accepted']
        }
    }
  } else {
    if (data.feraStatus === 'rejected') {
      return ['otherRejected']
    }
    switch (data.customerStatus) {
      case undefined:
        return ['pending']
      case 'pending':
        return ['pending']
      case 'rejected':
        if (data.statusChangedAt) {
          return ['rejected', `отказано: ${shortDateTimeFormatter.format(new Date(data.statusChangedAt))}`]
        } else return ['rejected']
      case 'accepted':
        if (data.statusChangedAt) {
          return ['accepted', `принято: ${shortDateTimeFormatter.format(new Date(data.statusChangedAt))}`]
        } else return ['accepted']
    }
  }
}

export default getReconciliationStatus
