import { FC, useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ReactComponent as TinkoffIcon } from '../../svg/icons/tinkoff.svg'
import { ReactComponent as TkbIcon } from '../../svg/icons/tkb.svg'
import { ReactComponent as SovcomIcon } from '../../svg/icons/sovcom.svg'
import { ReactComponent as ReadMoreIcon } from '../../svg/icons/cashBanknoteMore.svg'
import {
  CustomerAccountKind,
  PaymentTargetType,
  useBankOperationsByCustomerIdQuery,
  useCustomerAccountQuery,
  useDealForBankOperationsLazyQuery,
  usePaymentByIdLazyQuery,
  useTransactionsByCustomerAccountIdQuery
} from '../../graphql/schema'
import CustomerCompanyBalance from '../../components/CustomerCompanyBalance/index.tsx'
import useNodes from '../../hooks/useNodes'
import GridView, { GridViewConfig } from '../../components/GridView'
import { dateFormatter } from '../../utils/dateFormatter'
import Dropdown from '../../components/Dropdown'
import PaymentInfo from '../BankOperations/PaymentInfo.tsx'
import SuspenseModal from '../../components/SuspenseModal.tsx'
import TransactionAmount from '../../components/TransactionAmount.tsx'
import { Card } from '../../components/Card.tsx'

type Deal = {
  id: string
  bankKey?: string
  targetId?: string
  targetType?: PaymentTargetType
  date: string
  payerName: string
  paymentPurpose?: string
  amount: number
  kind?: CustomerAccountKind
}

const useDeal = (targetType: PaymentTargetType, targetId: string) => {
  const [paymentQuery] = usePaymentByIdLazyQuery()
  const [dealQuery] = useDealForBankOperationsLazyQuery()

  const [deal, setDeal] = useState<{
    _id: number
    contractNumber?: string
    contractDate?: string
  }>()

  useEffect(() => {
    if (targetType === PaymentTargetType.Deal) {
      dealQuery({ variables: { id: targetId } }).then((res) => setDeal(res.data?.deal))
    }
    if (targetType === PaymentTargetType.Payment) {
      paymentQuery({
        variables: { id: targetId }
      }).then((paymentRes) =>
        dealQuery({ variables: { id: `${paymentRes.data?.payment?.targetId}` } }).then((dealRes) =>
          setDeal(dealRes.data?.deal)
        )
      )
    }
  }, [targetId, targetType])

  return deal
}

const DealName: FC<{ targetId: string; targetType: PaymentTargetType }> = ({ targetId, targetType }) => {
  const deal = useDeal(targetType, targetId)

  return (
    <>
      {deal?.contractNumber &&
        deal?.contractDate &&
        `${'ДЛ' + deal?.contractNumber} от ${dateFormatter.format(new Date(deal?.contractDate || ''))}`}
    </>
  )
}

const DealLink: FC<{ targetId: string; targetType: PaymentTargetType }> = ({ targetId, targetType }) => {
  const deal = useDeal(targetType, targetId)

  return <Link to={`/deals/${deal?._id}`}>{deal?._id}</Link>
}

const BankOperations: FC = () => {
  const [readMoreId, setReadMoreId] = useState<string | undefined>()

  const { id } = useParams<'id'>()

  const { data: customerAccountData, loading: customerAccountLoading } = useCustomerAccountQuery({
    variables: { customerCompanyId: `${id}`, kind: CustomerAccountKind.Main },
    skip: !id,
    fetchPolicy: 'cache-and-network'
  })
  const customerAccountId = customerAccountData?.customerAccount.id
  const { data: penaltyCustomerAccountData, loading: penaltyCustomerAccountLoading } = useCustomerAccountQuery({
    variables: { customerCompanyId: `${id}`, kind: CustomerAccountKind.Penalty },
    skip: !id,
    fetchPolicy: 'cache-and-network'
  })
  const penaltyCustomerAccountId = penaltyCustomerAccountData?.customerAccount.id

  const {
    data: operationsData,
    loading: operationsLoading,
    error: operationsError
  } = useBankOperationsByCustomerIdQuery({
    variables: { customerAccountId: parseInt(`${customerAccountId}`) },
    skip: !customerAccountId,
    fetchPolicy: 'cache-and-network'
  })
  const operations = useNodes(operationsData?.bankOperations.edges)
  const {
    data: penaltyOperationsData,
    loading: penaltyOperationsLoading,
    error: penaltyOperationsError
  } = useBankOperationsByCustomerIdQuery({
    variables: { customerAccountId: parseInt(`${penaltyCustomerAccountId}`) },
    skip: !penaltyCustomerAccountId,
    fetchPolicy: 'cache-and-network'
  })
  const penaltyOperations = useNodes(penaltyOperationsData?.bankOperations.edges)

  const {
    data: transactionsData,
    loading: transactionsLoading,
    error: transactionsError
  } = useTransactionsByCustomerAccountIdQuery({
    variables: { customerAccountId: `${customerAccountId}` },
    skip: !customerAccountId,
    fetchPolicy: 'cache-and-network'
  })
  const transactions = useNodes(transactionsData?.transactions.edges).filter(
    (t) => t.payment.targetType === PaymentTargetType.Deal
  )
  const {
    data: penaltyTransactionsData,
    loading: penaltyTransactionsLoading,
    error: penaltyTransactionsError
  } = useTransactionsByCustomerAccountIdQuery({
    variables: { customerAccountId: `${penaltyCustomerAccountId}` },
    skip: !penaltyCustomerAccountId,
    fetchPolicy: 'cache-and-network'
  })
  const penaltyTransactions = useNodes(penaltyTransactionsData?.transactions.edges)

  const loading =
    customerAccountLoading ||
    operationsLoading ||
    transactionsLoading ||
    penaltyCustomerAccountLoading ||
    penaltyOperationsLoading ||
    penaltyTransactionsLoading

  const operationsTableData = useMemo(
    () =>
      loading
        ? []
        : [
            ...operations.map((o) => ({
              id: o.id,
              bankKey: o.bankKey,
              date: o.date,
              payerName: o.payerName,
              paymentPurpose: o.paymentPurpose,
              amount: o.amount
            })),
            ...penaltyOperations.map((o) => ({
              id: o.id,
              bankKey: o.bankKey,
              date: o.date,
              payerName: o.payerName,
              paymentPurpose: o.paymentPurpose,
              amount: o.amount,
              kind: CustomerAccountKind.Penalty
            })),
            ...transactions.map((t) => ({
              id: `transaction_${t.id}`,
              targetId: t.payment.targetId,
              targetType: t.payment.targetType,
              date: t.createdAt,
              payerName: t.customerAccount.customerCompany.shortWithOpf,
              paymentPurpose: 'Договор лизинга',
              amount: t.amount
            })),
            ...penaltyTransactions.map((t) => ({
              id: `penalty_transaction_${t.id}`,
              targetId: t.payment.targetId,
              targetType: t.payment.targetType,
              date: t.createdAt,
              payerName: t.customerAccount.customerCompany.shortWithOpf,
              paymentPurpose: 'Договор лизинга',
              amount: t.amount,
              kind: CustomerAccountKind.Penalty
            }))
          ].sort((a, b) => (new Date(a.date) < new Date(b.date) ? 1 : -1)),
    [operations, transactions, penaltyOperations, penaltyTransactions, loading]
  )
  const operationsTableConfig = useMemo<GridViewConfig<Deal>>(
    () => ({
      grid: 'grid-cols-auto-7',
      columns: [
        {
          title: 'Банк',
          value: (o) => {
            if (o.bankKey == 'tkb') {
              return <TkbIcon />
            } else if (o.bankKey == 'tinkoff') {
              return <TinkoffIcon />
            } else if (o.bankKey == 'sovcombank') {
              return <SovcomIcon />
            }
          }
        },
        {
          title: 'ID сделки',
          numeric: true,
          value: (o) =>
            o.targetId && o.targetType ? <DealLink targetId={o.targetId} targetType={o.targetType} /> : '-'
        },
        {
          title: 'Дата',
          numeric: true,
          value: (o) => dateFormatter.format(new Date(o.date))
        },
        {
          title: 'Плательщик',
          value: (o) => {
            return <div className='max-w-sm'>{o.payerName}</div>
          }
        },
        {
          title: 'Информация',
          value: (o) =>
            o.targetId && o.targetType ? (
              <div>
                {o.kind === CustomerAccountKind.Penalty && 'Оплата пени '}
                <DealName targetId={o.targetId} targetType={o.targetType} />
              </div>
            ) : (
              o.paymentPurpose
            )
        },
        {
          title: 'Сумма',
          numeric: true,
          value: (o) => (
            <div className='ml-auto'>
              <TransactionAmount
                className={!o.targetId ? 'text-base-green' : 'text-base-red'}
                amount={o.amount}
                isIncome={!o.targetId}
                currency='₽'
              />
            </div>
          )
        },
        {
          title: '',
          value: (o) =>
            !o?.targetId ? (
              <Dropdown className='ml-auto pl-4 pr-4'>
                <button
                  className='flex items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
                  onClick={() => setReadMoreId(o.id)}
                >
                  <ReadMoreIcon className='mr-7' height='18px' width='18px' title='Подробнее' />
                  <span className='whitespace-nowrap text-grayscale-0'>Подробнее</span>
                </button>
              </Dropdown>
            ) : (
              <></>
            )
        }
      ]
    }),
    [setReadMoreId]
  )

  return (
    <div className='flex flex-col gap-6'>
      <div className='grid grid-cols-3 gap-6'>
        <CustomerCompanyBalance title='Основной баланс' customerId={id} kind={CustomerAccountKind.Main} />
      </div>
      <Card>
        <div className='p-5'>
          <GridView
            config={operationsTableConfig}
            data={operationsTableData}
            loading={loading}
            error={
              operationsError?.message ||
              transactionsError?.message ||
              penaltyOperationsError?.message ||
              penaltyTransactionsError?.message
            }
          />
        </div>
      </Card>

      <SuspenseModal open={!!readMoreId} setOpen={() => setReadMoreId(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <PaymentInfo id={readMoreId!} onDone={() => setReadMoreId(undefined)} />
        </div>
      </SuspenseModal>
    </div>
  )
}

export default BankOperations
