import { useCallback, useState } from 'react'
import copyToClipboard from 'copy-to-clipboard'
import { env } from '../env'

const SITE_URL = env.REACT_APP_SITE_URL
if (!SITE_URL) throw new Error('REACT_APP_SITE_URL is not defined')

const useCopyCustomerLink = (customerFormPublicId?: string) => {
  const [copyLinkDone, setCopyLinkDone] = useState(false)

  const copyLink = useCallback(async () => {
    if (customerFormPublicId) {
      const url = `${SITE_URL}/client-forms/${customerFormPublicId}`
      copyToClipboard(url)
      setCopyLinkDone(true)
      await new Promise((res) => setTimeout(res, 1000))
      setTimeout(() => setCopyLinkDone(false), 100)
    }
  }, [customerFormPublicId])

  return [copyLink, copyLinkDone] as const
}

export default useCopyCustomerLink
