import { FC } from 'react'

interface PlaceholderProps {
  text: string
}

const Placeholder: FC<PlaceholderProps> = ({ text }) => {
  return <span className='inline-flex gap-x-5'>{text.split(' ').map((w, i) => <span key={i} className='inline-block bg-grayscale-400 px-5 rounded-sm text-transparent text-[75%]'>{w}</span>)}</span>
}

export default Placeholder
