import { FC, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import {
  DocumentTypeEntity,
  useSupplierApplicationDocumentsQuery,
  useSupplierDealSupplyDocumentsQuery
} from '../../graphql/schema'
import DocumentManager from '../../components/DocumentManager'
import {
  KP_FOLDER_NAME,
  SHIPPING_FOLDER_NAME,
  SPECIFICATIONS_FOLDER_NAME,
  SUPPLY_FOLDER_NAME,
  TRANSFER_FOLDER_NAME
} from '../../config'
import Folder from '../../components/DocumentManager/Folder'
import useNodes from '../../hooks/useNodes'
import GroupZip from '../../components/DocumentManager/GroupZip'
import { entityTypeDict } from '../../utils/dictionaries'

const Documents: FC = () => {
  const { id } = useParams<'id'>()

  const { data: suppliesSpecificationsData, loading: specificationsLoading } = useSupplierDealSupplyDocumentsQuery({
    variables: {
      supplierCompany: id as string,
      type: SPECIFICATIONS_FOLDER_NAME
    },
    nextFetchPolicy: 'cache-and-network',
    skip: !id
  })
  const suppliesSpecificationDocs = useNodes(suppliesSpecificationsData?.supplierCompanyDealSupplyDocuments?.edges)

  const { data: suppliesSupplyData, loading: supplyLoading } = useSupplierDealSupplyDocumentsQuery({
    variables: {
      supplierCompany: id as string,
      type: SUPPLY_FOLDER_NAME
    },
    nextFetchPolicy: 'cache-and-network',
    skip: !id
  })
  const suppliesSupplyDocs = useNodes(suppliesSupplyData?.supplierCompanyDealSupplyDocuments?.edges)

  const { data: suppliesShippingData, loading: suppliesShippingLoading } = useSupplierDealSupplyDocumentsQuery({
    variables: {
      supplierCompany: id as string,
      type: SHIPPING_FOLDER_NAME
    },
    nextFetchPolicy: 'cache-and-network',
    skip: !id
  })
  const suppliesShippingDocs = useNodes(suppliesShippingData?.supplierCompanyDealSupplyDocuments?.edges)

  const { data: suppliesTransferData, loading: suppliesTransferLoading } = useSupplierDealSupplyDocumentsQuery({
    variables: {
      supplierCompany: id as string,
      type: TRANSFER_FOLDER_NAME
    },
    nextFetchPolicy: 'cache-and-network',
    skip: !id
  })
  const suppliesTransferDocs = useNodes(suppliesTransferData?.supplierCompanyDealSupplyDocuments?.edges)

  const shippingAndTransferDocs = [...suppliesShippingDocs, ...suppliesTransferDocs]

  const { data: supplierKpDocumentsData, loading: supplierKpDocumentsDataLoading } =
    useSupplierApplicationDocumentsQuery({
      variables: { id: `${id}`, type: KP_FOLDER_NAME },
      skip: !id
    })

  const supplierKpDocs = useNodes(supplierKpDocumentsData?.supplierApplicationDocuments?.edges)

  return (
    <Fragment>
      {id && (
        <DocumentManager entityId={parseInt(id)} entityType={DocumentTypeEntity.SupplierCompany}>
          <div className='relative mb-5 rounded-xl bg-white-0 p-10 shadow-xs'>
            <div className='flex items-center justify-between gap-x-5 pb-12'>
              <h3 className='font-display text-h200'>Спецификации</h3>
              <GroupZip
                name={`${entityTypeDict[DocumentTypeEntity.SupplierCompany]} ${id} Спецификации`}
                ids={suppliesSpecificationDocs.map((doc) => doc._id)}
              />
            </div>
            <Folder files={suppliesSpecificationDocs} loading={specificationsLoading} canDelete={false} />
          </div>
          <div className='relative mb-5 rounded-xl bg-white-0 p-10 shadow-xs'>
            <div className='flex items-center justify-between gap-x-5 pb-12'>
              <h3 className='font-display text-h200'>Нерамочные договоры поставки</h3>
              <GroupZip
                name={`${entityTypeDict[DocumentTypeEntity.SupplierCompany]} ${id} Нерамочные договоры поставки`}
                ids={suppliesSupplyDocs.map((doc) => doc._id)}
              />
            </div>
            <Folder files={suppliesSupplyDocs} loading={supplyLoading} canDelete={false} />
          </div>
          <div className='relative mb-5 rounded-xl bg-white-0 p-10 shadow-xs'>
            <div className='flex items-center justify-between gap-x-5 pb-12'>
              <h3 className='font-display text-h200'>Документы отгрузки</h3>
              <GroupZip
                name={`${entityTypeDict[DocumentTypeEntity.SupplierCompany]} ${id} Документы отгрузки`}
                ids={shippingAndTransferDocs.map((doc) => doc._id)}
              />
            </div>
            <Folder
              files={shippingAndTransferDocs}
              loading={suppliesShippingLoading || suppliesTransferLoading}
              canDelete={false}
            />
          </div>
          <div className='relative mb-5 rounded-xl bg-white-0 p-10 shadow-xs'>
            <div className='mb-6 font-display text-h200'>КП или счёт</div>
            <Folder files={supplierKpDocs} loading={supplierKpDocumentsDataLoading} canDelete={false} />
          </div>
        </DocumentManager>
      )}
    </Fragment>
  )
}

export default Documents
