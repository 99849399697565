import { FC, Fragment } from 'react'
import { useOutletContext } from 'react-router-dom'
import { DocumentTypeEntity, SourceByIdQuery, useSourceActsQuery } from '../../graphql/schema'
import DocumentManager from '../../components/DocumentManager'
import { ApolloQueryResult } from '@apollo/client'
import Folder from '../../components/DocumentManager/Folder'
import { COMMISSION_REWARD_FOLDER_ACT_NAME, COMMISSION_REWARD_FOLDER_INVOICE_NAME } from '../../config'
import useNodes from '../../hooks/useNodes'
import GroupZip from '../../components/DocumentManager/GroupZip'
import { entityTypeDict } from '../../utils/dictionaries'

const Documents: FC = () => {
  const [data] = useOutletContext<[SourceByIdQuery, () => Promise<ApolloQueryResult<SourceByIdQuery>>]>()
  const source = data?.source

  const { data: actsData, loading: actsLoading } = useSourceActsQuery({
    variables: {
      source: source?._id?.toString() as string,
      type: COMMISSION_REWARD_FOLDER_ACT_NAME
    },
    nextFetchPolicy: 'cache-and-network',
    skip: !source
  })
  const { data: invoiceData, loading: invoiceLoading } = useSourceActsQuery({
    variables: {
      source: source?._id?.toString() as string,
      type: COMMISSION_REWARD_FOLDER_INVOICE_NAME
    },
    nextFetchPolicy: 'cache-and-network',
    skip: !source
  })

  const actDocs = useNodes(actsData?.sourceAgentRewardDocuments?.edges)
  const invoiceDocs = useNodes(invoiceData?.sourceAgentRewardDocuments?.edges)

  if (!source) return null

  return (
    <Fragment>
      <DocumentManager entityId={source._id} entityType={DocumentTypeEntity.Source}>
        <div className='p-10 rounded-xl bg-white-0 mb-5 shadow-xs relative'>
          <div className='pb-12 gap-x-5 flex justify-between items-center'>
            <h3 className='text-h200 font-display'>Акты</h3>
            <GroupZip
              name={`${entityTypeDict[DocumentTypeEntity.Source]} ${source._id} Акты`}
              ids={actDocs.map((doc) => doc._id)}
            />
          </div>
          <Folder files={actDocs} loading={actsLoading} canDelete={false} />
        </div>
        <div className='p-10 rounded-xl bg-white-0 mb-5 shadow-xs relative'>
          <div className='pb-12 gap-x-5 flex justify-between items-center'>
            <h3 className='text-h200 font-display'>Счета-фактуры</h3>
            <GroupZip
              name={`${entityTypeDict[DocumentTypeEntity.Source]} ${source._id} Счета-фактуры`}
              ids={invoiceDocs.map((doc) => doc._id)}
            />
          </div>
          <Folder files={invoiceDocs} loading={invoiceLoading} canDelete={false} />
        </div>
      </DocumentManager>
    </Fragment>
  )
}

export default Documents
