import { FC, useMemo, useRef, useState } from 'react'
import { ReactComponent as PlusIcon } from '../../svg/ui/plus.svg'
import { ReactComponent as LoadingIcon } from '../../svg/icons/loading.svg'
import {
  TaskConnection,
  TaskKind,
  TasksDocument,
  TasksQuery,
  TasksQueryVariables,
  TaskState,
  TaskTargetType,
  useDeleteTaskMutation,
  useDoneTaskMutation,
  usePendingTaskMutation,
  useTasksQuery
} from '../../graphql/schema'
import Modal from '../Modal'
import TaskForm from './TaskForm'
import TasksByDate from './TasksByDate'
import ConfirmationForm from '../ConfirmationForm'
import useScrollPagination from '../../hooks/useScrollPagination'
import useNodes from '../../hooks/useNodes'
import { TASK_QUOTES } from '../../utils/constants'
import c from 'clsx'
import cache from '../../graphql/cache'
import useTasksUpdates from './hooks/useTasksUpdates'
import UserFilter from './UserFilter.tsx'
import { Card, CardHeader, CardMenu, CardTitle } from '../Card.tsx'
import useCurrentUserId from '../../hooks/useCurrentUserId.ts'

const dateFormatter = new Intl.DateTimeFormat('ru-RU', {
  month: 'long',
  day: 'numeric'
})
const dateFormatterWithYear = new Intl.DateTimeFormat('ru-RU', {
  month: 'long',
  day: 'numeric',
  year: 'numeric'
})

interface TasksProps {
  targetId?: number
  targetType?: TaskTargetType
  managerId?: number
  main?: boolean
  childTargetId?: number
  childTargetType?: TaskTargetType
}

export interface Task {
  id: string
  body: string
  state: TaskState
  doneAt?: string
  doneByUserId?: number
  targetId?: number
  targetType?: TaskTargetType
  kind: TaskKind
  dueDate?: string | Date
  userId?: number
  isDueDateTimeSpecified: boolean
  isNotificationActivated: boolean
  authorId?: number
  originalTaskId?: string
  childTargetId?: number
  childTargetType?: TaskTargetType
}

function readTasksFromCache(variables: TasksQueryVariables): TasksQuery | null {
  return cache.readQuery<TasksQuery, TasksQueryVariables>({
    query: TasksDocument,
    variables
  })
}

function writeTasksCache(tasks: TaskConnection, variables: TasksQueryVariables): void {
  cache.writeQuery<TasksQuery, TasksQueryVariables>({
    query: TasksDocument,
    variables,
    data: {
      __typename: 'Query',
      tasks
    }
  })
}

const Tasks: FC<TasksProps> = ({ targetId, targetType, childTargetId, childTargetType, managerId, main }) => {
  const currentUserId = useCurrentUserId()

  const [taskFormOpen, setTaskFormOpen] = useState(false)
  const [entity, setEntity] = useState<Task>()
  const [deleteFormOpen, setDeleteFormOpen] = useState(false)
  const [deleteTaskId, setDeleteTaskId] = useState<string>()
  const [pendingFormOpen, setPendingFormOpen] = useState(false)
  const [pendingTaskId, setPendingTaskId] = useState<string>()
  const [isActiveDone, setIsActiveDone] = useState(false)
  const [isActiveAuthor, setIsActiveAuthor] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState<number | undefined>(currentUserId)
  const [quoteId] = useState(Math.floor(Math.random() * TASK_QUOTES.length))

  const currentDate = new Date()
  currentDate.setSeconds(0, 0)

  const skipping = !main && !targetId && !targetType

  const { data, fetchMore, subscribeToMore, loading, variables } = useTasksQuery({
    variables: {
      input: {
        // применяем фильт по user id только в main и если не активен фильтр по автору
        userId: main && !isActiveAuthor ? selectedUserId : undefined,
        authorId: isActiveAuthor ? selectedUserId : undefined,
        targetId,
        targetType,
        childTargetId,
        childTargetType,
        states: isActiveDone ? [TaskState.Done] : [TaskState.Pending]
      }
    },
    skip: skipping,
    fetchPolicy: 'cache-and-network'
  })
  const tasks = useNodes(data?.tasks?.edges)

  const [tasksGroupedByDate, hasOverdueTask, hasTodayTask] = useMemo(() => {
    let hasOverdueTask = false
    let hasTodayTask = false

    const tasksGrouped = tasks?.reduce(
      (acc, task) => {
        const lastTaskGroup = acc[acc.length - 1]

        if (lastTaskGroup && lastTaskGroup.date.setHours(0, 0, 0, 0) === new Date(task?.dueDate).setHours(0, 0, 0, 0)) {
          lastTaskGroup.data?.push(task)
        } else {
          acc.push({
            date: new Date(task?.dueDate),
            data: [task]
          })
        }

        if (!hasOverdueTask && new Date(task?.dueDate).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) {
          hasOverdueTask = true
        }
        if (!hasTodayTask && new Date(task?.dueDate).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
          hasTodayTask = true
        }

        return acc
      },
      [] as { date: Date; data?: typeof tasks }[]
    )

    if (main && !hasOverdueTask && !hasTodayTask) {
      tasksGrouped?.unshift({
        date: new Date()
      })
    }

    return [tasksGrouped, hasOverdueTask, hasTodayTask] as const
  }, [tasks, main])

  useTasksUpdates(subscribeToMore, main ? selectedUserId : undefined, targetId, targetType)

  const { wrapperRef: triggerRef, isFetching } = useScrollPagination(fetchMore, data?.tasks?.pageInfo)

  const [deleteTask] = useDeleteTaskMutation()
  const [doneTask] = useDoneTaskMutation()
  const [pendingTask] = usePendingTaskMutation()

  function openDeleteModal(id: string): void {
    setDeleteTaskId(id)
    setDeleteFormOpen(true)
  }

  function openPendingModal(id: string): void {
    setPendingTaskId(id)
    setPendingFormOpen(true)
  }

  function remove() {
    if (!deleteTaskId) {
      return
    }

    return deleteTask({
      variables: {
        id: parseInt(deleteTaskId)
      }
    }).then(() => {
      if (!variables) return
      writeTasksCache(
        {
          ...readTasksFromCache(variables)?.tasks,
          edges: (readTasksFromCache(variables)?.tasks?.edges || []).filter((edge) => edge?.node?.id !== deleteTaskId)
        } as TaskConnection,
        variables
      )
    })
  }

  async function done(task: Task) {
    if (!task.id) return

    await doneTask({
      variables: {
        id: parseInt(task.id)
      },
      optimisticResponse: {
        __typename: 'Mutation',
        done: {
          __typename: 'Task',
          id: task.id,
          state: TaskState.Done
        }
      }
    })
  }

  function pending() {
    if (!pendingTaskId) return

    return pendingTask({
      variables: {
        id: parseInt(pendingTaskId)
      },
      optimisticResponse: {
        __typename: 'Mutation',
        pending: {
          __typename: 'Task',
          id: pendingTaskId,
          state: TaskState.Pending
        }
      }
    })
  }

  const todayTasksRef = useRef<HTMLDivElement>(null)
  const goTodayTasks = () => {
    if (todayTasksRef.current) {
      todayTasksRef.current.focus()
      todayTasksRef.current.scrollIntoView()
      setTimeout(() => todayTasksRef?.current?.blur(), 1000)
    }
  }

  return (
    <div className='flex' ref={triggerRef}>
      <Card className='w-full'>
        <CardHeader>
          <CardTitle>
            Задачи
            {main && hasOverdueTask && hasTodayTask && (
              // TODO: может возникать ситуация, когда старых задач больше 30,
              //  тогда эта кнопка не отрендерится и придется рекрсивно подгружать следующие 30 задач и так далее,
              //  пока не догрузим задачи с датой на сегодня или позднее.
              //  Решили пока с этим ничего не делать, так как ситуация маловероятна
              <span className='ml-10 cursor-pointer font-normal text-base-red' onClick={goTodayTasks}>
                На сегодня
              </span>
            )}
          </CardTitle>
          <CardMenu>
            <button
              className={c(
                'cursor-pointer font-medium hover:opacity-80',
                isActiveDone ? 'text-labels-secondary' : 'text-labels-tertiary'
              )}
              onClick={() => {
                setIsActiveDone((value) => !value)
              }}
            >
              Выполненные
            </button>
            <button
              className={c(
                'ml-6 cursor-pointer font-medium hover:opacity-80',
                isActiveAuthor ? 'text-labels-secondary' : 'text-labels-tertiary'
              )}
              onClick={() => {
                setIsActiveAuthor((value) => !value)
              }}
            >
              Назначенные {selectedUserId === currentUserId ? 'мной' : 'пользователем'}
            </button>
            <span className='mx-6 h-10 w-1 border-r-1 border-separators-secondary' />
            {main && <UserFilter className='mr-6' userId={selectedUserId} onChange={(id) => setSelectedUserId(id)} />}
            <button
              className='mr-4 cursor-pointer text-labels-tertiary hover:opacity-80'
              onClick={() => {
                setEntity(undefined)
                setTaskFormOpen(true)
              }}
            >
              <PlusIcon title='Новая задача' />
            </button>
          </CardMenu>
        </CardHeader>

        {!!tasksGroupedByDate?.length && (
          <div className='px-5 pb-5'>
            <div className='grid gap-y-5'>
              {tasksGroupedByDate.map((tasksByDate) => (
                <div
                  key={tasksByDate.date.getTime()}
                  tabIndex={tasksByDate.date.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0) ? 0 : undefined}
                  ref={tasksByDate.date.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0) ? todayTasksRef : null}
                  className='rounded-lg bg-surface-primary shadow-card outline-none focus:ring-1 focus:ring-base-red'
                  onClick={(event) => event.currentTarget.blur()}
                >
                  <div
                    className={c(
                      'border-b-1 border-grayscale-400 px-10 py-4 text-p200 font-medium text-labels-primary',
                      tasksByDate.date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0) && '!text-red-100'
                    )}
                  >
                    {(() => {
                      const today = new Date()

                      const tomorrow = new Date()
                      tomorrow.setDate(tomorrow.getDate() + 1)

                      if (tasksByDate.date.getFullYear() !== today.getFullYear()) {
                        return dateFormatterWithYear.format(tasksByDate.date)
                      } else if (tasksByDate.date.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)) {
                        return `Сегодня, ${dateFormatter.format(tasksByDate.date)}`
                      } else if (tasksByDate.date.setHours(0, 0, 0, 0) === tomorrow.setHours(0, 0, 0, 0)) {
                        return `Завтра, ${dateFormatter.format(tasksByDate.date)}`
                      } else {
                        return dateFormatter.format(tasksByDate.date)
                      }
                    })()}
                  </div>

                  {loading || skipping ? (
                    <div className='p-10 text-center'>
                      <LoadingIcon className='mx-auto animate-spin self-center text-labels-tertiary' />
                    </div>
                  ) : tasksByDate.data?.length ? (
                    <TasksByDate
                      tasks={tasksByDate.data}
                      onDelete={(id) => openDeleteModal(id)}
                      onPending={(id) => openPendingModal(id)}
                      onDone={(task) => done(task)}
                      onEdit={(task) => {
                        setEntity(task)
                        setTaskFormOpen(true)
                      }}
                      main={main}
                    />
                  ) : (
                    <div className='p-10 text-center'>{TASK_QUOTES[quoteId]}</div>
                  )}
                </div>
              ))}
              <div className='flex items-center justify-center py-10 empty:hidden'>
                {isFetching && <LoadingIcon className='animate-spin self-center text-labels-tertiary' />}
              </div>
            </div>
          </div>
        )}

        <Modal open={taskFormOpen} setOpen={setTaskFormOpen}>
          <div className='z-10 rounded-xl bg-white-0'>
            <TaskForm
              targetId={targetId}
              targetType={targetType}
              childTargetId={childTargetId}
              childTargetType={childTargetType}
              onCreateTask={(task) => {
                setTaskFormOpen(false)
                setIsActiveAuthor(false)
                setIsActiveDone(false)

                if (!variables) return

                const existTaskEdge = (readTasksFromCache(variables)?.tasks?.edges || []).find(
                  (edge) => edge?.node?.id === task?.id
                )
                if (existTaskEdge || (main && currentUserId !== task?.userId)) return

                writeTasksCache(
                  {
                    ...readTasksFromCache(variables)?.tasks,
                    edges: [...(readTasksFromCache(variables)?.tasks?.edges || []), { cursor: '', node: task }].sort(
                      (a, b) => (new Date(a?.node?.dueDate) > new Date(b?.node?.dueDate) ? 1 : -1)
                    )
                  } as TaskConnection,
                  variables
                )
              }}
              onMoveTask={(task) => {
                setTaskFormOpen(false)

                if (!variables) return

                writeTasksCache(
                  {
                    ...readTasksFromCache(variables)?.tasks,
                    edges: (readTasksFromCache(variables)?.tasks?.edges || [])
                      .map((edge) =>
                        edge?.node?.id === task?.originalTaskId ? { ...edge, node: { ...edge?.node, ...task } } : edge
                      )
                      .sort((a, b) => (new Date(a?.node?.dueDate) > new Date(b?.node?.dueDate) ? 1 : -1))
                  } as TaskConnection,
                  variables
                )
              }}
              onUpdateTask={() => {
                setTaskFormOpen(false)
              }}
              entity={entity}
              managerId={managerId}
            />
          </div>
        </Modal>

        <Modal open={deleteFormOpen} setOpen={setDeleteFormOpen}>
          <div className='z-10 rounded-xl bg-white-0'>
            <ConfirmationForm
              title='Удаление задачи'
              onDone={() => {
                setDeleteFormOpen(false)
                remove()
              }}
              onDismiss={() => setDeleteFormOpen(false)}
            >
              Вы действительно хотите удалить задачу?
            </ConfirmationForm>
          </div>
        </Modal>

        <Modal open={pendingFormOpen} setOpen={setPendingFormOpen}>
          <div className='z-10 rounded-xl bg-white-0'>
            <ConfirmationForm
              onDone={() => {
                setPendingFormOpen(false)
                pending()
              }}
              onDismiss={() => setPendingFormOpen(false)}
            >
              Вы действительно хотите вернуть задачу в работу?
            </ConfirmationForm>
          </div>
        </Modal>
      </Card>
    </div>
  )
}

export default Tasks
