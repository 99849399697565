import { CellObject, WorkSheet, utils, writeFileXLSX } from 'xlsx'

const cellNumberFormat = '#,##0.00_);\\(#,##0.00\\)' // Currency format with two decimal places

const emptyCell: CellObject = { t: 'z' }
const textCell = (text?: string): CellObject => ({ t: 's', v: text })
const moneyCell = (value: number): CellObject => ({ t: 'n', v: value, z: cellNumberFormat })
const dateCell = (value?: string): CellObject => ({ t: 'd', v: value, z: 'dd.MM.yyyy' })
const linkCell = (text: string, link: string, tooltip?: string): CellObject => ({
  t: 's',
  v: text,
  l: { Target: link, Tooltip: tooltip }
})

const fillCell = (ws: WorkSheet, row: number, col: number, value: CellObject) => {
  const address = utils.encode_cell({ r: row, c: col })
  ws[address] = value
}

interface XLSXConfig<T> {
  data: T[]
  columns: {
    title: string
    width?: number
    render: (d: T, i: number) => CellObject
  }[]
  headerRows?: string[][]
}

const renderSheet = <T extends Record<string, unknown>>(config: XLSXConfig<T>) => {
  const data = [config.columns.map((c) => c.title)]
  if (config?.headerRows) {
    data.unshift(...config.headerRows)
  }
  const ws = utils.aoa_to_sheet(data)
  const headerRowsLength = config?.headerRows?.length || 0

  config.data.forEach((d, i) => {
    config.columns.forEach((c, j) => {
      const cell = c.render(d, i)
      fillCell(ws, i + 1 + headerRowsLength, j, cell.v === undefined ? emptyCell : cell)
    })
  })

  // set column width
  config.columns.forEach((c, i) => {
    if (c.width) {
      ws['!cols'] = ws['!cols'] || []
      ws['!cols'][i] = { wch: c.width }
    }
  })

  // update sheet range
  ws['!ref'] = utils.encode_range({
    s: { c: 0, r: 0 },
    e: { c: config.columns.length - 1, r: config.data.length + headerRowsLength }
  })

  return ws
}

const saveSheet = (ws: WorkSheet, filename: string, sheetName?: string) => {
  const wb = utils.book_new()
  utils.book_append_sheet(wb, ws, sheetName || 'Sheet1')
  writeFileXLSX(wb, filename)
}

export { renderSheet, saveSheet, textCell, moneyCell, dateCell, linkCell, emptyCell }
// renderSheet({
//   data: [
//     { id: 1, name: 'John', age: 30 },
//     { id: 2, name: 'Jane', age: 25 }
//   ],
//   columns: [
//     { title: 'ID', render: (d) => textCell(d.id.toString()) },
//     { title: 'Name', render: (d) => textCell(d.name) },
//     { title: 'Age', render: (d) => textCell(d.age.toString()) }
//   ]
// })
