import { Menu, MenuButton, MenuItems, Transition } from '@headlessui/react'
import { FC, Suspense } from 'react'
import { supplierStatusDict } from '../../utils/dictionaries'
import {
  EngagePartnerStatus,
  useSupplierStatusSelectQuery,
  useSupplierStatusSelectSuspenseQuery
} from '../../graphql/schema'
import { ReactComponent as LoadingIcon } from '../../svg/icons/loading.svg'
import { ReactComponent as ChevronDownSmall } from '../../svg/ui/chevron-down-small.svg'
import useStatusChange from './useChangeStatus'
import { SupplierStatusCircle, engageColorDict, engageTextColorDict } from './SupplierStatus.tsx'
import { dateFormatter } from '../../utils/dateFormatter.ts'
import c from 'clsx'

const listStatuses: EngagePartnerStatus[] = Object.keys(supplierStatusDict) as EngagePartnerStatus[]

interface SupplierStatusSwitcherProps {
  id: number
}

interface SupplierStatusSwitcherInnerProps {
  id: number
  changeStatus: (status: EngagePartnerStatus) => Promise<void>
}

const SupplierStatusSwitcherInner: FC<SupplierStatusSwitcherInnerProps> = ({ id, changeStatus }) => {
  const { data: supplierData } = useSupplierStatusSelectSuspenseQuery({ variables: { id: `${id}` }, skip: !id })
  const supplier = supplierData?.supplierCompany
  const supplierStatus = supplier?.engagePartnerStatus

  return (
    <>
      {listStatuses?.map((status) => (
        <Menu.Item key={status}>
          <div
            onClick={() => changeStatus(status)}
            className={c(
              'flex h-16 cursor-pointer items-center gap-5 whitespace-nowrap px-5',
              status === supplierStatus && 'pointer-events-none text-red-100'
            )}
          >
            <SupplierStatusCircle status={status} bg={false} />
            {supplierStatusDict[status]}
          </div>
        </Menu.Item>
      ))}
    </>
  )
}

// wrap SupplierStatusSwitcherInner with suspense
const SupplierStatusSwitcher: FC<SupplierStatusSwitcherProps> = ({ id }) => {
  const [changeStatus, loading] = useStatusChange(id)

  const { data: supplierData, refetch } = useSupplierStatusSelectQuery({ variables: { id: `${id}` }, skip: !id })
  const supplier = supplierData?.supplierCompany
  const status = supplier?.engagePartnerStatus

  return (
    <div className='relative flex gap-x-4'>
      <Menu>
        <MenuButton
          className={c(
            'flex min-h-16 items-center gap-x-3 rounded-md px-5 py-3 font-medium',
            status ? engageTextColorDict[status] : 'bg-surface-secondary',
            status ? engageColorDict[status] : 'text-labels-secondary'
          )}
        >
          {status && <SupplierStatusCircle status={status} />}
          {status && supplierStatusDict[status]}

          {loading ? (
            <LoadingIcon className='h-8 w-8 animate-spin' />
          ) : (
            <ChevronDownSmall className='transition-transform duration-200 ui-open:-rotate-180' />
          )}
        </MenuButton>

        <Suspense
          fallback={
            <MenuItems
              unmount={true}
              className='absolute left-0 z-50 mt-4 flex w-fit min-w-[244px] flex-col gap-y-1 overflow-y-auto whitespace-nowrap rounded-lg bg-surface-primary p-2 shadow-dropdown transition-transform'
            >
              <div className='py-10'>
                <LoadingIcon className='animate-spin' />
              </div>
            </MenuItems>
          }
        >
          <Transition
            as='div'
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <MenuItems
              unmount={true}
              className='absolute left-0 top-full z-50 mt-4 flex w-fit flex-col gap-y-1 overflow-y-auto rounded-lg bg-surface-primary p-2 shadow-dropdown transition-transform'
            >
              <SupplierStatusSwitcherInner
                id={id}
                changeStatus={async (status) => {
                  await changeStatus(status)
                  await refetch()
                }}
              />
            </MenuItems>
          </Transition>
        </Suspense>
      </Menu>

      {status === EngagePartnerStatus.Hold && supplier?.engagePartnerHoldStatusSetAt && (
        <div className='whitespace-nowrap rounded-md bg-tr-red px-5 py-3 font-medium text-dark-red'>
          c {dateFormatter.format(new Date(supplier?.engagePartnerHoldStatusSetAt))}
        </div>
      )}
    </div>
  )
}

export default SupplierStatusSwitcher
