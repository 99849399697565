import { FC } from 'react'
import c from 'clsx'
import { ReactComponent as FileIcon } from '../svg/icons/fileDownload.svg'
import { ReactComponent as LoadingIcon } from '../svg/icons/loading.svg'
import useFileDownload from '../hooks/useFileDownload'
interface FileDownloadProps {
  url: string,
  fileName: string;
  type?: 'GET' | 'POST';
  body?: any;
  className?: string
}

const FileDownload: FC<FileDownloadProps> = ({ url, fileName, type, body, className }) => {
  const [loading, download] = useFileDownload(url, fileName, type, body)

  return (
    <div className={c('flex justify-end cursor-pointer', className)}>
      {loading
        ? <LoadingIcon className='animate-spin' />
        : <FileIcon onClick={download} />
      }
    </div>
  )
}

export default FileDownload
