import { FC, useEffect, useMemo } from 'react'
import { SourceCategory, SourcesListQueryVariables, TagEntity, UserRoles } from '../../graphql/schema'
import { sourceCategoryDict } from '../../utils/dictionaries'
import FilterForm from '../../components/Filter/Form'
import Select from '../../components/SelectInput'
import { makeVar, useReactiveVar } from '@apollo/client'
import { throttle } from 'throttle-debounce'
import ManagerInput from '../../components/Forms/Inputs/ManagerInput'
import TagsInput from '../../components/Tags/TagsInput.tsx'
import { Tag } from '../../components/Tags/Tag.tsx'

const categories = Object.values(SourceCategory).map((cat) => ({
  _id: cat,
  name: sourceCategoryDict[cat]
}))

export interface SourceFilterState {
  categories: typeof categories
  users: number[]
  bdmUsers: number[]
  query?: string
  id?: number
  tagsList: Tag[]
}

export interface FilterFormProps {
  state: SourceFilterState
  onChange: (state: SourceFilterState) => void
}

const sourceFilterStateVar = makeVar<SourceFilterState>({
  categories: [],
  users: [],
  bdmUsers: [],
  tagsList: []
})
const sourceQueryVar = makeVar<string>('')

const Sources: FC<FilterFormProps> = ({ state, onChange }) => {
  return (
    <FilterForm
      onReset={() =>
        onChange({
          categories: [],
          users: [],
          bdmUsers: [],
          tagsList: []
        })
      }
      title='Фильтр источников'
    >
      <div className='group relative mb-10'>
        <Select
          label='Типы'
          placeholder='Выберите типы'
          options={categories}
          onChange={(categories) => onChange({ ...state, categories })}
          selectedOptions={state.categories}
          value={state.categories.map((cat) => cat.name).join(', ')}
        />
      </div>

      <div className='group relative mb-10'>
        <ManagerInput
          multiple
          selected={state.users}
          onChange={(users) => onChange({ ...state, users })}
          label='Менеджеры по лизингу'
          placeholder='Выберите менеджеров'
        />
      </div>

      <div className='group relative mb-10'>
        <ManagerInput
          multiple
          selected={state.bdmUsers}
          onChange={(bdmUsers) => onChange({ ...state, bdmUsers })}
          label='Менеджеры по развитию'
          placeholder='Выберите менеджеров'
          role={UserRoles.RoleBdm}
        />
      </div>

      <div className='group relative mb-10'>
        <TagsInput
          multiple
          entityType={TagEntity.Source}
          selected={state.tagsList}
          onChange={(tag) => onChange({ ...state, tagsList: tag })}
          label='Теги'
          placeholder='Выберите теги'
        />
      </div>
    </FilterForm>
  )
}

const setQuery = throttle(500, (query: string) => {
  const isNumber = query.length < 10 && /^\d+$/.test(query)
  sourceFilterStateVar({
    ...sourceFilterStateVar(),
    query,
    ...(isNumber && { id: parseInt(query) })
  })
})

export const useSourceFilter = () => {
  const filterState = useReactiveVar(sourceFilterStateVar)
  const query = useReactiveVar(sourceQueryVar)

  useEffect(() => {
    setQuery(query)
  }, [query])

  const filterActive =
    filterState.categories.length > 0 ||
    filterState.users.length > 0 ||
    filterState.bdmUsers.length > 0 ||
    filterState.tagsList.length > 0

  const variables = useMemo<SourcesListQueryVariables>((): SourcesListQueryVariables => {
    if (filterState?.query?.length) {
      return {
        query: filterState.query,
        id: filterState.id
      }
    }
    return {
      categories: filterState.categories.map((cat) => cat._id),
      users: filterState.users.map((id) => id.toString()),
      bdmUsers: filterState.bdmUsers,
      tagsList: filterState.tagsList.map((tag) => tag._id)
    }
  }, [filterState])

  return {
    filterState,
    setFilterState: sourceFilterStateVar,
    filterActive,
    query,
    setQuery: sourceQueryVar,
    variables
  }
}

export default Sources
