import { FC, useMemo, useState } from 'react'
import {
  CustomerAccountKind,
  useBankOperationPaymentInfoSuspenseQuery,
  useFeraBankAccountsSuspenseQuery
} from '../../graphql/schema'
import TransactionAmount from '../../components/TransactionAmount'
import { dateFormatter, dateTimeFormatter } from '../../utils/dateFormatter'
import c from 'clsx'
import { RadioGroup } from '@headlessui/react'
import useNodes from '../../hooks/useNodes'
import UserName from '../../components/UserName'
import { Link } from 'react-router-dom'

interface PaymentInfoProps {
  id: string
  onDone?: () => void
}

const PaymentInfo: FC<PaymentInfoProps> = ({ onDone, id }) => {
  const { data: readMoreData } = useBankOperationPaymentInfoSuspenseQuery({ variables: { id: id } })

  const { data: feraBankAccountsData } = useFeraBankAccountsSuspenseQuery()
  const feraBankAccounts = useNodes(feraBankAccountsData?.feraBankAccounts?.edges)

  const feraBankAccountKey = readMoreData?.bankOperation.bankKey
  const feraBankAccountName = useMemo(
    () => feraBankAccounts?.find((account) => account?.bankKey?.toString() === feraBankAccountKey)?.name,
    [feraBankAccounts, feraBankAccountKey]
  )

  const options = ['Детали', 'Реквизиты']
  const [selected, setSelected] = useState(options[0])

  const isPenalty = readMoreData?.bankOperation.customerAccount?.kind === CustomerAccountKind.Penalty

  return (
    <div className='md: flex w-[600px] flex-col gap-8 p-12'>
      <div className='flex justify-between leading-[23px]'>
        <div className='font-display text-h200'>Входящий платеж №{readMoreData?.bankOperation.documentNumber}</div>
        <div className='text-grayscale-150'>{dateFormatter.format(new Date(readMoreData?.bankOperation.date))}</div>
      </div>
      <div className='flex justify-center text-sm leading-6 text-grayscale-250'>
        Проведено по счету № {readMoreData?.bankOperation.accountNumber}
      </div>
      <div className='mx-7 flex justify-center text-grayscale-150'>{readMoreData?.bankOperation.payerName}</div>
      <TransactionAmount
        className='mx-7 flex justify-center text-h100 text-green-600'
        amount={readMoreData?.bankOperation.amount as number}
        isIncome
        currency='₽'
      />
      <RadioGroup
        value={selected}
        onChange={setSelected}
        className='grid auto-cols-fr grid-flow-col rounded-xl bg-grayscale-450 p-1'
      >
        <RadioGroup.Option value={options[0]}>
          <div
            className={c(
              'm-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center',
              selected == options[0] ? 'border-grayscale-350 bg-white-0' : ''
            )}
          >
            Детали
          </div>
        </RadioGroup.Option>
        <RadioGroup.Option value={options[1]}>
          <div
            className={c(
              'm-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center',
              selected == options[1] ? 'border-grayscale-350 bg-white-0' : ''
            )}
          >
            Платежи
          </div>
        </RadioGroup.Option>
      </RadioGroup>
      {selected == options[0] ? (
        <>
          <div className='border-b-1 border-grayscale-350 leading-7'>
            <div className='font-medium'>Назначение платежа</div>
            <div className='mb-4 text-grayscale-150'>{readMoreData?.bankOperation.paymentPurpose}</div>
          </div>
          <table className='w-full'>
            <tbody>
              <tr className='group'>
                <td className='rounded-l-xl px-8 py-6 text-grayscale-150 group-odd:bg-grayscale-450'>На балансе</td>
                <td className='rounded-r-xl px-8 py-6 text-right group-odd:bg-grayscale-450'>
                  <Link
                    to={`/customers/${readMoreData?.bankOperation.customerAccount?.customerCompany.id}`}
                    className='hover:text-red-100'
                  >
                    {readMoreData?.bankOperation.customerAccount?.customerCompany.shortWithOpf}
                  </Link>
                </td>
              </tr>
              <tr className='group'>
                <td className='rounded-l-xl px-8 py-6 text-grayscale-150 group-odd:bg-grayscale-450'>Банк Fera</td>
                <td className='rounded-r-xl px-8 py-6 text-right group-odd:bg-grayscale-450'>{feraBankAccountName}</td>
              </tr>
              <tr className='group'>
                <td className='rounded-l-xl px-8 py-6 text-grayscale-150 group-odd:bg-grayscale-450'>Разнесено</td>
                <td className='rounded-r-xl px-8 py-6 text-right group-odd:bg-grayscale-450'>
                  <UserName userId={parseInt(readMoreData?.bankOperation?.processedByUserId as string)} />
                </td>
              </tr>
              <tr className='group'>
                <td className='rounded-l-xl px-8 py-6 text-grayscale-150 group-odd:bg-grayscale-450'>
                  Дата разнесения
                </td>
                <td className='rounded-r-xl px-8 py-6 text-right group-odd:bg-grayscale-450'>
                  {dateTimeFormatter.format(new Date(readMoreData?.bankOperation.updatedAt))}
                </td>
              </tr>
              <tr className='group'>
                <td className='rounded-l-xl px-8 py-6 text-grayscale-150 group-odd:bg-grayscale-450'>Вид платежа</td>
                <td className='rounded-r-xl px-8 py-6 text-right group-odd:bg-grayscale-450'>
                  {isPenalty ? 'Пени' : 'Лизинговый платеж'}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ) : (
        <>
          <div className='font-medium'>Реквизиты плательщика</div>
          <table className='w-full'>
            <tbody>
              <tr className='group'>
                <td className='rounded-l-xl px-8 py-6 text-grayscale-150 group-odd:bg-grayscale-450'>Компания</td>
                <td className='rounded-r-xl px-8 py-6 text-right group-odd:bg-grayscale-450'>
                  {readMoreData?.bankOperation.payerName}
                </td>
              </tr>
              <tr className='group'>
                <td className='rounded-l-xl px-8 py-6 text-grayscale-150 group-odd:bg-grayscale-450'>ИНН/КПП</td>
                <td className='rounded-r-xl px-8 py-6 text-right group-odd:bg-grayscale-450'>
                  {readMoreData?.bankOperation.payerInn} / {readMoreData?.bankOperation.payerKpp}
                </td>
              </tr>
              <tr className='group'>
                <td className='rounded-l-xl px-8 py-6 text-grayscale-150 group-odd:bg-grayscale-450'>Банк</td>
                <td className='rounded-r-xl px-8 py-6 text-right group-odd:bg-grayscale-450'>
                  {readMoreData?.bankOperation.payerBankName}
                </td>
              </tr>
              <tr className='group'>
                <td className='rounded-l-xl px-8 py-6 text-grayscale-150 group-odd:bg-grayscale-450'>Счёт</td>
                <td className='rounded-r-xl px-8 py-6 text-right group-odd:bg-grayscale-450'>
                  {readMoreData?.bankOperation.payerAccountNumber}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}

      <button className='h-25 rounded-lg bg-grayscale-250 font-semibold text-white-0 hover:bg-red-150' onClick={onDone}>
        Закрыть
      </button>
    </div>
  )
}

export default PaymentInfo
