import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { useGuarantorCompanyByIdQuery, useRefreshFromDadataGuarantorCompanyMutation } from '../../graphql/schema'
import { Address, CompanyData } from '../../types/dadata'
import CompanyInfo from '../../components/CompanyInfo'
import Requisites from '../../components/Requisites'
import useNodes from '../../hooks/useNodes.ts'
import { Card, CardHeader, CardTitle } from '../../components/Card.tsx'

const Info: FC = () => {
  const { id } = useParams<'id'>()
  const { data, loading, refetch } = useGuarantorCompanyByIdQuery({
    variables: { id: id?.toString() || '' },
    skip: !id
  })
  const guarantor = data?.guarantorCompany
  const requisites = useNodes(guarantor?.bankDetails?.edges) || []

  const dadata: CompanyData = guarantor?.dadata ? guarantor?.dadata.data : {}
  const address: Address | undefined = dadata?.address

  const [refreshDadataMutation, { loading: loadingRefreshDadata }] = useRefreshFromDadataGuarantorCompanyMutation()

  async function refreshDadata() {
    if (!guarantor?.inn) return

    await refreshDadataMutation({ variables: { input: { inn: guarantor.inn } } })
    await refetch()
  }

  if (!guarantor) return null

  return (
    <div className='grid grid-cols-2 gap-6'>
      <div className='col-span-1 flex flex-col gap-6'>
        <CompanyInfo
          dadata={dadata}
          companyName={guarantor?.dadata?.value}
          refreshDadata={refreshDadata}
          loading={loadingRefreshDadata || loading}
          lastUpdateName={
            guarantor?.lastDadataRequestUser?.surname &&
            guarantor?.lastDadataRequestUser?.name &&
            guarantor.lastDadataRequestUser.surname + ' ' + guarantor.lastDadataRequestUser.name
          }
          lastUpdateDate={guarantor?.lastDadataRequestDate}
        />

        <Card>
          <CardHeader>
            <CardTitle>Адреса</CardTitle>
          </CardHeader>

          <div className='px-5 pb-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              <table className='w-full'>
                <tbody>
                  <tr className='group'>
                    <td className='w-70 rounded-l-xl p-6'>Юридический адрес</td>
                    <td className='rounded-r-xl p-6'>{address?.unrestricted_value}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Card>
      </div>

      <div className='col-span-1 flex flex-col gap-6'>
        <Requisites
          targetId={guarantor?._id}
          targetType='guarantorCompany'
          requisites={requisites}
          onDone={() => refetch()}
        />
      </div>
    </div>
  )
}

export default Info
