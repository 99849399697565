import { FC, useState, useMemo, useCallback } from 'react'
import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'
import { ReactComponent as LinkIcon } from '../../svg/icons/link.svg'
import { ReactComponent as TickIcon } from '../../svg/icons/tick.svg'
import { ReactComponent as CopyIcon } from '../../svg/icons/copy.svg'
import { ReactComponent as ChecklistUserIcon } from '../../svg/icons/checklistUser.svg'
import Modal from '../../components/Modal'
import { Maybe, useSourcesListQuery, SourcesListQuery } from '../../graphql/schema'
import SourceForm from './SourceForm'
import SearchBar, { FilterButton } from '../../components/Search'
import FilterForm, { useSourceFilter } from './FilterForm'
import Dropdown from '../../components/Dropdown'
import useScrollPagination from '../../hooks/useScrollPagination'
import { sourceCategoryDict } from '../../utils/dictionaries'
import AddButton from '../../components/AddButton'
import GridView, { GridViewConfig } from '../../components/GridView'
import { NodeType } from '../../types'
import useNodes from '../../hooks/useNodes'
import useUpdateEffect from '../../hooks/useUpdateEffect'
import AvatarTooltip from '../../components/Avatar/AvatarTooltip'
import useAccessCheck from '../../hooks/useAccessCheck'
import SelectedFilters from './SelectedFilters'
import useCopyCustomerLink from '../../hooks/useCopyCustomerLink.ts'
import useCopyPartnerLink from '../../hooks/useCopyPartnerLink.ts'
import useCopyWidgetToken from '../../hooks/useCopyWidgetToken.ts'

type Source = NodeType<SourcesListQuery['sources']>

export const SourceMenu: FC<{
  source: Source
  onEditClick: (s: Source) => void
  hasAccess?: boolean
}> = ({ source, hasAccess, onEditClick }) => {
  const [copyPartnerLink, copyPartnerLinkDone] = useCopyPartnerLink(source?.publicId)
  const [copyCustomerLink, copyCustomerLinkDone] = useCopyCustomerLink(source?.customerFormPublicId)
  const [copyWidgetToken, copyWidgetTokenDone] = useCopyWidgetToken(source?.widgetPublicId)

  return (
    <Dropdown className='ml-auto pl-4 pr-4'>
      {hasAccess ? (
        <button
          className='pl-5 pr-10 py-5 flex items-center text-grayscale-200 hover:text-red-100'
          onClick={() => onEditClick(source)}
        >
          <EditIcon className='mr-7' height='20px' width='20px' title='Редактировать' />
          <span className='text-grayscale-0'>Редактировать</span>
        </button>
      ) : (
        <></>
      )}
      <button
        className='pl-5 pr-10 py-5 flex items-center text-grayscale-200 hover:text-red-100 w-106'
        onClick={copyPartnerLink}
      >
        {copyPartnerLinkDone ? (
          <TickIcon className='mr-7 flex-none' width='20px' height='20px' title='Ссылка на партнерскую форму' />
        ) : (
          <LinkIcon className='mr-7 flex-none' width='20px' height='20px' title='Ссылка на партнерскую форму' />
        )}
        <span className='text-grayscale-0 whitespace-nowrap'>
          {copyPartnerLinkDone ? 'Скопировано' : 'Партнерская форма'}
        </span>
      </button>
      <button
        className='pl-5 pr-10 py-5 flex items-center text-grayscale-200 hover:text-red-100'
        onClick={copyCustomerLink}
      >
        {copyCustomerLinkDone ? (
          <TickIcon className='mr-7 flex-none' width='20px' height='20px' title='Ссылка на клиентскую форму' />
        ) : (
          <ChecklistUserIcon className='mr-7 flex-none' width='20px' height='20px' title='Ссылка на клиентскую форму' />
        )}
        <span className='text-grayscale-0 whitespace-nowrap'>
          {copyCustomerLinkDone ? 'Скопировано' : 'Клиентская форма'}
        </span>
      </button>
      <button
        className='pl-5 pr-10 py-5 flex items-center text-grayscale-200 hover:text-red-100'
        onClick={copyWidgetToken}
      >
        {copyWidgetTokenDone ? (
          <TickIcon className='mr-7 flex-none' width='20px' height='20px' title='Токен для виджета' />
        ) : (
          <CopyIcon className='mr-7 flex-none' width='20px' height='20px' title='Токен для виджета' />
        )}
        <span className='text-grayscale-0'>{copyWidgetTokenDone ? 'Скопировано' : 'Виджет'}</span>
      </button>
    </Dropdown>
  )
}

const Sources: FC = () => {
  const [formOpen, setFormOpen] = useState(false)
  const [editEntity, setEditEntity] = useState<Source>()

  const { filterState, setFilterState, query, setQuery, variables, filterActive } = useSourceFilter()

  const {
    data: sourcesData,
    refetch,
    fetchMore,
    loading,
    error
  } = useSourcesListQuery({
    nextFetchPolicy: 'cache-and-network',
    variables
  })

  const hasAccessEditSource = useAccessCheck('source.edit')
  const hasAccessCreateSource = useAccessCheck('source.create')

  useUpdateEffect(() => {
    refetch(variables)
  }, [variables, refetch])

  const sourcesList = useNodes(sourcesData?.sources?.edges)

  const openEditModal = useCallback((source?: Maybe<Source>): void => {
    if (source === null) {
      return
    }
    setEditEntity(source)
    setFormOpen(true)
  }, [])

  const { wrapperRef: triggerRef, isFetching } = useScrollPagination(fetchMore, sourcesData?.sources?.pageInfo)

  const sourceTableConfig = useMemo<GridViewConfig<Source>>(
    () => ({
      grid: 'grid-cols-source',
      columns: [
        { title: 'ID', value: '_id', numeric: true },
        { title: 'Категория', value: (s) => s.category && sourceCategoryDict[s.category] },
        { title: 'Название', value: (s) => <span className='line-clamp-1'>{s.name}</span> },
        {
          title: 'Компания',
          value: (s) => <span className='line-clamp-1'>{s.company?.shortWithOpf}</span>
        },
        {
          title: 'МЛ',
          titleClassName: 'text-center',
          padding: false,
          value: (s) => <AvatarTooltip className='mx-auto' userId={s?.user?._id} />
        },
        {
          title: 'МР',
          titleClassName: 'text-center',
          padding: false,
          value: (s) => <AvatarTooltip className='mx-auto' userId={s?.bdmUser?._id} />
        },
        { value: (s) => <SourceMenu source={s} onEditClick={openEditModal} hasAccess={hasAccessEditSource} /> }
      ],
      rows: {
        link: (c) => `/sources/${c._id}`
      }
    }),
    [openEditModal]
  )

  return (
    <div className='py-15 px-6 flex-grow container'>
      <div className='bg-surface-secondary rounded-xl p-5 mb-8'>
        <div className='bg-white-0 shadow-xs rounded-md'>
          <div className='flex justify-between items-start p-10 border-grayscale-400 border-b-1'>
            <div>
              <h1 className='font-display text-h100'>Источники</h1>
            </div>
          </div>
        </div>
      </div>

      <SearchBar onChange={setQuery} query={query}>
        <FilterButton active={filterActive}>
          <FilterForm state={filterState} onChange={setFilterState} />
        </FilterButton>
      </SearchBar>

      <SelectedFilters />

      <div className='bg-surface-secondary rounded-xl p-5' ref={triggerRef}>
        {hasAccessCreateSource && (
          <AddButton className='mb-5' onClick={() => openEditModal()}>
            Новый источник
          </AddButton>
        )}
        <GridView
          data={sourcesList}
          config={sourceTableConfig}
          loading={loading || isFetching}
          error={error?.message}
        />
      </div>
      <Modal open={formOpen} setOpen={setFormOpen}>
        <div className='bg-white-0 rounded-xl z-50'>
          <SourceForm
            onDone={() => {
              refetch()
              setFormOpen(false)
            }}
            entity={editEntity}
          />
        </div>
      </Modal>
    </div>
  )
}

export default Sources
