import { useCurrentUserQuery, CurrentUserQuery } from '../graphql/schema'
import useCurrentUserId from './useCurrentUserId'

/**
 * Моментально возвращает объект с id текущего пользователя
 * Все остальные данные приходят позже
 * @returns
 */
const useCurrentUser = () => {
  const userId = useCurrentUserId()
  const { data } = useCurrentUserQuery({
    variables: { id: userId?.toString() || '' },
    skip: !userId
  })
  return {
    ...data?.user,
    _id: userId
  } as CurrentUserQuery['user']
}

export default useCurrentUser
