import { FC } from 'react'
import { BaseHistoryEventProps } from '../..'
import { EventActedAt, EventInitiatedBy } from '../EventComponents'

const ApplicationCreatedEvent: FC<BaseHistoryEventProps> = ({ actedAt, withLine, initiatedByUserId }) => {
  return (
    <div className='flex gap-6'>
      <EventActedAt date={actedAt} withLine={withLine} />
      <div className='flex items-start gap-2'>
        <EventInitiatedBy userId={initiatedByUserId} />
        <span className='text-grayscale-200'>создал(а) заявку</span>
      </div>
    </div>
  )
}

export default ApplicationCreatedEvent
