import { FC, useMemo } from 'react'
import GridView, { GridViewConfig } from './GridView'
import { DealByCustomerCompanyIdQuery, useDealByCustomerCompanyIdQuery } from '../graphql/schema'
import useNodes from '../hooks/useNodes'
import { NodeType } from '../types'
import { dateFormatter } from '../utils/dateFormatter'
import DealStatusTooltip from './DealStatus/DealStatusTooltip'
import { Card, CardHeader, CardTitle } from './Card.tsx'

interface CustomerDealsProps {
  customerCompanyId: string
  currentDeal: number
}

export type Deal = NodeType<DealByCustomerCompanyIdQuery['deals']>

const CustomerDeals: FC<CustomerDealsProps> = ({ customerCompanyId, currentDeal }) => {
  const { data: customerDealsData, loading } = useDealByCustomerCompanyIdQuery({
    variables: { customerCompany: customerCompanyId }
  })
  const customerDeals = useNodes(customerDealsData?.deals?.edges)

  const customerOtherDeals = customerDeals.filter((deal) => deal._id !== currentDeal)

  const customerDealsTableConfig = useMemo<GridViewConfig<Deal>>(
    () => ({
      grid: 'grid-cols-deal-s',
      columns: [
        { title: 'ID', value: '_id' },
        { title: 'Дата', value: (d) => dateFormatter.format(new Date(d.created)) },
        {
          title: 'Статус',
          value: (d) => (
            <div className='flex justify-center'>
              <DealStatusTooltip status={d.status} className='py-4' />
            </div>
          )
        }
      ],
      rows: {
        link: (a) => `/deals/${a?._id}`
      }
    }),
    []
  )

  return (
    <Card>
      <CardHeader>
        <CardTitle>Другие сделки клиента</CardTitle>
      </CardHeader>
      <div className='px-5 pb-5'>
        <GridView config={customerDealsTableConfig} data={customerOtherDeals} loading={loading} />
      </div>
    </Card>
  )
}

export default CustomerDeals
