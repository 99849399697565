import { FC } from 'react'
import { useUserByIdQuery } from '../../graphql/schema'
import c from 'clsx'

interface AvatarProps {
  userId?: number
  active?: boolean
  className?: string
  height?: string
  width?: string
}

const bgColors = ['bg-green-50', 'bg-lime-50', 'bg-blue-50', 'bg-rose-50', 'bg-yellow-50', 'bg-purple-50']

const textColors = [
  'text-green-500',
  'text-lime-500',
  'text-blue-500',
  'text-rose-500',
  'text-yellow-500',
  'text-purple-500'
]

const ringColors = [
  'ring-green-500',
  'ring-lime-500',
  'ring-blue-500',
  'ring-rose-500',
  'ring-yellow-500',
  'ring-purple-500'
]

const Avatar: FC<AvatarProps> = ({ userId, active, height, width, className }) => {
  const { data: userData } = useUserByIdQuery({ variables: { id: userId?.toString() || '' }, skip: !userId })
  const user = userData?.user

  return (
    <div
      className={c(
        'flex flex-none items-center justify-center overflow-hidden rounded-full text-[11px]',
        userId ? bgColors[userId % bgColors.length] : 'bg-grayscale-500',
        userId ? textColors[userId % textColors.length] : 'text-grayscale-0',
        active && 'ring-2',
        active && ringColors[userId ? userId % ringColors.length : 0],
        height || 'h-12',
        width || 'w-12',
        className
      )}
    >
      {user?.avatarPath ? (
        <img className='h-full w-full object-cover' alt='' src={user.avatarPath} />
      ) : (
        <>
          {user?.name[0]}
          {user?.surname[0]}
        </>
      )}
    </div>
  )
}

export default Avatar
