import { env } from '../env'

const API_URL = env.REACT_APP_API_URL
const API_VERIFICATION_URL = env.REACT_APP_VERIFICATION_API_URL || "https://verification-stage.fera.ru"

if (!API_URL) throw new Error('REACT_APP_API_URL is not defined')

export const APIRoot = API_URL.replace('/api/graphql', '')
export const APIVerificationRoot = API_VERIFICATION_URL.replace('/internal_graphql', '')

