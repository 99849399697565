import { FC, ComponentPropsWithoutRef } from 'react'
import { ReactComponent as DocumentGraphics } from '../svg/graphics/document.svg'
import c from 'clsx'

interface FileIconProps {
  name: string
}

const FileIcon: FC<FileIconProps & ComponentPropsWithoutRef<'div'>> = ({ name, className }) => {
  const extension = name.split('.').pop()?.toLowerCase() || ''

  let color = 'text-grayscale-150'

  switch (extension) {
    case 'pdf':
      color = 'text-rose-500'
      break
    case 'doc':
    case 'docx':
      color = 'text-blue-500'
      break
    case 'jpeg':
    case 'jpg':
    case 'png':
    case 'tiff':
      color = 'text-lime-500'
      break
    case 'xls':
    case 'xlsx':
      color = 'text-green-500'
      break
  }

  return (
    <div className={c('relative w-15', color, className)}>
      <div className={c(
        'absolute w-full text-center top-1/2 -translate-y-1/2 font-medium',
        extension.length <= 3 && 'text-[13px]',
        extension.length > 3 && 'text-[11px]'
      )}>
        {extension}
      </div>
      <DocumentGraphics className={color} />
    </div>
  )
}

export default FileIcon