import { FC, useState } from 'react'
import { ReactComponent as PendingPaymentIcon } from '../../svg/icons/circleEmpty.svg'
import { ReactComponent as SuccessPaymentIcon } from '../../svg/icons/circleCheck.svg'
import { ReactComponent as TrashIcon } from '../../svg/icons/trash.svg'
import { ReactComponent as CashBanknoteMoreIcon } from '../../svg/icons/cashBanknoteMore.svg'
import { ReactComponent as TinkoffIcon } from '../../svg/icons/tinkoff.svg'
import { ReactComponent as TkbIcon } from '../../svg/icons/tkb.svg'
import { ReactComponent as SovcomIcon } from '../../svg/icons/sovcom.svg'
import { ReactComponent as FileIcon } from '../../svg/icons/fileDownload.svg'
import { ReactComponent as LoadingIcon } from '../../svg/icons/loading.svg'
import BankOrderInfoForm from './BankOrderInfoForm.tsx'
import { NodeType } from '../../types'
import { BankOrdersQuery, BankOrderState, useDeleteBankOrderMutation } from '../../graphql/schema.tsx'
import { env } from '../../env.ts'
import downloadFile from '../../utils/downloadFile'
import { dateFormatter, dateTimeFormatter } from '../../utils/dateFormatter.ts'
import Tooltip from '../Tooltip.tsx'
import Dropdown from '../Dropdown'
import Modal from '../Modal.tsx'
import ConfirmationForm from '../ConfirmationForm.tsx'

interface BankOrdersProps {
  companyName: string
  payments: NodeType<BankOrdersQuery['bankOrders']>[]
  canDelete: boolean
}

const formatMoney = Intl.NumberFormat('ru-RU', { currency: 'RUB', style: 'currency', maximumFractionDigits: 2 }).format

const BankOrders: FC<BankOrdersProps> = ({ companyName, payments, canDelete }) => {
  const [deletingOrderId, setDeletingOrderId] = useState(-1)
  const [formOpenByPaymentId, setFormOpenByPaymentId] = useState<string>()

  const [deleteBankOrder] = useDeleteBankOrderMutation()

  const [loading, setLoading] = useState(false)
  const download = async (bankOrderId: string) => {
    if (loading) return

    setLoading(true)
    try {
      const url = new URL(`/api/export_bank_order_to_1c?bank_order_id=${bankOrderId}`, env.REACT_APP_BILLING_API_URL)
      await downloadFile(url.href, `${bankOrderId}_1CClientBankExchange`, 'GET')
    } finally {
      setLoading(false)
    }
  }

  if (!payments.length) return null
  return (
    <>
      <table className='w-full'>
        <tbody>
          {payments.map((p) => (
            <tr key={p.id} className='group'>
              <td className='flex items-center gap-8 px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                {(() => {
                  switch (p.feraBankAccount.bankKey) {
                    case 'tinkoff':
                      return <TinkoffIcon title={p.feraBankAccount.name} />
                    case 'tkb':
                      return <TkbIcon title={p.feraBankAccount.name} />
                    case 'sovcombank':
                      return <SovcomIcon title={p.feraBankAccount.name} />
                  }
                })()}
                <p className='ml-auto text-right'>{dateFormatter.format(new Date(p.createdAt))}</p>
              </td>
              <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                {formatMoney(p.amount)}
              </td>
              <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                <div className='flex justify-end gap-2'>
                  {(p.state === BankOrderState.InProgress || p.state === BankOrderState.Pending) && (
                    <PendingPaymentIcon />
                  )}
                  {p.state === BankOrderState.Paid && (
                    <Tooltip target={<SuccessPaymentIcon className='ml-auto text-green-600' />}>
                      <p>Оплачено:</p>
                      <p>{dateTimeFormatter.format(new Date(p.paidAt))}</p>
                    </Tooltip>
                  )}
                  <Dropdown className='ml-auto pl-4 pr-4'>
                    <button
                      className='flex w-full items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100 disabled:text-grayscale-350'
                      onClick={() => setFormOpenByPaymentId(p.id)}
                    >
                      <CashBanknoteMoreIcon className='mr-6' title='Подробнее' />
                      <span>Подробнее</span>
                    </button>
                    {p.feraBankAccount.bankKey !== 'tinkoff' ? (
                      <button
                        className='flex items-center whitespace-nowrap py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
                        onClick={() => download(p.id)}
                      >
                        {loading ? (
                          <LoadingIcon className='animate-spin' />
                        ) : (
                          <FileIcon className='w-11' title='Платёжное поручение' />
                        )}
                        <span className='ml-5'>Платёжное поручение</span>
                      </button>
                    ) : (
                      <></>
                    )}
                    {canDelete ? (
                      <button
                        className='flex w-full items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100 disabled:text-grayscale-350'
                        onClick={() => {
                          setDeletingOrderId(parseInt(p.id))
                          //TODO: Ждём мутацию на удаление ордера
                        }}
                      >
                        <TrashIcon className='mr-7' title='Удалить' />
                        <span>Удалить</span>
                      </button>
                    ) : (
                      <></>
                    )}
                  </Dropdown>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal open={deletingOrderId > -1} setOpen={() => setDeletingOrderId(-1)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ConfirmationForm
            title='Удаление платежа'
            onDone={async () => {
              await deleteBankOrder({
                variables: {
                  input: {
                    id: deletingOrderId.toString()
                  }
                },
                update: (cache) => {
                  const normalizedId = cache.identify({ id: deletingOrderId, __typename: 'BankOrder' })
                  cache.evict({ id: normalizedId })
                  cache.gc()
                }
              })
              setDeletingOrderId(-1)
            }}
            onDismiss={() => setDeletingOrderId(-1)}
          >
            Вы действительно хотите удалить платёж?
          </ConfirmationForm>
        </div>
      </Modal>
      <Modal open={!!formOpenByPaymentId} setOpen={() => setFormOpenByPaymentId(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <BankOrderInfoForm
            companyName={companyName}
            paymentId={formOpenByPaymentId}
            onDone={() => {
              setFormOpenByPaymentId(undefined)
            }}
          />
        </div>
      </Modal>
    </>
  )
}

export default BankOrders
