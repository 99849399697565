import { FC } from 'react'
import { useIrrDealCalculationsQuery } from '../../graphql/schema'
import useInViewport from '../../hooks/useInViewport'
import { ReactComponent as LoadingIcon } from '../../svg/icons/loading.svg'
import { formatDecimal } from '../../utils/formatNumber'

interface IRRProps {
  dealId?: string
  amount?: number
  advanceRate?: number
  interestRate?: number
  durationMonths?: number
  comissionRate?: number
  insuranceRate?: number
  vatRate?: number
  advancePaymentDate?: string
  retroBonusAmount?: number
  agentRate?: number
}

const Irr: FC<IRRProps> = ({
  dealId,
  amount,
  advanceRate,
  interestRate,
  durationMonths,
  comissionRate,
  insuranceRate,
  vatRate,
  advancePaymentDate,
  agentRate,
  retroBonusAmount
}) => {
  const [inView, ref] = useInViewport<HTMLDivElement>({ threshold: 0.3, once: true })

  const dealParamsReady =
    amount !== undefined &&
    advanceRate !== undefined &&
    interestRate !== undefined &&
    durationMonths !== undefined &&
    comissionRate !== undefined &&
    insuranceRate !== undefined &&
    vatRate !== undefined &&
    advancePaymentDate !== undefined

  const { data: dealInfoData, loading } = useIrrDealCalculationsQuery({
    variables: {
      dealId: dealId as string,
      dealParams: {
        amount: amount as number,
        advanceRate: advanceRate as number,
        interestRate: interestRate as number,
        durationMonths: durationMonths as number,
        comissionRate: comissionRate as number,
        insuranceRate: insuranceRate as number,
        vatRate: vatRate as number,
        advancePaymentDate: advancePaymentDate as string,
        agentRate: agentRate as number,
        retroBonusAmount: retroBonusAmount as number
      }
    },
    skip: !dealId || !dealParamsReady || !inView
  })

  const { dealInfo, dealCalculations } = dealInfoData || {}

  return (
    <div ref={ref} className='px-5 pb-5'>
      <div className='rounded-md bg-surface-primary shadow-card'>
        {loading ? (
          <div className='flex min-h-67 items-center justify-center'>
            <LoadingIcon className='flex-none animate-spin text-labels-secondary' width='20px' height='20px' />
          </div>
        ) : (
          <table className='w-full tabular-nums'>
            <thead>
              <tr className='group'>
                <th className='px-6 py-6 text-left'></th>
                <th className='px-6 py-6 text-left'>IRR</th>
                <th className='px-6 py-6 text-right'>Сумма договора</th>
              </tr>
            </thead>
            <tbody>
              <tr className='group border-t-1 border-separators-primary'>
                <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>План.</td>
                <td className='whitespace-nowrap px-6 py-6 text-left first:rounded-l-md last:rounded-r-md'>
                  {dealCalculations?.irr && formatDecimal(dealCalculations.irr * 10000) + '%'}
                </td>
                <td className='whitespace-nowrap px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                  {dealCalculations?.contractAmount && formatDecimal(dealCalculations.contractAmount * 100) + ' ₽'}
                </td>
              </tr>
              <tr className='group border-t-1 border-separators-primary'>
                <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Факт.</td>
                <td className='whitespace-nowrap px-6 py-6 text-left first:rounded-l-md last:rounded-r-md'>
                  {dealInfo?.irr && formatDecimal(dealInfo.irr * 10000) + '%'}
                </td>
                <td className='whitespace-nowrap px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                  {dealInfo?.contractAmount && formatDecimal(dealInfo?.contractAmount * 100) + ' ₽'}
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}

export default Irr
