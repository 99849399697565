import { EngagePartnerStatus, useUpdateSupplierCompanyMutation } from '../../graphql/schema'
import { useCallback } from 'react'

const useChangeStatus = (id?: number) => {
  const [updateSupplier, { loading }] = useUpdateSupplierCompanyMutation()

  const changeStatus = useCallback(
    async (status: EngagePartnerStatus) => {
      if (!id) return
      await updateSupplier({
        variables: {
          input: {
            id: id.toString(),
            engagePartnerStatus: status
          }
        }
      })
    },
    [id, updateSupplier]
  )

  return [changeStatus, loading] as const
}

export default useChangeStatus
