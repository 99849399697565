import { inclineFirstname, inclineLastname, inclineMiddlename } from 'lvovich'
import { DeclentionStrT } from 'lvovich/lib/inclineRules'

function getInclineFIO(fio: string, declension: DeclentionStrT | undefined = 'genitive'): string {
  if (!fio) return ''

  const [lastName, firstName, middleNameFirst, middleNameSecond] = fio.trim().replace(/\s\s/g, ' ').split(' ')
  const middleName = middleNameSecond ? middleNameFirst + ' ' + middleNameSecond : middleNameFirst

  return [
    inclineLastname(lastName, declension),
    inclineFirstname(firstName, declension),
    middleName && inclineMiddlename(middleName, declension)
  ]
    .filter((a) => !!a)
    .join(' ')
}

export default getInclineFIO
