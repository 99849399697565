import { FC, FormEvent, useEffect, useMemo, useRef, useState } from 'react'
import { ReactComponent as AddIcon } from '../../svg/icons/add.svg'
import { ReactComponent as LoadingIcon } from '../../svg/icons/loading.svg'
import useThrottledState from '../../hooks/useThrottledState.ts'
import { TagEntity, useAddTagMutation, useTagsQuery } from '../../graphql/schema.tsx'
import useNodes from '../../hooks/useNodes.ts'
import Highlighted from '../Highlighted.tsx'

interface TagInputProps {
  entityType: TagEntity
  selectedTags: number[]
  onAddTag: (id: number) => void
}

const TagInput: FC<TagInputProps> = ({ entityType, selectedTags, onAddTag }) => {
  const [queryText, setQueryText] = useState('')
  const [throttledQueryText] = useThrottledState(queryText, 500)
  const inputRef = useRef<HTMLInputElement>(null)

  const { data, refetch: loadTags } = useTagsQuery({
    variables: {
      filter: {
        type: entityType
      }
    }
  })
  const tags = useNodes(data?.tags?.edges)
  const tagsList = useMemo(() => tags.filter((tag) => !selectedTags.includes(tag._id)), [selectedTags, tags])

  const [addTag, { loading }] = useAddTagMutation()

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    const inputEl = event.target as HTMLInputElement
    inputEl.style.width = '0'
    inputEl.style.width = inputEl.scrollWidth + 'px'
    setQueryText(inputEl.value)
  }

  const onSelectTag = (tagId: number) => {
    onAddTag(tagId)
    if (inputRef.current) {
      inputRef.current.style.width = '0'
      setQueryText('')
      inputRef.current.focus()
    }
  }

  const handleCreateTag = async () => {
    if (loading || !queryText) return

    const { data } = await addTag({
      variables: {
        input: {
          name: queryText.toLowerCase()
        }
      }
    })
    const tagId = data?.addTag?.tag?._id
    if (tagId) {
      onAddTag(tagId)
    }
  }

  useEffect(() => {
    const query = throttledQueryText.split(',')[0].toLowerCase()
    loadTags({ filter: { type: entityType, name: query } })
  }, [throttledQueryText, loadTags])

  return (
    <div className='group relative'>
      <div className='flex h-full'>
        <input
          ref={inputRef}
          type='text'
          className='block w-40 min-w-40 rounded-md border-none bg-surface-secondary px-5 py-3 text-body-m outline-none focus:ring-0'
          value={queryText}
          onChange={handleChange}
          onKeyDown={async (event) => {
            if (event.code === 'Enter') {
              event.preventDefault()
              await handleCreateTag()
            }
          }}
        />
      </div>
      <div className='absolute z-50 mt-3 w-[200px] overflow-hidden rounded-lg border-1 border-grayscale-400 bg-white-0 shadow-md'>
        {!loading && !!tagsList.length && (
          <ul className='max-h-85 overflow-auto border-b-1 border-grayscale-400'>
            {tagsList.map((tag) => (
              <li
                key={tag._id}
                className='cursor-pointer px-8 py-6 hover:bg-grayscale-450'
                onClick={() => onSelectTag(tag._id)}
              >
                <div className='text-grayscale-150'>
                  <Highlighted classMarkName='text-red-100 bg-transparent' text={tag.name} highlight={queryText} />
                </div>
              </li>
            ))}
          </ul>
        )}
        <button
          type='button'
          className='flex cursor-pointer items-center gap-5 px-8 py-6 text-grayscale-150 hover:text-red-100 disabled:cursor-not-allowed disabled:disabled:text-grayscale-250'
          onClick={handleCreateTag}
          disabled={loading || !queryText}
        >
          {loading ? <LoadingIcon className='animate-spin' /> : <AddIcon />}
          Создать тег
        </button>
      </div>
    </div>
  )
}

export default TagInput
