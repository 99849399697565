import { FC } from 'react'
import { formatMoneyDecimal } from '../../utils/formatNumber'
import {
  CustomerAccountKind,
  useCustomerAccountQuery,
  usePayCustomerInvoicesMutation,
  usePayCustomerPenaltiesMutation
} from '../../graphql/schema'
import useAccessCheck from '../../hooks/useAccessCheck'

import { ReactComponent as LoadingIcon } from '../../svg/ui/refresh.svg'
import { ReactComponent as ApplyPaymentIcon } from '../../svg/ui/payment.svg'
import { Card, CardAccentButton, CardHeader, CardMenu, CardTitle } from '../Card'

interface CompanyInfoTabProps {
  customerId?: number | string
  title?: string
  kind?: CustomerAccountKind
  onDone?: () => void
}

const CustomerCompanyBalance: FC<CompanyInfoTabProps> = ({ customerId, title, kind, onDone }) => {
  const { data: customerAccountData, loading: accountDataLoading } = useCustomerAccountQuery({
    variables: { customerCompanyId: `${customerId}`, kind: kind },
    skip: !customerId,
    fetchPolicy: 'cache-and-network'
  })
  const customerAccount = customerAccountData?.customerAccount

  const [payCustomerInvoices, { loading: loadingPayCustomerInvoices }] = usePayCustomerInvoicesMutation()
  const [payCustomerPenalties, { loading: loadingPayCustomerPenalties }] = usePayCustomerPenaltiesMutation()

  const hasAccessRedeem = useAccessCheck('customer_account.redeem')

  async function payInvoices() {
    if (!customerId || !customerAccount?.id) return
    payCustomerInvoices({
      variables: {
        input: {
          customerAccountId: customerAccount.id
        }
      }
    }).then(() => {
      if (onDone) onDone()
    })
  }

  async function payPenalties() {
    if (!customerId || !customerAccount?.id) return
    payCustomerPenalties({
      variables: {
        input: {
          customerAccountId: customerAccount.id
        }
      }
    }).then(() => {
      if (onDone) onDone()
    })
  }

  const loading = loadingPayCustomerPenalties || loadingPayCustomerInvoices

  return (
    <Card>
      <CardHeader>
        <CardTitle>{title || 'Баланс'}</CardTitle>
        {hasAccessRedeem && customerAccount && (
          <CardMenu>
            <CardAccentButton
              onClick={() => {
                if (loading) return
                kind === CustomerAccountKind.Main ? payInvoices() : payPenalties()
              }}
            >
              {loading && <LoadingIcon className='animate-spin' />}
              {!loading && <ApplyPaymentIcon />}
              Погасить начисления
            </CardAccentButton>
          </CardMenu>
        )}
      </CardHeader>
      <div className='px-5 pb-5'>
        <div className='rounded-md bg-surface-primary shadow-card'>
          <div className='w-full px-6 py-4 font-mono'>
            {accountDataLoading ? (
              <div className='mx-auto h-10 w-50 rounded-md bg-surface-secondary' />
            ) : (
              !!customerAccount && formatMoneyDecimal(customerAccount.amount)
            )}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default CustomerCompanyBalance
