import { UsersQuery } from '../graphql/schema'
import { NodeType } from '../types'

export type User = NodeType<UsersQuery['users']>

export default function sortUsersByAcivation(a: User, b: User) {
  if (a.isActive && !b.isActive) {
    return -1
  }
  if (!a.isActive && b.isActive) {
    return 1
  } else {
    return a.name.localeCompare(b.name)
  }
}
