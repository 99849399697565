import { useState } from 'react'
import downloadFile, { HTTP_METHODS } from '../utils/downloadFile'

const useFileDownload = (url: string, fileName: string, type: HTTP_METHODS = 'GET', body?: any): [boolean, () => Promise<void>] => {

  const [loading, setLoading] = useState(false)

  const download = async () => {
    if (loading) return

    setLoading(true)
    try {
      await downloadFile(url, fileName, type, body)
    } finally {
      setLoading(false)
    }
  }

  return [loading, download]
}

export default useFileDownload