import { FC, useEffect, useMemo, useRef, useState } from 'react'
import {
  Comment,
  CommentCreatedDocument,
  CommentCreatedSubscription,
  CommentsDocument,
  CommentsQuery,
  CommentsQueryVariables,
  CommentTargetType,
  useCommentsQuery,
  useCreateCommentMutation
} from '../../graphql/schema'
import { dateFormatter } from '../../utils/dateFormatter'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ApolloError } from '@apollo/client'
import { handleBackendErrorsToForm } from '../../utils/backendErrorUtils'
import CommentsItem from './CommentsItem'
import cache from '../../graphql/cache'
import useCurrentUser from '../../hooks/useCurrentUser'
import { uuid as uuidGen } from '../../utils/uuid'
import ls from 'localstorage-slim'
import { ReactComponent as SendIcon } from '../../svg/ui/send.svg'
import { Card, CardHeader, CardTitle } from '../Card.tsx'

interface CommentsProps {
  targetId: number
  targetType: CommentTargetType
}

interface Inputs {
  body: string
}

const Comments: FC<CommentsProps> = ({ targetId, targetType }) => {
  const { data, subscribeToMore } = useCommentsQuery({
    variables: { targetId, targetType },
    skip: !targetId || !targetType
  })
  const comments = data?.comments as Comment[]

  const currentUser = useCurrentUser()

  useEffect(() => {
    const commentCreatedUnsubscribe = subscribeToMore({
      document: CommentCreatedDocument,
      variables: { targetId, targetType },
      updateQuery: (prev, { subscriptionData }: { subscriptionData: { data: CommentCreatedSubscription } }) => {
        if (!subscriptionData.data) return prev
        const commentCreated = subscriptionData.data.commentCreated
        const commentFound = prev.comments.find((c) => c?.frontendSyncUuid === commentCreated.frontendSyncUuid)

        return Object.assign({}, prev, {
          comments: commentFound
            ? prev.comments.map((comment) =>
                comment?.frontendSyncUuid === commentFound.frontendSyncUuid ? commentFound.frontendSyncUuid : comment
              )
            : [commentCreated, ...prev.comments]
        })
      }
    })

    return () => {
      commentCreatedUnsubscribe()
    }
  }, [targetId, targetType, subscribeToMore])

  const commentsGroupedByDate = useMemo(
    () =>
      comments
        ?.reduce(
          (acc, comment) => {
            const currentDateComment = acc.find(
              (i) => dateFormatter.format(new Date(i.date)) === dateFormatter.format(new Date(comment.createdDate))
            )
            if (currentDateComment) {
              currentDateComment.data.push(comment)
            } else {
              acc.push({
                date: comment.createdDate,
                data: [comment]
              })
            }

            return acc
          },
          [] as { date: string; data: Comment[] }[]
        )
        .sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1))
        .map((i) => {
          const dayAgo = new Date()
          dayAgo.setDate(dayAgo.getDate() - 1)

          const twoDaysAgo = new Date()
          twoDaysAgo.setDate(twoDaysAgo.getDate() - 2)

          return {
            data: i.data.sort((a, b) => (new Date(a.createdDate) > new Date(b.createdDate) ? 1 : -1)),
            date:
              new Date(i.date).setHours(0, 0, 0, 0) > twoDaysAgo.setHours(0, 0, 0, 0)
                ? new Date(i.date).setHours(0, 0, 0, 0) > dayAgo.setHours(0, 0, 0, 0)
                  ? 'Сегодня'
                  : 'Вчера'
                : dateFormatter.format(new Date(i.date))
          }
        }),
    [comments]
  )

  const elementScrollComments = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (elementScrollComments.current) {
      elementScrollComments.current.scrollTop = elementScrollComments.current.scrollHeight
    }
  }, [commentsGroupedByDate])

  const { register, handleSubmit, setError, setValue, getValues, reset } = useForm<Inputs>()

  const [createComment, { loading }] = useCreateCommentMutation()

  const [commentPlaceholder, setCommentPlaceholder] = useState(
    ls.get(`${targetType}_${targetId}_COMMENT`) ? '•  У вас есть неотправленный комментарий' : 'Написать комментарий'
  )

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (!targetId || !targetType || !data.body) return

    const variables = {
      targetId,
      targetType
    }
    const uuid = uuidGen().toString()
    const newComment = {
      id: `temp_id_${uuid}`,
      targetId,
      targetType,
      body: data.body,
      createdDate: new Date(),
      userId: currentUser?._id,
      frontendSyncUuid: uuid
    } as Comment

    const commentsFromCache = cache.readQuery<CommentsQuery, CommentsQueryVariables>({
      query: CommentsDocument,
      variables
    })

    cache.writeQuery<CommentsQuery, CommentsQueryVariables>({
      query: CommentsDocument,
      variables,
      data: {
        __typename: 'Query',
        comments: [newComment, ...(commentsFromCache?.comments || [])]
      }
    })
    reset()
    ls.remove(`${targetType}_${targetId}_COMMENT`)
    setCommentPlaceholder('Написать комментарий')

    await createComment({
      variables: {
        input: {
          targetId,
          targetType,
          body: data.body,
          frontendSyncUuid: uuid
        }
      }
    }).catch((err: ApolloError) => {
      handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
        setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
      })
    })
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Комментарии</CardTitle>
      </CardHeader>

      <div className='px-5 pb-5'>
        <div className='rounded-md bg-surface-primary shadow-card'>
          <div className='p-4'>
            {!!commentsGroupedByDate?.length && (
              <div className='max-h-[485px] overflow-auto pb-2' ref={elementScrollComments}>
                {commentsGroupedByDate.map((commentsByDate) => (
                  <div key={commentsByDate.date} className='mt-10 flex flex-col gap-10 px-6'>
                    <div className='flex items-center'>
                      <div className='w-full border-b border-grayscale-350' />
                      <div className='px-5 text-grayscale-200'>{commentsByDate.date}</div>
                      <div className='w-full border-b border-grayscale-350' />
                    </div>

                    {commentsByDate?.data.map((comment) => <CommentsItem key={comment.id} comment={comment} />)}
                  </div>
                ))}
              </div>
            )}

            <form className='relative mb-9 mt-10 px-6' onSubmit={handleSubmit(onSubmit)}>
              <button
                disabled={loading}
                type='submit'
                className='absolute right-6 top-1/3 z-10 pr-9 text-grayscale-200 hover:text-red-100'
              >
                <SendIcon width={18} height={18} title='Отправить' />
              </button>
              <label className='group relative block rounded-xl bg-white-0 ring-1 ring-grayscale-350 focus-within:ring-red-100 hover:ring-grayscale-250 hover:focus-within:ring-red-100'>
                <input
                  type='text'
                  placeholder={commentPlaceholder}
                  className='w-full border-none bg-transparent py-8 pl-10 pr-27 placeholder-grayscale-250 outline-none transition-opacity focus:ring-0'
                  onInput={(e) => {
                    setValue('body', e.currentTarget.value)
                    ls.set(`${targetType}_${targetId}_COMMENT`, getValues('body'), { ttl: 86400 })
                    if (!e.currentTarget.value) {
                      setCommentPlaceholder('Написать комментарий')
                      ls.remove(`${targetType}_${targetId}_COMMENT`)
                    }
                  }}
                  onFocus={() => {
                    if (ls.get(`${targetType}_${targetId}_COMMENT`)) {
                      setValue('body', ls.get(`${targetType}_${targetId}_COMMENT`) || '')
                    }
                  }}
                  autoComplete='off'
                  {...register('body')}
                />
              </label>
            </form>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default Comments
