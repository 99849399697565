import { useState } from 'react'
import { useBoVerificationsLazyQuery, useInnByPassportLazyQuery } from '../graphql/schema'
import { getDateFormattedString } from '../utils/contractUtils'

export type Contact = {
  _id: number
  verificationStatus: string
}

const usePersonAddress = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [getVerifications] = useBoVerificationsLazyQuery()
  const [getInn] = useInnByPassportLazyQuery()

  const loadAddress = async (contacts: Array<Contact>, inn?: string) => {
    if (!inn || !contacts.length) return
    setLoading(true)
    // теперь для каждого контакта мы будем делать:
    // 1. запрос на получение верификаций
    // 2. запрос на получение ИНН

    // map пройдется по массиву айди и выполнит асинхронную функцию для каждого элемента
    // результатом map будет массив промисов
    try {
      const promises = contacts
        // фильтруем контакты по статусу, потом отдаем отфильтрованный список в map
        .filter((contact) => contact.verificationStatus === 'accepted')
        .map(async (contact) => {
          try {
            const verificationsData = await getVerifications({
              variables: { contactId: contact._id }
            })

            // выбирается последняя успешная верификация и берутся из нее данные

            const verification = verificationsData.data?.boVerifications[0]

            // по ней запрашивается инн

            const { data: innData } = await getInn({
              variables: {
                input: {
                  firstName: verification?.personInfo?.firstName || '',
                  lastName: verification?.personInfo?.lastName || '',
                  middleName: verification?.personInfo?.middleName || '',
                  birthDate: getDateFormattedString(new Date(verification?.personInfo?.birthDate)) || '',
                  passport: verification?.personInfo?.passportSeriesAndNumber || '',
                  passportIssueDate: getDateFormattedString(new Date(verification?.personInfo?.passportIssueDate)) || ''
                }
              }
            })
            const verificationInn = innData?.inn.inn
            if (inn != verificationInn) return // если инн не тот, вернем пустоту
            // если нашли нужный инн, вернем адрес
            return verification?.personInfo?.regAddress
          } catch (e) {
            console.error(e)
          }
        })

      // promise all дождется выполнения всех промисов и вернет массив результатов
      const results = await Promise.all(promises) // дождемся выполнения всех промисов
      if (!results.filter(Boolean)[0]) {
        setError(true)
      }
      return results.filter(Boolean)[0]

      // так как мы возвращали пустоту, если не нашли инн, то тут мы можем просто отфильтровать пустые значения и вернем первый адрес, который нашелся
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }
  return [loadAddress, loading, error] as const
}

export default usePersonAddress
