import { Kind, VatKind } from '../graphql/schema.tsx'

export default function convertKindToVatKind(kind: Kind) {
  switch (kind) {
    case Kind.Medicine:
      return VatKind.Medicine
    case Kind.Regular:
      return VatKind.Regular
    case Kind.Usn:
      return VatKind.Usn
    default:
      throw new Error(`Unsupported Kind: ${kind}`)
  }
}
