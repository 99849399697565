import { FC, useRef, useState, useEffect, useMemo, useCallback, ReactNode } from 'react'
import { ReactComponent as SearchIcon } from '../../svg/icons/search.svg'
import { ReactComponent as LoadingIcon } from '../../svg/icons/loading.svg'
import { ReactComponent as TickIcon } from '../../svg/icons/tick-xs.svg'
import { ReactComponent as MoreIcon } from '../../svg/icons/more.svg'
import Highlighted from '../Highlighted'
import c from 'clsx'
import GroupView from './GroupView'

export interface Option {
  name: string
  value: string | number
  group?: string
  children?: Option[]
}

export interface Group {
  name?: string
  options: Option[]
  selectedOptions: Option[]
  children: Option[]
}

interface GroupSelectInputProps {
  options: Option[]
  selectedOptions: Option[]
  label?: string
  renderGroup?: (name: string) => ReactNode
  placeholder?: string
  loading?: boolean
  error?: string
  flat?: boolean
  onChange: (options: Option[]) => void
  showSelectedOptions?: boolean
}

const GroupSelectInput: FC<GroupSelectInputProps> = ({
  options,
  selectedOptions,
  label,
  placeholder,
  loading,
  error,
  flat = false,
  onChange,
  renderGroup
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const searchInputRef = useRef<HTMLInputElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const [searchQuery, setSearchQuery] = useState('')

  // close on click outisde
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
        setIsOpen(false)
      }
    }

    if (isOpen) {
      searchInputRef.current?.focus()
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [options, isOpen])

  const filteredOptions = useMemo(() => {
    if (!searchQuery) {
      return options
    } else {
      return options.filter((option) => option.name.toLowerCase().includes(searchQuery.toLowerCase()))
    }
  }, [searchQuery, options])

  const toggleOption = useCallback(
    (opt: Option) => {
      if (selectedOptions.includes(opt)) {
        onChange(selectedOptions.filter((o) => o !== opt))
      } else {
        onChange([...selectedOptions, opt])
      }
    },
    [selectedOptions, onChange]
  )

  const innerLabel = selectedOptions.length ? `Выбрано: ${selectedOptions.length}` : placeholder

  const flatListOpen = !!searchQuery || flat

  return (
    <div>
      {!!label && <div className='inp-label text-p350 mb-5'>{label}</div>}
      <div className='group relative' ref={wrapperRef}>
        <input
          type='text'
          readOnly={true}
          className={c(
            'px-10 py-7 rounded-lg transition ease-in-out border-1 border-grayscale-350 outline-none focus:ring-0 focus:outline-none group-focus-within:border-red-100 focus:shadow-none w-full placeholder-grayscale-250 cursor-pointer',
            !!error && 'bg-red-200/70 border-red-100'
          )}
          placeholder={innerLabel}
          autoComplete='off'
          onFocus={() => {
            setIsOpen(true)
          }}
          onPointerDown={(e) => {
            setIsOpen((open) => {
              if (!open) {
                e.currentTarget?.focus()
              } else {
                e.currentTarget?.blur()
              }
              return !open
            })
            e.preventDefault()
          }}
        />
        <MoreIcon className='rotate-90 text-grayscale-250 absolute right-10 top-1/2 -translate-y-1/2 pointer-events-none group-focus-within:-rotate-90 transition-transform' />
        {isOpen && (
          <label className='absolute bg-white-0 rounded-xl shadow-xs w-full mt-5 overflow-y-auto flex flex-col z-50'>
            {loading && (
              <div className='h-29 flex items-center justify-center text-grayscale-250 flex-none'>
                <LoadingIcon className='animate-spin' />
              </div>
            )}
            {!loading && !options.length && (
              <div className='h-29 flex items-center justify-center text-grayscale-250 flex-none'>Список пуст</div>
            )}
            {!loading && !!options.length && (
              <>
                <div className='border-b-1 border-grayscale-350 flex items-center h-29 flex-none'>
                  <SearchIcon className='mx-10 text-grayscale-200' />
                  <input
                    className='border-none outline-none ring-0 focus:ring-0 placeholder-grayscale-250 flex-grow'
                    placeholder='Поиск'
                    onChange={(e) => setSearchQuery(e.target.value)}
                    value={searchQuery}
                    ref={searchInputRef}
                  />
                </div>
                {flatListOpen ? (
                  <div className='relative h-170 overflow-hidden'>
                    <ul className={c('py-4 absolute inset-0 overflow-y-auto scrollbar-hide transition-transform')}>
                      {filteredOptions.map((opt) => (
                        <li
                          tabIndex={flatListOpen ? 0 : -1}
                          key={opt.value}
                          className='hover:bg-grayscale-200/5 px-12 py-5 cursor-pointer'
                          onClick={() => toggleOption(opt)}
                        >
                          <div className='text-grayscale-0 mb-1 flex items-center'>
                            <div className='pr-5 font-medium text-p100 whitespace-nowrap overflow-ellipsis overflow-hidden min-w-0 flex-shrink-[0.5]'>
                              <Highlighted
                                text={opt.name}
                                highlight={searchQuery}
                                classMarkName='text-red-100 bg-transparent'
                              />
                            </div>
                            <div className='mr-auto pr-5 font-medium text-[10px] whitespace-nowrap overflow-ellipsis overflow-hidden flex-shrink-[2] min-w-0 text-grayscale-300'>
                              {opt.group || 'Прочее'}
                            </div>
                            <div>
                              {selectedOptions.find((o) => opt.value === o.value) && (
                                <div className='rounded-full bg-red-100/5 text-red-100 px-5 py-2 flex items-center'>
                                  <TickIcon />
                                </div>
                              )}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <GroupView
                    options={options}
                    selectedOptions={selectedOptions}
                    toggleOption={toggleOption}
                    onChange={onChange}
                    renderGroup={renderGroup}
                  />
                )}
              </>
            )}
          </label>
        )}
      </div>
      {error && <div className='text-red-150 text-p450 pl-4 pt-2'>{error}</div>}
    </div>
  )
}

export default GroupSelectInput
