import { FC } from 'react'
import Avatar from '.'
import { useUserByIdQuery } from '../../graphql/schema'
import Tooltip from '../Tooltip'
import getFullName from '../../utils/getFullName'

interface AvatarTooltipProps {
  userId?: number
  className?: string
}

const AvatarTooltip: FC<AvatarTooltipProps> = ({ userId, className }) => {
  const { data: userData } = useUserByIdQuery({ variables: { id: userId?.toString() as string }, skip: !userId })
  const user = userData?.user

  return <Tooltip target={<Avatar userId={userId} className={className} />}>{getFullName(user)}</Tooltip>
}

export default AvatarTooltip
