import { FC, useCallback, useEffect, useState } from 'react'
import { ReactComponent as ChevronDownIcon } from '../../../svg/ui/chevron-down.svg'
import { ReactComponent as AppScoringRisksUserEditIcon } from '../../../svg/icons/appScoringRisksUserEdit.svg'
import {
  CompanyScoringStatus,
  CompanyScoringTargetType,
  useCompanyScoringQuery,
  useUpdateCompanyScoringMutation,
  useUserByIdQuery
} from '../../../graphql/schema.tsx'
import ScoringStatusSwitcher from '../../Application/Scoring/StatusSwitcher.tsx'
import Avatar from '../../../components/Avatar'
import getFullName from '../../../utils/getFullName.ts'
import { dateTimeFormatter } from '../../../utils/dateFormatter.ts'
import c from 'clsx'
import ScoringRisksUserForm from '../../Application/Scoring/ScoringRisksUserForm.tsx'
import SuspenseModal from '../../../components/SuspenseModal.tsx'
import ScoringInput from '../../Application/Scoring/ScoringInput.tsx'
import ScoringQuestion from '../../Application/Scoring/ScoringQuestion.tsx'
import {
  SCORING_GOVERNMENT_CONTRACTS_QUESTIONS,
  SCORING_LITIGATION_AND_LEASING_QUESTIONS,
  SCORING_STATUTORY_QUESTIONS,
  SCORING_TAX_QUESTIONS
} from '../../../utils/constants.ts'
import useScoringConclusion from '../../Application/Scoring/useScoringConclusion.ts'
import { Card, CardAccentButton, CardHeader, CardIconButton, CardMenu, CardTitle } from '../../../components/Card.tsx'

export type CompanyScoring = {
  createdDate: string
  doneAt?: string
  expiresAt?: string
  id: string
  infoRequestedAt?: string
  questionnaireConclusion?: string
  questionnaireData?: any
  risksUserId: number
  status: CompanyScoringStatus
  targetId: string
  targetType: CompanyScoringTargetType
}

interface CompanyScoringCardProps {
  companyIP: boolean
  open: boolean
  setOpen: () => void
  scoringId: string
}

const CompanyScoringCard: FC<CompanyScoringCardProps> = ({ companyIP, open, setOpen, scoringId }) => {
  const [updateCompanyScoring, { loading }] = useUpdateCompanyScoringMutation()

  const [editRisksUserId, setEditRisksUserId] = useState<number>()
  const [questionnaireData, setQuestionnaireData] = useState()

  const { data: companyScoringData } = useCompanyScoringQuery({
    variables: {
      id: parseInt(scoringId)
    },
    skip: !scoringId
  })
  const scoring = companyScoringData?.companyScoring

  useEffect(() => {
    if (scoring?.questionnaireData) {
      setQuestionnaireData(scoring.questionnaireData)
    }
  }, [scoring?.questionnaireData])

  const { data: userData } = useUserByIdQuery({
    variables: { id: scoring?.risksUserId?.toString() as string },
    skip: !scoring?.risksUserId
  })
  const risksUser = userData?.user

  const [conclusion, setConclusion, generateConclusion] = useScoringConclusion(scoring?.questionnaireConclusion)

  const onConclusionAccept = useCallback(
    async (value: string) => {
      if (!scoring?.id) return

      await updateCompanyScoring({
        variables: {
          id: parseInt(scoring.id),
          input: {
            questionnaireConclusion: value
          }
        }
      })
    },
    [scoring?.id, updateCompanyScoring]
  )

  const handleDataSave = async (data: any) => {
    if (!scoring?.id) return

    await updateCompanyScoring({
      variables: {
        id: parseInt(scoring?.id),
        input: {
          questionnaireData: data
        }
      }
    })
    const generatedConclusion = generateConclusion(data)
    await onConclusionAccept(generatedConclusion)
  }

  const questionnaireChaptersFirst = [
    { value: 'Уставные', questions: SCORING_STATUTORY_QUESTIONS },
    { value: 'Госконтракты', questions: SCORING_GOVERNMENT_CONTRACTS_QUESTIONS }
  ]
  const questionnaireChaptersSecond = [
    {
      value: 'Налоговые',
      questions: companyIP
        ? SCORING_TAX_QUESTIONS.filter(
            (q) =>
              ![
                'Указать среднесписочное количество сотрудников организации?',
                'Специальный налоговый режим не применяется?'
              ].includes(q.value)
          )
        : SCORING_TAX_QUESTIONS
    },
    { value: 'Судебные и лизинговые', questions: SCORING_LITIGATION_AND_LEASING_QUESTIONS }
  ]

  const processExpired = scoring?.expiresAt && new Date(scoring.expiresAt) < new Date()

  return (
    <Card>
      <CardHeader>
        <CardTitle onClick={setOpen} className='cursor-pointer'>
          {processExpired ? 'Истекший' : 'Актуальный'} cкоринг
        </CardTitle>
        <span className='mr-8 font-normal text-labels-tertiary'>
          {scoring?.createdDate && dateTimeFormatter.format(new Date(scoring.createdDate))}
        </span>
        <CardMenu>
          <CardAccentButton
            className='py-3'
            onClick={() => {
              setEditRisksUserId(scoring?.risksUserId)
            }}
          >
            <Avatar userId={scoring?.risksUserId} height='h-10' width='w-10' />
            {getFullName(risksUser)}
            <AppScoringRisksUserEditIcon />
          </CardAccentButton>
        </CardMenu>

        <CardMenu className='ml-auto'>
          <ScoringStatusSwitcher
            status={scoring?.status}
            loading={loading}
            onChangeStatus={async (status) => {
              if (!scoring?.id) return
              await updateCompanyScoring({
                variables: {
                  id: parseInt(scoring.id),
                  input: {
                    status: status as CompanyScoringStatus
                  }
                }
              })
            }}
          />

          <CardIconButton onClick={setOpen} className='p-4'>
            <ChevronDownIcon className={c('transition-transform', open && '-rotate-180')} />
          </CardIconButton>
        </CardMenu>
      </CardHeader>

      {open && (
        <div className='flex flex-col gap-6 px-5 pb-5'>
          <div className='flex gap-12 rounded-md bg-surface-primary p-10 shadow-card'>
            <h2 className='font-display text-h200 font-medium'>Вывод:</h2>

            <div className='w-full'>
              <ScoringInput
                autoComplete='off'
                onChange={(e) => setConclusion(e.currentTarget.value)}
                onAccept={() => onConclusionAccept(conclusion)}
                value={conclusion}
              />
            </div>
          </div>

          <div className='grid grid-cols-3 gap-6'>
            <div className='col-span-1 flex flex-col gap-6'>
              {questionnaireChaptersFirst.map((chapter) => (
                <div key={chapter.value}>
                  <div className='rounded-md bg-surface-primary p-10 shadow-card'>
                    <h2 className='font-display text-h200 font-medium'>{chapter.value}</h2>

                    <div className='mt-10 flex flex-col gap-10'>
                      {chapter.questions.map((question) => (
                        <ScoringQuestion
                          key={chapter.value + question.value}
                          chapter={chapter.value}
                          question={question}
                          questionnaireData={questionnaireData}
                          children={question?.children}
                          onDataChange={(data) => setQuestionnaireData(data)}
                          onDataSave={handleDataSave}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {questionnaireChaptersSecond.map((chapter) => (
              <div key={chapter.value} className='col-span-1'>
                <div className='rounded-md bg-surface-primary p-10 shadow-card'>
                  <h2 className='font-display text-h200 font-medium'>{chapter.value}</h2>

                  <div className='mt-10 flex flex-col gap-10'>
                    {chapter.questions.map((question) => (
                      <ScoringQuestion
                        key={chapter.value + question.value}
                        chapter={chapter.value}
                        question={question}
                        questionnaireData={questionnaireData}
                        children={question?.children}
                        onDataChange={(data) => setQuestionnaireData(data)}
                        onDataSave={handleDataSave}
                      />
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <SuspenseModal open={!!editRisksUserId} setOpen={() => setEditRisksUserId(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ScoringRisksUserForm
            userId={scoring?.risksUserId}
            loading={loading}
            onDone={async (risksUserId) => {
              if (!scoring?.id) return
              await updateCompanyScoring({
                variables: {
                  id: parseInt(scoring.id),
                  input: {
                    risksUserId
                  }
                }
              })
              setEditRisksUserId(undefined)
            }}
          />
        </div>
      </SuspenseModal>
    </Card>
  )
}

export default CompanyScoringCard
