import { FC } from 'react'
import {
  Kind,
  PaymentKind,
  PaymentState,
  PaymentTargetType,
  useCreatePaymentScheduleMutation,
  useDealForSchedulesSuspenseQuery,
  usePaymentsSuspenseQuery,
  useScheduleByDealParamsLazyQuery
} from '../../../graphql/schema.tsx'
import c from 'clsx'
import { Card, CardHeader, CardIconButton, CardMenu, CardTitle } from '../../../components/Card.tsx'
import { ReactComponent as PlusWithLineIcon } from '../../../svg/ui/application.svg'
import { ReactComponent as PlusIcon } from '../../../svg/ui/plus.svg'
import { ReactComponent as TrashIcon } from '../../../svg/ui/delete.svg'
import { ReactComponent as LoadingIcon } from '../../../svg/ui/refresh.svg'
import { formatDecimal } from '../../../utils/formatNumber.ts'
import { REGULAR_VAT_RATE } from '../../../utils/constants.ts'
import { IMaskInput, ReactMaskOpts } from 'react-imask'
import { parseDecimalFromMasked } from '../../../utils/parseDecimal.ts'
import { suspend } from 'suspend-react'
import { showPopup } from '../../../components/Toaster/showPopup.tsx'
import { uuid } from '../../../utils/uuid.ts'
import useNodes from '../../../hooks/useNodes.ts'
import { Controller, useFieldArray, useForm, SubmitHandler } from 'react-hook-form'
import { addMonths } from 'date-fns'

type ScheduleItem = {
  _id: string
  amount: string
  date: string
  locked?: boolean
}

const numberMaskOptions: ReactMaskOpts = {
  mask: Number,
  thousandsSeparator: ' ',
  scale: 2
}

interface PaymentFormProps {
  dealId: number
  onDone?: () => void
}

interface PaymentsFormValues {
  payments: ScheduleItem[]
}

const ScheduleForm: FC<PaymentFormProps> = ({ dealId, onDone }) => {
  const { data: dealData } = useDealForSchedulesSuspenseQuery({
    variables: {
      id: `${dealId}`
    },
    fetchPolicy: 'network-only'
  })
  const deal = dealData?.deal

  const { data: leasingPaymentsData } = usePaymentsSuspenseQuery({
    variables: {
      kind: PaymentKind.Leasing,
      targetType: PaymentTargetType.Deal,
      targetId: dealId.toString()
    },
    fetchPolicy: 'network-only'
  })
  const leasingPayments = useNodes(leasingPaymentsData?.payments.edges)

  const [scheduleByDealParams] = useScheduleByDealParamsLazyQuery()

  const paymentsData: ScheduleItem[] = suspend(async () => {
    if (!deal) return []
    if (leasingPayments?.length)
      return leasingPayments.map((p) => ({
        _id: p.id,
        amount: formatDecimal(p.amount * 100),
        date: p.date,
        locked: p.state !== PaymentState.Pending || p.penaltyPayments?.length > 0
      }))

    const { data: scheduleData } = await scheduleByDealParams({
      variables: {
        dealParams: {
          amount: deal.amount / 100,
          advanceRate: deal.advanceRate,
          interestRate: deal.interestRate,
          durationMonths: deal.durationMonths,
          comissionRate: deal.comissionRate,
          insuranceRate: deal?.insuranceAmount ? Math.round((deal?.insuranceAmount / deal?.amount) * 10000) / 100 : 0,
          vatRate: deal?.kind === Kind.Medicine ? 0 : REGULAR_VAT_RATE,
          advancePaymentDate: deal?.advancePaymentDate
        }
      }
    })
    // надо убрать uuid когда будем получать id с бэка
    return (
      scheduleData?.scheduleByDealParams.map((s) => ({
        _id: uuid().toString(),
        amount: formatDecimal(s.amount * 100),
        date: s.date
      })) || []
    )
  }, [deal, leasingPaymentsData])

  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting }
  } = useForm<PaymentsFormValues>({
    defaultValues: {
      payments: paymentsData
    }
  })
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'payments',
    rules: {
      validate: {
        // isValidDate: (value: ScheduleItem[]) => value.every((v) => !!v.date) || 'Дата платежа не может быть пустой',
        // isValidAmount: (value: ScheduleItem[]) => value.every((v) => !!v.amount) || 'Сумма платежа не может быть пустой'
        isNotEmpty: (value) => {
          return (value as ScheduleItem[])?.length > 0 || 'Нужен как минимум один платёж'
        },
        isFilled: (value) => {
          return (value as ScheduleItem[]).every((v) => !!v.date && !!v.amount) || 'Заполните все поля'
        }
      }
    }
  })

  const [createPaymentSchedule] = useCreatePaymentScheduleMutation()

  const onSubmit: SubmitHandler<PaymentsFormValues> = async (data) => {
    await createPaymentSchedule({
      variables: {
        input: {
          dealId: dealId.toString(),
          paymentsData: data.payments.map((p) => ({
            amount: parseDecimalFromMasked(p.amount),
            date: new Date(p.date)
          }))
        }
      }
    }).then(({ data }) => {
      if (data?.createPaymentSchedule?.errors?.length)
        return showPopup({
          title: 'Ошибка',
          subtitle: data.createPaymentSchedule.errors.map((e) => e.message).toString()
        })

      if (onDone) onDone()
    })
  }

  const hanldePaymentAdd = () => {
    const payments = getValues('payments')
    const lastPayment = payments[payments.length - 1]

    let newDate = ''
    if (lastPayment?.date) {
      const date = addMonths(new Date(lastPayment.date), 1)
      newDate = date.toISOString().split('T')[0]
    }

    let newAmount = ''
    if (lastPayment?.amount) {
      newAmount = lastPayment.amount
    }

    append({ _id: uuid().toString(), amount: newAmount, date: newDate })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='w-[652px]'>
      <h1 className='px-12 py-10 font-display text-h200'>Создание нового графика платежей</h1>

      <div className='border-t-1 border-separators-primary px-12 pb-10 pt-6'>
        <Card>
          <CardHeader>
            <CardTitle className='mt-4'>График платежей</CardTitle>
            <CardMenu className='mt-4'>
              <CardIconButton
                type='button'
                onClick={() => {
                  // delete all payments except locked
                  const payments = getValues('payments')
                  replace(payments.filter((p) => p.locked))
                }}
              >
                <TrashIcon />
              </CardIconButton>
            </CardMenu>
          </CardHeader>
          <div className='px-5 pb-5'>
            <div className='grid w-full grid-cols-[auto_auto_1fr_auto] rounded-lg bg-surface-primary shadow-card'>
              <div className='contents border-b-1 border-separators-primary text-labels-tertiary'>
                <div className='border-b-1 border-separators-primary px-7 py-4'>№</div>
                <div className='min-w-80 border-b-1 border-l-1 border-separators-primary px-7 py-4'>Дата</div>
                <div className='col-span-2 border-b-1 border-l-1 border-separators-primary px-7 py-4'>Сумма, ₽</div>
              </div>
              {fields.map((field, index) => {
                const locked = !!getValues(`payments.${index}.locked`)
                return (
                  <section key={field.id} className='contents font-mono'>
                    <div className='border-b-1 border-separators-primary px-7 py-4 text-labels-tertiary'>
                      {index + 1}
                    </div>

                    <div className='relative border-b-1 border-l-1 border-separators-primary'>
                      <input
                        className='absolute inset-0 border-0 bg-transparent px-7 py-4 outline-none focus:border-0 focus:ring-transparent disabled:text-labels-secondary data-[invalid=true]:ring-2 data-[invalid=true]:ring-inset data-[invalid=true]:ring-base-red/25'
                        type='date'
                        disabled={!!getValues(`payments.${index}.locked`)}
                        data-invalid={!!errors.payments?.[index]?.date}
                        {...register(`payments.${index}.date`, { required: 'Дата платежа не может быть пустой' })}
                        placeholder={'дд.мм.гггг'}
                      />
                    </div>

                    <div
                      className={c('relative border-b-1 border-l-1 border-separators-primary', locked && 'col-span-2')}
                    >
                      <Controller
                        control={control}
                        name={`payments.${index}.amount`}
                        rules={{ required: 'Сумма платежа не может быть пустой' }}
                        render={({ field: { onChange, value } }) => (
                          <IMaskInput
                            {...numberMaskOptions}
                            className='absolute inset-0 border-0 bg-transparent px-7 py-4 outline-none focus:border-0 focus:ring-transparent disabled:text-labels-secondary data-[invalid=true]:ring-2 data-[invalid=true]:ring-inset data-[invalid=true]:ring-base-red/25'
                            value={value}
                            data-invalid={!!errors.payments?.[index]?.amount}
                            onAccept={(value) => onChange(value)}
                            disabled={!!getValues(`payments.${index}.locked`)}
                          />
                        )}
                      />
                    </div>

                    {!locked && (
                      <button
                        type='button'
                        disabled={locked}
                        onClick={() => {
                          remove(index)
                        }}
                        className='flex items-center border-b-1 border-l-1 border-separators-primary px-7 text-labels-tertiary hover:text-base-red'
                      >
                        <TrashIcon className='mx-auto cursor-pointer' />
                      </button>
                    )}
                  </section>
                )
              })}

              <div className='col-span-4'>
                <button
                  type='button'
                  className='flex w-full items-center gap-4 px-7 py-4 text-body-s text-labels-tertiary hover:text-base-red'
                  onClick={hanldePaymentAdd}
                >
                  <PlusIcon />
                  Добавить платёж
                </button>
              </div>
            </div>
          </div>
        </Card>
      </div>

      {errors.payments?.root && <div className='px-12 pb-4 text-base-red'>{errors.payments.root.message}</div>}

      <div className='px-12 pb-10'>
        <button
          className='flex items-center gap-3 rounded-md bg-base-red px-24 py-5 text-base-white disabled:bg-grayscale-450 disabled:text-grayscale-250'
          onClick={() => handleSubmit(onSubmit)}
        >
          {isSubmitting ? <LoadingIcon className='animate-spin' /> : <PlusWithLineIcon />}
          Создать
        </button>
      </div>
    </form>
  )
}

export default ScheduleForm
