import { FC, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ReactComponent as LoadingIcon } from '../../../svg/ui/refresh.svg'
import { ReactComponent as AppScoringStatusPendingIcon } from '../../../svg/icons/appScoringStatusPending.svg'
import {
  CompanyScoringStatus,
  CompanyScoringTargetType,
  useCompanyScoringsQuery,
  useCreateCompanyScoringMutation,
  useCustomerForScoringQuery
} from '../../../graphql/schema.tsx'
import CompanyScoringCard from './CompanyScoringCard.tsx'
import { CompanyData } from '../../../types/dadata.ts'
import { isIP } from '../../../utils/contractUtils.ts'
import { Card, CardAccentButton, CardHeader, CardMenu } from '../../../components/Card.tsx'

const Scoring: FC = () => {
  const { id } = useParams<'id'>()

  const [openTab, setOpenTab] = useState<number | undefined>(0)

  const { data, refetch, loading } = useCompanyScoringsQuery({
    variables: {
      targetId: id!,
      targetType: CompanyScoringTargetType.CustomerCompany
    },
    skip: !id
  })
  const scorings = data?.companyScorings

  const { data: customerForScoringData } = useCustomerForScoringQuery({
    variables: { id: id! },
    skip: !id
  })
  const customer = customerForScoringData?.customerCompany
  const customerCompanyDadata: CompanyData = useMemo(
    () => (customer?.dadata ? customer.dadata.data : {}),
    [customer?.dadata]
  )
  const IP: boolean = isIP(customerCompanyDadata)

  const [createScoring, { loading: createScoringLoading }] = useCreateCompanyScoringMutation()

  const handleCreateScoring = async () => {
    await createScoring({
      variables: {
        targetId: `${id}`,
        targetType: CompanyScoringTargetType.CustomerCompany
      }
    })
    refetch()
  }

  const canCreateScoring = !scorings?.length || !scorings.find((s) => s.status !== CompanyScoringStatus.Done)

  return (
    <div className='flex flex-col gap-4'>
      {canCreateScoring && !loading && (
        <Card>
          <CardHeader>
            <CardMenu>
              <CardAccentButton type='button' onClick={handleCreateScoring}>
                {createScoringLoading ? <LoadingIcon className='w-8 animate-spin' /> : <AppScoringStatusPendingIcon />}
                Запустить скоринг
              </CardAccentButton>
            </CardMenu>
          </CardHeader>
        </Card>
      )}

      {scorings?.map((scoring, i) => {
        const open = openTab === i

        return (
          <CompanyScoringCard
            key={scoring.id}
            companyIP={IP}
            scoringId={scoring.id}
            open={open}
            setOpen={() => setOpenTab((tab) => (tab === i ? undefined : i))}
          />
        )
      })}
    </div>
  )
}

export default Scoring
