import { RetryLink } from '@apollo/client/link/retry'
import { OperationTypeNode, Kind } from 'graphql'

const retryDisabledFor = [
  'dealCalculations',
  'scheduleByDealParams',
]

const retryLink = new RetryLink({
  attempts: {
    max: 8,
    retryIf: (_error, operation) => {
      // allow disabling retry with setting context.retry = false
      const context = operation.getContext()
      if(context?.retry === false) { return false }

      const preventRetry = operation.query.definitions.some(
        (definition) => {
          // retry is disabled for mutations
          const isMutation = definition.kind === Kind.OPERATION_DEFINITION && definition.operation === OperationTypeNode.MUTATION
          if (isMutation) return true
          // retry is disabled for some queries
          const isDisabledByName = retryDisabledFor.includes(operation.operationName)
          return isDisabledByName
        }
      )

      return !preventRetry
    }
  },

})

export default retryLink
