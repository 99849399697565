import { FC, useState, useCallback } from 'react'
import { throttle } from 'throttle-debounce'
import { Switch } from '@headlessui/react'
import { ReactComponent as TickIcon } from '../../svg/icons/tick-md.svg'
import { RadioGroup } from '@headlessui/react'
import { useEdoTypesQuery } from '../../graphql/schema'
import useNodes from '../../hooks/useNodes'
import Input from '../Input'
import c from 'clsx'
import { Card, CardHeader, CardTitle } from '../Card.tsx'

export interface EDONode {
  name: string
  _id: number
}

const noop = () => {}

export type EDOCallback = (data: { enabled?: boolean | null; edos?: EDONode[]; customEDO?: string | null }) => void

interface EDOProps {
  enabled?: boolean
  selectedEDOs: EDONode[]
  customEDO?: string
  onChange: EDOCallback
  hasAccess?: boolean
}

const EDO: FC<EDOProps> = ({ enabled, selectedEDOs, onChange, customEDO, hasAccess = false }) => {
  const { data: edoTypesData } = useEdoTypesQuery()
  const edoTypes = useNodes(edoTypesData?.edoTypes?.edges)

  const [customEDOValue, setCustomEDO] = useState(customEDO || '')

  const throttledEDOChange = useCallback(
    throttle(500, (value: string) => {
      onChange({
        customEDO: value?.length ? value : null
      })
    }),
    [onChange]
  )

  const onCustomEDOChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setCustomEDO(value)
      throttledEDOChange(value)
    },
    [throttledEDOChange]
  )

  const onSelect = useCallback(
    (edo: EDONode, checked: boolean) => {
      onChange({
        enabled,
        edos: checked ? [...selectedEDOs, edo] : selectedEDOs.filter((o) => o._id !== edo._id),
        customEDO
      })
    },
    [onChange, selectedEDOs, enabled, customEDO]
  )

  const hasCustomEDO = selectedEDOs.find((edo) => edo.name === 'Другой')

  const handleChange = (value: string) => {
    const edoEnabled = value === 'yes'

    // if values are equal, then we need to toggle
    if (edoEnabled === enabled) {
      onChange({ enabled: null })
    } else {
      onChange({ enabled: edoEnabled })
    }
    setCustomEDO(customEDO || '')
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>ЭДО</CardTitle>
      </CardHeader>

      <div className='px-5 pb-5'>
        <div className='rounded-md bg-surface-primary px-4 py-10 shadow-card'>
          {hasAccess ? (
            <>
              <p className='mx-6 mb-6'>Есть ли ЭДО?</p>
              <RadioGroup
                className='mx-6 grid auto-cols-fr grid-flow-col rounded-xl bg-grayscale-450 p-1'
                value={enabled === true ? 'yes' : enabled === false ? 'no' : ''}
                onChange={noop}
              >
                <RadioGroup.Option onClick={() => handleChange('yes')} value='yes'>
                  <div
                    className={c(
                      'm-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center',
                      enabled === true && 'border-grayscale-350 bg-white-0'
                    )}
                  >
                    Да
                  </div>
                </RadioGroup.Option>
                <RadioGroup.Option onClick={() => handleChange('no')} value='no'>
                  <div
                    className={c(
                      'm-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center',
                      enabled === false && 'border-grayscale-350 bg-white-0'
                    )}
                  >
                    Нет
                  </div>
                </RadioGroup.Option>
              </RadioGroup>
              {enabled && (
                <div className='mx-6 mt-12'>
                  <p className='mb-8'>Укажите ЭДО, которыми пользуется организация</p>
                  <div className='grid grid-cols-2 gap-8'>
                    {edoTypes.map((edo) => {
                      const checked = !!selectedEDOs.find((o) => o._id === edo._id)

                      return (
                        <Switch.Group key={edo._id}>
                          <div className='flex items-center'>
                            <Switch
                              checked={checked}
                              onChange={(checked: boolean) => onSelect(edo, checked)}
                              className={c(
                                'relative mr-5 inline-flex h-12 w-12 items-center justify-center overflow-hidden rounded-lg border-grayscale-300 transition-colors focus:outline-none focus:ring-2 focus:ring-grayscale-400 focus:ring-offset-2',
                                checked ? 'bg-red-100' : 'border-1 bg-white-0'
                              )}
                            >
                              <TickIcon
                                className={c(
                                  'text-white-0 transition-transform',
                                  checked ? 'translate-y-0' : 'translate-y-20'
                                )}
                              />
                            </Switch>
                            <Switch.Label className='mr-4'>{edo.name}</Switch.Label>
                          </div>
                        </Switch.Group>
                      )
                    })}
                  </div>
                  {!!edoTypes?.length && hasCustomEDO && (
                    <div className='mt-12'>
                      <Input
                        type='text'
                        placeholder='Название ЭДО'
                        value={customEDOValue}
                        onChange={onCustomEDOChange}
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <div className='flex items-center justify-between rounded-xl bg-grayscale-450 p-6'>
              <p>Операторы</p>
              <p>{!!selectedEDOs?.length ? selectedEDOs.map((type) => type.name).toString() : 'Нет'}</p>
            </div>
          )}
        </div>
      </div>
    </Card>
  )
}

export default EDO
