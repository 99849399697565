import { useApolloClient } from '@apollo/client'
import {
  ApplicationContactsByDealIdQuery,
  ApplicationContactsByDealIdQueryVariables,
  ApplicationContactsByDealIdDocument,
  DealContactsInfoDocument,
  DealContactsInfoQuery,
  DealContactsInfoQueryVariables,
  useCustomerContactInfosByIdQuery
} from '../graphql/schema'
import { useEffect, useState } from 'react'
import useNodes, { getNodes } from './useNodes'

export interface ContactInfo {
  _id: number
  fio: string
  phone?: string | undefined
  email?: string | undefined
  comment?: string | undefined
  verificationStatus?: string
  contactSource?: string
}

const useCustomerContactInfos = (dealIds: number[], id?: string) => {
  const client = useApolloClient()
  const [customerDealContacts, setCustomerDealContacts] = useState<ContactInfo[]>([])
  const { data: customerContactInfosData } = useCustomerContactInfosByIdQuery({
    variables: { id: id! },
    skip: !id
  })
  const customerContactInfos = useNodes(customerContactInfosData?.customerCompany?.contactInfos?.edges)

  const getCustomerDealContacts = async (dealIds: number[]) => {
    if (dealIds.length === 0) return []

    const dealsData = await Promise.all(
      dealIds.map((dealId) =>
        client.query<DealContactsInfoQuery, DealContactsInfoQueryVariables>({
          query: DealContactsInfoDocument,
          variables: { dealId: dealId }
        })
      )
    )

    const applicationsData = await Promise.all(
      dealIds.map((dealId) =>
        client.query<ApplicationContactsByDealIdQuery, ApplicationContactsByDealIdQueryVariables>({
          query: ApplicationContactsByDealIdDocument,
          variables: { id: dealId.toString() }
        })
      )
    )

    const applicationContactInfos = applicationsData.flatMap((a) =>
      getNodes(a.data.deal?.application.contacts?.edges)
        .filter((c) => c.verificationStatus === 'accepted')
        .map((c) => ({
          _id: c._id,
          fio: c.fio,
          email: c.email,
          phone: c.phone,
          verificationStatus: c.verificationStatus,
          contactSource: 'Из верификации'
        }))
    )

    const notificationsContactInfos = dealsData
      .flatMap((deal) =>
        deal.data.dealContacts.map(({ contact }) => ({
          _id: parseInt(contact.id || ''),
          fio: contact.fio,
          email: contact.email,
          phone: contact.phone,
          verificationStatus: contact.verificationStatus,
          contactSource: 'Из уведомлений'
        }))
      )
      .filter((nc) => !applicationContactInfos.find((ac) => ac._id === nc._id))

    return [...notificationsContactInfos, ...applicationContactInfos]
  }
  useEffect(() => {
    getCustomerDealContacts(dealIds).then((result) => setCustomerDealContacts(result as ContactInfo[]))
  }, [...dealIds, customerContactInfos])

  return [...customerContactInfos, ...customerDealContacts]
}

export default useCustomerContactInfos
