import { useState, useEffect, useMemo } from 'react'
import { env } from '../env'
import { uuid } from '../utils/uuid'
import DaDataSuggestions, { AddressData, BankData, CompanyData } from '../types/dadata'
import checkKPP from '../utils/kppValidator'

const { REACT_APP_DADATA_TOKEN } = env

type DaDataCompanyType = 'LEGAL' | 'INDIVIDUAL'

type DaDataQueryOptions = {
  query: string
  kpp?: string
  count: number
  type?: DaDataCompanyType
}

const useAccumulateBaseDaDataQuery = (query = ''): DaDataQueryOptions =>
  useMemo(
    () => ({
      query,
      count: 5
    }),
    [query]
  )

const useDaDataSuggestions = <T>(url = '', query: DaDataQueryOptions): T[] | null => {
  const [suggestions, setSuggestions] = useState<T[] | null>([])

  useEffect(() => {
    if (query.query) {
      fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Token ${REACT_APP_DADATA_TOKEN}`
        },
        body: JSON.stringify(query)
      })
        .then((res) => res.json())
        .then((res: DaDataSuggestions<T>) => {
          setSuggestions(
            res.suggestions.map((suggestion) => ({
              ...suggestion.data,
              value: suggestion.value,
              _id: uuid().toString()
            }))
          )
        })
        .catch(() => {
          // send error to sentry
          setSuggestions(null)
        })
    } else {
      setSuggestions([])
    }
  }, [query, url])

  return suggestions
}

const DADATA_BASE_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/'

const useCompanySuggestions = (query = '') =>
  useDaDataSuggestions<CompanyData>(DADATA_BASE_URL + 'suggest/party', useAccumulateBaseDaDataQuery(query))

const useCompanyByKppSuggestions = (inn = '', query = '', companyType?: DaDataCompanyType) => {
  const queryObject: DaDataQueryOptions = useMemo(
    () => ({
      query: inn,
      kpp: checkKPP(query) ? query : '',
      type: companyType,
      count: 5
    }),
    [query, inn, companyType]
  )
  return useDaDataSuggestions<CompanyData>(DADATA_BASE_URL + 'findById/party', queryObject)
}
const useAddressSuggestions = (query = '') =>
  useDaDataSuggestions<AddressData>(DADATA_BASE_URL + 'suggest/address', useAccumulateBaseDaDataQuery(query))
const useBankSuggestions = (query = '') =>
  useDaDataSuggestions<BankData>(DADATA_BASE_URL + 'suggest/bank', useAccumulateBaseDaDataQuery(query))

export { useCompanySuggestions, useCompanyByKppSuggestions, useAddressSuggestions, useBankSuggestions }
export default useDaDataSuggestions
