import { FC } from 'react'
import { ReactComponent as EditIcon } from '../../../svg/icons/edit.svg'
import { useForm, SubmitHandler } from 'react-hook-form'
import checkInn from '../../../utils/innValidator.ts'
import SubmitButton from '../../../components/SubmitButton'
import { useUpdateVerificationMutation } from '../../../graphql/schema.tsx'
import MaskedInput from '../../../components/MaskedInput.tsx'

interface InnFormProps {
  verificationUuid: string
  inn?: string
  onDone?: () => void
}

interface InnFormValues {
  inn: string
}

const InnForm: FC<InnFormProps> = ({ verificationUuid, inn, onDone }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<InnFormValues>({
    defaultValues: {
      inn
    }
  })

  const [updateVerification, { loading }] = useUpdateVerificationMutation()

  const onSubmit: SubmitHandler<InnFormValues> = async (data) => {
    if (loading) return

    await updateVerification({
      variables: {
        uuid: verificationUuid,
        input: {
          inn: data.inn
        }
      }
    }).then(() => {
      if (onDone) onDone()
    })
  }

  return (
    <section className='w-[448px] p-12'>
      <h1 className='mb-12 font-display text-h200'>Редактирование ИНН</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 grid grid-cols-1 gap-10'>
          <MaskedInput
            label=''
            mask='000000000000'
            autoComplete='off'
            placeholder='9999999999'
            {...register('inn', {
              required: true,
              validate: (value) => checkInn(value) || 'Неверный ИНН'
            })}
            error={errors.inn}
          />
        </div>

        <SubmitButton loading={loading}>
          <EditIcon className='mr-5' />
          Изменить
        </SubmitButton>
      </form>
    </section>
  )
}

export default InnForm
