import { FC, useEffect, useMemo, useState } from 'react'
import {
  DealInsuranceKind,
  DealStatus,
  Kind,
  LeasingSubjectCategory,
  PledgeKind,
  RetroBonusKind,
  SupplyingStatus,
  useCategoriesSuspenseQuery,
  useFeraBankAccountsSuspenseQuery,
  useDealByIdSuspenseQuery,
  usePledgeHoldersQuery,
  useUpdateDealMutation
} from '../../graphql/schema'
import { Controller, NestedValue, SubmitHandler, useForm } from 'react-hook-form'
import Input from '../../components/Input'
import { DealQueryLeasingCategory } from './models'
import { handleBackendErrorsToForm } from '../../utils/backendErrorUtils'
import { ApolloError } from '@apollo/client'
import { supplyingStatusDict } from '../../utils/dictionaries'
import Select from '../../components/Select'
import parseDecimal, { parseDecimalFromMasked } from '../../utils/parseDecimal'
import RadioButtonsGroup from '../../components/RadioButtonsGroup'
import { REGULAR_VAT_RATE } from '../../utils/constants'
import MaskedInput from '../../components/MaskedInput'
import { formatDecimal, formatPercent } from '../../utils/formatNumber'
import SubmitButton from '../../components/SubmitButton'
import GroupSelectInput, { Option } from '../../components/GroupSelectInput'
import ManagerInput from '../../components/Forms/Inputs/ManagerInput'
import useNodes from '../../hooks/useNodes'

import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'
import { ReactComponent as TickIcon } from '../../svg/icons/tick-xs.svg'
import limitFloat from '../../utils/limitFloat'
import MaskedNumberInput from '../../components/Forms/Inputs/MaskedNumberInput'
import MaskedPercentInput from '../../components/Forms/Inputs/MaskedPercentInput'

interface DealUpdateInputs {
  contractDate: string
  insuranceContract: string
  insuranceContractDate: string
  advancePaymentDate: string
  supplyingStatus: SupplyingStatus
  amount: string
  durationMonths: string
  advanceRate: string
  interestRate: string
  comissionRate: string
  insuranceAmount: string
  vatRate: number
  leasingSubjectCategories: NestedValue<DealQueryLeasingCategory[]>
  status: DealStatus
  user: number
  feraBankAccountId?: string
  pledgeHolder?: {
    _id: number
    name: string
    shortName?: string
    key?: string
  }
  kind?: Kind
  insuranceKind?: DealInsuranceKind
  pledgeKind?: PledgeKind
  retroBonusKind?: RetroBonusKind
}

const EditDealForm: FC<{ dealId: number; onDone: () => void }> = ({ dealId, onDone }) => {
  const { data } = useDealByIdSuspenseQuery({
    variables: { id: `${dealId}` }
  })

  const deal = data?.deal

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    setError,
    control
  } = useForm<DealUpdateInputs>({
    defaultValues: {
      contractDate: deal?.contractDate && new Date(deal.contractDate).toISOString().split('T').shift(),
      insuranceContract: deal?.insuranceContract,
      insuranceContractDate:
        deal?.insuranceContractDate && new Date(deal.insuranceContractDate).toISOString().split('T').shift(),
      advancePaymentDate:
        deal?.advancePaymentDate && new Date(deal.advancePaymentDate).toISOString().split('T').shift(),
      supplyingStatus: deal?.supplyingStatus,
      amount: deal?.amount && formatDecimal(deal.amount),
      durationMonths: deal?.durationMonths.toString(),
      advanceRate: formatPercent(deal?.advanceRate),
      interestRate: formatPercent(deal?.interestRate),
      comissionRate: formatPercent(deal?.comissionRate),
      insuranceAmount: deal?.insuranceAmount ? formatDecimal(deal.insuranceAmount) : '',
      user: deal?.user?._id,
      feraBankAccountId: deal?.feraBankAccountId,
      pledgeHolder: deal?.pledgeHolder,
      kind: deal?.kind || Kind.Regular,
      insuranceKind: deal?.insuranceKind,
      pledgeKind: deal?.pledgeKind,
      retroBonusKind: deal?.retroBonusKind
    }
  })
  const [updateDeal, { loading }] = useUpdateDealMutation()
  const [selectedCategories, setSelectedCategories] = useState<Option[]>([])

  const kind = watch('kind')
  const insuranceKind = watch('insuranceKind')
  const pledgeKind = watch('pledgeKind')
  const retroBonusKind = watch('retroBonusKind')

  const { data: categoriesData } = useCategoriesSuspenseQuery()
  const categories = useNodes(categoriesData?.leasingSubjectCategories?.edges)
  const options = useMemo(
    () =>
      categories?.map((cat) => ({
        name: cat.name,
        value: cat._id,
        group: cat.categoryGroup?.name
      })) || [],
    [categories]
  )

  const defaultLeasingSubjectCategories = useMemo(
    () => deal?.leasingSubjectCategories?.edges?.map((l) => l?.node) as LeasingSubjectCategory[],
    [deal?.leasingSubjectCategories]
  )
  useEffect(() => {
    setSelectedCategories(
      options?.filter((option) => defaultLeasingSubjectCategories?.map((cat) => cat._id).includes(option.value)) || []
    )
  }, [options, defaultLeasingSubjectCategories])

  const { data: feraBankAccountsData } = useFeraBankAccountsSuspenseQuery()
  const feraBankAccounts = useNodes(feraBankAccountsData?.feraBankAccounts?.edges)

  const { data: pledgeHoldersData } = usePledgeHoldersQuery()
  const pledgeHolders = useNodes(pledgeHoldersData?.pledgeHolders?.edges)

  const onSubmit: SubmitHandler<DealUpdateInputs> = async (data) => {
    await updateDeal({
      variables: {
        input: {
          ...data,
          id: dealId.toString(),
          contractDate: data.contractDate?.length ? new Date(data.contractDate).toISOString() : undefined,
          insuranceContract: insuranceKind === DealInsuranceKind.WithInsurance ? data.insuranceContract : undefined,
          insuranceContractDate:
            insuranceKind === DealInsuranceKind.WithInsurance && data.insuranceContractDate?.length
              ? new Date(data.insuranceContractDate).toISOString()
              : undefined,
          advancePaymentDate: data.advancePaymentDate?.length
            ? new Date(data.advancePaymentDate).toISOString()
            : undefined,
          supplyingStatus: data.supplyingStatus,
          amount: Math.round(parseDecimalFromMasked(data.amount) * 100),
          leasingSubjectCategories: selectedCategories.map((l) => l?.value.toString()),
          insuranceAmount: Math.round(parseDecimal(data.insuranceAmount.replace(/\s/g, '')) * 100) || 0,
          advanceRate: limitFloat(parseDecimal(data.advanceRate)),
          comissionRate: limitFloat(parseDecimal(data.comissionRate)),
          interestRate: limitFloat(parseDecimal(data.interestRate)),
          durationMonths: parseInt(data.durationMonths),
          vatRate: kind === Kind.Medicine ? 0 : REGULAR_VAT_RATE,
          user: data?.user?.toString() || undefined,
          feraBankAccountId: data?.feraBankAccountId || null,
          pledgeHolder: pledgeKind === PledgeKind.WithPledge ? data?.pledgeHolder?._id.toString() : null
        }
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<DealUpdateInputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  const supplyingStatuses: SupplyingStatus[] = Object.keys(supplyingStatusDict) as SupplyingStatus[]
  const supplyingStatus = watch('supplyingStatus') as SupplyingStatus
  const feraBankAccountId = watch('feraBankAccountId')
  const feraBankAccountName = useMemo(
    () => feraBankAccounts?.find((account) => account?.id?.toString() === feraBankAccountId)?.name,
    [feraBankAccounts, feraBankAccountId]
  )
  const pledgeHolder = watch('pledgeHolder')

  return (
    <section className='w-[448px] p-12 lg:w-[912px]'>
      <h1 className='mb-12 font-display text-h200'>Редактирование сделки</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 grid grid-cols-1 gap-20 lg:grid-cols-2'>
          <div className='relative flex flex-col gap-8'>
            <Input label='Дата договора' type='date' {...register('contractDate')} error={errors.contractDate} />
            <RadioButtonsGroup
              onChange={(value) => setValue('insuranceKind', value)}
              options={[
                ['Со страховкой', DealInsuranceKind.WithInsurance],
                ['Без страховки', DealInsuranceKind.WithoutInsurance]
              ]}
              checkedValue={insuranceKind}
              label='Страховка'
            />
            {insuranceKind === DealInsuranceKind.WithInsurance && (
              <>
                <Input
                  label='Номер полиса'
                  type='text'
                  placeholder='ИОГ-М-1001512270'
                  {...register('insuranceContract')}
                  error={errors.insuranceContract}
                />
                <Input
                  label='Дата полиса'
                  type='date'
                  {...register('insuranceContractDate')}
                  error={errors.insuranceContractDate}
                />
              </>
            )}
            <Controller
              name='user'
              control={control}
              render={({ field }) => (
                <ManagerInput
                  selected={field.value ? [field.value] : []}
                  onChange={(selected) => field.onChange(selected[selected.length - 1])}
                  label='Менеджер'
                  placeholder='Выберите менеджера'
                  error={errors?.user}
                />
              )}
            />
            <Select
              label='Отгрузки'
              type='text'
              editable={false}
              autoComplete='off'
              {...register('supplyingStatus')}
              value={supplyingStatus ? supplyingStatusDict[supplyingStatus] : ''}
            >
              {supplyingStatuses.map((s) => (
                <li
                  key={s}
                  onClick={() => setValue('supplyingStatus', s)}
                  className='cursor-pointer px-12 py-5 hover:bg-grayscale-450'
                >
                  <div className='flex items-center justify-between'>
                    <div className='mb-1 text-grayscale-0'>{supplyingStatusDict[s]}</div>
                    {supplyingStatus === s.toString() && (
                      <div className='ml-5 flex items-center rounded-full bg-red-100/5 px-5 py-2 text-red-100'>
                        <TickIcon />
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </Select>
            <div>
              <GroupSelectInput
                label='Категории лизинга'
                placeholder='Выбрать категории'
                options={options}
                selectedOptions={selectedCategories}
                onChange={setSelectedCategories}
                error={errors.leasingSubjectCategories?.message}
              />
            </div>
            <Select
              label='Реквизиты Fera'
              type='text'
              editable={false}
              autoComplete='off'
              {...register('feraBankAccountId')}
              value={feraBankAccountName}
              clearSelected={() => setValue('feraBankAccountId', '')}
            >
              {feraBankAccounts?.map((account) => (
                <li
                  key={account?.id}
                  onClick={() => {
                    if (feraBankAccountId === account?.id.toString()) {
                      setValue('feraBankAccountId', '')
                    } else {
                      setValue('feraBankAccountId', account?.id)
                    }
                  }}
                  className='cursor-pointer px-12 py-5 hover:bg-grayscale-450'
                >
                  <div className='flex items-center justify-between'>
                    <div className='mb-1 text-grayscale-0'>{account?.name}</div>
                    {feraBankAccountId === account?.id.toString() && (
                      <div className='ml-5 flex items-center rounded-full bg-red-100/5 px-5 py-2 text-red-100'>
                        <TickIcon />
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </Select>
            <RadioButtonsGroup
              onChange={(value) => setValue('pledgeKind', value)}
              options={[
                ['С залогом', PledgeKind.WithPledge],
                ['Без залога', PledgeKind.WithoutPledge]
              ]}
              checkedValue={pledgeKind}
              label='Залог'
            />
            {pledgeKind === PledgeKind.WithPledge && (
              <Select
                label='Залогодержатель (Банк)'
                type='text'
                editable={false}
                autoComplete='off'
                {...register('pledgeHolder')}
                value={pledgeHolder?.shortName}
                clearSelected={() => setValue('pledgeHolder', undefined)}
              >
                {pledgeHolders?.map((ph) => (
                  <li
                    key={ph?._id}
                    onClick={() => {
                      if (pledgeHolder?._id === ph?._id) {
                        setValue('pledgeHolder', undefined)
                      } else {
                        setValue('pledgeHolder', ph)
                      }
                    }}
                    className='cursor-pointer px-12 py-5 hover:bg-grayscale-450'
                  >
                    <div className='flex items-center justify-between'>
                      <div className='mb-1 text-grayscale-0'>{ph?.shortName}</div>
                      {pledgeHolder?._id === ph?._id && (
                        <div className='ml-5 flex items-center rounded-full bg-red-100/5 px-5 py-2 text-red-100'>
                          <TickIcon />
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </Select>
            )}
          </div>

          <div className='relative flex flex-col gap-8'>
            <Input
              label='Дата внесения аванса'
              type='date'
              {...register('advancePaymentDate')}
              error={errors.advancePaymentDate}
            />
            <Controller
              control={control}
              name='amount'
              rules={{ required: true, min: 0 }}
              render={({ field, fieldState }) => (
                <MaskedNumberInput
                  label='Сумма'
                  placeholder='100 000,00'
                  inputMode='decimal'
                  {...field}
                  error={fieldState?.error}
                />
              )}
            />
            <Controller
              control={control}
              name='advanceRate'
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <MaskedPercentInput
                  label='Аванс(%)'
                  placeholder='25'
                  inputMode='decimal'
                  {...field}
                  error={fieldState?.error}
                />
              )}
            />
            <Controller
              control={control}
              name='interestRate'
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <MaskedPercentInput
                  label='Ставка(%)'
                  placeholder='10'
                  inputMode='decimal'
                  {...field}
                  error={fieldState?.error}
                />
              )}
            />
            <MaskedInput
              label='Срок(мес.)'
              placeholder='12'
              inputMode='numeric'
              mask='00'
              {...register('durationMonths', { required: true })}
              error={errors.durationMonths}
            />
            <Controller
              control={control}
              name='comissionRate'
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <MaskedPercentInput
                  label='Комиссия(%)'
                  placeholder='3'
                  inputMode='decimal'
                  {...field}
                  error={fieldState?.error}
                />
              )}
            />
            {insuranceKind === DealInsuranceKind.WithInsurance && (
              <Controller
                control={control}
                name='insuranceAmount'
                rules={{ required: true, min: 0 }}
                render={({ field, fieldState }) => (
                  <MaskedNumberInput
                    label='Страховка'
                    placeholder='10 000,00'
                    inputMode='decimal'
                    {...field}
                    error={fieldState?.error}
                  />
                )}
              />
            )}
            <RadioButtonsGroup
              onChange={(value) => setValue('kind', value)}
              options={[
                ['Обычная (20%)', Kind.Regular],
                ['УСН (0%)', Kind.Usn],
                ['Медицина (0%)', Kind.Medicine]
              ]}
              checkedValue={kind}
              label='Вид сделки (НДС)'
              sameWidth={false}
            />
            <RadioButtonsGroup
              onChange={(value) => setValue('retroBonusKind', value)}
              options={[
                ['С ретробонусом', RetroBonusKind.WithRetrobonus],
                ['Без ретробонуса', RetroBonusKind.WithoutRetrobonus]
              ]}
              checkedValue={retroBonusKind}
              label='Ретробонус'
            />
          </div>
        </div>

        <SubmitButton loading={loading}>
          <EditIcon className='mr-5' />
          Изменить
        </SubmitButton>
      </form>
    </section>
  )
}

export default EditDealForm
