import { FC, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import SubmitButton from '../../components/SubmitButton'
import checkKPP from '../../utils/kppValidator'
import Input from '../../components/Input'
import { useAddCompanyBranchMutation, useUpdateCompanyBranchMutation } from '../../graphql/schema'
import { ApolloError } from '@apollo/client'
import { handleBackendErrorsToForm } from '../../utils/backendErrorUtils'
import { CompanyData } from '../../types/dadata'
import getCompanyName from '../../utils/getCompanyName'
import Suggestions from '../../components/Suggestions'
import useThrottledState from '../../hooks/useThrottledState'
import { useCompanyByKppSuggestions } from '../../hooks/useSuggestions'

import { ReactComponent as PlusIcon } from '../../svg/icons/plus.svg'
import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'

export type CompanyBranch = { _id: number; name: string; kpp: string }

interface CompanyBranchFormProps {
  onDone: () => void
  supplierCompany: number
  supplierCompanyInn: string
  entity?: CompanyBranch
}

type CompanyBranchInputs = {
  name: string
  kpp: string
}

const CompanyBranchForm: FC<CompanyBranchFormProps> = ({ onDone, supplierCompany, supplierCompanyInn, entity }) => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    watch,
    formState: { errors }
  } = useForm<CompanyBranchInputs>({
    defaultValues: {
      kpp: entity?.kpp,
      name: entity?.name
    }
  })

  const [company, setCompany] = useState<CompanyData>()

  const kppQuery = watch('kpp')

  const [addCompanyBranch, { loading: addLoading }] = useAddCompanyBranchMutation()
  const [updateCompanyBranch, { loading: updateLoading }] = useUpdateCompanyBranchMutation()

  const [throttledKppQuery] = useThrottledState(kppQuery, 500)
  const suggestions = useCompanyByKppSuggestions(supplierCompanyInn, throttledKppQuery, 'LEGAL')

  // при выборе компании устанавливаем её имя в поле компании
  useEffect(() => {
    if (!company) return
    setValue('kpp', getCompanyName(company), {
      shouldValidate: true
    })
  }, [company, setValue])

  const onSubmit: SubmitHandler<CompanyBranchInputs> = async (data) => {
    if (!entity) {
      await addCompanyBranch({
        variables: {
          input: {
            supplierCompany: supplierCompany.toString(),
            name: getCompanyName(company) || data?.name,
            kpp: company?.kpp || data?.kpp
          }
        }
      })
        .then(() => {
          if (onDone) onDone()
        })
        .catch((err: ApolloError) => {
          handleBackendErrorsToForm<CompanyBranchInputs>(err, (fieldPath, textError) => {
            setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
          })
        })
    } else {
      await updateCompanyBranch({
        variables: {
          input: {
            id: entity?._id.toString(),
            name: getCompanyName(company) || data?.name || undefined,
            kpp: company?.kpp || data?.kpp || undefined
          }
        }
      })
        .then(() => {
          if (onDone) onDone()
        })
        .catch((err: ApolloError) => {
          handleBackendErrorsToForm<CompanyBranchInputs>(err, (fieldPath, textError) => {
            setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
          })
        })
    }
  }

  return (
    <section className='md:w-[460px] p-12'>
      <h1 className='font-display text-h200 mb-12'>{entity ? 'Изменить филиал' : 'Добавить филиал'}</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 flex flex-col gap-8 relative'>
          <Suggestions<CompanyData>
            highlight={kppQuery}
            suggestions={suggestions?.map((s) => ({
              key: s._id,
              title: getCompanyName(s),
              subtitle: `КПП: ${s.kpp} ИНН: ${s.inn}`,
              payload: s,
              lineThrough: !!s.state.liquidation_date
            }))}
            select={(suggestion) => setCompany(suggestion.payload)}
          >
            <Input
              label='КПП'
              type='text'
              autoComplete='off'
              {...register('kpp', {
                validate: {
                  // требуем выбора из предложенных вариантов только если апи подсказок работает
                  fromSuggested: (value) => {
                    if (!value) return 'Требуется выбрать один из вариантов'

                    if (!!suggestions?.length || company) {
                      return value !== getCompanyName(company) ? 'Требуется выбрать один из вариантов' : true
                    } else {
                      return checkKPP(value) || 'Неверный КПП'
                    }
                  }
                }
              })}
              error={errors.kpp?.message}
            />
          </Suggestions>

          {checkKPP(kppQuery) && !suggestions?.length && (
            <Input
              label='Название филиала'
              type='text'
              {...register('name', { required: true })}
              error={errors?.name}
            />
          )}
        </div>
        <SubmitButton loading={addLoading || updateLoading}>
          {!entity ? (
            <>
              <PlusIcon className='mr-5' />
              Добавить филиал
            </>
          ) : (
            <>
              <EditIcon className='mr-5' />
              Изменить
            </>
          )}
        </SubmitButton>
      </form>
    </section>
  )
}

export default CompanyBranchForm
