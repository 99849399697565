import { FC, useMemo, useEffect } from 'react'
import PDF from './Files/PDF'
import { HTTP_METHODS } from '../../utils/downloadFile'
import useSuspenseFile from '../../hooks/useSuspenseFile'

interface PreviewProps {
  url: string
  filename: string
  requestType?: HTTP_METHODS
  mimetype?: string
  rotationPdf?: number
}

const Preview: FC<PreviewProps> = ({ url, requestType = 'POST', mimetype, rotationPdf }) => {
  const file = useSuspenseFile(url, requestType, mimetype)

  const type = file?.type

  const dataUrl = useMemo(() => {
    if (!file) return null
    return URL.createObjectURL(file)
  }, [file])

  useEffect(() => {
    return () => {
      if (!dataUrl) return
      // release memory when url is not needed any more
      URL.revokeObjectURL(dataUrl)
    }
  }, [dataUrl])

  let content = <div>Формат {type} пока не поддерживается</div>
  if (type && dataUrl) {
    switch (type) {
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
        content = <img className='object-contain max-h-[90vh] w-full max-w-[70vw]' src={dataUrl} alt={file?.name} />
        break
      case 'application/pdf':
        content = <PDF file={file} rotation={rotationPdf} />
        break
      // case msDocFormats.doc[0]:
      // case msDocFormats.doc[1]:
      // case msDocFormats.docx[0]:
      // case msDocFormats.docx[1]:
      // case msDocFormats.docx[2]:
      // case msDocFormats.xls[0]:
      // case msDocFormats.xls[1]:
      // case msDocFormats.xlsx[0]:
      // case msDocFormats.xlsx[1]:
      //   content = <MSDoc file={file} />
      //   break
    }
  }

  if (!file) content = <div>Файл не найден на сервере, ${url}</div>

  return <div className='max-h-[90vh] max-w-[90vw] overflow-y-auto'>{content}</div>
}

export default Preview
