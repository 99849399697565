import { ApolloClient, InMemoryCache, HttpLink, from, split, ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'
import retryLink from './retryLink'
import authLink from './authLink'
import cache, { jwtVar } from './cache'
import { env } from '../env'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'

const API_URL = env.REACT_APP_API_URL
if (!API_URL) throw new Error('REACT_APP_API_URL is not defined')

const BILLING_API_URL = env.REACT_APP_BILLING_API_URL
if (!BILLING_API_URL) throw new Error('REACT_APP_BILLING_API_URL is not defined')

const VERIFICATION_API_URL = env.REACT_APP_VERIFICATION_API_URL
if (!VERIFICATION_API_URL) throw new Error('REACT_APP_VERIFICATION_API_URL is not defined')

const NOTIFICATION_API_URL = env.REACT_APP_NOTIFICATION_API_URL
if (!NOTIFICATION_API_URL) throw new Error('REACT_APP_NOTIFICATION_API_URL is not defined')

const COMMENTS_API_URL = env.REACT_APP_COMMENTS_API_URL
if (!COMMENTS_API_URL) throw new Error('REACT_APP_COMMENTS_API_URL is not defined')

const COMMENTS_WS_API_URL = env.REACT_APP_COMMENTS_WS_API_URL
if (!COMMENTS_WS_API_URL) throw new Error('REACT_APP_COMMENTS_WS_API_URL is not defined')

const RISKS_API_URL = env.REACT_APP_RISKS_API_URL
if (!RISKS_API_URL) throw new Error('REACT_APP_RISKS_API_URL is not defined')

const TASKS_API_URL = env.REACT_APP_TASKS_API_URL
if (!TASKS_API_URL) throw new Error('REACT_APP_TASKS_API_URL is not defined')

const TASKS_WS_API_URL = env.REACT_APP_TASKS_WS_API_URL
if (!TASKS_WS_API_URL) throw new Error('REACT_APP_TASKS_WS_API_URL is not defined')

const HISTORY_API_URL = env.REACT_APP_HISTORY_API_URL
if (!HISTORY_API_URL) throw new Error('REACT_APP_HISTORY_API_URL is not defined')

const DOCUMENT_GENERATOR_API_URL = env.REACT_APP_DOCUMENT_GENERATOR_API_URL
if (!DOCUMENT_GENERATOR_API_URL) throw new Error('REACT_APP_DOCUMENT_GENERATOR_API_URL is not defined')

// TODO:
// split graphql backend link by operation type:
// for queries use batch link
// for other operations use upload link
// const graphqlBackendLink = split(
//   ({ query }) => {
//     const { kind, operation } = query.definitions[0] as any
//     return kind === 'OperationDefinition' && operation === 'query'
//   },
//   new BatchHttpLink({ uri: API_URL }),
//   createUploadLink({ uri: API_URL })
// )
const graphqlBackendLink = createUploadLink({ uri: API_URL })

const graphqlBillingLink = new HttpLink({ uri: BILLING_API_URL })
const graphqlVerificationLink = new HttpLink({ uri: VERIFICATION_API_URL })
const graphqlNotificationLink = new HttpLink({ uri: NOTIFICATION_API_URL })
const graphqlCommentsLink = new HttpLink({ uri: COMMENTS_API_URL })
const graphqlCommentsWsLink = new GraphQLWsLink(
  createClient({
    url: COMMENTS_WS_API_URL,
    connectionParams: {
      authToken: `Bearer ${jwtVar()}`
    }
  })
)
const graphqlRisksLink = new HttpLink({ uri: RISKS_API_URL })
const graphqlTasksLink = new HttpLink({ uri: TASKS_API_URL })
const graphqlTasksWsLink = new GraphQLWsLink(
  createClient({
    url: TASKS_WS_API_URL,
    connectionParams: {
      authToken: `Bearer ${jwtVar()}`
    }
  })
)
const graphqlHistoryLink = new HttpLink({ uri: HISTORY_API_URL })

const renameAuthHeaderLink = setContext(async (_, { headers = {} }) => {
  const auth = headers['Authorization']
  delete headers['Authorization']
  return {
    headers: {
      ...headers,
      'X-Fera-Auth': auth
    }
  }
})

const graphqlDocumentGeneratorLink = ApolloLink.from([
  renameAuthHeaderLink,
  new HttpLink({ uri: DOCUMENT_GENERATOR_API_URL })
])

const billingOperations = new Set([
  'payments',
  'updatePayment',
  'createPayments',
  'deletePayment',
  'createPayment',
  'bankOperations',
  'bankOrders',
  'applyIncomeToCustomer',
  'customerAccount',
  'payCustomerInvoices',
  'payCustomerPenalties',
  'bankOperationsCounters',
  'dealCalculations',
  'applyBankOperationToOther',
  'cancelPaymentTransactions',
  'cancelBankOperationApply',
  'scheduleByDealParams',
  'createBankOrder',
  'deleteBankOrder',
  'rateCalculation',
  'comissionRateCalculation',
  'insuranceRateCalculation',
  'rateCalculation',
  'irrDealCalculations',
  'payPayment',
  'deletePayments',
  'portfolio',
  'feraBankAccounts',
  'fundingSource',
  'fundingSources',
  'fundingSourcesByDealId',
  'fundingSourceIdsByDealId',
  'updateDealFundingSources',
  'fundingTranches',
  'createFundingTranche',
  'deleteFundingTranche',
  'bankOrder',
  'feraBankAccounts',
  'claimSchedule',
  'dealFundingSourceIds',
  'updateTransactionCreatedAt',
  'transactionsByPaymentId',
  'paymentById',
  'transactionsByDealId',
  'bankOperationPaymentInfo',
  'bankOperationsByCustomerId',
  'dealPenaltyAmount',
  'transactionsByCustomerAccountId',
  'dealRedemptionSchedule',
  'paymentSchedule',
  'createPaymentSchedule',
  'dealPaymentSchedules',
  'dealPaymentSchedule',
  'paymentDisciplineDealPayments',
  'paymentDisciplineDealsPayments',
  'paymentsForCollectorMessages'
])
const verificationOperations = new Set([
  'findOrCreateBoVerification',
  'boVerification',
  'boVerifications',
  'accept',
  'reject',
  'updateVerification'
])
const notificationOperations = new Set([
  'dealContacts',
  'addContactToDeal',
  'removeContactFromDeal',
  'updateDealContact',
  'notifications',
  'dealContactsInfo'
])
const commentOperations = new Set(['comment', 'comments', 'createComment', 'deleteComment'])
const commentWsOperations = new Set(['commentCreated'])
const riskOperations = new Set([
  'personCheckFinmon',
  'companyCheckFinmon',
  'refreshCompanyCheckFinmon',
  'innByPassport',
  'boNalog',
  'createEquifaxReport',
  'equifaxReports',
  'applicationQuestionnaire',
  'updateApplicationQuestionnaire',
  'scoringProcess',
  'createScoringProcess',
  'updateScoringProcess',
  'refreshInn',
  'companyScoring',
  'companyScorings',
  'createCompanyScoring',
  'updateCompanyScoring'
])
const taskOperations = new Set([
  'tasks',
  'createTask',
  'updateTask',
  'deleteTask',
  'doneTask',
  'pendingTask',
  'tasksCount',
  'moveTask'
])
const documentGeneratorOperations = new Set([
  'createCommercialPDF',
  'createSpecificationsPDF',
  'createActReportPDF',
  'createInvoicePDF',
  'createCommissionContractPDF',
  'createContractPDF',
  'createRulesLeasingPDF',
  'createGuaranteeContractPDF',
  'createCollectionNoticePDF',
  'createCollectionPretensionPDF',
  'createCollectionRequirementPDF',
  'createCollectionClaimPDF',

  'createTransferActDOCX',
  'createDataProcessingConsentDOCX',
  'createConclusionDOCX',
  'createBkiConsentDOCX',
  'createPetitionDOCX'
])
const taskWsOperations = new Set(['taskCreated', 'taskUpdated', 'taskDeleted'])
const historyOperations = new Set(['history'])

const billingLink = split(
  ({ operationName }) => billingOperations.has(operationName),
  graphqlBillingLink,
  graphqlBackendLink
)
const verificationLink = split(
  ({ operationName }) => verificationOperations.has(operationName),
  graphqlVerificationLink,
  billingLink
)
const notificationLink = split(
  ({ operationName }) => notificationOperations.has(operationName),
  graphqlNotificationLink,
  verificationLink
)
const commentLink = split(
  ({ operationName }) => commentOperations.has(operationName),
  graphqlCommentsLink,
  notificationLink
)
const commentWsLink = split(
  ({ operationName }) => commentWsOperations.has(operationName),
  graphqlCommentsWsLink,
  commentLink
)
const riskLink = split(({ operationName }) => riskOperations.has(operationName), graphqlRisksLink, commentWsLink)
const taskLink = split(({ operationName }) => taskOperations.has(operationName), graphqlTasksLink, riskLink)
const taskWsLink = split(({ operationName }) => taskWsOperations.has(operationName), graphqlTasksWsLink, taskLink)
const historyLink = split(({ operationName }) => historyOperations.has(operationName), graphqlHistoryLink, taskWsLink)
const generatorLink = split(
  ({ operationName }) => documentGeneratorOperations.has(operationName),
  graphqlDocumentGeneratorLink,
  historyLink
)

const client = new ApolloClient({
  link: from([retryLink, authLink, generatorLink]),
  cache
})

const authClient = new ApolloClient({
  link: new HttpLink({ uri: API_URL }),
  cache: new InMemoryCache()
})

export default client
export { authClient }
