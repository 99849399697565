import { FC } from 'react'
import { DocumentTypeEntity } from '../../graphql/schema'
import DocumentManager from '../../components/DocumentManager'
import { useParams } from 'react-router-dom'

const Documents: FC = () => {
  const { id } = useParams<'id'>()
  if (!id) return null

  return <DocumentManager entityId={parseInt(id)} entityType={DocumentTypeEntity.CustomerCompany} />
}

export default Documents
