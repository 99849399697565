import { forwardRef, ComponentPropsWithoutRef } from 'react'
import { FieldError } from 'react-hook-form'
import { errorToString } from '../../../utils/errorToString'
import c from 'clsx'
import { IMaskInput, ReactMaskOpts } from 'react-imask'

interface MaskedInvoiceInputProps extends Omit<ComponentPropsWithoutRef<'input'>, 'onChange'> {
  label?: string
  error?: string | FieldError
  value?: string
  onChange: (e: { target: { value: string } }) => void
  contractNumber: string
}

const invoiceMaskOptions = (contractNumber: string): ReactMaskOpts => ({
  mask: `СЧТ${contractNumber.replaceAll('0', '\\0')}-00`,
  lazy: false
})

const MaskedInvoiceInput = forwardRef<HTMLInputElement, MaskedInvoiceInputProps>(function Input(props, ref) {
  const { label, children, error, onChange, value, placeholder, inputMode, contractNumber } = props

  return (
    <div>
      {!!label && <div className='inp-label text-p350 mb-5'>{label}</div>}

      <label
        className={c(
          'group relative block rounded-xl bg-white-0 ring-1 ring-grayscale-400 focus-within:ring-red-100 hover:ring-grayscale-250 hover:focus-within:ring-red-100',
          !!error && 'ring-1 ring-red-100'
        )}
      >
        <IMaskInput
          value={value}
          // Необходимо перед каждым нулем в номере дл указывать символы //, иначе маска посчитает нули как места, куда можно подставить любую цифру
          // https://imask.js.org/guide.html
          {...invoiceMaskOptions(contractNumber)}
          inputRef={ref}
          placeholder={placeholder}
          inputMode={inputMode}
          className='w-full border-none bg-transparent px-10 py-7 tabular-nums placeholder-grayscale-250 outline-none transition-opacity focus:ring-0'
          onAccept={(v) => {
            onChange && onChange({ target: { value: v } })
          }}
        />
        {children}
      </label>
      {error && <div className='text-p450 pl-4 pt-2 text-base-red'>{errorToString(error)}</div>}
    </div>
  )
})

export default MaskedInvoiceInput
