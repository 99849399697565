import { FC, useMemo } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import {
  DealStatus,
  RetroBonusKind,
  TagEntity,
  useAddTagToDealMutation,
  useDealByIdQuery,
  useFeraBankAccountsQuery,
  useRemoveTagFromDealMutation
} from '../../graphql/schema'

import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'
import { ReactComponent as AppIcon } from '../../svg/icons/application.svg'
import EditDealForm from './EditDealForm'
import useNodes from '../../hooks/useNodes'
import { dateFormatter } from '../../utils/dateFormatter'
import getFullName from '../../utils/getFullName'
import { getFormattedContractNumber } from '../../utils/contractUtils'
import useAccessCheck from '../../hooks/useAccessCheck'
import { useReactiveVar } from '@apollo/client'
import dealFormOpenState from './state'
import SuspenseModal from '../../components/SuspenseModal'
import CopyInn from '../../components/CopyInn'
import Tags from '../../components/Tags'
import DealStatusSwitcher from './DealStatusSwitcher'
import Avatar from '../../components/Avatar'
import {
  EntityBar,
  EntityBarItem,
  EntityHeader,
  EntityInfo,
  EntityName,
  EntityNav,
  EntityNavItem
} from '../../components/EntityPage/EntityHeader'
import PrimaryButton from '../../components/UI/PrimaryButton'
import PrimaryButtonLink from '../../components/UI/PrimaryButtonLink'
import { EntityPage } from '../../components/EntityPage/EntityPage'

const Deal: FC = () => {
  const { id } = useParams<'id'>()
  const _id = id ? parseInt(id) : undefined
  const {
    data,
    refetch,
    loading: loading
  } = useDealByIdQuery({
    variables: { id: `${id}` },
    skip: !id,
    returnPartialData: true,
    notifyOnNetworkStatusChange: true
  })
  const hasAccessCommissionView = useAccessCheck('deal.tab.agent_commission.view')

  const { data: feraBankAccountsData, loading: bankAccountsLoading } = useFeraBankAccountsQuery()

  const dealFormOpen = useReactiveVar(dealFormOpenState)

  const deal = data?.deal

  const pledgeHolder = data?.deal?.pledgeHolder

  const tags = useNodes(deal?.tags?.edges)

  const feraBankAccounts = useNodes(feraBankAccountsData?.feraBankAccounts?.edges)
  const feraBankAccountName = useMemo(
    () => feraBankAccounts?.find((account) => account?.id?.toString() === deal?.feraBankAccountId)?.name || '',
    [feraBankAccounts, deal?.feraBankAccountId]
  )

  const hasAccessEditDealAfterSigning = useAccessCheck('deal.edit_after_signing')
  const hasAccessFinancesView = useAccessCheck('deal.finances.view')
  const canEdit = useMemo(() => {
    if (deal?.status) {
      if (
        [DealStatus.Signing, DealStatus.Activation, DealStatus.PaymentReception, DealStatus.Closed].includes(
          deal?.status
        )
      ) {
        return hasAccessEditDealAfterSigning
      }
      return true
    }
    return false
  }, [deal?.status, hasAccessEditDealAfterSigning])

  const [addTagToDeal] = useAddTagToDealMutation()
  const [removeTagFromDeal] = useRemoveTagFromDealMutation()

  const addTag = async (tagId: number) => {
    await addTagToDeal({
      variables: {
        input: {
          deal: `${id}`,
          tag: `${tagId}`
        }
      }
    })
  }

  const removeTag = async (tagId: number) => {
    await removeTagFromDeal({
      variables: {
        input: {
          deal: `${id}`,
          tag: `${tagId}`
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        removeTagFromDeal: {
          __typename: 'removeTagFromDealPayload',
          deal: {
            __typename: 'Deal',
            _id: parseInt(`${id}`),
            tags: {
              __typename: 'TagConnection',
              edges: tags
                .filter((tag) => tag._id !== tagId)
                .map((t) => ({
                  __typename: 'TagEdge',
                  cursor: '',
                  node: t
                }))
            }
          }
        }
      }
    })
  }

  return (
    <div className='flex-grow'>
      <EntityHeader>
        <div className='flex items-start justify-between'>
          <div>
            <EntityInfo>
              <div className='mr-6'>Сделка №{id}</div>
              {deal?.user && (
                <>
                  <Avatar width='w-8' height='h-8' className='mr-3' userId={deal.user._id} />
                  {getFullName(deal.user)}
                </>
              )}
            </EntityInfo>
            <div className='mb-6 flex items-center'>
              <EntityName
                value={deal?.customerCompany?.shortWithOpf}
                loading={loading}
                to={`/customers/${deal?.customerCompany?._id}`}
              />
              <CopyInn inn={deal?.customerCompany?.inn} />
            </div>
          </div>
          <div className='flex flex-none gap-x-4'>
            {canEdit && (
              <PrimaryButton onClick={() => dealFormOpenState(true)}>
                <EditIcon title='Редактировать' />
                Редактировать
              </PrimaryButton>
            )}
            {deal?.application && (
              <PrimaryButtonLink to={`/applications/${deal.application._id}`}>
                <AppIcon title='Открыть заявку' />
                Открыть заявку №{deal.application._id}
              </PrimaryButtonLink>
            )}
          </div>
        </div>
        <div className='mb-12 flex items-center gap-x-4'>
          <div className='relative'>{deal && !!_id && <DealStatusSwitcher id={_id} />}</div>
          {deal?.retroBonusKind === RetroBonusKind.WithRetrobonus && (
            <div className='rounded-md bg-tr-green px-5 py-3 font-medium text-dark-green'>Ретробонус</div>
          )}
          <Tags entityType={TagEntity.Deal} tags={tags} onAddTag={addTag} onRemoveTag={removeTag} />
        </div>

        {/* <LeasingSubjectCategoriesList
              categories={loading && !categories?.length ? placeholderCategories : categories}
              onEmptyClick={() => dealFormOpenState(true)}
            /> */}
        <EntityBar>
          <EntityBarItem
            title='Договор лизинга'
            loading={loading}
            value={
              deal?.contractNumber &&
              deal?.contractDate &&
              `${getFormattedContractNumber(deal.contractNumber)} от ${dateFormatter.format(
                new Date(deal.contractDate)
              )}`
            }
            action='Создать'
            onAction={() => dealFormOpenState(true)}
          />
          <EntityBarItem
            title='Реквизиты Fera'
            loading={loading || bankAccountsLoading}
            value={feraBankAccountName}
            action='Добавить'
            onAction={() => dealFormOpenState(true)}
          />
          <EntityBarItem
            title='Залог'
            loading={loading}
            value={!pledgeHolder ? 'Отсутствует' : pledgeHolder?.shortName}
          />
        </EntityBar>
        <EntityNav>
          <EntityNavItem title='Общая информация' to='' />
          <EntityNavItem title='Коллекшн' to='collection' />
          <EntityNavItem title='Регуляторы' to='regulators' />
          {hasAccessFinancesView && <EntityNavItem title='Финансы' to='finances' />}
          <EntityNavItem title='Поручительство' to='guarantors' />
          <EntityNavItem title='Поставки' to='supplies' />
          {hasAccessCommissionView && <EntityNavItem title='Комиссионные' to='commission' />}
          <EntityNavItem title='Уведомления' to='notifications' />
          <EntityNavItem title='Документы' to='docs' />
        </EntityNav>
      </EntityHeader>

      {deal && (
        <SuspenseModal open={dealFormOpen} setOpen={dealFormOpenState}>
          <div className='z-10 rounded-xl bg-white-0'>
            <EditDealForm
              dealId={deal._id}
              onDone={() => {
                dealFormOpenState(false)
                refetch()
              }}
            />
          </div>
        </SuspenseModal>
      )}
      <EntityPage>
        <Outlet />
      </EntityPage>
    </div>
  )
}

export default Deal
