import * as React from 'react'
import { ReactComponent as LoadingIcon } from '../../svg/icons/loading.svg'
import { ReactComponent as FileIcon } from '../../svg/icons/fileDownload.svg'
import { useDownloadCommercial } from '../../hooks/useDownloadCommercial'

interface RecDownloadProps {
  recId: number
  fileName: string
}

const ReconciliationDownloadCommercial: React.FC<RecDownloadProps> = ({ recId, fileName }) => {
  const [downloadCommercial, loading] = useDownloadCommercial(recId, fileName)

  return (
    <div className='flex justify-end cursor-pointer text-grayscale-200'>
      {loading ? <LoadingIcon className='animate-spin' /> : <FileIcon onClick={() => downloadCommercial()} />}
    </div>
  )
}

export default ReconciliationDownloadCommercial
