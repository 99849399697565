import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDealSuppliesQuery } from '../../../graphql/schema'
import useNodes from '../../../hooks/useNodes'
import SupplyForm from './SupplyForm'
import Supply from './Supply'
import { ReactComponent as PlusIcon } from '../../../svg/ui/plus.svg'
import Columns from '../../../components/Columns'
import SuspenseModal from '../../../components/SuspenseModal'
import { Card } from '../../../components/Card.tsx'

const Supplies: FC = () => {
  const { id } = useParams<'id'>()

  const { data, refetch } = useDealSuppliesQuery({ variables: { id: id as string }, skip: !id })

  const deal = data?.deal

  const dealSupplies = useNodes(deal?.dealSupplies?.edges)

  const [formOpen, setFormOpen] = useState(false)

  if (!deal) return null

  return (
    <Card>
      <div className='grid grid-cols-2 gap-6 p-5'>
        <Columns n={2}>
          {(dealSupplies || []).reverse().map((supply, index, array) => (
            <div key={supply?._id} className={index !== array.length - 1 ? 'mb-6' : ''}>
              <Supply supply={supply} dealId={parseInt(id as string)} />
            </div>
          ))}
          <div
            key='add'
            className='flex max-h-200 cursor-pointer flex-col items-center justify-center rounded-md border-1 border-dashed border-grayscale-300 py-25 text-grayscale-200'
            onClick={() => setFormOpen(true)}
          >
            <div>
              <PlusIcon className='h-15 w-15' />
            </div>
            <div className='mt-3'>Новая поставка</div>
          </div>
        </Columns>
      </div>

      <SuspenseModal open={formOpen} setOpen={setFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <SupplyForm
            dealId={deal._id}
            onDone={() => {
              setFormOpen(false)
              refetch()
            }}
          />
        </div>
      </SuspenseModal>
    </Card>
  )
}

export default Supplies
