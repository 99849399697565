import { Controller } from 'react-hook-form'
import { IMaskInput } from 'react-imask'
import { errorToString } from '../../../utils/errorToString.ts'
import { ControlledInput } from '../../../types/controlledInput.ts'
import c from 'clsx'

interface MaskedAccountNumberInputProps {
  label?: string
  bic?: string
}

function checkAccountNumber(number: string, bic?: string): string | boolean {
  if (number.length !== 20) {
    return 'Р/С может состоять только из 20 цифр'
  } else if (bic) {
    const bicRs = bic.toString().slice(-3) + number
    let checksum = 0
    const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1]
    for (const i in coefficients) {
      checksum += coefficients[i] * (parseInt(bicRs[i]) % 10)
    }
    if (checksum % 10 === 0) {
      return true
    } else {
      return 'Р/С не принадлежит этому банку'
    }
  }
  return true
}

/**
 * Компонент для ввода расчетного счета клиента
 */
const MaskedAccountNumberInput: ControlledInput<MaskedAccountNumberInputProps> = ({ label, bic, ...props }) => (
  <Controller
    name={props.name}
    control={props.control}
    rules={{
      required: true,
      validate: (value) => checkAccountNumber(value, bic)
    }}
    render={({ field, fieldState: { error } }) => {
      return (
        <div>
          {!!label && <div className='inp-label text-p350 mb-5'>{label}</div>}
          <label
            className={c(
              'group block relative bg-white-0 ring-grayscale-400 hover:focus-within:ring-red-100 focus-within:ring-red-100 hover:ring-grayscale-250 rounded-xl ring-1',
              !!error && 'ring-red-100 ring-1'
            )}
          >
            <IMaskInput
              value={field.value}
              onPaste={(e) => field.onChange(e.clipboardData.getData('text/plain').trim())}
              onChange={(value) => field.onChange(value)}
              mask='00000000000000000000'
              placeholder='9999999999999999999'
              className='py-7 px-10 transition-opacity border-none outline-none bg-transparent focus:ring-0 w-full placeholder-grayscale-250'
            />
          </label>
          {error && <div className='text-red-150 text-p450 pl-4 pt-2'>{errorToString(error)}</div>}
        </div>
      )
    }}
  />
)

export default MaskedAccountNumberInput
