import { FC, useState } from 'react'
import { ReactComponent as PlusIcon } from '../../../svg/ui/plus.svg'
import { useParams } from 'react-router-dom'
import { useSupplierCompaniesByApplicationIdQuery } from '../../../graphql/schema'
import useNodes from '../../../hooks/useNodes'
import Columns from '../../../components/Columns'
import Supplier from './Supplier'
import SupplierForm from './SupplierForm'
import Modal from '../../../components/Modal.tsx'
import { Card } from '../../../components/Card.tsx'

const Suppliers: FC = () => {
  const { id } = useParams<'id'>()

  const { data: supplierCompaniesData } = useSupplierCompaniesByApplicationIdQuery({
    variables: { id: id as string },
    skip: !id
  })
  const applicationSuppliers = useNodes(supplierCompaniesData?.application?.supplierCompanies?.edges)

  const [formOpen, setFormOpen] = useState(false)

  if (!supplierCompaniesData) return null

  return (
    <Card>
      <div className='grid auto-rows-fr grid-cols-3 gap-6 p-5'>
        <Columns n={3}>
          {applicationSuppliers.map((supplier, index, array) => (
            <div key={supplier._id} className={index !== array.length - 1 ? 'mb-6' : ''}>
              <Supplier applicationId={supplierCompaniesData?.application?._id as number} supplierCompany={supplier} />
            </div>
          ))}

          <div
            key='add'
            className='flex cursor-pointer flex-col items-center justify-center rounded-md border-1 border-dashed border-grayscale-300 py-25 text-grayscale-200'
            onClick={() => setFormOpen(true)}
          >
            <div>
              <PlusIcon className='h-15 w-15' />
            </div>
            <div className='mt-3'>Новый поставщик</div>
          </div>
        </Columns>

        <Modal open={formOpen} setOpen={setFormOpen}>
          <div className='z-10 rounded-xl bg-white-0'>
            <SupplierForm
              applicationId={supplierCompaniesData?.application?._id as number}
              onDone={() => {
                setFormOpen(false)
              }}
            />
          </div>
        </Modal>
      </div>
    </Card>
  )
}

export default Suppliers
