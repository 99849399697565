import { FC, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useSupplierFrameContractSuspenseQuery, useUpdateSupplierFrameContractMutation } from '../../graphql/schema'
import { ReactComponent as PlusIcon } from '../../svg/icons/plus.svg'
import Input from '../../components/Input'
import SubmitButton from '../../components/SubmitButton'

interface FrameContractFormProps {
  onDone: () => void
  supplierId: number
}

interface FrameContractFormValues {
  frameContractNumber: string
  frameContractDate: string
}

const FrameContractForm: FC<FrameContractFormProps> = ({ onDone, supplierId }) => {
  const [loading, setLoading] = useState(false)
  const { data: frameContractData } = useSupplierFrameContractSuspenseQuery({
    variables: { id: supplierId.toString() }
  })
  const [updateSupplierFrameContract] = useUpdateSupplierFrameContractMutation()

  const { register, handleSubmit, formState } = useForm<FrameContractFormValues>({
    defaultValues: {
      frameContractNumber: frameContractData?.supplierCompany?.frameContractDocumentNumber,
      frameContractDate: frameContractData?.supplierCompany?.frameContractDocumentDate?.split('T').shift()
    }
  })

  const onSubmit: SubmitHandler<FrameContractFormValues> = async (data) => {
    setLoading(true)

    await updateSupplierFrameContract({
      variables: {
        input: {
          id: supplierId.toString() as string,
          frameContractDocumentDate: new Date(data.frameContractDate).toISOString(),
          frameContractDocumentNumber: data.frameContractNumber
        }
      }
    }).then(() => onDone())
  }

  return (
    <div className='p-12 md:w-[448px]'>
      <h1 className='mb-12 font-display text-h200'>Редактирование поставщика</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-14 grid grid-cols-2 gap-6'>
          <Input
            label='Номер договора поставки'
            type='text'
            {...register('frameContractNumber')}
            error={formState.errors?.frameContractNumber}
            required={true}
          />
          <Input
            label='Дата договора'
            type='date'
            {...register('frameContractDate')}
            error={formState.errors?.frameContractDate}
            required={true}
          />
        </div>
        <SubmitButton loading={loading}>
          <PlusIcon className='mr-5' /> Создать
        </SubmitButton>
      </form>
    </div>
  )
}

export default FrameContractForm
