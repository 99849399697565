import { FC } from 'react'
import { RadioGroup } from '@headlessui/react'
import parseDecimal from '../utils/parseDecimal'
import c from 'clsx'

interface CommissionRewardRateListProps {
  commissionRewardRate?: string
  onChange: (value: string) => void
  className?: string
}

const COMMISSION_REWARD_RATE_OPTIONS: string[] = ['0', '1', '2', '2,5', '3']

const CommissionRewardRateList: FC<CommissionRewardRateListProps> = ({ commissionRewardRate, onChange, className }) => {
  return (
    <RadioGroup
      className={c('flex flex-wrap gap-y-3 gap-x-5', className)}
      value={''}
      onChange={(value) => onChange(value)}
    >
      {COMMISSION_REWARD_RATE_OPTIONS.map((option, i) => (
        <RadioGroup.Option
          className={c(
            'cursor-pointer text-center py-2 px-9 border-1 border-grayscale-350 rounded-full text-xs',
            commissionRewardRate &&
              parseDecimal(commissionRewardRate) === parseDecimal(option) &&
              'bg-red-200 border-red-50'
          )}
          value={option}
          key={i}
        >
          {option}%
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  )
}

export default CommissionRewardRateList
