import { Switch } from '@headlessui/react'
import { FC, ComponentProps } from 'react'
import c from 'clsx'
import { ReactComponent as TickIcon } from '../../svg/icons/tick-xs.svg'

interface CheckboxProps {
  label?: string
  labelClassName?: string
  className?: string
  checked?: boolean
  onChange?: ComponentProps<typeof Switch>['onChange']
  name?: string
}

const Checkbox: FC<CheckboxProps> = ({ label, labelClassName, className, checked, onChange, name }) => {
  return (
    <Switch.Group>
      <div className={c('flex items-center', className)}>
        <Switch
          checked={checked}
          name={name}
          onChange={onChange}
          className={c(
            'relative overflow-hidden inline-flex flex-none h-12 w-12 mr-5 border-grayscale-300 items-center justify-center rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-grayscale-400 focus:ring-offset-2',
            checked ? 'bg-red-100' : 'bg-white-0 border-1'
          )}
        >
          <TickIcon className={c('text-white-0 transition-transform', checked ? 'translate-y-0' : 'translate-y-20')} />
        </Switch>
        <Switch.Label className={c('mr-5', labelClassName)}>{label}</Switch.Label>
      </div>
    </Switch.Group>
  )
}

export default Checkbox
