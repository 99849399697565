import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ReactComponent as PlusIcon } from '../../../svg/icons/plus.svg'
import SubmitButton from '../../../components/SubmitButton'
import { useAddAgentCommissionRewardMutation } from '../../../graphql/schema'
import { ApolloError } from '@apollo/client'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils'
import parseDecimal from '../../../utils/parseDecimal'
import PercentInput from '../../../components/Forms/Inputs/PercentInput.tsx'

type CommissionRewardFormProps = {
  sourceId: string
  sourceName?: string
  commissionRewardRate?: number
  dealId: string
  dealAmount: number
  onDone: () => void
}

type AddAgentCommissionRewardInputs = {
  commission: string
  commissionRate: string
}

const CommissionRewardForm: FC<CommissionRewardFormProps> = ({
  sourceId,
  sourceName,
  commissionRewardRate,
  dealId,
  dealAmount,
  onDone
}) => {
  const {
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    watch
  } = useForm<AddAgentCommissionRewardInputs>({
    defaultValues: {
      commission: `${commissionRewardRate ? (dealAmount / 100) * commissionRewardRate : 0}`,
      commissionRate: `${commissionRewardRate || 0}`
    }
  })

  const [addAgentCommissionRewardMutation, { loading }] = useAddAgentCommissionRewardMutation()

  const onSubmit: SubmitHandler<AddAgentCommissionRewardInputs> = async (data) => {
    if (!data) return

    await addAgentCommissionRewardMutation({
      variables: {
        input: {
          source: sourceId,
          deal: dealId,
          commissionRate: parseDecimal(data?.commissionRate)
        }
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<AddAgentCommissionRewardInputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  return (
    <section className='w-[448px] p-12'>
      <h1 className='font-display text-h200'>Добавить комиссию агенту</h1>
      {sourceName && <p className='text-p200 mt-2 mb-12'>{sourceName}</p>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid grid-cols-1 mb-12'>
          <PercentInput
            label='Комиссионное вознаграждение агенту'
            percentValue={watch('commissionRate')}
            value={watch('commission')}
            onPercentChange={(percent) => {
              setValue('commissionRate', percent)
              setValue('commission', ((dealAmount / 100) * parseDecimal(percent)).toString())
            }}
            error={errors?.commissionRate}
          />
        </div>

        <SubmitButton loading={loading}>
          <PlusIcon className='mr-5' />
          Добавить комиссию
        </SubmitButton>
      </form>
    </section>
  )
}

export default CommissionRewardForm
