import { PropsWithChildren, ReactElement, Ref, useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import { useWindowEvent } from '../hooks/useWindowEvent'
import Highlighted from './Highlighted'
import c from 'clsx'

export interface Suggestion<T = never> {
  key: string | number
  title: string
  subtitle?: string
  payload?: T
  lineThrough?: boolean
  disabled?: boolean
}

interface SuggestionsProps<T = never> {
  highlight?: string
  suggestions?: Suggestion<T>[]
  select: (option: Suggestion<T>) => void
  ref?: Ref<HTMLElement>
  initialState?: boolean
}

type Props<T> = PropsWithChildren<SuggestionsProps<T>>

const Suggestions: <T = never>(props: Props<T>) => ReactElement<Props<T>> = ({
  children,
  select,
  suggestions,
  highlight,
  initialState = false
}) => {
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      { name: 'preventOverflow', enabled: false },
      { name: 'flip', enabled: false },
      { name: 'offset', options: { offset: [0, 10] } }
    ]
  })

  const [suggestionsOpen, setSuggestionsOpen] = useState(initialState)
  // закрываем подсказки, когда пользователь кликает вне их
  useWindowEvent('mousedown', (event) => {
    if (popperElement?.contains(event.target as Node)) return
    if (referenceElement?.contains(event.target as Node)) return
    setSuggestionsOpen(false)
  })
  useEffect(() => {
    const input: HTMLInputElement | undefined | null = referenceElement?.querySelector('input')
    if (!input) return
    const handler = () => !suggestionsOpen && setSuggestionsOpen(true)
    input.addEventListener('focus', handler)
    input.addEventListener('input', handler)
    return () => {
      input.removeEventListener('focus', handler)
      input.removeEventListener('input', handler)
    }
  })
  return (
    <div ref={setReferenceElement} className='relative'>
      {children}
      {suggestions && !!suggestions.length && suggestionsOpen && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className='absolute w-full max-h-135 flex z-50'
        >
          <div className='rounded-xl w-full flex flex-col ring-1 ring-grayscale-400 pt-10 bg-white-0 shadow-lg text-grayscale-150 text-p200 max-h-full'>
            <h3 className='px-9 pb-8'>Выберите вариант или продолжите ввод</h3>
            <ul className='overflow-y-auto pb-10 scrollbar-hide'>
              {suggestions.map((s) => (
                <li
                  key={s.key}
                  onClick={() => {
                    if (s.lineThrough) return
                    select(s)
                    setSuggestionsOpen(false)
                  }}
                  className={c(
                    'hover:bg-grayscale-450 px-9 py-5 disabled:cursor-not-allowed',
                    !s.lineThrough && 'cursor-pointer',
                    s.disabled && 'pointer-events-none'
                  )}
                >
                  <div
                    className={c(
                      'text-grayscale-0 mb-1',
                      s.lineThrough && 'line-through',
                      s.disabled && 'text-grayscale-250'
                    )}
                  >
                    <Highlighted classMarkName='text-red-100 bg-transparent' text={s.title} highlight={highlight} />
                  </div>
                  {s.subtitle && (
                    <div
                      className={c(
                        'text-ellipsis overflow-hidden line-clamp-2',
                        s.lineThrough && 'line-through',
                        s.disabled && 'text-grayscale-250'
                      )}
                    >
                      <Highlighted
                        classMarkName='text-red-100 bg-transparent'
                        text={s.subtitle}
                        highlight={highlight}
                      />
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}
export default Suggestions
