import { FC, ComponentProps } from 'react'

const Badge: FC<ComponentProps<'div'>> = ({ children }) => {
  return (
    <div className='py-3 px-5 text-body-m text-labels-secondary bg-surface-primary rounded-md shadow-card'>
      {children}
    </div>
  )
}

export default Badge
