import { FC } from 'react'
import { ReactComponent as WarningIcon } from '../svg/ui/warning.svg'
import Tooltip from './Tooltip.tsx'
import { useSuppliesBySupplierCompanyIdQuery } from '../graphql/schema.tsx'
import useNodes from '../hooks/useNodes.ts'

const supplyLines = (count: number) => {
  const plural = new Intl.PluralRules('ru-RU').select(count)

  switch (plural) {
    case 'one':
      return count + ' поставка'
    case 'few':
      return count + ' поставки'
    case 'many':
      return count + ' поставок'
    default:
      return count
  }
}

interface SupplyOverdueTooltipProps {
  supplyId?: number
}

const SupplyOverdueTooltip: FC<SupplyOverdueTooltipProps> = ({ supplyId }) => {
  const { data: suppliesData } = useSuppliesBySupplierCompanyIdQuery({
    variables: {
      id: supplyId
    },
    skip: !supplyId
  })
  const supplies = useNodes(suppliesData?.dealSupplies?.edges)

  const { currSupp, allSupp } = supplies.reduce(
    (acc, supply) => {
      if (supply?.shippingDate) {
        if (supply?.shippingDone) {
          if (supply?.factShippingDate && new Date(supply.factShippingDate) > new Date(supply.shippingDate)) {
            acc.allSupp++
          }
        } else if (new Date(supply.shippingDate) < new Date()) {
          acc.currSupp++
          acc.allSupp++
        }
      }

      return acc
    },
    { currSupp: 0, allSupp: 0 }
  )

  if (!currSupp && !allSupp) return null

  return (
    <Tooltip
      target={<WarningIcon width={20} height={20} className={currSupp > 0 ? 'text-base-red' : 'text-yellow-500'} />}
    >
      Текущая просрочка: {supplyLines(currSupp)}
      <br />
      Всего просрочено: {supplyLines(allSupp)}
    </Tooltip>
  )
}

export default SupplyOverdueTooltip
