import { FC, useState } from 'react'
import { ReactComponent as ClipIcon } from '../../../svg/icons/paperclip.svg'
import { ReactComponent as TrashIcon } from '../../../svg/icons/trash.svg'
import { ReactComponent as SpecIcon } from '../../../svg/icons/deals.svg'
import SuspenseModal from '../../../components/SuspenseModal'
import SpecTemplateForm from './SpecTemplateForm'
import { SpecItem, useUpdateDealSupplySpecMutation } from '../../../graphql/schema'
import { useDropzone } from 'react-dropzone'
import { showPopup } from '../../../components/Toaster/showPopup'
import { getSpecTotals, parseSpec, Spec } from './parseSpec'
import { getDocumentText, parseDocx } from '../../../utils/docx'
import ConfirmationForm from '../../../components/ConfirmationForm'
import Modal from '../../../components/Modal'
import { formatDecimal } from '../../../utils/formatNumber'

const parseSpecFromFile = async (files: File[], currency: string): Promise<Spec> => {
  const file = files[0]

  const documentText = await parseDocx(file)
    .then((zip) => getDocumentText(zip))
    .catch(() => {
      throw new Error('Не удалось открыть файл. Пересохраните документ в формате docx.')
    })

  if (!documentText) throw new Error('Не удалось открыть файл. Пересохраните документ в формате docx.')

  const spec = await parseSpec(documentText, currency)
  if (!spec.length) throw new Error('Таблица пустая')
  return spec
}

// pluralize word строка using Intl.PluralRules
const pluralRules = new Intl.PluralRules('ru-RU')
const pluralLines = (count: number) => {
  const plural = pluralRules.select(count)
  switch (plural) {
    case 'one':
      return 'строка'
    case 'few':
    case 'many':
      return 'строки'
    default:
      return 'строк'
  }
}

const checkSpecEntry = (s: Spec[number]): boolean => {
  for (const key of Object.keys(s)) {
    if (s[key as keyof Spec[number]] === undefined) return false
  }

  return true
}

const Specification: FC<{ supplyId: number; currency: string; spec?: Spec }> = ({ supplyId, currency, spec }) => {
  const [formOpen, setFormOpen] = useState(false)
  const [specOpen, setSpecOpen] = useState(false)
  const [deletionFormOpen, setDeletionFormOpen] = useState(false)
  const [updateSpec] = useUpdateDealSupplySpecMutation()
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    // accept docx files only
    accept: { 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'] },
    multiple: false,
    noClick: true,

    onDrop: async (files) => {
      try {
        const spec = await parseSpecFromFile(files, currency)
        console.log('Распознанная спецификация', spec)

        const validSpecs = spec.filter(checkSpecEntry) as SpecItem[]
        const invalidCount = spec.length - validSpecs.length

        if (invalidCount) {
          showPopup({
            title: 'Проблемы при распознавании',
            subtitle: `В таблице найдено ${invalidCount} ${pluralLines(
              invalidCount
            )} с незаполненными полями, они исключены из результата. Убедитесь, что все позиции распознались верно`,
            autoCloseTimeout: 10000
          })
        }

        await updateSpec({
          variables: {
            input: {
              id: supplyId.toString(),
              nomenclature: validSpecs
            }
          }
        })
        setSpecOpen(true)
      } catch (e: unknown) {
        if (e instanceof Error) {
          showPopup({ title: 'Ошибка распознавания', subtitle: e.message })
        }
      }
    }
  })

  const { totalAmount, totalVat } = getSpecTotals(spec || [])

  return (
    <>
      {spec ? (
        <div className='flex w-full items-stretch justify-start rounded-xl border-1 border-grayscale-400 ui-active:ring-2 ui-active:ring-red-50'>
          <button onClick={() => setSpecOpen(true)} className='flex flex-grow items-center px-8 py-12'>
            <SpecIcon className='flex-none text-grayscale-100' />
            <div className='pl-6 text-left'>Открыть номенклатуру</div>
          </button>
          <button onClick={() => setDeletionFormOpen(true)} className='flex-none px-8 text-grayscale-250'>
            <TrashIcon width='20px' title='Удалить' />
          </button>
        </div>
      ) : (
        <div
          className='flex w-full items-center justify-start rounded-xl border-1 border-grayscale-400 px-8 py-6 ui-active:ring-2 ui-active:ring-red-50'
          data-headlessui-state={isDragActive && 'active'}
          {...getRootProps()}
        >
          <ClipIcon className='flex-none' />
          <div className='pl-6 text-left'>
            <input {...getInputProps()} />
            <button className='block' onClick={open}>
              Загрузить заполненный шаблон
            </button>
            <button className='block text-red-150' onClick={() => setFormOpen(true)}>
              Создать шаблон спецификации
            </button>
          </div>
        </div>
      )}
      <SuspenseModal open={formOpen} setOpen={setFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <SpecTemplateForm
            supplyId={supplyId}
            onDone={() => {
              setFormOpen(false)
            }}
          />
        </div>
      </SuspenseModal>
      <Modal open={specOpen} setOpen={setSpecOpen}>
        <div className='z-10 rounded-xl bg-white-0 p-6'>
          <h1 className='my-6 px-8 font-display text-h100'>Номенклатура</h1>
          <table className='w-full tabular-nums'>
            <thead>
              <tr className='group'>
                <th className='rounded-l-xl px-8 py-6'>№</th>
                <th className='px-8 py-6'>Наименование, страна происхождения</th>
                <th className='px-8 py-6'>Кол-во</th>
                <th className='px-8 py-6'>Ед.</th>
                <th className='px-8 py-6'>Цена за единицу с НДС {currency}</th>
                <th className='px-8 py-6'>НДС в сумме {currency}</th>
                <th className='rounded-r-xl px-8 py-6'>Сумма с НДС {currency}</th>
              </tr>
            </thead>
            <tbody>
              {spec?.map((entry, index) => (
                <tr key={index} className='group'>
                  <td className='rounded-l-xl px-8 py-6 group-odd:bg-grayscale-450'>{index + 1}</td>
                  <td className='px-8 py-6 group-odd:bg-grayscale-450'>{entry.name}</td>
                  <td className='px-8 py-6 text-right group-odd:bg-grayscale-450'>{entry.count}</td>
                  <td className='px-8 py-6 group-odd:bg-grayscale-450'>{entry.unit}</td>
                  <td className='px-8 py-6 text-right group-odd:bg-grayscale-450'>
                    {formatDecimal(entry.pricePerUnitCents)}
                  </td>
                  <td className='px-8 py-6 text-right group-odd:bg-grayscale-450'>{formatDecimal(entry.VATCents)}</td>
                  <td className='rounded-r-xl px-8 py-6 text-right group-odd:bg-grayscale-450'>
                    {formatDecimal(entry.totalPriceCents)}
                  </td>
                </tr>
              ))}
              <tr className='group font-medium'>
                <td className='rounded-l-xl px-8 py-6 group-odd:bg-grayscale-450' />
                <td className='px-8 py-6 text-right group-odd:bg-grayscale-450' />
                <td className='px-8 py-6 text-right group-odd:bg-grayscale-450' />
                <td className='px-8 py-6 text-right group-odd:bg-grayscale-450' />
                <td className='px-8 py-6 text-right group-odd:bg-grayscale-450'>Итого:</td>
                <td className='px-8 py-6 text-right group-odd:bg-grayscale-450'>{formatDecimal(totalVat)}</td>
                <td className='rounded-r-xl px-8 py-6 text-right group-odd:bg-grayscale-450'>
                  {formatDecimal(totalAmount)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
      <Modal open={deletionFormOpen} setOpen={setDeletionFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ConfirmationForm
            title='Удаление номенклатуры'
            onDone={() => {
              setDeletionFormOpen(false)
              updateSpec({
                variables: {
                  input: {
                    id: supplyId.toString(),
                    nomenclature: null
                  }
                }
              })
            }}
            onDismiss={() => setDeletionFormOpen(false)}
          >
            Вы действительно хотите сбросить номенклатуру?
          </ConfirmationForm>
        </div>
      </Modal>
    </>
  )
}

export default Specification
