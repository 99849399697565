import { FC } from 'react'
import { BaseHistoryEventProps } from '../..'
import { EventActedAt, EventInitiatedBy } from '../EventComponents'
import { sourceCategoryDict } from '../../../../utils/dictionaries'
import { SourceCategory } from '../../../../graphql/schema'
import UserName from '../../../UserName'

export interface DealCreatedEventProps extends BaseHistoryEventProps {
  data?: {
    bdmUserId: number
    userId: number
    name: string
    category: string
    leasingSubjectCategories: string
  }
}

const SourceCreatedEvent: FC<DealCreatedEventProps> = ({ data, actedAt, initiatedByUserId, withLine }) => {
  return (
    <div className='flex gap-6'>
      <EventActedAt date={actedAt} withLine={withLine} />
      <div>
        <div className='flex items-start gap-2'>
          <EventInitiatedBy userId={initiatedByUserId} />
          <span className='text-labels-tertiary'>создал(а) Источник {data?.name}</span>
        </div>
        <div>
          {data?.category && <>Категория: {sourceCategoryDict[data.category as SourceCategory]}</>}
          <div className='flex gap-2'>
            Менеджер Fera: {data?.userId ? <UserName userId={data.userId} /> : 'Не указан'}
          </div>
          <div className='flex gap-2'>
            Менеджер по развитию: {data?.bdmUserId ? <UserName userId={data.bdmUserId} /> : 'Не указан'}
          </div>
          <div>Категории источника: {data?.leasingSubjectCategories}</div>
        </div>
      </div>
    </div>
  )
}

export default SourceCreatedEvent
