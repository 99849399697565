import { FC, useCallback, useState } from 'react'
import {
  useDealsListQuery,
  DealsListQuery,
  DealsListDocument,
  DealsListQueryVariables,
  DealStatus
} from '../../graphql/schema'
import useScrollPagination from '../../hooks/useScrollPagination'
import SearchBar, { FilterButton } from '../../components/Search'
import { formatDecimal } from '../../utils/formatNumber'
import { dealStatusDict, supplyingStatusDict } from '../../utils/dictionaries'
import DealFilterForm, { useDealFilter } from './FilterForm'
import GridView, { GridViewConfig } from '../../components/GridView'
import { NodeType } from '../../types'
import useNodes from '../../hooks/useNodes'
import useUpdateEffect from '../../hooks/useUpdateEffect'
import { getFormattedContractNumber } from '../../utils/contractUtils'
import AvatarTooltip from '../../components/Avatar/AvatarTooltip'
import SelectedFilters from './SelectedFilters'
import CurrentUserFilter from '../Applications/CurrentUserFilter'
import { useApolloClient } from '@apollo/client'
import { dateFormatter } from '../../utils/dateFormatter'
import { textCell, moneyCell, linkCell, renderSheet, saveSheet } from '../../utils/XLSX'
import { ReactComponent as LoadingIcon } from '../../svg/icons/loading.svg'
import { ReactComponent as ZipIcon } from '../../svg/icons/zip.svg'
import useAccessCheck from '../../hooks/useAccessCheck.ts'
import { DealStatusBadge } from '../../components/DealStatus/index.tsx'
import Tooltip from '../../components/Tooltip.tsx'

type Deal = NodeType<DealsListQuery['deals']>
const dealTableConfig: GridViewConfig<Deal> = {
  grid: 'grid-cols-deal',
  columns: [
    { title: 'ID', value: '_id', sort: 'id', numeric: true },
    { title: 'Клиент', value: (d) => <span className='line-clamp-1'>{d?.customerCompany?.shortWithOpf}</span> },
    {
      title: 'Договор',
      value: (d) => getFormattedContractNumber(d.contractNumber),
      numeric: true
    },
    {
      title: 'Сумма сделки, ₽',
      titleClassName: 'text-right',
      numeric: true,
      value: (d) => <div className='text-right w-full'>{formatDecimal(d.amount)}</div>
    },
    { title: 'Отгрузка', value: (d) => d.supplyingStatus && supplyingStatusDict[d.supplyingStatus] },
    {
      title: 'МЛ',
      titleClassName: 'text-center',
      value: (d) => <AvatarTooltip className='mx-auto' userId={d?.user?._id} />,
      padding: false
    },
    {
      title: 'Статус',
      padding: false,
      value: (d) => (
        <div className='flex px-[5px]'>
          <Tooltip target={<DealStatusBadge status={d.status} />}>
            {d.status === DealStatus.Terminated && d.terminationReason}
          </Tooltip>
        </div>
      )
    }
  ],
  rows: {
    link: (d) => `/deals/${d._id}`
  },
  selectedOrder: { id: 'desc' }
}

const Deals: FC = () => {
  const { filterState, setFilterState, filterActive, variables, query, setQuery } = useDealFilter()

  const hasAccessSaveAll = useAccessCheck('save_filtered_tables_to_xlsx')

  const {
    data: dealsData,
    fetchMore,
    loading,
    refetch,
    error
  } = useDealsListQuery({
    nextFetchPolicy: 'cache-and-network',
    variables
  })

  useUpdateEffect(() => {
    refetch(variables)
  }, [variables, refetch])

  const [dumpLoading, setDumpLoading] = useState(false)
  const client = useApolloClient()
  const saveAll = useCallback(async () => {
    setDumpLoading(true)
    try {
      const { data } = await client.query<DealsListQuery, DealsListQueryVariables>({
        query: DealsListDocument,
        variables: {
          ...variables,
          first: 9999
        },
        fetchPolicy: 'no-cache'
      })

      const origin = window.location.origin

      const ws = renderSheet({
        data: data?.deals?.edges?.map((d) => d?.node as Deal) || [],
        columns: [
          { title: 'ID', width: 6, render: (d) => linkCell(`${d._id}`, `${origin}/deals/${d._id}`) },
          {
            title: 'Клиент',
            width: 50,
            render: (d) =>
              linkCell(d.customerCompany?.shortWithOpf || '', `${origin}/customers/${d.customerCompany?._id}`)
          },
          { title: 'Договор', width: 15, render: (d) => textCell(getFormattedContractNumber(d.contractNumber)) },
          { title: 'Сумма сделки, ₽', width: 15, render: (d) => moneyCell(d.amount / 100) },
          {
            title: 'Отгрузка',
            width: 15,
            render: (d) => textCell(d.supplyingStatus && supplyingStatusDict[d.supplyingStatus])
          },
          { title: 'Статус', width: 15, render: (d) => textCell(dealStatusDict[d.status]) }
        ]
      })

      saveSheet(ws, `Сделки ${dateFormatter.format(new Date())}.xlsx`, 'Сделки')
    } catch (e) {
      console.error(e)
    } finally {
      setDumpLoading(false)
    }
  }, [client, variables])

  const dealsList = useNodes(dealsData?.deals?.edges)

  const { wrapperRef: triggerRef, isFetching } = useScrollPagination(fetchMore, dealsData?.deals?.pageInfo)

  return (
    <div className='py-15 px-6 flex-grow container'>
      <div className='bg-surface-secondary rounded-xl p-5 mb-8'>
        <div className='bg-white-0 shadow-xs rounded-md'>
          <div className='flex justify-between items-start p-10 border-grayscale-400 border-b-1'>
            <div>
              <h1 className='font-display text-h100'>Сделки</h1>
            </div>
          </div>
        </div>
      </div>

      <SearchBar onChange={setQuery} query={query}>
        <CurrentUserFilter
          userId={filterState?.users[0]}
          onChange={(userId) => {
            setFilterState({
              ...filterState,
              users: userId ? [userId] : []
            })
          }}
        />
        {hasAccessSaveAll && (
          <button
            disabled={dumpLoading}
            className='w-14 flex items-center justify-center text-grayscale-200'
            onClick={saveAll}
          >
            {!dumpLoading && <ZipIcon className='hover:text-red-100' />}
            {dumpLoading && <LoadingIcon className='animate-spin' width={24} height={24} />}
          </button>
        )}
        <FilterButton active={filterActive}>
          <DealFilterForm state={filterState} onChange={setFilterState} />
        </FilterButton>
      </SearchBar>

      <SelectedFilters />

      <div className='bg-surface-secondary rounded-xl p-5' ref={triggerRef}>
        <GridView
          data={dealsList}
          config={dealTableConfig}
          loading={loading || isFetching}
          error={error?.message}
          refetch={refetch}
        />
      </div>
    </div>
  )
}

export default Deals
