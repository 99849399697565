// ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ => Общество с ограниченной ответственностью

const capitalizeFirst = (str: string) => {
  if (!str) {
    return ''
  }
  str = str.toLowerCase()
  return str.charAt(0).toUpperCase() + str.slice(1)
}

// ЧАН МАКСИМ ТИЕНОВИЧ => Чан Максим Тиенович
const capitalizeFirstInWords = (str: string) => {
  if (!str) {
    return ''
  }
  return str
    .toLowerCase()
    .replace(/\s\s/g, ' ')
    .split(' ')
    .map((word) => capitalizeFirst(word))
    .join(' ')
}

const getCompanyForm = (str: string, companyName: string, full = false) => {
  if (!str) {
    return ''
  }
  str = str
    .toUpperCase()
    .replace(companyName.toUpperCase(), '')
    .trim()
    .replace(/[^A-Za-zА-Яа-яЁё\s]/gi, '')
  if (full) {
    return capitalizeFirst(str)
  }
  return str.split(' ')[0]
}

const formatZero = (n: number) => (n < 10 ? '0' : '') + n

const getDateFormattedString = (date: Date) => {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  return `${formatZero(day)}.${formatZero(month)}.${formatZero(year)}`
}

const getDateFormattedInDocumentName = (date: Date) => {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear().toString().substring(2)
  return `${year}${formatZero(month)}${formatZero(day)}`
}

const IP_CODES = ['50102', '50101']
const isIP = (company?: { opf: { code?: string } }) =>
  company?.opf?.code ? IP_CODES.includes(company?.opf.code) : false

const getFormattedContractNumber = (contractN: string | undefined) => (contractN ? 'ДЛ' + contractN : '')
const getFormattedGuaranteeNumber = (contractN: string, count: number) => `ДПР${contractN}-${('0' + count).slice(-2)}`

const actReportDateFormatter = new Intl.DateTimeFormat('ko-KR', {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit'
})
const getFormattedActReportNumber = () => {
  const date = actReportDateFormatter.format(new Date())
  return `АОВ${date.replace(/\D/g, '')}-01`
}

export {
  capitalizeFirst,
  getDateFormattedInDocumentName,
  formatZero,
  getDateFormattedString,
  getFormattedGuaranteeNumber,
  capitalizeFirstInWords,
  isIP,
  getFormattedContractNumber,
  getFormattedActReportNumber,
  getCompanyForm
}
