import { suspend } from 'suspend-react'
import { env } from '../env.ts'

function loadYmapsScript() {
  return new Promise<void>((resolve, reject) => {
    // Check if the script is already loaded
    if (document.getElementById('ymaps-script')) {
      resolve()
      return
    }

    const script = document.createElement('script')
    script.src = `https://api-maps.yandex.ru/v3/?apikey=${API_KEY}&lang=ru_RU`
    script.id = 'ymaps-script'
    script.onload = () => resolve()
    script.onerror = reject
    document.head.appendChild(script)
  })
}

const API_KEY = env.REACT_APP_YANDEX_MAP_API_KEY
if (!API_KEY) throw new Error('REACT_APP_YANDEX_MAP_API_KEY is not defined')

const useYmaps = () => {
  const maps = suspend(async () => {
    await loadYmapsScript()
    await ymaps3.ready
    return ymaps3
  }, [])

  return maps
}

export { useYmaps }
