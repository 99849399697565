import { ComponentPropsWithoutRef, forwardRef, useEffect } from 'react'
import { FieldError } from 'react-hook-form'
import Input from '../components/Input'
import { PHONE_MASK } from '../utils/constants'
import { IMask, useIMask } from 'react-imask'

interface MaskedInputProps {
  label: string
  labelClassName?: string
  mask: string
  error?: string | FieldError
  onAccept?: (value: string) => void
}

/**
 * @deprecated Уходим от использования этого компонента. Используйте или создайте инпут с маской для конкретного случая. Например MaskedNumberInput или MaskedPhoneInput
 */
const MaskedInput = forwardRef<HTMLInputElement, ComponentPropsWithoutRef<'input'> & MaskedInputProps>(
  ({ label, labelClassName, children, error, mask, onAccept, ...props }, refEl: any) => {
    let maskOpts

    switch (mask) {
      case 'number':
        maskOpts = {
          mask: Number,
          thousandsSeparator: ' '
        }
        break
      case 'percent':
        maskOpts = {
          mask: Number,
          radix: '.',
          min: 0,
          max: 100,
          scale: 8,
          normalizeZeros: true,
          lazy: false
        }
        break
      case 'time':
        maskOpts = {
          mask: 'HH:mm',
          blocks: {
            HH: {
              mask: IMask.MaskedRange,
              from: 0,
              to: 23
            },
            mm: {
              mask: IMask.MaskedRange,
              from: 0,
              to: 59
            }
          }
        }
        break
      default:
        maskOpts = { mask }
        break
    }
    const { ref, maskRef, setValue } = useIMask<HTMLInputElement>(maskOpts, { onAccept })

    useEffect(() => {
      setValue((props?.value as string) || '')
    }, [props.value, setValue])

    return (
      <Input
        label={label}
        labelClassName={labelClassName}
        inputMode='text'
        type='text'
        error={error}
        ref={(el: HTMLInputElement) => {
          ref.current = el
          refEl && refEl(el)
        }}
        {...props}
        onPaste={(e) => {
          if (mask === PHONE_MASK) {
            // gets all numbers from buffer
            const data = e.clipboardData.getData('text/plain').match(/\d+/g)?.join('') || ''
            // get start selection position
            const selectionStart = ref.current?.selectionStart || 0
            if ((data.startsWith('8') || data.startsWith('7')) && data.length > 10 && selectionStart < 4) {
              e.preventDefault()
              if (maskRef?.current) {
                maskRef.current.value = data.substring(1)
              }
            }
          }
          props?.onPaste?.(e)
        }}
      >
        {children}
      </Input>
    )
  }
)

export default MaskedInput
