import { ApolloError, ObservableQuery, OperationVariables } from '@apollo/client'
import { CheckResult, StateChecker, StateEnum } from '../hooks/useSwitcherState'
import { from, Observable } from 'rxjs'

type CheckFunction<TData, TContext, TState extends StateEnum> = (
  data: TData,
  context: TContext,
  currentState: TState
) => CheckResult

interface SimpleQueryResult<TData> {
  loading: boolean
  data?: TData
  error?: ApolloError
}

/**
 * Utility function to bind apollo cache to useSwitcherState checker function,
 * so that check result is updated on cache change
 * @param observableQuery apollo observable query from client.watchQuery
 * @param checkFn useSwitcherState checker function
 * @param onUnsubscribe optional callback to run on unsubscribe
 * @returns observable of check result
 */
export function checkQuery<TData, TContext, TState extends StateEnum>(
  observableQuery: Observable<SimpleQueryResult<TData>>,
  checkFn: CheckFunction<TData, TContext, TState>,
  context: TContext,
  currentState: TState,
  onUnsubscribe?: () => void
): Observable<CheckResult> {
  return new Observable<CheckResult>((observer) => {
    const subscription = observableQuery.subscribe({
      next: (result) => {
        if (result.loading) {
          observer.next({ loading: true, passed: false })
        } else if (result.error) {
          observer.next({ loading: false, passed: false, message: result.error.message })
        } else if (result.data) {
          const checkResult = checkFn(result.data, context, currentState)
          observer.next({ ...checkResult, loading: false })
        }
      },
      error: (error) => {
        observer.next({ loading: false, passed: false, message: error.message })
      },
      complete: () => {
        observer.complete()
      }
    })

    return () => {
      subscription.unsubscribe()
      onUnsubscribe?.()
    }
  })
}

/**
 * A helper function to create a checker function for useSwitcherState
 * @param queryCreator function that receives context and returns apollo observable query
 * @param checkFn useSwitcherState checker function
 * @param onUnsubscribe optional callback to run on unsubscribe
 * @returns StateChecker function
 */
export function createQueryChecker<TContext, TData, TVariables extends OperationVariables, TState extends StateEnum>(
  queryWatcher: (context: TContext) => ObservableQuery<TData, TVariables>,
  checkFn: CheckFunction<TData, TContext, TState>,
  onUnsubscribe?: () => void
): StateChecker<TContext, TState> {
  return function (context, currentState) {
    const observableQuery = queryWatcher(context)
    const observable = from(observableQuery) as Observable<SimpleQueryResult<TData>>
    return checkQuery(observable, checkFn, context, currentState, onUnsubscribe)
  }
}

export function createObservableChecker<TContext, TData, TState extends StateEnum>(
  queryWatcher: (context: TContext) => Observable<SimpleQueryResult<TData>>,
  checkFn: CheckFunction<TData, TContext, TState>,
  onUnsubscribe?: () => void
): StateChecker<TContext, TState> {
  return function (context, currentState) {
    return checkQuery(queryWatcher(context), checkFn, context, currentState, onUnsubscribe)
  }
}
