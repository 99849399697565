import { useEffect, useRef, useState } from 'react'
import { DocumentTypeEntity, useDocumentsLazyQuery, DocumentsQuery } from '../../graphql/schema'
import { NodeType } from '../../types'

interface DocumentsQueryVars {
  entityIds: (number | string)[]
  entityType: DocumentTypeEntity
  folderName: string
}

const useMultipleEntityDocuments = ({ entityIds, entityType, folderName }: DocumentsQueryVars) => {
  const files = useRef<NodeType<DocumentsQuery['documents']>[]>([])
  const [loading, setLoading] = useState(false)

  const [getDocuments] = useDocumentsLazyQuery()

  useEffect(() => {
    // use Promise all to run multiple getDocuments queries at once
    // abort controller if component unmounts or if entityIds changes
    setLoading(true)
    const abortController = new AbortController()
    const signal = abortController.signal
    Promise.all(
      entityIds.map((entityId) =>
        getDocuments({
          variables: {
            filter: {
              entityType,
              type: folderName,
              entityId: `${entityId}`
            }
          },
          context: {
            fetchOptions: {
              signal
            }
          }
        })
      )
    )
      .then((res) => {
        files.current = []
        res.forEach(({ data }) => {
          data?.documents?.edges?.forEach((edge) => {
            if (edge?.node) {
              files.current.push(edge.node)
            }
          })
        })
      })
      .finally(() => {
        setLoading(false)
      })

    return () => {
      abortController.abort()
    }

    // entityIds array reference changes on every render, so we need to join it to make it a string
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityIds.join(), entityType, folderName, getDocuments])

  return [files.current, loading] as const
}

export default useMultipleEntityDocuments
