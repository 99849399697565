import { FC } from 'react'
import { useContactByIdQuery, useContactsByNumberQuery } from '../../../graphql/schema'
import useNodes from '../../../hooks/useNodes'
import GridView, { GridViewConfig } from '../../../components/GridView'
import { dateFormatter } from '../../../utils/dateFormatter'
import { Card, CardHeader, CardTitle } from '../../../components/Card.tsx'

interface DuplicateContactsTableProps {
  id: string
}

export type Contact = {
  _id: number
  created: string
  fio: string
}

const DuplicateContactsTable: FC<DuplicateContactsTableProps> = ({ id }) => {
  const { data } = useContactByIdQuery({ variables: { id: id?.toString() }, skip: !id })
  const contactNumber = data?.contact?.phone

  const { data: contactsByNumberData } = useContactsByNumberQuery({
    variables: { number: contactNumber },
    skip: !contactNumber
  })
  const contactsByNumberList = useNodes(contactsByNumberData?.contacts?.edges)
  const tableContactsList = contactsByNumberList.filter((contact) => contact._id.toString() !== id)

  const duplicateContactsTableConfig: GridViewConfig<Contact> = {
    grid: 'grid-cols-auto-3',
    columns: [
      { title: 'ID', value: (c) => c._id },
      { title: 'Дата добавления', value: (c) => dateFormatter.format(new Date(c.created || '')) },
      { title: 'ФИО', value: 'fio' }
    ],
    rows: {
      link: (c) => `/contacts/${c._id}`
    }
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Дубли по телефону</CardTitle>
      </CardHeader>
      <div className='px-5 pb-5'>
        <GridView data={tableContactsList} config={duplicateContactsTableConfig} />
      </div>
    </Card>
  )
}

export default DuplicateContactsTable
