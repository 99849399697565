import { useRemoveDocumentMutation } from '../../graphql/schema'
import { useCallback } from 'react'
import { ApolloError } from '@apollo/client'
import { showPopup } from '../Toaster/showPopup.tsx'

const useRemoveDocument = (docId: number) => {
  const [removeDocument] = useRemoveDocumentMutation()

  return useCallback(async () => {
    try {
      await removeDocument({
        variables: {
          input: {
            document: docId.toString()
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          removeDocument: {
            __typename: 'removeDocumentPayload',
            document: {
              __typename: 'Document',
              _id: docId,
              loading: true
            }
          }
        },
        update: (cache, { data }) => {
          const document = data?.removeDocument?.document
          if (!document) return

          // keep document in cache while delete is in progress
          if (document?.loading) return

          // remove document from cache by id
          const normalizedId = cache.identify(document)
          cache.evict({
            id: normalizedId
          })
          cache.gc()
        }
      })
    } catch (err) {
      if ((err as ApolloError)?.graphQLErrors[0].extensions?.category === 'internal') {
        showPopup({
          title: 'Ошибка',
          subtitle: 'Документ используется в Поставке'
        })
        console.error(err)
      }
    }
  }, [docId, removeDocument])
}

export default useRemoveDocument
