import JSZip from 'jszip'

export const parseDocx = async (blob: Blob) => {
  return await JSZip.loadAsync(blob)
}

export const getDocumentText = async (zip: JSZip) => {
  const document = await zip.file('word/document.xml')?.async('string')
  return document
}

export const saveDocx = async (zip: JSZip, document: string) => {
  zip.file('word/document.xml', document)
  return await zip.generateAsync({ type: 'blob' })
}

const escapeXml = (unsafe: string) => {
  return unsafe.replace(/[<>&'"]/g, (c) => {
    switch (c) {
      case '<':
        return '&lt;'
      case '>':
        return '&gt;'
      case '&':
        return '&amp;'
      case "'":
        return '&apos;'
      case '"':
        return '&quot;'
      default:
        return c
    }
  })
}

export const renderDocxTemplate = <T extends Record<keyof T, string>>(content: string, variables: T) => {
  return content.replace(/{([^}]+)}/g, (_, key) => {
    const string = escapeXml(variables[key.trim() as keyof T] || '')
    return string
  })
}
