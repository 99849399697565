import { useState, useEffect } from 'react'
import throttle from 'lodash/throttle'

const INACTIVITY_TIMEOUT = 60 * 60 * 1000 // 1 час в миллисекундах
const events = ['mousemove', 'keydown', 'scroll', 'focus']

const useTrackInactivity = () => {
  const [inactive, setInactive] = useState(false)

  useEffect(() => {
    if (inactive) return

    let inactivityTimer: NodeJS.Timeout

    const resetInactivityTimer = throttle(() => {
      clearTimeout(inactivityTimer)
      startInactivityTimer()
    }, 200)

    const startInactivityTimer = () => {
      inactivityTimer = setTimeout(() => {
        setInactive(true)
      }, INACTIVITY_TIMEOUT)
    }

    // лайк и подписка
    events.forEach((event) => window.addEventListener(event, resetInactivityTimer))

    startInactivityTimer()

    // сброс таймера и дизлайк отписка
    return () => {
      clearTimeout(inactivityTimer)
      events.forEach((event) => window.removeEventListener(event, resetInactivityTimer))
    }
  }, [inactive])

  return [inactive, setInactive] as const
}

export default useTrackInactivity
