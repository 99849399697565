import { FC } from 'react'
import ScoringToggle from './ScoringToggle.tsx'
import ScoringInput from './ScoringInput.tsx'

const formatKey = (value: string) => value.toLowerCase().replace(/[,?]/g, '').replace(/\s/g, '_')

export type ApplicationQuestionnaire = {
  __typename: 'ApplicationQuestionnaire'
  id: string
  applicationId: number
  data?: any
  conclusion?: string
}

export interface ScoringQuestion {
  value: string
  fields?: string[]
  children?: ScoringQuestion[]
}

interface ScoringQuestionProps {
  loading?: boolean
  chapter: string
  question: ScoringQuestion
  questionnaireData?: any
  children?: ScoringQuestion[]
  onDataChange: (data: any) => void
  onDataSave: (data: any) => void
}

const ScoringQuestion: FC<ScoringQuestionProps> = ({
  loading,
  chapter,
  question,
  questionnaireData,
  children,
  onDataChange,
  onDataSave
}) => {
  const onToggleValueChange = (type: string, key: string, value: boolean) => {
    const updatedData = {
      ...questionnaireData,
      [`${type}__${formatKey(key)}`]: { ...questionnaireData?.[`${type}__${formatKey(key)}`], value }
    }
    onDataChange(updatedData)
    onDataSave(updatedData)
  }

  return (
    <>
      <div className='flex items-center justify-between gap-10'>
        <div>{question.value}</div>
        <div className='flex-none'>
          <ScoringToggle
            loading={loading}
            onChange={(value) => onToggleValueChange(formatKey(chapter), question.value, value)}
            checkedValue={questionnaireData?.[`${formatKey(chapter)}__${formatKey(question.value)}`]?.value}
          />
        </div>
      </div>

      {questionnaireData?.[`${formatKey(chapter)}__${formatKey(question.value)}`]?.value &&
        question?.fields &&
        question.fields.map((j) => (
          <div key={j} className='flex flex-col gap-5'>
            {!!j.length && <div className='text-p200 font-medium'>{j}</div>}
            <ScoringInput
              loading={loading}
              autoComplete='off'
              placeholder='Введите данные'
              onChange={(e) => {
                const type = `${formatKey(chapter)}__${formatKey(question.value)}`
                const key = formatKey(j)
                const value = e.currentTarget.value
                const updatedData = {
                  ...questionnaireData,
                  [type]: { ...questionnaireData?.[type], [key]: value }
                }
                onDataChange(updatedData)
              }}
              onAccept={() => onDataSave(questionnaireData)}
              value={questionnaireData?.[`${formatKey(chapter)}__${formatKey(question.value)}`]?.[formatKey(j)]}
            />
          </div>
        ))}

      {questionnaireData?.[`${formatKey(chapter)}__${formatKey(question.value)}`]?.value &&
        children?.length &&
        children.map((childrenQuestion) => (
          <ScoringQuestion
            key={chapter + question.value + childrenQuestion.value}
            loading={loading}
            chapter={`${chapter}__${question.value}`}
            question={childrenQuestion}
            questionnaireData={questionnaireData}
            onDataChange={onDataChange}
            onDataSave={onDataSave}
          />
        ))}
    </>
  )
}

export default ScoringQuestion
