import { FC } from 'react'
import useCurrentUser from '../hooks/useCurrentUser'
import Tasks from '../components/Tasks'
import { ReactComponent as GrafanaIcon } from '../svg/icons/grafana.svg'
import { EntityName } from '../components/EntityPage/EntityHeader'
import PrimaryButtonLink from '../components/UI/PrimaryButtonLink'
import { EntityPage } from '../components/EntityPage/EntityPage'

const Main: FC = () => {
  const currentUser = useCurrentUser()
  const name = currentUser?.name || 'Коллега'

  return (
    <div className='flex-grow'>
      <div className='border-separators-primary bg-white-0 px-6 pt-16'>
        <div className='container mx-auto'>
          <div className='flex items-start justify-between'>
            <EntityName value={`Привет, ${name}!`} />
            <PrimaryButtonLink href={`https://grafana.feratech.ru`}>
              <GrafanaIcon />
              Открыть Grafana
            </PrimaryButtonLink>
          </div>
        </div>
      </div>

      <EntityPage>
        <Tasks main />
      </EntityPage>
    </div>
  )
}

export default Main
