import { FC } from 'react'
import { ReactComponent as CheckIcon } from '../../../svg/icons/check.svg'

interface ContractStateProps {
  value: string
  withDescription?: boolean
}

const ContractState: FC<ContractStateProps> = ({ value, withDescription }) => {
  switch (value) {
    case '-':
      return (
        <>
          <span className='w-20 h-15 text-h200 flex justify-center items-center bg-[#D9D9D9]'>{value}</span>
          {withDescription && <span className='text-grayscale-150'>— Нет данных</span>}
        </>
      )
    case '0':
      return (
        <>
          <span className='w-20 h-15 text-h200 flex justify-center items-center bg-[#EAFAE4]'>
            <CheckIcon className='text-[#63B83C]' />
          </span>
          {withDescription && <span className='text-grayscale-150'>— Без просрочки</span>}
        </>
      )
    case '1':
      return (
        <>
          <span className='w-20 h-15 text-h200 flex justify-center items-center bg-[#9DBA9A] border-[1.5px] border-[#60B357]'>
            {value}
          </span>
          {withDescription && <span className='text-grayscale-150'>— Просрочка 1-5 дней</span>}
        </>
      )
    case '2':
      return (
        <>
          <span className='w-20 h-15 text-h200 flex justify-center items-center bg-[#AEBC96] border-[1.5px] border-[#8EBE3A]'>
            {value}
          </span>
          {withDescription && <span className='text-grayscale-150'>— Просрочка 6-29 дней</span>}
        </>
      )
    case '3':
      return (
        <>
          <span className='w-20 h-15 text-h200 flex justify-center items-center bg-[#FFFFC4] border-[1.5px] border-[#FFFF54]'>
            {value}
          </span>
          {withDescription && <span className='text-grayscale-150'>— Просрочка 30-59 дней</span>}
        </>
      )
    case '4':
      return (
        <>
          <span className='w-20 h-15 text-h200 flex justify-center items-center bg-[#F1D8AF] border-[1.5px] border-[#F3AE3D]'>
            {value}
          </span>
          {withDescription && <span className='text-grayscale-150'>— Просрочка 60-89 дней</span>}
        </>
      )
    case '5':
      return (
        <>
          <span className='w-20 h-15 text-h200 flex justify-center items-center bg-[#FFD0D0] border-[1.5px] border-[#FE8E8E]'>
            {value}
          </span>
          {withDescription && <span className='text-grayscale-150'>— Просрочка 90-119 дней</span>}
        </>
      )
    case '6':
      return (
        <>
          <span className='w-20 h-15 text-h200 flex justify-center items-center bg-[#FFA8A8] border-[1.5px] border-[#FF5454]'>
            {value}
          </span>
          {withDescription && <span className='text-grayscale-150'>— Просрочка 120-149 дней</span>}
        </>
      )
    case '7':
      return (
        <>
          <span className='w-20 h-15 text-h200 flex justify-center items-center bg-[#E16969] border-[1.5px] border-[#E02020]'>
            {value}
          </span>
          {withDescription && <span className='text-grayscale-150'>— Просрочка 150-179 дней</span>}
        </>
      )
    case '8':
      return (
        <>
          <span className='w-20 h-15 text-h200 flex justify-center items-center bg-[#9C4F4F] border-[1.5px] border-[#C31C00]'>
            {value}
          </span>
          {withDescription && <span className='text-grayscale-150'>— Просрочка 180-209 дней</span>}
        </>
      )
    case '9':
      return (
        <>
          <span className='w-20 h-15 text-h200 flex justify-center items-center bg-[#983F3F] border-[1.5px] border-[#9B0505]'>
            {value}
          </span>
          {withDescription && <span className='text-grayscale-150'>— Просрочка 210-239 дней</span>}
        </>
      )
    case 'A':
      return (
        <>
          <span className='w-20 h-15 text-h200 flex justify-center items-center bg-[#A51919] border-[1.5px] border-[#B35656]'>
            {value}
          </span>
          {withDescription && <span className='text-grayscale-150'>— Просрочка {`>=`} 240 дней</span>}
        </>
      )
    case 'B':
      return (
        <>
          <span className='w-20 h-15 text-h200 flex justify-center items-center bg-[#752502] text-white-0 border-[1.5px] border-[#750202]'>
            {value}
          </span>
          {withDescription && <span className='text-grayscale-150'>— Безнадежный долг (списан)</span>}
        </>
      )
    case 'C':
      return (
        <>
          <span className='w-20 h-15 text-h200 flex justify-center items-center bg-grayscale-150 text-white-0'>X</span>
          {withDescription && <span className='text-grayscale-150'>— Договор закрыт</span>}
        </>
      )
    case 'S':
      return (
        <>
          <span className='w-20 h-15 text-h200 flex justify-center items-center bg-[#B8912F] text-white-0 border-[1.5px] border-[#665323]'>
            {value}
          </span>
          {withDescription && <span className='text-grayscale-150'>— Договор продан</span>}
        </>
      )
    case 'R':
      return (
        <>
          <span className='w-20 h-15 text-h200 flex justify-center items-center bg-[#68349A] text-white-0 border-[1.5px] border-[#857494]'>
            {value}
          </span>
          {withDescription && <span className='text-grayscale-150'>— Договор рефинансирован</span>}
        </>
      )
    case 'W':
      return (
        <>
          <span className='w-20 h-15 text-h200 flex justify-center items-center bg-[#000000] text-white-0 border-[1.5px] border-[#646464]'>
            {value}
          </span>
          {withDescription && <span className='text-grayscale-150'>— Договор продан коллекторам</span>}
        </>
      )
    case 'U':
      return (
        <>
          <span className='w-20 h-15 text-h200 flex justify-center items-center bg-[#EA3323] text-white-0 border-[1.5px] border-[#000000]'>
            {value}
          </span>
          {withDescription && <span className='text-grayscale-150'>— Договор расторгнут</span>}
        </>
      )
    case 'T':
      return (
        <>
          <span className='w-20 h-15 text-h200 flex justify-center items-center bg-[#B75F29] text-white-0 border-[1.5px] border-[#DBA17D]'>
            {value}
          </span>
          {withDescription && <span className='text-grayscale-150'>— СКИ признан банкротом</span>}
        </>
      )
    case 'I':
      return (
        <>
          <span className='w-20 h-15 text-h200 flex justify-center items-center bg-[#4172B0] text-white-0 border-[1.5px] border-[#BFC5CC]'>
            {value}
          </span>
          {withDescription && <span className='text-grayscale-150'>— Прекращена передача инфо в бюро</span>}
        </>
      )
    default:
      return <div>{value}</div>
  }
}

export default ContractState
