import { FC } from 'react'
import { useSourceApplicationsCountQuery } from '../../graphql/schema'

const SourceApplicationsCount: FC<{ sourceId: string }> = ({ sourceId }) => {
  const { data } = useSourceApplicationsCountQuery({
    variables: { sourceId, first: 9999 },
    skip: !sourceId
  })
  const appCount = data?.source?.applications?.totalCount

  return <>{appCount}</>
}

export default SourceApplicationsCount
