import { FC } from 'react'
import { useUserByIdQuery } from '../../graphql/schema'
import Tooltip from '../Tooltip'
import Avatar from '../Avatar/index'
import getFullName from '../../utils/getFullName'
import c from 'clsx'

interface TaskAvatarProps {
  userId?: number
  authorId?: number
}

const TaskAvatar: FC<TaskAvatarProps> = ({ userId, authorId }) => {
  const { data: userData } = useUserByIdQuery({ variables: { id: userId?.toString() as string }, skip: !userId })
  const user = userData?.user

  const { data: authorData } = useUserByIdQuery({ variables: { id: authorId?.toString() as string }, skip: !authorId })
  const author = authorData?.user

  return (
    <Tooltip
      target={
        <div className={c('relative', !!authorId && 'mr-3')}>
          <Avatar userId={userId} className='mx-auto' />
          {!!authorId && (
            <Avatar
              userId={authorId}
              height='h-9'
              width='w-9'
              className='absolute right-2 bottom-0 border-1 border-white-0'
            />
          )}
        </div>
      }
    >
      {author ? `${getFullName(author, true)} -> ${getFullName(user, true)}` : getFullName(user)}
    </Tooltip>
  )
}

export default TaskAvatar
