import * as React from 'react'
import { Listbox } from '@headlessui/react'
import Input from '../Input'
import { ReactComponent as TickIcon } from '../../svg/icons/tick-xs.svg'

type GenericOption = {
  name: string
  _id?: any
  id?: any
}

const defaultRenderOption = (option: GenericOption) => option.name

interface InputProps<TType> {
  onChange: (value: TType[]) => void
  options: TType[]
  selectedOptions: TType[]
  renderOption?: (option: TType) => React.ReactNode
  value: string
  label: string
  placeholder?: string
}

function SelectInput<T extends GenericOption>(props: InputProps<T>) {
  const { onChange, options, renderOption, selectedOptions, value, placeholder, label } = props

  return (
    <Listbox
      multiple
      value={selectedOptions}
      onChange={(data) => {
        onChange(data)
      }}
    >
      <Listbox.Button className='text-left w-full'>
        <Input readOnly placeholder={placeholder} label={label} value={value} />
      </Listbox.Button>
      <Listbox.Options className='absolute z-50 py-5 bg-white-0 rounded-xl shadow-xs w-full mt-5 max-h-100 overflow-y-auto scrollbar-hide'>
        {options.map((opt) => (
          <Listbox.Option
            key={opt?._id || opt?.id}
            className='px-10 w-full py-5 cursor-pointer text-left hover:bg-grayscale-450 active:bg-grayscale-400'
            value={opt}
          >
            <div className='flex justify-between items-center'>
              {renderOption ? renderOption(opt) : defaultRenderOption(opt)}
              {selectedOptions.some((o) => o === opt) && (
                <div className='rounded-full bg-red-100/5 text-red-100 px-5 py-2 flex items-center ml-5'>
                  <TickIcon />
                </div>
              )}
            </div>
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  )
}

export default SelectInput
