import { FC } from 'react'
import { useFundingSourcesQuery } from '../../graphql/schema'
import GridView, { GridViewConfig } from '../../components/GridView'
import useScrollPagination from '../../hooks/useScrollPagination'
import useNodes from '../../hooks/useNodes'
import { dateTimeFormatter } from '../../utils/dateFormatter'
import { formatMoneyDecimal } from '../../utils/formatNumber'

export interface FundingSource {
  id: string
  name: string
  createdAt: string
  dealsCount?: number
  fundingTranchesCount?: number
  fundingTranchesAmountSum?: number
}

const fundingSourceTableConfig: GridViewConfig<FundingSource> = {
  grid: 'grid-cols-funding',
  columns: [
    { title: 'ID', value: 'id' },
    { title: 'Название', value: 'name' },
    {
      title: 'Дата добавления',
      value: (fundingSource) => dateTimeFormatter.format(new Date(fundingSource.createdAt!))
    },
    { title: 'Сделок', value: 'dealsCount' },
    { title: 'Количество траншей', value: 'fundingTranchesCount' },
    {
      title: 'Сумма траншей',
      value: (fundingSource) =>
        fundingSource.fundingTranchesAmountSum && formatMoneyDecimal(fundingSource.fundingTranchesAmountSum)
    }
  ],
  rows: {
    link: (c) => `/funding_sources/${c.id}`
  }
}

const FundingSources: FC = () => {
  const { data: fundingSourcesData, fetchMore, loading, error } = useFundingSourcesQuery()
  const fundingSourcesList = useNodes(fundingSourcesData?.fundingSources?.edges)
  const { wrapperRef, isFetching } = useScrollPagination(fetchMore, fundingSourcesData?.fundingSources?.pageInfo)

  return (
    <div className='py-15 px-6 flex-grow container'>
      <div className='bg-surface-secondary rounded-xl p-5 mb-8'>
        <div className='bg-white-0 shadow-xs rounded-md'>
          <div className='flex justify-between items-start p-10 border-grayscale-400 border-b-1'>
            <h1 className='font-display text-h100'>Источники финансирования</h1>
          </div>
        </div>
      </div>

      <div className='bg-surface-secondary rounded-xl p-5' ref={wrapperRef}>
        <GridView
          data={fundingSourcesList}
          config={fundingSourceTableConfig}
          loading={loading || isFetching}
          error={error?.message}
        />
      </div>
    </div>
  )
}

export default FundingSources
