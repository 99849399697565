export default function checkOgrnip(ogrnip?: string | number): string | boolean {
	if (typeof ogrnip === 'number') {
		ogrnip = ogrnip.toString()
	}
	if (!ogrnip?.length) {
		return 'ОГРНИП пуст'
	} else if (/[^0-9]/.test(ogrnip)) {
		return 'ОГРНИП может состоять только из цифр'
	} else if (ogrnip.length !== 15) {
		return 'ОГРНИП может состоять только из 15 цифр'
	} else {
		const n15 = parseInt((parseInt(ogrnip.slice(0, -1)) % 13).toString().slice(-1))
		if (n15 === parseInt(ogrnip[14])) {
			return true
		} else {
			return 'Неправильное контрольное число'
		}
	}
}
