import { FC } from 'react'
import { useParams, Outlet } from 'react-router-dom'
import { useFundingSourceQuery } from '../../graphql/schema'
import {
  EntityHeader,
  EntityInfo,
  EntityName,
  EntityNav,
  EntityNavItem
} from '../../components/EntityPage/EntityHeader'
import { EntityPage } from '../../components/EntityPage/EntityPage'

const FundingSource: FC = () => {
  const { id } = useParams<'id'>()
  const { data, loading } = useFundingSourceQuery({ variables: { id: `${id}` }, skip: !id })
  const fundingSource = data?.fundingSource

  if (!fundingSource) return null

  return (
    <div className='flex-grow'>
      <EntityHeader>
        <div className='flex justify-between items-start mb-6'>
          <div>
            <EntityInfo>Источник финансирования</EntityInfo>
            <EntityName value={fundingSource?.name} loading={loading} />
          </div>
        </div>
        <EntityNav>
          <EntityNavItem title='Общая информация' to='' />
          <EntityNavItem title='Документы' to='docs' />
        </EntityNav>
      </EntityHeader>
      <EntityPage>
        <Outlet />
      </EntityPage>
    </div>
  )
}

export default FundingSource
