import { FC } from 'react'
import Select from '../../Select'
import { FieldError } from 'react-hook-form'
import { ReactComponent as TickIcon } from '../../../svg/icons/tick-xs.svg'

export type ContactInfo = {
  _id: number
  fio: string
  phone?: string
  email?: string
  comment?: string
  verificationStatus?: string
}

interface ContactInfoSelectInputProps {
  selected: ContactInfo
  contactInfos: ContactInfo[]
  onChange: (contact: ContactInfo) => void
  label?: string
  error?: string | FieldError
  placeholder?: string
}

const ContactInfoSelectInput: FC<ContactInfoSelectInputProps> = ({
  contactInfos,
  selected,
  onChange,
  label,
  error,
  placeholder = 'Выберите контакт'
}) => (
  <div className='relative'>
    <Select
      type='text'
      label={label || 'Выбрать реквизиты поставщика'}
      placeholder={placeholder}
      value={selected?.fio}
      error={error}
      autoComplete='off'
    >
      {!contactInfos.length && (
        <li className='hover:bg-grayscale-450 px-12 py-5 cursor-pointer text-grayscale-0'>Нет контактов</li>
      )}
      {contactInfos?.map((contact) => (
        <li
          key={contact._id}
          onClick={() => onChange(contact)}
          className='hover:bg-grayscale-450 px-12 py-5 cursor-pointer'
        >
          <div className='text-grayscale-0 flex items-center'>
            {contact.fio}
            {contact.verificationStatus === 'accepted' && <div className='h-6 w-6 rounded-full ml-5 bg-green-400' />}
          </div>
          <div className='text-grayscale-150'>{contact.comment}</div>
          <div className='text-grayscale-150 mt-2 flex justify-between items-center'>
            <div className='flex items-center'>
              {!contact.email && !contact.phone && <div className='text-grayscale-300'>—</div>}
              <div>{contact.email}</div>
              {!!contact.email && <div className='w-2 h-2 rounded-full bg-grayscale-150 mx-5' />}
              <div>{contact.phone}</div>
            </div>
            {selected?._id === contact?._id && (
              <div className='rounded-full bg-red-100/5 text-red-100 px-5 py-2'>
                <TickIcon />
              </div>
            )}
          </div>
        </li>
      ))}
    </Select>
  </div>
)

export default ContactInfoSelectInput
