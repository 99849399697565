import { FC, useMemo, useState } from 'react'
import Input from '../../../components/Input'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import downloadFile from '../../../utils/downloadFileServerless'
import { ReactComponent as FileIcon } from '../../../svg/icons/fileDownload.svg'
import { UUIDLeasing } from '../../../utils/uuid'
import checkKPP from '../../../utils/kppValidator'
import { GuaranteeFormInputs } from '../models'
import MaskedInput from '../../../components/MaskedInput'
import {
  capitalizeFirst,
  capitalizeFirstInWords,
  getFormattedGuaranteeNumber,
  getDateFormattedInDocumentName,
  getDateFormattedString,
  getFormattedContractNumber,
  isIP,
  getCompanyForm
} from '../../../utils/contractUtils'
import getGenderByFio from '../../../utils/getGender'
import {
  useBoVerificationsQuery,
  useGuarantorByIdSuspenseQuery,
  useGuaranteeFormDealSuspenseQuery,
  useFeraBankAccountsSuspenseQuery,
  useCreateGuaranteeContractPdfMutation,
  Gender
} from '../../../graphql/schema'
import checkOgrnip from '../../../utils/ogrnipValidator'
import checkOgrn from '../../../utils/ogrnValidator'
import { LEADER_STATUSES_GENITIVE_MAP } from '../models/constants'
import getInclineFIO from '../../../utils/getInclineFIO'
import SubmitButton from '../../../components/SubmitButton'
import useNodes from '../../../hooks/useNodes'
import { ApolloError } from '@apollo/client'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils.ts'
import SignatorySelectInput, { KimSignatory } from '../../../components/SignatorySelectInput.tsx'
import usePersonAddress from '../../../hooks/usePersonAddress.ts'
import RequisiteInput from '../../../components/Requisites/RequisiteInput.tsx'

interface GuaranteeFormProps {
  onDone?: () => void
  dealId: string
  guarantorId: number
}

const GuaranteeContractForm: FC<GuaranteeFormProps> = ({ dealId, guarantorId, onDone }) => {
  const { data } = useGuarantorByIdSuspenseQuery({ variables: { id: `${guarantorId}` }, skip: !guarantorId })
  const [createGuaranteeContract] = useCreateGuaranteeContractPdfMutation()
  const guarantor = data?.guarantor

  const { data: dealData } = useGuaranteeFormDealSuspenseQuery({ variables: { id: dealId }, skip: !dealId })
  const deal = dealData?.deal
  const bankDetails = useNodes(guarantor?.guarantorCompany?.bankDetails?.edges)

  const { data: boVerificationData } = useBoVerificationsQuery({
    variables: { contactId: data?.guarantor?.contact?._id as number, states: ['completed', 'accepted'] },
    skip: !data?.guarantor?.contact?._id
  })
  const boVerification = boVerificationData?.boVerifications[0]

  const { data: feraBankAccountsData } = useFeraBankAccountsSuspenseQuery()
  const feraBankAccounts = useNodes(feraBankAccountsData?.feraBankAccounts?.edges)

  const companyDadata = deal?.customerCompany?.dadata?.data
  const IP: boolean = isIP(companyDadata)
  const guarantorCompanyDadataData = guarantor?.guarantorCompany?.dadata?.data
  const isGuarantorCompanyIP: boolean = isIP(guarantorCompanyDadataData)
  const leaderStatus =
    LEADER_STATUSES_GENITIVE_MAP.get(guarantorCompanyDadataData?.management?.post?.toLowerCase()) ||
    guarantorCompanyDadataData?.management?.post?.toLowerCase() ||
    ''
  const leaderStatusGenitiveWithName = (
    capitalizeFirst(leaderStatus) +
    ' ' +
    getInclineFIO(guarantorCompanyDadataData?.management?.name) +
    ', действующего на основании Устава'
  ).trim()
  const feraBankAccount = useMemo(
    () => feraBankAccounts?.find((account) => account?.id?.toString() === deal?.feraBankAccountId),
    [feraBankAccounts, deal?.feraBankAccountId]
  )

  const form = useForm<GuaranteeFormInputs>({
    defaultValues: {
      uuid: guarantor?.uuid || UUIDLeasing(),
      contractDate: deal?.contractDate?.split('T').shift(),
      leasingContractN: getFormattedContractNumber(deal?.contractNumber),
      leasingContractDate: deal?.contractDate?.split('T').shift(),
      guaranteeContractN:
        deal?.contractNumber && guarantor?.contractNumber
          ? getFormattedGuaranteeNumber(deal.contractNumber, guarantor.contractNumber)
          : '',
      company: {
        name: IP ? capitalizeFirstInWords(companyDadata.name?.full) : capitalizeFirst(companyDadata.name?.full),
        form:
          deal?.customerCompany?.fullWithOpf && deal?.customerCompany.name
            ? getCompanyForm(deal?.customerCompany?.fullWithOpf, deal?.customerCompany.name, true)
            : '',
        formShort:
          deal?.customerCompany?.shortWithOpf && deal?.customerCompany.name
            ? getCompanyForm(deal?.customerCompany?.shortWithOpf, deal?.customerCompany.name)
            : '',
        requisites: {
          inn: deal?.customerCompany.inn,
          legalAddress: companyDadata.address.value,
          kpp: !IP ? companyDadata.kpp : undefined
        }
      },
      guarantorPerson: guarantor?.contact
        ? {
            name: guarantor?.contact?.fio || ''
          }
        : undefined,
      guarantorCompany: guarantor?.guarantorCompany
        ? {
            name: isGuarantorCompanyIP
              ? capitalizeFirstInWords(guarantorCompanyDadataData.name?.full)
              : capitalizeFirst(guarantorCompanyDadataData.name?.full),
            form:
              guarantor.guarantorCompany?.fullWithOpf && guarantor.guarantorCompany?.name
                ? getCompanyForm(guarantor.guarantorCompany?.fullWithOpf, guarantor.guarantorCompany?.name, true)
                : '',
            formShort:
              guarantor.guarantorCompany?.shortWithOpf && guarantor.guarantorCompany?.name
                ? getCompanyForm(guarantor.guarantorCompany?.shortWithOpf, guarantor.guarantorCompany?.name)
                : '',
            requisites: {
              inn: guarantor.guarantorCompany.inn,
              legalAddress: guarantorCompanyDadataData.address.value,
              ogrn: guarantorCompanyDadataData.ogrn,
              kpp: !isGuarantorCompanyIP ? guarantorCompanyDadataData.kpp : undefined,
              bankName: bankDetails?.length === 1 ? bankDetails[0].name : '',
              accountNumber: bankDetails?.length === 1 ? bankDetails[0].account : '',
              correspondentNumber: bankDetails?.length === 1 ? bankDetails[0].correspondentAccount : '',
              bik: bankDetails?.length === 1 ? bankDetails[0].bic : ''
            },
            leader: !isGuarantorCompanyIP ? { name: guarantorCompanyDadataData?.management?.name } : undefined,
            leaderStatus: !isGuarantorCompanyIP
              ? capitalizeFirst(guarantorCompanyDadataData?.management?.post)
              : undefined,
            leaderStatusGenitiveWithName: !isGuarantorCompanyIP ? leaderStatusGenitiveWithName.trim() : undefined
          }
        : undefined,
      feraBankAccount: feraBankAccount
        ? {
            bankKey: feraBankAccount?.bankKey,
            bic: feraBankAccount?.bic,
            corrNumber: feraBankAccount?.corrNumber,
            name: feraBankAccount?.name,
            number: feraBankAccount?.number
          }
        : undefined,
      signatory: KimSignatory
    }
  })
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    formState: { errors }
  } = form

  const [loading, setLoading] = useState(false)
  const onSubmit: SubmitHandler<GuaranteeFormInputs> = async (data: GuaranteeFormInputs) => {
    if (loading) {
      return
    }

    const company = {
      ...data.company,
      requisites: {
        ...data.company.requisites,
        legalAddress: data.company.requisites.legalAddress || ''
      }
    }

    setLoading(true)
    try {
      if (data.guarantorPerson) {
        const createPersonContractResult = await createGuaranteeContract({
          variables: {
            input: {
              leasingContractN: data.leasingContractN,
              leasingContractDate: getDateFormattedString(new Date(data.leasingContractDate)),
              guaranteeContractN: data.guaranteeContractN,
              uuid: data.uuid,
              contractDate: getDateFormattedString(new Date(data.contractDate)),
              guarantorPerson: {
                name: data.guarantorPerson.name,
                phone: '+' + guarantor?.contact?.phone,
                issueDate: getDateFormattedString(
                  new Date(boVerification?.personInfo?.passportIssueDate?.split('T').shift())
                ),
                birthDate: getDateFormattedString(new Date(boVerification?.personInfo?.birthDate?.split('T').shift())),
                gender: getGenderByFio(data.guarantorPerson.name) as Gender,
                registerAddress: boVerification?.personInfo?.regAddress,
                email: guarantor?.contact?.email,
                seriesN: boVerification?.personInfo?.passportSeriesAndNumber,
                code: boVerification?.personInfo?.passportIssueId
              },
              company,
              feraBankAccount: deal?.feraBankAccountId ? data?.feraBankAccount : undefined,
              signatory: data.signatory
            }
          }
        }).catch((err: ApolloError) => {
          handleBackendErrorsToForm<GuaranteeFormInputs>(err, (fieldPath, textError) => {
            setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
          })
        })

        const personContractUrl = createPersonContractResult?.data?.createGuaranteeContract?.url
        if (!personContractUrl) throw new Error('Не удалось получить ссылку на договор поручительства')

        await downloadFile(
          personContractUrl,
          `${getDateFormattedInDocumentName(new Date())} Договор поручительства №${
            data.guaranteeContractN
          } от ${getDateFormattedString(new Date(data.contractDate))}.pdf`
        )
      } else if (data.guarantorCompany) {
        const createCompanyContractResult = await createGuaranteeContract({
          variables: {
            input: {
              leasingContractN: data.leasingContractN,
              leasingContractDate: getDateFormattedString(new Date(data.leasingContractDate)),
              guaranteeContractN: data.guaranteeContractN,
              uuid: data.uuid,
              contractDate: getDateFormattedString(new Date(data.contractDate)),
              guarantorCompany: {
                ...data.guarantorCompany,
                leader: data.guarantorCompany.leader?.name,
                requisites: {
                  ...data.guarantorCompany.requisites,
                  legalAddress: data.guarantorCompany.requisites.legalAddress || ''
                }
              },
              company: company,
              feraBankAccount: deal?.feraBankAccountId ? data?.feraBankAccount : undefined,
              signatory: data.signatory
            }
          }
        }).catch((err: ApolloError) => {
          handleBackendErrorsToForm<GuaranteeFormInputs>(err, (fieldPath, textError) => {
            setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
          })
        })

        const companyContractUrl = createCompanyContractResult?.data?.createGuaranteeContract?.url
        if (!companyContractUrl) throw new Error('Не удалось получить ссылку на договор поручительства')

        await downloadFile(
          companyContractUrl,
          `${getDateFormattedInDocumentName(new Date())} Договор поручительства №${
            data.guaranteeContractN
          } от ${getDateFormattedString(new Date(data.contractDate))}.pdf`
        )
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
    if (onDone) onDone()
  }

  const contactsList = useNodes(deal?.application.contacts?.edges)
  const [getContactRegAddress, contactAddressLoading, contactAddressError] = usePersonAddress()
  const [getGuarantorRegAddress, guarantorAddressLoading, guarantorAddressError] = usePersonAddress()
  if (!guarantor) return null

  return (
    <section className='w-[468px] lg:w-[1048px] xl:w-[1248px] p-12'>
      <h1 className='font-display text-h200 mb-12'>Договор поручительства</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-20 mb-20'>
          <MaskedInput
            label='Номер договора лизинга'
            type='string'
            placeholder='ДЛ220201-01'
            mask='ДЛ000000-00'
            {...register('leasingContractN', {
              required: true
            })}
            onInput={(e) => {
              const guaranteeN = getFormattedGuaranteeNumber(
                e.currentTarget.value.replace(/^ДЛ/, ''),
                guarantor.contractNumber
              )
              setValue('guaranteeContractN', guaranteeN)
            }}
            error={errors.leasingContractN}
          />
          <MaskedInput
            label='Номер договора поручительства'
            type='string'
            placeholder='ДПР220201-01-01'
            mask='ДПР000000-00-00'
            {...register('guaranteeContractN', { required: true })}
            error={errors.guaranteeContractN}
          />
          <Input
            label='Дата'
            type='date'
            readOnly
            {...register('contractDate', { required: true })}
            error={errors.contractDate}
          />
          <Input
            label='UUID'
            type='string'
            readOnly
            placeholder='bce9f9b2-843f-11ec-bf1d-12855d1c26f9-3'
            {...register('uuid', { required: true })}
            error={errors.uuid}
          />
          <Input
            readOnly
            label='Реквизиты Fera'
            type='text'
            {...register('feraBankAccount.name')}
            error={errors.feraBankAccount?.name}
          />
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-20'>
          <div className='flex flex-col gap-8 xl:mb-12'>
            <div className='flex flex-col gap-8 border-grayscale-400 border-t-1 pt-15 mt-10'>
              <h3 className='text-center font-display text-h300'>Компания</h3>
              <Input
                disabled
                label='ИНН'
                inputMode='numeric'
                autoComplete='off'
                placeholder='9999999999'
                {...register('company.requisites.inn', { required: true })}
                error={errors.company?.requisites?.inn}
              />
              <Input
                label='Наименование формы общества'
                type='string'
                placeholder='Общество с ограниченной ответственностью'
                {...register('company.form', { required: true })}
                error={errors.company?.form}
              />
              <Input
                label='Короткое наименование формы общества'
                type='string'
                placeholder='ООО'
                {...register('company.formShort', { required: true })}
                error={errors.company?.formShort}
              />
              <Input
                label='Полное наименование компании'
                type='string'
                placeholder='Компания'
                {...register('company.name', { required: true })}
                error={errors.company?.name}
              />
            </div>
          </div>
          <div className='flex flex-col gap-8 xl:mb-12'>
            <div className='flex flex-col gap-8 border-grayscale-400 border-t-1 pt-15 mt-10'>
              <h3 className='text-center font-display text-h300'>Прочие реквизиты </h3>
              <div>
                <Input
                  label='Юридический адрес'
                  type='text'
                  autoComplete='off'
                  placeholder='105005, г. Москва, Бакунинская улица, дом 4-6, строение 1'
                  {...register('company.requisites.legalAddress', { required: true })}
                  error={errors.company?.requisites?.legalAddress}
                />
                {isIP(companyDadata) && (
                  <>
                    {contactAddressLoading ? (
                      <span className='text-xs text-grayscale-250'>Загрузка...</span>
                    ) : contactAddressError ? (
                      <span className='text-xs text-red-150'>Данные не найдены</span>
                    ) : (
                      <button
                        className='text-xs flex-none text-red-50 underline decoration-dashed w-fit'
                        type='button'
                        onClick={async () => {
                          const address = await getContactRegAddress(contactsList, deal?.customerCompany.inn)
                          setValue('company.requisites.legalAddress', address)
                        }}
                      >
                        Заполнить из верификации
                      </button>
                    )}
                  </>
                )}
              </div>
              {!isIP(companyDadata) && (
                <>
                  <Input
                    label='КПП'
                    inputMode='decimal'
                    placeholder='999999999'
                    {...register('company.requisites.kpp', {
                      required: true,
                      validate: (value) => checkKPP(value) || 'Неверный КПП'
                    })}
                    error={errors.company?.requisites?.kpp}
                  />
                </>
              )}
            </div>
          </div>
          <div className='lg:col-span-2 xl:col-span-1 flex flex-col gap-8 mb-12'>
            <div className='flex flex-col gap-8 border-grayscale-400 border-t-1 pt-15 mt-10'>
              <h3 className='text-center font-display text-h300'>Поручитель</h3>
              <div className='flex flex-wrap gap-20'>
                {guarantor.contact ? (
                  <div className='flex flex-col gap-8 basis-full md:flex-basis-gap-20-2 xl:flex-basis-gap-20-3 xl:max-w-[580px]'>
                    <Input
                      label={'ФИО'}
                      placeholder='Петров Петр Петрович'
                      {...register('guarantorPerson.name', { required: true })}
                      error={errors.guarantorPerson?.name}
                    />
                  </div>
                ) : (
                  <div className='flex flex-col gap-8 basis-full md:flex-basis-gap-20-2 xl:flex-basis-gap-20-3 xl:max-w-[580px] relative'>
                    <Input
                      disabled
                      label='ИНН'
                      inputMode='numeric'
                      autoComplete='off'
                      placeholder='9999999999'
                      {...register('guarantorCompany.requisites.inn', { required: true })}
                      error={errors.guarantorCompany?.requisites?.inn}
                    />
                    <Input
                      label='Наименование формы общества'
                      type='string'
                      placeholder='Общество с ограниченной ответственностью'
                      {...register('guarantorCompany.form', { required: true })}
                      error={errors.guarantorCompany?.form}
                    />
                    <Input
                      label='Короткое наименование формы общества'
                      type='string'
                      placeholder='ООО'
                      {...register('guarantorCompany.formShort', { required: true })}
                      error={errors.guarantorCompany?.formShort}
                    />
                    <Input
                      label='Полное наименование компании'
                      type='string'
                      placeholder='Компания'
                      {...register('guarantorCompany.name', { required: true })}
                      error={errors.guarantorCompany?.name}
                    />
                    {!isIP(guarantor.guarantorCompany?.dadata?.data) && (
                      <>
                        <Input
                          label='Ответственный за компанию'
                          placeholder='Петров Петр Петрович'
                          type='string'
                          {...register('guarantorCompany.leader.name', { required: true })}
                          error={errors.guarantorCompany?.leader?.name}
                        />
                        <Input
                          label='Должность ответстветственного за компанию'
                          placeholder='Генеральный директор'
                          type='string'
                          {...register('guarantorCompany.leaderStatus', { required: true })}
                          error={errors.guarantorCompany?.leaderStatus}
                        />
                        <Input
                          label='Кто отвечает за компанию'
                          placeholder='Генерального директора Ивана Ивановича Иванова, действующего на основании Устава'
                          type='string'
                          {...register('guarantorCompany.leaderStatusGenitiveWithName', { required: true })}
                          error={errors.guarantorCompany?.leaderStatusGenitiveWithName}
                        />
                      </>
                    )}
                    <div>
                      <Input
                        label='Юридический адрес'
                        type='text'
                        autoComplete='off'
                        placeholder='105005, г. Москва, Бакунинская улица, дом 4-6, строение 1'
                        {...register('guarantorCompany.requisites.legalAddress', { required: true })}
                        error={errors.guarantorCompany?.requisites?.legalAddress}
                      />
                      {isIP(guarantor.guarantorCompany?.dadata?.data) && (
                        <>
                          {guarantorAddressLoading ? (
                            <span className='text-xs text-grayscale-250'>Загрузка...</span>
                          ) : guarantorAddressError ? (
                            <span className='text-xs text-red-150'>Данные не найдены</span>
                          ) : (
                            <button
                              className='text-xs flex-none text-red-50 underline decoration-dashed w-fit'
                              type='button'
                              onClick={async () => {
                                const address = await getGuarantorRegAddress(
                                  contactsList,
                                  guarantor?.guarantorCompany?.inn
                                )
                                setValue('guarantorCompany.requisites.legalAddress', address)
                              }}
                            >
                              Заполнить из верификации
                            </button>
                          )}
                        </>
                      )}
                    </div>
                    {!isIP(guarantor.guarantorCompany?.dadata?.data) && (
                      <>
                        <MaskedInput
                          label='КПП'
                          type='text'
                          placeholder='999999999'
                          mask='000000000'
                          {...register('guarantorCompany.requisites.kpp', {
                            required: true,
                            validate: (value) => checkKPP(value) || 'Неверный КПП'
                          })}
                          error={errors.guarantorCompany?.requisites?.kpp}
                        />
                      </>
                    )}
                    <MaskedInput
                      label={isIP(guarantor.guarantorCompany?.dadata?.data) ? 'ОГРНИП' : 'ОГРН'}
                      type='text'
                      mask='000000000000000'
                      placeholder='9999999999999'
                      {...register('guarantorCompany.requisites.ogrn', {
                        required: true,
                        validate: (value) =>
                          isIP(guarantor.guarantorCompany?.dadata?.data) ? checkOgrnip(value) : checkOgrn(value)
                      })}
                      error={errors.guarantorCompany?.requisites?.ogrn}
                    />
                    <Controller
                      name='guarantorCompany.requisites'
                      rules={{ validate: (value) => (!value?.bankName ? 'Обязательное поле' : true) }}
                      control={control}
                      render={({ field, fieldState }) => (
                        <RequisiteInput
                          onChange={(value) => field.onChange(value)}
                          value={field.value}
                          error={fieldState.error}
                          bankDetails={bankDetails}
                        />
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='lg:col-span-3 xl:col-span-1 flex flex-col gap-8 lg:mb-12'>
            <div className='border-grayscale-400 border-t-1 pt-15'>
              <h3 className='mb-8 text-center font-display text-h300'>Подписант Фера</h3>
              <Controller
                name='signatory'
                control={control}
                render={({ field }) => (
                  <SignatorySelectInput
                    selected={field.value}
                    onChange={(selected) => field.onChange(selected)}
                    error={errors?.signatory?.message}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <SubmitButton loading={loading}>
          <FileIcon className='mr-5' />
          Сгенерировать
        </SubmitButton>
      </form>
    </section>
  )
}

export default GuaranteeContractForm
