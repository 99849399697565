import { FC } from 'react'
import { useDocumentQuery } from '../../graphql/schema'
import Document from './Document'

interface DocumentByIdProps {
  id: number
  canDelete: boolean
}

const DocumentById: FC<DocumentByIdProps> = ({ id, canDelete = false }) => {
  const { data } = useDocumentQuery({
    variables: { id: id.toString() },
  })

  const doc = data?.document

  if (!doc) return null

  return (
    <Document
      doc={doc}
      canDelete={canDelete}
    />
  )
}

export default DocumentById
