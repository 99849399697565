import { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import { jwtVar, refreshTokenVar } from '../../graphql/cache'
import { TaskState, useBankOperationsCountersQuery, useCountersQuery, useTasksCountQuery } from '../../graphql/schema'
import useCurrentUser from '../../hooks/useCurrentUser'
import Avatar from '../Avatar'
import getFullName from '../../utils/getFullName'
import useAccessCheck from '../../hooks/useAccessCheck'
import SidebarButton from './SidebarButton'
import c from 'clsx'

import { ReactComponent as Logo } from '../../svg/logo.svg'
import { ReactComponent as ShortLogo } from '../../svg/shortLogo.svg'
import { ReactComponent as HomeIcon } from '../../svg/ui/home.svg'
import { ReactComponent as ApplicationIcon } from '../../svg/ui/application.svg'
import { ReactComponent as ContactIcon } from '../../svg/ui/contact.svg'
import { ReactComponent as CustomerIcon } from '../../svg/ui/customer.svg'
import { ReactComponent as SupplierIcon } from '../../svg/ui/supplier.svg'
import { ReactComponent as DealIcon } from '../../svg/ui/deal.svg'
import { ReactComponent as SourceIcon } from '../../svg/ui/source.svg'
import { ReactComponent as Finance } from '../../svg/ui/finance.svg'
import { ReactComponent as PaymentIcon } from '../../svg/ui/payment.svg'
import { ReactComponent as LogoutIcon } from '../../svg/ui/logout.svg'
import { ReactComponent as CollapseLeftIcon } from '../../svg/ui/collapse-left.svg'
import useNodes from '../../hooks/useNodes'

interface SidebarProps {
  rollup: boolean
  onRollup: () => void
}

const Sidebar: FC<SidebarProps> = ({ rollup, onRollup }) => {
  const client = useApolloClient()
  const hasAccessBankOperationManage = useAccessCheck('bank_operation.manage')
  const currentUser = useCurrentUser()
  const currentDate = new Date()
  currentDate.setSeconds(0, 0)

  const { data: countersData } = useCountersQuery()
  const rawCounters = useNodes(countersData?.counters?.edges)

  const counters = useMemo(() => {
    const data = new Map<string, number>()

    rawCounters?.forEach((node) => {
      const { name, count } = node
      data.set(name, count)
    })

    return data
  }, [rawCounters])

  const applicationsCount = counters.get('Application')
  const contactsCount = counters.get('Contact')
  const customersCount = counters.get('CustomerCompany')
  const suppliersCount = counters.get('SupplierCompany')
  const dealsCount = counters.get('Deal')
  const sourcesCount = counters.get('Source')

  const { data: bankOperationsCounters } = useBankOperationsCountersQuery()
  const { data: tasksCountData } = useTasksCountQuery({
    variables: {
      input: {
        userId: currentUser?._id,
        states: [TaskState.Pending]
      }
    },
    skip: !currentUser?._id,
    fetchPolicy: 'cache-and-network'
  })

  const pendingCount = bankOperationsCounters?.bankOperationsCounters?.pendingCount || 0
  const tasksCount = tasksCountData?.tasksCount || 0

  const logout = () => {
    jwtVar(undefined)
    refreshTokenVar(undefined)
    client.clearStore()
  }

  return (
    <div
      className={c(
        'fixed bottom-0 left-0 top-0 z-50 box-border flex flex-none flex-col bg-surface-secondary px-4 py-12 shadow-xs',
        rollup ? 'w-30' : 'w-145'
      )}
    >
      <Link className={rollup ? 'flex justify-center' : ''} to='/'>
        {rollup ? <ShortLogo className='mb-12 h-16' /> : <Logo className='mx-auto mb-12 h-16 w-36' />}
      </Link>
      <div className='mb-13' title={getFullName(currentUser)}>
        <Avatar
          className='mx-auto'
          userId={currentUser?._id}
          height={rollup ? 'h-21' : 'h-31'}
          width={rollup ? 'w-21' : 'w-31'}
        />
        {!rollup && (
          <div className='mt-5 text-center'>
            <div className='mb-1 text-labels-secondary'>Вы вошли как</div>
            <div className='font-medium text-labels-primary'>{getFullName(currentUser)}</div>
          </div>
        )}
      </div>
      <nav>
        <ul className={c(rollup && 'flex flex-col items-center')}>
          <SidebarButton to='/' title='Мой бэкофис' rollup={rollup} icon={<HomeIcon />} counterValue={tasksCount} />
          <SidebarButton
            to='/applications'
            title='Заявки'
            rollup={rollup}
            icon={<ApplicationIcon />}
            counterValue={applicationsCount}
          />
          <SidebarButton
            to='/contacts'
            title='Контакты'
            rollup={rollup}
            icon={<ContactIcon />}
            counterValue={contactsCount}
          />
          <SidebarButton
            to='/customers'
            title='Клиенты'
            rollup={rollup}
            icon={<CustomerIcon />}
            counterValue={customersCount}
          />
          <SidebarButton
            to='/suppliers'
            title='Поставщики'
            rollup={rollup}
            icon={<SupplierIcon />}
            counterValue={suppliersCount}
          />
          <SidebarButton to='/deals' title='Сделки' rollup={rollup} icon={<DealIcon />} counterValue={dealsCount} />
          <SidebarButton
            to='/sources'
            title='Источники'
            rollup={rollup}
            icon={<SourceIcon />}
            counterValue={sourcesCount}
          />
          <SidebarButton to='/funding_sources' title='Финансирование' rollup={rollup} icon={<Finance />} />
          {hasAccessBankOperationManage && (
            <SidebarButton
              to='/bank_operations'
              title='Разнесение платежей'
              rollup={rollup}
              icon={<PaymentIcon />}
              counterValue={pendingCount}
            />
          )}
        </ul>
      </nav>

      <div className={c('mt-auto', rollup && 'mx-auto')}>
        <SidebarButton title='Выйти из аккаунта' rollup={rollup} icon={<LogoutIcon />} onClick={logout} />
        <SidebarButton
          title={rollup ? 'Развернуть меню' : 'Свернуть меню'}
          rollup={rollup}
          icon={<CollapseLeftIcon className={c('text-labels-secondary duration-200', rollup && '-scale-x-100')} />}
          onClick={onRollup}
        />
      </div>
    </div>
  )
}

export default Sidebar
