import { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ReactComponent as RefreshIcon } from '../../../svg/ui/refresh.svg'
import {
  DocumentTypeEntity,
  EquifaxState,
  useBoVerificationsQuery,
  useCreateEquifaxReportMutation,
  useDataProcessingConsentDateQuery,
  useDocumentsQuery,
  useEquifaxReportsQuery,
  useUserByIdQuery
} from '../../../graphql/schema.tsx'
import { ContractData, ContractsInfo, ContractSummary, paymentOverdueStateDict } from '../../../types/bki.ts'
import getFullName from '../../../utils/getFullName.ts'
import { dateFormatter, dateTimeFormatter } from '../../../utils/dateFormatter.ts'
import Contract, { formatMoney, paymentMaxOverdue } from './Contract.tsx'
import parseDecimal from '../../../utils/parseDecimal.ts'
import { formatMoneyDecimal } from '../../../utils/formatNumber.ts'
import Client from './Client.tsx'
import c from 'clsx'
import useAccessCheck from '../../../hooks/useAccessCheck.ts'
import useNodes from '../../../hooks/useNodes.ts'
import { paymentOverdueStates } from './PaymentOverdueCounter.tsx'
import { Card, CardHeader, CardIconButton, CardMenu, CardTitle } from '../../../components/Card.tsx'
import useGetConsentPeriod from '../hooks/useGetConsentPeriod.ts'

const formattedPaymentDiscipline = (value: string) => {
  const array = Array.from(value)
  const targetIndex = array.findLastIndex((i) => i === 'C')

  return array.splice(targetIndex + 1).reverse()
}

const BKI: FC = () => {
  const { id } = useParams<'id'>()

  const hasAcessCreateEquifaxReport = useAccessCheck('contact.bki.create_equifax_report')

  const { data: equifaxReportsQuery, refetch } = useEquifaxReportsQuery({
    variables: {
      contactId: `${id}`
    },
    skip: !id
  })
  const equifaxReport = equifaxReportsQuery?.equifaxReports[0]
  const contracts: ContractData[] = useMemo(() => {
    if (!equifaxReport?.responseData?.bki_response?.response?.base_part?.contract) {
      return []
    }
    if (Array.isArray(equifaxReport?.responseData?.bki_response?.response?.base_part?.contract)) {
      return equifaxReport?.responseData?.bki_response?.response?.base_part?.contract
        .filter((c: ContractData) => !!c?.deal)
        ?.map((c: ContractData) => ({
          ...c,
          id: c?.extra_data?.find((e) => e['@id'] === '2')?.['@value']
        }))
    }
    return [
      {
        ...equifaxReport?.responseData?.bki_response?.response?.base_part?.contract,
        id: equifaxReport?.responseData?.bki_response?.response?.base_part?.contract?.extra_data?.find(
          (e: { '@id': string; '@value': string }) => e['@id'] === '2'
        )?.['@value']
      }
    ]
  }, [equifaxReport?.responseData])
  const paymentDiscipline: Map<string, string[]> = useMemo(() => {
    const targetPackage = equifaxReport?.responseData?.bki_response?.response?.extra_part?.attrs?.package?.find(
      (p: { '@name': string }) => p['@name'] === 'Платежная дисциплина за весь срок жизни кредита'
    )
    if (!targetPackage) {
      return new Map([])
    }
    if (Array.isArray(targetPackage?.section?.period?.attr)) {
      return new Map(
        targetPackage?.section?.period?.attr?.map((a: { '@v': string; '@c_i': string }) => [
          a['@c_i'],
          formattedPaymentDiscipline(a['@v'])
        ])
      )
    }
    return new Map([
      [
        targetPackage?.section?.period?.attr['@c_i'],
        formattedPaymentDiscipline(targetPackage?.section?.period?.attr['@v'])
      ]
    ])
  }, [equifaxReport?.responseData])
  const summary: Map<string, ContractSummary> = useMemo(() => {
    const credit: ContractSummary = equifaxReport?.responseData?.bki_response?.response?.extra_part?.summary?.credit
    if (!credit) {
      return new Map([])
    }
    if (Array.isArray(credit)) {
      return new Map(credit?.map((c: ContractSummary) => [c['@c_i'], c]))
    }
    return new Map([[credit['@c_i'], credit]])
  }, [equifaxReport?.responseData])

  const contractsInfo = useMemo(() => {
    const arr = new Array(9).fill(9)
    const overdueStatesByContractId: {
      [key: string]: {
        [key: string]: number
      }
    } = {}
    const result = contracts?.reduce<ContractsInfo>(
      (acc, contract) => {
        const accountStatus = contract?.extra_data?.find((d) => d['@id'] === '10')
        const sum =
          contract?.contract_amount?.sum && parseDecimal(contract?.contract_amount?.sum)
            ? parseDecimal(contract?.contract_amount?.sum)
            : 0
        const currency = contract?.contract_amount?.currency || 'RUB'
        const paymentOverdueSum =
          currency === 'RUB' &&
          summary.get(contract.id)?.cred_max_overdue &&
          parseDecimal(summary.get(contract.id)?.cred_max_overdue || '')
            ? parseDecimal(summary.get(contract.id)?.cred_max_overdue || '')
            : 0

        if (accountStatus) {
          switch (accountStatus['@value']) {
            case '0':
              // Проверяем вид участия в сделке (2 - Поручитель)
              if (contract?.deal?.ratio === '2') {
                acc.guarantee.closed.contracts.push(contract)
                acc.guarantee.closed.count++
                acc.guarantee.sumClosed[currency] = (acc.guarantee.sumClosed[currency] || 0) + sum
                break
              }
              // Проверяем категорию (8 - Гарантия)
              if (contract.deal.category === '8') {
                acc.warranty.closed.contracts.push(contract)
                acc.warranty.closed.count++
                acc.warranty.sumClosed[currency] = (acc.warranty.sumClosed[currency] || 0) + sum
                break
              }

              if (paymentOverdueSum > acc.closed.maxPaymentOverdueSum) {
                acc.closed.maxPaymentOverdueSum = paymentOverdueSum
              }
              if (paymentDiscipline.get(contract.id)?.includes('A')) {
                acc.closed.maxPaymentOverdue = 'A'
              } else {
                arr.forEach((value, i) => {
                  if (
                    paymentDiscipline.get(contract.id)?.includes((value - i).toString()) &&
                    parseInt(acc.closed.maxPaymentOverdue) < value - i
                  ) {
                    acc.closed.maxPaymentOverdue = (value - i).toString()
                  }
                })
              }

              acc.closed.count++
              acc.closed.sum[currency] = (acc.closed.sum[currency] || 0) + sum
              acc.closed.contracts.push(contract)
              break
            case '1':
            case '10':
            case '11':
            case '12':
            case '13':
            case '14':
            case '15':
            case '16':
            case '17':
            case '18':
            case '19': {
              // Проверяем вид участия в сделке (2 - Поручитель)
              if (contract?.deal?.ratio === '2') {
                acc.guarantee.active.contracts.push(contract)
                acc.guarantee.active.count++
                acc.guarantee.sumActive[currency] = (acc.guarantee.sumActive[currency] || 0) + sum
                break
              }
              // Проверяем категорию (8 - Гарантия)
              if (contract.deal.category === '8') {
                acc.warranty.active.contracts.push(contract)
                acc.warranty.active.count++
                acc.warranty.sumActive[currency] = (acc.guarantee.sumActive[currency] || 0) + sum
                break
              }

              const debtSum =
                contract?.debt?.sign === '1' && contract?.debt?.sum && parseDecimal(contract?.debt?.sum)
                  ? parseDecimal(contract?.debt?.sum)
                  : 0
              const averageSum =
                contract?.average_payment?.sum && parseDecimal(contract?.average_payment?.sum)
                  ? parseDecimal(contract?.average_payment?.sum)
                  : 0

              if (paymentOverdueSum > acc.active.maxPaymentOverdueSum) {
                acc.active.maxPaymentOverdueSum = paymentOverdueSum
              }
              if (paymentDiscipline.get(contract.id)?.includes('A')) {
                acc.active.maxPaymentOverdue = 'A'
              } else {
                arr.forEach((value, i) => {
                  if (
                    paymentDiscipline.get(contract.id)?.includes((value - i).toString()) &&
                    parseInt(acc.active.maxPaymentOverdue) < value - i
                  ) {
                    acc.active.maxPaymentOverdue = (value - i).toString()
                  }
                })
              }

              acc.active.count++
              acc.active.sum[currency] = (acc.active.sum[currency] || 0) + sum
              acc.active.debtSum[currency] = (acc.active.debtSum[currency] || 0) + debtSum
              acc.active.averageSum[currency] = (acc.active.averageSum[currency] || 0) + averageSum
              acc.active.contracts.push(contract)
              break
            }
            case '2':
              acc.sold.count++
              acc.sold.contracts.push(contract)
              break
            case '3':
              acc.badDebt.count++
              acc.badDebt.contracts.push(contract)
              break
            case '4':
              acc.refinanced.count++
              acc.refinanced.contracts.push(contract)
              break
            case '5':
              acc.soldToCollectors.count++
              acc.soldToCollectors.contracts.push(contract)
              break
            case '8':
              acc.informationStopped.count++
              acc.informationStopped.contracts.push(contract)
              break
            case '20':
              acc.terminated.count++
              acc.terminated.contracts.push(contract)
              break
            default:
              acc.other.count++
              acc.other.contracts.push(contract)
          }

          if (contract?.deal?.type === '3' || contract?.deal?.type === '13') {
            acc.microloan.count++
          }

          const paymentOverdue = (paymentDiscipline.get(contract?.id) || []).filter((v) =>
            paymentOverdueStates.includes(v)
          )
          if (paymentOverdue?.length) {
            acc.paymentOverdueDeals++
            overdueStatesByContractId[contract?.id] = {}

            paymentOverdue.forEach((i) => {
              if (acc.paymentOverdue[i]) {
                acc.paymentOverdue[i].count++
              } else {
                acc.paymentOverdue[i] = {
                  count: 1,
                  deals: 0
                }
              }
              if (overdueStatesByContractId[contract?.id][i]) {
                overdueStatesByContractId[contract?.id][i]++
              } else {
                overdueStatesByContractId[contract?.id][i] = 1
              }
            })
          }
        }
        return acc
      },
      {
        active: {
          count: 0,
          sum: {},
          debtSum: {},
          averageSum: {},
          maxPaymentOverdue: '0',
          maxPaymentOverdueSum: 0,
          contracts: []
        },
        closed: {
          count: 0,
          sum: {},
          maxPaymentOverdue: '0',
          maxPaymentOverdueSum: 0,
          contracts: []
        },
        microloan: {
          count: 0
        },
        sold: {
          count: 0,
          contracts: []
        },
        soldToCollectors: {
          count: 0,
          contracts: []
        },
        guarantee: {
          count: 0,
          active: {
            count: 0,
            contracts: []
          },
          closed: {
            count: 0,
            contracts: []
          },
          sumActive: {},
          sumClosed: {}
        },
        warranty: {
          count: 0,
          active: {
            count: 0,
            contracts: []
          },
          closed: {
            count: 0,
            contracts: []
          },
          sumActive: {},
          sumClosed: {}
        },
        terminated: {
          count: 0,
          contracts: []
        },
        refinanced: {
          count: 0,
          contracts: []
        },
        informationStopped: {
          count: 0,
          contracts: []
        },
        badDebt: {
          count: 0,
          contracts: []
        },
        other: {
          count: 0,
          contracts: []
        },
        paymentOverdueDeals: 0,
        paymentOverdue: {}
      } as ContractsInfo
    )
    Object.values(overdueStatesByContractId).forEach(
      (o) =>
        (result.paymentOverdue[paymentMaxOverdue(o).value].deals =
          result.paymentOverdue[paymentMaxOverdue(o).value].deals + 1)
    )

    return result
  }, [contracts, summary, paymentDiscipline])

  const [loading, setLoading] = useState(equifaxReport?.state === EquifaxState.InProgress)

  useEffect(() => {
    setLoading(!!equifaxReport && equifaxReport?.state === EquifaxState.InProgress)
  }, [equifaxReport])

  const { data: userByIdQuery } = useUserByIdQuery({
    variables: {
      id: `${equifaxReport?.initiatedByUserId}`
    },
    skip: !equifaxReport?.initiatedByUserId
  })
  const lastUpdateName = getFullName(userByIdQuery?.user)
  const lastUpdateDate = new Date(equifaxReport?.createdDate)

  const { data: boVerificationsQuery } = useBoVerificationsQuery({
    variables: { contactId: parseInt(id as string), states: ['completed', 'rejected', 'accepted'] },
    skip: !id
  })
  const boVerification = boVerificationsQuery?.boVerifications[0]

  const { data: contactData } = useDataProcessingConsentDateQuery({
    variables: { id: id?.toString() as string },
    skip: !id
  })
  const dataProcessingConsentDate = contactData?.contact?.dataProcessingConsentDate

  const { data: documentsData } = useDocumentsQuery({
    variables: {
      filter: {
        entityId: id as string,
        entityType: DocumentTypeEntity.Contact,
        type: 'Согласие на обработку персональных данных'
      }
    },
    skip: !id
  })
  const documents = useNodes(documentsData?.documents?.edges)
  const canCreateEquifaxReport = hasAcessCreateEquifaxReport && !!dataProcessingConsentDate && !!documents?.length

  const [createEquifaxReportMutation] = useCreateEquifaxReportMutation()
  const [getConsentPeriod, loadingConsentPeriod] = useGetConsentPeriod()

  const createEquifaxReport = async () => {
    if (loading || !boVerification || !dataProcessingConsentDate) return

    const passportSerialAndNumberArr = boVerification?.personInfo?.passportSeriesAndNumber?.split(' ') || []

    const [consentPeriod, contractDate] = await getConsentPeriod(`${id}`)

    setLoading(true)
    await createEquifaxReportMutation({
      variables: {
        contactId: boVerification.contactId,
        input: {
          lastName: boVerification?.personInfo?.lastName || '',
          birthDate: boVerification?.personInfo?.birthDate
            ? dateFormatter.format(new Date(boVerification?.personInfo?.birthDate))
            : '',
          firstName: boVerification?.personInfo?.firstName || '',
          birthPlace: boVerification?.personInfo?.birthPlace || '',
          middleName: boVerification?.personInfo?.middleName || '',
          passportNumber: passportSerialAndNumberArr[1] || '',
          passportSerial: passportSerialAndNumberArr[0] || '',
          passportIssuedBy: boVerification?.personInfo?.passportIssuedBy || '',
          passportIssueDate: boVerification?.personInfo?.passportIssueDate
            ? dateFormatter.format(new Date(boVerification?.personInfo?.passportIssueDate))
            : '',
          consentPeriod,
          consentDate: dateFormatter.format(new Date(dataProcessingConsentDate)),
          contractDate: contractDate ? dateFormatter.format(new Date(contractDate)) : undefined
        }
      }
    })
    await refetch()
  }

  return (
    <div className='grid grid-cols-3 gap-6'>
      <div className='col-span-1 flex flex-col gap-6'>
        <Card>
          <CardHeader>
            <CardTitle>Кредиты</CardTitle>
          </CardHeader>

          <div className='px-5 pb-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              {!loading && !!equifaxReport ? (
                <>
                  {contracts?.length ? (
                    <div>
                      <div className='flex items-center justify-around pt-10 text-h200 font-medium'>
                        <div className='flex flex-col items-center text-[#16A34A]'>
                          <div>{contractsInfo?.active?.count}</div>
                          <div>активных</div>
                        </div>
                        <div className='flex flex-col items-center text-grayscale-150'>
                          <div>{contractsInfo?.closed?.count}</div>
                          <div>закрытых</div>
                        </div>
                        <div className='flex flex-col items-center text-grayscale-150'>
                          <div>{contractsInfo?.sold?.count + contractsInfo?.soldToCollectors?.count}</div>
                          <div>продано</div>
                        </div>
                      </div>

                      <div className='mt-8 border-t border-grayscale-400'>
                        <div className='mt-8 px-12 font-medium'>Действующие кредиты</div>

                        <div className='mt-6 flex gap-6 px-5'>
                          <table className='w-full'>
                            <tbody>
                              {Object.keys(contractsInfo?.active?.sum).map((key) => (
                                <tr key={key} className='group'>
                                  <td className='p-7 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                    Сумма активных
                                  </td>
                                  <td className='p-7 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                    {formatMoney(contractsInfo?.active?.sum[key], key)}
                                  </td>
                                </tr>
                              ))}
                              {Object.keys(contractsInfo?.active?.debtSum).map((key) => (
                                <tr key={key} className='group'>
                                  <td className='p-7 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                    Текущая задолженность
                                  </td>
                                  <td className='p-7 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                    {formatMoney(contractsInfo?.active?.debtSum[key], key)}
                                  </td>
                                </tr>
                              ))}
                              {Object.keys(contractsInfo?.active?.averageSum).map((key) => (
                                <tr key={key} className='group'>
                                  <td className='p-7 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                    Ежемесячные платежи
                                  </td>
                                  <td className='p-7 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                    {formatMoney(contractsInfo?.active?.averageSum[key], key)}
                                  </td>
                                </tr>
                              ))}
                              <tr className='group'>
                                <td className='p-7 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                  Макс. просрочка в днях
                                </td>
                                <td className='p-7 px-8 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                  {paymentMaxOverdue({ [contractsInfo?.active?.maxPaymentOverdue]: 1 })?.days}
                                </td>
                              </tr>
                              <tr className='group'>
                                <td className='p-7 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                  Макс. просрочка в рублях
                                </td>
                                <td className='p-7 px-8 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                  {formatMoneyDecimal(contractsInfo?.active.maxPaymentOverdueSum)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className='mt-8 border-t border-grayscale-400'>
                        <div className='mt-8 px-12 font-medium'>Закрытые кредиты</div>

                        <div className='mt-6 flex gap-6 px-5'>
                          <table className='w-full'>
                            <tbody>
                              {Object.keys(contractsInfo?.closed?.sum).map((key) => (
                                <tr key={key} className='group'>
                                  <td className='p-7 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                    Сумма закрытых
                                  </td>
                                  <td className='p-7 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                    {formatMoney(contractsInfo?.closed?.sum[key], key)}
                                  </td>
                                </tr>
                              ))}
                              <tr className='group'>
                                <td className='p-7 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                  Макс. просрочка в днях
                                </td>
                                <td className='p-7 px-8 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                  {paymentMaxOverdue({ [contractsInfo?.closed?.maxPaymentOverdue]: 1 })?.days}
                                </td>
                              </tr>
                              <tr className='group'>
                                <td className='p-7 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                  Макс. просрочка в рублях
                                </td>
                                <td className='p-7 px-8 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                  {formatMoneyDecimal(contractsInfo?.closed.maxPaymentOverdueSum)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className='mt-8 border-t border-grayscale-400'>
                        <div className='mt-8 px-12 font-medium'>Микрозаймы</div>

                        <div className='mt-6 flex gap-6 px-5'>
                          <table className='w-full'>
                            <tbody>
                              <tr className='group'>
                                <td className='p-7 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                  Всего микрозаймов
                                </td>
                                <td className='p-7 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                  {contractsInfo?.microloan.count}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className='mt-8 border-t border-grayscale-400'>
                        <div className='mt-8 px-12 font-medium'>Поручительства</div>

                        <div className='mt-10 flex items-center justify-center gap-25 text-h200 font-medium'>
                          <div className='flex flex-col items-center text-[#16A34A]'>
                            <div>{contractsInfo?.guarantee?.active?.count}</div>
                            <div>активных</div>
                          </div>
                          <div className='flex flex-col items-center text-grayscale-150'>
                            <div>{contractsInfo?.guarantee?.closed?.count}</div>
                            <div>закрытых</div>
                          </div>
                        </div>

                        {(!!Object.keys(contractsInfo?.guarantee?.sumActive)?.length ||
                          !!Object.keys(contractsInfo?.guarantee?.sumClosed)?.length) && (
                          <div className='mt-8 flex gap-6 px-5'>
                            <table className='w-full'>
                              <tbody>
                                {Object.keys(contractsInfo?.guarantee?.sumActive).map((key) => (
                                  <tr key={key} className='group'>
                                    <td className='p-7 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                      Сумма активных ДПР
                                    </td>
                                    <td className='p-7 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                      {formatMoney(contractsInfo?.guarantee.sumActive[key], key)}
                                    </td>
                                  </tr>
                                ))}
                                {Object.keys(contractsInfo?.guarantee?.sumClosed).map((key) => (
                                  <tr key={key} className='group'>
                                    <td className='p-7 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                      Сумма закрытых ДПР
                                    </td>
                                    <td className='p-7 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                      {formatMoney(contractsInfo?.guarantee.sumClosed[key], key)}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>

                      <div className='mt-8 border-t border-grayscale-400'>
                        <div className='mt-8 px-12 font-medium'>Гарантии</div>

                        <div className='mt-10 flex items-center justify-center gap-25 text-h200 font-medium'>
                          <div className='flex flex-col items-center text-[#16A34A]'>
                            <div>{contractsInfo?.warranty?.active?.count}</div>
                            <div>активных</div>
                          </div>
                          <div className='flex flex-col items-center text-grayscale-150'>
                            <div>{contractsInfo?.warranty?.closed?.count}</div>
                            <div>закрытых</div>
                          </div>
                        </div>

                        {(!!Object.keys(contractsInfo?.warranty?.sumActive)?.length ||
                          !!Object.keys(contractsInfo?.warranty?.sumClosed)?.length) && (
                          <div className='mt-8 flex gap-6 px-5'>
                            <table className='w-full'>
                              <tbody>
                                {Object.keys(contractsInfo?.warranty?.sumActive).map((key) => (
                                  <tr key={key} className='group'>
                                    <td className='p-7 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                      Сумма активных Гарантий
                                    </td>
                                    <td className='p-7 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                      {formatMoney(contractsInfo?.warranty.sumActive[key], key)}
                                    </td>
                                  </tr>
                                ))}
                                {Object.keys(contractsInfo?.warranty?.sumClosed).map((key) => (
                                  <tr key={key} className='group'>
                                    <td className='p-7 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                      Сумма закрытых Гарантий
                                    </td>
                                    <td className='p-7 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                      {formatMoney(contractsInfo?.warranty.sumClosed[key], key)}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>

                      <div className='mt-8 border-t border-grayscale-400 pb-5'>
                        <div className='mt-8 px-12 font-medium'>Платёжная дисциплина клиента</div>

                        <div className='mt-6 flex gap-6 px-5'>
                          <table className='w-full'>
                            <tbody>
                              <tr className='group'>
                                <td
                                  colSpan={2}
                                  className='p-7 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'
                                >
                                  Всего просрочено договоров
                                </td>
                                <td className='p-7 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                  {contractsInfo?.paymentOverdueDeals}
                                </td>
                              </tr>
                              {!!contractsInfo?.paymentOverdueDeals && (
                                <tr className='group'>
                                  <td className='p-7 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                    Период
                                  </td>
                                  <td className='p-7 text-right text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                    Просрочки
                                  </td>
                                  <td className='p-7 text-right text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                    Договоры
                                  </td>
                                </tr>
                              )}
                              {paymentOverdueStates.map(
                                (state) =>
                                  !!contractsInfo?.paymentOverdue[state]?.deals && (
                                    <tr key={state} className='group'>
                                      <td className='p-7 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                        {paymentOverdueStateDict[state]} дней
                                      </td>
                                      <td className='p-7 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                        {contractsInfo?.paymentOverdue[state]?.count}
                                      </td>
                                      <td className='p-7 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                                        {contractsInfo?.paymentOverdue[state]?.deals}
                                      </td>
                                    </tr>
                                  )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='py-5 text-center text-grayscale-150'>Данных по заемщику нет</div>
                  )}
                </>
              ) : (
                <div className='py-5 text-center text-grayscale-150'>Нет информации</div>
              )}
            </div>
          </div>
        </Card>
      </div>

      <div className='col-span-2 flex flex-col gap-6'>
        <Card>
          <CardHeader>
            <CardTitle>Информация о клиенте</CardTitle>
          </CardHeader>

          <div className='px-5 pb-5'>
            {equifaxReport ? (
              <Client
                data={equifaxReport?.responseData?.bki_response?.response?.title_part?.private}
                contacts={equifaxReport?.responseData?.bki_response?.response?.base_part?.contacts}
                ogrnip={equifaxReport?.responseData?.bki_response?.response?.base_part?.ogrnip}
              />
            ) : (
              <div className='rounded-md bg-surface-primary shadow-card'>
                <div className='py-5 text-center text-grayscale-150'>Нет информации</div>
              </div>
            )}
          </div>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>
              Кредитная история
              {equifaxReport && lastUpdateName && lastUpdateDate && (
                <div className='mt-2 text-p100 font-medium text-labels-secondary'>
                  Запросил: {lastUpdateName} {lastUpdateDate && dateTimeFormatter.format(new Date(lastUpdateDate))}
                </div>
              )}
            </CardTitle>
            {canCreateEquifaxReport && (
              <CardMenu>
                <CardIconButton onClick={createEquifaxReport}>
                  <RefreshIcon className={c((loading || loadingConsentPeriod) && 'animate-spin-reverse')} />
                </CardIconButton>
              </CardMenu>
            )}
          </CardHeader>

          <div className='px-5 pb-5'>
            {!loading && !!equifaxReport ? (
              <>
                {contracts?.length ? (
                  <div className='mt-12 flex flex-col gap-12'>
                    {!!contractsInfo?.active.count && (
                      <div>
                        <div className='px-3 text-p100 font-medium text-[#16A34A]'>
                          Активные – {contractsInfo.active.count}
                        </div>
                        <div className='mt-5 flex flex-col gap-5'>
                          {contractsInfo.active.contracts?.map((contract: ContractData) => (
                            <Contract
                              key={contract?.id || contract?.uid?.id}
                              contract={contract}
                              paymentDiscipline={paymentDiscipline.get(contract?.id)}
                              credMaxOverdue={summary.get(contract?.id)?.cred_max_overdue}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                    {!!contractsInfo?.closed.count && (
                      <div>
                        <div className='px-3 text-p100 font-medium text-grayscale-150'>
                          Завершенные – {contractsInfo.closed.count}
                        </div>
                        <div className='mt-5 flex flex-col gap-5'>
                          {contractsInfo.closed.contracts?.map((contract: ContractData) => (
                            <Contract
                              key={contract?.id || contract?.uid?.id}
                              contract={contract}
                              paymentDiscipline={paymentDiscipline.get(contract?.id)}
                              credMaxOverdue={summary.get(contract?.id)?.cred_max_overdue}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                    {!!contractsInfo?.sold.count && (
                      <div>
                        <div className='px-3 text-p100 font-medium text-grayscale-150'>
                          Проданные – {contractsInfo.sold.count}
                        </div>
                        <div className='mt-5 flex flex-col gap-5'>
                          {contractsInfo.sold.contracts?.map((contract: ContractData) => (
                            <Contract
                              key={contract?.id || contract?.uid?.id}
                              contract={contract}
                              paymentDiscipline={paymentDiscipline.get(contract?.id)}
                              credMaxOverdue={summary.get(contract?.id)?.cred_max_overdue}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                    {!!contractsInfo?.soldToCollectors.count && (
                      <div>
                        <div className='px-3 text-p100 font-medium text-grayscale-150'>
                          Проданные коллекторам – {contractsInfo.soldToCollectors.count}
                        </div>
                        <div className='mt-5 flex flex-col gap-5'>
                          {contractsInfo.soldToCollectors.contracts?.map((contract: ContractData) => (
                            <Contract
                              key={contract?.id || contract?.uid?.id}
                              contract={contract}
                              paymentDiscipline={paymentDiscipline.get(contract?.id)}
                              credMaxOverdue={summary.get(contract?.id)?.cred_max_overdue}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                    {!!contractsInfo?.guarantee.active.count && (
                      <div>
                        <div className='px-3 text-p100 font-medium text-grayscale-150'>
                          Активные поручительства – {contractsInfo.guarantee.active.count}
                        </div>
                        <div className='mt-5 flex flex-col gap-5'>
                          {contractsInfo.guarantee.active.contracts?.map((contract: ContractData) => (
                            <Contract
                              key={contract?.id || contract?.uid?.id}
                              contract={contract}
                              paymentDiscipline={paymentDiscipline.get(contract?.id)}
                              credMaxOverdue={summary.get(contract?.id)?.cred_max_overdue}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                    {!!contractsInfo?.guarantee.closed.count && (
                      <div>
                        <div className='px-3 text-p100 font-medium text-grayscale-150'>
                          Завершенные поручительства – {contractsInfo.guarantee.closed.count}
                        </div>
                        <div className='mt-5 flex flex-col gap-5'>
                          {contractsInfo.guarantee.closed.contracts?.map((contract: ContractData) => (
                            <Contract
                              key={contract?.id || contract?.uid?.id}
                              contract={contract}
                              paymentDiscipline={paymentDiscipline.get(contract?.id)}
                              credMaxOverdue={summary.get(contract?.id)?.cred_max_overdue}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                    {!!contractsInfo?.warranty.active.count && (
                      <div>
                        <div className='px-3 text-p100 font-medium text-grayscale-150'>
                          Активные гарантии – {contractsInfo.warranty.active.count}
                        </div>
                        <div className='mt-5 flex flex-col gap-5'>
                          {contractsInfo.warranty.active.contracts?.map((contract: ContractData) => (
                            <Contract
                              key={contract?.id || contract?.uid?.id}
                              contract={contract}
                              paymentDiscipline={paymentDiscipline.get(contract?.id)}
                              credMaxOverdue={summary.get(contract?.id)?.cred_max_overdue}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                    {!!contractsInfo?.warranty.closed.count && (
                      <div>
                        <div className='px-3 text-p100 font-medium text-grayscale-150'>
                          Завершенные гарантии – {contractsInfo.warranty.closed.count}
                        </div>
                        <div className='mt-5 flex flex-col gap-5'>
                          {contractsInfo.warranty.closed.contracts?.map((contract: ContractData) => (
                            <Contract
                              key={contract?.id || contract?.uid?.id}
                              contract={contract}
                              paymentDiscipline={paymentDiscipline.get(contract?.id)}
                              credMaxOverdue={summary.get(contract?.id)?.cred_max_overdue}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                    {!!contractsInfo?.terminated.count && (
                      <div>
                        <div className='px-3 text-p100 font-medium text-grayscale-150'>
                          Расторгнутые – {contractsInfo.terminated.count}
                        </div>
                        <div className='mt-5 flex flex-col gap-5'>
                          {contractsInfo.terminated.contracts?.map((contract: ContractData) => (
                            <Contract
                              key={contract?.id || contract?.uid?.id}
                              contract={contract}
                              paymentDiscipline={paymentDiscipline.get(contract?.id)}
                              credMaxOverdue={summary.get(contract?.id)?.cred_max_overdue}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                    {!!contractsInfo?.refinanced.count && (
                      <div>
                        <div className='px-3 text-p100 font-medium text-grayscale-150'>
                          Рефинансированные – {contractsInfo.refinanced.count}
                        </div>
                        <div className='mt-5 flex flex-col gap-5'>
                          {contractsInfo.refinanced.contracts?.map((contract: ContractData) => (
                            <Contract
                              key={contract?.id || contract?.uid?.id}
                              contract={contract}
                              paymentDiscipline={paymentDiscipline.get(contract?.id)}
                              credMaxOverdue={summary.get(contract?.id)?.cred_max_overdue}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                    {!!contractsInfo?.informationStopped.count && (
                      <div>
                        <div className='px-3 text-p100 font-medium text-grayscale-150'>
                          Прекращена передача информации – {contractsInfo.informationStopped.count}
                        </div>
                        <div className='mt-5 flex flex-col gap-5'>
                          {contractsInfo.informationStopped.contracts?.map((contract: ContractData) => (
                            <Contract
                              key={contract?.id || contract?.uid?.id}
                              contract={contract}
                              paymentDiscipline={paymentDiscipline.get(contract?.id)}
                              credMaxOverdue={summary.get(contract?.id)?.cred_max_overdue}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                    {!!contractsInfo?.badDebt.count && (
                      <div>
                        <div className='px-3 text-p100 font-medium text-grayscale-150'>
                          Безнадёжный долг (списанные) – {contractsInfo.badDebt.count}
                        </div>
                        <div className='mt-5 flex flex-col gap-5'>
                          {contractsInfo.badDebt.contracts?.map((contract: ContractData) => (
                            <Contract
                              key={contract?.id || contract?.uid?.id}
                              contract={contract}
                              paymentDiscipline={paymentDiscipline.get(contract?.id)}
                              credMaxOverdue={summary.get(contract?.id)?.cred_max_overdue}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                    {!!contractsInfo?.other.count && (
                      <div>
                        <div className='px-3 text-p100 font-medium text-grayscale-150'>
                          Прочие – {contractsInfo.other.count}
                        </div>
                        <div className='mt-5 flex flex-col gap-5'>
                          {contractsInfo.other.contracts?.map((contract: ContractData) => (
                            <Contract
                              key={contract?.id || contract?.uid?.id}
                              contract={contract}
                              paymentDiscipline={paymentDiscipline.get(contract?.id)}
                              credMaxOverdue={summary.get(contract?.id)?.cred_max_overdue}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className='rounded-md bg-surface-primary shadow-card'>
                    <div className='px-10 py-5 text-center text-grayscale-150'>
                      {equifaxReport?.responseData?.bki_response?.response?.responsecode === '31'
                        ? !!dataProcessingConsentDate &&
                          `Согласие БКИ просрочено ${dateFormatter.format(new Date(dataProcessingConsentDate))}`
                        : 'Данных по заемщику нет'}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className='rounded-md bg-surface-primary shadow-card'>
                <div className='py-5 text-center text-grayscale-150'>Нет информации</div>
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  )
}

export default BKI
