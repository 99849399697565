import { useState } from 'react'
import {
  Kind,
  useDealCalculationsLazyQuery,
  useScheduleByDealParamsLazyQuery,
  useUserByIdQuery,
  useCreateCommercialPdfMutation,
  useCommercialReconciliationLazyQuery
} from '../graphql/schema'
import { BALANCE_HOLDER_REC, REDEMPTION_PAYMENT, REGULAR_VAT_RATE } from '../utils/constants'
import { formatZero, getDateFormattedString } from '../utils/contractUtils'
import downloadFile from '../utils/downloadFileServerless'
import useCurrentUser from '../hooks/useCurrentUser'
import { PaymentShedule } from '../containers/Deal/Documents/LeasingContractForm'

export interface DownloadReconciliation {
  _id: number
  amount: number
  advanceRate: number
  comissionRate: number
  durationMonths: number
  insuranceRate: number
  interestRate: number
  kind: Kind
  monthPayment?: number
  vatRate: number
  created: string
  requiredGuarantorsCount?: number
  createdBy: {
    name: string
    surname: string
  }
}

export const getAdvancePaymentDate = (date: Date, defaultFormat = false) => {
  date.setDate(date.getDate() + 3)

  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  return defaultFormat
    ? `${formatZero(year)}-${formatZero(month)}-${formatZero(day)}`
    : `${formatZero(day)}.${formatZero(month)}.${formatZero(year)}`
}

export const useDownloadCommercial = (recId?: number, fileName?: string) => {
  const [loading, setLoading] = useState(false)
  const [createCommercial] = useCreateCommercialPdfMutation()
  const [getReconciliation] = useCommercialReconciliationLazyQuery()

  const [getDealCalculations] = useDealCalculationsLazyQuery()
  const [getScheduleData] = useScheduleByDealParamsLazyQuery()

  const currentUser = useCurrentUser()
  const { data: userData } = useUserByIdQuery({
    variables: { id: currentUser?._id.toString() as string },
    skip: !currentUser?._id
  })
  const contact = userData?.user

  const downloadCommercial = async () => {
    if (!recId || !contact) return

    setLoading(true)
    try {
      const { data: recData } = await getReconciliation({
        variables: { id: recId.toString() }
      })
      const rec = recData?.reconciliation

      if (!rec) throw new Error('Failed to get reconciliation')

      const { data: dealCalculationsData } = await getDealCalculations({
        variables: {
          dealParams: {
            amount: rec.amount / 100,
            advanceRate: rec.advanceRate,
            interestRate: rec.interestRate,
            durationMonths: rec.durationMonths,
            comissionRate: rec.comissionRate,
            insuranceRate: rec.insuranceRate,
            vatRate: rec.kind === Kind.Medicine ? 0 : REGULAR_VAT_RATE,
            advancePaymentDate: getAdvancePaymentDate(new Date(rec.created), true)
          }
        }
      })
      const dealCalculations = dealCalculationsData?.dealCalculations

      if (!dealCalculations) throw new Error('Failed to get deal calculations')

      const { data: scheduleData } = await getScheduleData({
        variables: {
          dealParams: {
            amount: rec.amount / 100,
            advanceRate: rec.advanceRate,
            interestRate: rec.interestRate,
            durationMonths: rec.durationMonths,
            comissionRate: rec.comissionRate,
            insuranceRate: rec.insuranceRate,
            vatRate: rec.kind === Kind.Medicine ? 0 : REGULAR_VAT_RATE,
            advancePaymentDate: getAdvancePaymentDate(new Date(rec.created), true)
          }
        }
      })

      if (!scheduleData) throw new Error('Failed to get schedule data')

      const schedule = scheduleData.scheduleByDealParams
      let advanceAmountVat = 0
      if (dealCalculations.advanceAmount && rec.vatRate) {
        advanceAmountVat = (dealCalculations.advanceAmount / (100 + rec.vatRate)) * rec.vatRate
      }

      let redemptionAmount = {
        amount: REDEMPTION_PAYMENT,
        date: ''
      }
      if (schedule.length) {
        redemptionAmount = {
          amount: REDEMPTION_PAYMENT,
          date: schedule[schedule.length - 1].date
        }
      }
      let redemptionAmountVat = 0
      if (rec.vatRate) {
        redemptionAmountVat = (redemptionAmount.amount / (100 + rec.vatRate)) * rec.vatRate
      }

      const paymentSchedule: PaymentShedule[] = schedule
        ? [
            {
              row: 'Аванс',
              date: getAdvancePaymentDate(new Date(rec?.created)),
              payment: dealCalculations?.advanceAmount || 0,
              vat: Math.floor(advanceAmountVat * 100) / 100,
              redemptionPaymentWithVat: 0
            },
            ...(schedule.map((s, i) => ({
              row: `${i + 1}`,
              date: getDateFormattedString(new Date(s.date)),
              payment: s.amount,
              vat: s.vatAmount,
              redemptionPaymentWithVat: s.redemption
            })) || []),
            {
              row: 'Выкуп',
              date: getDateFormattedString(new Date(redemptionAmount?.date)),
              payment: redemptionAmount?.amount || 0,
              vat: Math.floor(redemptionAmountVat * 100) / 100,
              redemptionPaymentWithVat: 0
            }
          ]
        : []

      const guarantorsCount = Math.max(rec.requiredGuarantorsCount || 0, rec.reconciliationGuarantors?.totalCount || 0)

      const createCommercialResult = await createCommercial({
        variables: {
          input: {
            applicationId: rec.application._id.toString(),
            contact: {
              name: contact.name,
              surname: contact.surname,
              phone: contact.phone,
              email: contact.email
            },
            reconciliationDate: getDateFormattedString(new Date(rec.created)),
            calculation: {
              leasingAmount: rec.amount / 100 || 0,
              leasingAmountVat: dealCalculations.amountVat,
              advancePercentage: rec.advanceRate,
              advanceAmount: dealCalculations.advanceAmount,
              durationMonths: rec.durationMonths,
              balanceHolder: BALANCE_HOLDER_REC,
              premium: dealCalculations.insuranceAmount,
              comission: dealCalculations.comissionAmount,
              leasingContractAmount: dealCalculations.contractAmount,
              leasingContractAmountVat: dealCalculations.contractAmountVat,
              appreciation: dealCalculations.appreciation,
              redemptionPayment: REDEMPTION_PAYMENT,
              monthlyPayment: dealCalculations.monthlyPayment,
              vat: rec.kind === Kind.Medicine ? 0 : REGULAR_VAT_RATE,
              requiredGuarantorsCount: guarantorsCount
            },
            paymentSchedule,
            savings: {
              incomeTax: dealCalculations.approximatelyIncomeTaxSavings,
              vat: dealCalculations.vatSavings
            }
          }
        }
      })

      const commercialUrl = createCommercialResult?.data?.createCommercial?.url
      if (!commercialUrl) throw new Error('Не удалось получить ссылку на КП')

      await downloadFile(commercialUrl, fileName || 'unnamed')
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return [downloadCommercial, loading] as const
}
