const parseDecimal = (value: string): number => {
  return parseFloat(value.replace(',', '.'))
}

const parseDecimalFromMasked = (value: string): number => {
  return parseFloat(value.replace(/\s/g, '').replace(',', '.'))
}

export default parseDecimal
export { parseDecimalFromMasked }
