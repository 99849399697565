import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'
import SubmitButton from '../../components/SubmitButton'
import Input from '../../components/Input.tsx'
import { ApolloError } from '@apollo/client'
import { handleBackendErrorsToForm } from '../../utils/backendErrorUtils.ts'
import { useUpdateCustomerConsentDateMutation } from '../../graphql/schema.tsx'

const EditBkiConsentDateForm: FC<{
  customerId: string
  date?: string
  onDone: () => void
}> = ({ customerId, date, onDone }) => {
  const {
    register,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm<{ date: string }>({
    defaultValues: {
      date: date ? new Date(date).toISOString().split('T').shift() : ''
    }
  })

  const [updateCustomerConsentDate, { loading }] = useUpdateCustomerConsentDateMutation()

  const onSubmit: SubmitHandler<{ date: string }> = async (data) => {
    if (loading) return

    await updateCustomerConsentDate({
      variables: {
        input: {
          id: customerId,
          dataProcessingConsentDate: new Date(data.date).toISOString()
        }
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<{ date: string }>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  return (
    <section className='w-[448px] p-12'>
      <h1 className='mb-12 font-display text-h200'>Согласие на получение кредитного отчета</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 grid grid-cols-1 gap-20'>
          <Input label='Дата согласия' type='date' {...register('date', { required: true })} error={errors.date} />
        </div>

        <SubmitButton loading={loading}>
          <EditIcon className='mr-5' />
          Изменить
        </SubmitButton>
      </form>
    </section>
  )
}

export default EditBkiConsentDateForm
