import { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { ApolloError } from '@apollo/client'
import { SourceByIdQuery, useUpdateSourceMutation } from '../../graphql/schema'
import { handleBackendErrorsToForm } from '../../utils/backendErrorUtils'
import SubmitButton from '../../components/SubmitButton'
import parseDecimal from '../../utils/parseDecimal'
import CommissionRewardRateList from '../../components/CommissionRewardRateList'
import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'
import MaskedPercentInput from '../../components/Forms/Inputs/MaskedPercentInput.tsx'
import { formatPercent } from '../../utils/formatNumber.ts'

interface BonusesFormProps {
  source: SourceByIdQuery['source']
  onDone: () => void
}

interface BonusesUpdateInputs {
  commissionRewardRate: string
}

const BonusesForm: FC<BonusesFormProps> = ({ source, onDone }) => {
  const { control, handleSubmit, setError, setValue, watch } = useForm<BonusesUpdateInputs>({
    defaultValues: {
      commissionRewardRate:
        typeof source?.commissionRewardRate === 'number' ? formatPercent(source.commissionRewardRate) || '0' : undefined
    }
  })
  const [updateSource, { loading }] = useUpdateSourceMutation()

  const commissionRewardRate = watch('commissionRewardRate')

  const onSubmit: SubmitHandler<BonusesUpdateInputs> = async (data) => {
    if (!data) return

    await updateSource({
      variables: {
        input: {
          id: source?._id.toString()!,
          commissionRewardRate: parseDecimal(data.commissionRewardRate)
        }
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<BonusesUpdateInputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  return (
    <section className='w-[448px] p-12'>
      <h1 className='mb-12 font-display text-h200'>Бонусы</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 grid grid-cols-1 gap-10'>
          <div>
            <Controller
              render={({ field, fieldState }) => (
                <MaskedPercentInput
                  label='Агентское вознаграждение'
                  placeholder='1,5%'
                  inputMode='decimal'
                  error={fieldState.error?.message}
                  {...field}
                />
              )}
              control={control}
              name='commissionRewardRate'
              rules={{ required: true, min: 0 }}
            />
            <CommissionRewardRateList
              commissionRewardRate={commissionRewardRate}
              onChange={(value) => setValue('commissionRewardRate', value)}
              className='mt-3'
            />
          </div>
        </div>

        <SubmitButton loading={loading}>
          <EditIcon className='mr-5' />
          Изменить
        </SubmitButton>
      </form>
    </section>
  )
}

export default BonusesForm
