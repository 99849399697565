import { FC, useEffect, useMemo } from 'react'
import FilterForm from '../../components/Filter/Form'
import { ContactsQueryVariables, VerificationState } from '../../graphql/schema'
import { verificationStateDict } from '../../utils/dictionaries'
import { ReactComponent as TickIcon } from '../../svg/icons/tick-xs.svg'
import Select from '../../components/Select'
import { makeVar, useReactiveVar } from '@apollo/client'
import { throttle } from 'throttle-debounce'

const verificationStatuses = [VerificationState.Completed, VerificationState.Rejected, VerificationState.Accepted].map(
  (s) => ({
    _id: s,
    name: verificationStateDict[s]
  })
)

export interface ContactFilterState {
  verificationStatus?: VerificationState
  query?: string
}

export interface FilterFormProps {
  state: ContactFilterState
  onChange: (state: ContactFilterState) => void
}

const emptyState: ContactFilterState = {
  verificationStatus: undefined
}

const contactFilterStateVar = makeVar<ContactFilterState>(emptyState)
const contactQueryVar = makeVar<string>('')

const ContactFilterForm: FC<FilterFormProps> = ({ state, onChange }) => {
  return (
    <FilterForm
      onReset={() =>
        onChange({
          ...state,
          verificationStatus: undefined
        })
      }
      title='Фильтр контактов'
    >
      <div className='relative group mb-10'>
        <Select
          type='text'
          label='Статус'
          autoComplete='off'
          placeholder='Выберите статус'
          value={state.verificationStatus ? verificationStateDict[state.verificationStatus] : ''}
        >
          {verificationStatuses.map((verificationStatus) => (
            <li
              key={verificationStatus._id}
              onClick={() =>
                onChange({
                  ...state,
                  verificationStatus: verificationStatus._id
                })
              }
              className='hover:bg-grayscale-450 px-12 py-5 cursor-pointer'
            >
              <div className='flex justify-between items-center'>
                <div className='text-grayscale-0 mb-1'>{verificationStateDict[verificationStatus._id]}</div>
                {verificationStatus._id === state.verificationStatus && (
                  <div className='rounded-full bg-red-100/5 text-red-100 px-5 py-2 flex items-center ml-5'>
                    <TickIcon />
                  </div>
                )}
              </div>
            </li>
          ))}
        </Select>
      </div>
    </FilterForm>
  )
}

const setQuery = throttle(500, (query: string) => {
  contactFilterStateVar({
    ...contactFilterStateVar(),
    query
  })
})

export const useContactFilter = () => {
  const filterState = useReactiveVar(contactFilterStateVar)
  const queryState = useReactiveVar(contactQueryVar)

  const filterActive = Boolean(filterState.verificationStatus)

  useEffect(() => {
    setQuery(queryState)
  }, [queryState])

  const variables = useMemo<ContactsQueryVariables>((): ContactsQueryVariables => {
    if (filterState?.query?.length) {
      return {
        search: filterState.query
      }
    }
    return {
      verificationStatus: filterState.verificationStatus
    }
  }, [filterState])

  return {
    filterState,
    setFilterState: contactFilterStateVar,
    filterActive,
    query: queryState,
    setQuery: contactQueryVar,
    variables
  }
}

export default ContactFilterForm
