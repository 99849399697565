import { FC, ReactNode } from 'react'
import NavLink from '../NavLink'
import { Link } from 'react-router-dom'

interface EntityHeaderProps {
  children?: ReactNode
}
export const EntityHeader: FC<EntityHeaderProps> = ({ children }) => {
  return (
    <div className='bg-white-0 pt-12 px-6 border-b-1 border-separators-primary'>
      <div className='container mx-auto'>{children}</div>
    </div>
  )
}

export const EntityInfo: FC<{ children?: ReactNode }> = ({ children }) => {
  return <div className='text-labels-secondary mb-1 flex items-center font-medium text-body-s'>{children}</div>
}

export const EntityName: FC<{ value?: string; loading?: boolean; to?: string }> = ({ value, loading, to }) => {
  const Name = (
    <h1 className='font-semibold text-title-l'>
      {!value && loading ? (
        <div className='flex'>
          <div className='h-[1lh] bg-surface-tertiary w-30 rounded-sm mr-4' />
          <div className='h-[1lh] bg-surface-tertiary w-90 rounded-sm' />
        </div>
      ) : (
        value
      )}
    </h1>
  )

  if (!to) {
    return Name
  }

  return (
    <Link className='leading-full' to={to}>
      {Name}
    </Link>
  )
}

interface EntityBarItemProps {
  title: string
  value?: string
  onAction?: () => void
  action?: string
  loading?: boolean
  children?: ReactNode
}

export const EntityBarItem: FC<EntityBarItemProps> = ({ title, value, onAction, action, loading, children }) => {
  return (
    <div className='flex-none'>
      <div className='text-labels-secondary mb-1'>{title}</div>
      {loading ? (
        <div className='h-[1lh] bg-surface-tertiary w-90 rounded-sm' />
      ) : value || children ? (
        <div className='font-medium'>
          {value}
          {children}
        </div>
      ) : (
        <div onClick={onAction} className='text-labels-tertiary hover:text-labels-primary cursor-pointer'>
          {action}
        </div>
      )}
    </div>
  )
}

interface EntityBarProps {
  children?: ReactNode
}
export const EntityBar: FC<EntityBarProps> = ({ children }) => {
  return (
    <div className='rounded-xl flex py-6 px-8 mb-12 bg-surface-secondary divide-x divide-separators-secondary *:px-10 first:*:pl-0 last:*:pr-0'>
      {children}
    </div>
  )
}

export const EntityNavItem: FC<{ title: string; to: string }> = ({ title, to }) => {
  return (
    <NavLink
      className='h-full pb-8 cursor-pointer text-body-m font-medium hover:text-base-red aria-current:border-b-2 aria-current:text-base-red'
      to={to}
      end
    >
      {title}
    </NavLink>
  )
}

interface EntityNavProps {
  children?: ReactNode
}
export const EntityNav: FC<EntityNavProps> = ({ children }) => {
  return <div className='flex gap-12 text-labels-secondary'>{children}</div>
}
