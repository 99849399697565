import { FC, useMemo, useState } from 'react'
import MultipleSelect from '../../Select'
import { UserRoles, useUsersQuery } from '../../../graphql/schema'
import useNodes from '../../../hooks/useNodes'
import useFilteredUsers from '../../../hooks/useFilteredUsers'
import Highlighted from '../../Highlighted'
import getFullName from '../../../utils/getFullName'
import { FieldError } from 'react-hook-form'
import c from 'clsx'
import { ReactComponent as TickIcon } from '../../../svg/icons/tick-xs.svg'
import sortUsersByAcivation from '../../../utils/sortUsersByActivation'

interface ManagerInputProps {
  selected: number[]
  onChange: (users: number[]) => void
  label?: string
  placeholder?: string
  multiple?: boolean
  error?: string | FieldError
  role?: UserRoles
  disabled?: boolean
}

const ManagerInput: FC<ManagerInputProps> = ({
  selected,
  onChange,
  label,
  placeholder,
  multiple,
  error,
  role,
  disabled
}) => {
  const [userQuery, setUserQuery] = useState('')

  const { data: usersData } = useUsersQuery({
    variables: { order: [{ name: 'asc' }], roles: role || UserRoles.RoleManager }
  })
  const users = useNodes(usersData?.users?.edges)
  const usersList = useFilteredUsers(users, userQuery)

  usersList.sort(sortUsersByAcivation)

  const value = useMemo(() => {
    return selected
      .map((id) => {
        const user = users.find((user) => user._id === id)
        return getFullName(user)
      })
      .join(', ')
  }, [selected, users])

  return (
    <MultipleSelect
      multiple={multiple}
      type='text'
      label={label}
      placeholder={placeholder}
      autoComplete='off'
      onQuery={(query) => {
        setUserQuery(query)
      }}
      value={value}
      error={error}
      clearSelected={() => onChange([])}
      disabled={disabled}
    >
      {usersList.map((user) => (
        <li
          key={user._id}
          onClick={() => {
            if (!user.isActive) return
            if (selected.includes(user._id)) {
              onChange(selected.filter((id) => id !== user._id))
            } else {
              onChange([...selected, user._id])
            }
          }}
          className={c('px-12 py-5 hover:bg-grayscale-450', user.isActive ? 'cursor-pointer' : 'cursor-not-allowed')}
        >
          <div className='flex items-center justify-between'>
            <div className={c('mb-1', user.isActive ? 'text-grayscale-0' : 'text-grayscale-150')}>
              <Highlighted
                classMarkName='text-red-100 bg-transparent'
                text={`${user?.name} ${user?.surname}${!user.isActive ? ' (Деактивирован)' : ''}`}
                highlight={userQuery}
              />
            </div>
            {selected.includes(user._id) && (
              <div className='ml-5 flex items-center rounded-full bg-red-100/5 px-5 py-2 text-red-100'>
                <TickIcon />
              </div>
            )}
          </div>
        </li>
      ))}
    </MultipleSelect>
  )
}

export default ManagerInput
