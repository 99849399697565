import { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { ApolloError } from '@apollo/client'
import SubmitButton from '../../../components/SubmitButton'
import { UserRoles, useUpdateCollectorMutation } from '../../../graphql/schema.tsx'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils.ts'
import ManagerInput from '../../../components/Forms/Inputs/ManagerInput.tsx'

type Inputs = {
  collector: number
}

interface CollectorFormProps {
  dealId: number
  userId?: number
  onDone?: () => void
}

const CollectorForm: FC<CollectorFormProps> = ({ dealId, userId, onDone }) => {
  const [updateCollector, { loading }] = useUpdateCollectorMutation()

  const { handleSubmit, control, setError, formState } = useForm<Inputs>({
    defaultValues: {
      collector: userId
    }
  })

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await updateCollector({
      variables: {
        id: `${dealId}`,
        collector: `${data.collector}`
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  return (
    <section className='w-[448px] p-12'>
      <h1 className='mb-12 font-display text-h200'>Коллектор</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='relative mb-12 flex flex-col gap-8'>
          <Controller
            name='collector'
            control={control}
            rules={{
              required: true
            }}
            render={({ field }) => (
              <ManagerInput
                selected={field.value ? [field.value] : []}
                onChange={(selected) =>
                  selected.length ? field.onChange(selected[selected.length - 1]) : field.onChange(null)
                }
                error={formState.errors?.collector}
                role={UserRoles.RoleCollector}
              />
            )}
          />
        </div>
        <SubmitButton loading={loading}>Сохранить</SubmitButton>
      </form>
    </section>
  )
}

export default CollectorForm
