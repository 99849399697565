import { FC, useMemo, useState } from 'react'
import Avatar from '../../components/Avatar'
import { useUsersQuery } from '../../graphql/schema.tsx'
import useNodes from '../../hooks/useNodes.ts'
import getFullName from '../../utils/getFullName.ts'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'

interface UserFilterProps {
  userId?: number
  className?: string
  onChange: (userId?: number) => void
}

const UserFilter: FC<UserFilterProps> = ({ userId, className, onChange }) => {
  const { data: usersData } = useUsersQuery({
    variables: { order: [{ name: 'asc' }] }
  })
  const users = useNodes(usersData?.users?.edges)
  const [query, setQuery] = useState('')

  const filteredUsers = useMemo(() => {
    if (!query) return users
    return users.filter((user) => getFullName(user).toLowerCase().includes(query.toLowerCase()))
  }, [users, query])

  return (
    <Listbox
      value={userId}
      onChange={(id) => {
        if (!id === null) return
        if (id === userId) return
        onChange(id)
      }}
    >
      <ListboxButton className={className}>
        <Avatar userId={userId} />
      </ListboxButton>
      <ListboxOptions
        anchor={{ to: 'bottom end', gap: '10px' }}
        className='flex w-122 flex-col gap-2 rounded-md bg-surface-primary p-2 shadow-dropdown [--anchor-max-height:200px] empty:invisible'
      >
        <input
          type='text'
          tabIndex={-1}
          placeholder='Поиск'
          className='w-full appearance-none rounded border-separators-primary px-5 py-3 placeholder:text-labels-tertiary focus:border-separators-primary focus:ring-0 focus:ring-transparent'
          value={query}
          // Prevent spacebar in input from closing the listbox
          onKeyDown={(e) => {
            if (e.key === ' ') e.stopPropagation()
          }}
          onChange={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setQuery(e.target.value)
          }}
        />
        {filteredUsers.length ? (
          <div className='overflow-y-scroll'>
            {filteredUsers.map((user) => (
              <ListboxOption
                key={user._id}
                value={user._id}
                className='flex cursor-pointer items-center gap-5 rounded bg-surface-primary px-5 py-3 hover:bg-surface-secondary data-[focus]:bg-surface-secondary'
              >
                <Avatar userId={user._id} height='h-10' width='w-10' />
                <div className='overflow-hidden overflow-ellipsis text-nowrap'>{getFullName(user)}</div>
              </ListboxOption>
            ))}
          </div>
        ) : (
          <div className='px-5 py-3 text-center text-labels-tertiary'>¯\_(ツ)_/¯</div>
        )}
      </ListboxOptions>
    </Listbox>
  )
}

export default UserFilter
