import { FC } from 'react'
import { formatDecimal } from '../utils/formatNumber'
import c from 'clsx'

interface TransactionAmountProps {
  amount: number
  isIncome?: boolean
  className?: string
  currency?: string
}

const TransactionAmount: FC<TransactionAmountProps> = ({ isIncome = false, amount, className, currency }) => {
  const [integer, fractional] = formatDecimal(amount * 100).split(',')

  return (
    <div className={c('whitespace-nowrap', className)}>
      <span>
        {isIncome ? '+' : '-'} {integer},
      </span>
      <span className='opacity-60'>{fractional}</span>
      {currency && <span>&nbsp;{currency}</span>}
    </div>
  )
}

export default TransactionAmount
