// react component that takes children and renders them into N columns

import { FC, Children } from 'react'
import useColumns from '../hooks/useColumns'

interface ColumnsProps {
  n: number
  children?: React.ReactNode
}

const Columns: FC<ColumnsProps> = ({ children, n }) => {
  // get array of children from children props
  const columns = useColumns(n, Children.toArray(children) as React.ReactElement[])

  return (
    <>
      {columns.map((column, index) => (
        <div key={index}>{column}</div>
      ))}
    </>
  )
}

export default Columns