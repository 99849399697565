import { FC, useMemo, useState } from 'react'
import MultipleSelect from '../../../components/Select'
import { SourcesQuery, useSourcesQuery } from '../../../graphql/schema'
import useNodes from '../../../hooks/useNodes'
import Highlighted from '../../Highlighted'
import { ReactComponent as TickIcon } from '../../../svg/icons/tick-xs.svg'
import { NodeType } from '../../../types'

export type SourceInputSource = NodeType<SourcesQuery['sources']>

interface SourceInputProps {
  selected: SourceInputSource[]
  onChange: (users: SourceInputSource[]) => void
}

const SourceInput: FC<SourceInputProps> = ({ selected, onChange }) => {
  const [query, setQuery] = useState('')

  const { data: sourcesData } = useSourcesQuery({ fetchPolicy: 'cache-and-network' })
  const sources = useNodes(sourcesData?.sources?.edges)

  const sourcesList = useMemo(() => {
    if (query) {
      const queryParts = query.toLowerCase().replace(/\s\s/g, ' ').trim().split(' ')
      return sources.filter((source) => queryParts.every((part) => source?.name.toLowerCase().includes(part)))
    }
    return sources
  }, [query, sources])

  return (
    <MultipleSelect
      multiple
      label='Источники'
      type='text'
      placeholder={selected.length ? `Выбрано: ${selected.length}` : 'Выберите источники'}
      autoComplete='off'
      onQuery={(query) => {
        setQuery(query)
      }}
    >
      {sourcesList?.map((source) => (
        <li
          key={source._id}
          onClick={() => {
            if (selected.includes(source)) {
              onChange(selected.filter((s) => s._id !== source._id))
            } else {
              onChange([...selected, source])
            }
          }}
          className='hover:bg-grayscale-450 px-12 py-5 cursor-pointer'
        >
          <div className='flex justify-between items-center'>
            <div className='text-grayscale-0 mb-1'>
              <Highlighted classMarkName='text-red-100 bg-transparent' text={source.name} highlight={query} />
            </div>
            {selected.some((s) => s === source) && (
              <div className='rounded-full bg-red-100/5 text-red-100 px-5 py-2 flex items-center ml-5'>
                <TickIcon />
              </div>
            )}
          </div>
        </li>
      ))}
    </MultipleSelect>
  )
}

export default SourceInput
