import { FC, Suspense } from 'react'
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import { ReactComponent as LoadingIcon } from '../svg/icons/loading.svg'

interface SuspenseModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  children?: React.ReactNode
}

const SuspenseModal: FC<SuspenseModalProps> = ({ children, open, setOpen }) => {
  return (
    <Dialog className='relative z-50' open={open} onClose={() => setOpen(false)}>
      <DialogBackdrop
        transition
        className='bg-base-black/30 fixed inset-0 duration-500 ease-out data-[closed]:opacity-0'
      />

      <div className='fixed inset-0 overflow-y-auto py-10'>
        <div className='flex min-h-full w-screen items-center justify-center'>
          <Suspense
            fallback={
              <div className='relative z-10 flex h-20 w-20 items-center justify-center rounded-xl bg-white-0'>
                <LoadingIcon className='animate-spin text-labels-tertiary' />
              </div>
            }
          >
            <DialogPanel transition className='duration-300 ease-out data-closed:scale-95 data-closed:opacity-0'>
              {open && children}
            </DialogPanel>
          </Suspense>
        </div>
      </div>
    </Dialog>
  )
}

export default SuspenseModal
