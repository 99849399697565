import { FC } from 'react'
import { isDefaultStatuses, useApplicationFilter, AppFilterState } from './FilterForm'
import SelectedFilter from '../../components/Filter/SelectedFilter'
import UserName from '../../components/UserName'
import { dateFormatter } from '../../utils/dateFormatter'

const SelectedFilters: FC = () => {
  const { filterState, setFilterState, filterActive } = useApplicationFilter()

  if (!filterActive || filterState?.query?.length) return null

  return (
    <div className='mb-8 flex flex-wrap gap-2'>
      {(Object.keys(filterState) as (keyof AppFilterState)[]).map((key) => {
        switch (key) {
          case 'statuses':
            if (isDefaultStatuses(filterState.statuses)) break
            return filterState[key]?.map((filter) => (
              <SelectedFilter
                key={filter.value}
                onRemove={() =>
                  setFilterState({
                    ...filterState,
                    [key]: filterState[key].filter((i) => i.value !== filter.value)
                  })
                }
              >
                {filter.name}
              </SelectedFilter>
            ))
            break
          case 'sources':
            return filterState[key]?.map((filter) => (
              <SelectedFilter
                key={filter._id + filter.name}
                onRemove={() =>
                  setFilterState({
                    ...filterState,
                    [key]: filterState[key].filter((i) => i._id !== filter._id)
                  })
                }
              >
                {filter.name}
              </SelectedFilter>
            ))
          case 'users':
            return filterState[key]?.map((filter: number) => (
              <SelectedFilter
                key={key + filter}
                onRemove={() =>
                  setFilterState({
                    ...filterState,
                    [key]: filterState[key].filter((id) => id !== filter)
                  })
                }
              >
                <UserName userId={filter} />
              </SelectedFilter>
            ))
          case 'categories':
            return filterState[key].map((filter) => (
              <SelectedFilter
                key={filter.name + filter.value}
                onRemove={() =>
                  setFilterState({
                    ...filterState,
                    [key]: filterState[key].filter((i) => i.value !== filter.value)
                  })
                }
              >
                {filter.name}
              </SelectedFilter>
            ))
          case 'dateFrom':
            return (
              filterState[key] && (
                <SelectedFilter
                  key={key}
                  onRemove={() =>
                    setFilterState({
                      ...filterState,
                      [key]: undefined
                    })
                  }
                >
                  c {dateFormatter.format(new Date(filterState[key] as string))}
                </SelectedFilter>
              )
            )
          case 'dateTo':
            return (
              filterState[key] && (
                <SelectedFilter
                  key={key}
                  onRemove={() =>
                    setFilterState({
                      ...filterState,
                      [key]: undefined
                    })
                  }
                >
                  по {dateFormatter.format(new Date(filterState[key] as string))}
                </SelectedFilter>
              )
            )
          case 'sourceCategories':
            return filterState[key]?.map((filter) => (
              <SelectedFilter
                key={filter.value}
                onRemove={() =>
                  setFilterState({
                    ...filterState,
                    [key]: filterState[key].filter((i) => i.value !== filter.value)
                  })
                }
              >
                {filter.name}
              </SelectedFilter>
            ))
        }
      })}
    </div>
  )
}

export default SelectedFilters
