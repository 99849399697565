import { FC, MouseEvent, ReactNode, RefObject } from 'react'
import { AttachmentCategory } from '../../../graphql/schema.tsx'
import Preview from '../../../components/Preview'
import { APIRoot, APIVerificationRoot } from '../../../utils/APIRoot.ts'
import { IDocument } from '../../../components/DocumentManager/Document.tsx'
import { useRotation } from '../../../hooks/useRotation.tsx'
import { useZoomable } from '../../../hooks/useZoomable.tsx'
import { ReactComponent as RotateLeftIcon } from '../../../svg/icons/rotateLeft.svg'
import { ReactComponent as RotateRightIcon } from '../../../svg/icons/rotateRight.svg'
import { ReactComponent as RotateFlipIcon } from '../../../svg/icons/rotateFlip.svg'
import { ReactComponent as LoupMinusIcon } from '../../../svg/icons/loupMinus.svg'
import { ReactComponent as LoupPlusIcon } from '../../../svg/icons/loupPlus.svg'
import { ReactComponent as RefreshIcon } from '../../../svg/icons/refresh-slim.svg'
import useSuspenseFile from '../../../hooks/useSuspenseFile.ts'
import c from 'clsx'

interface CustomIconButtonProps {
  onClick: () => void
  children: ReactNode
}

const CustomIconButton = ({ children, onClick }: CustomIconButtonProps) => (
  <button
    type='button'
    className='text-grayscale-250 hover:text-grayscale-150 transition ease-out duration-100'
    onClick={onClick}
  >
    {children}
  </button>
)

const DocumentPrev: FC<{
  doc: IDocument & { category?: AttachmentCategory | undefined }
  onMouseDown: (event: MouseEvent<HTMLDivElement>) => void
  onMouseMove: (event: MouseEvent<HTMLDivElement>) => void
  onMouseUp: () => void
  containerRef: RefObject<HTMLDivElement>
  contentRef: RefObject<HTMLDivElement>
  cursor: string
  rotation: number
  containerWidth: number
  containerHeight: number
  isFlip: boolean
  contentSize: string
  contentTransform: string
  apiRoot?: boolean
}> = ({
  doc,
  apiRoot,
  onMouseDown,
  onMouseMove,
  onMouseUp,
  containerRef,
  cursor,
  rotation,
  containerWidth,
  containerHeight,
  isFlip,
  contentRef,
  contentSize,
  contentTransform
}) => {
  const file = useSuspenseFile((apiRoot ? APIRoot : APIVerificationRoot) + doc?.filePath, 'GET', doc?.mimetype)
  const type = file?.type

  return (
    <div
      className='img-rotator w-full h-full object-contain origin-center relative transition duration-300'
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      ref={containerRef}
      style={{
        cursor,
        transform: `${
          type !== 'application/pdf'
            ? `rotate(${rotation}deg) scale(${
                rotation % 180 === 0
                  ? 1
                  : containerWidth < containerHeight
                  ? containerWidth / containerHeight
                  : containerHeight / containerWidth
              })`
            : ''
        } ${isFlip ? `scale${type === 'application/pdf' ? 'X' : rotation % 180 === 0 ? 'X' : 'Y'}(-1)` : ''}`
      }}
    >
      <div
        ref={contentRef}
        style={{
          width: contentSize,
          marginLeft: 'auto',
          marginRight: 'auto',
          transform: contentTransform
        }}
      >
        <Preview
          url={(apiRoot ? APIRoot : APIVerificationRoot) + doc?.filePath}
          filename={doc?.originalName || ''}
          requestType='GET'
          mimetype={doc?.mimetype}
          rotationPdf={rotation}
        />
      </div>
    </div>
  )
}

const VerificationDocumentPreview: FC<{
  docs: (IDocument & { category?: AttachmentCategory | undefined })[]
  className: string
  apiRoot?: boolean
}> = ({ docs, apiRoot, className }) => {
  const {
    rotation,
    rotateClockwise,
    rotateCounterClockwise,
    isFlip,
    handleFlip,
    handleReset: handleResetRotation
  } = useRotation()
  const {
    containerRef,
    cursor,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    handleZoomIn,
    handleZoomOut,
    contentRef,
    contentSize,
    contentTransform,
    handleReset: handleResetZoomable
  } = useZoomable()

  const containerWidth = containerRef?.current?.getBoundingClientRect()?.width || 0
  const containerHeight = containerRef?.current?.getBoundingClientRect()?.height || 0

  return (
    <div className='py-6 px-8 rounded-xl bg-grayscale-450'>
      <div className='mb-10 flex items-center justify-between'>
        <div className='font-semibold leading-5 truncate'>{docs[0]?.originalName}</div>
        <div className='ml-10 flex justify-center gap-8'>
          <CustomIconButton onClick={rotateCounterClockwise}>
            <RotateLeftIcon />
          </CustomIconButton>
          <CustomIconButton onClick={handleFlip}>
            <RotateFlipIcon />
          </CustomIconButton>
          <CustomIconButton onClick={rotateClockwise}>
            <RotateRightIcon />
          </CustomIconButton>
          <CustomIconButton onClick={handleZoomOut}>
            <LoupMinusIcon />
          </CustomIconButton>
          <CustomIconButton onClick={handleZoomIn}>
            <LoupPlusIcon />
          </CustomIconButton>
          <CustomIconButton
            onClick={() => {
              handleResetRotation()
              handleResetZoomable()
            }}
          >
            <RefreshIcon />
          </CustomIconButton>
        </div>
      </div>
      <div className={c('relative max-w-full overflow-auto', className)}>
        {docs.length &&
          docs.map((doc) => (
            <DocumentPrev
              key={doc._id}
              doc={doc}
              apiRoot={apiRoot}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              containerRef={containerRef}
              contentRef={contentRef}
              cursor={cursor}
              rotation={rotation}
              containerWidth={containerWidth}
              containerHeight={containerHeight}
              isFlip={isFlip}
              contentSize={contentSize}
              contentTransform={contentTransform}
            />
          ))}
      </div>
    </div>
  )
}

export default VerificationDocumentPreview
