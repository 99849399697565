import { FC, useRef, useEffect } from 'react'
import { GlobalWorkerOptions, getDocument, PageViewport } from 'pdfjs-dist'
import type { PDFPageProxy } from 'pdfjs-dist'
import pdfWorkerUrl from 'pdfjs-dist/build/pdf.worker.mjs?worker&url'
import { suspend } from 'suspend-react'

GlobalWorkerOptions.workerSrc = pdfWorkerUrl

const PDFPage: FC<{ page: PDFPageProxy; viewport: PageViewport; rotation?: number }> = ({
  page,
  viewport,
  rotation = 0
}) => {
  const containerRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (!containerRef.current) return
    const canvas = containerRef.current
    const context = canvas.getContext('2d')
    if (!context) return

    canvas.width = viewport.width
    canvas.height = viewport.height
    const renderTask = page.render({
      canvasContext: context,
      viewport
    })

    return () => {
      // cancel call does not cancel it immediately
      // it can cause an error if the new render happens before the old one is ended
      // but it is not a problem in this case, the error is just annoying
      if (renderTask) renderTask.cancel()
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      renderTask.promise.catch(() => {})
    }
  }, [page, viewport])

  const containerWidth = containerRef.current?.width || 0
  const containerHeight = containerRef.current?.height || 0

  return (
    <canvas
      className='object-cover w-full'
      ref={containerRef}
      style={{
        transform: `rotate(${rotation}deg) scale(${
          rotation % 180 === 0
            ? 1
            : containerWidth < containerHeight
            ? containerWidth / containerHeight
            : containerHeight / containerWidth
        })`,
        transition: 'transform 0.3s'
      }}
    />
  )
}

const PDFDoc: FC<{ file: File; rotation?: number }> = ({ file, rotation }) => {
  const urlObject = useRef<string>()

  const [pages, viewports] = suspend(async () => {
    const url = URL.createObjectURL(file)
    urlObject.current = url

    const pages: PDFPageProxy[] = []
    const viewports: PageViewport[] = []

    try {
      const document = await getDocument(url).promise
      // iterate over pages and load them with promise all
      const promises = Array.from(Array(document.numPages).keys()).map(async (pageNumber) => {
        const page = await document.getPage(pageNumber + 1)
        const viewport = await page.getViewport({ scale: window.devicePixelRatio })
        pages[pageNumber] = page
        viewports[pageNumber] = viewport
      })

      await Promise.all(promises)
    } catch (error) {
      console.error(error)
    }

    return [pages, viewports] as const
  }, [file])

  useEffect(() => {
    return () => {
      if (!urlObject.current) return
      // release memory when url is not needed any more
      URL.revokeObjectURL(urlObject.current)
    }
  }, [file])

  return (
    <div>
      {pages.length
        ? pages.map((page, pageNumber) => (
            <PDFPage key={pageNumber} page={page} viewport={viewports[pageNumber]} rotation={rotation} />
          ))
        : 'Ошибка. Не удалось загрузить PDF-документ.'}
    </div>
  )
}

export default PDFDoc
