import { FC, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ReactComponent as TrashIcon } from '../../../svg/ui/delete.svg'
import { ReactComponent as EditIcon } from '../../../svg/ui/edit.svg'
import { ReactComponent as WarningIcon } from '../../../svg/ui/warning.svg'
import {
  useDeleteFundingTrancheMutation,
  useFundingSourcesByDealIdQuery,
  useFundingTranchesQuery
} from '../../../graphql/schema'
import { FundingSource } from '../../FundingSources'
import { formatMoneyDecimal } from '../../../utils/formatNumber'
import GridView, { GridViewConfig } from '../../../components/GridView/index'
import useNodes from '../../../hooks/useNodes'
import AddButton from '../../../components/AddButton/index'
import Modal from '../../../components/Modal'
import FundingSourcesForm from './FundingSourcesForm'
import Dropdown from '../../../components/Dropdown/index'
import FundingTrancheForm from './FundingTrancheForm'
import ConfirmationForm from '../../../components/ConfirmationForm'
import useScrollPagination from '../../../hooks/useScrollPagination'
import SuspenseModal from '../../../components/SuspenseModal.tsx'
import TransactionTable from './TransactionTable.tsx'
import useAccessCheck from '../../../hooks/useAccessCheck.ts'
import { Card, CardHeader, CardIconButton, CardMenu, CardTitle } from '../../../components/Card.tsx'
import AddItem from '../../../components/AddItem.tsx'

export interface FundingTranche {
  id: string
  dealId: string
  amount: number
  fundingSource: FundingSource
  createdAt: string
  number?: string
}

const Finances: FC = () => {
  const { id } = useParams<'id'>()
  const [fundingSourcesFormOpen, setFundingSourcesFormOpen] = useState(false)
  const [fundingTrancheFormOpen, setFundingTrancheFormOpen] = useState(false)
  const [deletionTrancheFormOpenById, setDeletionTrancheFormOpenById] = useState<string>()

  const hasAccessToFinances = useAccessCheck('deal.finances.view')

  const { data, refetch: refetchFundingSources } = useFundingSourcesByDealIdQuery({
    variables: { dealId: `${id}` },
    skip: !id
  })
  const fundingSources = useNodes(data?.dealFundingSources?.edges)
    ?.map((i) => i.fundingSource)
    .sort((fundingSource) => (fundingSource.name === 'Собственные средства' ? -1 : 1))

  const {
    data: fundingTranchesData,
    refetch: refetchFundingTranches,
    loading,
    error,
    fetchMore
  } = useFundingTranchesQuery({
    variables: { dealId: `${id}` },
    skip: !id
  })
  const fundingTranches = useNodes(fundingTranchesData?.fundingTranches?.edges)
  const { wrapperRef, isFetching } = useScrollPagination(fetchMore, fundingTranchesData?.fundingTranches?.pageInfo)

  const [deleteFundingTrancheMutation] = useDeleteFundingTrancheMutation()

  function deleteFundingTranche() {
    if (!deletionTrancheFormOpenById) {
      return
    }
    return deleteFundingTrancheMutation({
      variables: {
        input: {
          id: deletionTrancheFormOpenById
        }
      }
    })
  }

  const fundingTranchesTableConfig = useMemo<GridViewConfig<FundingTranche>>(
    () => ({
      grid: 'grid-cols-auto-4',
      columns: [
        { title: '№ транша', value: 'number' },
        { title: 'Источник', value: (t) => t.fundingSource.name },
        { title: 'Сумма', value: (t) => formatMoneyDecimal(t.amount) },
        {
          value: (t) => (
            <Dropdown className='ml-auto pl-4 pr-4'>
              <button
                className='flex items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
                onClick={() => setDeletionTrancheFormOpenById(t.id)}
              >
                <TrashIcon className='mr-7' title='Удалить' />
                <span className='text-grayscale-0'>Удалить</span>
              </button>
            </Dropdown>
          )
        }
      ]
    }),
    []
  )

  if (!hasAccessToFinances) return null

  return (
    <div className='grid grid-cols-2 gap-6'>
      <div className='flex flex-col gap-6'>
        <Card>
          {fundingSources?.length ? (
            <>
              <CardHeader>
                <CardTitle>Источник финансирования</CardTitle>
                <CardMenu>
                  <CardIconButton onClick={() => setFundingSourcesFormOpen(true)}>
                    <EditIcon />
                  </CardIconButton>
                </CardMenu>
              </CardHeader>
              <div className='px-5 pb-5'>
                <div className='rounded-md bg-surface-primary shadow-card'>
                  <table className='w-full'>
                    <tbody>
                      {fundingSources.map((fundingSource) => (
                        <tr key={fundingSource?.id} className='group border-t-1 border-grayscale-400 first:border-none'>
                          <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>
                            <Link className='hover:text-red-100' to={`/funding_sources/${fundingSource?.id}`}>
                              {fundingSource?.name}
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : (
            <AddItem onClick={() => setFundingSourcesFormOpen(true)} className='h-20'>
              Добавить источник финансирования
            </AddItem>
          )}
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Транши</CardTitle>
          </CardHeader>
          <div className='px-5 pb-5' ref={wrapperRef}>
            <GridView
              data={fundingTranches}
              config={fundingTranchesTableConfig}
              loading={loading || isFetching}
              error={error?.message}
            />
            {!fundingSources?.length ? (
              <button
                disabled
                className='mt-4 flex h-25 w-full items-center justify-center rounded-xl border-1 border-dashed border-grayscale-300 text-grayscale-250'
                title='Не выбран источник финансирования'
              >
                <WarningIcon className='mr-5' height='18px' width='18px' />
                <span>Не выбран источник финансирования</span>
              </button>
            ) : (
              <AddButton
                disable={!fundingSources.length}
                className='mt-4'
                onClick={() => setFundingTrancheFormOpen(true)}
              >
                Добавить транш
              </AddButton>
            )}
          </div>
        </Card>
      </div>

      <div className='flex flex-col gap-6'>
        <TransactionTable dealId={`${id}`} />
      </div>

      <SuspenseModal open={fundingSourcesFormOpen} setOpen={setFundingSourcesFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <FundingSourcesForm
            dealId={`${id}`}
            onDone={() => {
              setFundingSourcesFormOpen(false)
              refetchFundingSources()
            }}
          />
        </div>
      </SuspenseModal>
      <SuspenseModal open={fundingTrancheFormOpen} setOpen={setFundingTrancheFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <FundingTrancheForm
            dealId={`${id}`}
            onDone={() => {
              setFundingTrancheFormOpen(false)
              refetchFundingTranches()
            }}
          />
        </div>
      </SuspenseModal>
      <Modal open={!!deletionTrancheFormOpenById} setOpen={() => setDeletionTrancheFormOpenById(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ConfirmationForm
            title='Удаление транша'
            onDone={async () => {
              setDeletionTrancheFormOpenById(undefined)
              await deleteFundingTranche()
              await refetchFundingTranches()
            }}
            onDismiss={() => setDeletionTrancheFormOpenById(undefined)}
          >
            Вы действительно хотите удалить транш?
          </ConfirmationForm>
        </div>
      </Modal>
    </div>
  )
}

export default Finances
