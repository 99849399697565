import { useState } from 'react'

export const useRotation = () => {
  const [rotation, setRotation] = useState(0)
  const [isFlip, setIsFlip] = useState(false)

  const rotateClockwise = () => {
    setRotation((prevRotation) => prevRotation + 90)
  }

  const rotateCounterClockwise = () => {
    setRotation((prevRotation) => prevRotation - 90)
  }

  const handleFlip = () => setIsFlip((prevState) => !prevState)

  const handleReset = () => {
    setRotation(0)
    setIsFlip(false)
  }

  return { rotation, rotateClockwise, rotateCounterClockwise, isFlip, handleFlip, handleReset }
}
