import { FC } from 'react'
import c from 'clsx'
import { ReactComponent as PlusIcon } from '../svg/icons/add.svg'

interface SearchProps {
  onClick: () => void
  children: React.ReactNode
  className?: string
  disabled?: boolean
}

const AddItem: FC<SearchProps> = ({ onClick, children, className, disabled = false }) => {
  return (
    <button
      onClick={onClick}
      className={c(
        'flex w-full items-center rounded-xl px-4 py-2 font-medium text-labels-tertiary hover:bg-surface-tertiary hover:text-labels-primary',
        className
      )}
      disabled={disabled}
    >
      <PlusIcon className='mx-5' />
      <span>{children}</span>
    </button>
  )
}

export default AddItem
