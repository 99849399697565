import { FC } from 'react'
import { ReactComponent as SuccessIcon } from '../../svg/ui/success.svg'
import { ReactComponent as ErrorIcon } from '../../svg/ui/error.svg'
import c from 'clsx'
import StatusRing from '../StatusRing'
export interface AppStatusProps {
  status?: string
  className?: string
}

// FIXME: в базе статус сделки это просто строка, а не enum
export enum AppStatus {
  FirstContact = 'Первичный контакт',
  Reconciliation = 'Ознакомление с расчётом',
  DecisionMaking = 'Принятие решения',
  DataCollection = 'Сбор данных',
  Scoring = 'Скоринг',
  DealPreparation = 'Подготовка к сделке',
  Deal = 'Сделка',
  Closed = 'Закрыто и не реализовано'
}

const statusOrder = Object.values(AppStatus)

export const statusColorDict: Record<AppStatus, string> = {
  [AppStatus.FirstContact]: 'bg-tr-cyan',
  [AppStatus.Reconciliation]: 'bg-tr-blue',
  [AppStatus.DecisionMaking]: 'bg-tr-purple',
  [AppStatus.DataCollection]: 'bg-tr-pink',
  [AppStatus.Scoring]: 'bg-tr-orange',
  [AppStatus.DealPreparation]: 'bg-tr-yellow',
  [AppStatus.Deal]: 'bg-tr-green',
  [AppStatus.Closed]: 'bg-tr-red'
}

export const statusTextColorDict: Record<AppStatus, string> = {
  [AppStatus.FirstContact]: 'text-dark-cyan',
  [AppStatus.Reconciliation]: 'text-dark-blue',
  [AppStatus.DecisionMaking]: 'text-dark-purple',
  [AppStatus.DataCollection]: 'text-dark-pink',
  [AppStatus.Scoring]: 'text-dark-orange',
  [AppStatus.DealPreparation]: 'text-dark-yellow',
  [AppStatus.Deal]: 'text-dark-green',
  [AppStatus.Closed]: 'text-dark-red'
}

const statusIconColorDict: Record<AppStatus, string> = {
  [AppStatus.FirstContact]: 'text-base-cyan',
  [AppStatus.Reconciliation]: 'text-base-blue',
  [AppStatus.DecisionMaking]: 'text-base-purple',
  [AppStatus.DataCollection]: 'text-base-pink',
  [AppStatus.Scoring]: 'text-base-orange',
  [AppStatus.DealPreparation]: 'text-base-yellow',
  [AppStatus.Deal]: 'text-dark-green',
  [AppStatus.Closed]: 'text-dark-red'
}

const maxSegments = 6

/**
 * when on colored background success and error icons should be darker (designer's request)
 * set bg to true to use this behavior
 */
const ApplicationStatusCircle: FC<AppStatusProps & { bg?: boolean }> = ({
  status: statusString,
  className,
  bg = false
}) => {
  const status = statusString as AppStatus

  const statusNumber = statusOrder.indexOf(status) + 1

  return (
    <div className={c(statusTextColorDict[status], className)}>
      {statusNumber <= maxSegments && (
        <StatusRing className={statusIconColorDict[status]} maxSegments={maxSegments} activeSegments={statusNumber} />
      )}
      {status === AppStatus.Deal && <SuccessIcon className={bg ? 'text-dark-green' : 'text-base-green'} />}
      {status === AppStatus.Closed && <ErrorIcon className={bg ? 'text-dark-red' : 'text-base-red'} />}
    </div>
  )
}

const ApplicationStatus: FC<AppStatusProps> = ({ status: statusString, className }) => {
  const status = statusString as AppStatus
  return (
    <div className={c('rounded-md p-4', statusTextColorDict[status], statusColorDict[status], className)}>
      <ApplicationStatusCircle status={status} bg={true} />
    </div>
  )
}

const ApplicationStatusBadge: FC<AppStatusProps> = ({ status: statusString }) => {
  const status = statusString as AppStatus

  return (
    <div
      className={c(
        'flex h-13 items-center justify-center gap-x-3 rounded-md px-3',
        statusTextColorDict[status],
        statusColorDict[status]
      )}
    >
      <ApplicationStatusCircle status={status} bg={true} />
      <div>{status}</div>
    </div>
  )
}

export default ApplicationStatus
export { ApplicationStatusCircle, ApplicationStatusBadge }
