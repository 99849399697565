import { FC, useState } from 'react'
import { ReactComponent as ZipIcon } from '../../svg/icons/zip.svg'
import { ReactComponent as LoadingIcon } from '../../svg/icons/loading.svg'
import downloadAndSaveFile from '../../utils/downloadFile'
import { getFileArchiveUrl } from '../../utils/getFileArchiveUrl'
import { showPopup } from '../Toaster/showPopup.tsx'

interface GroupZipProps {
  name: string
  ids: number[]
}

const GroupZip: FC<GroupZipProps> = ({ name, ids }) => {
  const [loading, setLoading] = useState(false)

  if (!ids.length) return null

  return (
    <button
      disabled={loading}
      className='text-grayscale-250'
      onClick={async () => {
        setLoading(true)
        try {
          await downloadAndSaveFile(getFileArchiveUrl(name, ids), name)
        } catch (e) {
          showPopup({ title: 'Ошибка', subtitle: 'Не удалось скачать архив' })
          console.error(e)
        } finally {
          setLoading(false)
        }
      }}
    >
      {loading ? (
        <LoadingIcon className='flex-none animate-spin' width='20px' height='20px' />
      ) : (
        <ZipIcon className='hover:text-red-100' />
      )}
    </button>
  )
}

export default GroupZip
