import { FC, ComponentProps } from 'react'
import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'
import { ReactComponent as DeleteIcon } from '../../svg/icons/trash.svg'

export type ButtonType = 'edit' | 'delete'

const buttonIconDict: Record<ButtonType, FC<ComponentProps<'svg'>>> = {
  edit: EditIcon,
  delete: DeleteIcon,
}

const buttonTitleDict: Record<ButtonType, string> = {
  edit: 'Редактировать',
  delete: 'Удалить',
}

interface DropdownButtonProps extends ComponentProps<'button'> {
  title?: string
  // action should be one of ButtonType
  action: ButtonType
}

const DropdownButton: FC<DropdownButtonProps> = ({ title, action, ...props }) => {
  const ButtonIcon = buttonIconDict[action]

  return (
    <button
      className='pl-5 pr-10 py-5 flex items-center text-grayscale-200 hover:text-red-100'
      {...props}
    >
      <ButtonIcon className='mr-7' height='18px' width='18px' />
      <span className='text-grayscale-0'>{title || buttonTitleDict[action]}</span>
    </button>
  )
}

export default DropdownButton