import {
  DealStatus,
  SourceCategory,
  GuarantorStatus,
  GuarantorSignMethod,
  PaymentKind,
  SupplyingStatus,
  BankOperationState,
  VerificationState,
  TaskKind,
  SmsErrorCode,
  AccreditationStatus,
  RateCalculationLiquidityType,
  RateCalculationAgeType,
  LiquidityType,
  AgeType,
  VatKind,
  SourceChannelsStatus,
  DocumentTypeEntity,
  EngagePartnerStatus,
  ScoringProcessStatus,
  PaymentCollectionProcessStatus,
  RetroBonusKind,
  PledgeKind,
  InsuranceKind
} from '../graphql/schema'
import { AppStatus } from '../components/ApplicationStatus'
import { SourceChannelsKind } from '../containers/Source/Channels.tsx'

export const dealStatusDict: Record<DealStatus, string> = {
  [DealStatus.Preparing]: 'Подготовка',
  [DealStatus.Signing]: 'Подписание',
  [DealStatus.Activation]: 'Активация',
  [DealStatus.PaymentReception]: 'Платежи',
  [DealStatus.Closed]: 'Завершена',
  [DealStatus.Cancelled]: 'Отменена',
  [DealStatus.Terminated]: 'Расторгнута'
}

export const reverseDealStatusDict: Record<string, DealStatus> = {
  Подготовка: DealStatus.Preparing,
  Подписание: DealStatus.Signing,
  Активация: DealStatus.Activation,
  Платежи: DealStatus.PaymentReception,
  Завершена: DealStatus.Closed,
  Отменена: DealStatus.Cancelled,
  Расторгнута: DealStatus.Terminated
}

export const collectionStatusDict: Record<PaymentCollectionProcessStatus, string> = {
  [PaymentCollectionProcessStatus.Warning]: 'Первое предупреждение',
  [PaymentCollectionProcessStatus.Penalty]: 'Штрафы',
  [PaymentCollectionProcessStatus.Claim]: 'Претензия',
  [PaymentCollectionProcessStatus.PreparingLawsuit]: 'Подготовка иска',
  [PaymentCollectionProcessStatus.Lawsuit]: 'Иск',
  [PaymentCollectionProcessStatus.Finished]: 'Завершено'
}

export const sourceCategoryDict: Record<SourceCategory, string> = {
  [SourceCategory.Agent]: 'Агент',
  [SourceCategory.Marketplace]: 'Маркетплейс',
  [SourceCategory.Supplier]: 'Поставщик',
  [SourceCategory.Fera]: 'Фера'
}

export const guarantorStatusDict: Record<GuarantorStatus, string> = {
  [GuarantorStatus.Signed]: 'Подписан',
  [GuarantorStatus.NotSigned]: 'Направлен'
}

export const guarantorSignMethod: Record<GuarantorSignMethod, string> = {
  [GuarantorSignMethod.Edo]: 'ЭДО',
  [GuarantorSignMethod.Otp]: 'Электронное',
  [GuarantorSignMethod.Personal]: 'Личное'
}

export const paymentKindDict: Record<PaymentKind, string> = {
  [PaymentKind.Leasing]: 'Лизинговый',
  [PaymentKind.Advance]: 'Аванс',
  [PaymentKind.Commission]: 'Комиссия',
  [PaymentKind.Redemption]: 'Выкупной',
  [PaymentKind.Insurance]: 'Страховка',
  [PaymentKind.Service]: 'Услуга',
  [PaymentKind.Penalty]: 'Пени',
  [PaymentKind.Compensation]: 'Компенсация расходов',
  [PaymentKind.DebtTransferService]: 'Переуступка',
  [PaymentKind.Buyout]: 'Досрочный выкуп',
  [PaymentKind.Body]: 'Оплата тела'
}

export const supplyingStatusDict: Record<SupplyingStatus, string> = {
  [SupplyingStatus.Waiting]: 'Ожидается',
  [SupplyingStatus.Partially]: 'Частично',
  [SupplyingStatus.Shipped]: 'Отгружено'
}

export const bankOperationStateDict: Record<BankOperationState, string> = {
  [BankOperationState.Processed]: 'Разнесённые',
  [BankOperationState.Pending]: 'Неразнесённые'
}

export const retrobonusKindDict: Record<RetroBonusKind, string> = {
  [RetroBonusKind.WithRetrobonus]: 'С ретробонусом',
  [RetroBonusKind.WithoutRetrobonus]: 'Без ретробонуса'
}

export const pledgeKindDict: Record<PledgeKind, string> = {
  [PledgeKind.WithPledge]: 'С залогом',
  [PledgeKind.WithoutPledge]: 'Без залога'
}

export const insuranceKindDict: Record<InsuranceKind, string> = {
  [InsuranceKind.WithoutInsurance]: 'Без страховки',
  [InsuranceKind.WithInsurance]: 'Со страховкой'
}

export const applicationStatusDict: Record<AppStatus, string> = {
  [AppStatus.FirstContact]: 'Первичный контакт',
  [AppStatus.Reconciliation]: 'Ознакомление с расчётом',
  [AppStatus.DecisionMaking]: 'Принятие решения',
  [AppStatus.DataCollection]: 'Сбор данных',
  [AppStatus.Scoring]: 'Скоринг',
  [AppStatus.DealPreparation]: 'Подготовка к сделке',
  [AppStatus.Deal]: 'Сделка',
  [AppStatus.Closed]: 'Закрыто и не реализовано'
}

export const verificationStateDict: Record<VerificationState, string> = {
  [VerificationState.Completed]: 'Ожидает проверки',
  [VerificationState.Rejected]: 'Отказано',
  [VerificationState.Accepted]: 'Проверен',
  [VerificationState.Pending]: 'Заполняется',

  [VerificationState.PersonVerification]: 'PersonVerification',
  [VerificationState.PhoneVerification]: 'PhoneVerification',
  [VerificationState.Questionnaire]: 'Questionnaire',
  [VerificationState.Geolocation]: 'Geolocation'
}

export const taskKindDict: Record<TaskKind, string> = {
  [TaskKind.Task]: 'Задача',
  [TaskKind.Contact]: 'Связаться',
  [TaskKind.Meeting]: 'Встреча'
}

export const taskKindColorDict: Record<TaskKind, string> = {
  [TaskKind.Task]: 'bg-purple-50 text-purple-600',
  [TaskKind.Contact]: 'bg-green-50 text-green-600',
  [TaskKind.Meeting]: 'bg-rose-50 text-rose-600'
}

export const smsErrorCodeDict: Record<SmsErrorCode, string> = {
  [SmsErrorCode.Canceled]: 'Отправка отменена',
  [SmsErrorCode.InvalidPhone]: 'Неверный номер',
  [SmsErrorCode.OperatorError]: 'Ошибка оператора',
  [SmsErrorCode.Timeout]: 'Истекло количество попыток доставки',
  [SmsErrorCode.Unknown]: 'Неизвестная ошибка'
}

export const companyAccreditationStatusDict: Record<AccreditationStatus, string> = {
  [AccreditationStatus.Pending]: 'Отправить на аккредитацию',
  [AccreditationStatus.Requested]: 'Ожидает проверки',
  [AccreditationStatus.InProgress]: 'В работе',
  [AccreditationStatus.Accepted]: 'Аккредитован',
  [AccreditationStatus.Rejected]: 'Отказано'
}

export const liquidityTypeDict: Record<LiquidityType, string> = {
  [LiquidityType.LiquidHighRisk]: 'Высокий риск',
  [LiquidityType.LiquidLowRisk]: 'Низкий риск',
  [LiquidityType.NotLiquid]: 'Неликвидный'
}

export const rateCalculationLiquidityTypeDict: Record<RateCalculationLiquidityType, string> = {
  [RateCalculationLiquidityType.LiquidHighRisk]: 'Высокий риск',
  [RateCalculationLiquidityType.LiquidLowRisk]: 'Низкий риск',
  [RateCalculationLiquidityType.NotLiquid]: 'Неликвидный'
}

export const rateCalculationAgeTypeDict: Record<RateCalculationAgeType, string> = {
  [RateCalculationAgeType.Less_1Year]: 'Менее 1 года',
  [RateCalculationAgeType.From_1To_3Years]: 'От 1 до 3 лет',
  [RateCalculationAgeType.From_3To_5Years]: 'От 3 до 5 лет',
  [RateCalculationAgeType.More_5Years]: 'Более 5 лет',
  [RateCalculationAgeType.More_5YearsWoLossesAndRevenueGrowth]: 'Более 5 лет, 3 года рост выручки без убытков',
  [RateCalculationAgeType.More_5YearsWoLossesOrRevenueGrowth]: 'Более 5 лет, 3 года рост выручки или без убытков'
}

export const ageTypeDict: Record<AgeType, string> = {
  [AgeType.Less_1Year]: 'Менее 1 года',
  [AgeType.From_1To_3Years]: 'От 1 до 3 лет',
  [AgeType.From_3To_5Years]: 'От 3 до 5 лет',
  [AgeType.More_5Years]: 'Более 5 лет',
  [AgeType.More_5YearsWoLossesAndRevenueGrowth]: 'Более 5 лет, 3 года рост выручки без убытков',
  [AgeType.More_5YearsWoLossesOrRevenueGrowth]: 'Более 5 лет, 3 года рост выручки или без убытков'
}

export const vatKindDict: Record<VatKind, string> = {
  [VatKind.Osno]: 'ОСНО',
  [VatKind.Usn]: 'УСН',
  [VatKind.Medicine]: 'Медицина',
  [VatKind.Regular]: 'Обычный'
}

export const sourceChannelsStatusDict: Record<SourceChannelsStatus, string> = {
  [SourceChannelsStatus.InWork]: 'В работе',
  [SourceChannelsStatus.Issued]: 'Выдано',
  [SourceChannelsStatus.Test]: 'Тестируется',
  [SourceChannelsStatus.NotIssued]: 'Не выдано'
}

export const sourceChannelsKindDict: Record<SourceChannelsKind, string> = {
  [SourceChannelsKind.Customer]: 'Клиентская форма',
  [SourceChannelsKind.Partner]: 'Партнёрская форма',
  [SourceChannelsKind.Api]: 'API',
  [SourceChannelsKind.Widget]: 'Виджет'
}

export const entityTypeDict: Record<DocumentTypeEntity, string> = {
  [DocumentTypeEntity.AgentCommissionReward]: 'Комиссионное вознаграждение агента',
  [DocumentTypeEntity.Application]: 'Заявка',
  [DocumentTypeEntity.Contact]: 'Контакт',
  [DocumentTypeEntity.CustomerCompany]: 'Клиент',
  [DocumentTypeEntity.Deal]: 'Сделка',
  [DocumentTypeEntity.DealRetrobonus]: 'Ретробонус сделки',
  [DocumentTypeEntity.DealSupply]: 'Поставка сделки',
  [DocumentTypeEntity.FundingSource]: 'Источник финансирования',
  [DocumentTypeEntity.Guarantor]: 'Гарант',
  [DocumentTypeEntity.Source]: 'Источник',
  [DocumentTypeEntity.SupplierCompany]: 'Поставщик',
  [DocumentTypeEntity.ApplicationSupplierCompany]: 'Поставщик в заявке',
  [DocumentTypeEntity.Shipment]: 'Отгрузка',
  [DocumentTypeEntity.GuarantorCompany]: 'Компания-поручитель'
}

export const supplierStatusDict: Record<EngagePartnerStatus, string> = {
  [EngagePartnerStatus.Pending]: 'В ожидании',
  [EngagePartnerStatus.InProgress]: 'В работе',
  [EngagePartnerStatus.Enlisted]: 'Привлечён',
  [EngagePartnerStatus.Hold]: 'Отложено'
}

export const scoringStatusDict: Record<ScoringProcessStatus, string> = {
  [ScoringProcessStatus.InProgress]: 'В работе',
  [ScoringProcessStatus.WaitingForAdditionalInfo]: 'Доп. запрос',
  [ScoringProcessStatus.Done]: 'Готово'
}
