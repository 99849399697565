import { FC, useMemo, useState } from 'react'
import { ContactsQuery } from '../graphql/schema'
import { NodeType } from '../types'
import GridView, { GridViewConfig } from './GridView'
import Dropdown from './Dropdown'
import useCopyVerificationLink from '../hooks/useCopyVerificationLink'
import { ReactComponent as EditIcon } from '../svg/icons/edit.svg'
import { ReactComponent as LoadingIcon } from '../svg/icons/loading.svg'
import { ReactComponent as TickIcon } from '../svg/icons/tick.svg'
import { ReactComponent as LinkIcon } from '../svg/icons/link.svg'
import { ReactComponent as TrashIcon } from '../svg/icons/trash.svg'
import { dateTimeFormatter } from '../utils/dateFormatter'
import { verificationRiskColor, VerificationState, verificationStateDict } from '../utils/verification'
import c from 'clsx'
import useAccessCheck from '../hooks/useAccessCheck.ts'

export type Contact = NodeType<ContactsQuery['contacts']>

interface ContactsListProps {
  contactsList: Contact[]
  onEdit: (id: number) => void
  onDelete?: (id: number) => void
  loading: boolean
  error?: string
}

const ContactsList: FC<ContactsListProps> = ({ contactsList, onEdit, onDelete, loading, error }) => {
  const [loadAndCopyLink, linkLoading] = useCopyVerificationLink()
  const [linkDone, setLinkDone] = useState(false)

  const hasAccessEditContactAfterAccepted = useAccessCheck('contact.edit_after_accepted')

  const appTableConfig = useMemo<GridViewConfig<Contact>>(
    () => ({
      grid: 'grid-cols-contact',
      columns: [
        { title: 'ID', value: '_id' },
        { title: 'Создано', value: (a) => dateTimeFormatter.format(new Date(a?.created)) },
        { title: 'ФИО', value: 'fio' },
        { title: 'Телефон', value: 'phone' },
        { title: 'Email', value: 'email' },
        {
          title: 'Статус',
          value: (a) =>
            a.verificationStatus === 'accepted' &&
            a.verificationAcceptExpiresAt &&
            new Date() > new Date(a.verificationAcceptExpiresAt) ? (
              <div className='flex items-center justify-start'>
                Верификация просрочена
                <div className='ml-5 h-6 w-6 rounded-full bg-red-100' />
              </div>
            ) : (
              <div className='flex items-center justify-start'>
                {verificationStateDict[a.verificationStatus as VerificationState]}
                <div
                  className={c(
                    'ml-5 h-6 w-6 rounded-full',
                    verificationRiskColor[a.verificationStatus as VerificationState]
                  )}
                />
              </div>
            )
        },
        {
          value: (a) => {
            const canEdit = a?.verificationStatus === 'accepted' ? hasAccessEditContactAfterAccepted : true

            return (
              <Dropdown className='ml-auto pl-4 pr-4'>
                {canEdit ? (
                  <button
                    className='flex items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
                    onClick={() => onEdit(a._id)}
                  >
                    <EditIcon className='mr-7' title='Редактировать' />
                    <span className='text-grayscale-0'>Редактировать</span>
                  </button>
                ) : (
                  <></>
                )}
                <button
                  className='flex w-95 items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
                  onClick={async () => {
                    await loadAndCopyLink(a._id)
                    setLinkDone(true)
                    await new Promise((res) => setTimeout(res, 1000))
                    setTimeout(() => setLinkDone(false), 100)
                  }}
                >
                  {linkLoading ? (
                    <LoadingIcon className='mr-7 flex-none animate-spin' width='20px' height='20px' />
                  ) : linkDone ? (
                    <TickIcon className='mr-7 flex-none' width='20px' height='20px' title='Верификация' />
                  ) : (
                    <LinkIcon className='mr-7 flex-none' width='20px' height='20px' title='Верификация' />
                  )}
                  <span className='text-grayscale-0'>{linkDone ? 'Скопировано' : 'Верификация'}</span>
                </button>
                {onDelete ? (
                  <button
                    className='flex items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
                    onClick={() => {
                      onDelete(a._id)
                    }}
                  >
                    <TrashIcon className='mr-7' title='Удалить' />
                    <span className='text-grayscale-0'>Удалить</span>
                  </button>
                ) : (
                  <></>
                )}
              </Dropdown>
            )
          }
        }
      ],
      rows: {
        link: (a) => `/contacts/${a?._id}`
      }
    }),
    [onEdit, loadAndCopyLink, setLinkDone, linkLoading, linkDone, onDelete, hasAccessEditContactAfterAccepted]
  )

  return <GridView data={contactsList} config={appTableConfig} loading={loading} error={error} />
}

export default ContactsList
