const regExpKppCode = /^\d{4}\w{2}\d{3}$/
const regExpZeroStr = /^0*$/

const checkKPP = (kpp?: string | number) =>
  kpp &&
  (
    (typeof kpp === 'string' && regExpKppCode.test(kpp) && !regExpZeroStr.test(kpp)) ||
    (typeof kpp === 'number' && kpp >= 10e9 && kpp < 10e10)
  )

export default checkKPP
