import { MouseEvent, RefObject, useEffect, useRef, useState } from 'react'

interface useZoomableOutput {
  containerRef: RefObject<HTMLDivElement>
  cursor: string
  handleMouseDown: (event: MouseEvent<HTMLDivElement>) => void
  handleMouseMove: (event: MouseEvent<HTMLDivElement>) => void
  handleMouseUp: () => void
  handleReset: () => void
  handleZoomIn: () => void
  handleZoomOut: () => void
  contentRef: RefObject<HTMLDivElement>
  contentSize: string
  contentTransform: string
}

export const useZoomable = (): useZoomableOutput => {
  const [zoomLevel, setZoomLevel] = useState(1)
  const [offsetX, setOffsetX] = useState(0)
  const [offsetY, setOffsetY] = useState(0)
  const [isDragging, setIsDragging] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  const contentRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const prevClientXRef = useRef<number | null>(null)
  const prevClientYRef = useRef<number | null>(null)

  const handleZoomIn = () => {
    if (zoomLevel < 3) {
      setZoomLevel(zoomLevel + 0.1)
    }
    if (zoomLevel >= 1) {
      const contentRect = contentRef.current?.getBoundingClientRect()
      const containerRect = containerRef.current?.getBoundingClientRect()

      if (contentRect && containerRect) {
        const contentWidth = contentRect.width * zoomLevel
        const containerWidth = containerRect.width

        const newOffsetX = (containerWidth - contentWidth) / 4

        setOffsetX(newOffsetX)
      }
    } else {
      setOffsetX(0)
    }
  }

  const handleZoomOut = () => {
    if (zoomLevel <= 1.1) {
      setOffsetX(0)
      setOffsetY(0)
    }
    if (zoomLevel > 0.5) {
      setZoomLevel(zoomLevel - 0.1)
    }
    if (zoomLevel > 1) {
      const contentRect = contentRef.current?.getBoundingClientRect()
      const containerRect = containerRef.current?.getBoundingClientRect()

      if (contentRect && containerRect) {
        const contentWidth = contentRect.width * zoomLevel
        const containerWidth = containerRect.width

        const newOffsetX = (containerWidth - contentWidth) / 4

        setOffsetX(newOffsetX)
      }
    }
  }

  const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDragging(true)
    prevClientXRef.current = event.clientX
    prevClientYRef.current = event.clientY
  }

  const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
    if (!isDragging || zoomLevel <= 1 || isUpdating) return

    const contentRect = contentRef?.current?.getBoundingClientRect()
    const containerRect = containerRef?.current?.getBoundingClientRect()

    const contentWidth = (contentRect?.width || 0) * zoomLevel
    const contentHeight = (contentRect?.height || 0) * zoomLevel
    const containerWidth = containerRect?.width || 0
    const containerHeight = containerRect?.height || 0

    const maxOffsetX = (contentWidth - containerWidth) / 2
    const maxOffsetY = (contentHeight - containerHeight) / 2

    // Рассчитываем смещение между предыдущим и текущим положением мыши
    const deltaX = event.clientX - (prevClientXRef?.current || 0)
    const deltaY = event.clientY - (prevClientYRef?.current || 0)
    prevClientXRef.current = event.clientX
    prevClientYRef.current = event.clientY

    const rotation = getRotatorAngleFromTransform()

    // Применяем угол поворота к смещению
    const radianAngle = rotation * (Math.PI / 180) // Преобразуем градусы в радианы
    const rotatedDeltaX = Math.cos(radianAngle) * deltaX + Math.sin(radianAngle) * deltaY
    const rotatedDeltaY = -Math.sin(radianAngle) * deltaX + Math.cos(radianAngle) * deltaY

    setIsUpdating(true)
    requestAnimationFrame(() => {
      setOffsetX((prevOffsetX) => {
        const newOffsetX = prevOffsetX + rotatedDeltaX
        return Math.max(-maxOffsetX, Math.min(maxOffsetX, newOffsetX))
      })

      setOffsetY((prevOffsetY) => {
        const newOffsetY = prevOffsetY + rotatedDeltaY
        return Math.max(-maxOffsetY, Math.min(maxOffsetY, newOffsetY))
      })

      setIsUpdating(false)
    })
  }

  const getRotatorAngleFromTransform = () => {
    const parentDiv = containerRef?.current?.closest('.img-rotator')
    if (!parentDiv) return 0

    const computedStyle = window.getComputedStyle(parentDiv)
    const transform = computedStyle.transform

    let rotation = 0

    if (transform.includes('matrix')) {
      const matrix = transform.match(/matrix.*\((.+)\)/) || []
      const matrixValues = matrix[1] ? matrix[1].split(', ') : []
      if (matrixValues.length === 6) {
        const a = parseFloat(matrixValues[0])
        const b = parseFloat(matrixValues[1])
        rotation = Math.atan2(b, a) * (180 / Math.PI)
      }
    } else if (transform.includes('rotate')) {
      const match = transform.match(/rotate\(([^)]+)/)
      rotation = match ? parseFloat(match[1]) : 0
    }

    return rotation
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const handleReset = () => {
    setZoomLevel(1)
    setOffsetX(0)
    setOffsetY(0)
  }

  useEffect(() => {
    const handleMouseLeave = () => {
      setIsDragging(false)
    }

    document.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      document.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [])

  const contentSize = `${zoomLevel * 100}%`
  const contentTransform = `translate(${offsetX}px, ${offsetY}px)`
  const cursor = zoomLevel > 1 ? 'grab' : 'auto'

  return {
    containerRef,
    cursor,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    handleReset,
    handleZoomIn,
    handleZoomOut,
    contentRef,
    contentSize,
    contentTransform
  }
}
