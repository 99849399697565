import { FC, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ReactComponent as FileIcon } from '../../svg/icons/fileDownload.svg'
import SubmitButton from '../../components/SubmitButton'
import Input from '../../components/Input.tsx'
import { useCreateBkiConsentDocxMutation, useCustomerForBkiConsentSuspenseQuery } from '../../graphql/schema.tsx'
import { CompanyData } from '../../types/dadata.ts'
import { capitalizeFirst, getCompanyForm, getDateFormattedInDocumentName, isIP } from '../../utils/contractUtils.ts'
import { LEADER_STATUSES_GENITIVE_MAP } from '../Deal/models/constants.ts'
import getInclineFIO from '../../utils/getInclineFIO.ts'
import downloadFile from '../../utils/downloadFileServerless.ts'

const dateFormatter = new Intl.DateTimeFormat('ru-RU', {
  month: 'long',
  day: 'numeric',
  year: 'numeric'
})

type Inputs = {
  leaderStatusGenitiveWithName: string
  basis: string
}

const BkiConsentDocForm: FC<{
  customerId: string
  date?: string
}> = ({ customerId, date }) => {
  const [loading, setLoading] = useState(false)

  const { data } = useCustomerForBkiConsentSuspenseQuery({ variables: { id: customerId } })
  const customer = data?.customerCompany
  const companyDadata: CompanyData = useMemo(() => (customer?.dadata ? customer.dadata.data : {}), [customer?.dadata])
  const IP: boolean = isIP(companyDadata)
  if (IP) return null

  const companyName = capitalizeFirst(companyDadata.name?.full)
  let leaderStatus =
    LEADER_STATUSES_GENITIVE_MAP.get(companyDadata?.management?.post?.toLowerCase()) ||
    companyDadata?.management?.post?.toLowerCase() ||
    ''
  leaderStatus = capitalizeFirst(leaderStatus)
  const leaderStatusGenitiveWithName = companyDadata?.management?.name
    ? (leaderStatus + ' ' + getInclineFIO(companyDadata?.management?.name)).trim()
    : ''

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<Inputs>({
    defaultValues: {
      leaderStatusGenitiveWithName,
      basis: 'действующего на основании Устава'
    }
  })

  const [createBkiConsent] = useCreateBkiConsentDocxMutation()

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (loading) return

    setLoading(true)

    try {
      const createBkiConsentResult = await createBkiConsent({
        variables: {
          input: {
            companyName: `${customer?.fullWithOpf ? getCompanyForm(customer.fullWithOpf, companyName, true) : ''} «${companyName}» (${customer?.shortWithOpf ? getCompanyForm(customer.shortWithOpf, companyName) : ''} «${companyName}»)`,
            address: companyDadata?.address?.unrestricted_value,
            phoneNumber: '',
            email: '',
            ogrn: companyDadata?.ogrn,
            inn: customer?.inn,
            leaderStatusGenitiveWithName: data.leaderStatusGenitiveWithName,
            basis: data.basis,
            leaderName: companyDadata?.management?.name,
            date: dateFormatter.format(date ? new Date(date) : new Date())
          }
        }
      })

      const createBkiConsentUrl = createBkiConsentResult.data?.createBkiConsent?.url
      if (!createBkiConsentUrl) throw new Error('Не удалось получить ссылку на файл')

      await downloadFile(
        createBkiConsentUrl,
        `${getDateFormattedInDocumentName(new Date())} Согласие на получение кредитного отчета юр лица.docx`
      )
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
    setLoading(false)
  }

  return (
    <section className='w-[448px] p-12'>
      <h1 className='mb-12 font-display text-h200'>Согласие БКИ</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid grid-cols-1 gap-10'>
          <Input
            label='Кто отвечает за компанию'
            placeholder='Генерального директора Ивана Ивановича Иванова'
            type='string'
            {...register('leaderStatusGenitiveWithName')}
            error={errors?.leaderStatusGenitiveWithName}
          />
          <Input
            label='Основание'
            placeholder='действующего на основании Устава'
            type='string'
            {...register('basis')}
            error={errors?.basis}
          />
        </div>

        <SubmitButton className='mt-12' loading={loading}>
          <FileIcon className='mr-5' />
          Сгенерировать
        </SubmitButton>
      </form>
    </section>
  )
}

export default BkiConsentDocForm
