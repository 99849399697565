import { useMemo } from 'react'
import { BarRounded, BarStack } from '@visx/shape'
import { Group } from '@visx/group'
import { GridRows } from '@visx/grid'
import { AxisBottom, AxisLeft } from '@visx/axis'
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale'
import { localPoint } from '@visx/event'
import { useTooltip, TooltipWithBounds } from '@visx/tooltip'
import { formatMoney, formatNumber } from '../../../utils/formatNumber'
import { useParentSize } from '@visx/responsive'

const MARGIN = {
  left: 20,
  right: 20,
  bottom: 35,
  top: 25
}

const LEFT_AXIS = 66
const Y_TICKS = 4

export interface PaymentBar {
  month: Date
  pendingAmount: number
  paidOnTimeAmount: number
  paidOverdueAmount: number
  unpaidOverdueAmount: number
}

const getTotal = (d: PaymentBar) => d.pendingAmount + d.paidOnTimeAmount + d.paidOverdueAmount + d.unpaidOverdueAmount

const barKeys = ['paidOverdueAmount', 'paidOnTimeAmount', 'unpaidOverdueAmount', 'pendingAmount']

export type BarChartProps = {
  className?: string
  data: PaymentBar[]
}

const colorScale = scaleOrdinal<string, string>({
  domain: barKeys,
  range: ['fill-tr-red', 'fill-tr-green', 'fill-base-red', 'fill-surface-tertiary']
})

export default function BarChart({ className, data }: BarChartProps) {
  const { width, height, parentRef } = useParentSize()

  const seed = useMemo(() => Math.random().toString(36).substr(2, 9), [])

  // bounds
  const xMax = width - MARGIN.left - MARGIN.right - LEFT_AXIS
  const yMax = height - MARGIN.top - MARGIN.bottom

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleBand({
        range: [0, xMax],
        round: true,
        padding: 0.2,
        domain: data.map((d) => d.month)
      }),
    [xMax, data]
  )

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [
          0,
          Math.max(
            ...data.map((d) => d.pendingAmount + d.paidOnTimeAmount + d.paidOverdueAmount + d.unpaidOverdueAmount)
          )
        ]
      }),
    [data, yMax]
  )

  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<PaymentBar>()

  return (
    <div ref={parentRef} className={className + ' relative'}>
      {!!width && (
        <svg width={width} height={height}>
          <Group
            data-id='hi'
            top={MARGIN.top}
            left={MARGIN.left + LEFT_AXIS}
            onPointerMove={(event) => {
              const eventSvgCoords = localPoint(event)
              if (!eventSvgCoords) return

              const stepSize = xScale.step()

              // when rounding is emabled there is a leftover space on the sides of the chart
              const roundingLeftover = xMax - stepSize * xScale.domain().length

              const chartX = eventSvgCoords.x - MARGIN.left - LEFT_AXIS - roundingLeftover / 2

              const index = Math.floor(chartX / stepSize)

              if (!data[index] || getTotal(data[index]) === 0) {
                hideTooltip()
                return
              }

              showTooltip({
                tooltipData: data[index],
                tooltipTop: eventSvgCoords.y,
                tooltipLeft: eventSvgCoords.x
              })
            }}
            onPointerLeave={() => {
              hideTooltip()
            }}
          >
            <GridRows
              className='text-separators-primary'
              scale={yScale}
              width={xMax}
              height={yMax}
              numTicks={Y_TICKS}
              stroke='currentColor'
            />
            <BarStack data={data} keys={barKeys} x={(d) => d.month} xScale={xScale} yScale={yScale} color={colorScale}>
              {(barStacks) => {
                const masks: JSX.Element[] = []
                barStacks[0].bars.forEach((bar) => {
                  const totalHeight = barStacks.reduce((acc, barStack) => acc + barStack.bars[bar.index].height, 0)
                  const lastBar = barStacks[barStacks.length - 1].bars[bar.index]
                  masks.push(
                    <clipPath key={`clip-${bar.index}`} id={`clip-${seed}-${bar.index}`}>
                      <BarRounded
                        key={`mask-${bar.index}`}
                        data-key={`mask-${bar.index}`}
                        x={bar.x}
                        y={lastBar.y}
                        width={bar.width}
                        height={totalHeight}
                        radius={5}
                        top={true}
                        className='black'
                      />
                    </clipPath>
                  )
                })
                return (
                  <>
                    {masks}
                    {barStacks.map((barStack) =>
                      barStack.bars.map((bar, i) => (
                        <BarRounded
                          key={`bar-${barStack.index}-${bar.index}`}
                          data-key={`bar-${barStack.index}-${bar.index}`}
                          x={bar.x}
                          y={bar.y}
                          width={bar.width}
                          height={bar.height}
                          fill={bar.color}
                          radius={0}
                          className={bar.color}
                          clipPath={`url(#clip-${seed}-${i})`}
                        />
                      ))
                    )}
                  </>
                )
              }}
            </BarStack>

            <rect width={xMax} height={yMax} className='fill-transparent' />
          </Group>
          <AxisLeft
            scale={yScale}
            top={MARGIN.top}
            left={MARGIN.left + LEFT_AXIS}
            tickClassName={'fill-separators-primary'}
            tickFormat={(value) => (value ? formatNumber(value.valueOf() * 100) : '0')}
            tickLabelProps={{ className: 'fill-labels-tertiary text-body-s' }}
            tickLineProps={{ className: 'stroke-separators-primary fill-separators-primary' }}
            axisLineClassName='stroke-separators-primary'
            numTicks={Y_TICKS}
            strokeWidth={1}
          />
          <AxisBottom
            scale={xScale}
            top={yMax + MARGIN.top}
            left={MARGIN.left + LEFT_AXIS}
            tickClassName={'fill-current text-labels-primary font-medium'}
            tickLabelProps={{ className: 'fill-labels-tertiary capitalize' }}
            tickFormat={(value) =>
              value
                .toLocaleDateString('ru-RU', { month: 'short', year: '2-digit' })
                .replace(' г', '')
                .replaceAll('.', '')
            }
            tickLineProps={{ className: 'stroke-separators-primary fill-separators-primary' }}
            axisLineClassName='stroke-separators-primary'
            strokeWidth={1}
          />
        </svg>
      )}

      {tooltipOpen && tooltipData && (
        <TooltipWithBounds
          unstyled={true}
          className='pointer-events-none absolute min-w-80 rounded-md bg-grayscale-0 px-7 py-4 text-base-white'
          top={tooltipTop}
          left={tooltipLeft}
        >
          <div className='mb-5 text-title-m font-medium capitalize'>
            {tooltipData.month.toLocaleString('ru-RU', { month: 'long', year: 'numeric' })}
          </div>
          <div>{formatMoney(getTotal(tooltipData))}</div>
          {tooltipData.unpaidOverdueAmount > 0 && (
            <div>Не оплачено: {formatMoney(tooltipData.unpaidOverdueAmount)}</div>
          )}
        </TooltipWithBounds>
      )}
    </div>
  )
}
