import { ScoringQuestion } from '../containers/Application/Scoring/ScoringQuestion.tsx'

export const PHONE_MASK = '+7 000 000 00 00'
export const EMAIL_PATTERN =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~а-яА-ЯёЁ-]+@[a-zA-Z0-9а-яА-ЯёЁ-]+(?:\.[a-zA-Z0-9а-яА-ЯёЁ-]+)*$/
export const REGULAR_VAT_RATE = 20
export const BALANCE_HOLDER = 'Лизингополучатель'
export const BALANCE_HOLDER_REC = 'Лизингодатель'
export const REDEMPTION_PAYMENT = 1000
export const TASK_QUOTES = [
  'Задач на сегодня больше нет — пора отдохнуть и набраться сил для новых битв.',
  'Поток задач иссяк, а я остаюсь на посту, готовый к новым вызовам.',
  'Закончил все дела на сегодня, теперь только медитация и подготовка к завтрашним битвам.',
  'Как самурай, я выполнил все задачи на сегодня и готов к новым испытаниям.',
  'Победа над задачами сегодня — это лишь начало пути к новым победам.',
  'В бою с задачами на сегодня я оставил их все на поле боя и победил.',
  'Сегодняшний день был выигран благодаря планированию и упорству в выполнении задач.',
  'Я завершил все задачи на сегодня и теперь могу спокойно закрыть глаза и отдохнуть.',
  'Как самурай, я не оглядываюсь на задачи, которые уже были выполнены, а готовлюсь к новым.',
  'Сегодняшний день подарил мне мир и покой, благодаря тому, что я справилась со всеми задачами.',
  'Катана без врагов, задачи без вызовов.',
  'Самурайский путь достигнут, задачи позади оставлены.',
  'Тени горницы пусты, задачи на исходе.',
  'Поле сражения почищено, задачи повержены в пыль.',
  'Самурая окружают молчание и покой, задачи опустошены до основания.',
  'Самурайское сердце жаждет новых вызовов, но задач больше нет.',
  'Задачи исчезли, словно рассеялись лепестки сакуры под ветром.',
  'Задачи смирились перед самураем, и теперь затаились в тени.',
  'Самурай проникся мудростью, и задачи стали лишь пустыми воспоминаниями.',
  'Верный меч самурая блестит, а на горизонте не видно остатков задач.'
]

export const SCORING_STATUTORY_QUESTIONS: ScoringQuestion[] = [
  { value: 'Необходимо ли одобрение крупной суммы?' },
  { value: 'Отсутствует протокол назначения генерального директора?' },
  { value: 'Отсутствует договор аренды или право собственности?' }
]
export const SCORING_TAX_QUESTIONS: ScoringQuestion[] = [
  { value: 'Гендир в ЕГРЮЛ не совпадает с заявленным в протоколе?' },
  { value: 'Находится в списке массовых руководителей?' },
  { value: 'Находится в списке дисквалифицированных?' },
  { value: 'Юр.адрес не совпадает с договором аренды/собственности?' },
  { value: 'Сведения о задолженности > 1000 ₽ по уплате налогов, которая направлялась приставам?' },
  { value: 'Сведения о непредставлении налоговой отчётности более года?' },
  { value: 'Сведения о суммах недоимки по пеням и штрафам?', fields: [''] },
  { value: 'Налоговые правонарушения?', fields: ['С чем связаны блокировки?'] },
  { value: 'Указать среднесписочное количество сотрудников организации?', fields: [''] },
  { value: 'Специальный налоговый режим не применяется?' }
]
export const SCORING_LITIGATION_AND_LEASING_QUESTIONS: ScoringQuestion[] = [
  {
    value: 'Есть сообщения о получении лизинга?',
    fields: ['Сколько раз компания брала лизинг?', 'Какие предметы лизинга?', 'Когда был получен последний лизинг?']
  },
  { value: 'Находится в списке банкротов?' },
  {
    value: 'Есть исполнительные производства?',
    fields: ['Сумма открытых претензий?', 'Количество претензий?', 'Причины претензий?']
  },
  {
    value: 'Есть арбитражные производства?',
    children: [
      {
        value: 'ЮЛ отвечало ответчиком?',
        fields: [
          'Сколько раз выступало ответчиком?',
          'С чем связаны претензии?',
          'Макс.сумма на рассмотрении?',
          'Макс.сумма завершенных?'
        ]
      },
      {
        value: 'ЮЛ отвечало истцом?',
        fields: [
          'Сколько раз выступало истцом?',
          'С чем связаны претензии?',
          'Макс.сумма на рассмотрении?',
          'Макс.сумма завершенных?'
        ]
      }
    ]
  },
  { value: 'Были ли проверки за последние 12 месяцев?', fields: ['С чем связаны проверки?', 'Результат проверки?'] },
  {
    value: 'Применялись ли обеспечительные меры?',
    fields: ['Есть действующие обеспечительные меры?', 'Какие обеспечительные меры?']
  }
]
export const SCORING_GOVERNMENT_CONTRACTS_QUESTIONS: ScoringQuestion[] = [
  {
    value: 'Были ли госконтракты?',
    fields: ['Дата последнего заключения?', 'Общая сумма госконтрактов?', 'Сумма активных госконтрактов?']
  },
  { value: 'Были ли пени/штрафы во время исполнения?' }
]

export const LOSS_REASON_GROUP = [
  { name: 'Отказ Fera', children: ['Не прошел прескоринг', 'Отказ скоринга'] },
  {
    name: 'Не устраивают условия',
    children: [
      'Не устраивает срок лизинга',
      'Не устраивает ставка удорожания',
      'Не устраивает комиссия',
      'Не устраивает страховка',
      'Не устраивает аванс'
    ]
  },
  {
    name: 'Приобрели иначе',
    children: ['Взяли лизинг у конкуретнов', 'Взяли в кредит', 'Взяли за собственные средства']
  },
  {
    name: 'Отказ клиент',
    children: [
      'Пропала потребность',
      'Не хотят проходить верификацию',
      'Не хотят предоставлять документы',
      'Не выходит на связь',
      'Агент не выходит на связь',
      'Принимает решение о потребности (неопределенный срок)'
    ]
  },
  {
    name: 'Спам',
    children: ['Не наш клиент', 'Спам', 'Дубль']
  }
]

export const TERMINATION_REASON_GROUP = [
  { name: 'Нарушение обязательств' },
  { name: 'Не прошла поставка' },
  { name: 'Не выполнил условия "Активации"' }
]
