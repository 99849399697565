import { useReactiveVar } from '@apollo/client'
import { jwtVar } from './graphql/cache'
import Auth from './containers/Auth'
import Layout from './containers/Layout'

function App() {
  const authenticated = !!useReactiveVar(jwtVar)

  return <>{authenticated ? <Layout /> : <Auth />}</>
}

export default App
