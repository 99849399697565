import { Menu, MenuButton, MenuItems, Transition } from '@headlessui/react'
import { FC } from 'react'
import { ReactComponent as MoreIcon } from '../svg/icons/more.svg'
import { ReactComponent as TelegramIcon } from '../svg/icons/telegram.svg'
import { ReactComponent as WhatsappIcon } from '../svg/icons/whatsapp.svg'
import { ReactComponent as PhoneIcon } from '../svg/icons/phone.svg'
import { ReactComponent as CopyIcon } from '../svg/icons/copy.svg'
import copyToClipboard from 'copy-to-clipboard'
import { formatPhoneNumber } from '../utils/formatPhoneNumber'
import { showFizz } from './Fizz'

interface PhoneNumberProps {
  number?: string
  additionalNumber?: string | null
}

const PhoneNumber: FC<PhoneNumberProps> = ({ number, additionalNumber }) => {
  return (
    <Menu>
      <div className='relative'>
        <MenuButton className='flex items-center'>
          {formatPhoneNumber(number)}
          {number && <MoreIcon className='ml-3 rotate-90 text-grayscale-150' height='15px' width='15px' />}
        </MenuButton>
        {additionalNumber && <>доб. {additionalNumber}</>}
        <Transition
          as='div'
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <MenuItems className='bg-white absolute z-50 mt-2 flex w-[240px] flex-col gap-6 rounded-lg bg-white-0 p-7 shadow-lg focus:outline-none'>
            {!additionalNumber && (
              <>
                <a
                  href={`https://t.me/${number}`}
                  target='_blank'
                  rel='noreferrer'
                  className='flex items-center gap-5 hover:text-red-100'
                >
                  <TelegramIcon />
                  Написать в Telegram
                </a>
                <a
                  href={`https://wa.me/${number}`}
                  target='_blank'
                  rel='noreferrer'
                  className='flex items-center gap-5 hover:text-red-100'
                >
                  <WhatsappIcon />
                  Написать в WhatsApp
                </a>
              </>
            )}
            <a
              href={`tel:${number}`}
              target='_blank'
              rel='noreferrer'
              className='flex items-center gap-8 hover:text-red-100'
            >
              <PhoneIcon height='20px' width='20px' className='text-grayscale-200' />
              Позвонить
            </a>
            <div
              className='flex cursor-pointer items-center gap-8 hover:text-red-100'
              onClick={async (e) => {
                if (!number) return
                copyToClipboard(number)
                showFizz('Скопировано', e.clientX, e.clientY)
              }}
            >
              <CopyIcon height='20px' width='20px' className='text-grayscale-200' />
              <div>Скопировать</div>
            </div>
          </MenuItems>
        </Transition>
      </div>
    </Menu>
  )
}

export default PhoneNumber
