import { FC } from 'react'
import { useDealFilter } from './FilterForm'
import SelectedFilter from '../../components/Filter/SelectedFilter'
import UserName from '../../components/UserName'
import { formatMoney } from '../../utils/formatNumber'
import { dateFormatter } from '../../utils/dateFormatter'
import { Tag } from '../../components/Tags/Tag.tsx'

const SelectedFilters: FC = () => {
  const { filterState, setFilterState, filterActive } = useDealFilter()

  if (!filterActive || filterState?.query?.length) return null

  return (
    <div className='flex flex-wrap gap-2 mb-8'>
      {Object.keys(filterState).map((key) => {
        //@ts-ignore
        const objValue = filterState[key]
        switch (key) {
          case 'statuses':
          case 'supplyingStatus':
          case 'sources':
            return objValue?.map((filter: { _id: string; name: string }) => (
              <SelectedFilter
                key={filter._id + filter.name}
                onRemove={() =>
                  setFilterState({
                    ...filterState,
                    [key]: (objValue as { _id: string; name: string }[]).filter((i) => i._id !== filter._id)
                  })
                }
              >
                {filter.name}
              </SelectedFilter>
            ))
          case 'users':
            return objValue?.map((filter: number) => (
              <SelectedFilter
                key={key + filter}
                onRemove={() =>
                  setFilterState({
                    ...filterState,
                    [key]: (objValue as number[]).filter((id) => id !== filter)
                  })
                }
              >
                <UserName userId={filter} />
              </SelectedFilter>
            ))
          case 'categories':
            return objValue?.map((filter: { value: string; name: string }) => (
              <SelectedFilter
                key={filter.name + filter.value}
                onRemove={() =>
                  setFilterState({
                    ...filterState,
                    [key]: (objValue as { value: string; name: string }[]).filter((i) => i.value !== filter.value)
                  })
                }
              >
                {filter.name}
              </SelectedFilter>
            ))
          case 'amountFrom':
            return (
              objValue && (
                <SelectedFilter
                  key={key}
                  onRemove={() =>
                    setFilterState({
                      ...filterState,
                      [key]: undefined
                    })
                  }
                >
                  от {formatMoney(objValue)}
                </SelectedFilter>
              )
            )
          case 'amountTo':
            return (
              objValue && (
                <SelectedFilter
                  key={key}
                  onRemove={() =>
                    setFilterState({
                      ...filterState,
                      [key]: undefined
                    })
                  }
                >
                  до {formatMoney(objValue)}
                </SelectedFilter>
              )
            )
          case 'dateFrom':
            return (
              objValue && (
                <SelectedFilter
                  key={key}
                  onRemove={() =>
                    setFilterState({
                      ...filterState,
                      [key]: undefined
                    })
                  }
                >
                  c {dateFormatter.format(new Date(objValue))}
                </SelectedFilter>
              )
            )
          case 'dateTo':
            return (
              objValue && (
                <SelectedFilter
                  key={key}
                  onRemove={() =>
                    setFilterState({
                      ...filterState,
                      [key]: undefined
                    })
                  }
                >
                  по {dateFormatter.format(new Date(objValue))}
                </SelectedFilter>
              )
            )
          case 'tagsList':
            return objValue?.map((filter: Tag) => (
              <SelectedFilter
                key={filter._id}
                onRemove={() =>
                  setFilterState({
                    ...filterState,
                    [key]: (objValue as Tag[]).filter((i) => i._id !== filter._id)
                  })
                }
              >
                {filter.name}
              </SelectedFilter>
            ))
        }
      })}
    </div>
  )
}

export default SelectedFilters
