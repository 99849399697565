import { FC } from 'react'
import ohNoImage from '../images/ohno.jpg'
import useCurrentUser from '../hooks/useCurrentUser'
import { ReactComponent as WarningIcon } from '../svg/icons/warning.svg'

const ErrorFallback: FC<{ error?: Error }> = ({ error }) => {
  const currentUser = useCurrentUser()

  const name = currentUser?.name || 'Коллега'

  return (
    <div className='min-h-screen w-full flex flex-col items-center justify-center'>
      <h1 className='text-h100 font-display font-medium mb-10'>Всё сломалось!</h1>
      <div className='relative'>
        <WarningIcon width={60} height={60} className='text-red-50 absolute left-full rotate-12 translate-x-20' />
        <WarningIcon width={80} height={80} className='text-red-50 absolute right-full -rotate-12 -translate-x-20' />
        <WarningIcon width={60} height={60} className='text-red-50 absolute left-full translate-y-120 -rotate-12 translate-x-60' />
        <WarningIcon width={70} height={70} className='text-red-50 absolute right-full translate-y-120 rotate-12 -translate-x-70' />
        <WarningIcon width={70} height={70} className='text-red-50 absolute top-full left-full -translate-y-30 rotate-12 translate-x-20' />
        <WarningIcon width={50} height={50} className='text-red-50 absolute top-full right-full -translate-y-30 -rotate-12 -translate-x-20' />

        <div className='absolute bg-grayscale-0 text-white-0 px-5 top-40 left-10'>
          {name}, вставай,<br /> мы всё уронили
        </div>

        <div className='absolute bg-grayscale-0 text-white-0 px-5 top-90 right-10'>
          Мы уронили вообще<br /> всё, {name}, честно
        </div>

        <div className='absolute bg-grayscale-0 text-white-0 px-5 top-130 left-10'>
          Попробуй страницу<br /> обновить или позвать<br /> на помощь
        </div>

        {!!error?.name && !!error?.message && <div className='absolute bg-grayscale-0 text-white-0 px-5 left-50 right-10 bottom-10'>
          {error.name} {error.message}, {name}, понимаешь?
        </div>}

        <img className='rounded-xl w-[420px]' src={ohNoImage} alt='всё пропало' />
      </div>
    </div>
  )
}

export default ErrorFallback