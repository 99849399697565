import { FC, useCallback, useEffect, useMemo } from 'react'
import { makeVar, useReactiveVar } from '@apollo/client'
import FilterForm from '../../components/Filter/Form'
import { useCategoriesQuery, ApplicationsListQueryVariables, SourceCategory } from '../../graphql/schema'
import useNodes from '../../hooks/useNodes'
import Input from '../../components/Input'
import GroupSelectInput, { Option } from '../../components/GroupSelectInput'
import { AppStatus, ApplicationStatusCircle } from '../../components/ApplicationStatus'
import { throttle } from 'throttle-debounce'
import ManagerInput from '../../components/Forms/Inputs/ManagerInput'
import SourceInput, { SourceInputSource } from '../../components/Forms/Inputs/SourceInput'
import { applicationStatusDict, sourceCategoryDict } from '../../utils/dictionaries'
import { toLastSecond } from '../../utils/toLastSecond'
import { LOSS_REASON_GROUP } from '../../utils/constants.ts'
import Select from '../../components/SelectInput'

const statuses: Option[] = Object.keys(applicationStatusDict)
  .map((key) => ({
    value: key,
    name: applicationStatusDict[key as AppStatus],
    group: applicationStatusDict[key as AppStatus]
  }))
  .filter((status) => status.value !== AppStatus.Closed)

const lossReasonStatuses: Option[] = LOSS_REASON_GROUP.map((lr) => ({
  value: lr.name,
  name: lr.name,
  group: AppStatus.Closed,
  children: lr.children.map((c) => ({
    value: c,
    name: c,
    group: lr.name
  }))
}))

statuses.push(...lossReasonStatuses)

const defaultStatuses = statuses.filter((s) => s.group !== AppStatus.Closed)

const LOSS_REASON_OPTIONS = LOSS_REASON_GROUP.map((g) => g.name)

const sourceCategories = [
  {
    value: SourceCategory.Agent,
    name: sourceCategoryDict[SourceCategory.Agent]
  },
  {
    value: SourceCategory.Fera,
    name: sourceCategoryDict[SourceCategory.Fera]
  },
  {
    value: SourceCategory.Marketplace,
    name: sourceCategoryDict[SourceCategory.Marketplace]
  },
  {
    value: SourceCategory.Supplier,
    name: sourceCategoryDict[SourceCategory.Supplier]
  }
]

export interface AppFilterState {
  dateFrom?: string
  dateTo?: string
  sources: SourceInputSource[]
  users: number[]
  categories: Option[]
  statuses: Option[]
  sourceCategories: typeof sourceCategories
  query?: string
  id?: number
}

export interface FilterFormProps {
  state: AppFilterState
  onChange: (state: AppFilterState) => void
}

const appFilterStateVar = makeVar<AppFilterState>({
  sources: [],
  categories: [],
  users: [],
  sourceCategories: [],
  statuses: defaultStatuses
})

const appQueryVar = makeVar<string>('')

const ApplicationFilterForm: FC<FilterFormProps> = ({ state, onChange }) => {
  const { data: categoriesData } = useCategoriesQuery()
  const categories = useNodes(categoriesData?.leasingSubjectCategories?.edges)
  const options = useMemo(
    () =>
      categories?.map((cat) => ({
        name: cat.name,
        value: cat._id,
        group: cat.categoryGroup?.name
      })) || [],
    [categories]
  )

  const updateSelectedCategories = useCallback(
    (selectedCategories: Option[]) => {
      onChange({ ...state, categories: [...selectedCategories] })
    },
    [onChange, state]
  )

  const updateSelectedStatusesAndLossReasons = useCallback(
    (selected: Option[]) => {
      const selectedHasClosed = selected.some((s) => s.group && LOSS_REASON_OPTIONS.includes(s.group))
      // deselect all other statuses if default state and loss reason selected
      if (selectedHasClosed && isDefaultStatuses(state.statuses)) {
        onChange({ ...state, statuses: selected.filter((s) => s.group && LOSS_REASON_OPTIONS.includes(s.group)) })
      } else {
        onChange({ ...state, statuses: selected })
      }
    },
    [onChange, state]
  )

  return (
    <FilterForm
      onReset={() => {
        onChange({
          ...state,
          sources: [],
          categories: [],
          sourceCategories: [],
          users: [],
          statuses: defaultStatuses,
          dateFrom: undefined,
          dateTo: undefined
        })
      }}
      title='Фильтр заявок'
    >
      <div className='group relative z-50 mb-10 grid grid-cols-2 gap-16'>
        <Input
          type='date'
          placeholder='c'
          label='Создано c'
          onChange={(e) => onChange({ ...state, dateFrom: e.target.value })}
          value={state.dateFrom || ''}
        />
        <Input
          type='date'
          placeholder='до'
          label='до'
          onChange={(e) => onChange({ ...state, dateTo: e.target.value })}
          value={state.dateTo || ''}
          min={state.dateFrom || ''}
        />
        <hr className='absolute bottom-12 left-1/2 w-4 -translate-x-1/2 border-t-2 border-grayscale-200' />
      </div>

      <div className='group relative mb-10'>
        <GroupSelectInput
          label='Статус'
          placeholder={state.statuses.length ? `Выбрано: ${state.statuses.length}` : 'Выберите статусы'}
          options={statuses}
          selectedOptions={state.statuses}
          onChange={updateSelectedStatusesAndLossReasons}
          showSelectedOptions={false}
          renderGroup={(name) => (
            <div className='flex items-center gap-6 overflow-hidden'>
              <ApplicationStatusCircle status={name} />
              <span className='overflow-hidden overflow-ellipsis whitespace-nowrap'>{name}</span>
            </div>
          )}
        />
      </div>

      <div className='group relative mb-10'>
        <SourceInput selected={state.sources} onChange={(sources) => onChange({ ...state, sources })} />
      </div>

      <div className='group relative mb-10'>
        <Select
          label='Типы источников'
          placeholder='Выберите типы'
          options={sourceCategories}
          renderOption={(opt) => (
            <div className='flex gap-6 overflow-hidden'>
              <span className='overflow-hidden overflow-ellipsis whitespace-nowrap'>{opt.name}</span>
            </div>
          )}
          onChange={(selectedSourceCategories) =>
            onChange({ ...state, sourceCategories: [...selectedSourceCategories] })
          }
          selectedOptions={state.sourceCategories}
          value={state.sourceCategories.map((c) => c.name).join(', ')}
        />
      </div>

      <div className='group relative mb-10'>
        <ManagerInput
          label='Менеджеры по лизингу'
          placeholder='Выберите менеджеров'
          multiple
          selected={state.users}
          onChange={(users) => onChange({ ...state, users })}
        />
      </div>

      <div className='group relative mb-10'>
        <GroupSelectInput
          label='Категория'
          placeholder='Выберите категории'
          options={options}
          selectedOptions={state.categories}
          onChange={updateSelectedCategories}
          showSelectedOptions={false}
        />
      </div>
    </FilterForm>
  )
}

// function that checks if current status filter is same with default
export const isDefaultStatuses = (statuses: typeof defaultStatuses) => {
  return (
    statuses.length === defaultStatuses.length &&
    statuses.every((status) => defaultStatuses.includes(status)) &&
    defaultStatuses.every((status) => statuses.includes(status))
  )
}

const setQuery = throttle(500, (query: string) => {
  const isNumber = query.length < 10 && /^\d+$/.test(query)
  appFilterStateVar({
    ...appFilterStateVar(),
    query,
    ...(isNumber && { id: parseInt(query) })
  })
})

export const useApplicationFilter = () => {
  const filterState = useReactiveVar(appFilterStateVar)
  const query = useReactiveVar(appQueryVar)

  const filterActive = Boolean(
    filterState.sources.length > 0 ||
      filterState.categories.length > 0 ||
      !isDefaultStatuses(filterState.statuses) ||
      filterState.users.length > 0 ||
      filterState.dateFrom ||
      filterState.dateTo ||
      filterState.sourceCategories
  )

  useEffect(() => {
    setQuery(query)
  }, [query])

  const variables = useMemo<ApplicationsListQueryVariables>((): ApplicationsListQueryVariables => {
    if (filterState?.query?.length) {
      return {
        query: filterState.query,
        id: filterState.id
      }
    }

    const statusList = [
      ...new Set(
        filterState.statuses.map((status) =>
          status.group && LOSS_REASON_OPTIONS.includes(status.group) ? AppStatus.Closed : status.group
        )
      )
    ]
    const lossReasonList = [
      ...new Set(
        filterState.statuses
          .filter((s) => s.group && LOSS_REASON_OPTIONS.includes(s.group))
          .map((status) => status.value as string)
      )
    ]

    return {
      sourceList: filterState.sources.map((source) => source._id.toString()),
      created: [
        {
          before: filterState.dateTo && toLastSecond(new Date(filterState.dateTo)).toISOString(),
          after: filterState.dateFrom && new Date(filterState.dateFrom).toISOString()
        }
      ],
      categories: filterState.categories.map((category) => category.value.toString()),
      statusList: statusList.length ? statusList : undefined,
      sourceCategoriesList: filterState.sourceCategories.map((sourceCategory) => sourceCategory.value.toString()),
      lossReasonList: lossReasonList.length ? lossReasonList : undefined,
      managers: filterState.users
    }
  }, [filterState])

  return {
    filterState,
    filterActive,
    setFilterState: appFilterStateVar,
    query,
    setQuery: appQueryVar,
    variables
  }
}

export default ApplicationFilterForm
