import { FC } from 'react'
import { useUserByIdQuery } from '../graphql/schema'
import getFullName from '../utils/getFullName'
import Tooltip from './Tooltip.tsx'

interface UserNameProps {
  userId: number
  short?: boolean
}

const UserName: FC<UserNameProps> = ({ userId, short }) => {
  const { data } = useUserByIdQuery({
    variables: {
      id: `${userId}`
    },
    skip: !userId
  })

  if (!data?.user) return null

  return (
    <div>
      {short ? (
        <Tooltip
          target={
            <>
              {data?.user?.name} {data?.user?.surname[0]}.
            </>
          }
        >
          {getFullName(data?.user)}
        </Tooltip>
      ) : (
        getFullName(data?.user)
      )}
    </div>
  )
}

export default UserName
