import { FC } from 'react'
import c from 'clsx'
import { DealStatus as Status } from '../../graphql/schema'
import StatusRing from '../StatusRing'
import { ReactComponent as SuccessIcon } from '../../svg/ui/success.svg'
import { ReactComponent as ErrorIcon } from '../../svg/ui/error.svg'
import { ReactComponent as TerminateIcon } from '../../svg/ui/terminate.svg'
import { dealStatusDict } from '../../utils/dictionaries'

export interface DealStatusProps {
  status: Status
  className?: string
}

export const statusColorDict: Record<Status, string> = {
  [Status.Preparing]: 'bg-tr-cyan',
  [Status.Signing]: 'bg-tr-blue',
  [Status.Activation]: 'bg-tr-purple',
  [Status.PaymentReception]: 'bg-tr-pink',
  [Status.Closed]: 'bg-tr-green',
  [Status.Cancelled]: 'bg-tr-red',
  [Status.Terminated]: 'bg-tr-red'
}

export const statusTextColorDict: Record<Status, string> = {
  [Status.Preparing]: 'text-dark-cyan',
  [Status.Signing]: 'text-dark-blue',
  [Status.Activation]: 'text-dark-purple',
  [Status.PaymentReception]: 'text-dark-pink',
  [Status.Closed]: 'text-dark-green',
  [Status.Cancelled]: 'text-dark-red',
  [Status.Terminated]: 'text-dark-red'
}

export const statusIconColorDict: Record<Status, string> = {
  [Status.Preparing]: 'text-base-cyan',
  [Status.Signing]: 'text-base-blue',
  [Status.Activation]: 'text-base-purple',
  [Status.PaymentReception]: 'text-base-pink',
  [Status.Closed]: 'text-dark-green',
  [Status.Cancelled]: 'text-dark-red',
  [Status.Terminated]: 'text-dark-red'
}

const statusOrder = Object.keys(statusColorDict)

const maxSegments = 4

/**
 * when on colored background success and error icons should be darker (designer's request)
 * set bg to true to use this behavior
 */
const DealStatusCircle: FC<DealStatusProps & { bg?: boolean }> = ({ status, className, bg = false }) => {
  const statusNumber = statusOrder.indexOf(status) + 1

  return (
    <div className={c(statusTextColorDict[status], className)}>
      {statusNumber <= maxSegments && (
        <StatusRing className={statusIconColorDict[status]} maxSegments={maxSegments} activeSegments={statusNumber} />
      )}
      {status === Status.Closed && <SuccessIcon className={bg ? 'text-dark-green' : 'text-base-green'} />}
      {status === Status.Cancelled && <ErrorIcon className={bg ? 'text-dark-red' : 'text-base-red'} />}
      {status === Status.Terminated && <TerminateIcon className={bg ? 'text-dark-red' : 'text-base-red'} />}
    </div>
  )
}

const DealStatus: FC<DealStatusProps> = ({ status, className }) => {
  return (
    <div className={c('p-4 rounded-md', statusTextColorDict[status], statusColorDict[status], className)}>
      <DealStatusCircle status={status} bg={true} />
    </div>
  )
}

const DealStatusBadge: FC<DealStatusProps> = ({ status }) => {
  return (
    <div
      className={c(
        'rounded-md px-3 h-13 flex items-center justify-center gap-x-3',
        statusTextColorDict[status],
        statusColorDict[status]
      )}
    >
      <DealStatusCircle status={status} bg={true} />
      <div>{dealStatusDict[status]}</div>
    </div>
  )
}

export default DealStatus
export { DealStatusCircle, DealStatusBadge }
