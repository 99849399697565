import { FC, useEffect, useMemo, useState } from 'react'
import { ReactComponent as TickIcon } from '../../svg/icons/tick-xs.svg'
import { Tag } from './Tag.tsx'
import { FieldError } from 'react-hook-form'
import MultipleSelect from '../Select'
import Highlighted from '../Highlighted.tsx'
import useNodes from '../../hooks/useNodes.ts'
import { TagEntity, useTagsQuery } from '../../graphql/schema.tsx'
import useThrottledState from '../../hooks/useThrottledState.ts'

interface TagsInputProps {
  entityType: TagEntity
  selected: Tag[]
  onChange: (tags: Tag[]) => void
  label?: string
  placeholder?: string
  multiple?: boolean
  error?: string | FieldError
}

const TagsInput: FC<TagsInputProps> = ({ entityType, selected, onChange, label, placeholder, multiple, error }) => {
  const [tagQuery, setTagQuery] = useState('')
  const [throttledTagQuery] = useThrottledState(tagQuery, 500)

  const { data: tagsData, refetch: loadTags } = useTagsQuery({
    variables: {
      filter: {
        type: entityType
      }
    }
  })
  const tags = useNodes(tagsData?.tags?.edges)
  const selectedTagIds = selected.map((t) => t._id)

  const value = useMemo(
    () => selected.map((tag) => tags.find((t) => t._id === tag._id)?.name).join(', '),
    [selected, tags]
  )

  useEffect(() => {
    const query = throttledTagQuery.split(',')[0]
    loadTags({ filter: { type: entityType, name: query } })
  }, [throttledTagQuery, loadTags])

  return (
    <MultipleSelect
      type='text'
      multiple={multiple}
      label={label}
      placeholder={placeholder}
      autoComplete='off'
      onQuery={(query) => setTagQuery(query)}
      value={value}
      error={error}
      clearSelected={() => onChange([])}
    >
      {tags.map((tag) => (
        <li
          key={tag._id}
          onClick={() => {
            if (selectedTagIds.includes(tag._id)) {
              onChange(selected.filter((tag) => tag._id !== tag._id))
            } else {
              onChange([...selected, tag])
            }
          }}
          className='hover:bg-grayscale-450 px-12 py-5 cursor-pointer'
        >
          <div className='flex justify-between items-center'>
            <div className='text-grayscale-0 mb-1'>
              <Highlighted classMarkName='text-red-100 bg-transparent' text={tag.name} highlight={tagQuery} />
            </div>
            {selectedTagIds.includes(tag._id) && (
              <div className='rounded-full bg-red-100/5 text-red-100 px-5 py-2 flex items-center ml-5'>
                <TickIcon />
              </div>
            )}
          </div>
        </li>
      ))}
    </MultipleSelect>
  )
}

export default TagsInput
