import { FC, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import Select from '../../components/Select'
import {
  CustomerAccountKind,
  useApplyIncomeToCustomerMutation,
  useCustomersForBankOperationQuery
} from '../../graphql/schema'
import { ApolloError } from '@apollo/client'
import useNodes from '../../hooks/useNodes'
import { handleBackendErrorsToForm } from '../../utils/backendErrorUtils'
import c from 'clsx'
import Highlighted from '../../components/Highlighted'
import { dateFormatter } from '../../utils/dateFormatter'
import SubmitButton from '../../components/SubmitButton'
import useThrottledState from '../../hooks/useThrottledState'
import { BankOperation } from './index'
import TransactionAmount from '../../components/TransactionAmount'
import { ReactComponent as ApplyPaymentIcon } from '../../svg/icons/applyPayment.svg'
import Checkbox from '../../components/Forms/Checkbox'

interface FormProps {
  onDone?: () => void
  bankOperation: BankOperation
}

interface FormValues {
  customerId: string
  customer: string
  isPenalty: boolean
}

const ApplyIncomeForm: FC<FormProps> = ({ onDone, bankOperation }) => {
  const [applyIncomeToCustomer, { loading }] = useApplyIncomeToCustomerMutation()

  const {
    data: customersData,
    previousData: previousCustomerData,
    refetch
  } = useCustomersForBankOperationQuery({
    notifyOnNetworkStatusChange: true
  })

  const customers = useNodes(
    customersData?.customerCompanies?.edges || previousCustomerData?.customerCompanies?.edges
  ).reverse()

  const [queryTextCustomer, setQueryTextCustomer] = useState('')
  const [query] = useThrottledState(queryTextCustomer, 500)
  const contractNumberQuery = queryTextCustomer.toLowerCase().replace('дл', '').slice(0, 11)

  const { register, handleSubmit, formState, getValues, setValue, watch, setError, control } = useForm<FormValues>({
    defaultValues: {
      customer: '',
      customerId: '',
      isPenalty: false
    }
  })

  useEffect(() => {
    const contractNumber = query.toLocaleLowerCase().startsWith('дл') ? query.slice(2) : query
    refetch({
      query,
      contractNumber,
      exists: [{ deals: true }]
    })
  }, [refetch, query])

  const innFound = !!bankOperation.payerInn && parseInt(bankOperation.payerInn) > 0

  const { data: initialSearchData } = useCustomersForBankOperationQuery({
    variables: { query: bankOperation.payerInn, exists: [{ deals: true }] },
    skip: !innFound
  })

  useEffect(() => {
    if (initialSearchData && initialSearchData.customerCompanies?.edges?.length) {
      const s = initialSearchData.customerCompanies?.edges[0]?.node
      if (!s) return
      setValue('customer', `${s?.shortWithOpf}`)
      setValue('customerId', s._id.toString())
    }
  }, [initialSearchData, setValue])

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    await applyIncomeToCustomer({
      variables: {
        input: {
          bankOperationId: bankOperation.id,
          customerCompanyId: data.customerId,
          customerAccountKind: data.isPenalty ? CustomerAccountKind.Penalty : CustomerAccountKind.Main
        }
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<FormValues>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  const isIndividual =
    bankOperation?.payerAccountNumber?.startsWith('40817') || bankOperation?.payerAccountNumber?.startsWith('3023')

  return (
    <section className='md: w-[448px] p-12'>
      <div className='flex items-center justify-between'>
        <h1 className='font-display text-h200'>Входящий платеж №{bankOperation.documentNumber}</h1>
        <div className='text-grayscale-150'>{dateFormatter.format(new Date(bankOperation.date))}</div>
      </div>

      <div className='mt-12 flex justify-center text-grayscale-150'>{bankOperation.payerName}</div>

      <TransactionAmount
        isIncome
        amount={bankOperation.amount}
        className='mt-12 flex justify-center text-h100 text-green-600'
        currency='₽'
      />

      <h1 className='text-h350 mt-12'>Назначение платежа</h1>
      <div className='mt-6 text-grayscale-150'>{bankOperation.paymentPurpose}</div>

      <table className='mt-10 w-full'>
        <tbody>
          <tr className='group'>
            <td className='rounded-l-xl bg-grayscale-450 px-8 py-6 text-grayscale-150'>Счёт</td>
            <td className='rounded-r-xl bg-grayscale-450 px-8 py-6 text-right'>{bankOperation.payerAccountNumber}</td>
          </tr>
        </tbody>
      </table>
      {isIndividual && <div className='mt-4 text-center text-red-100'>Оплата от физического лица</div>}

      <form className='mt-12 border-t-1 border-grayscale-400 pt-12' onSubmit={handleSubmit(onSubmit)}>
        <div className='relative mb-12 flex flex-col gap-8'>
          <Select
            label='Название, ИНН или номер договора'
            type='text'
            editable={true}
            autoComplete='off'
            throttleTime={500}
            onQuery={(query) => {
              setQueryTextCustomer(query)
            }}
            {...register('customer', { required: true })}
            value={watch('customer')}
            error={formState.errors.customer?.message as string}
          >
            {customers?.map((s) => {
              const contractNumberMatch = s?.deals?.edges?.find((deal) => {
                if (!contractNumberQuery) return false
                if (!deal?.node?.contractNumber?.includes(contractNumberQuery)) return false
                return true
              })

              return (
                <li
                  key={s._id}
                  onClick={() => {
                    setValue('customer', `${s?.shortWithOpf}`)
                    setValue('customerId', s._id.toString())
                  }}
                  className={c(
                    getValues('customerId') === s?._id?.toString() && 'bg-grayscale-400',
                    'cursor-pointer px-12 py-5 hover:bg-grayscale-450'
                  )}
                >
                  <div className='mb-1 text-grayscale-0'>
                    <Highlighted
                      classMarkName='text-red-100 bg-transparent'
                      text={`${s?.shortWithOpf}`}
                      highlight={queryTextCustomer}
                    />
                  </div>
                  <div className='flex gap-x-5'>
                    <div className='line-clamp-2 overflow-hidden text-ellipsis'>
                      ИНН:&nbsp;
                      <Highlighted
                        classMarkName='text-red-100 bg-transparent'
                        text={s.inn}
                        highlight={queryTextCustomer}
                      />
                    </div>
                    {contractNumberMatch && (
                      <div className='line-clamp-2 overflow-hidden text-ellipsis'>
                        <Highlighted
                          classMarkName='text-red-100 bg-transparent'
                          text={`ДЛ${contractNumberMatch?.node?.contractNumber}`}
                          highlight={`ДЛ${contractNumberQuery}`}
                        />
                      </div>
                    )}
                  </div>
                </li>
              )
            })}
          </Select>
        </div>

        <Controller
          name='isPenalty'
          control={control}
          render={({ field }) => (
            <Checkbox
              label={'Это пени'}
              checked={field.value}
              onChange={() => setValue('isPenalty', !field.value)}
              className='mb-10'
            />
          )}
        />

        <SubmitButton loading={loading}>
          <ApplyPaymentIcon className='mr-5 text-white-0' />
          Разнести на баланс компании
        </SubmitButton>
      </form>
    </section>
  )
}

export default ApplyIncomeForm
