import { ReactElement, useState } from 'react'
import c from 'clsx'
import { Link } from 'react-router-dom'
import { ReactComponent as LoadingIcon } from '../../svg/icons/loading.svg'

import useUpdateEffect from '../../hooks/useUpdateEffect'
import { RefetchType } from '../../types'
import SortMenu from './SortMenu'

type ElementLike = { id?: any; _id?: any }
type Order = { [key: string]: string }
type ValueRenderFunction<T> = (value: T, i: number, arr: T[]) => ReactElement | string | number | undefined
// [title, cellRenderFunction]
type GridViewElement<T> = {
  title?: string
  titleClassName?: string
  value: ValueRenderFunction<T> | keyof T
  sort?: string
  padding?: boolean
  numeric?: boolean
}

export type GridViewConfig<T> = {
  columns: GridViewElement<T>[]
  grid: string
  rows?: {
    link?: (value: T) => string
    className?: (value: T) => string
  }
  selectedOrder?: Order
}

interface GridViewProps<T> {
  data: T[]
  config: GridViewConfig<T>
  loading?: boolean
  error?: string
  refetch?: RefetchType<{
    order: Order[]
  }>
}

export default function GridView<T extends ElementLike>(props: GridViewProps<T>) {
  const {
    data,
    config: { columns, rows, grid, selectedOrder },
    loading,
    error,
    refetch
  } = props
  const hasRows = !!data.length

  const [order, setOrder] = useState<Order | undefined>(selectedOrder || undefined)

  useUpdateEffect(() => {
    if (refetch && order) {
      refetch({
        order: [order]
      })
    }
  }, [refetch, order])

  return (
    <div className='w-full'>
      <div className={c('grid rounded-md bg-surface-primary tabular-nums shadow-card', grid)}>
        <div className='group relative contents text-body-m text-labels-tertiary'>
          {columns.map((conf, i) => {
            // const last = i === columns.length - 1
            const titleClassName = c(
              'border-b-1 first:rounded-tl-md first:group-last:rounded-bl-md last:rounded-tr-md last:group-last:rounded-br-md group-last:border-b-0 border-r-1 last:border-r-0 h-full bg-surface-primary border-separators-primary sticky top-0 z-10',
              // last && 'text-right',
              conf.titleClassName && conf.titleClassName
            )
            if (conf.sort) {
              return (
                <SortMenu
                  key={i}
                  title={conf.title || ''}
                  sort={conf.sort}
                  order={order || {}}
                  setOrder={setOrder}
                  className={titleClassName}
                />
              )
            }

            return (
              <div key={i} className={titleClassName}>
                <div className='px-7 py-4'>{conf.title}</div>
              </div>
            )
          })}
        </div>

        {data?.map((element, i, arr) => {
          const link = rows?.link?.(element)
          const className = rows?.className?.(element)

          return (
            <div
              key={element._id || element.id || i}
              className='group contents text-labels-primary hover:text-base-red'
            >
              {columns.map((column, j) => {
                const value =
                  typeof column.value === 'function'
                    ? column.value(element, i, arr)
                    : (element[column.value] as ReactElement)

                return (
                  <div
                    key={j}
                    className={c(
                      'flex w-full items-center border-b-1 border-r-1 border-separators-primary tabular-nums last:border-r-0 group-last:border-b-0',
                      column.padding !== false && 'px-7 py-4',
                      column.numeric && 'font-mono',
                      className
                    )}
                  >
                    {!!link && !!column.title ? (
                      <Link className={c('block flex-1')} to={link}>
                        {value || '—'}
                      </Link>
                    ) : (
                      value || '—'
                    )}
                  </div>
                )
              })}
            </div>
          )
        })}

        {loading && (
          <div className='col-span-full flex items-center justify-center py-10 text-labels-tertiary'>
            <LoadingIcon className='animate-spin self-center' />
          </div>
        )}
        {!loading && !hasRows && !error && (
          <div className='col-span-full flex items-center justify-center py-10 text-labels-tertiary'>Список пуст</div>
        )}
        {!loading && !hasRows && error && (
          <div className='col-span-full flex items-center justify-center py-10 text-labels-tertiary'>
            <div>
              Не удалось получить данные,{' '}
              <span className='cursor-pointer underline' onClick={() => window.location.reload()}>
                обновите страницу
              </span>
              .
            </div>
            <div>{error}</div>
          </div>
        )}
      </div>
    </div>
  )
}
