import { FC, useState } from 'react'
import { ReactComponent as FileUploadIcon } from '../../svg/icons/fileUpload.svg'
import { ReactComponent as LoadingIcon } from '../../svg/icons/loading.svg'
import { useDropzone } from 'react-dropzone'
import { env } from '../../env'
import { jwtVar } from '../../graphql/cache'
import c from 'clsx'
import { Transition } from '@headlessui/react'

async function uploadStatementFile(file: File): Promise<void> {
  const url = new URL('/api/import_bank_statement', env.REACT_APP_BILLING_API_URL)
  const formData = new FormData()
  formData.append('file', file)

  const headers = new Headers()
  headers.append('Authorization', `Bearer ${jwtVar()}`)

  const options: RequestInit = {
    method: 'POST',
    headers,
    body: formData
  }

  try {
    const response = await fetch(url, options)

    if (!response.ok) {
      // Handle error response
      const errorResponse = await response.text()
      throw new Error(`Error uploading file: ${errorResponse}`)
    }
  } catch (error) {
    // Handle any other errors that might occur during the fetch process
    console.error('Error occurred during file upload:', error)
  }
}

interface StatementUploadProps {
  onDone: () => void
  className?: string
}

const StatementUpload: FC<StatementUploadProps> = ({ onDone, className }) => {
  const [uploading, setUploading] = useState(false)

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: { 'text/*': ['.txt'] },
    onDrop: async (files) => {
      setUploading(true)
      try {
        await uploadStatementFile(files[0])
      } catch (error) {
        console.error(error)
      } finally {
        setUploading(false)
        onDone()
      }
    },
    multiple: false,
    noClick: true
  })

  return (
    <button
      {...getRootProps()}
      onClick={open}
      className={c(
        'relative flex h-25 w-full items-center justify-center rounded-md border-1 border-dashed border-grayscale-300 text-grayscale-250 hover:text-grayscale-200 disabled:text-grayscale-250',
        className
      )}
    >
      <Transition
        as='div'
        show={!uploading}
        className='relative flex transform items-center justify-center transition-all duration-300'
        enterFrom='-translate-x-1/2 opacity-0'
        enterTo='translate-x-0 opacity-100'
        leaveTo='translate-x-1/2 opacity-0'
      >
        <FileUploadIcon className='mr-5' />
        <span>Загрузить банковскую выписку</span>
      </Transition>
      <Transition
        as='div'
        show={uploading}
        className='absolute inset-0 flex transform items-center justify-center transition-all duration-300'
        enterFrom='-translate-x-1/4 opacity-0'
        enterTo='translate-x-0 opacity-100'
        leaveTo='translate-x-1/4 opacity-0'
      >
        <LoadingIcon className='animate-spin' />
      </Transition>
      <input {...getInputProps()} className='absolute inset-0 h-full w-full cursor-pointer opacity-0' type='file' />
    </button>
  )
}

export default StatementUpload
