import { FC } from 'react'
import { useParams } from 'react-router-dom'
import ApplicationsList from '../../components/ApplicationsList'
import { useSourceApplicationsQuery } from '../../graphql/schema'
import useScrollPagination from '../../hooks/useScrollPagination'
import useNodes from '../../hooks/useNodes'

const Applications: FC = () => {
  const { id } = useParams<'id'>()

  const { data, fetchMore, loading, error } = useSourceApplicationsQuery({
    variables: { sourceId: id?.toString() as string },
    skip: !id
  })
  const applicationsList = useNodes(data?.source?.applications?.edges)

  const { wrapperRef: triggerRef, isFetching } = useScrollPagination(fetchMore, data?.source?.applications?.pageInfo)

  return (
    <div className='flex-grow container' ref={triggerRef}>
      <div className='bg-surface-secondary rounded-xl p-5 mb-4'>
        <ApplicationsList loading={loading || isFetching} applicationsList={applicationsList} error={error?.message} />
      </div>
    </div>
  )
}

export default Applications
