import { FC } from 'react'
import Tooltip from '../Tooltip'
import { DealStatus as Status } from '../../graphql/schema'
import DealStatus from './'
import { dealStatusDict } from '../../utils/dictionaries'

interface ApplicationStatusTooltipProps {
  status: Status
  terminationReason?: string
  className?: string
}

const ApplicationStatusTooltip: FC<ApplicationStatusTooltipProps> = ({ status, terminationReason, className }) => {
  return (
    <Tooltip target={<DealStatus status={status} className={className} />}>
      {status === Status.Terminated && terminationReason ? terminationReason : dealStatusDict[status]}
    </Tooltip>
  )
}

export default ApplicationStatusTooltip
