import { FC, useCallback, useEffect, useMemo } from 'react'
import FilterForm from '../../components/Filter/Form'
import Input from '../../components/Input'
import Select from '../../components/SelectInput'
import {
  DealsListQueryVariables,
  DealStatus,
  SupplyingStatus,
  TagEntity,
  useCategoriesQuery
} from '../../graphql/schema'
import useNodes from '../../hooks/useNodes'
import { dealStatusDict, reverseDealStatusDict, supplyingStatusDict } from '../../utils/dictionaries'
import GroupSelectInput, { Option } from '../../components/GroupSelectInput'
import DealStatusComponent from '../../components/DealStatus'
import ManagerInput from '../../components/Forms/Inputs/ManagerInput'
import SourceInput, { SourceInputSource } from '../../components/Forms/Inputs/SourceInput'
import { makeVar, useReactiveVar } from '@apollo/client'
import { throttle } from 'throttle-debounce'
import { toLastSecond } from '../../utils/toLastSecond'
import TagsInput from '../../components/Tags/TagsInput.tsx'
import { Tag } from '../../components/Tags/Tag.tsx'
import { TERMINATION_REASON_GROUP } from '../../utils/constants.ts'

const statuses: Option[] = Object.keys(dealStatusDict)
  .map((key) => ({
    value: key,
    name: dealStatusDict[key as DealStatus],
    group: key
  }))
  .filter((status) => status.value !== DealStatus.Terminated)

const terminationReasonStatuses: Option[] = TERMINATION_REASON_GROUP.map((tr) => ({
  value: tr.name,
  name: tr.name,
  group: dealStatusDict[DealStatus.Terminated]
}))

const supplyingStatuses = Object.values(SupplyingStatus).map((s) => ({
  _id: s,
  name: supplyingStatusDict[s]
}))

statuses.push(...terminationReasonStatuses)

const defaultStatuses = statuses.filter((s) => s.group !== dealStatusDict[DealStatus.Terminated])

const TERMINATION_REASON_OPTIONS = TERMINATION_REASON_GROUP.map((g) => g.name)

export interface DealFilterState {
  statuses: Option[]
  categories: Option[]
  supplyingStatus: typeof supplyingStatuses
  users: number[]
  sources: SourceInputSource[]
  dateFrom?: string
  dateTo?: string
  amountFrom?: string
  amountTo?: string
  query?: string
  contractNumber?: string
  id?: number
  tagsList: Tag[]
}

export interface FilterFormProps {
  state: DealFilterState
  onChange: (state: DealFilterState) => void
}

const dealFilterStateVar = makeVar<DealFilterState>({
  statuses: defaultStatuses,
  categories: [],
  supplyingStatus: [],
  users: [],
  sources: [],
  tagsList: []
})
const dealQueryVar = makeVar<string>('')

const DealFilterForm: FC<FilterFormProps> = ({ state, onChange }) => {
  const { data: categoriesData } = useCategoriesQuery()
  const categories = useNodes(categoriesData?.leasingSubjectCategories?.edges)
  const options = useMemo(
    () =>
      categories?.map((cat) => ({
        name: cat.name,
        value: cat._id,
        group: cat.categoryGroup?.name
      })) || [],
    [categories]
  )

  const updateSelectedCategories = useCallback(
    (selectedCategories: Option[]) => {
      onChange({ ...state, categories: [...selectedCategories] })
    },
    [onChange, state]
  )

  const updateSelectedStatusesAndTerminationReasons = useCallback(
    (selected: Option[]) => {
      const selectedHasTerminated = selected.some((s) => s.group && TERMINATION_REASON_OPTIONS.includes(s.name))
      // deselect all other statuses if default state and loss reason selected
      if (selectedHasTerminated && isDefaultStatuses(state.statuses)) {
        onChange({
          ...state,
          statuses: selected.filter((s) => s.name && TERMINATION_REASON_OPTIONS.includes(s.name))
        })
      } else {
        onChange({ ...state, statuses: selected })
      }
    },
    [onChange, state]
  )

  return (
    <FilterForm
      onReset={() => {
        onChange({
          ...state,
          statuses: [],
          categories: [],
          supplyingStatus: [],
          users: [],
          sources: [],
          tagsList: [],
          dateFrom: undefined,
          dateTo: undefined,
          amountFrom: undefined,
          amountTo: undefined
        })
      }}
    >
      <div className='relative group mb-10'>
        <GroupSelectInput
          label='Статус'
          placeholder={state.statuses.length ? `Выбрано: ${state.statuses.length}` : 'Выберите статусы'}
          options={statuses}
          selectedOptions={state.statuses}
          onChange={updateSelectedStatusesAndTerminationReasons}
          showSelectedOptions={false}
          renderGroup={(name) => (
            <div className='flex gap-6 overflow-hidden items-center'>
              <DealStatusComponent status={reverseDealStatusDict[name as DealStatus]} />
              <span className='whitespace-nowrap overflow-hidden overflow-ellipsis'>{name}</span>
            </div>
          )}
        />
      </div>

      <div className='relative group mb-10'>
        <Select
          label='Отгрузки'
          placeholder='Выберите отгрузки'
          options={supplyingStatuses.sort((a, b) => (a.name > b.name || a.name === 'Отгружено' ? 1 : -1))}
          onChange={(selectedSupplyingStatuses) =>
            onChange({ ...state, supplyingStatus: [...selectedSupplyingStatuses] })
          }
          selectedOptions={state.supplyingStatus}
          value={state.supplyingStatus.map((s) => s.name).join(', ')}
        />
      </div>

      <div className='relative group mb-10'>
        <ManagerInput
          label='Менеджеры по лизингу'
          placeholder='Выберите менеджеров'
          multiple
          selected={state.users}
          onChange={(users) => onChange({ ...state, users })}
        />
      </div>

      <div className='relative group mb-10'>
        <SourceInput selected={state.sources} onChange={(sources) => onChange({ ...state, sources })} />
      </div>

      <div className='relative group mb-10'>
        <GroupSelectInput
          label='Категория'
          placeholder='Выберите категории'
          options={options}
          selectedOptions={state.categories}
          onChange={updateSelectedCategories}
        />
      </div>

      <div className='relative group mb-10'>
        <TagsInput
          multiple
          entityType={TagEntity.Deal}
          selected={state.tagsList}
          onChange={(tag) => onChange({ ...state, tagsList: tag })}
          label='Теги'
          placeholder='Выберите теги'
        />
      </div>

      <div className='relative group mb-10 grid grid-cols-2 gap-16'>
        <Input
          type='date'
          placeholder='c'
          label='Подписан c'
          onChange={(e) => onChange({ ...state, dateFrom: e.target.value })}
          value={state.dateFrom || ''}
        />
        <Input
          type='date'
          placeholder='до'
          label='по'
          onChange={(e) => onChange({ ...state, dateTo: e.target.value })}
          value={state.dateTo || ''}
          min={state.dateFrom || ''}
        />
        <hr className='absolute w-4 border-t-2 border-grayscale-200 left-1/2 bottom-12 -translate-x-1/2' />
      </div>

      <div className='relative group mb-10 grid grid-cols-2 gap-16'>
        <Input
          type='text'
          inputMode='numeric'
          placeholder='c'
          label='Сумма от'
          onChange={(e) => onChange({ ...state, amountFrom: e.target.value })}
          value={state.amountFrom || ''}
        />
        <Input
          type='text'
          inputMode='numeric'
          placeholder='до'
          label='до'
          onChange={(e) => onChange({ ...state, amountTo: e.target.value })}
          value={state.amountTo || ''}
        />
        <hr className='absolute w-4 border-t-2 border-grayscale-200 left-1/2 bottom-12 -translate-x-1/2' />
      </div>
    </FilterForm>
  )
}

// function that checks if current status filter is same with default
export const isDefaultStatuses = (statuses: typeof defaultStatuses) => {
  return statuses.length === defaultStatuses.length && statuses.every((status) => defaultStatuses.includes(status))
}

export default DealFilterForm

const setQuery = throttle(500, (query: string) => {
  const isNumber = query.length < 10 && /^\d+$/.test(query)
  const lowercaseQuery = query.toLocaleLowerCase()
  const contractNumber = lowercaseQuery.startsWith('дл') ? lowercaseQuery.replace('дл', '') : query
  dealFilterStateVar({
    ...dealFilterStateVar(),
    query,
    contractNumber,
    ...(isNumber && { id: parseInt(query) })
  })
})

export const useDealFilter = () => {
  const filterState = useReactiveVar(dealFilterStateVar)
  const query = useReactiveVar(dealQueryVar)

  const filterActive = Boolean(
    !isDefaultStatuses(filterState.statuses) ||
      filterState.categories.length > 0 ||
      filterState.users.length > 0 ||
      filterState.sources.length > 0 ||
      filterState.supplyingStatus.length > 0 ||
      filterState.dateFrom ||
      filterState.dateTo ||
      filterState.amountFrom ||
      filterState.amountTo ||
      filterState.tagsList.length > 0
  )

  useEffect(() => {
    setQuery(query)
  }, [query])

  const variables: DealsListQueryVariables = useMemo<DealsListQueryVariables>((): DealsListQueryVariables => {
    if (filterState?.query?.length) {
      return {
        query: filterState.query,
        id: filterState.id,
        contractNumber: filterState.contractNumber
      }
    }

    const statusList = [
      ...new Set(
        filterState.statuses.map((status) =>
          status.group && TERMINATION_REASON_OPTIONS.includes(status.name) ? DealStatus.Terminated : status.group
        )
      )
    ]
    const terminationReasonList = [
      ...new Set(
        filterState.statuses
          .filter((s) => s.group && TERMINATION_REASON_OPTIONS.includes(s.name))
          .map((status) => status.value as string)
      )
    ]

    return {
      statuses: statusList.length ? statusList : undefined,
      terminationReasonList: terminationReasonList.length ? terminationReasonList : undefined,
      supplyingStatus: filterState.supplyingStatus.map((s) => s._id),
      created: [
        {
          before: filterState.dateTo ? toLastSecond(new Date(filterState.dateTo)).toISOString() : undefined,
          after: filterState.dateFrom ? new Date(filterState.dateFrom).toISOString() : undefined
        }
      ],
      amount: [
        {
          lte: filterState.amountTo ? filterState.amountTo : undefined,
          gte: filterState.amountFrom ? filterState.amountFrom : undefined
        }
      ],
      categories: filterState.categories.map((c) => c.value.toString()),
      users: filterState.users.map((id) => id.toString()),
      sources: filterState.sources.map((source) => source._id),
      order: [{ id: 'desc' }],
      tagsList: filterState.tagsList.map((tag) => tag._id)
    }
  }, [filterState])

  return {
    filterState,
    setFilterState: dealFilterStateVar,
    filterActive,
    variables,
    query,
    setQuery: dealQueryVar
  }
}
