const decimalNumberFormatter = Intl.NumberFormat('ru-RU', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

const numberFormatter = Intl.NumberFormat('ru-RU')

const percentFormatter = Intl.NumberFormat('ru-RU', {
  style: 'percent',
  maximumFractionDigits: 2
})

const formatMoney = Intl.NumberFormat('ru-RU', { currency: 'RUB', style: 'currency', maximumFractionDigits: 0 }).format
const formatMoneyDecimal = Intl.NumberFormat('ru-RU', { currency: 'RUB', style: 'currency' }).format

const formatDecimal = (number = 0) => decimalNumberFormatter.format(number / 100)

const formatNumber = (number = 0) => numberFormatter.format(number / 100)

// TODO: same for other formatters
const formatPercent = (input: number | undefined | null): string =>
  typeof input === 'number' ? percentFormatter.format(input / 100) : ''

export { formatDecimal, formatNumber, formatPercent, formatMoney, formatMoneyDecimal, percentFormatter }
