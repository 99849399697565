import { FC, useState, useEffect, useMemo } from 'react'
import c from 'clsx'
import {
  useApplicationContactsQuery,
  useContactsSeparateFiltersQuery,
  useAddContactToApplicationMutation,
  useRemoveContactFromApplicationMutation,
  useApplicationByIdQuery
} from '../../graphql/schema'
import { ReactComponent as PlusIcon } from '../../svg/icons/plus.svg'
import SuspenseModal from '../../components/SuspenseModal'
import CreateContactForm from '../../components/Forms/CreateContactForm'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import useNodes from '../../hooks/useNodes'
import useThrottledState from '../../hooks/useThrottledState'
import AddButton from '../../components/AddButton'
import EditContactForm from '../../components/Forms/EditContactForm'
import ConfirmationForm from '../../components/ConfirmationForm'
import ContactsList from '../../components/ContactsList'
import { verificationRiskColor, VerificationState } from '../../utils/verification'

const defaultFilters = {
  phone: undefined,
  fio: undefined,
  email: undefined
}

type Inputs = {
  phone?: string
  fio?: string
  email?: string
}

const Contacts: FC = () => {
  const [formOpen, setFormOpen] = useState(false)
  const [editContactId, setEditContactId] = useState<number>()

  const [deletingContactId, setDeletingContactId] = useState<number>()

  const { id } = useParams<'id'>()

  const { data: applicationData } = useApplicationByIdQuery({ variables: { id: id?.toString() as string }, skip: !id })
  const application = applicationData?.application

  const query = useApplicationContactsQuery({ variables: { id: id?.toString() as string }, skip: !id })

  const formContext = useForm<Inputs>()

  const phone = formContext.watch('phone')
  const fio = formContext.watch('fio')
  const email = formContext.watch('email')

  const [contactFilters, setContactFilters] = useThrottledState<{ phone?: string; fio?: string; email?: string }>(
    defaultFilters,
    500
  )

  const suggestedContactsQuery = useContactsSeparateFiltersQuery()
  const refetchSuggestions = suggestedContactsQuery.refetch

  useEffect(() => {
    setContactFilters({
      phone,
      fio,
      email
    })
  }, [phone, fio, email, setContactFilters])

  useEffect(() => {
    console.log('refetch', {
      phone:
        contactFilters.phone && contactFilters.phone.length > 1 ? contactFilters.phone.replace(/\D/g, '') : undefined,
      fio: contactFilters.fio ? contactFilters.fio : undefined,
      email: contactFilters.email ? contactFilters.email : undefined,
      order: [{ fio: 'asc' }]
    })
    refetchSuggestions({
      phone:
        contactFilters.phone && contactFilters.phone.length > 1 ? contactFilters.phone.replace(/\D/g, '') : undefined,
      fio: contactFilters.fio ? contactFilters.fio : undefined,
      email: contactFilters.email ? contactFilters.email : undefined,
      order: [{ fio: 'asc' }]
    })
  }, [contactFilters, refetchSuggestions])

  const suggestedContacts = useNodes(suggestedContactsQuery.data?.contacts?.edges)
  const { data: appData, refetch, loading, error } = query

  const contactsList = useNodes(appData?.application?.contacts?.edges)

  const addedContactIds = useMemo(() => {
    return new Set(contactsList.map((node) => node._id))
  }, [contactsList])

  const [addContactToApp] = useAddContactToApplicationMutation({
    onCompleted: () => {
      refetch()
    }
  })
  const [removeContactFromApp] = useRemoveContactFromApplicationMutation({
    onCompleted: () => {
      refetch()
    }
  })

  function remove() {
    if (!deletingContactId) {
      return
    }
    return removeContactFromApp({
      variables: { input: { application: id as string, contact: deletingContactId.toString() } }
    })
  }

  if (!application) return null

  return (
    <div>
      <div className='rounded-xl bg-surface-secondary p-5'>
        <AddButton className='mb-5' onClick={() => setFormOpen(true)}>
          Добавить контакт
        </AddButton>
        <ContactsList
          loading={loading}
          contactsList={contactsList}
          onEdit={setEditContactId}
          onDelete={setDeletingContactId}
          error={error?.message}
        />
      </div>
      <SuspenseModal open={!!editContactId} setOpen={() => setEditContactId(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <EditContactForm
            onDone={() => {
              refetch()
              setEditContactId(undefined)
            }}
            id={editContactId as number}
          />
        </div>
      </SuspenseModal>
      <SuspenseModal open={formOpen} setOpen={setFormOpen}>
        <div className='z-10 grid grid-cols-2 rounded-xl bg-white-0'>
          <div>
            <CreateContactForm
              onDone={async (contactId) => {
                setFormOpen(false)
                if (contactId) {
                  await addContactToApp({
                    variables: {
                      input: {
                        contact: contactId.toString(),
                        application: id!
                      }
                    }
                  })
                }
                formContext.reset()
                refetch()
              }}
              context={formContext}
              suggestedValues={application}
            />
          </div>
          {(phone && phone.length > 3) || fio || email ? (
            <>
              <div className='flex flex-col justify-stretch py-12 pr-12'>
                <h1 className='mb-12 font-display text-h200'>Выбрать имеющийся</h1>
                <div className='flex h-200 min-h-0 flex-shrink flex-col gap-5 overflow-y-auto'>
                  {suggestedContacts.map((contact) => {
                    const added = addedContactIds.has(contact._id)
                    return (
                      <div
                        key={contact._id}
                        onClick={
                          added
                            ? () =>
                                removeContactFromApp({
                                  variables: { input: { application: id!, contact: contact._id.toString() } }
                                })
                            : () =>
                                addContactToApp({
                                  variables: { input: { application: id!, contact: contact._id.toString() } }
                                })
                        }
                        className='relative flex h-41 flex-none cursor-pointer items-center rounded-xl border-1 border-grayscale-400 px-10 hover:border-red-100'
                      >
                        <PlusIcon
                          className={c(
                            'absolute right-5 top-5 h-10 w-10 transition-transform duration-300',
                            added ? 'rotate-[225deg] text-grayscale-250' : 'text-red-100'
                          )}
                        />
                        <div>
                          <div className='mb-3 flex items-center text-p100 font-medium text-grayscale-0'>
                            {contact.fio}
                            <div
                              className={c(
                                'ml-5 h-6 w-6 rounded-full',
                                verificationRiskColor[contact.verificationStatus as VerificationState]
                              )}
                            />
                          </div>
                          <div className='flex items-center text-grayscale-150'>
                            {!contact.email && !contact.phone && <div className='text-grayscale-300'>—</div>}
                            <div className='flex items-center'>{contact.email}</div>
                            {!!contact.email && <div className='mx-5 h-2 w-2 rounded-full bg-grayscale-150' />}
                            <div className='flex items-center'>{contact.phone}</div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </>
          ) : (
            <div className='flex items-center justify-center'>
              <div className='rounded-full bg-grayscale-450 px-13 py-8 text-grayscale-200'>
                Начни вводить, чтобы выбрать имеющиеся
              </div>
            </div>
          )}
        </div>
      </SuspenseModal>
      <SuspenseModal open={!!deletingContactId} setOpen={() => setDeletingContactId(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ConfirmationForm
            title='Удаление контакта'
            onDone={() => {
              setDeletingContactId(undefined)
              remove()
            }}
            onDismiss={() => setDeletingContactId(undefined)}
          >
            Вы действительно хотите удалить контакт из заявки?
          </ConfirmationForm>
        </div>
      </SuspenseModal>
    </div>
  )
}

export default Contacts
