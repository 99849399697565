import { useContactByIdQuery } from '../../graphql/schema.tsx'

const useGetContactFioAndPhone = (contactId: number) => {
  const { data } = useContactByIdQuery({
    variables: {
      id: contactId.toString()
    },
    skip: !contactId
  })
  const contact = data?.contact

  return `${contact?.fio} ${contact?.phone}`
}

export default useGetContactFioAndPhone
