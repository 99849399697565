import { useMemo } from 'react'
import { useAccessAbilitiesQuery } from '../graphql/schema'
import useCurrentUser from './useCurrentUser'
import useNodes from './useNodes'
import { captureMessage } from '@sentry/react'

const useAccessCheck = (ability: string) => {
  const currentUser = useCurrentUser()
  const { data: accessAbilitiesData } = useAccessAbilitiesQuery()
  const accessAbilities = useNodes(accessAbilitiesData?.accessAbilities?.edges)

  return useMemo(() => {
    let hasAccess = false

    if (currentUser?.roles?.length && accessAbilities?.length) {
      const targetAbility = accessAbilities.find((accessAbility) => accessAbility.ability === ability)

      if (!targetAbility) {
        console.log(`Правило доступа "${ability}" не найдено!`)
        captureMessage(`Правило доступа "${ability}" не найдено!`)
      } else {
        currentUser.roles.forEach((role) => {
          if (targetAbility?.roles?.includes(role)) {
            hasAccess = true
          }
        })
      }
    }

    return hasAccess
  }, [currentUser, accessAbilities, ability])
}

export default useAccessCheck
