import { Kind } from '../graphql/schema.tsx'

const getVatKind = (kind?: Kind) => {
  switch (kind) {
    case Kind.Medicine:
      return 'Медицина (0%)'
    case Kind.Regular:
      return 'Обычная (20%)'
    case Kind.Usn:
      return 'УСН (0%)'
    default:
      return ''
  }
}

export default getVatKind
