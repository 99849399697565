import { useSwitcherState, StateDefinition } from '../../hooks/useSwitcherState'
import {
  ApplicationReconciliationsStatus,
  ApplicationStatusSelectDocument,
  ApplicationStatusSelectQuery,
  ApplicationStatusSelectQueryVariables,
  ReconciliationCustomerStatus,
  ReconciliationFeraStatus
} from '../../graphql/schema'
import { ApolloClient, useApolloClient } from '@apollo/client'
import { useEffect, useRef } from 'react'
import { BehaviorSubject } from 'rxjs'
import { AppStatus } from '../../components/ApplicationStatus'
import { createQueryChecker } from '../../utils/checkQuery.ts'

interface ApplicationSwitcherStateContext {
  applicationId: string
  client: ApolloClient<unknown>
}

const watchApplication = ({ client, applicationId }: ApplicationSwitcherStateContext) => {
  return client.watchQuery<ApplicationStatusSelectQuery, ApplicationStatusSelectQueryVariables>({
    query: ApplicationStatusSelectDocument,
    variables: { id: applicationId }
  })
}

const recStatusNegotiations = createQueryChecker(watchApplication, (data) => {
  if (data?.application?.reconciliationsStatus === ApplicationReconciliationsStatus.Negotiations)
    return { passed: true }
  return { passed: false, message: 'Добавить согласования в работу' }
})

const hasAcceptedRecs = createQueryChecker(watchApplication, (data) => {
  const reconciliations = data?.application?.reconciliations?.edges?.map((e) => e?.node) || []
  const acceptedRecs = reconciliations.filter(
    (rec) =>
      rec?.feraStatus === ReconciliationFeraStatus.Accepted &&
      rec?.customerStatus === ReconciliationCustomerStatus.Accepted &&
      rec?.feraAcceptExpiresAt &&
      new Date() <= new Date(rec?.feraAcceptExpiresAt)
  )
  if (acceptedRecs.length > 0) return { passed: true }
  return { passed: false, message: 'Согласовать условия' }
})

const edoSpecified = createQueryChecker(watchApplication, (data) => {
  const customer = data.application?.customerCompany
  if (
    customer?.edoStatus === false ||
    (customer?.edoStatus === true && (!!customer?.edoTypes?.totalCount || !!customer?.edoCustomValue))
  )
    return { passed: true }
  return { passed: false, message: 'Указать ЭДО' }
})

const applicationStateDefinitions: StateDefinition<AppStatus, ApplicationSwitcherStateContext>[] = [
  {
    state: AppStatus.FirstContact,
    nextStates: [
      AppStatus.Reconciliation,
      AppStatus.DecisionMaking,
      AppStatus.DataCollection,
      AppStatus.Scoring,
      AppStatus.Closed
    ]
  },
  {
    state: AppStatus.Reconciliation,
    nextStates: [
      AppStatus.FirstContact,
      AppStatus.DecisionMaking,
      AppStatus.DataCollection,
      AppStatus.Scoring,
      AppStatus.Closed
    ]
  },
  {
    state: AppStatus.DecisionMaking,
    nextStates: [
      AppStatus.FirstContact,
      AppStatus.Reconciliation,
      AppStatus.DataCollection,
      AppStatus.Scoring,
      AppStatus.Closed
    ]
  },
  {
    state: AppStatus.DataCollection,
    nextStates: [
      AppStatus.FirstContact,
      AppStatus.Reconciliation,
      AppStatus.DecisionMaking,
      AppStatus.Scoring,
      AppStatus.Closed
    ]
  },
  {
    state: AppStatus.Scoring,
    nextStates: [
      AppStatus.FirstContact,
      AppStatus.Reconciliation,
      AppStatus.DecisionMaking,
      AppStatus.DataCollection,
      AppStatus.DealPreparation,
      AppStatus.Deal,
      AppStatus.Closed
    ],
    checks: [recStatusNegotiations]
  },
  {
    state: AppStatus.DealPreparation,
    nextStates: [
      AppStatus.FirstContact,
      AppStatus.Reconciliation,
      AppStatus.DecisionMaking,
      AppStatus.DataCollection,
      AppStatus.Scoring,
      AppStatus.Deal,
      AppStatus.Closed
    ]
  },
  {
    state: AppStatus.Deal,
    nextStates: [],
    checks: [hasAcceptedRecs, edoSpecified]
  },
  {
    state: AppStatus.Closed,
    nextStates: [
      AppStatus.FirstContact,
      AppStatus.Reconciliation,
      AppStatus.DecisionMaking,
      AppStatus.DataCollection,
      AppStatus.Scoring,
      AppStatus.Closed
    ]
  }
]

export function useApplicationSwitcherState(applicationId: string, applicationStatus: AppStatus | undefined) {
  const client = useApolloClient()

  const context$ = useRef(
    new BehaviorSubject<ApplicationSwitcherStateContext>({
      client,
      applicationId
    })
  ).current

  useEffect(() => {
    context$.next({
      client,
      applicationId
    })
  }, [client, applicationId, context$])

  return useSwitcherState(applicationStatus, applicationStateDefinitions, context$)
}
