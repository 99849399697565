import { getGender } from 'lvovich'

const getGenderByFio = (fio: string): 'male' | 'female' => {
  if (!fio) {
    return 'male'
  }
  const fioArr = fio.trim().replace(/\s\s/g, ' ').split(' ')
  if (fioArr.length > 3) {
    return 'male'
  }
  const [first, last, middle] = fioArr
  const gender = getGender({first, last, middle})
  return gender === 'female' ? 'female' : 'male'
}

export default getGenderByFio
