import { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useUpdateCustomerManagerMutation } from '../../graphql/schema'
import { handleBackendErrorsToForm } from '../../utils/backendErrorUtils'
import { ApolloError } from '@apollo/client'
import SubmitButton from '../../components/SubmitButton'
import ManagerInput from '../../components/Forms/Inputs/ManagerInput'

import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'

interface Inputs {
  id: string
  userId: number
}

interface CustomerManagerFormProps {
  customerId: string
  managerId: string
  onDone?: () => void
}

const CustomerManagerForm: FC<CustomerManagerFormProps> = ({ customerId, managerId, onDone }) => {
  const [updateManager, { loading }] = useUpdateCustomerManagerMutation()

  const { handleSubmit, formState, setError, control } = useForm<Inputs>({
    defaultValues: {
      userId: parseInt(managerId)
    }
  })

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await updateManager({
      variables: {
        input: {
          id: customerId,
          customerManagerUser: data.userId ? data.userId.toString() : null
        }
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  return (
    <section className='md: w-[448px] p-12'>
      <h1 className='mb-12 font-display text-h200'>Менеджеры Fera</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='relative mb-12 flex flex-col gap-8'>
          <Controller
            name='userId'
            control={control}
            render={({ field }) => (
              <ManagerInput
                selected={field.value ? [field.value] : []}
                onChange={(selected) =>
                  selected.length ? field.onChange(selected[selected.length - 1]) : field.onChange(null)
                }
                label='Менеджер по лизингу'
                placeholder='Выберите менеджера'
                error={formState.errors?.userId}
              />
            )}
          />
        </div>
        <SubmitButton loading={loading}>
          <EditIcon className='mr-5' />
          Изменить
        </SubmitButton>
      </form>
    </section>
  )
}

export default CustomerManagerForm
