import { makeVar, useReactiveVar } from '@apollo/client'
import { FC, useEffect, useMemo } from 'react'
import { CustomersListQueryVariables, UserRoles, useUsersQuery } from '../../graphql/schema'
import useNodes from '../../hooks/useNodes'
import FilterForm from '../../components/Filter/Form'
import { throttle } from 'throttle-debounce'
import ManagerInput from '../../components/Forms/Inputs/ManagerInput'
import sortUsersByAcivation from '../../utils/sortUsersByActivation'

export interface CustomersFilterState {
  managerUsers: number[]
  query?: string
}

export interface FilterFormProps {
  state: CustomersFilterState
  onChange: (state: CustomersFilterState) => void
}
const customerFilterStateVar = makeVar<CustomersFilterState>({
  managerUsers: []
})
const customerQueryVar = makeVar<string>('')

const CustomerFilterForm: FC<FilterFormProps> = ({ state, onChange }) => {
  const { data: managerUsersData } = useUsersQuery({
    variables: { order: [{ name: 'asc' }], roles: UserRoles.RoleManager }
  })
  const managerUsers = useNodes(managerUsersData?.users?.edges)
  managerUsers.sort(sortUsersByAcivation)

  return (
    <FilterForm
      onReset={() => {
        onChange({
          ...state,
          managerUsers: []
        })
      }}
      title='Фильтр клиентов'
    >
      <div className='group relative mb-10'>
        <ManagerInput
          label='Менеджеры по лизингу'
          placeholder='Выберите менеджеров'
          multiple
          selected={state.managerUsers}
          onChange={(managerUsers) => onChange({ ...state, managerUsers })}
        />
      </div>
    </FilterForm>
  )
}

export default CustomerFilterForm

const setQuery = throttle(500, (query: string) => {
  customerFilterStateVar({
    ...customerFilterStateVar(),
    query
  })
})

export const useCustomerFilter = () => {
  const filterState = useReactiveVar(customerFilterStateVar)
  const query = useReactiveVar(customerQueryVar)

  const filterActive = Boolean(filterState.managerUsers.length)

  useEffect(() => {
    setQuery(query)
  }, [query])

  const variables = useMemo<CustomersListQueryVariables>((): CustomersListQueryVariables => {
    if (filterState?.query?.length) {
      return {
        query: filterState.query
      }
    }
    return {
      customerManagerUsers: filterState.managerUsers.map((user) => user.toString())
    }
  }, [filterState])

  return {
    filterState,
    setFilterState: customerFilterStateVar,
    filterActive,
    variables,
    query,
    setQuery: customerQueryVar
  }
}
