import { FC } from 'react'
import { Card, CardHeader, CardTitle } from '../../../components/Card'
import { ReactComponent as CopyIcon } from '../../../svg/icons/copy.svg'
import copyToClipboard from 'copy-to-clipboard'
import { showFizz } from '../../../components/Fizz'
import {
  PaymentKind,
  PaymentTargetType,
  useDealForCollectorMessagesQuery,
  usePaymentsForCollectorMessagesQuery
} from '../../../graphql/schema'
import { getDateFormattedString, getFormattedContractNumber } from '../../../utils/contractUtils'
import { dateFormatter, getCalendarFormatDate } from '../../../utils/dateFormatter'
import useNodes from '../../../hooks/useNodes'
import { formatDecimal } from '../../../utils/formatNumber'
import useClaimSchedule from '../Documents/hooks/useClaimSchedule'

interface CollectorMessagesProps {
  dealId: number
}

interface ThreatMessageProps {
  contractNumber?: string
  contractDate?: string
  overduePaymentsAmount: number
  totalOverdueUnpaidAmount: number
  totalUnpaidPenalties: number
}

const NO_CONTACT_MESSAGE =
  'Здравствуйте! Не смог до Вас дозвониться, свяжитесь со мной для обсуждения условий оплаты задолженности и возврате лизингового оборудования. Предлагаю решить данный вопрос без привлечения правоохранительных органов, судов и судебных приставов. В случае игнорирования и уклонения от исполнения взятых на себя, в соответствии с договором лизинга, обязательств, будем вынуждены обратиться в правоохранительные органы для решения вопроса о возбуждении в отношении Вас уголовного дела по ст. 158, 159, 160 УК РФ.'

const THREAT_MESSAGE = ({
  contractNumber,
  contractDate,
  overduePaymentsAmount,
  totalOverdueUnpaidAmount,
  totalUnpaidPenalties
}: ThreatMessageProps) =>
  `Здравствуйте! Напоминаем Вам о том, что в связи с невыполнением Вами условий договора лизинга ${getFormattedContractNumber(contractNumber || '-')} от ${contractDate ? dateFormatter.format(new Date(contractDate)) : '—'}, заключенного между Вами и ООО ФЕРА, образовалась просроченная задолженность в размере: (сумма ${overduePaymentsAmount === 1 ? 'ежемесячного платежа' : 'ежемесячных платежей'} в размере ${formatDecimal(totalOverdueUnpaidAmount * 100)} руб., а так же пени на текущий период в размере ${formatDecimal(totalUnpaidPenalties * 100)} руб.). Прошу дать обратную связь для урегулирования данного вопроса. С уважением ООО ФЕРА!`

const CollectorMessages: FC<CollectorMessagesProps> = ({ dealId }) => {
  const { data: dealData } = useDealForCollectorMessagesQuery({
    variables: {
      id: `${dealId}`
    },
    skip: !dealId
  })

  const deal = dealData?.deal

  const { data: leasingPaymentsData } = usePaymentsForCollectorMessagesQuery({
    variables: {
      kind: PaymentKind.Leasing,
      targetType: PaymentTargetType.Deal,
      targetId: `${dealId}`
    },
    skip: !dealId
  })

  const { getClaimSchedule } = useClaimSchedule(dealId)

  const leasingPayments = useNodes(leasingPaymentsData?.payments.edges)

  const overdueUnpaidPayments = leasingPayments.filter(
    (payment) => (payment.state === 'PENDING' || payment.state === 'PARTLY_PAID') && payment.isOverdued
  )

  const overdueUnpaidPaymentsCount = overdueUnpaidPayments.length

  const totalOverdueUnpaidAmount = overdueUnpaidPayments.reduce((sum, payment) => {
    if (payment.state === 'PENDING') {
      return sum + payment.amount
    }
    if (payment.state === 'PARTLY_PAID') {
      return sum + (payment.amount - payment.amountPaid)
    }
    return sum
  }, 0)

  return (
    <Card className='h-44'>
      <CardHeader>
        <CardTitle>Быстрые сообщения</CardTitle>
      </CardHeader>
      <div className='mx-5 flex h-18 items-center justify-between rounded-md bg-surface-primary font-medium text-labels-secondary shadow-card'>
        <button
          className='inline-flex h-full grow cursor-pointer items-center justify-center gap-2 rounded-l-md hover:text-labels-primary active:bg-surface-secondary'
          onClick={async (e) => {
            const claimSchedule = (await getClaimSchedule(getCalendarFormatDate(new Date())))?.map((c) => ({
              amount: c.amount,
              date: c.date ? getDateFormattedString(new Date(c.date)) : '',
              number: c.number,
              overdueDays: c.overdueDays || undefined,
              overdueEnd: c.overdueEnd ? getDateFormattedString(new Date(c.overdueEnd)) : undefined,
              overdueFrom: c.overdueFrom ? getDateFormattedString(new Date(c.overdueFrom)) : undefined,
              paidAmount: c.paidAmount || undefined,
              paidPenaltyAmount: c.paidPenaltyAmount || undefined,
              penaltyAmount: c.penaltyAmount || undefined,
              transactionPaidAt: c.transactionPaidAt ? getDateFormattedString(new Date(c.transactionPaidAt)) : undefined
            }))

            const totalPenaltyAmount = claimSchedule?.reduce((acc, claim) => acc + (claim?.penaltyAmount || 0), 0)
            const totalPaidPenaltyAmount = claimSchedule?.reduce(
              (acc, claim) => acc + (claim?.paidPenaltyAmount || 0),
              0
            )
            const diffPenaltyAmount = (totalPenaltyAmount || 0) - (totalPaidPenaltyAmount || 0)

            copyToClipboard(
              THREAT_MESSAGE({
                contractDate: deal?.contractDate,
                contractNumber: deal?.contractNumber,
                overduePaymentsAmount: overdueUnpaidPaymentsCount,
                totalOverdueUnpaidAmount: totalOverdueUnpaidAmount,
                totalUnpaidPenalties: diffPenaltyAmount
              })
            )
            showFizz('Скопировано', e.clientX, e.clientY)
          }}
        >
          <CopyIcon /> Угроза
        </button>
        <span className='h-18 w-1 border-r-1 border-grayscale-400' />
        <button
          className='inline-flex h-full grow cursor-pointer items-center justify-center gap-2 rounded-r-md hover:text-labels-primary active:bg-surface-secondary'
          onClick={async (e) => {
            copyToClipboard(NO_CONTACT_MESSAGE)
            showFizz('Скопировано', e.clientX, e.clientY)
          }}
        >
          <CopyIcon /> Недозвон
        </button>
      </div>
    </Card>
  )
}

export default CollectorMessages
