import { useEffect, useMemo, useState } from 'react'
import * as React from 'react'
import Input from '../../../components/Input'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { ReactComponent as PlusIcon } from '../../../svg/icons/plus.svg'
import { ReactComponent as LoadingIcon } from '../../../svg/icons/loading.svg'
import { ReactComponent as MoreIcon } from '../../../svg/icons/more.svg'
import { ReactComponent as TrashIcon } from '../../../svg/icons/trash.svg'
import Bubble from '../../../components/Bubble'
import {
  Subject,
  distinctUntilChanged,
  debounceTime,
  combineLatest,
  switchMap,
  from,
  of,
  BehaviorSubject,
  map,
  filter,
  debounce,
  timer,
  tap,
  finalize,
  skip,
  catchError
} from 'rxjs'
import c from 'clsx'
import {
  Kind,
  useAddReconciliationMutation,
  RateCalculationAgeType,
  AgeType,
  RateCalculationLiquidityType,
  LiquidityType,
  ReconciliationInitiatedBy,
  ReconciliationCustomerStatus,
  ReconciliationFeraStatus,
  RateCalculationDocument,
  RateCalculationQuery,
  RateCalculationQueryVariables,
  InsuranceRateCalculationDocument,
  InsuranceRateCalculationQuery,
  InsuranceRateCalculationQueryVariables,
  ComissionRateCalculationQuery,
  ComissionRateCalculationQueryVariables,
  ComissionRateCalculationDocument,
  ReconciliationGuarantorsQuery,
  ReconciliationGuarantorsQueryVariables,
  ReconciliationGuarantorsDocument,
  DealCalculationsQuery,
  DealCalculationsQueryVariables,
  DealCalculationsDocument,
  useApplicationContactsQuery,
  VerificationState,
  ApplicationContactsQuery
} from '../../../graphql/schema'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils'
import { ApolloError, useApolloClient } from '@apollo/client'
import parseDecimal, { parseDecimalFromMasked } from '../../../utils/parseDecimal'
import { formatDecimal, formatPercent } from '../../../utils/formatNumber'
import SubmitButton from '../../../components/SubmitButton'
import { RadioGroup } from '@headlessui/react'
import { getAdvancePaymentDate } from '../../../hooks/useDownloadCommercial'
import MaskedNumberInput from '../../../components/Forms/Inputs/MaskedNumberInput'
import MaskedPercentInput from '../../../components/Forms/Inputs/MaskedPercentInput'
import { CompanyData } from '../../../types/dadata'
import useNodes from '../../../hooks/useNodes'
import useThrottledState from '../../../hooks/useThrottledState'
import { useCompanySuggestions } from '../../../hooks/useSuggestions'
import Suggestions from '../../../components/Suggestions'
import getCompanyName from '../../../utils/getCompanyName'
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber'
import { errorToString } from '../../../utils/errorToString'
import { verificationStateDict } from '../../../utils/dictionaries'
import { NodeType } from '../../../types'
import { REGULAR_VAT_RATE } from '../../../utils/constants.ts'
import convertKindToVatKind from '../../../utils/convertKindToVatKind.ts'

type Tab = 'main' | 'guarantors'

type Contact = NodeType<NonNullable<ApplicationContactsQuery['application']>['contacts']>

interface RecGuarantor {
  type: 'contact' | 'company'
  _id: number | string
  name: string
  verificationStatusAccepted?: boolean
  verificationStatusAcceptExpired?: boolean
}

interface Inputs {
  advanceRate?: string
  amount?: string
  application?: string
  commissionRate?: string
  durationMonths?: string
  insuranceRate?: string
  interestRate?: string
  kind?: Kind
  monthPayment?: string
  vatRate?: string
  ageType?: RateCalculationAgeType
  liquidityType?: RateCalculationLiquidityType

  company?: string

  // this fields are not shown to user, they store calculated rates for analytics
  recommendedCommissionRate?: number
  recommendedInsuranceRate?: number
  recommendedInterestRate?: number

  requiredGuarantorsCount?: number
  guarantors?: RecGuarantor[]
}

interface RecFormProps {
  onDone?: () => void
  applicationId: string
  initiatedByUs?: boolean
  actual?: boolean
  acceptedByDefault?: boolean
  companyRegDate?: string
  previousReconciliationId?: number
  defaultValues: {
    amount?: number
    durationMonths?: number
    advanceRate?: number
    interestRate?: number
    insuranceRate?: number
    comissionRate?: number
    kind?: Kind
    vatRate?: number
    monthPayment?: number
    ageType?: AgeType
    liquidityType?: LiquidityType
    recommendedCommissionRate?: number
    recommendedInsuranceRate?: number
    recommendedInterestRate?: number

    reconciliationGuarantors?: {
      edges?: (
        | {
            node?: {
              _id: number
              contact?: {
                _id: number
                fio: string
                verificationStatus: string
                verificationAcceptExpiresAt?: string
              }
              company?: {
                _id: number
                inn: string
                shortWithOpf?: string
              }
            }
          }
        | undefined
      )[]
    }
    requiredGuarantorsCount?: number
  }
  commissionRewardRate?: number
}

const getCompanyAgeType = (companyRegDate?: string) => {
  if (!companyRegDate) return
  const reg = new Date(companyRegDate).getTime()

  const date = new Date()
  const year = date.getUTCFullYear()

  date.setUTCFullYear(year - 1)
  const yearAgo = date.getTime()

  if (reg > yearAgo) {
    return RateCalculationAgeType.Less_1Year
  }

  date.setUTCFullYear(year - 3)
  const threeYearsAgo = date.getTime()

  if (reg > threeYearsAgo) {
    return RateCalculationAgeType.From_1To_3Years
  }

  date.setUTCFullYear(year - 5)
  const fiveYearsAgo = date.getTime()

  if (reg > fiveYearsAgo) {
    return RateCalculationAgeType.From_3To_5Years
  }

  return RateCalculationAgeType.More_5Years
}

const ageTypeMap: Record<RateCalculationAgeType, AgeType> = {
  [RateCalculationAgeType.Less_1Year]: AgeType.Less_1Year,
  [RateCalculationAgeType.From_1To_3Years]: AgeType.From_1To_3Years,
  [RateCalculationAgeType.From_3To_5Years]: AgeType.From_3To_5Years,
  [RateCalculationAgeType.More_5Years]: AgeType.More_5Years,
  [RateCalculationAgeType.More_5YearsWoLossesOrRevenueGrowth]: AgeType.More_5YearsWoLossesOrRevenueGrowth,
  [RateCalculationAgeType.More_5YearsWoLossesAndRevenueGrowth]: AgeType.More_5YearsWoLossesAndRevenueGrowth
}

const liquidityTypeMap: Record<RateCalculationLiquidityType, LiquidityType> = {
  [RateCalculationLiquidityType.NotLiquid]: LiquidityType.NotLiquid,
  [RateCalculationLiquidityType.LiquidHighRisk]: LiquidityType.LiquidHighRisk,
  [RateCalculationLiquidityType.LiquidLowRisk]: LiquidityType.LiquidLowRisk
}

const reverseAgeTypeMap: Record<AgeType, RateCalculationAgeType> = {
  [AgeType.Less_1Year]: RateCalculationAgeType.Less_1Year,
  [AgeType.From_1To_3Years]: RateCalculationAgeType.From_1To_3Years,
  [AgeType.From_3To_5Years]: RateCalculationAgeType.From_3To_5Years,
  [AgeType.More_5Years]: RateCalculationAgeType.More_5Years,
  [AgeType.More_5YearsWoLossesOrRevenueGrowth]: RateCalculationAgeType.More_5YearsWoLossesOrRevenueGrowth,
  [AgeType.More_5YearsWoLossesAndRevenueGrowth]: RateCalculationAgeType.More_5YearsWoLossesAndRevenueGrowth
}

const reverseLiquidityTypeMap: Record<LiquidityType, RateCalculationLiquidityType> = {
  [LiquidityType.NotLiquid]: RateCalculationLiquidityType.NotLiquid,
  [LiquidityType.LiquidHighRisk]: RateCalculationLiquidityType.LiquidHighRisk,
  [LiquidityType.LiquidLowRisk]: RateCalculationLiquidityType.LiquidLowRisk
}

function sortContactsByStatus(a: Contact, b: Contact) {
  const isAcceptedAndNotExpired = (contact: Contact) =>
    contact.verificationStatus === 'accepted' && new Date() < new Date(contact.verificationAcceptExpiresAt ?? '')

  if (isAcceptedAndNotExpired(a)) {
    if (isAcceptedAndNotExpired(b)) {
      return 0
    } else {
      return -1
    }
  } else if (isAcceptedAndNotExpired(b)) {
    return 1
  } else {
    return a.verificationStatus === 'accepted' ? -1 : b.verificationStatus === 'accepted' ? 1 : 0
  }
}

const ReconciliationForm: React.FC<RecFormProps> = ({
  applicationId,
  onDone,
  defaultValues,
  companyRegDate,
  initiatedByUs,
  actual,
  acceptedByDefault,
  commissionRewardRate,
  previousReconciliationId
}) => {
  const [addReconciliation] = useAddReconciliationMutation()
  // const [dealCalculationsQuery, { loading: dealLoading }] = useDealCalculationsLazyQuery()

  const [operationsLoading, setOperationsLoading] = useState(false)
  const [tab, setTab] = useState<Tab>('main')

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    watch,
    control,
    trigger,
    formState: { errors, isSubmitting, isValid, defaultValues: formDefaultValues }
  } = useForm<Inputs>({
    mode: 'onBlur',
    defaultValues: {
      amount: defaultValues.amount ? formatDecimal(defaultValues.amount) : '',
      durationMonths: defaultValues.durationMonths?.toString() ?? '',
      advanceRate: formatPercent(defaultValues.advanceRate),
      interestRate: formatPercent(defaultValues.interestRate),
      insuranceRate: formatPercent(defaultValues.insuranceRate),
      commissionRate: formatPercent(defaultValues.comissionRate),
      kind: defaultValues.kind,
      ageType: defaultValues.ageType ? reverseAgeTypeMap[defaultValues.ageType] : getCompanyAgeType(companyRegDate),
      liquidityType: defaultValues.liquidityType ? reverseLiquidityTypeMap[defaultValues.liquidityType] : undefined,
      monthPayment: defaultValues.monthPayment !== undefined ? (defaultValues.monthPayment / 100).toString() : '',

      requiredGuarantorsCount: defaultValues.requiredGuarantorsCount ?? 0,
      guarantors: defaultValues.reconciliationGuarantors?.edges?.map((edge) => {
        const node = edge?.node
        if (!node) return
        return node.contact
          ? {
              type: 'contact',
              _id: node.contact._id,
              name: node.contact.fio,
              verificationStatusAccepted: node.contact.verificationStatus === 'accepted',
              verificationStatusAcceptExpired:
                node.contact.verificationStatus === 'accepted' && node.contact.verificationAcceptExpiresAt
                  ? new Date() > new Date(node.contact.verificationAcceptExpiresAt)
                  : false
            }
          : node.company
            ? { type: 'company', _id: node.company.inn, name: node.company.shortWithOpf }
            : undefined
      }),

      company: ''
    }
  })

  const { data } = useApplicationContactsQuery({
    variables: { id: applicationId.toString() },
    skip: !applicationId
  })
  const contactsList = useNodes(data?.application?.contacts?.edges)
  const sortedContacts = useMemo(() => {
    return contactsList.sort(sortContactsByStatus)
  }, [contactsList])

  const companyQuery = watch('company')
  const [throttledCompanyQuery] = useThrottledState(companyQuery, 500)
  const suggestions = useCompanySuggestions(throttledCompanyQuery)

  const client = useApolloClient()

  // handle all complex async logic using RXJS
  useEffect(() => {
    // subject to track form values
    const formValuesSubject = new Subject<Inputs>()

    // track loading state of the rates calculations
    const rateCalculationLoading = new BehaviorSubject(false)
    const insuranceRateCalculationLoading = new BehaviorSubject(false)
    const commissionRateCalculationLoading = new BehaviorSubject(false)

    const dealCalculationLoading = new BehaviorSubject(false)

    const rateCalculationsLoading = combineLatest([
      rateCalculationLoading,
      insuranceRateCalculationLoading,
      commissionRateCalculationLoading
    ]).pipe(
      // output true if any of the rates is loading
      map((loadings) => loadings.some(Boolean)),
      distinctUntilChanged()
    )

    const operationsLoadingSubscription = combineLatest([rateCalculationsLoading, dealCalculationLoading])
      .pipe(
        // output true if any of the rates is loading
        map((loadings) => loadings.some(Boolean)),
        distinctUntilChanged(),
        debounce((value) => timer(value ? 0 : 300))
      )
      .subscribe((loading) => {
        setOperationsLoading(loading)
      })

    // calculate rate
    const rateSubscription = formValuesSubject
      .pipe(
        // only emit if the value has changed
        distinctUntilChanged((a, b) => {
          return (
            parseDecimalFromMasked(a.amount ?? '') === parseDecimalFromMasked(b.amount ?? '') && // parsing because mask sometimes eats fractional zeros
            a.liquidityType === b.liquidityType &&
            a.ageType === b.ageType &&
            parseFloat(a.advanceRate ?? '') === parseFloat(b.advanceRate ?? '') && // parsing because mask sometimes adds non-breaking spaces
            a.durationMonths === b.durationMonths &&
            a.kind === b.kind
          )
        }),
        // run initial calculation only if default value is not set
        skip(typeof defaultValues.interestRate === 'number' ? 1 : 0), // skip the initial value
        tap(() => {
          rateCalculationLoading.next(true)
        }),
        debounceTime(300),
        // create a new request observable for each value change, but cancel the previous one if it's still in progress
        switchMap(({ ageType, liquidityType, amount, durationMonths, advanceRate, kind }) => {
          if (!ageType || !liquidityType || !amount || !durationMonths || !advanceRate || !kind) {
            rateCalculationLoading.next(false)
            return of()
          }
          const controller = new AbortController()
          const signal = controller.signal
          const promise = client
            .query<RateCalculationQuery, RateCalculationQueryVariables>({
              query: RateCalculationDocument,
              variables: {
                params: {
                  amount: parseDecimal(amount.replace(/\s/g, '')),
                  liquidityType: liquidityType,
                  ageType: ageType,
                  advanceRate: parseFloat(advanceRate),
                  durationMonths: parseInt(durationMonths),
                  vatKind: convertKindToVatKind(kind)
                }
              },
              context: {
                fetchOptions: {
                  signal
                }
              }
            })
            .then((response) => {
              return response.data.rateCalculation
            })
          return from(promise).pipe(finalize(() => rateCalculationLoading.next(false)))
        })
      )
      .subscribe((rateCalculation) => {
        setValue('interestRate', formatPercent(rateCalculation.rate), { shouldValidate: true })
        setValue('recommendedInterestRate', rateCalculation.rate)
      })

    // calculate insurance rate
    const insuranceRateSubscription = formValuesSubject
      .pipe(
        distinctUntilChanged((a, b) => {
          return parseDecimalFromMasked(a.amount ?? '') === parseDecimalFromMasked(b.amount ?? '') // parsing because mask sometimes eats fractional zeros
        }),
        // run initial calculation only if default value is not set
        skip(typeof defaultValues.insuranceRate === 'number' ? 1 : 0), // skip the initial value
        tap(() => insuranceRateCalculationLoading.next(true)),
        debounceTime(300),
        switchMap(({ amount }) => {
          if (!amount) {
            insuranceRateCalculationLoading.next(false)
            return of()
          }
          const controller = new AbortController()
          const signal = controller.signal

          const promise = client
            .query<InsuranceRateCalculationQuery, InsuranceRateCalculationQueryVariables>({
              query: InsuranceRateCalculationDocument,
              variables: {
                amount: parseDecimal(amount.replace(/\s/g, ''))
              },
              context: {
                fetchOptions: {
                  signal
                }
              }
            })
            .then((response) => {
              return response.data.insuranceRateCalculation
            })

          return from(promise).pipe(finalize(() => insuranceRateCalculationLoading.next(false)))
        })
      )
      .subscribe((insuranceRateCalculation) => {
        setValue('insuranceRate', formatPercent(insuranceRateCalculation.insuranceRate), { shouldValidate: true })
        setValue('recommendedInsuranceRate', insuranceRateCalculation.insuranceRate)
      })

    // calculate commission rate
    const commissionRateSubscription = formValuesSubject
      .pipe(
        distinctUntilChanged((a, b) => {
          return a.ageType === b.ageType
        }),
        // run initial calculation only if default value is not set
        skip(typeof defaultValues.comissionRate === 'number' ? 1 : 0),
        tap(() => commissionRateCalculationLoading.next(true)),
        debounceTime(300),
        switchMap(({ ageType }) => {
          if (!ageType) {
            commissionRateCalculationLoading.next(false)
            return of()
          }
          const controller = new AbortController()
          const signal = controller.signal

          const promise = client
            .query<ComissionRateCalculationQuery, ComissionRateCalculationQueryVariables>({
              query: ComissionRateCalculationDocument,
              variables: {
                sourceCommissionRewardRate: commissionRewardRate ?? 0,
                ageType
              },
              context: {
                fetchOptions: {
                  signal
                }
              }
            })
            .then((response) => {
              return response.data.comissionRateCalculation
            })

          return from(promise).pipe(finalize(() => commissionRateCalculationLoading.next(false)))
        })
      )
      .subscribe((commissionRateCalculation) => {
        setValue('commissionRate', formatPercent(commissionRateCalculation.comissionRate), { shouldValidate: true })
        setValue('recommendedCommissionRate', commissionRateCalculation.comissionRate)
      })

    const dealCalculationsSubscription = combineLatest([formValuesSubject, rateCalculationsLoading])
      .pipe(
        // we want to prevent deal calculations until all the rates are calculated
        // listen to the active operations and only calculate if there are no active operations
        filter(([_, activeRateCalculations]) => !activeRateCalculations),
        map(([values]) => values),
        distinctUntilChanged((a, b) => {
          return (
            a.amount === b.amount &&
            a.advanceRate === b.advanceRate &&
            a.interestRate === b.interestRate &&
            a.durationMonths === b.durationMonths &&
            a.commissionRate === b.commissionRate &&
            a.insuranceRate === b.insuranceRate &&
            a.kind === b.kind
          )
        }),
        skip(1), // skip the initial value
        tap(() => dealCalculationLoading.next(true)),
        switchMap(({ amount, advanceRate, interestRate, durationMonths, commissionRate, insuranceRate, kind }) => {
          if (
            !amount ||
            !advanceRate ||
            !interestRate ||
            !durationMonths ||
            !commissionRate ||
            !insuranceRate ||
            !kind
          ) {
            dealCalculationLoading.next(false)
            return of('')
          }
          const controller = new AbortController()
          const signal = controller.signal

          const promise = client
            .query<DealCalculationsQuery, DealCalculationsQueryVariables>({
              query: DealCalculationsDocument,
              variables: {
                dealParams: {
                  amount: parseDecimalFromMasked(amount) || 0,
                  advanceRate: parseDecimal(advanceRate) || 0,
                  interestRate: parseDecimal(interestRate) || 0,
                  durationMonths: parseInt(durationMonths) || 0,
                  comissionRate: parseDecimal(commissionRate) || 0,
                  insuranceRate: parseDecimal(insuranceRate) || 0,
                  vatRate: kind === Kind.Medicine ? 0 : REGULAR_VAT_RATE,
                  advancePaymentDate: getAdvancePaymentDate(new Date(), true)
                }
              },
              context: {
                fetchOptions: {
                  signal
                }
              }
            })
            .then(({ data }) => {
              const dealCalculations = data.dealCalculations
              return dealCalculations.monthlyPayment.toString() || ''
            })

          return from(promise)
        }),
        catchError(() => {
          dealCalculationLoading.next(false)
          return of('')
        })
      )
      .subscribe((monthPayment) => {
        setValue('monthPayment', monthPayment, { shouldValidate: true })
        dealCalculationLoading.next(false)
      })

    // listen to form updates
    const subscription = watch((value) => {
      formValuesSubject.next(value as Inputs)
    })

    // trigger the initial form values
    if (formDefaultValues) {
      formValuesSubject.next(formDefaultValues as Inputs)
    }

    return () => {
      subscription.unsubscribe()
      rateSubscription.unsubscribe()
      insuranceRateSubscription.unsubscribe()
      commissionRateSubscription.unsubscribe()
      dealCalculationsSubscription.unsubscribe()
      operationsLoadingSubscription.unsubscribe()
    }
  }, [watch, client, setValue, commissionRewardRate, formDefaultValues])

  const onSubmit: SubmitHandler<Inputs> = async ({
    durationMonths,
    amount,
    kind,
    ageType,
    insuranceRate,
    advanceRate,
    commissionRate,
    interestRate,
    liquidityType,
    recommendedCommissionRate,
    recommendedInsuranceRate,
    recommendedInterestRate,
    requiredGuarantorsCount
  }) => {
    // all this fields are required in form, this check is for typescript
    // it will not fail silently

    if (!durationMonths) console.log('no durationMonth')
    if (!amount) console.log('no amount')
    if (!insuranceRate) console.log('no insuranceRate')
    if (!advanceRate) console.log('no advanceRate')
    if (!commissionRate) console.log('no commissionRate')
    if (!interestRate) console.log('no interestRate')
    if (!liquidityType) console.log('no liquidityType')
    if (!kind) console.log('no kind')
    if (!ageType) console.log('no ageType')

    if (!durationMonths) return
    if (!amount) return
    if (!insuranceRate) return
    if (!advanceRate) return
    if (!commissionRate) return
    if (!interestRate) return
    if (!liquidityType) return
    if (!kind) return
    if (!ageType) return

    const contacts = guarantors.filter((g) => g.type === 'contact').map((g) => g._id.toString())
    const companyInns = guarantors.filter((g) => g.type === 'company').map((g) => g._id.toString())

    await addReconciliation({
      variables: {
        input: {
          kind: kind as Kind,
          vatRate: kind === Kind.Medicine ? 0 : REGULAR_VAT_RATE,
          durationMonths: parseInt(durationMonths),
          amount: Math.round(parseDecimalFromMasked(amount) * 100),
          application: applicationId,
          insuranceRate: parseDecimal(insuranceRate),
          advanceRate: parseDecimal(advanceRate),
          comissionRate: parseDecimal(commissionRate),
          interestRate: parseDecimal(interestRate),
          initiatedBy:
            initiatedByUs === true
              ? ReconciliationInitiatedBy.Fera
              : initiatedByUs === false
                ? ReconciliationInitiatedBy.Customer
                : undefined,
          isActual: actual,
          customerStatus:
            initiatedByUs === false && actual && acceptedByDefault ? ReconciliationCustomerStatus.Accepted : undefined,
          feraStatus:
            initiatedByUs === true && actual && acceptedByDefault ? ReconciliationFeraStatus.Accepted : undefined,
          ageType: ageTypeMap[ageType],
          liquidityType: liquidityTypeMap[liquidityType],
          previousReconciliation: previousReconciliationId ? previousReconciliationId.toString() : undefined,
          recommendedCommissionRate: recommendedCommissionRate ?? defaultValues.recommendedCommissionRate,
          recommendedInsuranceRate: recommendedInsuranceRate ?? defaultValues.recommendedInsuranceRate,
          recommendedInterestRate: recommendedInterestRate ?? defaultValues.recommendedInterestRate,

          // менять количество поручителей можно только с нашей стороны (скоринга)
          // Важно: нужно делать количество слотов для поручителей не меньше, чем количество поручителей
          // иначе менеджер сможет удалить поручителя и принять согласование
          requiredGuarantorsCount:
            initiatedByUs === true
              ? Math.max(requiredGuarantorsCount ?? 0, guarantors.length)
              : defaultValues.requiredGuarantorsCount,

          contacts,
          companyInns
        }
      }
    })
      .then((result) => {
        // guarantor company data is fetched from dadata on backend in async manner
        // and company name can be missing in the response
        // we need to refetch guarantors to get company names after 2 second delay
        setTimeout(() => {
          client.query<ReconciliationGuarantorsQuery, ReconciliationGuarantorsQueryVariables>({
            query: ReconciliationGuarantorsDocument,
            variables: {
              id: `${result.data?.addReconciliation?.reconciliation?._id}`
            }
          })
        }, 2000)
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  register('monthPayment', { required: true })
  register('guarantors', {
    validate: {
      enoughGuarantors: (value: RecGuarantor[] | undefined) => {
        // со стороны скоринга можно менять количество поручителей
        if (initiatedByUs) return true
        if (!requiredGuarantorsCount) return true
        if (!value?.length || value.length < requiredGuarantorsCount)
          return `Недостаточно поручителей, требуется ${requiredGuarantorsCount}`
        return true
      },
      invalidVerificationContactGuarantors: (value: RecGuarantor[] | undefined) =>
        !value?.find(
          (v) => v.type === 'contact' && (v.verificationStatusAccepted ? v.verificationStatusAcceptExpired : true)
        )
    }
  })
  useEffect(() => {
    // Force validation of guarantors fields on mount
    trigger('guarantors')
  }, [trigger])

  const guarantors = watch('guarantors') ?? []
  const monthlyPayment = watch('monthPayment') ?? ''

  const requiredGuarantorsCount = watch('requiredGuarantorsCount') ?? 0
  const extraGuarantors = Math.max(0, requiredGuarantorsCount - (guarantors.length || 0))

  const disabled = !isValid || operationsLoading

  // guards for manually set values that were overridden by calculations
  const interestRateManual =
    typeof defaultValues.recommendedInterestRate === 'number' &&
    typeof defaultValues.interestRate === 'number' &&
    defaultValues.interestRate !== defaultValues.recommendedInterestRate

  const insuranceRateManual =
    typeof defaultValues.recommendedInsuranceRate === 'number' &&
    typeof defaultValues.insuranceRate === 'number' &&
    defaultValues.insuranceRate !== defaultValues.recommendedInsuranceRate

  const comissionRateManual =
    typeof defaultValues.recommendedCommissionRate === 'number' &&
    typeof defaultValues.comissionRate === 'number' &&
    defaultValues.comissionRate !== defaultValues.recommendedCommissionRate

  return (
    <section className='w-[448px] p-12 lg:w-[912px]'>
      <h1 className='mb-6 font-display text-h200'>Новое согласование</h1>
      <div className='flex gap-4 pb-12 pt-4'>
        <button
          data-headlessui-state={tab === 'main' ? 'active' : 'inactive'}
          onClick={() => setTab('main')}
          className='relative whitespace-nowrap rounded-xl px-10 py-6 text-grayscale-150 ring-1 ring-grayscale-400 ui-active:bg-red-200 ui-active:font-medium ui-active:text-red-100 ui-active:ring-red-200'
        >
          Финансы
        </button>
        <button
          data-headlessui-state={tab === 'guarantors' ? 'active' : 'inactive'}
          onClick={() => setTab('guarantors')}
          className='relative whitespace-nowrap rounded-xl px-10 py-6 text-grayscale-150 ring-1 ring-grayscale-400 ui-active:bg-red-200 ui-active:font-medium ui-active:text-red-100 ui-active:ring-red-200'
        >
          Поручители
          <Bubble count={errors.guarantors ? 1 : 0} />
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={tab !== 'main' ? 'hidden' : 'visible'}>
          <div>
            <div className='mb-12 grid grid-cols-1 gap-20 lg:grid-cols-2'>
              <div className='relative flex flex-col gap-12'>
                <Controller
                  render={({ field, fieldState }) => {
                    return (
                      <MaskedNumberInput
                        label='Сумма сделки'
                        placeholder='1 500 000'
                        inputMode='decimal'
                        error={fieldState.error?.message}
                        {...field}
                      />
                    )
                  }}
                  name='amount'
                  control={control}
                  rules={{ required: true, min: 0 }}
                />
                <Input
                  label='Срок'
                  inputMode='numeric'
                  placeholder='от 6 месяцев'
                  {...register('durationMonths', {
                    required: true,
                    min: {
                      value: 6,
                      message: 'Минимум 6 месяцев'
                    }
                  })}
                  error={errors.durationMonths}
                />
              </div>

              <div className='relative flex flex-col gap-12'>
                <Controller
                  render={({ field, fieldState }) => (
                    <MaskedPercentInput
                      label='Аванс'
                      placeholder='до 49 %'
                      inputMode='decimal'
                      error={fieldState.error?.message}
                      {...field}
                    />
                  )}
                  name='advanceRate'
                  control={control}
                  rules={{
                    required: true,
                    validate: (value) => {
                      if (value && parseDecimal(value) > 49) return 'Максимум 49%'
                      return true
                    }
                  }}
                />
                <div>
                  <div className='inp-label text-p350 mb-5'>Вид (НДС)</div>
                  <Controller
                    name='kind'
                    control={control}
                    render={({ field }) => (
                      <RadioGroup
                        className='auto-cols grid grid-flow-col rounded-xl bg-grayscale-450 p-1'
                        name={field.name}
                        value={field.value || ''}
                        onChange={(value) => field.onChange(value)}
                      >
                        <RadioGroup.Option value={Kind.Regular}>
                          <div className='m-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center ui-checked:border-grayscale-350 ui-checked:bg-white-0'>
                            Обычная (20%)
                          </div>
                        </RadioGroup.Option>
                        <RadioGroup.Option value={Kind.Medicine}>
                          <div className='m-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center ui-checked:border-grayscale-350 ui-checked:bg-white-0'>
                            Медицина (0%)
                          </div>
                        </RadioGroup.Option>
                        <RadioGroup.Option value={Kind.Usn}>
                          <div className='m-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center ui-checked:border-grayscale-350 ui-checked:bg-white-0'>
                            УСН (0%)
                          </div>
                        </RadioGroup.Option>
                      </RadioGroup>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className='mb-12'>
              <div>
                <div className='inp-label text-p350 mb-5'>Ликвидность предмета лизинга</div>
                <Controller
                  control={control}
                  name='liquidityType'
                  rules={{ required: true }}
                  render={({ field }) => (
                    <RadioGroup
                      className='grid auto-cols-fr grid-flow-col rounded-xl bg-grayscale-450 p-1'
                      name={field.name}
                      value={field.value ?? ''}
                      onChange={(value) => field.onChange(value)}
                    >
                      <RadioGroup.Option value={RateCalculationLiquidityType.NotLiquid}>
                        <div
                          className={
                            'm-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center ui-checked:border-grayscale-350 ui-checked:bg-white-0'
                          }
                        >
                          Неликвидный
                        </div>
                      </RadioGroup.Option>
                      <RadioGroup.Option value={RateCalculationLiquidityType.LiquidHighRisk}>
                        <div
                          className={
                            'm-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center ui-checked:border-grayscale-350 ui-checked:bg-white-0'
                          }
                        >
                          Высокий риск
                        </div>
                      </RadioGroup.Option>
                      <RadioGroup.Option value={RateCalculationLiquidityType.LiquidLowRisk}>
                        <div
                          className={
                            'm-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center ui-checked:border-grayscale-350 ui-checked:bg-white-0'
                          }
                        >
                          Низкий риск
                        </div>
                      </RadioGroup.Option>
                    </RadioGroup>
                  )}
                />
              </div>
            </div>
            <div className='mb-12'>
              <div>
                <div className='inp-label text-p350 mb-5'>Возраст бизнеса</div>
                <Controller
                  control={control}
                  name='ageType'
                  rules={{ required: true }}
                  render={({ field }) => {
                    const showMoreAgeOptions =
                      field.value === RateCalculationAgeType.More_5Years ||
                      field.value === RateCalculationAgeType.More_5YearsWoLossesOrRevenueGrowth ||
                      field.value === RateCalculationAgeType.More_5YearsWoLossesAndRevenueGrowth
                    return (
                      <RadioGroup
                        className='relative overflow-x-hidden rounded-xl bg-grayscale-450 p-1'
                        name={field.name}
                        value={field.value || ''}
                        onChange={(value) => field.onChange(value)}
                      >
                        <div
                          className={c(
                            'relative grid auto-cols-fr grid-flow-col transition-transform duration-500',
                            showMoreAgeOptions && '-translate-x-full'
                          )}
                        >
                          <RadioGroup.Option value={RateCalculationAgeType.Less_1Year}>
                            <div
                              className={
                                'm-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center ui-checked:border-grayscale-350 ui-checked:bg-white-0'
                              }
                            >
                              Менее года
                            </div>
                          </RadioGroup.Option>
                          <RadioGroup.Option value={RateCalculationAgeType.From_1To_3Years}>
                            <div
                              className={
                                'm-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center ui-checked:border-grayscale-350 ui-checked:bg-white-0'
                              }
                            >
                              1 - 3 года
                            </div>
                          </RadioGroup.Option>
                          <RadioGroup.Option value={RateCalculationAgeType.From_3To_5Years}>
                            <div
                              className={
                                'm-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center ui-checked:border-grayscale-350 ui-checked:bg-white-0'
                              }
                            >
                              3 - 5 лет
                            </div>
                          </RadioGroup.Option>
                          <RadioGroup.Option value={RateCalculationAgeType.More_5Years}>
                            <div
                              className={
                                'm-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center ui-checked:border-grayscale-350 ui-checked:bg-white-0'
                              }
                            >
                              Более 5 лет
                            </div>
                          </RadioGroup.Option>
                        </div>
                        <div
                          className={c(
                            'absolute inset-1 flex items-stretch justify-items-stretch transition-transform duration-500',
                            !showMoreAgeOptions && 'translate-x-full'
                          )}
                        >
                          <RadioGroup.Option value={undefined}>
                            <div
                              className={
                                'm-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center ui-checked:border-grayscale-350 ui-checked:bg-white-0'
                              }
                            >
                              <MoreIcon className='rotate-180' />
                            </div>
                          </RadioGroup.Option>
                          <RadioGroup.Option value={RateCalculationAgeType.More_5Years}>
                            <div
                              className={
                                'm-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center ui-checked:border-grayscale-350 ui-checked:bg-white-0'
                              }
                            >
                              Более 5 лет
                            </div>
                          </RadioGroup.Option>
                          <RadioGroup.Option
                            className='flex-1'
                            value={RateCalculationAgeType.More_5YearsWoLossesOrRevenueGrowth}
                          >
                            <div
                              className={
                                'm-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center ui-checked:border-grayscale-350 ui-checked:bg-white-0'
                              }
                            >
                              и 3 года рост выручки <b>или</b> нет убытков
                            </div>
                          </RadioGroup.Option>
                          <RadioGroup.Option
                            className='flex-1'
                            value={RateCalculationAgeType.More_5YearsWoLossesAndRevenueGrowth}
                          >
                            <div
                              className={
                                'm-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center ui-checked:border-grayscale-350 ui-checked:bg-white-0'
                              }
                            >
                              и 3 года рост выручки <b>и</b> нет убытков
                            </div>
                          </RadioGroup.Option>
                        </div>
                      </RadioGroup>
                    )
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <div className='grid grid-cols-1 gap-20 lg:grid-cols-3'>
              <Controller
                render={({ field, fieldState }) => {
                  return (
                    <div>
                      <MaskedPercentInput
                        label='Ставка'
                        placeholder='20 %'
                        inputMode='decimal'
                        max={1000}
                        error={fieldState.error?.message}
                        {...field}
                      />
                      {/* Show warning when rate was changed manually in previous rec
                        and was recalculated automatically in current rec */}
                      {interestRateManual && defaultValues.interestRate !== parseDecimal(field.value ?? '') && (
                        <div className='mt-2 text-body-s text-labels-secondary'>
                          Предыдущая: {formatPercent(defaultValues.interestRate)},{' '}
                          <span
                            className='cursor-pointer underline'
                            onClick={() => {
                              field.onChange({ target: { value: formatPercent(defaultValues?.interestRate) } })
                            }}
                          >
                            вернуть
                          </span>
                        </div>
                      )}
                    </div>
                  )
                }}
                name='interestRate'
                control={control}
                rules={{ required: true, min: 0 }}
              />
              <Controller
                render={({ field, fieldState }) => (
                  <div>
                    <MaskedPercentInput
                      label='Страховка'
                      placeholder='1 %'
                      inputMode='decimal'
                      error={fieldState.error?.message}
                      {...field}
                    />
                    {insuranceRateManual && defaultValues.insuranceRate !== parseDecimal(field.value ?? '') && (
                      <div className='mt-2 text-body-s text-labels-secondary'>
                        Предыдущая: {formatPercent(defaultValues.insuranceRate)},{' '}
                        <span
                          className='cursor-pointer underline'
                          onClick={() => {
                            console.log(
                              `reverting insurance rate from ${field.value} to ${formatPercent(defaultValues?.insuranceRate)}`
                            )
                            field.onChange({ target: { value: formatPercent(defaultValues?.insuranceRate) } })
                          }}
                        >
                          вернуть
                        </span>
                      </div>
                    )}
                  </div>
                )}
                name='insuranceRate'
                control={control}
                rules={{ required: true, min: 0 }}
              />
              <Controller
                render={({ field, fieldState }) => (
                  <div>
                    <MaskedPercentInput
                      label='Комиссия'
                      placeholder='3 %'
                      inputMode='decimal'
                      error={fieldState.error?.message}
                      {...field}
                    />
                    {comissionRateManual && defaultValues.comissionRate !== parseDecimal(field.value ?? '') && (
                      <div className='mt-2 text-body-s text-labels-secondary'>
                        Предыдущая: {formatPercent(defaultValues.comissionRate)},{' '}
                        <span
                          className='cursor-pointer underline'
                          onClick={() => {
                            field.onChange({ target: { value: formatPercent(defaultValues?.comissionRate) } })
                          }}
                        >
                          вернуть
                        </span>
                      </div>
                    )}
                  </div>
                )}
                name='commissionRate'
                control={control}
                rules={{ required: true, min: 0 }}
              />
            </div>
          </div>
        </div>
        <div className={tab !== 'guarantors' ? 'hidden' : 'visible'}>
          <div className='grid grid-cols-1 gap-16 lg:grid-cols-2'>
            <section>
              <div className='inp-label text-p350 mb-5'>Поручители</div>
              <div className='mb-5 grid gap-y-5'>
                {guarantors.map((guarantor, i) => {
                  const invalidVerification =
                    guarantor.type === 'contact' &&
                    (!guarantor.verificationStatusAccepted || guarantor.verificationStatusAcceptExpired)

                  return (
                    <div key={guarantor.type + guarantor._id}>
                      <div
                        className={c(
                          'flex flex-grow items-center justify-between rounded-xl border-1 border-grayscale-300 px-8 py-8 text-grayscale-150',
                          invalidVerification && 'border-red-100'
                        )}
                      >
                        {i + 1}. {guarantor.name}
                        <TrashIcon
                          className='flex-none cursor-pointer text-grayscale-200 hover:text-red-100'
                          title='Удалить'
                          onClick={() => {
                            setValue(
                              'guarantors',
                              guarantors.filter((g) => g._id !== guarantor._id),
                              { shouldValidate: true }
                            )
                          }}
                        />
                      </div>
                      {invalidVerification && (
                        <div className='pt-2 text-p200 text-red-150'>
                          статус верификации: {!guarantor.verificationStatusAccepted ? 'Отказано' : 'Просрочено'}
                        </div>
                      )}
                    </div>
                  )
                })}
                {Array.from({ length: extraGuarantors }).map((_, i) => (
                  <div
                    key={i}
                    className='flex flex-grow justify-between rounded-xl border-1 border-dashed border-grayscale-300 px-8 py-8 text-grayscale-250'
                  >
                    {(guarantors.length || 0) + i + 1}. Дополнительный поручитель
                    {initiatedByUs && (
                      <TrashIcon
                        className='cursor-pointer text-grayscale-200 hover:text-red-100'
                        title='Удалить'
                        onClick={() => {
                          setValue('requiredGuarantorsCount', Math.max(0, requiredGuarantorsCount - 1))
                        }}
                      />
                    )}
                  </div>
                ))}
                {!guarantors.length && !requiredGuarantorsCount && (
                  <div className='flex h-25 w-full items-center justify-center gap-5 rounded-xl border-1 border-dashed border-grayscale-300 text-grayscale-250'>
                    Нет поручителей
                  </div>
                )}
                {errors.guarantors && (
                  <div className='text-p450 pl-4 text-red-150'>{errorToString(errors.guarantors.message)}</div>
                )}
              </div>
              {initiatedByUs && (
                <button
                  type='button'
                  className='h-25 w-full cursor-pointer rounded-xl bg-grayscale-450 px-8 text-grayscale-150 hover:bg-grayscale-400'
                  onClick={() => {
                    if (requiredGuarantorsCount <= guarantors.length) {
                      setValue('requiredGuarantorsCount', guarantors.length + 1)
                    } else {
                      setValue('requiredGuarantorsCount', requiredGuarantorsCount + 1)
                    }
                  }}
                >
                  Запросить дополнительного поручителя
                </button>
              )}
            </section>
            <section>
              <div className='mb-12'>
                <Suggestions<CompanyData>
                  suggestions={suggestions?.map((s) => ({
                    key: s._id,
                    title: getCompanyName(s),
                    subtitle: `${s.inn} ${s.address?.value || ''}`,
                    payload: s,
                    lineThrough: !!s.state.liquidation_date
                  }))}
                  initialState={true}
                  select={(suggestion) => {
                    setValue(
                      'guarantors',
                      [
                        ...guarantors,
                        {
                          _id: suggestion.payload?.inn || '',
                          type: 'company',
                          name: suggestion.payload?.name.short_with_opf || ''
                        }
                      ],
                      { shouldValidate: true }
                    )
                    setValue('company', '')
                  }}
                  highlight={companyQuery}
                >
                  <Input
                    label='Добавить юр. лицо поручителем:'
                    type='text'
                    placeholder='Начните вводить название или ИНН'
                    autoComplete='off'
                    {...register('company')}
                  />
                </Suggestions>
              </div>
              <div>
                <div className='inp-label text-p350 mb-5'>Добавить физ. лицо поручителем:</div>
                {!sortedContacts.length && (
                  <div className='text-center text-grayscale-150'>В этой сделке пока нет верифицированных физлиц</div>
                )}

                <div className='mt-5 flex flex-col'>
                  {sortedContacts.map((contact) => (
                    <div
                      key={contact._id}
                      onClick={() => {
                        if (
                          contact.verificationStatus !== 'accepted' ||
                          new Date() > new Date(contact.verificationAcceptExpiresAt || '')
                        ) {
                          return
                        }
                        if (guarantors.some((g) => g._id === contact._id)) {
                          setValue(
                            'guarantors',
                            guarantors.filter((g) => g._id !== contact._id)
                          )
                          return
                        }

                        setValue(
                          'guarantors',
                          [
                            ...guarantors,
                            {
                              _id: contact._id,
                              type: 'contact',
                              name: contact.fio,
                              verificationStatusAccepted: contact.verificationStatus === 'accepted',
                              verificationStatusAcceptExpired:
                                contact.verificationStatus === 'accepted' && contact.verificationAcceptExpiresAt
                                  ? new Date() > new Date(contact.verificationAcceptExpiresAt)
                                  : false
                            }
                          ],
                          { shouldValidate: true }
                        )
                      }}
                      className={c(
                        'mb-5 cursor-pointer rounded-xl px-9 py-5 ring-1 ring-grayscale-350 hover:bg-grayscale-450 disabled:cursor-not-allowed',
                        guarantors.some((g) => g._id === contact._id) && 'bg-grayscale-450',
                        (contact.verificationStatus !== 'accepted' ||
                          new Date() > new Date(contact.verificationAcceptExpiresAt ?? '')) &&
                          'ring-grayscale-400'
                      )}
                    >
                      <div
                        className={c(
                          'mb-1 flex items-center gap-6 text-grayscale-0',
                          (contact.verificationStatus !== 'accepted' ||
                            new Date() > new Date(contact.verificationAcceptExpiresAt ?? '')) &&
                            'text-grayscale-250'
                        )}
                      >
                        <div>{contact.fio}</div>
                        <div className='h-2 w-2 rounded-full bg-grayscale-250' />

                        <div>
                          {/* В случае, если контакт проверен, необходимо сделать проверку на дату верификации */}
                          {contact.verificationStatus === 'accepted' &&
                          contact.verificationAcceptExpiresAt &&
                          new Date() > new Date(contact.verificationAcceptExpiresAt) ? (
                            <div className='flex items-center justify-start'>Верификация просрочена</div>
                          ) : (
                            <div className='flex items-center justify-start'>
                              {verificationStateDict[contact.verificationStatus.toUpperCase() as VerificationState]}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className={c('line-clamp-2 overflow-hidden text-ellipsis')}>
                        <div className='flex items-center gap-5 text-p200 tabular-nums text-grayscale-150'>
                          <div>{contact.phone && formatPhoneNumber(contact.phone)}</div>
                          {contact.phone && <div className='h-2 w-2 rounded-full bg-grayscale-250' />}
                          <div>{contact.email}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className='grid grid-cols-1 gap-20 pt-18 lg:grid-cols-2'>
          <SubmitButton loading={isSubmitting} disabled={disabled}>
            <PlusIcon className='mr-5' />
            Создать согласование
          </SubmitButton>
          <div>
            <h3 className='mb-2 font-display text-p200'>Лизинговый платёж</h3>
            {isValid ? (
              operationsLoading ? (
                <LoadingIcon className='animate-spin text-grayscale-150' />
              ) : (
                <div className='text-h100 font-medium'>{formatDecimal(parseFloat(monthlyPayment) * 100)} ₽ в месяц</div>
              )
            ) : (
              <div className='text-h100 font-medium'>Недостаточно данных</div>
            )}
          </div>
        </div>
      </form>
    </section>
  )
}

export default ReconciliationForm
