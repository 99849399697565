import { FC } from 'react'
import { ReactComponent as CheckmarkIcon } from '../../svg/ui/success.svg'
import { ReactComponent as CrossIcon } from '../../svg/ui/error.svg'
import StatusRing from '../../components/StatusRing'

interface AccreditationStatusIconProps {
  maxSegments: number
  activeSegments: number
  isDone?: boolean
}

const AccreditationStatusIcon: FC<AccreditationStatusIconProps & { bg?: boolean }> = ({
  maxSegments,
  activeSegments,
  isDone,
  bg
}) => {
  if (isDone !== undefined) {
    if (isDone === true) {
      return <CheckmarkIcon width='16' height='16' className={bg ? 'text-dark-green' : 'text-base-green'} />
    } else {
      return <CrossIcon width='16' height='16' className={bg ? 'text-dark-red' : 'text-base-red'} />
    }
  }

  return (
    <div>
      <StatusRing maxSegments={maxSegments} activeSegments={activeSegments} />
    </div>
  )
}
export default AccreditationStatusIcon
