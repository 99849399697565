import { FC, useState } from 'react'
import ReconciliationCard from './ReconciliationCard'
import {
  ReconciliationCustomerStatus,
  ReconciliationFeraStatus,
  useUpdateReconciliationMutation
} from '../../../graphql/schema'
import ConfirmationForm from '../../../components/ConfirmationForm'
import type { CardReconciliation } from './ReconciliationCard'
import Modal from '../../../components/Modal'
import ReconciliationForm from './ReconciliationForm'
import useAccessCheck from '../../../hooks/useAccessCheck'
import { shortDateTimeFormatter } from '../../../utils/dateFormatter.ts'
import getFullName from '../../../utils/getFullName.ts'
import useCurrentUser from '../../../hooks/useCurrentUser.ts'

interface NegotiationStepProps {
  appId: number
  rec: CardReconciliation
  commissionRewardRate?: number
  diffRec?: CardReconciliation
  onRecAdd: () => void
}

const RecAuthor: FC<{
  createdBy: {
    name: string
    surname: string
  }
  created: string
}> = ({ createdBy, created }) => {
  return (
    <div className='my-2 text-right text-sm text-grayscale-150'>
      создано: {getFullName(createdBy)}, {created && shortDateTimeFormatter.format(new Date(created))}
    </div>
  )
}

const NegotiationStep: FC<NegotiationStepProps> = ({ appId, rec, commissionRewardRate, diffRec, onRecAdd }) => {
  const [formOpenBy, setFormOpenBy] = useState<'fera' | 'client' | undefined>()
  const [rejectOpenBy, setRejectOpenBy] = useState<'fera' | 'client' | undefined>()
  const [confirmOpenBy, setConfirmOpenBy] = useState<'fera' | 'client' | undefined>()
  const [updateReconciliation] = useUpdateReconciliationMutation()
  const hasAccessCustomerDecision = useAccessCheck('application.reconciliation.customer.decision')
  const hasAccessFeraDecision = useAccessCheck('application.reconciliation.fera.decision')

  const currentUser = useCurrentUser()

  const updateStatus = async (ourSide: boolean, accepted: boolean) => {
    if (ourSide) {
      await updateReconciliation({
        variables: {
          input: {
            id: rec?._id?.toString() as string,
            feraStatus: accepted ? ReconciliationFeraStatus.Accepted : ReconciliationFeraStatus.Rejected
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateReconciliation: {
            __typename: 'updateReconciliationPayload',
            reconciliation: {
              __typename: 'Reconciliation',
              _id: rec?._id,
              feraStatus: accepted ? ReconciliationFeraStatus.Accepted : ReconciliationFeraStatus.Rejected,
              feraStatusChangedAt: new Date().toISOString(),
              // set expires in one month
              feraAcceptExpiresAt: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(),
              feraStatusChangedBy: {
                __typename: 'User',
                _id: currentUser?._id || -1,
                name: currentUser?.name || '',
                surname: currentUser?.surname || ''
              },

              customerStatus: rec?.customerStatus,
              customerStatusChangedAt: rec?.customerStatusChangedAt,
              customerStatusChangedBy: {
                __typename: 'User',
                _id: rec?.customerStatusChangedBy?._id || -1,
                name: rec?.customerStatusChangedBy?.name || '',
                surname: rec?.customerStatusChangedBy?.surname || ''
              }
            }
          }
        }
      })
    } else {
      await updateReconciliation({
        variables: {
          input: {
            id: rec?._id?.toString() as string,
            customerStatus: accepted ? ReconciliationCustomerStatus.Accepted : ReconciliationCustomerStatus.Rejected
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateReconciliation: {
            __typename: 'updateReconciliationPayload',
            reconciliation: {
              __typename: 'Reconciliation',
              _id: rec?._id,
              feraStatus: rec.feraStatus,
              feraStatusChangedAt: rec.feraStatusChangedAt,
              feraAcceptExpiresAt:
                rec.feraAcceptExpiresAt || new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(),
              feraStatusChangedBy: {
                __typename: 'User',
                _id: rec?.customerStatusChangedBy?._id || -1,
                name: rec?.customerStatusChangedBy?.name || '',
                surname: rec?.customerStatusChangedBy?.surname || ''
              },

              customerStatus: accepted ? ReconciliationCustomerStatus.Accepted : ReconciliationCustomerStatus.Rejected,
              customerStatusChangedAt: new Date().toISOString(),
              customerStatusChangedBy: {
                __typename: 'User',
                _id: currentUser?._id || -1,
                name: currentUser?.name || '',
                surname: currentUser?.surname || ''
              }
            }
          }
        }
      })
    }
  }

  return (
    <div className='mt-4 grid cursor-default grid-cols-2 gap-x-4'>
      <div>
        <div className='rounded-md bg-surface-primary shadow-card'>
          <ReconciliationCard
            ourSide={true}
            canDecide={hasAccessFeraDecision}
            reconciliation={rec}
            diffRec={diffRec}
            onAccept={() => {
              if (rec.customerStatus === ReconciliationCustomerStatus.Accepted) {
                setConfirmOpenBy('fera')
              } else {
                updateStatus(true, true)
              }
            }}
            onOffer={() => {
              setFormOpenBy('fera')
            }}
            onReject={() => setRejectOpenBy('fera')}
          />
        </div>
        {!rec?.feraStatusAutoAccepted && rec?.initiatedBy === 'fera' && (
          <RecAuthor createdBy={rec.createdBy} created={rec.created} />
        )}
      </div>
      <div>
        <div className='rounded-md bg-surface-primary shadow-card'>
          <ReconciliationCard
            ourSide={false}
            canDecide={
              hasAccessCustomerDecision &&
              !(
                rec.feraStatus === ReconciliationFeraStatus.Pending &&
                rec.customerStatus === ReconciliationCustomerStatus.Pending
              )
            }
            reconciliation={rec}
            diffRec={diffRec}
            onAccept={() => {
              if (rec.feraStatus === ReconciliationFeraStatus.Accepted) {
                setConfirmOpenBy('client')
              } else {
                updateStatus(false, true)
              }
            }}
            onOffer={() => {
              setFormOpenBy('client')
            }}
            onReject={() => setRejectOpenBy('client')}
          />
        </div>
        {rec?.initiatedBy === 'customer' && <RecAuthor createdBy={rec.createdBy} created={rec.created} />}
      </div>
      <Modal open={!!formOpenBy} setOpen={() => setFormOpenBy(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ReconciliationForm
            actual
            initiatedByUs={formOpenBy === 'fera' ? true : formOpenBy === 'client' ? false : undefined}
            defaultValues={rec}
            previousReconciliationId={rec._id}
            applicationId={appId.toString()}
            acceptedByDefault={false}
            commissionRewardRate={commissionRewardRate}
            onDone={async () => {
              if (formOpenBy === 'fera') {
                await updateStatus(true, false)
              } else if (formOpenBy === 'client') {
                await updateStatus(false, false)
              }
              onRecAdd()
              setFormOpenBy(undefined)
            }}
          />
        </div>
      </Modal>
      <Modal open={!!confirmOpenBy} setOpen={() => setConfirmOpenBy(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ConfirmationForm
            title='Утвердить согласование'
            onDone={() => {
              setConfirmOpenBy(undefined)
              if (confirmOpenBy === 'fera') {
                updateStatus(true, true)
              } else if (confirmOpenBy === 'client') {
                updateStatus(false, true)
              }
            }}
            onDismiss={() => setConfirmOpenBy(undefined)}
          >
            Вы уверены, что хотите принять согласование?
          </ConfirmationForm>
        </div>
      </Modal>
      <Modal open={!!rejectOpenBy} setOpen={() => setRejectOpenBy(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ConfirmationForm
            title='Отказ от согласования'
            onDone={() => {
              setRejectOpenBy(undefined)
              if (rejectOpenBy === 'fera') {
                updateStatus(true, false)
              } else if (rejectOpenBy === 'client') {
                updateStatus(false, false)
              }
            }}
            onDismiss={() => setRejectOpenBy(undefined)}
          >
            Вы действительно хотите отказаться и прекратить процесс согласования с клиентом?
          </ConfirmationForm>
        </div>
      </Modal>
    </div>
  )
}

export default NegotiationStep
