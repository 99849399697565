import { FC, useState, useMemo, ReactNode } from 'react'
import { ReactComponent as TickIcon } from '../../svg/icons/tick-xs.svg'
import { ReactComponent as MoreIcon } from '../../svg/icons/more.svg'
import type { Group, Option } from './'
import c from 'clsx'

interface GroupViewProps {
  options: Option[]
  selectedOptions: Option[]
  toggleOption: (option: Option) => void
  onChange: (options: Option[]) => void
  renderGroup?: (name: string) => ReactNode
  childrenGroupSelect?: (name?: string) => void
}

const useGroups = (options: Option[], selectedOptions: Option[]) => {
  return useMemo(
    () =>
      options.reduce<Group[]>((acc, option) => {
        let group = acc.find((group) => group.name === option.group)

        if (!group) {
          group = {
            name: option.group,
            options: [],
            selectedOptions: [],
            children: []
          }
          acc.push(group)
        }

        group.options.push(option)
        if (selectedOptions.includes(option)) {
          group.selectedOptions.push(option)
        }
        if (option.children && selectedOptions.some((o) => option.children?.includes(o))) {
          group.selectedOptions = selectedOptions
        }
        if (option.children) {
          group.children.push(...option.children)
        }

        return acc
      }, []),
    [options, selectedOptions]
  )
}

const GroupOption: FC<{ name: string; selected: boolean; onToggle: () => void }> = ({ name, selected, onToggle }) => {
  return (
    <li className='hover:bg-grayscale-200/5 px-12 py-5 cursor-pointer' onClick={() => onToggle()}>
      <div className='text-grayscale-0 mb-1 flex items-center'>
        <div className='mr-auto pr-5 font-medium text-p100 whitespace-nowrap overflow-ellipsis overflow-hidden flex-shrink min-w-0'>
          {name}
        </div>
        <div>
          {selected && (
            <div className='rounded-full bg-red-100/5 text-red-100 px-5 py-2 flex items-center'>
              <TickIcon />
            </div>
          )}
        </div>
      </div>
    </li>
  )
}

const GroupView: FC<GroupViewProps> = ({
  options,
  selectedOptions,
  toggleOption,
  onChange,
  renderGroup,
  childrenGroupSelect
}) => {
  const [selectedGroupName, setSelectedGroup] = useState<string | undefined>()
  const [childrenSelectedGroupName, setChildrenSelectedGroupName] = useState<string | undefined>()
  const groupOpen = !!selectedGroupName
  const groups = useGroups(options, selectedOptions)
  const selectedGroup = groups.find((g) => g.name === selectedGroupName)
  const allOptionsSelected = selectedGroup?.selectedOptions.length === selectedGroup?.options.length

  return (
    <div className='relative h-170 overflow-hidden'>
      <ul
        className={c(
          'py-4 absolute inset-0 overflow-y-auto scrollbar-hide transition-transform',
          groupOpen && '-translate-x-full'
        )}
      >
        {groups.map((group) => {
          const hasOptions = group.options.length > 1
          const label = hasOptions ? group.name || 'Прочее' : group.options[0].name
          const optionsCount = group.options.reduce(
            (acc, option) => (option.children?.length ? acc + option.children.length : acc + 1),
            0
          )

          return (
            <li
              tabIndex={0}
              className='flex w-full px-10 cursor-pointer hover:bg-grayscale-200/5 h-21 items-center'
              onClick={() => {
                if (hasOptions) {
                  setSelectedGroup(group.name)
                  childrenGroupSelect && childrenGroupSelect(group.name)
                } else {
                  toggleOption(group.options[0])
                }
              }}
              key={group.name || 'Прочее'}
            >
              <div className='mr-auto pr-5 font-medium text-p100 whitespace-nowrap overflow-ellipsis overflow-hidden flex-shrink min-w-0'>
                {renderGroup ? renderGroup(label) : label}
              </div>
              {group.selectedOptions.length ? (
                <div className='rounded-full bg-red-100/5 text-red-100 px-5 py-2 flex items-center'>
                  <TickIcon />
                  {hasOptions && <div className='ml-2'>{group.selectedOptions.length}</div>}
                </div>
              ) : (
                hasOptions && (
                  <div className='flex items-center'>
                    <div className='text-grayscale-200'>{optionsCount}</div>
                    <MoreIcon className='text-grayscale-250' />
                  </div>
                )
              )}
            </li>
          )
        })}
      </ul>

      <div className={c('absolute inset-0 transition-transform flex flex-col', !groupOpen && 'translate-x-full')}>
        <div
          tabIndex={groupOpen ? 0 : -1}
          className={c(
            'flex text-grayscale-200 items-center py-8 px-12 cursor-pointer border-b-1 border-grayscale-350 transition-transform',
            childrenSelectedGroupName && 'absolute inset-0 -translate-x-full'
          )}
          onClick={() => {
            setSelectedGroup(undefined)
            if (childrenGroupSelect) {
              childrenGroupSelect(undefined)
            }
          }}
        >
          <MoreIcon className='rotate-180 mr-6' />
          <div className='text-grayscale-150'>{selectedGroup?.name}</div>
        </div>
        {selectedGroup?.children.length ? (
          <GroupView
            options={selectedGroup.children}
            selectedOptions={selectedOptions}
            toggleOption={toggleOption}
            onChange={onChange}
            renderGroup={(name) => (
              <div className='flex gap-6 overflow-hidden items-center'>
                <span className='whitespace-nowrap overflow-hidden overflow-ellipsis'>{name}</span>
              </div>
            )}
            childrenGroupSelect={(name) => setChildrenSelectedGroupName(name)}
          />
        ) : (
          <ul className='overflow-y-auto scrollbar-hide w-full py-4'>
            <GroupOption
              name='Все'
              selected={allOptionsSelected}
              onToggle={() => {
                if (!selectedGroup) return
                const otherGroupsOptions = selectedOptions.filter((o) => !selectedGroup?.options.includes(o))
                if (allOptionsSelected) {
                  onChange(otherGroupsOptions)
                } else {
                  onChange([...otherGroupsOptions, ...selectedGroup.options])
                }
              }}
            />
            {selectedGroup?.options.map((opt) => (
              <GroupOption
                key={opt.value}
                name={opt.name}
                selected={selectedGroup.selectedOptions.find((o) => opt.value === o.value) !== undefined}
                onToggle={() => toggleOption(opt)}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default GroupView
