import { FieldPath, FieldValues } from 'react-hook-form'
import { ApolloError } from '@apollo/client'
import { GraphQLError } from 'graphql'
function handleBackendErrorsToForm<TValues extends FieldValues>(err: ApolloError, setErrorFunc: (fieldPath: FieldPath<TValues>, errorText: string) => void) {
  const errors = err.graphQLErrors
  if (!Array.isArray(errors)) return

  errors.forEach((error: GraphQLError) => {
    const errorArr = error.message.split(':')
    const keys = errorArr.shift()
    const keysTextMatches = keys?.match(/([a-zA-Z\d]+)/g)
    if (!keysTextMatches) {
      return
    }
    const path: FieldPath<TValues> = keysTextMatches.join('.') as FieldPath<TValues>
    const errorText = errorArr.join(':')
    if (keys && errorText) {
      setErrorFunc(path, errorText)
    }
  })
}

export { handleBackendErrorsToForm }
