import { FC, ReactElement } from 'react'
import { NavLink } from 'react-router-dom'
import c from 'clsx'

interface SidebarButtonProps {
  rollup: boolean
  title: string
  counterValue?: number
  to?: string
  onClick?: () => void
  icon: ReactElement
}

const SidebarButton: FC<SidebarButtonProps> = ({ rollup, title, counterValue = 0, to, icon, onClick }) => {
  const internals = (
    <>
      <div className='text-labels-secondary group-aria-current:text-labels-primary'>{icon}</div>
      {/** key is needed to prevent hide transition flash */}
      <div
        key={rollup.toString()}
        className={c(
          'flex items-center gap-x-6 grow text-labels-primary',
          !rollup && 'transition-none',
          rollup &&
            'absolute left-full top-0 bottom-0 bg-labels-primary/75 rounded-md whitespace-nowrap pointer-events-none pr-6 opacity-0 duration-150 transition-all translate-x-16 group-hover:opacity-100 group-hover:translate-x-9'
        )}
      >
        <span
          className={c(
            'ml-6 mr-auto font-medium',
            rollup ? 'text-base-white' : 'text-labels-secondary group-aria-current:text-labels-primary'
          )}
        >
          {title}
        </span>
        {counterValue > 0 && (
          <span className={c(rollup ? 'text-base-white' : 'text-labels-tertiary group-aria-current:text-labels-primary')}>
            {counterValue}
          </span>
        )}
      </div>
    </>
  )

  const className = c(
    'group flex h-18 items-center rounded-md hover:bg-surface-tertiary mb-1 aria-current:bg-surface-tertiary relative',
    rollup ? 'w-21 justify-center' : 'px-6 w-full'
  )

  if (to) {
    return (
      <NavLink to={to} className={className}>
        {internals}
      </NavLink>
    )
  }
  return (
    <button className={className} onClick={onClick}>
      {internals}
    </button>
  )
}

export default SidebarButton
