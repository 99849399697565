import { env } from '../env'
import { Suggestion, NameData } from '../types/dadata'

const { REACT_APP_DADATA_TOKEN } = env
const DADATA_BASE_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/'

type JSONPrimitive = string | number | boolean | null
type JSONArray = JSONValue[]
type JSONObject = { [key: string]: JSONValue }
type JSONValue = JSONPrimitive | JSONArray | JSONObject

const getSuggestions = async <T>(method: string, query: JSONValue) => {
  const { suggestions } = await fetch(DADATA_BASE_URL + method, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Token ${REACT_APP_DADATA_TOKEN}`
    },
    body: JSON.stringify(query)
  }).then((res) => res.json())

  return suggestions as Suggestion<T>[]
}

export const getCleanName = async (query = '') => {
  return getSuggestions<NameData>('suggest/fio', { query })
}
