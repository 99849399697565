import { FC, useEffect, useMemo, useState } from 'react'
import { ReactComponent as PayIcon } from '../../../svg/icons/payout.svg'
import { ReactComponent as WarningIcon } from '../../../svg/icons/warning.svg'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import Textarea from '../../../components/Textarea'
import {
  BankOrderState,
  BankOrderTargetType,
  FeraBankAccount,
  useAgentCommissionRewardSuspenseQuery,
  useCreateBankOrderMutation,
  useFeraBankAccountsSuspenseQuery,
  VatKind
} from '../../../graphql/schema'
import c from 'clsx'
import SubmitButton from '../../../components/SubmitButton'
import { RadioGroup } from '@headlessui/react'
import MaskedNumberInput from '../../../components/Forms/Inputs/MaskedNumberInput'
import parseDecimal from '../../../utils/parseDecimal'
import Suggestions from '../../../components/Suggestions'
import useNodes from '../../../hooks/useNodes'
import { Requisite } from '../../../components/Requisites'
import { formatDecimal } from '../../../utils/formatNumber.ts'
import { ApolloError } from '@apollo/client'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils.ts'

interface RewardPaymentFormProps {
  rewardId?: number
  onDone?: () => void
}

interface RewardPaymentFormValues {
  purpose: string
  amount: string
  receiptAccount: string
}

const getFormattedDate = (value?: string) => {
  if (!value) return ''

  return new Intl.DateTimeFormat('ru-RU', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  }).format(new Date(value))
}

const RewardPaymentForm: FC<RewardPaymentFormProps> = ({ onDone, rewardId }) => {
  const { data: rewardData } = useAgentCommissionRewardSuspenseQuery({
    variables: { id: `${rewardId}` }
  })
  const reward = rewardData?.agentCommissionReward
  const source = reward?.source

  const [createBankOrder, { loading }] = useCreateBankOrderMutation()

  const vat =
    source?.company?.vatKind === VatKind.Osno
      ? `В т.ч. НДС 20% — ${formatDecimal((reward?.amount / 120) * 20)} руб`
      : 'НДС не облагается'
  const { register, handleSubmit, setValue, setError, watch, control } = useForm<RewardPaymentFormValues>({
    defaultValues: {
      purpose: `АВ АКТ-ОТЧЕТ №_____ от __ _____ 202_ г. Во исполнение агентского договора №${source?.agentContractNumber} от ${getFormattedDate(source?.agentContractDate)} ${vat}`,
      amount: reward?.amount && formatDecimal(reward.amount)
    }
  })
  const [showRequisites, setShowRequisites] = useState(false)
  const [feraBankAccountTarget, setFeraBankAccountTarget] = useState<FeraBankAccount | null>()
  const [receiptAccountTarget, setReceiptAccountTarget] = useState<Requisite | null>()
  const [receiptAccountQuery, setReceiptAccountQuery] = useState('')

  const { data: feraBankAccountsData } = useFeraBankAccountsSuspenseQuery()
  const feraBankAccounts = useNodes(feraBankAccountsData?.feraBankAccounts?.edges)
  useEffect(() => {
    const target = feraBankAccounts.find((a) => a.bankKey === 'tinkoff')
    if (target) {
      setFeraBankAccountTarget(target)
    }
  }, [feraBankAccounts.length])

  const receiptAccounts = useNodes(source?.company?.bankDetails?.edges)
  const receiptAccountsFiltered = useMemo(() => {
    if (receiptAccountQuery) {
      return receiptAccounts.filter(
        (receiptAccount) =>
          receiptAccount?.name.toLowerCase().includes(receiptAccountQuery.toLowerCase()) ||
          receiptAccount?.bic.toLowerCase().includes(receiptAccountQuery.toLowerCase()) ||
          receiptAccount?.account.toLowerCase().includes(receiptAccountQuery.toLowerCase())
      )
    }
    return receiptAccounts
  }, [receiptAccountQuery, receiptAccounts])

  const purpose = watch('purpose')

  const onSubmit: SubmitHandler<RewardPaymentFormValues> = async (data) => {
    if (!receiptAccountTarget) throw new Error('Отсутствует счёт получателя')
    if (!feraBankAccountTarget) throw new Error('Отсутствует счёт оплаты поставки')

    const amount = parseDecimal(data.amount.replace(/\s/g, ''))
    await createBankOrder({
      variables: {
        input: {
          amount,
          targetId: `${rewardId}`,
          targetType: BankOrderTargetType.AgentCommissionReward,
          feraBankAccountId: feraBankAccountTarget.id,
          paymentPurpose: data.purpose,
          recipientAccountNumber: receiptAccountTarget.account,
          recipientBankBik: receiptAccountTarget.bic,
          recipientBankName: receiptAccountTarget.name,
          recipientBankCorrNumber: receiptAccountTarget.correspondentAccount,
          recipientInn: `${source?.company?.inn}`,
          recipientName: `${source?.company?.shortWithOpf}`,
          recipientKpp: source?.company?.kpp || undefined
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        createBankOrder: {
          __typename: 'CreateBankOrderPayload',
          bankOrder: {
            __typename: 'BankOrder',
            id: Math.round(Math.random() * -10000).toString(),
            targetId: `${rewardId}`,
            targetType: BankOrderTargetType.AgentCommissionReward,
            createdAt: new Date().toISOString(),
            amount,
            state: BankOrderState.Pending,
            feraBankAccount: feraBankAccountTarget,
            recipientInn: `${source?.company?.inn}`,
            recipientKpp: source?.company?.kpp,
            recipientBankName: receiptAccountTarget.name,
            recipientAccountNumber: receiptAccountTarget.account,
            recipientBankBik: receiptAccountTarget.bic,
            recipientBankCorrNumber: receiptAccountTarget.correspondentAccount
          },
          errors: []
        }
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<RewardPaymentFormValues>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  if (!source) return null

  return (
    <section className='md: w-[448px] p-12 pb-6'>
      <h1 className='mb-6 font-display text-h200'>Оплата агенсткого вознаграждения</h1>
      <div className='mb-12'>{source?.company?.shortWithOpf}</div>
      <RadioGroup
        className='mb-12 grid auto-cols-fr grid-flow-col rounded-xl bg-grayscale-450 p-1'
        value={'foo'}
        onChange={(value: string) => {
          const showRequisites = value === 'b'
          setShowRequisites(showRequisites)
        }}
      >
        <RadioGroup.Option value='a'>
          <div
            className={c(
              'm-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center',
              !showRequisites && 'border-grayscale-350 bg-white-0'
            )}
          >
            Детали
          </div>
        </RadioGroup.Option>
        <RadioGroup.Option value='b'>
          <div
            className={c(
              'm-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center',
              showRequisites && 'border-grayscale-350 bg-white-0'
            )}
          >
            Реквизиты
          </div>
        </RadioGroup.Option>
      </RadioGroup>
      {showRequisites && (
        <>
          <table className='w-full'>
            <tbody>
              <tr className='group'>
                <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>ИНН/КПП</td>
                <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                  {source?.company?.inn}/{source?.company?.kpp || 0}
                </td>
              </tr>
            </tbody>
          </table>
          <div className='mt-19'>
            <Suggestions<Requisite>
              highlight={receiptAccountQuery || receiptAccountTarget?.name}
              suggestions={receiptAccountsFiltered?.map((receiptAccount) => ({
                key: receiptAccount._id,
                title: receiptAccount.name,
                subtitle: `БИК: ${receiptAccount.bic || '–'} Р/с: ${receiptAccount.account || '–'}`,
                payload: receiptAccount
              }))}
              select={(suggestion) => {
                setValue('receiptAccount', suggestion?.payload?.name || '')
                setReceiptAccountQuery('')
                setReceiptAccountTarget(suggestion?.payload)
              }}
            >
              <div className='inp-label text-p350 mb-5'>Выбрать счёт получателя</div>
              <label
                className={c(
                  'group relative flex items-center rounded-xl bg-white-0 ring-1 ring-grayscale-400 focus-within:ring-red-100 hover:ring-grayscale-250 hover:focus-within:ring-red-100',
                  !receiptAccountTarget && 'ring-1 ring-red-100'
                )}
              >
                <input
                  type='text'
                  placeholder='Выбрать'
                  className='w-full border-none bg-transparent px-10 py-7 placeholder-grayscale-250 outline-none transition-opacity focus:ring-0'
                  autoComplete='off'
                  onInput={(e) => {
                    setReceiptAccountTarget(null)
                    setReceiptAccountQuery(e.currentTarget.value)
                  }}
                  {...register('receiptAccount')}
                />
                {receiptAccountTarget?.account && (
                  <p className='whitespace-nowrap pr-10 text-grayscale-200'>
                    Р/с: ...{receiptAccountTarget.account.substring(receiptAccountTarget.account.length - 6)}
                  </p>
                )}
              </label>
            </Suggestions>
          </div>
        </>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        {!showRequisites && (
          <div className='relative flex flex-col gap-8'>
            <div className='relative'>
              <Textarea
                label='Назначение платежа'
                autoComplete='off'
                {...register('purpose', { required: true })}
                maxLength={210}
              />
              <div className='absolute bottom-1 right-4 text-xs text-grayscale-200'>{purpose?.length}/210</div>
            </div>
            <Controller
              render={({ field, fieldState }) => {
                return (
                  <MaskedNumberInput
                    label='Сумма'
                    placeholder='300 000,00'
                    inputMode='decimal'
                    error={fieldState?.error?.message}
                    {...field}
                  />
                )
              }}
              name='amount'
              control={control}
              rules={{ required: true, min: 0 }}
            />
          </div>
        )}

        <p className='my-12 text-center text-p100 text-red-100'>
          Убедитесь что данные в платеже совпадают с данными счета
        </p>

        {receiptAccountTarget ? (
          <SubmitButton loading={loading}>
            <PayIcon className='mr-5' />
            Отправить на оплату
          </SubmitButton>
        ) : (
          <button
            disabled
            className='flex h-27 w-full items-center justify-center rounded-xl bg-grayscale-200 font-semibold disabled:text-white-0'
            title='Не выбран счёт получателя'
          >
            <WarningIcon className='mr-5' height='24px' width='24px' />
            <p>Не выбран счёт получателя</p>
          </button>
        )}
        <div className='mt-5 text-center text-grayscale-250'>Оплата со счёта № {feraBankAccountTarget?.number}</div>
      </form>
    </section>
  )
}

export default RewardPaymentForm
