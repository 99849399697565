import { FC } from 'react'
import { ReactComponent as CheckmarkSuccessIcon } from '../../../svg/icons/circleCheck.svg'
import { ReactComponent as CheckmarkPendingIcon } from '../../../svg/icons/circleEmpty.svg'
import { ReactComponent as CheckmarkPartlyIcon } from '../../../svg/icons/circleSlot.svg'
import { ReactComponent as CheckmarkIsOverdue } from '../../../svg/icons/circleCross.svg'
import { ReactComponent as RefreshIcon } from '../../../svg/ui/refresh.svg'
import { ReactComponent as TrashIcon } from '../../../svg/ui/delete.svg'
import { ReactComponent as CancelIcon } from '../../../svg/icons/cancel.svg'
import { ReactComponent as EditIcon } from '../../../svg/ui/edit.svg'
import { ReactComponent as PlusIcon } from '../../../svg/ui/plus.svg'
import { ReactComponent as ApplyPaymentIcon } from '../../../svg/ui/payment.svg'
import { dateTimeFormatter } from '../../../utils/dateFormatter.ts'
import { formatMoneyDecimal } from '../../../utils/formatNumber.ts'
import { PaymentKind, PaymentState } from '../../../graphql/schema.tsx'
import Tooltip from '../../../components/Tooltip.tsx'

export enum PaymentActionType {
  CancelPaymentTransactions,
  EditPaymentTransactions,
  OpenPenaltyModal,
  OpenDeletingEntityModal,
  PayPayment,
  RecountPenalty,
  PayPenaltyPayment
}

export interface Payment {
  id: string
  date: any
  amount: number
  amountPaid: number
  kind: PaymentKind
  createdAt: any
  state: PaymentState
  isOverdued: boolean
  targetId: string
  paidAt?: any
  transactions: { id: string; amount: number; createdAt: any }[]
}

interface PaymentActionsProps {
  payment: Payment
  hasPenalties?: boolean
  triggerAction: (actionType: PaymentActionType) => void
  canPaymentsManage?: boolean
  canPenaltyCreate?: boolean
  canPenaltyDelete?: boolean
  canEditTransactionsDate?: boolean
  canPayPayment?: boolean
}

interface PaymentActionsIconProps {
  payment: Payment
}

export const PaymentActionsIcon: FC<PaymentActionsIconProps> = ({ payment }) => {
  if (payment.state === PaymentState.Paid) {
    return (
      <Tooltip target={<CheckmarkSuccessIcon className='text-green-600' />}>
        {payment.transactions?.length <= 1 ? (
          <p className='text-xs'>Разнесено: {dateTimeFormatter.format(new Date(payment.paidAt))}</p>
        ) : (
          <div className='flex flex-col gap-5 text-xs'>
            {payment.transactions.map((transaction, i) => (
              <div key={transaction?.id}>
                <p className='font-medium'>
                  {i + 1}. <span className='text-[#62B85A]'>{formatMoneyDecimal(transaction?.amount)}</span>
                </p>
                <p className='text-grayscale-250'>
                  Разнесено: {dateTimeFormatter.format(new Date(transaction.createdAt))}
                </p>
              </div>
            ))}
          </div>
        )}
      </Tooltip>
    )
  }
  if (payment.state === PaymentState.PartlyPaid) {
    return (
      <Tooltip target={<CheckmarkPartlyIcon className='text-[#F7C61B]' />}>
        <div className='flex flex-col gap-5 text-xs'>
          {payment.transactions?.map((transaction, i) => (
            <div key={transaction?.id}>
              <p className='font-medium'>
                {i + 1}. <span className='text-[#F6C61B]'>{formatMoneyDecimal(transaction?.amount)}</span>
              </p>
              <p className='text-grayscale-250'>
                Разнесено: {dateTimeFormatter.format(new Date(transaction.createdAt))}
              </p>
            </div>
          ))}
          <p>
            Необходимо доплатить:
            <span className='text-[#F6C61B]'> {formatMoneyDecimal(payment.amount - payment.amountPaid)}</span>
          </p>
        </div>
      </Tooltip>
    )
  }
  if (payment.isOverdued) {
    return <CheckmarkIsOverdue className='text-red-100' />
  }
  return <CheckmarkPendingIcon />
}

const PaymentActions: FC<PaymentActionsProps> = ({
  payment,
  hasPenalties,
  triggerAction,
  canPaymentsManage,
  canPenaltyCreate,
  canPenaltyDelete,
  canEditTransactionsDate,
  canPayPayment
}) => {
  return (
    <>
      {canPayPayment && payment.state !== PaymentState.Paid && (
        <button
          className='flex items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
          onClick={() => {
            triggerAction(PaymentActionType.PayPayment)
          }}
        >
          <ApplyPaymentIcon className='mr-7' height='18px' width='18px' title='Оплатить с баланса' />
          <span className='whitespace-nowrap'>Оплатить с баланса</span>
        </button>
      )}
      {canPenaltyCreate && payment.kind === PaymentKind.Leasing && (
        <button
          className='flex items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
          onClick={() => {
            triggerAction(PaymentActionType.OpenPenaltyModal)
          }}
        >
          <PlusIcon className='mr-7' height='20px' width='20px' title='Добавить пени' />
          <span className='whitespace-nowrap'>Добавить пени</span>
        </button>
      )}
      {canEditTransactionsDate && payment.state !== PaymentState.Pending && (
        <button
          className='flex items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
          onClick={() => triggerAction(PaymentActionType.EditPaymentTransactions)}
        >
          <EditIcon className='mr-7' height='18px' width='18px' title='Уточнить дату поступления' />
          <span className='whitespace-nowrap'>Уточнить дату поступления</span>
        </button>
      )}
      {canPenaltyDelete &&
        canPenaltyCreate &&
        payment.kind === PaymentKind.Penalty &&
        payment.state === PaymentState.Pending &&
        new Date(payment.date) < new Date() && (
          <button
            className='flex items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
            onClick={() => {
              triggerAction(PaymentActionType.RecountPenalty)
            }}
          >
            <RefreshIcon className='mr-7' height='20px' width='20px' title='Оплатить пени' />
            <span className='whitespace-nowrap'>Пересчитать пени</span>
          </button>
        )}
      {canPaymentsManage && (
        <>
          {payment.state !== PaymentState.Pending && (
            <button
              className='flex items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
              onClick={() => triggerAction(PaymentActionType.CancelPaymentTransactions)}
            >
              <CancelIcon className='mr-7' height='18px' width='18px' title='Отменить оплату' />
              <span className='whitespace-nowrap'>Отменить оплату</span>
            </button>
          )}
          {payment.kind === PaymentKind.Penalty && payment.state !== PaymentState.Paid && (
            <>
              <button
                className='flex items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
                onClick={() => {
                  triggerAction(PaymentActionType.PayPenaltyPayment)
                }}
              >
                <ApplyPaymentIcon className='mr-7' height='20px' width='20px' title='Оплатить с баланса пеней' />
                <span className='whitespace-nowrap'>Оплатить с баланса пеней</span>
              </button>
              <button
                className='flex items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
                onClick={() => {
                  triggerAction(PaymentActionType.PayPayment)
                }}
              >
                <ApplyPaymentIcon className='mr-7' height='20px' width='20px' title='Оплатить с основного баланса' />
                <span className='whitespace-nowrap'>Оплатить с основного баланса</span>
              </button>
            </>
          )}
          {payment.kind !== PaymentKind.Penalty &&
            payment.kind !== PaymentKind.Leasing &&
            payment.state === PaymentState.Pending &&
            !hasPenalties && (
              <button
                className='flex items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
                onClick={() => {
                  triggerAction(PaymentActionType.OpenDeletingEntityModal)
                }}
              >
                <TrashIcon className='mr-7' height='18px' width='18px' title='Удалить' />
                <span>Удалить</span>
              </button>
            )}
        </>
      )}
      {canPenaltyDelete && payment.kind === PaymentKind.Penalty && payment.state === PaymentState.Pending && (
        <button
          className='flex items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
          onClick={() => {
            triggerAction(PaymentActionType.OpenDeletingEntityModal)
          }}
        >
          <TrashIcon className='mr-7' height='18px' width='18px' title='Удалить' />
          <span>Удалить</span>
        </button>
      )}
    </>
  )
}

export default PaymentActions
