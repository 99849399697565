import { ReactElement } from 'react'

const useColumns = (n: number, items: ReactElement[] = []) => {
  const blocks: ReactElement[][] = []
  let i = 0

  items?.forEach((item) => {
    const col = i % n
    if (!blocks[col]) {
      blocks[col] = []
    }

    blocks[col].push(item)

    i += 1
  })

  return blocks
}

export default useColumns