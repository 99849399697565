import { FC } from 'react'
import { CompanyData, Okved } from '../types/dadata'
import { formatDecimal } from '../utils/formatNumber'
import { getDateFormattedString } from '../utils/contractUtils'
import { dateFormatter } from '../utils/dateFormatter'
import { Tab } from '@headlessui/react'
import c from 'clsx'
import { ReactComponent as RefreshIcon } from '../svg/ui/refresh.svg'
import { Card, CardHeader, CardIconButton, CardMenu, CardTitle } from './Card.tsx'

interface CompanyInfoTabProps {
  dadata: CompanyData
  companyName: string
  loading?: boolean
  refreshDadata?: () => void
  lastUpdateName?: string
  lastUpdateDate?: string
}

const taxSystemDict: Record<string, string> = {
  ENVD: 'Единый налог на вмененный доход',
  ESHN: 'Единый сельскохозяйственный налог',
  SRP: 'Система налогообложения при выполнении соглашений о разделе продукции',
  USN: 'Упрощенная система налогообложения',
  ENVD_ESHN: 'Единый налог на вмененный доход + Единый сельскохозяйственный налог',
  USN_ENVD: 'Упрощенная система налогообложения + Единый налог на вмененный доход'
}

const CompanyInfo: FC<CompanyInfoTabProps> = ({
  dadata,
  companyName,
  refreshDadata,
  lastUpdateName,
  lastUpdateDate,
  loading
}) => {
  const okveds: Okved[] | undefined = dadata?.okveds
  const mainOkved: Okved | undefined = okveds?.find((o) => o?.code === dadata?.okved)

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          Выгрузка по компании
          {lastUpdateName && lastUpdateDate && (
            <div className='font-medium text-labels-secondary'>
              Последний запрос: {lastUpdateName} {lastUpdateDate && dateFormatter.format(new Date(lastUpdateDate))}
            </div>
          )}
        </CardTitle>
        {refreshDadata && (
          <CardMenu>
            <CardIconButton onClick={refreshDadata}>
              <RefreshIcon className={c(loading && 'animate-spin-reverse')} />
            </CardIconButton>
          </CardMenu>
        )}
      </CardHeader>

      <div className='px-5 pb-5'>
        <div className='rounded-md bg-surface-primary px-4 pb-4 shadow-card'>
          <Tab.Group>
            <Tab.List className='flex gap-10 overflow-x-auto rounded-xl px-6 text-grayscale-150'>
              <Tab className='h-full cursor-pointer py-10 font-medium outline-none hover:text-red-100 ui-selected:border-b-2 ui-selected:border-red-100 ui-selected:text-red-100'>
                Основное
              </Tab>
              {dadata?.type !== 'INDIVIDUAL' && (
                <Tab className='h-full cursor-pointer py-10 font-medium outline-none hover:text-red-100 ui-selected:border-b-2 ui-selected:border-red-100 ui-selected:text-red-100'>
                  Руководители
                </Tab>
              )}
              <Tab className='h-full cursor-pointer py-10 font-medium outline-none hover:text-red-100 ui-selected:border-b-2 ui-selected:border-red-100 ui-selected:text-red-100'>
                Финансы
              </Tab>
              <Tab className='h-full cursor-pointer py-10 font-medium outline-none hover:text-red-100 ui-selected:border-b-2 ui-selected:border-red-100 ui-selected:text-red-100'>
                ОКВЭД
              </Tab>
              {!!dadata?.licenses?.length && (
                <Tab className='h-full cursor-pointer py-10 font-medium outline-none hover:text-red-100 ui-selected:border-b-2 ui-selected:border-red-100 ui-selected:text-red-100'>
                  Лицензии
                </Tab>
              )}
              <Tab className='h-full cursor-pointer py-10 font-medium outline-none hover:text-red-100 ui-selected:border-b-2 ui-selected:border-red-100 ui-selected:text-red-100'>
                Прочее
              </Tab>
            </Tab.List>

            <Tab.Panels>
              <Tab.Panel>
                <table className='w-full'>
                  <tbody>
                    <tr className='group'>
                      <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>Наименование компании</td>
                      <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>{companyName}</td>
                    </tr>
                    <tr className='group'>
                      <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>ИНН</td>
                      <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>{dadata?.inn}</td>
                    </tr>
                    {dadata?.type === 'LEGAL' && (
                      <tr className='group'>
                        <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>КПП</td>
                        <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>{dadata?.kpp}</td>
                      </tr>
                    )}
                    <tr className='group'>
                      <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>
                        {dadata?.type === 'LEGAL' ? 'ОГРН' : 'ОГРНИП'}
                      </td>
                      <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>{dadata?.ogrn}</td>
                    </tr>
                    <tr className='group'>
                      <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>Дата регистрации</td>
                      <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>
                        {dadata?.state?.registration_date
                          ? dateFormatter.format(new Date(dadata?.state?.registration_date))
                          : '-'}
                      </td>
                    </tr>
                    <tr className='group'>
                      <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>Основной ОКВЭД</td>
                      <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>
                        <a
                          className='hover:text-red-100'
                          target={'_blank'}
                          rel='noreferrer'
                          href={`https://regforum.ru/okved/kod_okved_${dadata?.okved}`}
                        >
                          {mainOkved?.code} {mainOkved?.name}
                        </a>
                      </td>
                    </tr>
                    {dadata?.type === 'LEGAL' && (
                      <tr className='group'>
                        <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>Наличие филиалов</td>
                        <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>{dadata?.branch_count}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Tab.Panel>

              {dadata?.type !== 'INDIVIDUAL' && (
                <Tab.Panel>
                  <table className='w-full'>
                    <tbody>
                      <tr className='group'>
                        <td className='w-100 rounded-l-xl p-6 group-odd:bg-grayscale-450'>Руководитель</td>
                        <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>{dadata?.management?.post}</td>
                      </tr>
                      <tr className='group'>
                        <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>ФИО</td>
                        <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>{dadata?.management?.name}</td>
                      </tr>
                    </tbody>
                  </table>
                  {dadata?.founders && (
                    <>
                      <h2 className='mb-2 px-8 pt-6 font-display text-h200 font-normal'>Учредители</h2>
                      {dadata?.founders?.map((founder, index) => (
                        <table key={founder?.hid} className='mb-4 w-full'>
                          <tbody>
                            <tr className='group'>
                              <td className='w-100 rounded-l-xl p-6 group-odd:bg-grayscale-450'>
                                Учредитель №{index + 1}
                              </td>
                              <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>
                                {founder?.type === 'PHYSICAL' ? founder?.fio?.source : founder?.name}
                              </td>
                            </tr>
                            {founder?.share?.type === 'PERCENT' && (
                              <tr className='group'>
                                <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>Доля</td>
                                <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>
                                  {founder?.share?.value}% →{' '}
                                  {formatDecimal((founder?.share?.value / 100) * dadata?.capital?.value! * 100)} ₽
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      ))}
                    </>
                  )}
                </Tab.Panel>
              )}

              <Tab.Panel>
                <table className='w-full'>
                  <tbody>
                    <tr className='group'>
                      <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>Система налогообложения</td>
                      <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>
                        {dadata?.finance?.tax_system ? taxSystemDict[dadata?.finance?.tax_system] : <em>неизвестно</em>}
                      </td>
                    </tr>
                    <tr className='group'>
                      <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>Уставной капитал</td>
                      <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>
                        {dadata?.capital?.value ? (
                          formatDecimal(dadata?.capital?.value * 100) + ' ₽'
                        ) : (
                          <em>неизвестно</em>
                        )}
                      </td>
                    </tr>
                    <tr className='group'>
                      <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>
                        Доходы за {dadata?.finance?.year} год
                      </td>
                      <td className='rounded-r-xl p-6 text-green-600 group-odd:bg-grayscale-450'>
                        {dadata?.finance?.income ? formatDecimal(dadata?.finance?.income * 100) + ' ₽' : null}
                      </td>
                    </tr>
                    <tr className='group'>
                      <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>
                        Расходы за {dadata?.finance?.year} год
                      </td>
                      <td className='rounded-r-xl p-6 text-red-50 group-odd:bg-grayscale-450'>
                        {dadata?.finance?.expense ? formatDecimal(dadata?.finance?.expense * 100) + ' ₽' : null}
                      </td>
                    </tr>
                    <tr className='group'>
                      <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>Недоимки по налогам</td>
                      <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>
                        {dadata?.finance?.debt ? formatDecimal(dadata?.finance?.debt * 100) + ' ₽' : null}
                      </td>
                    </tr>
                    <tr className='group'>
                      <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>Налоговые штрафы</td>
                      <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>
                        {dadata?.finance?.penalty ? formatDecimal(dadata?.finance?.penalty * 100) + ' ₽' : null}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Tab.Panel>

              <Tab.Panel>
                <table className='w-full'>
                  <tbody>
                    <tr className='group'>
                      <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>Основной ОКВЭД</td>
                      <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>
                        <a
                          className='hover:text-red-100'
                          target={'_blank'}
                          rel='noreferrer'
                          href={`https://regforum.ru/okved/kod_okved_${dadata?.okved}`}
                        >
                          {mainOkved?.code} {mainOkved?.name}
                        </a>
                      </td>
                    </tr>
                    <tr className='group'>
                      <td className='flex items-start rounded-l-xl p-6 group-odd:bg-grayscale-450'>
                        Дополнительные ОКВЭД
                      </td>
                      <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>
                        {okveds
                          ?.filter((o) => o.code !== mainOkved?.code)
                          .map((o) => (
                            <a
                              key={o.code}
                              className='mb-6 block last:mb-0 hover:text-red-100'
                              target={'_blank'}
                              rel='noreferrer'
                              href={`https://regforum.ru/okved/kod_okved_${dadata?.okved}`}
                            >
                              {o?.code} {o?.name}
                            </a>
                          ))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Tab.Panel>

              {!!dadata?.licenses?.length && (
                <Tab.Panel>
                  {dadata?.licenses?.map((license) => (
                    <table key={license.number} className='mb-4 w-full'>
                      <tbody>
                        <tr className='group'>
                          <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>Номер лицензии</td>
                          <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>
                            {license?.series} {license?.number}
                          </td>
                        </tr>
                        <tr className='group'>
                          <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>Кем выдана</td>
                          <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>{license?.issue_authority}</td>
                        </tr>
                        <tr className='group'>
                          <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>Когда выдана</td>
                          <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>
                            {getDateFormattedString(new Date(license?.issue_date))}
                          </td>
                        </tr>
                        <tr className='group'>
                          <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>
                            Вид лицензируемой деятельности
                          </td>
                          <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>
                            {license?.activities?.map((a, i) => (
                              <p key={i} className='mb-6 last:mb-0'>
                                {a}
                              </p>
                            ))}
                          </td>
                        </tr>
                        <tr className='group'>
                          <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>Срок действия</td>
                          <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>
                            {getDateFormattedString(new Date(license?.valid_to))}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ))}
                </Tab.Panel>
              )}

              <Tab.Panel>
                <table className='mb-4 w-full'>
                  <tbody>
                    <tr className='group'>
                      <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>Дата последних изменений</td>
                      <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>
                        {getDateFormattedString(new Date(dadata?.state?.actuality_date))}
                      </td>
                    </tr>
                    <tr className='group'>
                      <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>
                        Среднесписочная численность работников
                      </td>
                      <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>
                        {dadata?.employee_count !== null ? dadata?.employee_count : <em>неизвестно</em>}
                      </td>
                    </tr>
                    <tr className='group'>
                      <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>Правопредшественники</td>
                      <td className='rounded-r-xl p-6 group-odd:bg-grayscale-450'>
                        {dadata?.predecessors?.map((predecessor) => (
                          <p key={predecessor?.inn}>{predecessor?.name}</p>
                        )) || <em>Информация отсутствуют</em>}
                      </td>
                    </tr>
                    <tr className='group'>
                      <td className='rounded-l-xl p-6 group-odd:bg-grayscale-450'>Провоприемники</td>
                      <td className='w-120 rounded-r-xl p-6 group-odd:bg-grayscale-450'>
                        {dadata?.successors?.map((successor) => <p key={successor?.inn}>{successor?.name}</p>) || (
                          <em>Информация отсутствуют</em>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </Card>
  )
}

export default CompanyInfo
