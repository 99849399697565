import { FC } from 'react'
import { ReactComponent as PlusIcon } from '../../../svg/icons/plus.svg'
import { ReactComponent as TickIcon } from '../../../svg/icons/tick-xs.svg'
import { SubmitHandler, useForm } from 'react-hook-form'
import SubmitButton from '../../../components/SubmitButton/index'
import { useCreateFundingTrancheMutation, useFundingSourcesByDealIdSuspenseQuery } from '../../../graphql/schema'
import Select from '../../../components/Select'
import { ApolloError } from '@apollo/client'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils'
import MaskedInput from '../../../components/MaskedInput'
import parseDecimal from '../../../utils/parseDecimal'
import useNodes from '../../../hooks/useNodes.ts'

type Inputs = {
  dealId: string
  fundingSourceId: string
  fundingSourceName: string
  number: string
  amount: string
}

interface FormProps {
  dealId: string
  onDone?: () => void
}

const FundingTrancheForm: FC<FormProps> = ({ dealId, onDone }) => {
  const {
    handleSubmit,
    register,
    setValue,
    setError,
    watch,
    formState: { errors }
  } = useForm<Inputs>()
  const [createFundingTranche, { loading }] = useCreateFundingTrancheMutation()

  const { data } = useFundingSourcesByDealIdSuspenseQuery({
    variables: { dealId: `${dealId}` },
    skip: !dealId
  })
  const fundingSources = useNodes(data?.dealFundingSources?.edges)?.map((i) => i.fundingSource)

  const fundingSourceId = watch('fundingSourceId')
  const fundingSourceName = watch('fundingSourceName')

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (loading) return

    const amount = parseDecimal(data.amount.replace(/\s/g, ''))
    await createFundingTranche({
      variables: {
        input: {
          dealId,
          amount,
          fundingSourceId: data.fundingSourceId,
          number: data.number
        }
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  if (!fundingSources?.length) return null

  return (
    <section className='md: w-[448px] p-12'>
      <h1 className='font-display text-h200 mb-12'>Добавление транша</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 flex flex-col gap-8 relative'>
          <div className='relative'>
            <Select
              label='Источник финансирования'
              placeholder='Выберите источник финансирования'
              type='text'
              autoComplete='off'
              value={fundingSourceName}
              error={errors.fundingSourceId || errors.fundingSourceName}
              {...register('fundingSourceId', { required: true })}
            >
              {fundingSources?.map((fundingSource) => (
                <li
                  key={fundingSource.id}
                  onClick={() => {
                    setValue('fundingSourceId', fundingSource.id, { shouldValidate: true })
                    setValue('fundingSourceName', fundingSource.name)
                  }}
                  className='hover:bg-grayscale-450 px-12 py-5 cursor-pointer'
                >
                  <div className='flex justify-between items-center'>
                    <div className='text-grayscale-0 mb-1'>{fundingSource.name}</div>
                    {fundingSourceId === fundingSource.id && (
                      <div className='rounded-full bg-red-100/5 text-red-100 px-5 py-2 flex items-center ml-5'>
                        <TickIcon />
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </Select>
          </div>
          <MaskedInput
            label='№ транша'
            placeholder='17'
            inputMode='numeric'
            mask='number'
            {...register('number', { required: true, min: 0 })}
            error={errors.number}
          />
          <MaskedInput
            label='Сумма'
            placeholder='10000'
            inputMode='decimal'
            mask='number'
            {...register('amount', { required: true, min: 0 })}
            error={errors.amount}
          />
        </div>
        <SubmitButton loading={loading}>
          <PlusIcon className='mr-5' />
          Добавить
        </SubmitButton>
      </form>
    </section>
  )
}

export default FundingTrancheForm
