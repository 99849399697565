import { FC, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { ReactComponent as FileIcon } from '../../../svg/icons/fileDownload.svg'
import Input from '../../../components/Input'
import downloadFile from '../../../utils/downloadFileServerless'
import checkInn from '../../../utils/innValidator'
import checkKPP from '../../../utils/kppValidator'
import { ComissionContractFormInputs } from '../models'
import { CompanyData } from '../../../types/dadata'
import getInclineFIO from '../../../utils/getInclineFIO'
import { LEADER_STATUSES_GENITIVE_MAP } from '../models/constants'
import {
  capitalizeFirst,
  capitalizeFirstInWords,
  getCompanyForm,
  getDateFormattedInDocumentName,
  getDateFormattedString,
  isIP
} from '../../../utils/contractUtils'
import MaskedInput from '../../../components/MaskedInput'
import checkOgrn from '../../../utils/ogrnValidator'
import checkOgrnip from '../../../utils/ogrnipValidator'
import {
  Kind,
  useCreateCommissionContractPdfMutation,
  useDealForLeasingContractByIdSuspenseQuery,
  useDealCalculationsSuspenseQuery,
  useFeraBankAccountsSuspenseQuery,
  DealInsuranceKind
} from '../../../graphql/schema'
import { REGULAR_VAT_RATE } from '../../../utils/constants'
import { formatDecimal } from '../../../utils/formatNumber'
import parseDecimal from '../../../utils/parseDecimal'
import SubmitButton from '../../../components/SubmitButton'
import useNodes from '../../../hooks/useNodes'
import SignatorySelectInput, { KimSignatory } from '../../../components/SignatorySelectInput.tsx'
import { ApolloError } from '@apollo/client'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils.ts'
import usePersonAddress from '../../../hooks/usePersonAddress.ts'
import RequisiteInput from '../../../components/Requisites/RequisiteInput.tsx'

interface CommissionControlFormProps {
  onDone?: () => void
  dealId: number
}

const CommissionContractForm: FC<CommissionControlFormProps> = ({ dealId }) => {
  const { data } = useDealForLeasingContractByIdSuspenseQuery({ variables: { id: `${dealId}` } })
  const deal = data?.deal
  const bankDetails = useNodes(deal?.customerCompany?.bankDetails?.edges)

  const insuranceRate = deal?.insuranceAmount ? Math.round((deal?.insuranceAmount / deal?.amount) * 10000) / 100 : 0

  const { data: dealCalculationsData } = useDealCalculationsSuspenseQuery({
    variables: {
      dealParams: {
        amount: deal?.amount / 100 || 0,
        advanceRate: deal?.advanceRate || 0,
        interestRate: deal?.interestRate || 0,
        durationMonths: deal?.durationMonths || 0,
        comissionRate: deal?.comissionRate || 0,
        insuranceRate: deal?.insuranceKind === DealInsuranceKind.WithoutInsurance ? 0 : insuranceRate,
        vatRate: deal?.kind === Kind.Medicine ? 0 : REGULAR_VAT_RATE,
        advancePaymentDate: deal?.advancePaymentDate || undefined
      }
    }
  })
  const dealCalculations = dealCalculationsData?.dealCalculations

  const { data: feraBankAccountsData } = useFeraBankAccountsSuspenseQuery()
  const feraBankAccounts = useNodes(feraBankAccountsData?.feraBankAccounts?.edges)

  const companyDadata: CompanyData = useMemo(
    () => (deal?.customerCompany?.dadata ? deal?.customerCompany.dadata.data : {}),
    [deal?.customerCompany]
  )

  const [createCommissionContract] = useCreateCommissionContractPdfMutation()

  const IP: boolean = isIP(companyDadata)
  const companyName = IP ? capitalizeFirstInWords(companyDadata.name?.full) : capitalizeFirst(companyDadata.name?.full)
  let leaderStatus =
    LEADER_STATUSES_GENITIVE_MAP.get(companyDadata?.management?.post?.toLowerCase()) ||
    companyDadata?.management?.post?.toLowerCase() ||
    ''
  leaderStatus = capitalizeFirst(leaderStatus)
  const leaderStatusGenitiveWithName = companyDadata?.management?.name
    ? (leaderStatus + ' ' + getInclineFIO(companyDadata?.management?.name)).trim()
    : ''
  const feraBankAccount = feraBankAccounts?.find((account) => account?.id?.toString() === deal?.feraBankAccountId)

  const form = useForm<ComissionContractFormInputs>({
    defaultValues: {
      products: [
        {
          name: 'Услуги по формированию пакета документов для заключения лизингового договора',
          amount: '1',
          price: formatDecimal(dealCalculations?.comissionAmount ? dealCalculations.comissionAmount * 100 : 0)
        }
      ],
      signatory: KimSignatory,
      commissionContractN: `СД${deal?.contractNumber}`,
      commissionContractDate: deal?.contractDate?.split('T').shift(),
      company: {
        name: companyName,
        formShort: getCompanyForm(deal?.customerCompany?.shortWithOpf || '', companyName),
        form: getCompanyForm(deal?.customerCompany?.fullWithOpf || '', companyName, true),
        requisites: {
          inn: deal?.customerCompany?.inn,
          legalAddress: companyDadata.address?.value,
          ogrn: companyDadata.ogrn,
          kpp: !IP ? companyDadata.kpp : undefined,
          bankName: bankDetails?.length === 1 ? bankDetails[0]?.name : undefined,
          accountNumber: bankDetails?.length === 1 ? bankDetails[0]?.account : undefined,
          correspondentNumber: bankDetails?.length === 1 ? bankDetails[0]?.correspondentAccount : undefined,
          bik: bankDetails?.length === 1 ? bankDetails[0]?.bic : undefined
        },
        leaderStatus: !IP ? capitalizeFirst(companyDadata?.management?.post) : undefined,
        leaderStatusGenitiveWithName: !IP ? leaderStatusGenitiveWithName.trim() : undefined,
        leader: !IP
          ? {
              name: companyDadata?.management?.name || ''
            }
          : undefined
      },
      feraBankAccount: feraBankAccount
        ? {
            bankKey: feraBankAccount?.bankKey,
            bic: feraBankAccount?.bic,
            corrNumber: feraBankAccount?.corrNumber,
            name: feraBankAccount?.name,
            number: feraBankAccount?.number
          }
        : undefined,
      vatRate: REGULAR_VAT_RATE
    }
  })
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    watch,
    formState: { errors }
  } = form

  const products = watch('products')

  const [loading, setLoading] = useState(false)
  const onSubmit: SubmitHandler<ComissionContractFormInputs> = async (data: ComissionContractFormInputs) => {
    if (loading) {
      return
    }

    setLoading(true)
    try {
      const createCommissionContractResult = await createCommissionContract({
        variables: {
          input: {
            ...data,
            company: {
              ...data.company,
              requisites: {
                ...data.company.requisites,
                legalAddress: data.company.requisites.legalAddress || ''
              }
            },
            products: data.products.map((p) => ({
              ...p,
              amount: parseInt(p.amount),
              price: parseDecimal(p.price.replace(/\s/g, ''))
            })),
            commissionContractDate: getDateFormattedString(new Date(data.commissionContractDate)),
            feraBankAccount: deal?.feraBankAccountId ? data?.feraBankAccount : undefined
          }
        }
      }).catch((err: ApolloError) => {
        handleBackendErrorsToForm<ComissionContractFormInputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })

      const commissionContractUrl = createCommissionContractResult?.data?.createCommissionContract?.url
      if (!commissionContractUrl) throw new Error('Не удалось получить ссылку на документ контракта')

      await downloadFile(
        commissionContractUrl,
        `${getDateFormattedInDocumentName(new Date())} Счёт-договор №${
          data.commissionContractN
        } от ${getDateFormattedString(new Date(data.commissionContractDate))}.pdf`
      )
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }

    // if (onDone) onDone()
  }

  const contactsList = useNodes(deal?.application.contacts?.edges)
  const [getContactRegAddress, addressLoading, addressError] = usePersonAddress()

  if (!deal) return null

  return (
    <section className='w-[468px] p-12 lg:w-[1048px] xl:w-[1248px]'>
      <h1 className='mb-12 font-display text-h200'>Счёт-договор на комиссию</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-10 grid grid-cols-1 gap-x-20 gap-y-8 lg:grid-cols-2 xl:grid-cols-2'>
          <Input
            label='Номер счет-договора'
            type='string'
            placeholder='СД220101-01'
            {...register('commissionContractN', { required: true })}
            error={errors.commissionContractN}
          />
          <Input
            label='Дата'
            type='date'
            readOnly
            {...register('commissionContractDate', { required: true })}
            error={errors.commissionContractDate}
          />
          {products.map((_, index) => (
            <MaskedInput
              key={index}
              label='Сумма комиссии'
              placeholder='10000'
              inputMode='decimal'
              mask='number'
              readOnly
              {...register(`products.${index}.price`, { required: true, min: 0 })}
              error={errors.products?.[index]?.price}
            />
          ))}
          <Input
            label='Ставка НДС(%)'
            placeholder='20'
            step={0.01}
            min={0}
            max={100}
            inputMode='decimal'
            readOnly
            {...register('vatRate', { required: true, valueAsNumber: true })}
            error={errors.vatRate}
          />
          <Input
            readOnly
            label='Реквизиты Fera'
            type='text'
            {...register('feraBankAccount.name')}
            error={errors.feraBankAccount?.name}
          />
        </div>

        <div className='grid grid-cols-1 gap-20 lg:grid-cols-2 xl:grid-cols-2'>
          <div className='flex flex-col gap-8 xl:mb-12'>
            <div className='mt-10 flex flex-col gap-8 border-t-1 border-grayscale-400 pt-15'>
              <h3 className='text-center font-display text-h300'>Компания</h3>
              <MaskedInput
                label='ИНН'
                disabled={true}
                mask='000000000000'
                autoComplete='off'
                placeholder='9999999999'
                {...register('company.requisites.inn', {
                  required: true,
                  validate: (value) => checkInn(value) || 'Неверный ИНН'
                })}
                error={errors.company?.requisites?.inn}
              />
              <Input
                label='Наименование формы общества'
                type='string'
                placeholder='Общество с ограниченной ответственностью'
                {...register('company.form', { required: true })}
                error={errors.company?.form}
              />
              <Input
                label='Короткое наименование формы общества'
                type='string'
                placeholder='ООО'
                {...register('company.formShort', { required: true })}
                error={errors.company?.formShort}
              />
              <Input
                label='Полное наименование компании'
                type='string'
                placeholder='Компания'
                {...register('company.name', { required: true })}
                error={errors.company?.name}
              />
              {!isIP(companyDadata) && (
                <>
                  <Input
                    label='Должность ответственного за компанию'
                    placeholder='Генеральный директор'
                    type='string'
                    {...register('company.leaderStatus', { required: true })}
                    error={errors.company?.leaderStatus}
                  />
                  <Input
                    label='ФИО ответственного за компанию'
                    placeholder='Иванов Иван Иванович'
                    type='string'
                    {...register('company.leader.name', { required: true })}
                    error={errors.company?.leader?.name}
                  />
                  <Input
                    label='Кто отвечает за компанию'
                    placeholder='Генерального директора Ивана Ивановича Иванова, действующего на основании Устава'
                    type='string'
                    {...register('company.leaderStatusGenitiveWithName', { required: true })}
                    error={errors.company?.leaderStatusGenitiveWithName}
                  />
                </>
              )}
            </div>
          </div>
          <div className='relative flex flex-col gap-8 xl:mb-12'>
            <div className='mt-10 flex flex-col gap-8 border-t-1 border-grayscale-400 pt-15'>
              <h3 className='text-center font-display text-h300'>Прочие реквизиты</h3>
              <div>
                <Input
                  label='Юридический адрес'
                  type='text'
                  autoComplete='off'
                  placeholder='105005, г. Москва, Бакунинская улица, дом 4-6, строение 1'
                  {...register('company.requisites.legalAddress', { required: true })}
                  error={errors.company?.requisites?.legalAddress}
                />
                {isIP(companyDadata) && (
                  <>
                    {addressLoading ? (
                      <span className='text-xs text-grayscale-250'>Загрузка...</span>
                    ) : addressError ? (
                      <span className='text-xs text-red-150'>Данные не найдены</span>
                    ) : (
                      <button
                        className='w-fit flex-none text-xs text-red-50 underline decoration-dashed'
                        type='button'
                        onClick={async () => {
                          const address = await getContactRegAddress(contactsList, deal?.customerCompany.inn)
                          setValue('company.requisites.legalAddress', address)
                        }}
                      >
                        Заполнить из верификации
                      </button>
                    )}
                  </>
                )}
              </div>
              {!isIP(companyDadata) && (
                <>
                  <MaskedInput
                    label='КПП'
                    type='text'
                    placeholder='999999999'
                    mask='000000000'
                    {...register('company.requisites.kpp', {
                      required: true,
                      validate: (value) => checkKPP(value) || 'Неверный КПП'
                    })}
                    error={errors.company?.requisites?.kpp}
                  />
                </>
              )}
              <MaskedInput
                label={isIP(companyDadata) ? 'ОГРНИП' : 'ОГРН'}
                type='text'
                mask='000000000000000'
                placeholder='9999999999999'
                {...register('company.requisites.ogrn', {
                  required: true,
                  validate: (value) => (isIP(companyDadata) ? checkOgrnip(value) : checkOgrn(value))
                })}
                error={errors.company?.requisites?.ogrn}
              />
              <Controller
                name='company.requisites'
                rules={{ validate: (value) => (!value?.bankName ? 'Обязательное поле' : true) }}
                control={control}
                render={({ field, fieldState }) => (
                  <RequisiteInput
                    onChange={(value) => field.onChange(value)}
                    value={field.value}
                    error={fieldState.error}
                    bankDetails={bankDetails}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className='mb-12 grid grid-cols-1 gap-20 lg:grid-cols-2 xl:grid-cols-2'>
          <div className='border-t-1 border-grayscale-400 pt-15'>
            <h3 className='mb-8 text-center font-display text-h300'>Подписант Фера</h3>
            <Controller
              name='signatory'
              control={control}
              render={({ field }) => (
                <SignatorySelectInput
                  selected={field.value}
                  onChange={(selected) => field.onChange(selected)}
                  error={errors?.signatory?.message}
                />
              )}
            />
          </div>
        </div>
        <SubmitButton loading={loading}>
          <FileIcon className='mr-5' />
          Сгенерировать
        </SubmitButton>
      </form>
    </section>
  )
}

export default CommissionContractForm
