import { FC, useState } from 'react'
import { ReactComponent as CrossIcon } from '../../svg/icons/cross-xs.svg'
import c from 'clsx'
import { capitalizeFirst } from '../../utils/contractUtils.ts'

export interface Tag {
  _id: number
  name: string
}

interface TagProps {
  value: Tag
  wiggle: boolean
  onRemove: (id: number) => void
}

const Tag: FC<TagProps> = ({ value, wiggle, onRemove }) => {
  const [animationDelay] = useState(Math.random())

  return (
    <div
      className={c('relative', wiggle && 'animate-wiggle-vertical')}
      style={{ animationDelay: `-${animationDelay}s` }}
    >
      <div className='px-5 py-3 rounded-md bg-surface-secondary font-medium'>{capitalizeFirst(value.name)}</div>
      {wiggle && (
        <button
          className='absolute -top-3 -right-2 p-2 rounded-full bg-white-0 text-grayscale-250 hover:text-red-100 cursor-pointer'
          onClick={() => onRemove(value._id)}
        >
          <CrossIcon />
        </button>
      )}
    </div>
  )
}

export default Tag
