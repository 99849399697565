import { PropsWithChildren, ReactElement } from 'react'
import { RadioGroup } from '@headlessui/react'
import c from 'clsx'

interface RadioButtonsGroupProps<T> {
  options: Array<[string, T]>
  className?: string
  onChange?: (value: T) => void
  checkedValue?: T
  label?: string
  labelClassName?: string
  sameWidth?: boolean
}
type Props<T> = PropsWithChildren<RadioButtonsGroupProps<T>>

const RadioButtonsGroup: <T>(props: Props<T>) => ReactElement<Props<T>> = ({
  options,
  className,
  onChange,
  checkedValue,
  label,
  labelClassName,
  sameWidth = true
}) => {
  return (
    <div className={c(className)}>
      {label && <div className={c('inp-label text-p350 mb-5', labelClassName)}>{label}</div>}
      <RadioGroup
        className={c('bg-grayscale-450 rounded-xl p-1 grid grid-flow-col', sameWidth ? 'auto-cols-fr' : 'auto-cols')}
        value={checkedValue}
        onChange={(value) => {
          onChange && onChange(value)
        }}
      >
        {options.map((option, i) => (
          <RadioGroup.Option value={option[1]} key={i}>
            {({ checked }) => (
              <div
                className={c(
                  'cursor-pointer text-center m-1 py-5 px-5 border-1 border-solid border-grayscale-450 rounded-xl',
                  checked && 'bg-white-0 border-grayscale-350'
                )}
              >
                {option[0]}
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
    </div>
  )
}

export default RadioButtonsGroup
