import { FC, ReactNode, ComponentPropsWithoutRef } from 'react'
import { FieldError } from 'react-hook-form'
import c from 'clsx'
import parseDecimal from '../../../utils/parseDecimal'
import { errorToString } from '../../../utils/errorToString'
import { IMaskInput, IMask } from 'react-imask'
interface PercentNumberInputProps {
  baseValue: number
  percentValue: string
  value: string
  onPercentChange: (percent: string) => void
  onValueChange: (value: string) => void
  label?: string
  error?: string | FieldError
  children?: ReactNode
  money?: boolean
}

type MaskProps = ComponentPropsWithoutRef<typeof IMaskInput>

const maskProps = (money: boolean, baseValue: number): MaskProps => {
  if (money) {
    return {
      mask: 'num ₽',
      blocks: {
        num: {
          mask: Number,
          thousandsSeparator: ' ',
          max: baseValue
        }
      },
      lazy: false
    }
  } else {
    return {
      mask: Number,
      thousandsSeparator: ' ',
      max: baseValue
    }
  }
}

export const percentProps: MaskProps = {
  mask: 'num %',
  blocks: {
    num: {
      mask: Number,
      scale: 2,
      min: 0,
      max: 100,
      radix: ',',
      mapToRadix: ['.']
    }
  },
  lazy: false
}

const percentMask = IMask.createMask(percentProps)
const moneyMask = IMask.createMask(maskProps(true, 0))
const numberMask = IMask.createMask(maskProps(false, 0))

const prepareValue = (value: string, money: boolean, max: number) => {
  if (money) {
    moneyMask.updateOptions({ blocks: { num: { mask: Number, max } } })
    moneyMask.resolve(value)
    return moneyMask.unmaskedValue
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    numberMask.updateOptions({ max })
    numberMask.resolve(value)
    return numberMask.unmaskedValue
  }
}

const preparePercentValue = (value: string) => {
  percentMask.resolve(value)
  return percentMask.unmaskedValue
}

export const percentToAmount = (percent: string, value: number, max: number, money: boolean) => {
  const result = value * (parseDecimal(percent) / 100)
  return prepareValue(result.toString(), money, max)
}

export const amountToPercent = (amount: string, value: number) => {
  const result = (parseDecimal(amount) / value) * 100
  return preparePercentValue(result.toString())
}

const Input: FC<PercentNumberInputProps> = ({
  label,
  children,
  error,
  baseValue,
  onPercentChange,
  onValueChange,
  value,
  percentValue,
  money = false
}) => {
  return (
    <div>
      {!!label && <div className='inp-label text-p350 mb-5'>{label}</div>}

      <label
        className={c(
          'group flex relative bg-white-0 ring-grayscale-400 hover:focus-within:ring-red-100 focus-within:ring-red-100 hover:ring-grayscale-250 rounded-xl ring-1',
          !!error && 'ring-red-100 ring-1'
        )}
      >
        <IMaskInput
          value={percentValue?.replace('.', ',')}
          {...percentProps}
          // it accepts className prop
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          className='block tabular-nums box-content w-30 py-7 pl-10 pr-5 transition-opacity border-none outline-none bg-transparent focus:ring-0 placeholder-grayscale-250'
          onAccept={(_, { unmaskedValue }) => {
            if (unmaskedValue === percentValue) return
            onPercentChange(unmaskedValue.replace(',', '.'))
          }}
        />
        <div className='w-1px my-3 bg-grayscale-400 flex-none' />
        <IMaskInput
          value={value?.replace('.', ',')}
          {...maskProps(money, baseValue)}
          // it accepts className prop
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          className='block w-full py-7 pr-10 pl-5 transition-opacity border-none outline-none bg-transparent focus:ring-0 placeholder-grayscale-250'
          onAccept={(_, { unmaskedValue }) => {
            if (unmaskedValue === value) return
            onValueChange(unmaskedValue.replace(',', '.'))
          }}
        />
        {children}
      </label>
      {error && <div className='text-red-150 text-p450 pl-4 pt-2'>{errorToString(error)}</div>}
    </div>
  )
}

export default Input
