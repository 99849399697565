import { FC } from 'react'
import { Popover } from '@headlessui/react'
import { ReactComponent as TrashIcon } from '../../svg/icons/trash.svg'
import { ReactComponent as CloseIcon } from '../../svg/icons/cross.svg'

interface FilterFormProps {
  onReset: () => void,
  title?: string,
  children?: React.ReactNode
}

const FilterForm: FC<FilterFormProps> = ({ children, onReset, title = 'Фильтр сделок' }) => {
  return (
    <section className='p-10 relative'>
      <h2 className='font-display text-h200 mb-10'>{title}</h2>
      <Popover.Button className='absolute top-0 right-0 p-10'>
        <CloseIcon className='text-grayscale-200 hover:text-red-100' />
      </Popover.Button>

      {children}

      <button
        onClick={onReset}
        type="submit"
        className="px-20 h-27 bg-red-200/60 hover:bg-red-200 active:bg-red-200 focus-visible:outline-red-200 focus-visible:outline-2 focus-visible:outline ring-red-200 rounded-xl text-red-100 text-p100 font-semibold leading-full w-full overflow-hidden relative">
        <div className='flex items-center justify-center'>
          <TrashIcon className='mr-5' />
          Очистить фильтры
        </div>
      </button>
    </section>
  )
}

export default FilterForm
