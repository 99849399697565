import { FC } from 'react'
import { ReactComponent as BoNalogIcon } from '../svg/icons/boNalog.svg'
import { useBoNalogQuery } from '../graphql/schema'
import c from 'clsx'
import PrimaryButtonLink from './UI/PrimaryButtonLink'

interface BoNalogLinkProps {
  inn?: string
}

const BoNalogLink: FC<BoNalogLinkProps> = ({ inn = '' }) => {
  const { data: boNalogData } = useBoNalogQuery({
    variables: { inn },
    skip: !inn
  })
  const boNalogLink = boNalogData?.boNalog?.link

  return (
    <PrimaryButtonLink href={boNalogLink} target='_blank' rel='noreferrer'>
      <BoNalogIcon className={c(!boNalogLink && 'grayscale')} />
      {boNalogLink ? 'Открыть в налоговой' : 'Не найдено на nalog.ru'}
    </PrimaryButtonLink>
  )
}

export default BoNalogLink
