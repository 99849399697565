import { FC } from 'react'
import { ReactComponent as SuccessIcon } from '../../svg/ui/success.svg'
import { ReactComponent as ErrorIcon } from '../../svg/ui/error.svg'
import { EngagePartnerStatus } from '../../graphql/schema'
import StatusRing from '../../components/StatusRing'
import c from 'clsx'
import { supplierStatusDict } from '../../utils/dictionaries'

export interface SupplierStatusProps {
  status: EngagePartnerStatus
  className?: string
}

export interface ColorState {
  active: string
  inactive?: string
}

export const engageColorDict: Record<EngagePartnerStatus, string> = {
  [EngagePartnerStatus.Pending]: 'bg-tr-cyan',
  [EngagePartnerStatus.InProgress]: 'bg-tr-blue',
  [EngagePartnerStatus.Enlisted]: 'bg-tr-green',
  [EngagePartnerStatus.Hold]: 'bg-tr-red'
}

export const engageTextColorDict: Record<EngagePartnerStatus, string> = {
  [EngagePartnerStatus.Pending]: 'text-dark-cyan',
  [EngagePartnerStatus.InProgress]: 'text-dark-blue',
  [EngagePartnerStatus.Enlisted]: 'text-dark-green',
  [EngagePartnerStatus.Hold]: 'text-dark-red'
}

export const engageIconColorDict: Record<EngagePartnerStatus, string> = {
  [EngagePartnerStatus.Pending]: 'text-base-cyan',
  [EngagePartnerStatus.InProgress]: 'text-base-blue',
  [EngagePartnerStatus.Enlisted]: 'text-dark-green',
  [EngagePartnerStatus.Hold]: 'text-dark-red'
}

const statusOrder = Object.keys(engageColorDict)
const maxSegments = 3

/**
 * when on colored background success and error icons should be darker (designer's request)
 * set bg to true to use this behavior
 */
export const SupplierStatusCircle: FC<SupplierStatusProps & { bg?: boolean }> = ({ status, className, bg = false }) => {
  const statusNumber = statusOrder.indexOf(status) + 1

  return (
    <div className={c(engageTextColorDict[status], className)}>
      {statusNumber < maxSegments && (
        <StatusRing className={engageIconColorDict[status]} maxSegments={maxSegments} activeSegments={statusNumber} />
      )}
      {status === EngagePartnerStatus.Enlisted && (
        <SuccessIcon className={bg ? 'text-dark-green' : 'text-base-green'} />
      )}
      {status === EngagePartnerStatus.Hold && <ErrorIcon className={bg ? 'text-dark-red' : 'text-base-red'} />}
    </div>
  )
}

export const SupplierStatusBadge: FC<SupplierStatusProps> = ({ status }) => {
  return (
    <div
      className={c(
        'rounded-md px-3 h-13 flex items-center justify-center gap-x-3',
        engageTextColorDict[status],
        engageColorDict[status]
      )}
    >
      <SupplierStatusCircle status={status} bg={true} />
      <div>{supplierStatusDict[status]}</div>
    </div>
  )
}
