import { FC } from 'react'
import { Toaster as SToaster } from 'sonner'

const Toaster: FC = () => {
  return (
    <SToaster
      toastOptions={{
        unstyled: true,
        classNames: {
          toast: 'bg-white-0 w-full pt-4 pb-6 px-10 rounded-md shadow-md ring-1 ring-grayscale-350',
          title: '!text-red-50'
        }
      }}
      visibleToasts={10}
    />
  )
}

export default Toaster
