import { useEffect, useMemo, useState } from 'react'
import { LeasingSubjectCategory, useAddSupplierMutation, useCategoriesQuery } from '../../graphql/schema'
import { SubmitHandler, useForm } from 'react-hook-form'
import { handleBackendErrorsToForm } from '../../utils/backendErrorUtils'
import { ApolloError } from '@apollo/client'
import SubmitButton from '../../components/SubmitButton'
import GroupSelectInput, { Option } from '../../components/GroupSelectInput'
import { CompanyData } from '../../types/dadata'
import getCompanyName from '../../utils/getCompanyName'
import Input from '../../components/Input'
import Suggestions from '../../components/Suggestions'
import useThrottledState from '../../hooks/useThrottledState'
import { useCompanySuggestions } from '../../hooks/useSuggestions'

import { ReactComponent as PlusIcon } from '../../svg/icons/plus.svg'

interface SupplierFormProps {
  onDone?: () => void
}

type Inputs = { company: string; leasingSubjectCategories: LeasingSubjectCategory[]; inn?: string }

const SupplierForm: React.FC<SupplierFormProps> = ({ onDone }) => {
  const [selectedCategories, setSelectedCategories] = useState<Option[]>([])
  const [errorCategories, setCategoriesError] = useState('')

  const [addSupplier, { loading }] = useAddSupplierMutation()
  const { register, handleSubmit, formState, setError, setValue, watch } = useForm<Inputs>()

  const { data: categoriesData } = useCategoriesQuery()
  const categories = useMemo(
    () => categoriesData?.leasingSubjectCategories?.edges?.map((edge) => edge?.node!) || [],
    [categoriesData]
  )
  const options = useMemo(
    () =>
      categories?.map((cat) => ({
        name: cat.name,
        value: cat._id,
        group: cat.categoryGroup?.name
      })) || [],
    [categories]
  )

  useEffect(() => {
    setCategoriesError('')
  }, [selectedCategories])

  const [company, setCompany] = useState<CompanyData>()
  const companyQuery = watch('company')
  const [throttledCompanyQuery] = useThrottledState(companyQuery, 500)
  const suggestions = useCompanySuggestions(throttledCompanyQuery)

  // при выборе компании устанавливаем её имя в поле компании
  useEffect(() => {
    if (!company) return
    setValue('company', getCompanyName(company), {
      shouldValidate: true
    })
  }, [company, setValue])

  const onSubmit: SubmitHandler<Inputs> = async () => {
    if (!selectedCategories.length) {
      return setCategoriesError('Поле обязательно')
    }
    await addSupplier({
      variables: {
        input: {
          inn: company?.inn!,
          leasingSubjectCategories: selectedCategories.map((lc) => lc?.value.toString())
        }
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  return (
    <section className='md: w-[448px] p-12'>
      <h1 className='font-display text-h200 mb-12'>Новый поставщик</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 flex flex-col gap-8 relative'>
          <Suggestions<CompanyData>
            suggestions={suggestions?.map((s) => ({
              key: s._id,
              title: getCompanyName(s),
              subtitle: `${s.inn} ${s.address?.value || ''}`,
              payload: s,
              lineThrough: !!s.state.liquidation_date
            }))}
            select={(suggestion) => setCompany(suggestion.payload)}
            highlight={companyQuery}
          >
            <Input
              label='Компания, ИП или ИНН'
              type='text'
              autoComplete='off'
              {...register('company', {
                required: true,
                validate: {
                  // требуем выбора из предложенных вариантов только если апи подсказок работает
                  fromSuggested: (value) => {
                    return value !== getCompanyName(company) ? 'Требуется выбрать один из вариантов' : true
                  }
                }
              })}
              error={formState.errors.inn?.message}
            />
          </Suggestions>
          <div>
            <GroupSelectInput
              label='Категории лизинга'
              placeholder='Выбрать категории'
              options={options}
              selectedOptions={selectedCategories}
              onChange={setSelectedCategories}
              error={errorCategories || formState.errors.leasingSubjectCategories?.message}
            />
          </div>
        </div>
        <SubmitButton loading={loading}>
          <PlusIcon className='mr-5' />
          Создать
        </SubmitButton>
      </form>
    </section>
  )
}

export default SupplierForm
