import { forwardRef, ComponentPropsWithoutRef } from 'react'
import { FieldError } from 'react-hook-form'
import { errorToString } from '../utils/errorToString'
import c from 'clsx'

interface InputProps {
  label?: string
  labelClassName?: string
  error?: string | FieldError
}

const Input = forwardRef<HTMLInputElement, ComponentPropsWithoutRef<'input'> & InputProps>(function Input(
  { label, labelClassName, children, error, ...props },
  ref
) {
  return (
    <div>
      {!!label && <div className={c('inp-label text-p350 mb-5', labelClassName)}>{label}</div>}

      <label
        className={c(
          'group block relative bg-white-0 ring-grayscale-400 hover:focus-within:ring-red-100 focus-within:ring-red-100 hover:ring-grayscale-250 rounded-xl ring-1 overflow-hidden',
          !!error && 'ring-red-100 ring-1'
        )}
      >
        <input
          className='py-7 px-10 transition-opacity border-none outline-none bg-transparent focus:ring-0 w-full placeholder-grayscale-250'
          ref={ref}
          {...props}
        />
        {children}
      </label>
      {error && <div className='text-red-150 text-p450 pl-4 pt-2'>{errorToString(error)}</div>}
    </div>
  )
})

export default Input
