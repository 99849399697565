import { FC, useCallback, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'
import { ReactComponent as MoreIcon } from '../../svg/icons/more.svg'
import {
  CommentTargetType,
  HistoryTargetType,
  TaskTargetType,
  useRefreshFromDadataCompanyMutation,
  useSourceByIdQuery,
  useUpdateCompanyMutation,
  useUpdateSourceMutation,
  VatKind
} from '../../graphql/schema'
import Comments from '../../components/Comments/Comments'
import Modal from '../../components/Modal'
import ManagersForm from './ManagersForm'
import useNodes from '../../hooks/useNodes'
import CategoriesForm from './CategoriesForm'
import EDO, { EDOCallback } from '../../components/EDO'
import Tasks from '../../components/Tasks'
import useAccessCheck from '../../hooks/useAccessCheck'
import ContactInfo, { ContactInfoKind } from '../../components/ContactInfo/ContactInfo'
import { vatKindDict } from '../../utils/dictionaries'
import RadioButtonsGroup from '../../components/RadioButtonsGroup'
import Requisites from '../../components/Requisites/index'
import { Address, CompanyData } from '../../types/dadata'
import CompanyInfo from '../../components/CompanyInfo'
import getFullName from '../../utils/getFullName'
import Avatar from '../../components/Avatar/index'
import Channels from './Channels.tsx'
import { Card, CardHeader, CardIconButton, CardMenu, CardTitle } from '../../components/Card.tsx'
import LeasingSubjectCategoriesList from '../../components/LeasingSubjectCategoriesList.tsx'
import History from '../../components/History/index.tsx'

const Info: FC = () => {
  const { id } = useParams<'id'>()
  const context = useOutletContext<[any, any, () => void]>()
  const openEditModal = context[2]

  const { data, refetch, loading } = useSourceByIdQuery({ variables: { id: `${id}` }, skip: !id })
  const source = data?.source
  const sourceCompany = source?.company

  const categories = useNodes(source?.leasingSubjectCategories?.edges)
  const contactInfos = useNodes(source?.contactInfos?.edges)
  const selectedEDOs = useNodes(source?.edoTypes?.edges)
  const requisites = useNodes(sourceCompany?.bankDetails?.edges) || []

  const dadata: CompanyData = sourceCompany?.dadata ? sourceCompany.dadata.data : {}
  const address: Address | undefined = dadata?.address

  const hasAccessManagerEdit = useAccessCheck('source.manager.edit')
  const hasAccessSourceEdit = useAccessCheck('source.edit')

  const [managersFormOpen, setManagersFormOpen] = useState(false)
  const [categoriesFormOpen, setCategoriesFormOpen] = useState(false)

  const [refreshDadataMutation, { loading: loadingRefreshDadata }] = useRefreshFromDadataCompanyMutation()
  const [updateSourceEDOs] = useUpdateSourceMutation()
  const [updateSourceVAT] = useUpdateCompanyMutation()

  async function refreshDadata() {
    if (!sourceCompany?.inn) return
    await refreshDadataMutation({ variables: { input: { inn: sourceCompany?.inn } } })
    await refetch()
  }

  const onVATChange = useCallback(
    (vatKind: string) => {
      if (!sourceCompany?._id) return

      updateSourceVAT({
        variables: {
          input: {
            id: `${sourceCompany._id}`,
            vatKind: vatKind as VatKind
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateCompany: {
            __typename: 'updateCompanyPayload',
            company: {
              __typename: 'Company',
              _id: sourceCompany?._id,
              vatKind: vatKind as VatKind
            }
          }
        }
      })
    },
    [sourceCompany?._id, updateSourceVAT]
  )

  const onEDOChange = useCallback<EDOCallback>(
    (data) => {
      if (!source?._id) return

      const edos = data.edos === undefined ? selectedEDOs : data.edos
      const customEDO = data.customEDO === undefined ? source?.edoCustomValue : data.customEDO
      const edoStatus = data.enabled === undefined ? source?.edoStatus : data.enabled

      updateSourceEDOs({
        variables: {
          input: {
            id: `${source._id}`,
            //@ts-ignore
            edoStatus,
            edoTypes: edos && edos.map((o) => `${o._id}`),
            //@ts-ignore
            edoCustomValue: customEDO
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateSource: {
            __typename: 'updateSourcePayload',
            source: {
              __typename: 'Source',
              _id: source?._id,
              //@ts-ignore
              edoStatus,
              edoTypes: {
                __typename: 'EdoTypeConnection',
                edges: edos?.map((o) => ({
                  __typename: 'EdoTypeEdge',
                  node: {
                    ...o,
                    __typename: 'EdoType'
                  }
                }))
              },
              //@ts-ignore
              edoCustomValue: customEDO
            }
          }
        }
      })
    },
    [selectedEDOs, source?._id, source?.edoCustomValue, source?.edoStatus, updateSourceEDOs]
  )

  if (!source) return null

  return (
    <div className='grid grid-cols-2 gap-6'>
      <div className='col-span-1 flex flex-col gap-8'>
        {sourceCompany ? (
          <>
            <CompanyInfo
              dadata={dadata}
              companyName={sourceCompany?.dadata?.value}
              refreshDadata={refreshDadata}
              loading={loadingRefreshDadata || loading}
              lastUpdateName={
                sourceCompany?.lastDadataRequestUser?.surname &&
                sourceCompany?.lastDadataRequestUser?.name &&
                sourceCompany.lastDadataRequestUser.surname + ' ' + sourceCompany.lastDadataRequestUser.name
              }
              lastUpdateDate={sourceCompany?.lastDadataRequestDate}
            />

            <Card>
              <CardHeader>
                <CardTitle>Адрес юридический</CardTitle>
              </CardHeader>

              <div className='px-5 pb-5'>
                <div className='rounded-md bg-surface-primary shadow-card'>
                  <table className='w-full'>
                    <tbody>
                      <tr className='group'>
                        <td className='w-70 rounded-l-xl p-6'>Полный адрес</td>
                        <td className='rounded-r-xl p-6'>{address?.unrestricted_value}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </Card>

            <Requisites
              targetId={sourceCompany?._id}
              targetType='company'
              requisites={requisites}
              onDone={() => refetch()}
            />

            <Card>
              <CardHeader>
                <CardTitle>НДС</CardTitle>
              </CardHeader>

              <div className='px-5 pb-5'>
                <div className='rounded-md bg-surface-primary px-4 py-10 shadow-card'>
                  {hasAccessSourceEdit ? (
                    <RadioButtonsGroup
                      onChange={onVATChange}
                      options={[
                        ['ОСНО', VatKind.Osno],
                        ['УСН', VatKind.Usn]
                      ]}
                      checkedValue={sourceCompany?.vatKind}
                    />
                  ) : (
                    <p className='text-p100 text-grayscale-0'>
                      {sourceCompany.vatKind ? vatKindDict[sourceCompany?.vatKind] : '-'}
                    </p>
                  )}
                </div>
              </div>
            </Card>
          </>
        ) : (
          <Card>
            <CardHeader>
              <CardTitle>Выгрузка по компании</CardTitle>
            </CardHeader>
            <div className='px-5 pb-5'>
              <div className='rounded-md bg-surface-primary shadow-card'>
                <div className='px-8 pt-5 font-display text-h300 font-normal'>Необходимо заполнить:</div>
                <button className='block w-full p-5 text-left' onClick={openEditModal}>
                  <div className='flex items-center justify-between rounded-lg bg-red-200 px-6 py-6 font-medium text-red-50'>
                    ИНН компании
                    <MoreIcon />
                  </div>
                </button>
              </div>
            </div>
          </Card>
        )}

        <EDO
          enabled={source?.edoStatus}
          selectedEDOs={selectedEDOs}
          customEDO={source?.edoCustomValue}
          onChange={onEDOChange}
          hasAccess={hasAccessSourceEdit}
        />
      </div>

      <div className='col-span-1 flex flex-col gap-8'>
        <Card>
          <CardHeader>
            <CardTitle>Менеджеры Fera</CardTitle>
            <CardMenu>
              {hasAccessManagerEdit && (
                <CardIconButton
                  onClick={() => {
                    setManagersFormOpen(true)
                  }}
                >
                  <EditIcon />
                </CardIconButton>
              )}
            </CardMenu>
          </CardHeader>
          <div className='px-5 pb-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              <table className='conte w-full'>
                <tbody>
                  <tr className='group'>
                    <td className='p-6 first:rounded-l-md last:rounded-r-md'>Менеджер по лизингу</td>
                    <td className='p-6 text-right first:rounded-l-md last:rounded-r-md'>
                      <div className='flex items-center'>
                        <Avatar className='ml-0 mr-5' userId={source?.user?._id} />
                        {getFullName(source.user) || '—'}
                      </div>
                    </td>
                  </tr>
                  <tr className='group border-t-1 border-grayscale-400'>
                    <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Менеджер по развитию</td>
                    <td className='px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      <div className='flex items-center'>
                        <Avatar className='ml-0 mr-5' userId={source?.bdmUser?._id} />
                        {getFullName(source.bdmUser) || '—'}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Категории</CardTitle>
            <CardMenu>
              {hasAccessSourceEdit && (
                <CardIconButton onClick={() => setCategoriesFormOpen(true)}>
                  <EditIcon />
                </CardIconButton>
              )}
            </CardMenu>
          </CardHeader>
          <div className='px-5 pb-5'>
            <LeasingSubjectCategoriesList categories={categories} />
          </div>
        </Card>

        <ContactInfo
          onDone={() => refetch()}
          contactInfos={contactInfos}
          targetId={source?._id}
          targetType={ContactInfoKind.Source}
          abilityCreate='source.contact_info.create'
          abilityEdit='source.contact_info.edit'
          title='Контакты источника'
        />

        <Tasks targetId={source?._id} targetType={TaskTargetType.Source} />

        {!!id && <Comments targetId={parseInt(id)} targetType={CommentTargetType.Source} />}

        <Channels sourceId={source?._id} />

        <History targetId={source?._id} targetType={HistoryTargetType.Source} />
      </div>

      <Modal open={managersFormOpen} setOpen={setManagersFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ManagersForm
            source={source}
            onDone={() => {
              refetch()
              setManagersFormOpen(false)
            }}
          />
        </div>
      </Modal>

      <Modal open={categoriesFormOpen} setOpen={setCategoriesFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <CategoriesForm
            onDone={() => {
              refetch()
              setCategoriesFormOpen(false)
            }}
            source={source}
          />
        </div>
      </Modal>
    </div>
  )
}

export default Info
