import { FC } from 'react'
import { ReactComponent as TickIcon } from '../../svg/icons/tick-xs.svg'
import { FieldError } from 'react-hook-form'
import Select from '../Select.tsx'
import { Requisite } from './index.tsx'
import { CompanyRequisites } from '../../containers/Deal/models'

interface RequisiteInputProps {
  onChange: (value: CompanyRequisites) => void
  value: CompanyRequisites
  error?: string | FieldError
  bankDetails?: Requisite[]
}

const RequisiteInput: FC<RequisiteInputProps> = ({ value, error, bankDetails, onChange }) => (
  <Select
    type='text'
    label='Реквизиты компании'
    placeholder='Выберите реквизиты'
    autoComplete='off'
    value={value?.bankName || ''}
    error={error}
  >
    <h3 className='px-12 pt-6'>Выберите реквизиты</h3>
    {bankDetails?.map((bankDetail) => (
      <li
        key={bankDetail?._id}
        onClick={() =>
          onChange({
            ...value,
            bankName: bankDetail?.name || '',
            accountNumber: bankDetail?.account || '',
            correspondentNumber: bankDetail?.correspondentAccount || '',
            bik: bankDetail?.bic || ''
          })
        }
        className='hover:bg-grayscale-450 px-12 py-5 cursor-pointer'
      >
        <div className='flex justify-between items-center'>
          <div className='text-grayscale-0 mb-1'>
            <p>{bankDetail?.name}</p>
            <p>Расч. счёт: {bankDetail?.account}</p>
          </div>
          {value?.bankName === bankDetail?.name && value?.accountNumber === bankDetail?.account && (
            <div className='rounded-full bg-red-100/5 text-red-100 px-5 py-2 flex items-center ml-5'>
              <TickIcon />
            </div>
          )}
        </div>
      </li>
    ))}
  </Select>
)

export default RequisiteInput
