import { FC, useRef, useLayoutEffect } from 'react'
import { animate, spring } from 'motion'

interface BubbleProps {
  count?: number
}

const Bubble: FC<BubbleProps> = ({ count = 0 }) => {
  const ref = useRef<HTMLDivElement>(null)
  const visible = useRef<boolean | undefined>()

  useLayoutEffect(() => {
    if (!ref.current) return
    const nextVisible = count > 0

    if (visible.current === undefined || nextVisible !== visible.current) {
      if (nextVisible) {
        animate(
          ref.current,
          {
            opacity: 1,
            y: 0
          },
          {
            easing: spring({
              stiffness: 500,
              damping: 20
            })
          }
        )
      } else {
        animate(
          ref.current,
          {
            opacity: 0,
            y: 40
          },
          visible.current === undefined
            ? {
                duration: 0
              }
            : {
                easing: spring({
                  stiffness: 200,
                  damping: 20,
                  velocity: -500
                })
              }
        )
      }
    }

    visible.current = nextVisible
  }, [count])

  return (
    <div className='absolute w-10 h-10 top-0 right-0 translate-x-1/2 -translate-y-1/2'>
      <div ref={ref} className='w-10 h-10 rounded-full bg-red-50 text-white-0 flex items-center justify-center'>
        {count}
      </div>
    </div>
  )
}

export default Bubble
