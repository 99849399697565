import { FC, useState } from 'react'
import { ReactComponent as EditIcon } from '../../../svg/icons/edit.svg'
import { ReactComponent as TickIcon } from '../../../svg/icons/tick-xs.svg'
import { useForm } from 'react-hook-form'
import SubmitButton from '../../../components/SubmitButton/index'
import { FundingSource } from '../../FundingSources'
import {
  useFundingSourceIdsByDealIdSuspenseQuery,
  useFundingSourcesSuspenseQuery,
  useFundingTranchesQuery,
  useUpdateDealFundingSourcesMutation
} from '../../../graphql/schema'
import useNodes from '../../../hooks/useNodes'
import MultipleSelect from '../../../components/Select'
import c from 'clsx'

interface FormProps {
  dealId: string
  onDone?: () => void
}

const FundingSourcesForm: FC<FormProps> = ({ dealId, onDone }) => {
  const { handleSubmit } = useForm()
  const [updateDealFundingSources, { loading }] = useUpdateDealFundingSourcesMutation()

  const { data } = useFundingSourceIdsByDealIdSuspenseQuery({
    variables: { dealId: `${dealId}` },
    skip: !dealId
  })
  const selectedFundingSourceIds = useNodes(data?.dealFundingSources?.edges)?.map((i) => i.fundingSource.id)

  const { data: fundingSourcesData } = useFundingSourcesSuspenseQuery()
  const fundingSources = useNodes(fundingSourcesData?.fundingSources?.edges).sort((fundingSource) =>
    fundingSource.name === 'Собственные средства' ? -1 : 1
  )

  const [fundingSourcesSelected, setFundingSourcesSelected] = useState<FundingSource[]>(
    fundingSources.filter((fundingSource) => selectedFundingSourceIds?.includes(fundingSource.id))
  )

  const { data: fundingTranchesData } = useFundingTranchesQuery({
    variables: { dealId: `${dealId}`, first: 9999 },
    skip: !dealId
  })
  const fundingSourceIdsHasTranches = useNodes(fundingTranchesData?.fundingTranches?.edges).map(
    (t) => t.fundingSource.id
  )

  const onSubmit = async () => {
    if (loading) return

    await updateDealFundingSources({
      variables: {
        input: {
          dealId: dealId,
          fundingSourceIds: fundingSourcesSelected.map((i) => i.id)
        }
      }
    }).then(() => {
      if (onDone) onDone()
    })
  }

  return (
    <section className='md: w-[448px] p-12'>
      <h1 className='font-display text-h200 mb-12'>Источник финансирования</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 flex flex-col gap-8 relative'>
          <div className='relative'>
            <MultipleSelect
              multiple
              label='Источник финансирования'
              placeholder='Выберите источник финансирования'
              type='text'
              autoComplete='off'
              value={fundingSourcesSelected.map((fundingSource) => fundingSource.name).join(', ')}
            >
              {fundingSources?.map((fundingSource) => (
                <li
                  key={fundingSource.id}
                  onClick={() => {
                    if (fundingSourcesSelected.find((fs) => fs.id === fundingSource.id)) {
                      setFundingSourcesSelected(fundingSourcesSelected.filter((fs) => fs.id !== fundingSource.id))
                    } else {
                      setFundingSourcesSelected((value) => [...value, fundingSource])
                    }
                  }}
                  className={c(
                    'hover:bg-grayscale-450 px-12 py-5 cursor-pointer',
                    fundingSourceIdsHasTranches.includes(fundingSource.id) && 'pointer-events-none'
                  )}
                >
                  <div className='flex justify-between items-center'>
                    <div
                      className={c(
                        'mb-1',
                        fundingSourceIdsHasTranches.includes(fundingSource.id)
                          ? 'text-grayscale-250'
                          : 'text-grayscale-0'
                      )}
                    >
                      {fundingSource.name}
                    </div>
                    {fundingSourcesSelected.some((s) => s.id === fundingSource.id) && (
                      <div className='rounded-full bg-red-100/5 text-red-100 px-5 py-2 flex items-center ml-5'>
                        <TickIcon />
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </MultipleSelect>
          </div>
        </div>
        <SubmitButton loading={loading}>
          <EditIcon className='mr-5' />
          Изменить
        </SubmitButton>
      </form>
    </section>
  )
}

export default FundingSourcesForm
