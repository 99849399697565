import { useMemo } from 'react'

const getYearDifference = (date1: Date, date2: Date): number => {
  const diff = date1.getFullYear() - date2.getFullYear()
  if (
    date2.getMonth() > date1.getMonth() ||
    (date2.getMonth() === date1.getMonth() && date2.getDate() > date1.getDate())
  ) {
    return diff - 1
  }
  return diff
}

export const checkPassportExpiry = (age: number, issueAgeDiff: number): string => {
  if (age < 20) {
    // ок
    return ''
  }
  if (age > 20 && age < 45) {
    if (issueAgeDiff < 20) {
      return 'Паспорт просрочен'
    }
    if (issueAgeDiff < 45) {
      // ок
      return ''
    }
    return 'Неверные данные'
  }
  if (issueAgeDiff < 45) {
    return 'Паспорт просрочен'
  }
  // ок
  return ''
}

const useCheckPassportExpiry = (birthDate?: string, issueDate?: string): string => {
  return useMemo(() => {
    if (!birthDate || !issueDate) {
      return ''
    }

    const today = new Date()
    const birthDateTime = new Date(birthDate)
    const issueDateTime = new Date(issueDate)

    const age = getYearDifference(today, birthDateTime)
    const issueAgeDiff = getYearDifference(issueDateTime, birthDateTime)

    return checkPassportExpiry(age, issueAgeDiff)
  }, [birthDate, issueDate])
}

export default useCheckPassportExpiry
