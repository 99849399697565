import { useEffect } from 'react'
import {
  Task,
  TaskConnection,
  TaskCreatedDocument,
  TaskCreatedSubscription,
  TaskDeletedDocument,
  TaskDeletedSubscription,
  TaskEdge,
  TaskState,
  TaskTargetType,
  TaskUpdatedDocument,
  TaskUpdatedSubscription
} from '../../../graphql/schema'
import { SubscribeToMoreOptions } from '@apollo/client/core'

export default function useTasksUpdates(
  subscribeToMore: (options: SubscribeToMoreOptions) => () => void,
  userId?: number,
  targetId?: number,
  targetType?: TaskTargetType
): void {
  useEffect(() => {
    const taskCreatedUnsubscribe = subscribeToMore({
      document: TaskCreatedDocument,
      variables: { userId, targetId, targetType },
      updateQuery: (
        prev: { tasks: TaskConnection },
        { subscriptionData }: { subscriptionData: { data: TaskCreatedSubscription } }
      ) => {
        if (!subscriptionData.data) return prev

        const tasksEdges: TaskEdge[] = prev.tasks.edges?.length ? [...prev.tasks.edges] : []
        const taskCreated: Task = subscriptionData.data.taskCreated
        const existTaskEdge = tasksEdges.find((edge) => edge?.node?.id === taskCreated?.id)
        if (existTaskEdge) return prev

        const taskCreatedEdge: TaskEdge = {
          __typename: 'TaskEdge',
          node: taskCreated,
          cursor: ''
        }
        const taskCreatedDueDate = new Date(taskCreated?.dueDate)
        const targetTasksEdge = tasksEdges.find((edge) => new Date(edge?.node?.dueDate) > taskCreatedDueDate)
        const targetTasksEdgeIndex = targetTasksEdge ? tasksEdges.indexOf(targetTasksEdge) : -1
        if (targetTasksEdgeIndex !== -1) {
          tasksEdges.splice(targetTasksEdgeIndex, 0, taskCreatedEdge)
        } else {
          tasksEdges.push(taskCreatedEdge)
        }

        return Object.assign({}, prev, {
          tasks: {
            ...prev.tasks,
            edges: tasksEdges
          }
        })
      }
    })

    const taskUpdatedUnsubscribe = subscribeToMore({
      document: TaskUpdatedDocument,
      variables: { userId, targetId, targetType },
      updateQuery: (
        prev: { tasks: TaskConnection },
        { subscriptionData }: { subscriptionData: { data: TaskUpdatedSubscription } }
      ) => {
        if (!subscriptionData.data) return prev

        const taskUpdated = subscriptionData.data.taskUpdated

        if (taskUpdated.state === TaskState.Moved) {
          return Object.assign({}, prev, {
            tasks: {
              ...prev.tasks,
              edges: (prev.tasks.edges || []).filter((edge) => edge?.node?.id !== taskUpdated.id)
            }
          })
        }

        return Object.assign({}, prev, {
          tasks: {
            ...prev.tasks,
            edges: (prev.tasks.edges || []).map((edge) =>
              edge?.node?.id === taskUpdated.id ? { ...edge, node: taskUpdated } : edge
            )
          }
        })
      }
    })

    const taskDeletedUnsubscribe = subscribeToMore({
      document: TaskDeletedDocument,
      variables: { userId, targetId, targetType },
      updateQuery: (
        prev: { tasks: TaskConnection },
        { subscriptionData }: { subscriptionData: { data: TaskDeletedSubscription } }
      ) => {
        if (!subscriptionData.data) return prev

        const taskDeleted = subscriptionData.data.taskDeleted

        return Object.assign({}, prev, {
          tasks: {
            ...prev.tasks,
            edges: (prev.tasks.edges || []).filter((edge) => edge?.node?.id !== taskDeleted.id)
          }
        })
      }
    })

    return () => {
      taskCreatedUnsubscribe()
      taskUpdatedUnsubscribe()
      taskDeletedUnsubscribe()
    }
  }, [userId, targetId, targetType, subscribeToMore])
}
