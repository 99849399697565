import * as React from 'react'
import Input from '../Input'
import { SubmitHandler, useForm, UseFormReturn } from 'react-hook-form'
import { ReactComponent as PlusIcon } from '../../svg/icons/plus.svg'
import { useAddContactMutation } from '../../graphql/schema'
import { EMAIL_PATTERN, PHONE_MASK } from '../../utils/constants'
import MaskedInput from '../MaskedInput'
import { handleBackendErrorsToForm } from '../../utils/backendErrorUtils'
import { ApolloError } from '@apollo/client'
import SubmitButton from '../SubmitButton'

type Inputs = {
  phone?: string
  fio?: string
  email?: string
}

const preparePhoneForApi = (phone?: string) => (phone?.length ? '+' + phone.replace(/\D/g, '') : '')
interface CreateContactFormProps {
  context?: UseFormReturn<Inputs, object>
  onDone?: (contactId?: number) => void | Promise<void>
  suggestedValues: { fio?: string; email?: string; phone?: string }
  requiredPhone?: boolean
}

const CreateContactForm: React.FC<CreateContactFormProps> = ({
  onDone,
  context,
  suggestedValues,
  requiredPhone = false
}) => {
  const [addContact, { loading }] = useAddContactMutation()
  const form = useForm<Inputs>({ context })

  const { register, handleSubmit, formState, setValue, setError, setFocus } = context || form

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await addContact({
      variables: {
        input: {
          ...data,
          fio: data.fio!,
          phone: preparePhoneForApi(data.phone)
        }
      }
    })
      .then((result) => {
        if (onDone) onDone(result.data?.addContact?.contact?._id)
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }
  return (
    <section className='md: w-[448px] p-12'>
      <h1 className='font-display text-h200'>Новый контакт</h1>
      <button
        onClick={() => {
          setValue('phone', suggestedValues.phone)
          setFocus('phone')
          if (suggestedValues.fio) setValue('fio', suggestedValues.fio)
          setValue('email', suggestedValues.email)
        }}
        className='my-8 px-14 py-6 rounded-full bg-red-100 hover:bg-red-50 active:bg-red-150 focus-visible:outline-red-100 focus-visible:outline-2 focus-visible:outline ring-red-100 text-white-0 text-p200 leading-full overflow-hidden relative flex items-center justify-center'
      >
        Заполнить из заявки
      </button>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 flex flex-col gap-8'>
          <MaskedInput
            label='Телефон'
            inputMode='tel'
            type='text'
            mask={PHONE_MASK}
            placeholder='+7 987 654 32 10'
            {...register('phone', {
              required: requiredPhone,
              minLength: {
                value: 16,
                message: 'Введите номер телефона полностью'
              }
            })}
            error={formState.errors.phone}
          />
          <Input label='ФИО' type='text' {...register('fio', { required: true })} error={formState.errors?.fio} />
          <Input
            label='Email'
            type='text'
            placeholder='mail@example.com'
            {...register('email', {
              pattern: {
                value: EMAIL_PATTERN,
                message: 'Некорректный E-mail'
              }
            })}
            error={formState.errors?.email}
          />
        </div>
        <SubmitButton loading={loading}>
          <PlusIcon className='mr-5' />
          Создать
        </SubmitButton>
      </form>
    </section>
  )
}

export default CreateContactForm
