import UUIDJS from 'uuidjs'

export const sum = (s: string) => {
	let acc = 0
	for (let i = 0; i < s.length; i++) {
		acc += parseInt(s[i], 16) * ((i % 10) + 1)
	}
	return (acc % 16).toString(16)
}

export const UUIDLeasing: () => string = () => {
	const UUID: UUIDJS = UUIDJS.genV1()

	return UUID.toString() + '-' + sum(UUID.hexNoDelim)
}


export const uuid = UUIDJS.genV4
