import { useState } from 'react'
import { Transition } from '@headlessui/react'
import { Kind } from '../../graphql/schema.tsx'
import { ReactComponent as LoadingIcon } from '../../svg/icons/loading.svg'
import { ReactComponent as PlusIcon } from '../../svg/icons/plus.svg'
import { ReactComponent as CircleCheckIcon } from '../../svg/icons/circleCheck.svg'
import { formatNumber } from '../../utils/formatNumber'
import getVatKind from '../../utils/getVatKind.ts'
import c from 'clsx'

export interface AddDealReconciliation {
  _id: number
  amount: number
  advanceRate: number
  comissionRate: number
  durationMonths: number
  insuranceRate: number
  interestRate: number
  kind: Kind
}

interface RecSelectFormProps {
  onDone: (rec: AddDealReconciliation) => void | Promise<void>
  recs: AddDealReconciliation[]
}

const RecSelectForm: React.FC<RecSelectFormProps> = ({ recs, onDone }) => {
  const [loading, setLoading] = useState(false)
  const [selectedRec, setSelectedRec] = useState<AddDealReconciliation>()

  return (
    <section className='w-[448px] py-12'>
      <h1 className='mb-12 px-12 font-display text-h200'>Выбрать согласование для сделки</h1>
      <form>
        <div className='mb-12 flex flex-col'>
          {recs.map((rec) => (
            <div
              key={rec?._id}
              className={c('cursor-pointer px-12 py-6', selectedRec?._id === rec?._id && 'bg-grayscale-450')}
              onClick={() => setSelectedRec(rec)}
            >
              <div className='mb-3 flex items-center gap-x-5 font-display text-h200 tabular-nums'>
                <h3>Согласование</h3>
                <div className='h-2 w-2 rounded-full bg-grayscale-250' />
                <span>{formatNumber(rec?.amount)} ₽</span>
                <CircleCheckIcon className='text-green-600' />
                <CircleCheckIcon className='text-green-600' />
              </div>
              <div className='flex items-center justify-between gap-5 text-p200 tabular-nums text-grayscale-150'>
                <div>{rec?.durationMonths} мес.</div>
                <div className='h-2 w-2 rounded-full bg-grayscale-250' />
                <div>A - {rec?.advanceRate}%</div>
                <div className='h-2 w-2 rounded-full bg-grayscale-250' />
                <div>C - {rec?.interestRate}%</div>
                <div className='h-2 w-2 rounded-full bg-grayscale-250' />
                <div>{getVatKind(rec?.kind)}</div>
              </div>
            </div>
          ))}
        </div>
        <div className='px-12'>
          <button
            type='button'
            onClick={async () => {
              if (loading || !selectedRec) return
              setLoading(true)
              await onDone(selectedRec)
            }}
            disabled={!selectedRec}
            className='relative h-27 w-full overflow-hidden rounded-xl bg-red-100 px-20 text-p200 font-semibold leading-full text-white-0 ring-red-100 hover:bg-red-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-red-100 active:bg-red-150 disabled:bg-grayscale-450 disabled:text-grayscale-250'
          >
            <Transition
              as='div'
              show={!loading}
              className='relative flex transform items-center justify-center transition-all duration-300'
              enterFrom='-translate-x-1/2 opacity-0'
              enterTo='translate-x-0 opacity-100'
              leaveTo='translate-x-1/2 opacity-0'
            >
              <PlusIcon className='mr-5' />
              Создать
            </Transition>
            <Transition
              as='div'
              show={loading}
              className='absolute inset-0 flex transform items-center justify-center transition-all duration-300'
              enterFrom='-translate-x-1/4 opacity-0'
              enterTo='translate-x-0 opacity-100'
              leaveTo='translate-x-1/4 opacity-0'
            >
              <LoadingIcon className='animate-spin' />
            </Transition>
          </button>
        </div>
      </form>
    </section>
  )
}

export default RecSelectForm
