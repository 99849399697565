import { FC, useCallback, useEffect, useMemo } from 'react'
import FilterForm from '../../components/Filter/Form'
import {
  EngagePartnerStatus,
  SuppliersListQueryVariables,
  TagEntity,
  useCategoriesQuery,
  UserRoles
} from '../../graphql/schema'
import useNodes from '../../hooks/useNodes'
import GroupSelectInput, { Option } from '../../components/GroupSelectInput'
import { makeVar, useReactiveVar } from '@apollo/client'
import { throttle } from 'throttle-debounce'
import Select from '../../components/SelectInput'
import { supplierStatusDict } from '../../utils/dictionaries.ts'
import { SupplierStatusCircle } from '../Supplier/SupplierStatus.tsx'
import ManagerInput from '../../components/Forms/Inputs/ManagerInput.tsx'
import TagsInput from '../../components/Tags/TagsInput.tsx'
import { Tag } from '../../components/Tags/Tag.tsx'

const statuses = [
  {
    value: EngagePartnerStatus.Pending,
    name: supplierStatusDict[EngagePartnerStatus.Pending]
  },
  {
    value: EngagePartnerStatus.InProgress,
    name: supplierStatusDict[EngagePartnerStatus.InProgress]
  },
  {
    value: EngagePartnerStatus.Enlisted,
    name: supplierStatusDict[EngagePartnerStatus.Enlisted]
  },
  {
    value: EngagePartnerStatus.Hold,
    name: supplierStatusDict[EngagePartnerStatus.Hold]
  }
]

export interface SupplierFilterState {
  statuses: typeof statuses
  bdmUsers: number[]
  categories: Option[]
  query?: string
  id?: number
  tagsList: Tag[]
}

export interface FilterFormProps {
  state: SupplierFilterState
  onChange: (state: SupplierFilterState) => void
}

const supplierFilterStateVar = makeVar<SupplierFilterState>({
  statuses: [],
  bdmUsers: [],
  categories: [],
  tagsList: []
})
const supplierQueryVar = makeVar<string>('')

const SupplierFilterForm: FC<FilterFormProps> = ({ state, onChange }) => {
  const { data: categoriesData } = useCategoriesQuery()
  const categories = useNodes(categoriesData?.leasingSubjectCategories?.edges)
  const options = useMemo(
    () =>
      categories?.map((cat) => ({
        name: cat.name,
        value: cat._id,
        group: cat.categoryGroup?.name
      })) || [],
    [categories]
  )

  const updateSelectedCategories = useCallback(
    (selectedCategories: Option[]) => {
      onChange({ ...state, categories: [...selectedCategories] })
    },
    [onChange, state]
  )

  return (
    <FilterForm
      onReset={() => {
        onChange({
          ...state,
          categories: [],
          tagsList: []
        })
      }}
      title='Фильтр поставщиков'
    >
      <div className='group relative mb-10'>
        <Select
          label='Привлечение'
          placeholder='Выберите статусы'
          options={statuses}
          renderOption={(opt) => (
            <div className='flex gap-6 overflow-hidden'>
              <SupplierStatusCircle bg={false} status={opt.value} />
              <span className='overflow-hidden overflow-ellipsis whitespace-nowrap'>{opt.name}</span>
            </div>
          )}
          onChange={(selectedStatuses) => onChange({ ...state, statuses: [...selectedStatuses] })}
          selectedOptions={state.statuses}
          value={state.statuses.map((s) => s.name).join(', ')}
        />
      </div>

      <div className='group relative mb-10'>
        <ManagerInput
          multiple
          selected={state.bdmUsers}
          onChange={(bdmUsers) => onChange({ ...state, bdmUsers })}
          label='Менеджеры по привлечению'
          placeholder='Выберите менеджеров'
          role={UserRoles.RoleBdm}
        />
      </div>

      <div className='group relative mb-10'>
        <GroupSelectInput
          label='Категория'
          placeholder='Выберите категории'
          options={options}
          selectedOptions={state.categories}
          onChange={updateSelectedCategories}
        />
      </div>

      <div className='group relative mb-10'>
        <TagsInput
          multiple
          entityType={TagEntity.SupplierCompany}
          selected={state.tagsList}
          onChange={(tag) => onChange({ ...state, tagsList: tag })}
          label='Теги'
          placeholder='Выберите теги'
        />
      </div>
    </FilterForm>
  )
}

const setQuery = throttle(500, (query: string) => {
  const isNumber = query.length < 10 && /^\d+$/.test(query)
  supplierFilterStateVar({
    ...supplierFilterStateVar(),
    query,
    ...(isNumber && { id: parseInt(query) })
  })
})

export const useSupplierFilter = () => {
  const filterState = useReactiveVar(supplierFilterStateVar)
  const query = useReactiveVar(supplierQueryVar)

  useEffect(() => {
    setQuery(query)
  }, [query])

  const variables = useMemo<SuppliersListQueryVariables>((): SuppliersListQueryVariables => {
    if (filterState?.query?.length) {
      return {
        query: filterState.query,
        id: filterState.id
      }
    }
    return {
      statuses: filterState.statuses.map((status) => status.value),
      bdmUsers: filterState.bdmUsers,
      categories: filterState.categories.map((category) => category.value.toString()),
      tagsList: filterState.tagsList.map((tag) => tag._id)
    }
  }, [filterState])

  const filterActive = Boolean(
    filterState.statuses.length > 0 ||
      filterState.bdmUsers.length > 0 ||
      filterState.categories.length > 0 ||
      filterState.tagsList.length > 0
  )

  return {
    filterState,
    setFilterState: supplierFilterStateVar,
    filterActive,
    variables,
    query,
    setQuery: supplierQueryVar
  }
}

export default SupplierFilterForm
