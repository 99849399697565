import { FC, useEffect, useState } from 'react'
import { ReactComponent as EditIcon } from '../../../svg/icons/edit.svg'
import {
  useAcceptMutation,
  useRejectMutation,
  useUpdateVerificationMutation,
  useUserByIdQuery,
  Verification,
  VerificationState
} from '../../../graphql/schema'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useWindowEvent } from '../../../hooks/useWindowEvent'
import { ApolloError } from '@apollo/client'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils'
import { RadioGroup } from '@headlessui/react'
import { dateFormatter, dateTimeFormatter } from '../../../utils/dateFormatter'
import Textarea from '../../../components/Textarea'
import SubmitButton from '../../../components/SubmitButton'
import c from 'clsx'
import useAccessCheck from '../../../hooks/useAccessCheck'
import Tooltip from '../../../components/Tooltip.tsx'
import { Card, CardHeader, CardMenu, CardTitle } from '../../../components/Card.tsx'

interface ContactInfoResultProps {
  boVerification: Verification
  onDone: () => void
  canAccept?: boolean
}

const CheckResult: FC<ContactInfoResultProps> = ({ boVerification, onDone, canAccept }) => {
  const [state, setState] = useState<VerificationState>(VerificationState.Pending)
  const [formElement, setFormElement] = useState<HTMLFormElement | null>(null)
  const [editBtnElement, setEditBtnElement] = useState<HTMLButtonElement | null>(null)

  const [openCommentForm, setOpenCommentForm] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors }
  } = useForm<{ reviewComment: string }>()

  // делаем неактивным поле с комментарием, когда пользователь кликает вне этого поля
  useWindowEvent('pointerdown', (event) => {
    if (!openCommentForm) return
    if (formElement?.contains(event.target as Node)) return
    if (editBtnElement?.contains(event.target as Node)) return
    setOpenCommentForm(false)
    event.preventDefault()
  })

  useEffect(() => {
    if (boVerification?.reviewComment) {
      setValue('reviewComment', boVerification?.reviewComment)
    }
    if (boVerification?.state) {
      setState(boVerification.state)
    }
  }, [boVerification?.reviewComment, boVerification?.state, setValue])

  const [accept] = useAcceptMutation({
    onCompleted: () => {
      onDone()
    }
  })
  const [reject] = useRejectMutation({
    onCompleted: () => {
      setOpenCommentForm(true)
      onDone()
    }
  })
  const [updateVerification, { loading }] = useUpdateVerificationMutation()

  const { data: userData } = useUserByIdQuery({
    variables: { id: boVerification?.reviewedByUserId?.toString() || '' },
    skip: !boVerification?.reviewedByUserId
  })
  const reviewer = userData?.user

  const hasAccessVerificationDecision = useAccessCheck('contact.verification.decision')

  async function changeVerificationState(state: VerificationState) {
    if (boVerification?.uuid) {
      switch (state) {
        case VerificationState.Accepted:
          await accept({ variables: { uuid: boVerification.uuid } })
          break
        case VerificationState.Rejected:
          await reject({ variables: { uuid: boVerification.uuid } })
          break
      }
    }
  }

  const acceptExpiresAt =
    state === VerificationState.Accepted &&
    boVerification?.acceptExpiresAt &&
    new Date() > new Date(boVerification.acceptExpiresAt) &&
    dateFormatter.format(new Date(boVerification.acceptExpiresAt))

  const onSubmit: SubmitHandler<{ reviewComment: string }> = async (data: { reviewComment: string }) => {
    if (!data || !boVerification) return

    await updateVerification({
      variables: {
        uuid: boVerification?.uuid,
        input: {
          reviewComment: data.reviewComment
        }
      }
    })
      .then(() => {
        setOpenCommentForm(false)
        onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<{ reviewComment: string }>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Решение</CardTitle>
        {acceptExpiresAt && <CardMenu>Просрочено {acceptExpiresAt}</CardMenu>}
      </CardHeader>

      {hasAccessVerificationDecision && (
        <RadioGroup
          className={c(
            'mx-8 mb-5 grid auto-cols-fr grid-flow-col rounded-md',
            !hasAccessVerificationDecision && 'pointer-events-none opacity-50'
          )}
          value={state}
          onChange={(value) => {
            if (value === VerificationState.Accepted) {
              changeVerificationState(VerificationState.Accepted)
            } else {
              changeVerificationState(VerificationState.Rejected)
            }
            setState(value)
          }}
        >
          <RadioGroup.Option value={VerificationState.Accepted} disabled={!canAccept}>
            <Tooltip
              target={
                <div
                  className={c(
                    'm-1 cursor-pointer rounded-md border-1 border-solid border-grayscale-450 px-5 py-5 text-center text-green-600',
                    state === VerificationState.Accepted && 'border-grayscale-350 bg-white-0',
                    !canAccept && 'cursor-not-allowed opacity-50'
                  )}
                >
                  Проверен
                </div>
              }
            >
              {!canAccept && <div className='text-center'>Подгрузите согласие клиента на обработку ПД</div>}
            </Tooltip>
          </RadioGroup.Option>
          <RadioGroup.Option value={VerificationState.Rejected}>
            <div
              className={c(
                'm-1 cursor-pointer rounded-md border-1 border-solid border-grayscale-450 px-5 py-5 text-center text-red-50',
                state === VerificationState.Rejected && 'border-grayscale-350 bg-white-0'
              )}
            >
              Отказано
            </div>
          </RadioGroup.Option>
        </RadioGroup>
      )}
      {reviewer && (
        <div className={c('px-8 text-grayscale-150', hasAccessVerificationDecision ? 'mb-10' : 'mb-5')}>
          Принял решение: {reviewer.surname + ' ' + reviewer.name}{' '}
          {boVerification?.reviewedAt && dateTimeFormatter.format(new Date(boVerification.reviewedAt))}
        </div>
      )}
      {hasAccessVerificationDecision ? (
        boVerification?.state === VerificationState.Rejected && (
          <>
            <div className='mb-6 flex'>
              <h3 className='text-h250 pl-8 font-display'>Комментарий</h3>
              {hasAccessVerificationDecision && (
                <button
                  ref={setEditBtnElement}
                  className='flex items-center text-grayscale-200 hover:text-red-100'
                  onClick={() => setOpenCommentForm(!openCommentForm)}
                >
                  <EditIcon className='ml-5 h-[18px] w-[18px]' title='Редактировать' />
                </button>
              )}
            </div>
            {openCommentForm ? (
              <form ref={setFormElement} onSubmit={handleSubmit(onSubmit)} className='mb-10 px-8'>
                <Textarea {...register('reviewComment')} error={errors.reviewComment} />
                <div className='mt-10 max-w-[250px]'>
                  <SubmitButton loading={loading}>Сохранить</SubmitButton>
                </div>
              </form>
            ) : (
              <h3 className='mb-10 px-8'>{boVerification?.reviewComment}</h3>
            )}
          </>
        )
      ) : (
        <>
          <div className='mb-6 flex items-center justify-between rounded-md bg-grayscale-450 px-8 py-6'>
            <p>Решение</p>
            <p
              className={(() => {
                switch (boVerification?.state) {
                  case VerificationState.Accepted:
                    return 'text-[#62B85A]'
                  case VerificationState.Rejected:
                    return 'text-red-150'
                  default:
                    return 'text-yellow-600'
                }
              })()}
            >
              {(() => {
                switch (boVerification?.state) {
                  case VerificationState.Accepted:
                    return 'Проверен'
                  case VerificationState.Rejected:
                    return 'Отказано'
                  default:
                    return 'Ожидает проверки'
                }
              })()}
            </p>
          </div>
          {boVerification?.reviewComment && <h3 className='mb-6 px-8'>{boVerification?.reviewComment}</h3>}
        </>
      )}
    </Card>
  )
}

export default CheckResult
