import { FC } from 'react'
import c from 'clsx'
import { PaymentCollectionProcessStatus as Status } from '../../graphql/schema'

export interface DealStatusProps {
  status: Status
  className?: string
}
export interface ColorState {
  active: string
  inactive?: string
}

const mapColors: Map<Status, ColorState> = new Map([
  [Status.Warning, { active: '#5392DC', inactive: '#3479CB26' }],
  [Status.Penalty, { active: '#E8BD25', inactive: '#CEAF4126' }],
  [Status.Claim, { active: '#E88035', inactive: '#D06E2626' }],
  [Status.PreparingLawsuit, { active: '#985BE5', inactive: '#985BE526' }],
  [Status.Lawsuit, { active: '#E55BD7', inactive: '#D424C326' }],
  [Status.Finished, { active: '#3FB23D', inactive: '#CDEECD' }]
])

const statusOrder = [...mapColors.keys()]
const circlesCount = 6

const defaultColors = { active: '#D9D9D9', inactive: '#D9D9D9' }

const CollectionStatus: FC<DealStatusProps> = ({ status, className }) => {
  const statusNumber = statusOrder.indexOf(status) + 1

  const { active, inactive } = mapColors.get(status) || defaultColors

  const circles = Array.from({ length: circlesCount }, (_, i) => (
    <div
      key={i}
      className='rounded-full w-[5px] h-[5px]'
      style={{ backgroundColor: statusNumber > i ? active : inactive }}
    />
  ))

  return <div className={c('flex items-center gap-x-[3px]', className)}>{circles}</div>
}

export default CollectionStatus
