import { FC, ReactNode } from 'react'
import { ReactComponent as CrossIcon } from '../../svg/icons/cross-xs.svg'

interface SelectedFilterProps {
  children: ReactNode
  onRemove: () => void
}

const SelectedFilter: FC<SelectedFilterProps> = ({ children, onRemove }) => {
  return (
    <div className='flex items-center font-semibold leading-[18px] text-grayscale-150 justify-center py-5 px-8 rounded-full bg-grayscale-0/5'>
      {children}
      <i className='p-3 ml-2 -mr-2 -my-2'>
        <CrossIcon className='text-grayscale-250 hover:text-red-100 cursor-pointer' onClick={() => onRemove()} />
      </i>
    </div>
  )
}

export default SelectedFilter
