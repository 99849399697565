import { useReactiveVar } from '@apollo/client'
import { jwtVar } from '../graphql/cache'
import parseJWT from '../utils/parseJWT'

/**
 * Использовать там, где нужен только id текущего пользователя
 * @returns
 */
const useCurrentUserId = () => {
  const jwt = useReactiveVar(jwtVar)
  const userId = parseJWT(jwt)?.id
  return userId
}

export default useCurrentUserId
