export interface ContractData {
  id: string
  uid: {
    id: string
    contract_number: string
  }
  contract_amount: {
    currency: string
    sum: string
  }
  // код основания прекращения обязательства
  contract_end: {
    reason: string
    date: string
  }
  // полная стоимость потребительского кредита (займа)
  full_cost: {
    percent: string
    sum: string
    date: string
  }
  deal: {
    category: string
    date: string
    end_date: string
    purpose: string
    ratio: string
    sign_credit: string
    sign_credit_card: string
    sign_deal_cash_source: string
    sign_deal_cash_subject: string
    sign_renovation: string
    type: string
  }
  payments: {
    last_payout_date: string
    last_payout_sum: string
    overdue_day: string
    paid_op_sum: string
    paid_other_sum: string
    paid_percent_sum: string
    paid_sum: string
    payments_deadline_type: string
    size_payments_type: string
  }
  average_payment: {
    sum: string
    date: string
  }
  debt: {
    op_sum: string
    other_sum: string
    percent_sum: string
    // признак наличия задолженности (0 - нет, 1 - да)
    sign: '0' | '1'
    sum: string
  }
  // сведения о просроченной задолженности
  debt_overdue: {
    sum: string
  }
  court: {
    // признак судебного спора или требования (0 - нет, 1 - да)
    sign: '0' | '1'
    // признак наличия судебного акта (0 - нет, 1 - суд принял акт, которым заканчивается рассмотрение дела по существу (в частности, судебное решение или судебный приказ))
    dispute_case?: '0' | '1'
    // сведения о судебном акте (блок отсутствует, если хотя бы одно из полей court.sign или court.dispute_case = 0)
    court_act?: {
      // признак вступления акта в законную силу (0 - нет, 1 - судебный акт вступил в законную силу)
      accepted: '0' | '1'
      date: string
      no: string
      resolution: string
    }
  }
  extra_data: { '@id': string; '@value': string }[]
  collaterals: {
    // sign = 1 говорит нам о том, что залог есть
    sign: '0' | '1'
    // если sign = 1
    collateral?: {
      currency: string
      date: string
      date_assessment: string
      end_date: string
      end_reason: string
      fact_end_date: string
      id: string
      item_type: string
      sum: string
    }
  }
}

export interface ContractsInfo {
  active: {
    count: number
    sum: {
      [key: string]: number
    }
    debtSum: {
      [key: string]: number
    }
    averageSum: {
      [key: string]: number
    }
    maxPaymentOverdue: string
    maxPaymentOverdueSum: number
    contracts: ContractData[]
  }
  closed: {
    count: number
    sum: {
      [key: string]: number
    }
    maxPaymentOverdue: string
    maxPaymentOverdueSum: number
    contracts: ContractData[]
  }
  microloan: {
    count: number
  }
  sold: {
    count: number
    contracts: ContractData[]
  }
  soldToCollectors: {
    count: number
    contracts: ContractData[]
  }
  guarantee: {
    count: number
    active: {
      count: number
      contracts: ContractData[]
    }
    closed: {
      count: number
      contracts: ContractData[]
    }
    sumActive: {
      [key: string]: number
    }
    sumClosed: {
      [key: string]: number
    }
  }
  warranty: {
    count: number
    active: {
      count: number
      contracts: ContractData[]
    }
    closed: {
      count: number
      contracts: ContractData[]
    }
    sumActive: {
      [key: string]: number
    }
    sumClosed: {
      [key: string]: number
    }
  }
  terminated: {
    count: number
    contracts: ContractData[]
  }
  refinanced: {
    count: number
    contracts: ContractData[]
  }
  informationStopped: {
    count: number
    contracts: ContractData[]
  }
  badDebt: {
    count: number
    contracts: ContractData[]
  }
  other: {
    count: number
    contracts: ContractData[]
  }
  paymentOverdueDeals: number
  paymentOverdue: {
    [key: string]: {
      count: number
      deals: number
    }
  }
}

export interface ContractSummary {
  '@c_i': string
  cred_max_overdue: string
  delay5: string
  delay30: string
  delay60: string
  delay90: string
  delay_more: string
}

export interface PaymentDiscipline {
  year: number
  value: string[]
}

export interface ClientData {
  birth: {
    country: string
    date: string
    place: string
  }
  doc: {
    country: string
    date: string
    department_code: string
    end_date: string
    number: string
    serial: string
    type: string
  }
  history: {
    doc: ClientHistoryDoc | ClientHistoryDoc[]
    name: ClientHistoryName | ClientHistoryName[]
  }
  inn: {
    code: string
    no: string
  }
  name: {
    first: string
    last: string
    middle: string
  }
  snils: {
    no: string
  }
}

export interface ClientHistoryDoc {
  country: string
  date: string
  department_code: string
  end_date: string
  hist_doc_sign: string
  number: string
  serial: string
  type: string
  who: string
}

export interface ClientHistoryName {
  doc_date: string
  first: string
  hist_name_sign: string
  last: string
  middle: string
}

export const dealTypeDict: Record<string, string> = {
  '1': 'Заем (кредит)',
  '2': 'Заем (кредит) с ипотекой',
  '3': 'Микрозаем',
  '4': 'Кредитная линия с лимитом выдачи',
  '5': 'Кредитная линия с лимитом задолженности',
  '6': 'Комбинированная кредитная линия с лимитом выдачи и лимитом задолженности',
  '7': 'Кредит «овердрафт» (кредитование счета)',
  '8': 'Синдицированный заем (кредит)',
  '9': 'Жилищный кредит (кроме кредита с ипотекой)',
  '10': 'Кредит с ипотекой',
  '11': 'Кредит на приобретение автотранспортного средства',
  '12': 'Кредит на приобретение транспортного средства за исключением кредита на приобретение автотранспортного средства',
  '13': 'Необеспеченный микрозаем',
  '14': 'Иной необеспеченный заем',
  '15': 'Жилищный заем (кроме займа с ипотекой)',
  '16': 'Заем с ипотекой',
  '17': 'Заем на приобретение автомобиля',
  '99': 'Иной заем (кредит)'
}

export const dealCategoryDict: Record<string, string> = {
  '1': 'Договор займа (кредита)',
  '2': 'Договор лизинга',
  '3': 'Независимая гарантия',
  '4': 'Поручительство',
  '5': 'Договор с элементами займа, поручительства, гарантии или лизинга (смешанный договор)',
  '6': 'Независимая гарантия по займу (кредиту)',
  '7': 'Независимая гарантия по лизингу',
  '8': 'Иная независимая гарантия',
  '9': 'Поручительство по займу (кредиту)',
  '10': 'Поручительство по лизингу',
  '11': 'Поручительство по независимой гарантии',
  '12': 'Поручительство по поручительству',
  '13': 'Иное поручительство'
}

export const paymentOverdueStateDict: Record<string, string> = {
  '1': '1 - 5',
  '2': '6 - 29',
  '3': '30 - 59',
  '4': '60 - 89',
  '5': '90 - 119',
  '6': '120 - 149',
  '7': '150 - 179',
  '8': '180 - 209',
  '9': '210 - 239',
  A: '>= 240'
}

// Виды предметов залога и неденежных предоставлений по сделке
export const collateralItemType: Record<string, string> = {
  '1': 'Недвижимость, за исключением судов и космических объектов',
  '1.1': 'Здание',
  '1.2': 'Сооружение',
  '1.3': 'Помещение',
  '1.4': 'Машино-место',
  '1.5': 'Объект незавершенного строительства',
  '1.6': 'Земельный участок',
  '1.7': 'Единый недвижимый комплекс',
  '1.8': 'Имущественный комплекс',

  '2': 'Транспортные средства',
  '2.1': 'Самоходная машина (указывается для транспортного средства, имеющего паспорт самоходной машины)',
  '2.2': 'Иное транспортное средство',

  '3': 'Машины и оборудование',
  '3.1': 'Сельскохозяйственное оборудование',
  '3.2': 'Оборудование для строительства',
  '3.3': 'Оборудование для добычи полезных ископаемых',
  '3.4': 'Оборудование нефтеперерабатывающей и нефтехимической промышленности',
  '3.5': 'Оборудование черной и цветной металлургии',
  '3.6': 'Оборудование металлообрабатывающее',
  '3.7': 'Оборудование деревообрабатывающее и целлюлозно-бумажной промышленности',
  '3.8': 'Энергетическое оборудование',
  '3.9': 'Оборудование легкой промышленности',
  '3.10': 'Оборудование пищевой промышленности',
  '3.11': 'Медицинское оборудование',
  '3.12': 'Вычислительное и телекоммуникационное оборудование',
  '3.99': 'Иное',

  '4': 'Воздушные суда',
  '4.1': 'Самолет',
  '4.2': 'Вертолет',
  '4.99': 'Иное',

  '5': 'Плавучие сооружения',
  '5.1': 'Судно для перевозки пассажиров и их багажа',
  '5.2': 'Судно для перевозки грузов или буксировки, а также для хранения грузов',
  '5.3': 'Судно для рыболовства',
  '5.4': 'Судно для иных целей',
  '5.5': 'Плавучее сооружение, не являющееся судном',

  '6': 'Железнодорожный подвижной состав',
  '6.1': 'Локомотив',
  '6.2': 'Самоходная единица специального железнодорожного подвижного состава',
  '6.3': 'Вагон моторвагонного подвижного состава',
  '6.4': 'Грузовой вагон',
  '6.5': 'Пассажирский вагон локомотивной тяги',
  '6.6': 'Вагон высокоскоростного железнодорожного подвижного состава',
  '6.7': 'Несамоходная единица специального железнодорожного подвижного состава',

  '7': 'Космические объекты',
  '7.1': 'Космический объект',

  '8': 'Товары в обороте',
  '8.1': 'Сельскохозяйственная продукция, в том числе продукты растениеводства',
  '8.2': 'Животные заменимые',
  '8.3': 'Продукты пищевые, напитки, изделия табачные',
  '8.4': 'Драгоценные металлы и драгоценные камни',
  '8.5': 'Ювелирные и другие изделия из драгоценных металлов или драгоценных камней',
  '8.6': 'Строительные и отделочные материалы, металлопродукция',
  '8.7': 'Транспортные средства, оборудование, комплектующие и запасные части к ним',
  '8.8': 'Текстиль и изделия текстильные',
  '8.9': 'Топливно-энергетические ресурсы',
  '8.10': 'Вещества химические и продукты химические',
  '8.11': 'Средства лекарственные и материалы, применяемые в медицинских целях',
  '8.99': 'Иное',

  '9': 'Будущие урожаи',
  '9.1': 'Зерновые культуры',
  '9.2': 'Зернобобовые культуры',
  '9.3': 'Масличные культуры',
  '9.4': 'Технические культуры',
  '9.5': 'Кормовые культуры',
  '9.6': 'Бахчевые культуры',
  '9.7': 'Картофель',
  '9.8': 'Овощи',
  '9.9': 'Многолетние насаждения',
  '9.99': 'Иное',

  '10': 'Аффинированные драгоценные металлы в слитках',
  '10.1': 'Золото',
  '10.2': 'Серебро',
  '10.3': 'Платина',
  '10.4': 'Палладий',

  '11': 'Исключительные права на результат интеллектуальной деятельности',
  '11.1': 'Произведение науки, литературы или искусства',
  '11.2': 'Программа для электронных вычислительных машин (ЭВМ)',
  '11.3': 'База данных',
  '11.4': 'Изобретение',
  '11.5': 'Секрет производства (ноу-хау)',
  '11.6': 'Товарный знак или знак обслуживания',
  '11.99': 'Иное',

  '12': 'Права по договору банковского счета',
  '12.1':
    'Права в отношении всей денежной суммы на залоговом счете в любой момент в течение времени действия договора залога',
  '12.2': 'Права в отношении денежной суммы, размер которой указан в договоре залога',

  '13': 'Доля в уставном капитале общества с ограниченной ответственностью',
  '13.1': 'Доля в уставном капитале общества с ограниченной ответственностью',

  '14': 'Векселя',
  '14.1': 'Вексель',

  '15': 'Эмиссионные ценные бумаги',
  '15.1': 'Акция',
  '15.2': 'Облигация',
  '15.3': 'Опцион эмитента',
  '15.4': 'Депозитарная расписка',
  '15.99': 'Иное',

  '16': 'Инвестиционные паи',
  '16.1': 'Инвестиционный пай',

  '17': 'Ипотечные сертификаты участия',
  '17.1': 'Ипотечный сертификат участия',

  '18': 'Прочие ценные бумаги',
  '18.1': 'Закладная',
  '18.2': 'Депозитный сертификат',
  '18.3': 'Сберегательный сертификат',
  '18.99': 'Иное',

  '19': 'Прочие имущественные права',
  '19.1': 'Право аренды или пользования',
  '19.2': 'Права участника долевого строительства',
  '19.3': 'Имущественные права на недвижимое имущество',
  '19.4': 'Право требования',
  '19.99': 'Иное',

  '20': 'Прочие движимые вещи',
  '20.1': 'Животное, индивидуально определенное',
  '20.2': 'Монета, содержащая драгоценные металлы',
  '20.3': 'Предмет бытового использования (бытовая техника, мебель и другое)',
  '20.99': 'Иное',

  '21': 'Все имущество залогодателя'
}
