const isPropValuesEqual = <T extends Record<string, any>>(obj1: T, obj2: T, props: (keyof T)[]) => {
  return props.every((prop) => obj1[prop] === obj2[prop])
}

/**
 * function that dynamically helps to get rid of duplicates based on selected properties
 */
export const getUniqueItemsByProperties = <T extends Record<string, any>>(items: T[], props: (keyof T)[]) => {
  return items.filter((item, index, self) => index === self.findIndex((t) => isPropValuesEqual(t, item, props)))
}

export default getUniqueItemsByProperties
