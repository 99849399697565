
const parseJWT = (token?: string) => {
  if (!token) return undefined
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    const payload = JSON.parse(atob(base64))
    if (payload.id) {
      return payload as JWTPayload
    }
  } catch (e) {
    return undefined
  }
}

export default parseJWT
