import { FC, useEffect, useMemo } from 'react'
import { BankOperationState, BankOperationsQueryVariables, BankOperationKind } from '../../graphql/schema'
import { Popover, RadioGroup } from '@headlessui/react'
import { ReactComponent as CloseIcon } from '../../svg/icons/cross.svg'
import c from 'clsx'
import { bankOperationStateDict } from '../../utils/dictionaries'
import { makeVar, useReactiveVar } from '@apollo/client'
import { throttle } from 'throttle-debounce'

export interface FilterFormProps {
  state: BankOperationFilterState
  onChange: (state: BankOperationFilterState) => void
}

export interface BankOperationFilterState {
  state: BankOperationState
  operational?: boolean
  query?: string
}

const emptyState: BankOperationFilterState = {
  state: BankOperationState.Pending,
  operational: false
}

const bankOperationFilterStateVar = makeVar<BankOperationFilterState>(emptyState)
const bankOperationQueryVar = makeVar<string>('')

const options: Array<[string, BankOperationState | 'operational']> = [
  [bankOperationStateDict[BankOperationState.Pending], BankOperationState.Pending],
  [bankOperationStateDict[BankOperationState.Processed], BankOperationState.Processed],
  ['Операционные платежи', 'operational']
]

const BankOperationsFilterForm: FC<FilterFormProps> = ({ state, onChange }) => {
  return (
    <section className='relative p-10'>
      <h2 className='mb-10 font-display text-h200'>Фильтр платежей</h2>
      <Popover.Button className='absolute right-0 top-0 p-10'>
        <CloseIcon className='text-grayscale-200 hover:text-red-100' />
      </Popover.Button>

      <div className='group relative'>
        <RadioGroup
          className='text-p200 text-grayscale-150'
          value={state.operational ? 'operational' : state.state}
          onChange={(value) => {
            if (value === 'operational') {
              onChange({
                ...state,
                operational: true
              })
            } else {
              onChange({
                ...state,
                state: value as BankOperationState,
                operational: false
              })
            }
          }}
        >
          {options.map((option, i) => (
            <RadioGroup.Option className='cursor-pointer py-3' value={option[1]} key={i}>
              {({ checked }) => (
                <div className='flex flex-col'>
                  <div className='flex items-center'>
                    <div
                      className={c(
                        'h-10 w-10 rounded-full border-1 border-grayscale-300',
                        checked && 'border-[6px] !border-red-100'
                      )}
                    />
                    <div className='rounded-full px-6'>{option[0]}</div>
                  </div>
                </div>
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      </div>
    </section>
  )
}

const setQuery = throttle(500, (query: string) => {
  bankOperationFilterStateVar({
    ...bankOperationFilterStateVar(),
    query
  })
})

export const useBankOperationFilter = () => {
  const filterState = useReactiveVar(bankOperationFilterStateVar)
  const queryState = useReactiveVar(bankOperationQueryVar)

  const filterActive = Boolean(filterState.state !== emptyState.state)

  useEffect(() => {
    setQuery(queryState)
  }, [queryState])

  const variables = useMemo<BankOperationsQueryVariables>((): BankOperationsQueryVariables => {
    if (filterState?.query?.length) {
      return {
        search: filterState.query,
        states: filterState.state
      }
    }
    if (filterState.operational === true) {
      return {
        customerAccount: null,
        states: [BankOperationState.Processed],
        kinds: [BankOperationKind.Income]
      }
    }
    return {
      kinds: [BankOperationKind.Income],
      states: [filterState.state]
    }
  }, [filterState])

  return {
    filterState,
    setFilterState: bankOperationFilterStateVar,
    filterActive,
    query: queryState,
    setQuery: bankOperationQueryVar,
    variables
  }
}

export default BankOperationsFilterForm
