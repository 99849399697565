import { FC, useCallback, useMemo, useState } from 'react'
import GridView, { GridViewConfig } from '../../components/GridView'
import {
  BankOperationKind,
  BankOperationState,
  useApplyBankOperationToOtherMutation,
  useBankOperationsCountersQuery,
  useBankOperationsQuery,
  useCancelBankOperationApplyMutation
} from '../../graphql/schema'
import useScrollPagination from '../../hooks/useScrollPagination'
import useNodes from '../../hooks/useNodes'
import { dateFormatter } from '../../utils/dateFormatter'
import { ReactComponent as ApplyPaymentIcon } from '../../svg/icons/applyPayment.svg'
import { ReactComponent as EditDocumentIcon } from '../../svg/icons/editDocument.svg'
import { ReactComponent as CancelIcon } from '../../svg/icons/cancel.svg'
import { ReactComponent as ReadMoreIcon } from '../../svg/icons/cashBanknoteMore.svg'
import Dropdown from '../../components/Dropdown'
import Modal from '../../components/Modal'
import ApplyIncomeForm from './ApplyIncomeForm'
import SearchBar, { FilterButton } from '../../components/Search'
import BankOperationsFilterForm, { useBankOperationFilter } from './FilterForm'
import useUpdateEffect from '../../hooks/useUpdateEffect'
import StatementUpload from './StatementUpload'
import TransactionAmount from '../../components/TransactionAmount'
import PaymentInfo from './PaymentInfo'
import SuspenseModal from '../../components/SuspenseModal.tsx'
import c from 'clsx'

export interface BankOperation {
  id: string
  date: string
  amount: number
  payerInn: string
  payerName: string
  recipientName: string
  customerAccount?: {
    customerCompany: { id: string; shortWithOpf: string }
  }
  paymentPurpose: string
  kind: BankOperationKind
  documentNumber: string
  payerAccountNumber?: string
}

const BankOperations: FC = () => {
  const [applyIncomeEntity, setApplyIncomeEntity] = useState<BankOperation | undefined>()
  const [readMoreId, setReadMoreId] = useState<string | undefined>()
  const [applyBankOperationToOther] = useApplyBankOperationToOtherMutation()
  const [cancelBankOperationApply] = useCancelBankOperationApplyMutation()

  const { setFilterState, query, setQuery, filterActive, filterState, variables } = useBankOperationFilter()

  const {
    data: bankOperationsData,
    refetch,
    fetchMore,
    loading,
    error
  } = useBankOperationsQuery({
    nextFetchPolicy: 'cache-and-network',
    variables
  })
  const bankOperationsList = useNodes(bankOperationsData?.bankOperations.edges) as BankOperation[]

  const { data: bankOperationsCounters, refetch: refetchCounters } = useBankOperationsCountersQuery({
    fetchPolicy: 'cache-and-network'
  })
  const todayCount = bankOperationsCounters?.bankOperationsCounters?.todayCount
  const pendingCount = bankOperationsCounters?.bankOperationsCounters?.pendingCount

  useUpdateEffect(() => {
    refetch(variables)
  }, [variables, refetch])

  const applyBankOperationToOtherCallback = useCallback(
    async (bankOperation: BankOperation) => {
      await applyBankOperationToOther({
        variables: {
          input: {
            bankOperationId: bankOperation.id
          }
        }
      }).then(() => {
        refetch()
        refetchCounters()
      })
    },
    [applyBankOperationToOther, refetch, refetchCounters]
  )

  const { wrapperRef: triggerRef, isFetching } = useScrollPagination(
    fetchMore,
    bankOperationsData?.bankOperations?.pageInfo
  )

  const cancelBankOperationApplyCallback = useCallback(
    async (bankOperation: BankOperation) => {
      await cancelBankOperationApply({
        variables: {
          input: {
            bankOperationId: bankOperation.id
          }
        }
      }).then(() => {
        refetch()
        refetchCounters()
      })
    },
    [cancelBankOperationApply, refetch, refetchCounters]
  )

  const bankOperationsTableConfig = useMemo<GridViewConfig<BankOperation>>(
    () => ({
      grid: 'grid-cols-bank-operation',
      columns: [
        {
          title: 'Дата',
          numeric: true,
          value: (o) => {
            const today = new Date()
            today.setHours(0, 0, 0, 0)

            const orderDate = new Date(o.date)
            orderDate.setHours(0, 0, 0, 0)
            return (
              <div className={c(orderDate < today ? 'text-base-red' : '')}>
                {dateFormatter.format(new Date(o.date))}
              </div>
            )
          }
        },
        {
          title: 'Плательщик',
          value: (o) => (
            <div className='line-clamp-1' title={o.payerName}>
              {o.payerName}
            </div>
          )
        },
        {
          title: 'Назначение',
          value: (o) => (
            <div className='line-clamp-1' title={o.paymentPurpose}>
              {o.paymentPurpose}
            </div>
          )
        },
        filterState.state === BankOperationState.Pending
          ? {
              title: 'Получатель',
              value: (o) => (
                <div className='line-clamp-1' title={o.recipientName}>
                  {o.recipientName}
                </div>
              )
            }
          : {
              title: 'Клиент',
              value: (o) => (
                <div className='line-clamp-1' title={o?.customerAccount?.customerCompany?.shortWithOpf}>
                  {o?.customerAccount?.customerCompany?.shortWithOpf || '—'}
                </div>
              )
            },
        {
          title: 'Сумма, ₽',
          titleClassName: 'text-right',
          numeric: true,
          value: (o) => (
            <TransactionAmount
              isIncome={o.kind === BankOperationKind.Income}
              amount={o.amount}
              className={o.kind === BankOperationKind.Income ? 'ml-auto text-base-green' : 'ml-auto'}
            />
          )
        },
        {
          title: '',
          value: (o) => (
            <Dropdown className='ml-auto pl-4 pr-4'>
              {filterState.state === BankOperationState.Pending ? (
                <>
                  <button
                    className='flex items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
                    onClick={() => applyBankOperationToOtherCallback(o)}
                  >
                    <EditDocumentIcon className='mr-7' height='18px' width='18px' title='На операционку' />
                    <span className='whitespace-nowrap text-grayscale-0'>На операционку</span>
                  </button>
                  <button
                    className='flex items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
                    onClick={() => setApplyIncomeEntity(o)}
                  >
                    <ApplyPaymentIcon className='mr-7' height='18px' width='18px' title='Разнести' />
                    <span className='text-grayscale-0'>Разнести</span>
                  </button>
                </>
              ) : (
                <>
                  <button
                    className='flex items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
                    onClick={() => setReadMoreId(o.id)}
                  >
                    <ReadMoreIcon className='mr-7' height='18px' width='18px' title='Подробнее' />
                    <span className='whitespace-nowrap text-grayscale-0'>Подробнее</span>
                  </button>
                  <button
                    className='flex items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
                    onClick={() => cancelBankOperationApplyCallback(o)}
                  >
                    <CancelIcon className='mr-7' height='18px' width='18px' title='Отменить разнесение' />
                    <span className='whitespace-nowrap text-grayscale-0'>Отменить разнесение</span>
                  </button>
                </>
              )}
            </Dropdown>
          )
        }
      ]
    }),
    [applyBankOperationToOtherCallback, cancelBankOperationApplyCallback, filterState.state]
  )

  return (
    <div className='container flex-grow px-6 py-15'>
      <div className='mb-8 rounded-xl bg-surface-secondary p-5'>
        <div className='rounded-md bg-white-0 shadow-xs'>
          <div className='flex items-start justify-between border-b-1 border-grayscale-400 p-10'>
            <div>
              <h1 className='font-display text-h100'>Разнесение платежей</h1>
            </div>
          </div>
          <div className='flex gap-12 p-10'>
            <div>
              <div className='mb-4 text-grayscale-150'>Новых платежей за сегодня</div>
              <div>{todayCount?.toString() || '-'}</div>
            </div>
            <div>
              <div className='mb-4 text-grayscale-150'>Всего необработанных платежей</div>
              <div>{pendingCount?.toString() || '-'}</div>
            </div>
          </div>
        </div>
      </div>

      <SearchBar query={query} onChange={setQuery}>
        <FilterButton active={filterActive}>
          <BankOperationsFilterForm state={filterState} onChange={setFilterState} />
        </FilterButton>
      </SearchBar>

      <div className='rounded-xl bg-surface-secondary p-5' ref={triggerRef}>
        <StatementUpload className='mb-5' onDone={() => refetch()} />
        <GridView
          config={bankOperationsTableConfig}
          data={bankOperationsList}
          loading={loading || isFetching}
          error={error?.message}
        />
      </div>
      <Modal open={Boolean(applyIncomeEntity)} setOpen={() => setApplyIncomeEntity(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ApplyIncomeForm
            onDone={() => {
              refetch()
              refetchCounters()
              setApplyIncomeEntity(undefined)
            }}
            bankOperation={applyIncomeEntity!}
          />
        </div>
      </Modal>
      <SuspenseModal open={Boolean(readMoreId)} setOpen={() => setReadMoreId(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <PaymentInfo
            id={readMoreId || ''}
            onDone={() => {
              setReadMoreId(undefined)
            }}
          />
        </div>
      </SuspenseModal>
    </div>
  )
}

export default BankOperations
