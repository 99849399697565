import { AccreditationStatus } from '../../../graphql/schema'

export enum CompanyStatus {
  ACTIVE = 'ACTIVE',
  LIQUIDATING = 'LIQUIDATING',
  LIQUIDATED = 'LIQUIDATED',
  BANKRUPT = 'BANKRUPT',
  REORGANIZING = 'REORGANIZING'
}

export const companyStatusDict: Record<CompanyStatus, string> = {
  [CompanyStatus.ACTIVE]: 'Действующая',
  [CompanyStatus.LIQUIDATING]: 'Ликвидируется',
  [CompanyStatus.LIQUIDATED]: 'Ликвидирована',
  [CompanyStatus.BANKRUPT]: 'Банкротство',
  [CompanyStatus.REORGANIZING]: 'В процессе присоединения к другому юрлицу, с последующей ликвидацией'
}

export const companyColorDict: Record<CompanyStatus, string> = {
  [CompanyStatus.ACTIVE]: 'bg-tr-green',
  [CompanyStatus.LIQUIDATING]: 'bg-tr-yellow',
  [CompanyStatus.LIQUIDATED]: 'bg-tr-red',
  [CompanyStatus.BANKRUPT]: 'bg-tr-red',
  [CompanyStatus.REORGANIZING]: 'bg-tr-yellow'
}

export const companyTextColorDict: Record<CompanyStatus, string> = {
  [CompanyStatus.ACTIVE]: 'text-dark-green',
  [CompanyStatus.LIQUIDATING]: 'text-dark-yellow',
  [CompanyStatus.LIQUIDATED]: 'text-dark-red',
  [CompanyStatus.BANKRUPT]: 'text-dark-red',
  [CompanyStatus.REORGANIZING]: 'text-dark-yellow'
}

export const companyAccreditationColorDict: Record<AccreditationStatus, string> = {
  [AccreditationStatus.Pending]: '',
  [AccreditationStatus.Requested]: 'bg-tr-cyan',
  [AccreditationStatus.InProgress]: 'bg-tr-blue',
  [AccreditationStatus.Accepted]: 'bg-tr-green',
  [AccreditationStatus.Rejected]: 'bg-tr-red'
}

export const companyAccreditationIconColorDict: Record<AccreditationStatus, string> = {
  [AccreditationStatus.Pending]: '',
  [AccreditationStatus.Requested]: 'text-base-cyan',
  [AccreditationStatus.InProgress]: 'text-base-blue',
  [AccreditationStatus.Accepted]: 'text-base-green',
  [AccreditationStatus.Rejected]: 'text-base-red'
}

export const companyAccreditationTextColorDict: Record<AccreditationStatus, string> = {
  [AccreditationStatus.Pending]: '',
  [AccreditationStatus.Requested]: 'text-dark-cyan',
  [AccreditationStatus.InProgress]: 'text-dark-blue',
  [AccreditationStatus.Accepted]: 'text-dark-green',
  [AccreditationStatus.Rejected]: 'text-dark-red'
}
