import { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { SourceByIdQuery, UserRoles, useUpdateSourceMutation } from '../../graphql/schema'
import { handleBackendErrorsToForm } from '../../utils/backendErrorUtils'
import { ApolloError } from '@apollo/client'
import SubmitButton from '../../components/SubmitButton'
import ManagerInput from '../../components/Forms/Inputs/ManagerInput'

import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'

interface Inputs {
  id: string
  userId: number
  bdmUserId: number
}

interface SourceFormProps {
  source: SourceByIdQuery['source']
  onDone?: () => void
}

const ManagersForm: FC<SourceFormProps> = ({ source, onDone }) => {
  const [updateSource, { loading }] = useUpdateSourceMutation()

  const { handleSubmit, formState, setError, control } = useForm<Inputs>({
    defaultValues: {
      userId: source?.user?._id,
      bdmUserId: source?.bdmUser?._id
    }
  })

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    await updateSource({
      variables: {
        input: {
          id: source?._id.toString()!,
          // @ts-ignore
          user: data?.userId?.toString() || null,
          // @ts-ignore
          bdmUser: data?.bdmUserId?.toString() || null
        }
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  return (
    <section className='md: w-[448px] p-12'>
      <h1 className='mb-12 font-display text-h200'>Менеджеры Fera</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='relative mb-12 flex flex-col gap-8'>
          <Controller
            name='userId'
            control={control}
            render={({ field }) => (
              <ManagerInput
                selected={field.value ? [field.value] : []}
                onChange={(selected) =>
                  selected.length ? field.onChange(selected[selected.length - 1]) : field.onChange(null)
                }
                label='Менеджер по лизингу'
                placeholder='Выберите менеджера'
                error={formState.errors?.userId}
              />
            )}
          />
          <Controller
            name='bdmUserId'
            control={control}
            render={({ field }) => (
              <ManagerInput
                selected={field.value ? [field.value] : []}
                onChange={(selected) =>
                  selected.length ? field.onChange(selected[selected.length - 1]) : field.onChange(null)
                }
                label='Менеджер по развитию'
                placeholder='Выберите менеджера'
                error={formState.errors?.bdmUserId}
                role={UserRoles.RoleBdm}
              />
            )}
          />
        </div>
        <SubmitButton loading={loading}>
          <EditIcon className='mr-5' />
          Изменить
        </SubmitButton>
      </form>
    </section>
  )
}

export default ManagersForm
