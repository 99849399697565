import { FC } from 'react'
import { dateFormatter, timeFormatter } from '../../../utils/dateFormatter'
import UserName from '../../UserName'

export const EventActedAt: FC<{ date: string; withLine?: boolean }> = ({ date, withLine }) => {
  return (
    <div className='flex flex-col'>
      <div className='text-grayscale-200'>{dateFormatter.format(new Date(date))}</div>
      <div className='text-[13px] text-grayscale-200'>{timeFormatter.format(new Date(date))}</div>
      {withLine && <div className='mt-2 ml-6 min-h-[16px] h-full border-r-1 border-grayscale-350 w-1' />}
    </div>
  )
}

export const EventInitiatedBy: FC<{ userId?: number }> = ({ userId }) => {
  return userId ? <UserName short userId={userId} /> : <div>Fera Робот</div>
}
