import { FC } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as MoreIcon } from '../svg/icons/more.svg'

interface HintProps {
  text: string
  to: string
  onClick: () => void
}

const Hint: FC<RequireAtLeastOne<HintProps, 'onClick' | 'to'>> = ({ text, onClick, to }) => {
  const component = (
    <div className='bg-red-50/5 px-8 py-6 rounded-lg text-red-50 font-medium flex items-center justify-between'>
      {text}
      <MoreIcon />
    </div>
  )
  if (onClick) return <button className='block w-full text-left' onClick={onClick}>{component}</button>
  if (to) return <Link className='block' to={to}>{component}</Link>
  return null
}

export default Hint