import { PaymentCollectionProcessStatus as Status, useUpdateCollectionStatusMutation } from '../../../graphql/schema'

const useChangeCollectionStatus = (id?: string) => {
  const [updateCollection, { loading }] = useUpdateCollectionStatusMutation()
  const changeStatus = async (status: Status) => {
    if (!id) return
    await updateCollection({
      variables: {
        id: id,
        status: status
      }
    })
  }

  return [changeStatus, loading] as const
}

export default useChangeCollectionStatus
