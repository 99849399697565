import { FC, ReactNode, ComponentPropsWithoutRef } from 'react'
import PrimaryButton from './PrimaryButton'
import { Link } from 'react-router-dom'

interface PrimaryButtonLinkProps extends ComponentPropsWithoutRef<'a'> {
  children?: ReactNode
  to?: string
}

/**
 * @param to - for internal links
 * @param href - for external links
 */
const PrimaryButtonLink: FC<PrimaryButtonLinkProps> = ({ children, className, href, to, ...otherProps }) => {
  if (!href && !to) {
    return (
      <PrimaryButton className={className} disabled>
        {children}
      </PrimaryButton>
    )
  }

  if (to) {
    return (
      <Link to={to} {...otherProps}>
        <PrimaryButton type='button' className={className}>
          {children}
        </PrimaryButton>
      </Link>
    )
  }

  return (
    <a href={href} target='_blank' rel='noopener noreferrer' {...otherProps}>
      <PrimaryButton type='button' className={className}>
        {children}
      </PrimaryButton>
    </a>
  )
}

export default PrimaryButtonLink
