import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import 'unfonts.css'
import './index.css'
import initSentry from './utils/sentry.ts'
import App from './App'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'
import FizzRoot from './components/Fizz'
import reportWebVitals from './reportWebVitals'
import client from './graphql/client'

initSentry()

const container = document.getElementById('root')
if (!container) throw new Error('No root element found')
const root = createRoot(container)

root.render(
  <StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
        <FizzRoot />
      </BrowserRouter>
    </ApolloProvider>
  </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
