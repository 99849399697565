import { FC } from 'react'
import { SourceChannelsQuery, SourceChannelsStatus, useUpdateSourceChannelsMutation } from '../../graphql/schema'
import { SourceChannelsKind } from './Channels.tsx'
import { RadioGroup } from '@headlessui/react'
import { sourceChannelsKindDict } from '../../utils/dictionaries.ts'
import c from 'clsx'

interface BonusesFormProps {
  source: SourceChannelsQuery['source']
  status: SourceChannelsStatus
  kind?: SourceChannelsKind
  onDone: () => void
}

const SOURCE_CHANNEL_STATUSES = [
  ['Не выдано', SourceChannelsStatus.NotIssued],
  ['В работе', SourceChannelsStatus.InWork],
  ['Тестируется', SourceChannelsStatus.Test],
  ['Выдано', SourceChannelsStatus.Issued]
]

const ChannelForm: FC<BonusesFormProps> = ({ source, kind, status, onDone }) => {
  const [updateSourceChannel] = useUpdateSourceChannelsMutation()

  const changeStatus = (status: SourceChannelsStatus) => {
    if (!source || !kind) return

    updateSourceChannel({
      variables: {
        input: {
          id: source._id.toString(),
          [kind]: status
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateSource: {
          __typename: 'updateSourcePayload',
          source: {
            ...source,
            [kind]: status
          }
        }
      }
    }).then(() => {
      if (onDone) onDone()
    })
  }

  return (
    <section className='w-[345px] p-10'>
      <h1 className='font-display text-h200'>Статуса канала</h1>
      {kind && <div className='mt-2 text-p200 text-grayscale-150'>{sourceChannelsKindDict[kind]}</div>}
      <RadioGroup className='text-p350 mt-8' value={status} onChange={changeStatus}>
        {SOURCE_CHANNEL_STATUSES.map((option, i) => (
          <RadioGroup.Option className='flex items-center cursor-pointer py-5' value={option[1]} key={i}>
            {({ checked }) => (
              <>
                <div
                  className={c(
                    'rounded-full w-12 h-12 border-1 border-grayscale-300',
                    checked && 'border-[7px] !border-red-100'
                  )}
                />
                <div className='px-6 rounded-full'>{option[0]}</div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
    </section>
  )
}

export default ChannelForm
