import { FC, useState } from 'react'
import NestedSelectInput, { NestedSelectOption } from '../../components/NestedSelectInput'
import c from 'clsx'
import { LOSS_REASON_GROUP, REGULAR_VAT_RATE } from '../../utils/constants'
import { ReactComponent as WarningIcon } from '../../svg/icons/warning.svg'
import { statusColorDict, statusTextColorDict } from '../../components/ApplicationStatus'
import {
  useDealByIdLazyQuery,
  useApplicationStatusSelectQuery,
  useAddDealMutation,
  Kind,
  ReconciliationFeraStatus,
  ReconciliationCustomerStatus,
  useUpdateApplicationStatusMutation
} from '../../graphql/schema'
import Tooltip from '../../components/Tooltip'
import Modal from '../../components/Modal'
import { showPopup } from '../../components/Toaster/showPopup'
import { ApplicationStatusCircle, AppStatus } from '../../components/ApplicationStatus'
import Placeholder from '../../components/Placeholder.tsx'
import RecSelectForm, { AddDealReconciliation } from './RecSelectForm.tsx'
import confetti from '../../utils/confetti.ts'
import { useApplicationSwitcherState } from './useApplicationSwitcherState.ts'
import { useNavigate } from 'react-router-dom'
import { applicationStatusDict } from '../../utils/dictionaries.ts'
import limitFloat from '../../utils/limitFloat.ts'
import useNodes from '../../hooks/useNodes.ts'

const LOSS_REASON_OPTIONS = LOSS_REASON_GROUP.flatMap((g) => g.children)

const LossReason: FC<{ reason: string; active: boolean }> = ({ active, reason }) => (
  <div className='flex cursor-pointer items-center gap-5 px-5 py-3'>
    <div>
      <div
        className={c('h-8 w-8 rounded-full border-1 border-grayscale-300', active && 'border-[3px] border-red-100')}
      />
    </div>
    <div className='text-left'>{reason}</div>
  </div>
)

const ApplicationStatusSwitcher: FC<{ id: number }> = ({ id }) => {
  const [recSelectFormOpen, setRecSelectFormOpen] = useState(false)

  const { data: appData, loading: appLoading } = useApplicationStatusSelectQuery({
    variables: { id: id?.toString() },
    skip: !id
  })
  const application = appData?.application
  const status = application?.status as AppStatus
  const existingDealId = application?.deal?._id

  const { switcherState, loading } = useApplicationSwitcherState(id?.toString(), status)

  const reconciliations = useNodes(application?.reconciliations?.edges)
  const acceptedRecs = reconciliations.filter(
    (rec) =>
      rec?.feraStatus === ReconciliationFeraStatus.Accepted &&
      rec?.customerStatus === ReconciliationCustomerStatus.Accepted &&
      rec?.feraAcceptExpiresAt &&
      new Date() <= new Date(rec?.feraAcceptExpiresAt)
  )

  const [addDeal, { loading: addDealLoading }] = useAddDealMutation()
  const [loadDeal] = useDealByIdLazyQuery({ variables: { id: id?.toString() } })

  const [updateApplicationStatus, { loading: updateApplicationLoading }] = useUpdateApplicationStatusMutation()

  const addLossReason = async (lossReason: string) => {
    await updateApplicationStatus({
      variables: {
        input: {
          id: application?._id?.toString() as string,
          lossReason
        }
      }
    }).then(
      () => {
        changeStatus(AppStatus.Closed)
      },
      () => {
        showPopup({
          title: 'Ошибка',
          subtitle: 'Не удалось добавить причину закрытия заявки'
        })
      }
    )
  }

  const navigate = useNavigate()

  const handleRecSelect = async (rec: AddDealReconciliation) => {
    const { data } = await addDeal({
      variables: {
        input: {
          amount: rec?.amount,
          advanceRate: limitFloat(rec?.advanceRate),
          application: id.toString(),
          comissionRate: limitFloat(rec?.comissionRate),
          customerCompany: application?.customerCompany?._id.toString() || '',
          durationMonths: rec?.durationMonths,
          insuranceAmount: Math.round((rec?.insuranceRate / 100) * rec?.amount * Math.max(rec?.durationMonths / 12, 1)),
          interestRate: limitFloat(rec?.interestRate),
          leasingSubjectCategories: application?.leasingSubjectCategories?.edges?.map((a) => a?.node?._id.toString()),
          kind: rec?.kind,
          vatRate: rec?.kind === Kind.Medicine ? 0 : REGULAR_VAT_RATE,
          user: application?.user?._id?.toString(),
          sourceReconciliation: rec._id.toString()
        }
      }
    })
    const dealId = data?.addDeal?.deal?._id
    await changeStatus(AppStatus.Deal)

    // preload deal before route change for immediate render
    await loadDeal()
    navigate(`/deals/${dealId}`)
    await confetti()
  }

  const changeStatus = async (status: string) => {
    if (!application) return
    await updateApplicationStatus({
      variables: {
        input: {
          id: application?._id.toString(),
          status
        }
      }
    }),
      () => {
        showPopup({
          title: 'Ошибка',
          subtitle: 'Не удалось изменить статус заявки'
        })
      }
  }

  const globalLoading = appLoading || loading || updateApplicationLoading || addDealLoading

  const lossReasons = LOSS_REASON_GROUP.map((group) => ({
    name: group.name,
    node: (
      <div
        className={c(
          'flex h-16 cursor-pointer items-center gap-5 px-5',
          application?.status === AppStatus.Closed &&
            application?.lossReason &&
            group.children.includes(application.lossReason) &&
            'text-base-red'
        )}
      >
        {group.name}
      </div>
    ),
    children: group.children.map((lossReason) => ({
      name: lossReason,
      node: (
        <LossReason
          reason={lossReason}
          active={application?.status === AppStatus.Closed && application?.lossReason === lossReason}
        />
      )
    }))
  }))

  const options: NestedSelectOption[] = switcherState
    .map((option) => {
      const current = option.state === status

      return {
        name: option.state,
        node: (
          <div
            className={c(
              'flex h-16 cursor-pointer items-center gap-5 px-5',
              current && 'pointer-events-none text-base-red',
              !current && !option.allowed && 'pointer-events-none hover:bg-surface-primary'
            )}
          >
            <ApplicationStatusCircle
              status={option.state}
              bg={!current && !option.allowed}
              className={c(!current && !option.allowed && 'opacity-50')}
            />
            <div className={c('whitespace-nowrap', !current && !option.allowed && 'opacity-50')}>
              {applicationStatusDict[option.state]}
            </div>
            {!!option?.messages?.length && (
              <Tooltip className='pointer-events-auto ml-auto' target={<WarningIcon className='text-base-red' />}>
                <div className='mb-2 font-display font-medium'>Чтобы продолжить, нужно:</div>
                <ul className='list-inside list-disc'>
                  {option.messages.map((message) => (
                    <li key={message}>{message}</li>
                  ))}
                </ul>
              </Tooltip>
            )}
          </div>
        ),
        disabled: !option.allowed,
        children: option.state === AppStatus.Closed ? lossReasons : undefined
      }
    })
    .filter(Boolean) as NestedSelectOption[]

  return (
    <div className='mb-12 flex gap-x-4'>
      <NestedSelectInput
        className={c(
          'flex min-h-16 items-center gap-x-3 rounded-md px-5 py-3',
          status ? statusColorDict[status] : 'bg-surface-secondary',
          status ? statusTextColorDict[status] : 'text-labels-secondary'
        )}
        loading={globalLoading}
        options={options}
        onSelect={async (value: string) => {
          if (LOSS_REASON_OPTIONS.includes(value)) {
            await addLossReason(value)
          } else if (value === AppStatus.Deal && !existingDealId) {
            if (acceptedRecs?.length === 1) {
              // short path for single accepted reconciliation
              await handleRecSelect(acceptedRecs[0])
            } else {
              // long path where user selects reconciliation
              setRecSelectFormOpen(true)
            }
          } else {
            await changeStatus(value)
          }
        }}
      >
        <ApplicationStatusCircle status={status || 'Укажите статус'} bg={true} />
        <div className='whitespace-nowrap'>
          {appLoading && !status ? <Placeholder text='Какой-то статус' /> : status || 'Укажите статус'}
        </div>
      </NestedSelectInput>
      {status === AppStatus.Closed && (
        <div className='rounded-md bg-tr-red px-5 py-3 font-medium text-dark-red'>{application?.lossReason}</div>
      )}

      <Modal open={recSelectFormOpen} setOpen={setRecSelectFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <RecSelectForm recs={acceptedRecs} onDone={handleRecSelect} />
        </div>
      </Modal>
    </div>
  )
}

export default ApplicationStatusSwitcher
