import { QuestionnaireEducation, QuestionnaireMaritalStatus } from '../graphql/schema'

export type VerificationState = 'accepted' | 'rejected' | 'completed' | 'pending'

export const educationDict: Record<QuestionnaireEducation, string> = {
  [QuestionnaireEducation.Middle]: 'Среднее общее',
  [QuestionnaireEducation.MiddlePro]: 'Среднее профессиональное',
  [QuestionnaireEducation.HigherUnfinished]: 'Высшее незаконченное',
  [QuestionnaireEducation.Bachelor]: 'Высшее (бакалавр)',
  [QuestionnaireEducation.Master]: 'Высшее (магистр, специалист)'
}

export const maritalStatusDict: Record<QuestionnaireMaritalStatus, string> = {
  [QuestionnaireMaritalStatus.Single]: 'Холост / Не замужем',
  [QuestionnaireMaritalStatus.Married]: 'Женат / Замужем',
  [QuestionnaireMaritalStatus.Divorced]: 'Разведен / Разведена',
  [QuestionnaireMaritalStatus.Widowed]: 'Вдовец / Вдова',
  [QuestionnaireMaritalStatus.Separate]: 'Проживаем раздельно'
}

export const verificationStateDict: Record<VerificationState, string> = {
  accepted: 'Проверен',
  rejected: 'Отказано',
  completed: 'Ожидает проверки',
  pending: 'Заполняется'
}

export const verificationRiskColor: Record<VerificationState, string> = {
  accepted: 'bg-green-400',
  rejected: 'bg-red-100',
  completed: 'bg-yellow-400',
  pending: 'bg-transparent'
}
