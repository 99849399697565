import { FC, useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  CommentTargetType,
  CustomerAccountKind,
  DealInsuranceKind,
  DealStatus,
  HistoryTargetType,
  Kind,
  PaymentKind,
  PaymentsQuery,
  PaymentTargetType,
  RetroBonusKind,
  TaskTargetType,
  useAgentCommissionRewardForIrrQuery,
  useDealByIdQuery,
  useDealCalculationsQuery,
  useDealRetrobonusesForIrrQuery,
  useDealRetrobonusesQuery,
  usePaymentsQuery
} from '../../graphql/schema'
import { ReactComponent as EditIcon } from '../../svg/ui/edit.svg'
import { ReactComponent as WarningIcon } from '../../svg/ui/warning.svg'
import { dateFormatter } from '../../utils/dateFormatter'
import { supplyingStatusDict } from '../../utils/dictionaries'
import { REGULAR_VAT_RATE } from '../../utils/constants'
import { formatDecimal, formatMoneyDecimal } from '../../utils/formatNumber'
import Payments from './Payments/Payments.tsx'
import Comments from '../../components/Comments/Comments'
import useNodes from '../../hooks/useNodes'
import Tasks from '../../components/Tasks'
import { getFormattedContractNumber } from '../../utils/contractUtils'
import CustomerDeals from '../../components/CustomerDeals.tsx'
import LeasingSubjectCategoriesList from '../../components/LeasingSubjectCategoriesList.tsx'
import dealFormOpenState from './state.ts'
import History from '../../components/History/index.tsx'
import { Card, CardHeader, CardIconButton, CardMenu, CardTitle } from '../../components/Card.tsx'
import Tooltip from '../../components/Tooltip.tsx'
import getVatKind from '../../utils/getVatKind.ts'
import useAccessCheck from '../../hooks/useAccessCheck.ts'
import SuspenseModal from '../../components/SuspenseModal.tsx'
import TransactionsDateForm from './Payments/TransactionsDateForm.tsx'
import CustomerCompanyBalance from '../../components/CustomerCompanyBalance/index.tsx'
import IRR from './IRR.tsx'
import { NodeType } from '../../types/index.ts'

export type Payment = NodeType<PaymentsQuery['payments']> & { penaltyPayments?: Payment[] }

const Info: FC = () => {
  const { id } = useParams<'id'>()
  const { data } = useDealByIdQuery({ variables: { id: `${id}` }, skip: !id })

  const deal = data?.deal

  const categories = useNodes(deal?.leasingSubjectCategories?.edges)

  const [editTransactionsFormOpenByPaymentId, setEditTransactionsFormOpenByPaymentId] = useState<string>()

  const { data: basePaymentsData, refetch: refetchBasePayments } = usePaymentsQuery({
    variables: {
      kinds: [
        PaymentKind.Advance,
        PaymentKind.Commission,
        PaymentKind.Redemption,
        PaymentKind.Insurance,
        PaymentKind.Service,
        PaymentKind.Compensation,
        PaymentKind.DebtTransferService,
        PaymentKind.Buyout,
        PaymentKind.Body
      ],
      targetType: PaymentTargetType.Deal,
      targetId: id
    }
  })
  const basePayments = useNodes(basePaymentsData?.payments.edges)

  const { data: leasingPaymentsData, refetch: refetchLeasingPayments } = usePaymentsQuery({
    variables: {
      kind: PaymentKind.Leasing,
      targetType: PaymentTargetType.Deal,
      targetId: id
    }
  })

  const leasingPayments = useNodes(leasingPaymentsData?.payments.edges) as Payment[]

  const insuranceRate = deal?.insuranceAmount ? Math.round((deal?.insuranceAmount / deal?.amount) * 10000) / 100 : 0

  const { data: dealCalculationsData } = useDealCalculationsQuery({
    variables: {
      dealParams: {
        amount: deal?.amount / 100 || 0,
        advanceRate: deal?.advanceRate || 0,
        interestRate: deal?.interestRate || 0,
        durationMonths: deal?.durationMonths || 0,
        comissionRate: deal?.comissionRate || 0,
        insuranceRate: deal?.insuranceKind === DealInsuranceKind.WithoutInsurance ? 0 : insuranceRate,
        vatRate: deal?.kind === Kind.Medicine ? 0 : REGULAR_VAT_RATE,
        advancePaymentDate: deal?.advancePaymentDate
      }
    },
    skip:
      !deal ||
      deal.amount === null ||
      deal.advanceRate === null ||
      deal.interestRate === null ||
      deal.durationMonths === null ||
      deal.comissionRate === null ||
      deal.advancePaymentDate === null
  })
  const dealCalculations = dealCalculationsData?.dealCalculations

  const dealInfoAvailable = Boolean(basePayments?.length && leasingPayments?.length)

  const { data: dealRetrobonusesData } = useDealRetrobonusesQuery({
    variables: {
      deal: `${id}`
    },
    skip: !id
  })
  const dealRetrobonuses = useNodes(dealRetrobonusesData?.dealRetrobonuses?.edges)

  // у нас должно быть только одно АВ в сделке
  const agentCommissionRewardId = useNodes(deal?.agentCommissionRewards?.edges)[0]

  const { data: agentCommissionForIrrData } = useAgentCommissionRewardForIrrQuery({
    variables: {
      id: agentCommissionRewardId?._id.toString()
    },
    skip: !agentCommissionRewardId?._id
  })

  const agentCommissionForIRR = agentCommissionForIrrData?.agentCommissionReward?.commissionRate

  const { data: dealRetrobonusesForIrrData } = useDealRetrobonusesForIrrQuery({
    variables: {
      deal: `${id}`
    },
    skip: !id
  })

  const retrobonusesForIRR = useNodes(dealRetrobonusesForIrrData?.dealRetrobonuses?.edges)

  const retrobonusesAmountForIRR = retrobonusesForIRR.reduce((total, r) => {
    return total + r.amount
  }, 0)

  const dealSuppliesAmount = useNodes(deal?.dealSupplies?.edges).reduce((acc, supply) => acc + (supply?.amount || 0), 0)

  const onPayInvoices = useCallback(async () => {
    await Promise.allSettled([refetchBasePayments(), refetchLeasingPayments()])
  }, [refetchBasePayments, refetchLeasingPayments])

  const hasAccessEditDealAfterSigning = useAccessCheck('deal.edit_after_signing')

  const canEdit = useMemo(() => {
    if (!deal?.status) return
    if (
      [DealStatus.Signing, DealStatus.Activation, DealStatus.PaymentReception, DealStatus.Closed].includes(deal?.status)
    )
      return hasAccessEditDealAfterSigning
    return true
  }, [deal?.status, hasAccessEditDealAfterSigning])

  if (!deal) return null

  return (
    <div className='grid grid-cols-3 gap-6'>
      <div className='col-span-1 flex flex-col gap-6'>
        <Card>
          <CardHeader>
            <CardTitle>Категории</CardTitle>
            <CardMenu>
              {canEdit && (
                <CardIconButton onClick={() => dealFormOpenState(true)}>
                  <EditIcon />
                </CardIconButton>
              )}
            </CardMenu>
          </CardHeader>
          <div className='px-5 pb-5'>
            <LeasingSubjectCategoriesList categories={categories} />
          </div>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Контракт</CardTitle>
          </CardHeader>
          <div className='px-5 pb-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              <table className='conte w-full'>
                <tbody>
                  <tr className='group'>
                    <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Номер договора</td>
                    <td className='px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {getFormattedContractNumber(deal.contractNumber) || '—'}
                    </td>
                  </tr>
                  <tr className='group border-t-1 border-separators-primary'>
                    <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Дата договора</td>
                    <td className='px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {deal.contractDate ? dateFormatter.format(new Date(deal.contractDate)) : '—'}
                    </td>
                  </tr>
                  {deal?.insuranceKind === DealInsuranceKind.WithoutInsurance ? (
                    <tr className='group border-t-1 border-separators-primary'>
                      <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Полис</td>
                      <td className='px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>Без страховки</td>
                    </tr>
                  ) : (
                    <>
                      <tr className='group border-t-1 border-separators-primary'>
                        <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Полис</td>
                        <td className='px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                          {deal.insuranceContract || '—'}
                        </td>
                      </tr>
                      <tr className='group border-t-1 border-separators-primary'>
                        <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Дата полиса</td>
                        <td className='px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                          {deal.insuranceContractDate
                            ? dateFormatter.format(new Date(deal.insuranceContractDate))
                            : '—'}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className='px-5 pb-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              <table className='conte w-full'>
                <tbody>
                  <tr className='group'>
                    <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Дата внесения аванса</td>
                    <td className='px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {deal.advancePaymentDate && dateFormatter.format(new Date(deal.advancePaymentDate))}
                    </td>
                  </tr>
                  <tr className='group border-t-1 border-separators-primary'>
                    <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Сумма сделки</td>
                    <td className='flex items-center justify-end gap-x-5 px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {deal?.amount !== dealSuppliesAmount && (
                        <div className='relative'>
                          <Tooltip target={<WarningIcon className='text-base-red' height='20px' width='20px' />}>
                            Сумма ДП {formatMoneyDecimal(dealSuppliesAmount / 100)}. Ожидаемая{' '}
                            {formatMoneyDecimal(deal.amount / 100)}
                          </Tooltip>
                        </div>
                      )}
                      {formatDecimal(deal.amount)} ₽
                    </td>
                  </tr>
                  <tr className='group border-t-1 border-separators-primary'>
                    <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Срок</td>
                    <td className='px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {deal.durationMonths} месяцев
                    </td>
                  </tr>
                  <tr className='group border-t-1 border-separators-primary'>
                    <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Ставка</td>
                    <td className='whitespace-nowrap px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {deal.interestRate}% →{' '}
                      {formatDecimal(dealCalculations?.appreciation ? dealCalculations.appreciation * 100 : 0)} ₽
                    </td>
                  </tr>
                  <tr className='group border-t-1 border-separators-primary'>
                    <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Аванс</td>
                    <td className='whitespace-nowrap px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {deal.advanceRate}% →{' '}
                      {formatDecimal(dealCalculations?.advanceAmount ? dealCalculations.advanceAmount * 100 : 0)} ₽
                    </td>
                  </tr>
                  <tr className='group border-t-1 border-separators-primary'>
                    <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Комиссия</td>
                    <td className='whitespace-nowrap px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {deal.comissionRate}% →{' '}
                      {formatDecimal(dealCalculations?.comissionAmount ? dealCalculations.comissionAmount * 100 : 0)} ₽
                    </td>
                  </tr>
                  {deal?.insuranceKind !== DealInsuranceKind.WithoutInsurance && (
                    <tr className='group border-t-1 border-separators-primary'>
                      <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Страховка</td>
                      <td className='whitespace-nowrap px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                        {formatDecimal(deal.insuranceAmount)} ₽
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className='px-5 pb-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              <table className='w-full'>
                <tbody>
                  <tr className='group'>
                    <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Вид (НДС)</td>
                    <td className='px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {getVatKind(deal.kind)}
                    </td>
                  </tr>
                  <tr className='group border-t-1 border-separators-primary'>
                    <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Ставка (НДС)</td>
                    <td className='px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>{deal?.vatRate}%</td>
                  </tr>
                  <tr className='group border-t-1 border-separators-primary'>
                    <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Ретробонус</td>
                    <td className='px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {deal?.retroBonusKind === RetroBonusKind.WithRetrobonus
                        ? dealRetrobonuses?.length
                          ? dealRetrobonuses?.map((retrobonus) => (
                              <div key={retrobonus._id} className='mb-4 last:mb-0'>{`${
                                retrobonus.rate
                              }% → ${formatDecimal(retrobonus.amount)} ₽`}</div>
                            ))
                          : '—'
                        : 'Отсутствует'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <CardHeader>
            <CardTitle>Поставки и отгрузки</CardTitle>
          </CardHeader>
          <div className='px-5 pb-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              <table className='conte w-full'>
                <tbody>
                  <tr className='group'>
                    <td className='px-6 py-6 first:rounded-l-md last:rounded-r-md'>Отгрузки</td>
                    <td className='px-6 py-6 text-right first:rounded-l-md last:rounded-r-md'>
                      {deal.supplyingStatus && supplyingStatusDict[deal.supplyingStatus]}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {dealInfoAvailable && (
            <>
              <CardHeader>
                <CardTitle>Показатели сделки</CardTitle>
              </CardHeader>
              <IRR
                dealId={id}
                amount={deal?.amount / 100 || 0}
                advanceRate={deal?.advanceRate || 0}
                interestRate={deal?.interestRate || 0}
                durationMonths={deal?.durationMonths || 0}
                comissionRate={deal?.comissionRate || 0}
                insuranceRate={deal?.insuranceKind === DealInsuranceKind.WithoutInsurance ? 0 : insuranceRate}
                vatRate={deal?.kind === Kind.Medicine ? 0 : REGULAR_VAT_RATE}
                advancePaymentDate={deal?.advancePaymentDate}
                agentRate={agentCommissionForIRR}
                retroBonusAmount={retrobonusesAmountForIRR / 100}
              />
            </>
          )}
        </Card>

        {deal?.customerCompany?._id && (
          <CustomerDeals customerCompanyId={`${deal?.customerCompany?._id}`} currentDeal={deal._id} />
        )}
      </div>
      <div className='col-span-2 flex flex-col gap-6'>
        <Tasks targetId={deal?._id} targetType={TaskTargetType.Deal} />

        <div className='grid grid-cols-2 gap-x-5'>
          <CustomerCompanyBalance
            title='Основной баланс'
            customerId={deal?.customerCompany?._id}
            kind={CustomerAccountKind.Main}
            onDone={onPayInvoices}
          />
          <CustomerCompanyBalance
            title='Баланс пеней'
            customerId={deal?.customerCompany?._id}
            kind={CustomerAccountKind.Penalty}
            onDone={onPayInvoices}
          />
        </div>

        <Payments dealId={deal._id} />

        <Comments targetId={deal._id} targetType={CommentTargetType.Deal} />

        <History targetId={deal._id} targetType={HistoryTargetType.Deal} />
      </div>

      <SuspenseModal
        open={!!editTransactionsFormOpenByPaymentId}
        setOpen={() => setEditTransactionsFormOpenByPaymentId(undefined)}
      >
        <div className='z-10 rounded-xl bg-white-0'>
          <TransactionsDateForm
            paymentId={editTransactionsFormOpenByPaymentId as string}
            onDone={() => {
              refetchBasePayments()
              refetchLeasingPayments()
              setEditTransactionsFormOpenByPaymentId(undefined)
            }}
          />
        </div>
      </SuspenseModal>
    </div>
  )
}

export default Info
