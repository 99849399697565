import { useContactApplicationsLazyQuery } from '../../../graphql/schema'
import { AppStatus } from '../../../components/ApplicationStatus'

const useGetConsentPeriod = () => {
  const [contactApplicationsQuery, { loading: loadingConsentPeriod }] = useContactApplicationsLazyQuery()

  const getConsentPeriod = async (contactId: string) => {
    const { data: contactApplicationsData } = await contactApplicationsQuery({
      variables: {
        contactId
      }
    })
    const contactApplications = contactApplicationsData?.contact?.applications?.edges?.filter(
      (e) => e?.node?.status === AppStatus.Deal
    )
    const contactActiveDeals = contactApplications
      ?.map((a) => a?.node?.deal)
      .filter((d) => d?.status === 'payment_reception')

    return [
      contactActiveDeals?.length ? '3' : '2',
      contactActiveDeals?.length ? contactActiveDeals[0]?.contractDate : undefined
    ] as const
  }

  return [getConsentPeriod, loadingConsentPeriod] as const
}

export default useGetConsentPeriod
