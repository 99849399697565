import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ApolloError } from '@apollo/client'
import { ReactComponent as PlusIcon } from '../../svg/icons/plus.svg'
import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'
import { useAddBankDetailMutation, useUpdateBankDetailMutation } from '../../graphql/schema'
import { BankData } from '../../types/dadata'
import useThrottledState from '../../hooks/useThrottledState'
import { useBankSuggestions } from '../../hooks/useSuggestions'
import { handleBackendErrorsToForm } from '../../utils/backendErrorUtils'
import Suggestions from '../../components/Suggestions'
import Input from '../../components/Input'
import SubmitButton from '../../components/SubmitButton'
import { Requisite } from './index'
import MaskedAccountNumberInput from '../Forms/ControlledInputs/MaskedAccountNumberInput.tsx'

interface RequisiteFormProps {
  onDone?: () => void
  entity?: Requisite
  targetId: number
  targetType: string
}

type RequisiteInputs = {
  name: string
  bic: string
  account: string
  correspondentAccount: string
}

const getBankName = (bank: BankData) => bank?.name?.payment || bank?.name?.short || bank?.value || ''

const RequisiteForm: FC<RequisiteFormProps> = ({ onDone, entity, targetId, targetType }) => {
  const {
    register,
    handleSubmit,
    watch,
    setError,
    getValues,
    reset,
    control,
    formState: { errors }
  } = useForm<RequisiteInputs>({
    defaultValues: {
      name: entity?.name,
      bic: entity?.bic,
      account: entity?.account,
      correspondentAccount: entity?.correspondentAccount
    }
  })
  const [addBankDetail, { loading: loadingAdding }] = useAddBankDetailMutation()
  const [updateBankDetail, { loading: loadingUpdating }] = useUpdateBankDetailMutation()

  const bankQuery = watch('name')
  const bic = watch('bic')

  const [throttledBankQuery] = useThrottledState(bankQuery, 500)
  const bankSuggestions = useBankSuggestions(throttledBankQuery)

  const onSubmit: SubmitHandler<RequisiteInputs> = async (data) => {
    if (!entity) {
      await addBankDetail({
        variables: {
          input: {
            ...data,
            [targetType]: targetId.toString()
          }
        }
      })
        .then(() => {
          if (onDone) onDone()
        })
        .catch((err: ApolloError) => {
          handleBackendErrorsToForm<RequisiteInputs>(err, (fieldPath, textError) => {
            setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
          })
        })
    } else {
      await updateBankDetail({
        variables: {
          input: {
            ...data,
            id: entity?._id.toString(),
            [targetType]: targetId.toString()
          }
        }
      })
        .then(() => {
          if (onDone) onDone()
        })
        .catch((err: ApolloError) => {
          handleBackendErrorsToForm<RequisiteInputs>(err, (fieldPath, textError) => {
            setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
          })
        })
    }
  }

  return (
    <section className='md:w-[460px] p-12'>
      <h1 className='font-display text-h200 mb-12'>{entity ? 'Редактирование реквизитов' : 'Новые реквизиты'}</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 flex flex-col gap-8 relative'>
          <Suggestions<BankData>
            highlight={bankQuery}
            suggestions={bankSuggestions?.map((s) => ({
              key: s._id,
              title: getBankName(s),
              subtitle: `БИК: ${s.bic || '–'} Корр. счёт: ${s.correspondent_account || '–'}`,
              payload: s
            }))}
            select={(suggestion) => {
              if (suggestion?.payload) {
                reset({
                  name: getBankName(suggestion.payload),
                  bic: suggestion.payload.bic,
                  correspondentAccount: suggestion.payload.correspondent_account,
                  account: getValues('account')
                })
              }
            }}
          >
            <Input
              label='БИК или Название Банка'
              placeholder='Строка для поиска'
              type='text'
              autoComplete='off'
              {...register('name', {
                required: true,
                validate: {
                  // требуем выбора из предложенных вариантов только если апи подсказок работает
                  fromSuggested: () => {
                    return !bic ? 'Требуется выбрать один из вариантов' : true
                  }
                }
              })}
              error={errors?.name}
            />
          </Suggestions>
          <MaskedAccountNumberInput label='Р/С' name='account' bic={bic} control={control} />
        </div>
        <SubmitButton loading={loadingAdding || loadingUpdating}>
          {!entity ? (
            <>
              <PlusIcon className='mr-5' />
              Создать
            </>
          ) : (
            <>
              <EditIcon className='mr-5' />
              Изменить
            </>
          )}
        </SubmitButton>
      </form>
    </section>
  )
}

export default RequisiteForm
