import { FC } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { useGuarantorCompanyByIdQuery } from '../../graphql/schema'
import CopyInn from '../../components/CopyInn'
import BoNalogLink from '../../components/BoNalogLink'
import {
  EntityHeader,
  EntityInfo,
  EntityName,
  EntityNav,
  EntityNavItem
} from '../../components/EntityPage/EntityHeader'
import { EntityPage } from '../../components/EntityPage/EntityPage'

const Guarantor: FC = () => {
  const { id } = useParams<'id'>()

  const { data, loading, refetch } = useGuarantorCompanyByIdQuery({ variables: { id: `${id}` }, skip: !id })

  const guarantor = data?.guarantorCompany

  if (!guarantor) return null

  return (
    <div className='flex-grow'>
      <EntityHeader>
        <div className='mb-6 flex items-start justify-between'>
          <div>
            <EntityInfo>Компания-поручитель</EntityInfo>
            <div className='mb-6 flex items-center'>
              <EntityName value={guarantor?.shortWithOpf} loading={loading} />
              <CopyInn inn={guarantor?.inn} />
            </div>
          </div>
          <div className='flex flex-none gap-x-4'>
            <BoNalogLink inn={guarantor?.inn} />
          </div>
        </div>
        <EntityNav>
          <EntityNavItem title='Общая информация' to='' />
          <EntityNavItem title='Скоринг' to='scoring' />
          <EntityNavItem title='Документы' to='docs' />
        </EntityNav>
      </EntityHeader>

      <EntityPage>
        <Outlet context={[data, refetch]} />
      </EntityPage>
    </div>
  )
}

export default Guarantor
