import { FC, useState } from 'react'
import * as React from 'react'
import c from 'clsx'
import { Portal } from '@headlessui/react'
import { useWindowEvent } from '../hooks/useWindowEvent'
import useToggle from '../hooks/useToggle'
import { usePopper } from 'react-popper'

interface TooltipProps {
  target: React.ReactNode
  children?: React.ReactNode
  className?: string
}

const Tooltip: FC<TooltipProps> = ({ target, children, className, ...props }) => {
  const [isOpen, toggle, setOpen] = useToggle(false)

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
    modifiers: [
      { name: 'offset', options: { offset: [0, 10] } },
      { name: 'arrow', options: { element: arrowElement, padding: 0 } },
      {
        name: 'flip',
        options: {
          padding: { top: 90, right: 30, bottom: 30, left: 30 }
        }
      },
      {
        name: 'preventOverflow',
        options: {
          padding: 30,
          tetherOffset: 30 + 8 // arrow padding + reference width / 2
        }
      }
    ]
  })

  // закрываем подсказки, когда пользователь кликает вне их
  useWindowEvent('mousedown', (event) => {
    if (popperElement?.contains(event.target as Node)) return
    if (referenceElement?.contains(event.target as Node)) return

    setOpen(false)
  })

  return (
    <div
      {...props}
      ref={setReferenceElement}
      className={c('relative', className)}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <div className='contents' onPointerDown={toggle}>
        {target}
      </div>
      {isOpen && children && (
        <Portal>
          <div style={styles.popper} {...attributes.popper} className='absolute z-50' ref={setPopperElement}>
            <div className='p-6 text-sm rounded-lg text-white-0 leading-6 bg-grayscale-0 sm:max-w-md max-w-screen-p-6'>
              {children}
            </div>
            <div style={styles.arrow} className='tooltip-arrow -z-1' ref={setArrowElement} />
          </div>
        </Portal>
      )}
    </div>
  )
}

export default Tooltip
