const nameDateFormatter = Intl.DateTimeFormat('ru-RU', {
  year: '2-digit',
  month: 'numeric',
  day: 'numeric',
})

const getReconciliationFileName = (recNumber: number, appId: number) => {
  const date = new Date()
  const dateParts = nameDateFormatter.formatToParts(date)
  const day = dateParts[0].value
  const month = dateParts[2].value
  const year = dateParts[4].value
  return `${year}${month}${day}-${appId} КП №${recNumber}.pdf`
}

export default getReconciliationFileName