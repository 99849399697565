import { FC } from 'react'
import { BaseHistoryEventProps } from '../..'
import { EventActedAt, EventInitiatedBy } from '../EventComponents'

export interface ApplicationStatusUpdatedEventProps extends BaseHistoryEventProps {
  data?: {
    previousStatus?: string
    status?: string
  }
}

const ApplicationStatusUpdatedEvent: FC<ApplicationStatusUpdatedEventProps> = ({
  actedAt,
  data,
  withLine,
  initiatedByUserId
}) => {
  return (
    <div className='flex gap-6'>
      <EventActedAt date={actedAt} withLine={withLine} />
      <div>
        <div className='flex items-start gap-2'>
          <EventInitiatedBy userId={initiatedByUserId} />
          <span className='text-grayscale-200'>сменил(а) статус заявки</span>
        </div>
        <div>
          {data?.previousStatus} → {data?.status}
        </div>
      </div>
    </div>
  )
}

export default ApplicationStatusUpdatedEvent
