import { FC, ComponentProps } from 'react'
import c from 'clsx'

const SecondaryButton: FC<ComponentProps<'button'>> = ({ children, className, ...props }) => {
  return (
    <button
      className={c(
        'block p-4 rounded-md text-labels-tertiary bg-surface-secondary hover:text-labels-primary hover:bg-gradient-to-r from-black/5 to-black/5',
        className
      )}
      {...props}
    >
      {children}
    </button>
  )
}

export default SecondaryButton
