import { FC } from 'react'
import { RadioGroup } from '@headlessui/react'
import c from 'clsx'

interface ScoringToggleProps {
  loading?: boolean
  onChange: (value: boolean) => void
  checkedValue: boolean
}

export const ScoringToggle: FC<ScoringToggleProps> = ({ loading, onChange, checkedValue }) => {
  const options = [
    ['Нет', false],
    ['Да', true]
  ]

  if (loading) return <div className='h-21 w-58 rounded-xl bg-grayscale-400' />

  return (
    <RadioGroup
      className={c('grid auto-cols-fr grid-flow-col rounded-xl p-2', checkedValue ? 'bg-red-200' : 'bg-grayscale-450')}
      value={checkedValue}
      onChange={(value) => {
        onChange && onChange(value)
      }}
    >
      {options.map((option, i) => (
        <RadioGroup.Option value={option[1]} key={i}>
          {({ checked }) => (
            <div
              className={c(
                'flex h-17 w-27 cursor-pointer items-center justify-center rounded-md border-1 border-solid',
                checked ? 'border-grayscale-350 bg-white-0' : 'border-grayscale-450'
              )}
            >
              {option[0]}
            </div>
          )}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  )
}

export default ScoringToggle
