import { FC, useMemo } from 'react'
import { ReactComponent as ChevronIcon } from '../../../svg/icons/chevron-slim.svg'
import { Disclosure } from '@headlessui/react'
import {
  ContractData,
  PaymentDiscipline,
  dealTypeDict,
  collateralItemType,
  dealCategoryDict
} from '../../../types/bki.ts'
import Tooltip from '../../../components/Tooltip.tsx'
import parseDecimal from '../../../utils/parseDecimal.ts'
import PaymentOverdueCounter from './PaymentOverdueCounter.tsx'
import ContractState from './ContractState.tsx'
import { addMonthSuffix, addYearSuffix } from '../../../utils/dateFormatter.ts'

const formattedDate = (date: string) => {
  const [day, month, year] = date.split('.')
  return new Date(`${month}-${day}-${year}`)
}

const monthDifference = (dateRaw2: string, dateRaw1: string) => {
  const date2 = formattedDate(dateRaw2)
  const date1 = formattedDate(dateRaw1)
  if (!date2.getTime() || !date1.getTime()) return ''

  let diffMonths = (date2.getFullYear() - date1.getFullYear()) * 12
  diffMonths -= date1.getMonth()
  diffMonths += date2.getMonth()

  return diffMonths > 12
    ? Math.floor(diffMonths / 12) > 50
      ? 'Бессрочно'
      : addYearSuffix(Math.floor(diffMonths / 12))
    : addMonthSuffix(diffMonths)
}

export const paymentMaxOverdue = (paymentOverdue: { [key: string]: number }) => {
  if (paymentOverdue['A']) return { days: '>= 240', value: 'A' }
  if (paymentOverdue['9']) return { days: '210 - 239', value: '9' }
  if (paymentOverdue['8']) return { days: '180 - 209', value: '8' }
  if (paymentOverdue['7']) return { days: '150 - 179', value: '7' }
  if (paymentOverdue['6']) return { days: '120 - 149', value: '6' }
  if (paymentOverdue['5']) return { days: '90 - 119', value: '5' }
  if (paymentOverdue['4']) return { days: '60 - 89', value: '4' }
  if (paymentOverdue['3']) return { days: '30 - 59', value: '3' }
  if (paymentOverdue['2']) return { days: '6 - 29', value: '2' }
  if (paymentOverdue['1']) return { days: '1 - 5', value: '1' }
  return { days: '0', value: '0' }
}

export const formatMoney = (value: number, currency?: string) => {
  let validCurr = 'RUB'
  if (currency?.length && /^[a-zA-Z]+$/.test(currency)) {
    validCurr = currency
  }

  return Intl.NumberFormat('ru-RU', {
    currency: validCurr,
    style: 'currency'
  }).format(value)
}

const dateWithShortYear = (date: string) => {
  if (!date || date === '-') return ''

  const [day, month, year] = date.split('.')
  return `${day}.${month}.${year?.slice(-2)}`
}

interface ContractProps {
  contract: ContractData
  paymentDiscipline?: string[]
  credMaxOverdue?: string
}

const getEndContractYear = (contract: ContractData) => {
  const accountStatus = contract?.extra_data?.find((d) => d['@id'] === '10')
  if (accountStatus && accountStatus['@value'] !== '0') {
    return new Date().getFullYear()
  }
  return formattedDate(contract?.deal?.end_date).getFullYear() <= new Date().getFullYear()
    ? formattedDate(contract?.deal?.end_date).getFullYear()
    : new Date().getFullYear()
}

const Contract: FC<ContractProps> = ({ contract, paymentDiscipline, credMaxOverdue }) => {
  const paymentDisciplineByYear: PaymentDiscipline[] = useMemo(() => {
    const disciplineByYear = []
    const disciplineCopy = paymentDiscipline ? [...paymentDiscipline] : []
    let startDateYear = formattedDate(contract?.deal?.date).getFullYear()
    let startDateMonth = formattedDate(contract?.deal?.date).getMonth()
    let endDateYear = getEndContractYear(contract)
    for (let i = startDateYear; i <= endDateYear; i++) {
      disciplineByYear.push({
        year: i,
        value: new Array(12).fill('').map((value, index) => {
          if (i === endDateYear) {
            return disciplineCopy.shift()
          }
          if (i === startDateYear && startDateMonth > index) {
            return value
          } else {
            return disciplineCopy.shift()
          }
        })
      })
    }

    return disciplineByYear
  }, [contract, paymentDiscipline])

  const paymentOverdue: { [key: string]: number } = useMemo(() => {
    const paymentOverdue = (paymentDiscipline || []).filter((v) =>
      ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'A'].includes(v)
    )
    return paymentOverdue?.length
      ? paymentOverdue.reduce(
          (acc, item) => {
            if (acc[item]) {
              acc[item] = acc[item] + 1
              return acc
            } else {
              acc[item] = 1
              return acc
            }
          },
          {} as { [key: string]: number }
        )
      : {}
  }, [paymentDiscipline])

  const actualDate = contract?.extra_data?.find((d) => d['@id'] === '7')?.['@value']

  const collaterals = contract?.collaterals?.collateral
    ? Array.isArray(contract?.collaterals.collateral)
      ? contract?.collaterals.collateral
      : [contract?.collaterals.collateral]
    : []

  return (
    <div className='cursor-pointer rounded-md bg-surface-primary py-6 shadow-card'>
      <Disclosure>
        <Disclosure.Button className='flex w-full items-center px-6 text-p100'>
          <div className='mr-5'>
            <Tooltip target={<ContractState value={paymentMaxOverdue(paymentOverdue)?.value} />}>
              {paymentMaxOverdue(paymentOverdue)?.days !== '0'
                ? `Макс. просрочка ${paymentMaxOverdue(paymentOverdue)?.days} дней`
                : 'Все платежи в срок'}
            </Tooltip>
          </div>
          <div className='mr-2 flex w-90 items-center truncate text-left ui-open:whitespace-normal'>
            {contract?.deal?.category === '8'
              ? dealCategoryDict[contract?.deal?.category]
              : dealTypeDict[contract?.deal?.type] ||
                (contract?.deal?.ratio === '2' && 'Поручительство') ||
                dealCategoryDict[contract?.deal?.category]}
          </div>
          <div className='mr-2 w-35 text-red-150'>
            {contract?.full_cost?.percent ? contract?.full_cost?.percent + '%' : ''}
          </div>
          <div className='mr-2 w-70 text-grayscale-150'>
            {((contract?.deal?.date && contract?.deal?.date !== '-') ||
              (contract?.deal?.end_date && contract?.deal?.end_date !== '-')) &&
              `${dateWithShortYear(contract?.deal?.date)} - ${dateWithShortYear(contract?.deal?.end_date)}`}
          </div>
          <div className='mr-2 w-50 text-grayscale-150'>
            {monthDifference(contract?.deal?.end_date, contract?.deal?.date)}
          </div>
          {contract?.contract_amount?.sum && (
            <span className='text-grayscale-150'>
              {contract?.contract_amount?.sum && parseDecimal(contract?.contract_amount?.sum)
                ? formatMoney(parseDecimal(contract?.contract_amount?.sum), contract?.contract_amount?.currency)
                : ''}
            </span>
          )}
          <ChevronIcon className='ml-auto mr-5 transition-transform ui-open:-rotate-180' />
        </Disclosure.Button>

        <Disclosure.Panel className='cursor-auto'>
          <div className='mt-15 flex gap-6 px-6'>
            <table className='w-full'>
              <tbody>
                <tr className='group'>
                  <td className='px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    Выплачено
                  </td>
                  <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    {contract?.payments?.paid_sum && parseDecimal(contract?.payments?.paid_sum)
                      ? formatMoney(parseDecimal(contract?.payments?.paid_sum), contract?.contract_amount?.currency)
                      : '0 ₽'}
                  </td>
                </tr>
                <tr className='group'>
                  <td className='px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    Остаток долга
                  </td>
                  <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    {contract?.debt?.sign === '1' && contract?.debt?.sum && parseDecimal(contract?.debt?.sum)
                      ? formatMoney(parseDecimal(contract?.debt?.sum), contract?.contract_amount?.currency)
                      : '—'}
                  </td>
                </tr>
                <tr className='group'>
                  <td className='px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    Ежемесячные платежи
                  </td>
                  <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    {formatMoney(
                      parseDecimal(contract?.average_payment?.sum || '0'),
                      contract?.contract_amount?.currency
                    )}
                  </td>
                </tr>
              </tbody>
            </table>

            <table className='w-full'>
              <tbody>
                <tr className='group'>
                  <td className='px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    Актуально до
                  </td>
                  <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    {actualDate || '—'}
                  </td>
                </tr>
                <tr className='group'>
                  <td className='px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    Макс. просрочка в днях
                  </td>
                  <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    {paymentMaxOverdue(paymentOverdue)?.days}
                  </td>
                </tr>
                <tr className='group'>
                  <td className='px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    Макс. просрочка в деньгах
                  </td>
                  <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                    {formatMoney(parseDecimal(credMaxOverdue || '0'), contract?.contract_amount?.currency)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {contract?.collaterals?.sign === '1' &&
            collaterals?.map((collateral, index) => (
              <div key={collateral.id} className='mt-10 border-t border-grayscale-400 px-6'>
                <div className='mt-6'>{collaterals?.length > 1 ? `Залог ${index + 1}` : 'Залог'}</div>

                <div className='mt-6 flex gap-6'>
                  <table className='w-full'>
                    <tbody>
                      <tr className='group'>
                        <td className='px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                          Тип залога
                        </td>
                        <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                          {collateral?.item_type ? collateralItemType[collateral.item_type] : '—'}
                        </td>
                      </tr>
                      <tr className='group'>
                        <td className='px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                          Сумма залога
                        </td>
                        <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                          {formatMoney(parseDecimal(collateral?.sum || '0'), collateral?.currency)}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table className='w-full'>
                    <tbody>
                      <tr className='group'>
                        <td className='px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                          Дата начала залога
                        </td>
                        <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                          {collateral?.date}
                        </td>
                      </tr>
                      <tr className='group'>
                        <td className='px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                          Дата окончания залога
                        </td>
                        <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                          {collateral?.end_date}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ))}

          <div className='mt-8 border-t border-grayscale-400 px-6'>
            <div className='mt-6'>Платежная дисциплина</div>

            <PaymentOverdueCounter paymentOverdue={paymentOverdue} />
          </div>

          {!!paymentDiscipline?.length && !!paymentDisciplineByYear?.length && (
            <div className='mt-8 border-t border-grayscale-400 px-6'>
              <table className='mt-15 border-separate border-spacing-2 text-center'>
                <thead>
                  <tr>
                    {new Array(13).fill(0).map((_, index) => (
                      <td key={index} className='w-20 text-h200 text-grayscale-150'>
                        {index ? index : ''}
                      </td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paymentDisciplineByYear?.map((discipline) => (
                    <tr key={discipline?.year}>
                      <td className='pr-10 text-h200 text-grayscale-150'>{discipline?.year}</td>
                      {discipline?.value?.map((state, index) =>
                        !!state ? (
                          <td key={index}>
                            <ContractState value={state} />
                          </td>
                        ) : (
                          <td key={index}></td>
                        )
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {!!paymentDiscipline?.length && (
            <div className='mt-15 border-t border-grayscale-400 px-6'>
              <div className='mt-10 grid grid-flow-row auto-rows-fr grid-cols-2 gap-3'>
                {Array.from(new Set(paymentDiscipline))?.map((state, index) => (
                  <div key={state + index} className='flex items-center gap-3'>
                    <ContractState value={state} withDescription />
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className='mt-10 border-t border-grayscale-400 px-6'>
            <div className='mt-6'>Судебные споры</div>

            <div className='mt-6 flex gap-6'>
              <table className='w-full'>
                <tbody>
                  <tr className='group'>
                    <td className='px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                      Наличие судебного акта
                    </td>
                    <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                      {contract?.court?.sign === '1' ? 'Да' : 'Нет'}
                    </td>
                  </tr>
                  <tr className='group'>
                    <td className='px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                      Номер судебного акта
                    </td>
                    <td
                      className='max-w-[150px] truncate px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'
                      title={contract?.court?.court_act?.no}
                    >
                      {contract?.court?.court_act?.no || '—'}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className='w-full'>
                <tbody>
                  <tr className='group'>
                    <td className='px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                      Дата судебного акта
                    </td>
                    <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                      {contract?.court?.court_act?.date || '—'}
                    </td>
                  </tr>
                  <tr className='group'>
                    <td className='px-8 py-6 text-grayscale-150 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                      Акт вступил в силу
                    </td>
                    <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                      {contract?.court?.sign === '1'
                        ? contract?.court?.court_act?.accepted === '1'
                          ? 'Да'
                          : 'Нет'
                        : '—'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className='mt-6 flex items-center gap-3 px-8 text-xs text-grayscale-150'>
            <div>ID Договора: {contract?.id}</div>
            <div>Источник: БКИ Эквифакс</div>
          </div>
        </Disclosure.Panel>
      </Disclosure>
    </div>
  )
}

export default Contract
