import { FC, Suspense } from 'react'
import { PaymentCollectionProcessStatus, PaymentCollectionProcessStatus as Status } from '../../../graphql/schema'
import { collectionStatusDict } from '../../../utils/dictionaries'
import { Menu, MenuButton, MenuItems, Transition } from '@headlessui/react'
import CollectionStatus from '../../../components/CollectionStatus'
import useChangeCollectionStatus from './useChangeCollectionStatus'
import { ReactComponent as LoadingIcon } from './../../../svg/icons/loading.svg'
import { ReactComponent as MoreIcon } from './../../../svg/icons/more.svg'

const listStatuses: Status[] = Object.keys(collectionStatusDict) as Status[]

interface CollectionStatusSwitcherProps {
  paymentId: number
  collectionProcessId: string
  status: PaymentCollectionProcessStatus
  onChangeStatus: (status: Status) => void
}

interface CollectionStatusSwitcherInnerProps {
  paymentId: number
  changeStatus: (status: Status) => Promise<void>
  onChangeStatus: (status: Status) => void
}

const CollectionStatusSwitcherInner: FC<CollectionStatusSwitcherInnerProps> = ({ changeStatus, onChangeStatus }) => {
  return (
    <>
      {listStatuses?.map((status) => {
        return (
          <Menu.Item key={status}>
            <div
              onClick={() => {
                changeStatus(status)
                onChangeStatus(status)
              }}
              className='flex cursor-pointer items-center gap-8 px-8 py-5 first:rounded-t-xl last:rounded-b-xl hover:bg-grayscale-400'
            >
              <CollectionStatus status={status} />
              {collectionStatusDict[status]}
            </div>
          </Menu.Item>
        )
      })}
    </>
  )
}

const CollectionStatusSwitcher: FC<CollectionStatusSwitcherProps> = ({
  paymentId,
  collectionProcessId,
  status,
  onChangeStatus
}) => {
  const [changeStatus, loading] = useChangeCollectionStatus(collectionProcessId)

  return (
    <div className='relative'>
      <Menu>
        <MenuButton className='flex w-full items-center rounded-xl border-1 border-grayscale-400 bg-white-0 px-4 font-medium text-grayscale-150'>
          <CollectionStatus status={status} />
          <div className='ml-6'>{collectionStatusDict[status]}</div>
          <div className='ml-4'>
            {loading ? (
              <LoadingIcon className='h-7 w-7 animate-spin text-red-100' />
            ) : (
              <MoreIcon className='pointer-events-none rotate-90 text-grayscale-150' />
            )}
          </div>
        </MenuButton>

        <Suspense
          fallback={
            <MenuItems
              unmount={true}
              className='absolute mt-4 w-full rounded-xl border-[1px] border-grayscale-400 bg-white-0 text-p200 font-medium'
            >
              <div className='py-10'>
                <LoadingIcon className='mx-auto animate-spin text-red-100' />
              </div>
            </MenuItems>
          }
        >
          <Transition
            as='div'
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <MenuItems
              unmount={true}
              className='absolute z-50 mt-4 w-fit rounded-xl border-[1px] border-grayscale-400 bg-white-0 text-p200 font-medium'
            >
              <CollectionStatusSwitcherInner
                paymentId={paymentId}
                changeStatus={changeStatus}
                onChangeStatus={onChangeStatus}
              />
            </MenuItems>
          </Transition>
        </Suspense>
      </Menu>
    </div>
  )
}

export default CollectionStatusSwitcher
