import { FC } from 'react'
import { useDealsListQuery, DealsListQuery, DealStatus } from '../../graphql/schema'
import useScrollPagination from '../../hooks/useScrollPagination'
import { formatDecimal } from '../../utils/formatNumber'
import { supplyingStatusDict } from '../../utils/dictionaries'
import GridView, { GridViewConfig } from '../../components/GridView'
import { NodeType } from '../../types'
import useNodes from '../../hooks/useNodes'
import { useParams } from 'react-router-dom'
import { getFormattedContractNumber } from '../../utils/contractUtils'
import AvatarTooltip from '../../components/Avatar/AvatarTooltip'
import { DealStatusBadge } from '../../components/DealStatus'
import Tooltip from '../../components/Tooltip'

const dateFormatter = Intl.DateTimeFormat('ru-RU')

type Deal = NodeType<DealsListQuery['deals']>
const dealTableConfig: GridViewConfig<Deal> = {
  grid: 'grid-cols-deal-l',
  columns: [
    { title: 'ID', value: '_id' },
    { title: 'Клиент', value: (d) => d?.customerCompany?.shortWithOpf },
    { title: 'Номер договора', value: (d) => getFormattedContractNumber(d.contractNumber) },
    { title: 'Дата договора', value: (d) => d.contractDate && dateFormatter.format(new Date(d.contractDate)) },
    { title: 'Сумма сделки, ₽', value: (d) => formatDecimal(d.amount) },
    { title: 'Отгрузка', value: (d) => d.supplyingStatus && supplyingStatusDict[d.supplyingStatus] },
    {
      title: 'МЛ',
      titleClassName: 'text-center',
      padding: false,
      value: (d) => <AvatarTooltip className='mx-auto' userId={d?.user?._id} />
    },
    {
      title: 'Статус',
      padding: false,
      value: (d) => (
        <div className='flex px-[5px]'>
          <Tooltip target={<DealStatusBadge status={d.status} />}>
            {d.status === DealStatus.Terminated && d.terminationReason}
          </Tooltip>
        </div>
      )
    }
  ],
  rows: {
    link: (d) => `/deals/${d._id}`
  }
}

const Deals: FC = () => {
  const { id } = useParams<'id'>()
  const {
    data: dealsData,
    fetchMore,
    loading,
    refetch,
    error
  } = useDealsListQuery({
    fetchPolicy: 'cache-and-network',
    variables: { source: parseInt(id!), order: [{ id: 'desc' }] },
    skip: !id
  })

  const dealsList = useNodes(dealsData?.deals?.edges)

  const { wrapperRef: triggerRef, isFetching } = useScrollPagination(fetchMore, dealsData?.deals?.pageInfo)

  return (
    <div className='flex-grow container' ref={triggerRef}>
      <div className='bg-surface-secondary rounded-xl p-5 mb-4'>
        <GridView
          data={dealsList}
          config={dealTableConfig}
          loading={loading || isFetching}
          error={error?.message}
          refetch={refetch}
        />
      </div>
    </div>
  )
}

export default Deals
