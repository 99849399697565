import { FC } from 'react'
import GridView, { GridViewConfig } from '../GridView/index'
import { useSourcesListQuery } from '../../graphql/schema'
import useNodes from '../../hooks/useNodes'
import SourceApplicationsCount from './useSourceApplicationsCount'

// минимальный набор полей для отображения в таблице
export interface SourcesListConfig {
  _id?: number
  name?: string
}

const tableConfig: GridViewConfig<SourcesListConfig> = {
  grid: 'grid-cols-auto-3',
  columns: [
    { title: 'ID', value: '_id' },
    { title: 'Название', value: 'name' },
    {
      title: 'Количество заявок',
      value: (s) => <SourceApplicationsCount sourceId={s?._id?.toString()!} />
    }
  ],
  rows: {
    link: (s) => `/sources/${s?._id}`
  }
}

interface SourcesListProps {
  inn: string
}

const SourcesList: FC<SourcesListProps> = ({ inn }) => {
  const {
    data: sourcesData,
    loading,
    error
  } = useSourcesListQuery({
    variables: {
      query: inn
    },
    skip: !inn
  })
  const sources = useNodes(sourcesData?.sources?.edges)

  return !!sources.length ? (
    <GridView data={sources} config={tableConfig} loading={loading} error={error?.message} />
  ) : (
    <p className='pb-10 text-center text-grayscale-200'>Список пуст</p>
  )
}

export default SourcesList
