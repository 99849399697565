import { RefreshTokenMutation, RefreshTokenMutationVariables, RefreshTokenDocument } from './schema'
import { jwtVar, refreshTokenVar } from './cache'
import createAuthRefreshLink from '../utils/createAuthRefreshLink'
import { authClient } from './client'
import parseJWT from '../utils/parseJWT'

async function getRefreshedAccessTokenPromise() {
  console.log('token refresh started')
  try {
    // fetch new token

    // we must take refreshToken from localStorage instead of memory because
    // when multiple tabs are used if refresh happens in another tab
    // stored refresh token becomes stale and will trigger auth error and logout if used in refresh query
    const refresh = localStorage.getItem('refreshToken')
    if (!refresh) throw new Error('Refresh impossible: missing refresh token')

    const { data } = await authClient.mutate<RefreshTokenMutation, RefreshTokenMutationVariables>({
      mutation: RefreshTokenDocument,
      variables: {
        refreshToken: refresh,
      },
      context: {
        headers: {
          authorization: `Bearer ${jwtVar()}`,
        }
      }
    })

    // handle result and return token
    const token = data?.refreshTokenUser?.user?.jwt
    const refreshToken = data?.refreshTokenUser?.user?.refreshToken
    jwtVar(token)
    refreshTokenVar(refreshToken)
    if (!token) throw new Error('Server returned no token')
    return token
  } catch (error) {
    // logout, show alert or something
    console.log('error refreshing token', error)
    jwtVar(undefined)
    refreshTokenVar(undefined)
    throw (error)
  }
}

export default createAuthRefreshLink({
  getToken: () => jwtVar(),
  refreshToken: getRefreshedAccessTokenPromise,
  isTokenExpired: () => {
    const token = parseJWT(jwtVar())
    if (!token) return true

    const currentNumericDate = Math.round(Date.now() / 1000)
    return token.exp - currentNumericDate < 60
  }
})