import { useMemo } from 'react'
import { NodeType } from '../types'
import { UsersQuery } from '../graphql/schema'

type User = NodeType<UsersQuery['users']>

export default function useFilteredUsers(users: User[], query: string): User[] {
  return useMemo(() => {
    if (query) {
      const queryParts = query.toLowerCase().replace(/\s\s/g, ' ').trim().split(' ')
      return users.filter(
        (user) =>
          queryParts.every(
            (part) => user?.name.toLowerCase().includes(part) || user?.surname.toLowerCase().includes(part)
          ) &&
          (user.name || user.surname)
      )
    }
    return users.filter((user) => user.name || user.surname)
  }, [query, users])
}
