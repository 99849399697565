import Input from '../Input'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ReactComponent as PlusIcon } from '../../svg/icons/plus.svg'
import { useAddContactInfoMutation, useUpdateContactInfoMutation, useContactInfoQuery } from '../../graphql/schema'
import { EMAIL_PATTERN, PHONE_MASK } from '../../utils/constants'
import MaskedInput from '../MaskedInput'
import { handleBackendErrorsToForm } from '../../utils/backendErrorUtils'
import { ApolloError } from '@apollo/client'
import SubmitButton from '../SubmitButton/index'
import { formatPhoneNumber } from '../../utils/formatPhoneNumber'
import { useEffect } from 'react'
import { ContactInfoKind } from './ContactInfo'

type Inputs = {
  phone?: string
  name?: string
  email?: string
  comment?: string
}

const preparePhoneForApi = (phone?: string) => (phone?.length ? '+' + phone.replace(/\D/g, '') : '')

interface CreateContactFormProps {
  onDone?: (contactId?: number) => void | Promise<void>
  contactInfoId?: number
  targetId: number
  targetType: ContactInfoKind
}

const ContactInfoForm: React.FC<CreateContactFormProps> = ({ onDone, contactInfoId, targetId, targetType }) => {
  const [addContactInfo, { loading: addLoading }] = useAddContactInfoMutation()
  const [updateContactInfo, { loading: updateLoading }] = useUpdateContactInfoMutation()
  const { data: contactInfoData } = useContactInfoQuery({
    variables: { id: contactInfoId?.toString() as string },
    skip: !contactInfoId
  })

  const form = useForm<Inputs>({
    defaultValues: {
      phone: formatPhoneNumber(contactInfoData?.contactInfo?.phone),
      name: contactInfoData?.contactInfo?.fio,
      email: contactInfoData?.contactInfo?.email,
      comment: contactInfoData?.contactInfo?.comment
    }
  })

  const { register, handleSubmit, formState, setError, reset } = form

  useEffect(() => {
    reset({
      phone: formatPhoneNumber(contactInfoData?.contactInfo?.phone),
      name: contactInfoData?.contactInfo?.fio,
      email: contactInfoData?.contactInfo?.email,
      comment: contactInfoData?.contactInfo?.comment
    })
  }, [contactInfoData, reset])

  const addMode = contactInfoId === undefined

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (addMode) {
      await addContactInfo({
        variables: {
          input: {
            email: data.email || '',
            fio: data.name || '',
            phone: preparePhoneForApi(data.phone),
            comment: data.comment || '',
            [targetType]: targetId.toString()
          }
        }
      })
        .then((result) => {
          if (onDone) onDone(result.data?.addContactInfo?.contactInfo?._id)
        })
        .catch((err: ApolloError) => {
          handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
            setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
          })
        })
    } else {
      await updateContactInfo({
        variables: {
          input: {
            id: contactInfoId.toString(),
            email: data.email || '',
            fio: data.name || '',
            comment: data.comment || '',
            phone: preparePhoneForApi(data.phone)
          }
        }
      })
        .then((result) => {
          if (onDone) onDone(result.data?.updateContactInfo?.contactInfo?._id)
        })
        .catch((err: ApolloError) => {
          handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
            setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
          })
        })
    }
  }

  return (
    <section className='md: w-[448px] p-12'>
      <h1 className='font-display text-h200 mb-12'>{addMode ? 'Новый контакт' : 'Редактирование контакта'}</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 flex flex-col gap-8'>
          <Input
            label='Название'
            type='text'
            {...register('name', { required: true })}
            error={formState.errors?.name}
          />
          <MaskedInput
            label='Телефон'
            inputMode='tel'
            type='text'
            mask={PHONE_MASK}
            placeholder='+7 987 654 32 10'
            {...register('phone', {
              minLength: {
                value: 16,
                message: 'Введите номер телефона полностью'
              }
            })}
            error={formState.errors.phone}
          />
          <Input
            label='Email'
            type='text'
            placeholder='mail@example.com'
            {...register('email', {
              pattern: {
                value: EMAIL_PATTERN,
                message: 'Некорректный E-mail'
              }
            })}
            error={formState.errors?.email}
          />
          <Input label='Комментарий' type='text' {...register('comment')} error={formState.errors?.comment} />
        </div>
        <SubmitButton loading={addLoading || updateLoading}>
          <PlusIcon className='mr-5' />
          {addMode ? 'Создать' : 'Сохранить'}
        </SubmitButton>
      </form>
    </section>
  )
}

export default ContactInfoForm
