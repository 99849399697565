import useAccessCheck from '../../../hooks/useAccessCheck.ts'
import { useMemo } from 'react'
import { DealStatus } from '../../../graphql/schema.tsx'

const usePaymentsAccessCheck = (dealStatus?: DealStatus) => {
  const hasAccessPaymentsManageAfterDealSigning = useAccessCheck('deal.payments.manage_after_signing')
  const hasAccessServicePaymentsCreateAfterDealActivation = useAccessCheck(
    'deal.service_payments.create_after_deal_activation'
  )
  const hasAccessPenaltyPaymentsCreate = useAccessCheck('deal.penalty_payments.create')
  const hasAccessPenaltyPaymentsDelete = useAccessCheck('deal.penalty_payments.delete')
  const hasAccessPaymentsEditTransactionsDate = useAccessCheck('deal.payments.edit_transactions_date')
  const hasAccessPayPayment = useAccessCheck('deal.payments.pay_payment')
  const hasAccessDebtTransferServiceCreateAfterDealActivation = useAccessCheck(
    'deal.debt_transfer_payment.create_after_deal_activation'
  )

  const canPaymentsManage = useMemo(() => {
    if (dealStatus) {
      if (
        [DealStatus.Signing, DealStatus.Activation, DealStatus.PaymentReception, DealStatus.Closed].includes(dealStatus)
      ) {
        return hasAccessPaymentsManageAfterDealSigning
      }
      return true
    }
    return false
  }, [dealStatus, hasAccessPaymentsManageAfterDealSigning])

  const canServicePaymentsCreate = useMemo(() => {
    if (dealStatus) {
      if ([DealStatus.PaymentReception, DealStatus.Closed].includes(dealStatus)) {
        return hasAccessServicePaymentsCreateAfterDealActivation
      }
      return true
    }
    return true
  }, [dealStatus, hasAccessServicePaymentsCreateAfterDealActivation])

  const canDebtTransferServicePaymentsCreate = useMemo(() => {
    if (dealStatus) {
      if ([DealStatus.PaymentReception, DealStatus.Closed].includes(dealStatus)) {
        return hasAccessDebtTransferServiceCreateAfterDealActivation
      }
      return true
    }
    return true
  }, [dealStatus, hasAccessDebtTransferServiceCreateAfterDealActivation])

  return {
    canPaymentsManage,
    canServicePaymentsCreate,
    canDebtTransferServicePaymentsCreate,
    canPenaltyPaymentsCreate: hasAccessPenaltyPaymentsCreate,
    canPenaltyPaymentsDelete: hasAccessPenaltyPaymentsDelete,
    canPaymentsEditTransactionsDate: hasAccessPaymentsEditTransactionsDate,
    canPayPayment: hasAccessPayPayment
  } as const
}

export default usePaymentsAccessCheck
