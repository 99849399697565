import { FC, useState } from 'react'
import { dateFormatter } from '../../utils/dateFormatter'
import { EngagePartnerStatus, SuppliersListQuery, useSuppliersListQuery } from '../../graphql/schema'
import Modal from '../../components/Modal'
import SupplierForm from './SupplierForm'
import useScrollPagination from '../../hooks/useScrollPagination'
import AddButton from '../../components/AddButton'
import SearchBar, { FilterButton } from '../../components/Search'
import SupplierFilterForm, { useSupplierFilter } from './FilterForm'
import GridView, { GridViewConfig } from '../../components/GridView'
import { NodeType } from '../../types'
import useNodes from '../../hooks/useNodes'
import useUpdateEffect from '../../hooks/useUpdateEffect'
import useAccessCheck from '../../hooks/useAccessCheck'
import SelectedFilters from './SelectedFilters'
import { SupplierStatusBadge } from '../Supplier/SupplierStatus.tsx'
import AvatarTooltip from '../../components/Avatar/AvatarTooltip.tsx'
import Tooltip from '../../components/Tooltip.tsx'

type Supplier = NodeType<SuppliersListQuery['supplierCompanies']>
const supplierTableConfig: GridViewConfig<Supplier> = {
  grid: 'grid-cols-supplier',
  columns: [
    { title: 'ID', value: '_id', numeric: true },
    { title: 'Поставщик', value: (s) => <span className='line-clamp-1 max-w-xl'>{s?.shortWithOpf}</span> },
    {
      title: 'Рамка',
      value: (s) => (s?.frameContractDocumentDate ? s?.frameContractDocumentNumber : '—')
    },
    { title: 'Дата добавления', value: (s) => dateFormatter.format(new Date(s.created)), numeric: true },
    {
      title: 'Привлечение',
      padding: false,
      value: (s) => (
        <div className='flex px-[5px]'>
          <Tooltip target={<SupplierStatusBadge status={s.engagePartnerStatus} />}>
            {s.engagePartnerStatus === EngagePartnerStatus.Hold &&
              s.engagePartnerHoldStatusSetAt &&
              `Отложено с ${dateFormatter.format(new Date(s.engagePartnerHoldStatusSetAt))}`}
          </Tooltip>
        </div>
      )
    },
    {
      title: 'МП',
      titleClassName: '!text-center',
      padding: false,
      value: (s) => <AvatarTooltip className='mx-auto' userId={s?.engagePartnerBdmUser?._id} />
    }
  ],
  rows: {
    link: (s) => `/suppliers/${s?._id}`
  }
}

const Suppliers: FC = () => {
  const [formOpen, setFormOpen] = useState(false)

  const { filterState, setFilterState, filterActive, variables, query, setQuery } = useSupplierFilter()

  const {
    data: suppliersCompaniesListQuery,
    refetch,
    fetchMore,
    loading,
    error
  } = useSuppliersListQuery({
    nextFetchPolicy: 'cache-and-network',
    variables
  })
  const supplierCompaniesList = useNodes(suppliersCompaniesListQuery?.supplierCompanies?.edges)

  const hasAccessSupplierCompanyCreate = useAccessCheck('suppliers_company.create')

  useUpdateEffect(() => {
    refetch(variables)
  }, [variables, refetch])

  const { wrapperRef, isFetching } = useScrollPagination(
    fetchMore,
    suppliersCompaniesListQuery?.supplierCompanies?.pageInfo
  )

  return (
    <div className='py-15 px-6 flex-grow container'>
      <div className='bg-surface-secondary rounded-xl p-5 mb-8'>
        <div className='bg-white-0 shadow-xs rounded-md'>
          <div className='flex justify-between items-start p-10 border-grayscale-400 border-b-1'>
            <div>
              <h1 className='font-display text-h100'>Компании-поставщики</h1>
            </div>
          </div>
        </div>
      </div>

      <SearchBar onChange={setQuery} query={query}>
        <FilterButton active={filterActive}>
          <SupplierFilterForm state={filterState} onChange={setFilterState} />
        </FilterButton>
      </SearchBar>

      <SelectedFilters />

      <div className='bg-surface-secondary rounded-xl p-5' ref={wrapperRef}>
        {hasAccessSupplierCompanyCreate && (
          <AddButton className='mb-5' onClick={() => setFormOpen(true)}>
            Новый поставщик
          </AddButton>
        )}
        <GridView
          data={supplierCompaniesList}
          config={supplierTableConfig}
          loading={loading || isFetching}
          error={error?.message}
        />
      </div>
      <Modal open={formOpen} setOpen={setFormOpen}>
        <div className='bg-white-0 rounded-xl z-10'>
          <SupplierForm
            onDone={() => {
              refetch()
              setFormOpen(false)
            }}
          />
        </div>
      </Modal>
    </div>
  )
}

export default Suppliers
