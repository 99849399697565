import { FC, memo } from 'react'

interface HighlightedProps {
  text: string
  highlight?: string
  classMarkName: string
}

const Highlighted: FC<HighlightedProps> = ({ text, highlight, classMarkName }) => {
  if (!highlight || !highlight?.trim()) {
    return <span>{text}</span>
  }
  const regExpDelBrackets = /[()]/g
  const regex = new RegExp(`(${highlight.replace(regExpDelBrackets, '')})`, 'gi')
  const parts = text.split(regex)

  return (
    <span>
      {parts.map((part, i) => {
        return regex.test(part.replace(regExpDelBrackets, '')) ? (
          <mark key={i} className={classMarkName}>
            {part}
          </mark>
        ) : (
          <span key={i}>{part}</span>
        )
      })}
    </span>
  )
}
export default memo(Highlighted)
