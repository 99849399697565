import { FC } from 'react'
import { DealsListQuery, useCustomerCompanyDealsQuery } from '../../graphql/schema'
import useScrollPagination from '../../hooks/useScrollPagination'
import { formatDecimal } from '../../utils/formatNumber'
import { supplyingStatusDict } from '../../utils/dictionaries'
import GridView, { GridViewConfig } from '../../components/GridView'
import { NodeType } from '../../types'
import useNodes from '../../hooks/useNodes'
import { useParams } from 'react-router-dom'
import { getFormattedContractNumber } from '../../utils/contractUtils'
import AvatarTooltip from '../../components/Avatar/AvatarTooltip'
import { DealStatusBadge } from '../../components/DealStatus'

const dateFormatter = Intl.DateTimeFormat('ru-RU')

type Deal = NodeType<DealsListQuery['deals']>
const dealTableConfig: GridViewConfig<Deal> = {
  grid: 'grid-cols-deal',
  columns: [
    { title: 'ID', value: '_id', numeric: true },
    { title: 'Номер договора', value: (d) => getFormattedContractNumber(d.contractNumber) },
    { title: 'Дата договора', value: (d) => d.contractDate && dateFormatter.format(new Date(d.contractDate)) },
    { title: 'Сумма сделки, ₽', value: (d) => formatDecimal(d.amount), numeric: true },
    { title: 'Отгрузка', value: (d) => d.supplyingStatus && supplyingStatusDict[d.supplyingStatus] },
    {
      title: 'МЛ',
      titleClassName: 'text-center',
      padding: false,
      value: (d) => <AvatarTooltip className='mx-auto' userId={d?.user?._id} />
    },
    {
      title: 'Статус',
      padding: false,
      value: (d) => (
        <div className='px-[5px]'>
          <DealStatusBadge status={d.status} />
        </div>
      )
    }
  ],
  rows: {
    link: (d) => `/deals/${d._id}`
  }
}

const Deals: FC = () => {
  const { id } = useParams<'id'>()
  const { data, fetchMore, loading, refetch, error } = useCustomerCompanyDealsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { id: id!, order: [{ id: 'desc' }] },
    skip: !id
  })

  const dealsList = useNodes(data?.customerCompany?.deals?.edges) as Deal[]

  const { wrapperRef: triggerRef, isFetching } = useScrollPagination(fetchMore, data?.customerCompany?.deals?.pageInfo)

  return (
    <div className='flex-grow container' ref={triggerRef}>
      <div className='bg-surface-secondary rounded-xl p-5 mb-4'>
        <GridView
          data={dealsList}
          config={dealTableConfig}
          loading={loading || isFetching}
          error={error?.message}
          refetch={refetch}
        />
      </div>
    </div>
  )
}

export default Deals
