import { FC, useEffect, useRef } from 'react'
import { usePortfolioQuery } from '../../graphql/schema'
import Counter from '../../components/Counter'
import confetti from 'canvas-confetti'
import { ReactComponent as Comma } from '../../svg/graphics/comma.svg'

const GOAL = Math.pow(10, 9)

const TresCommas: FC = () => {
  const { data } = usePortfolioQuery({
    pollInterval: 5000,
  })
  const amount = Math.round(data?.portfolio?.amount ?? 0)

  const lastAmountRef = useRef<number>(0)

  useEffect(() => {
    const lastAmount = lastAmountRef.current
    let timeout: NodeJS.Timeout
    if (lastAmount !== undefined && lastAmount < GOAL && amount >= GOAL) {
      const end = Date.now() + (15 * 1000)

      const colors = ['#bb0000', '#ff3939']

      timeout = setTimeout(() => {
        (function frame() {
          confetti({
            particleCount: 2,
            angle: 60,
            spread: 55,
            origin: { x: 0 },
            colors: colors
          })
          confetti({
            particleCount: 2,
            angle: 120,
            spread: 55,
            origin: { x: 1 },
            colors: colors
          })

          if (Date.now() < end) {
            requestAnimationFrame(frame)
          }
        }())
      }, 4000)


      lastAmountRef.current = amount

      return () => clearTimeout(timeout)
    }
  }, [amount])

  return (
    <div className='flex-grow container min-h-screen flex flex-col items-center justify-center'>
      <div className='flex items-center gap-16 justify-center'>
        <Comma className='w-40' />
        <Comma className='w-40' />
        <Comma className='w-40' />
      </div>

      <div className='flex text-[60px]'>
        <div className='font-bold mr-8'>
          <Counter value={amount} />
        </div>
        <div className='leading-full'>₽</div>
      </div>
      <div className='relative w-200 h-2 rounded-full bg-grayscale-350 mt-4 mb-2'>
        <div className='absolute top-0 left-0 h-full rounded-full bg-red-50 transition-all duration-3s' style={{ width: `${Math.min(100, Math.round(amount / GOAL * 100))}%` }}></div>
      </div>
      <h1 className='font-display text-center mb-30 text-grayscale-150'>Fera Tres Comas milestone</h1>

    </div>
  )
}

export default TresCommas
