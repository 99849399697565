import { FC } from 'react'
import { useParams } from 'react-router-dom'
import ApplicationsList from '../../components/ApplicationsList'
import { Application, useCustomerCompanyApplicationsQuery } from '../../graphql/schema'
import useScrollPagination from '../../hooks/useScrollPagination'

const Applications: FC = () => {
  const { id } = useParams<'id'>()

  const { data, fetchMore, loading, error } = useCustomerCompanyApplicationsQuery({
    variables: { customerCompanyId: id?.toString()! },
    skip: !id
  })
  const applicationsList: Application[] =
    data?.customerCompany?.applications?.edges?.map((edge) => edge?.node as Application) || []

  const { wrapperRef: triggerRef, isFetching } = useScrollPagination(
    fetchMore,
    data?.customerCompany?.applications?.pageInfo
  )

  return (
    <div className='flex-grow container' ref={triggerRef}>
      <div className='bg-surface-secondary rounded-xl p-5 mb-4'>
        <ApplicationsList loading={loading || isFetching} applicationsList={applicationsList} error={error?.message} />
      </div>
    </div>
  )
}

export default Applications
