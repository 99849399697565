import { FC, ReactNode } from 'react'
import { DocumentTypeEntity } from '../../graphql/schema'
import useDocumentTypes from './useDocumentTypes'
import EntityFolder from './EntityFolder'
import FolderZip from './FolderZip'
import Columns from '../Columns'
import { entityTypeDict } from '../../utils/dictionaries'
import { Card } from '../Card'

interface DocumentManagerProps {
  entityId: number
  entityType: DocumentTypeEntity
  children?: ReactNode
}

const COLS = 3

const DocumentManager: FC<DocumentManagerProps> = ({ entityId, entityType, children }) => {
  const docTypes = useDocumentTypes(entityType)

  return (
    <Card>
      <div className='gap-5 grid grid-cols-3 px-5 pt-5'>
        <Columns n={COLS}>
          {docTypes?.map((docType) => (
            <div key={docType?._id} className='p-5 rounded-md bg-surface-primary mb-5 shadow-xs relative'>
              <div className='pb-12 gap-x-5 flex justify-between items-center'>
                <h3 className='text-h200 font-display'>{docType?.type}</h3>
                <FolderZip
                  name={`${entityTypeDict[entityType]} ${entityId} ${docType?.type}`}
                  entityId={entityId}
                  entityType={entityType}
                  folderName={docType?.type as string}
                />
              </div>
              <EntityFolder entityId={entityId} entityType={entityType} folderName={docType?.type as string} />
            </div>
          ))}
          {children}
        </Columns>
      </div>
    </Card>
  )
}

export default DocumentManager
