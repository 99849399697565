import { FC } from 'react'
import Avatar from '../../components/Avatar'
import useCurrentUser from '../../hooks/useCurrentUser'

interface CurrentUserFilterProps {
  userId?: number
  onChange: (userId?: number) => void
}

const CurrentUserFilter: FC<CurrentUserFilterProps> = ({ userId, onChange }) => {
  const currentUser = useCurrentUser()

  const isActive = currentUser?._id === userId

  return (
    <button onClick={() => {
      onChange(isActive ? undefined : currentUser?._id)
    }}>
      <Avatar userId={currentUser?._id} active={isActive} />
    </button>
  )
}

export default CurrentUserFilter