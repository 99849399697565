import { useRef } from 'react'
import c from 'clsx'
import { useController, useForm, Controller } from 'react-hook-form'
import { IMaskInput } from 'react-imask'
import { addUTCDays, addWorkDays, daysBetween, maxWorkingDate, workDaysBetween } from '../../../utils/dates'
import { dateFormatter, getCalendarFormatDate } from '../../../utils/dateFormatter'
import Checkbox from '../Checkbox'
import { errorToString } from '../../../utils/errorToString'
import { ControlledInput } from '../../../types/controlledInput'

interface RelativeDateInputProps {
  label: string
}

interface InternalFormValues {
  checkWorkDays: boolean
  dayInterval: string
}

const dayMaskOptions = {
  mask: 'num д.',
  blocks: {
    num: {
      mask: Number
    }
  },
  unmask: true
}

const addDaysToDate = (date: Date, days: number, workDays: boolean) => {
  if (workDays) {
    return addWorkDays(date, days)
  }
  return addUTCDays(date, days)
}

const countDaysBetween = (date1: Date, date2: Date, workDays: boolean) => {
  if (workDays) {
    return workDaysBetween(date1, date2)
  }
  return daysBetween(date1, date2)
}

const getInitialDayInterval = (date: Date, workDays: boolean) => {
  const days = countDaysBetween(new Date(), date, workDays)
  if (days === undefined) return ''
  return Math.max(days - 2, 0).toString()
}

const RelativeDateInput: ControlledInput<RelativeDateInputProps> = ({ label, rules, ...props }) => {
  const baseDate = useRef(new Date())

  const { field: dateField, fieldState: dateFieldState } = useController({
    ...props,
    rules: {
      ...rules,
      validate: {
        ...rules?.validate,
        dateInSafeRange: (value: string) => {
          // dont validate if empty
          if (!value) return true

          const date = new Date(value)
          // allow date to be in the past
          if (date < baseDate.current) return true

          const countDaysWorks = countDaysBetween(baseDate.current, date, getInternalFormValues('checkWorkDays'))
          const addDaysWorks = addDaysToDate(
            baseDate.current,
            parseInt(getInternalFormValues('dayInterval')),
            getInternalFormValues('checkWorkDays')
          )
          if (!countDaysWorks || !addDaysWorks)
            return `Посчитать рабочие дни автоматически можно только до ${dateFormatter.format(
              maxWorkingDate
            )} (${Math.max(0, (workDaysBetween(new Date(), maxWorkingDate) || 0) - 2)} д.)`
          return true
        }
      }
    }
  })

  // small internal form to control dayInterval input and checkWorkDays checkbox
  const {
    control,
    setValue: setInternalFormValue,
    getValues: getInternalFormValues
  } = useForm<InternalFormValues>({
    defaultValues: {
      checkWorkDays: true,
      dayInterval: dateField.value ? getInitialDayInterval(new Date(dateField.value), true) : ''
    }
  })

  return (
    <div>
      {!!label && <div className='inp-label text-p350 mb-5'>{label}</div>}

      <label
        className={c(
          'group flex relative bg-white-0 ring-grayscale-400 hover:focus-within:ring-red-100 focus-within:ring-red-100 hover:ring-grayscale-250 rounded-xl ring-1',
          !!dateFieldState.error && 'ring-red-100 ring-1'
        )}
      >
        <Controller
          name='dayInterval'
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <IMaskInput
                {...dayMaskOptions}
                value={value}
                placeholder='120 д.'
                onAccept={(_, mask) => {
                  const newDayInterval = mask.unmaskedValue
                  if (newDayInterval === value) return
                  onChange(newDayInterval)

                  // if the value is invalid, just set it to empty string
                  if (newDayInterval === '') {
                    dateField.onChange({
                      name: props.name,
                      value: ''
                    })
                    return
                  }
                  // бизнес требует, чтобы даты поставки считались пессимистично
                  // например, если сегодня понедельник, а поставка через один рабочий день
                  // то поставка будет в среду, а не во вторник
                  const newDate = addDaysToDate(
                    new Date(),
                    parseInt(newDayInterval) + 1,
                    getInternalFormValues('checkWorkDays')
                  )
                  if (newDate) {
                    dateField.onChange(getCalendarFormatDate(newDate))
                  }
                }}
                className='block tabular-nums box-content w-25 py-7 pl-10 pr-5 transition-opacity border-none outline-none bg-transparent focus:ring-0 placeholder-grayscale-250'
              />
            )
          }}
        />

        <div className='w-1px my-3 bg-grayscale-400 flex-none' />
        <input
          {...dateField}
          onChange={(e) => {
            const value = e.target.value
            dateField.onChange(e)
            if (value === '') {
              setInternalFormValue('dayInterval', '')
              return
            }
            if (value === dateField.value) return
            const newDate = new Date(value)
            // check if date is valid
            if (isNaN(newDate.getTime())) return
            if (newDate < baseDate.current) return
            const days = countDaysBetween(baseDate.current, newDate, getInternalFormValues('checkWorkDays'))
            if (days === undefined) return
            // бизнес требует, чтобы даты поставки считались пессимистично
            // например, если сегодня понедельник, а поставка через один рабочий день
            // то поставка будет в среду, а не во вторник
            setInternalFormValue('dayInterval', Math.max(days - 2, 0).toString())
          }}
          type='date'
          className='block w-full py-7 pr-10 pl-5 transition-opacity border-none outline-none bg-transparent focus:ring-0 placeholder-grayscale-250'
        />
      </label>
      {dateFieldState.error && <div className='text-red-150 text-p450 pt-2'>{errorToString(dateFieldState.error)}</div>}
      <Controller
        name='checkWorkDays'
        control={control}
        render={({ field: { onChange, value } }) => (
          <Checkbox
            label={'Рабочих дней'}
            checked={value}
            onChange={(value) => {
              onChange(value)
              const newDate = addDaysToDate(new Date(), parseInt(getInternalFormValues('dayInterval')), value)
              if (!newDate) {
                return
              }
              dateField.onChange(getCalendarFormatDate(newDate))
            }}
            className='mt-5'
          />
        )}
      />
    </div>
  )
}

export default RelativeDateInput
