export default function checkOgrn(ogrn?: string | number): boolean | string {
	if (typeof ogrn === 'number') {
		ogrn = ogrn.toString()
	}
	if (!ogrn?.length) {
		return  'ОГРН пуст'
	} else if (/[^0-9]/.test(ogrn)) {
		return  'ОГРН может состоять только из цифр'
	} else if (ogrn.length !== 13) {
		return  'ОГРН может состоять только из 13 цифр'
	} else {
		const n13 = parseInt((parseInt(ogrn.slice(0, -1)) % 11).toString().slice(-1))
		if (n13 === parseInt(ogrn[12])) {
			return true
		} else {
			return  'Неправильное контрольное число'
		}
	}
}
